import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, TabContent, TabPane } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { actGetOrder, actLogout, actLogoutchecked } from "../../redux/actions";
import getUrlParam from "../../utils/getUrlParam";
import TabContentAccessHistory from "./Tabs/TabContentAccessHistory";
import TabContentNotification from "./Tabs/TabContentNotification";
import TabContentTabHistory from "./Tabs/TabContentTabHistory";
import TabContentTabInfo from "./Tabs/TabContentTabInfo";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { deleteCookie } from "../../utils/cookies";
import TabChangePassword from "./Tabs/TabChangePassword";
export class AccountsManagementDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getUrlParam()["tab"] ? parseInt(getUrlParam()["tab"]) : 1,
      userAccount: {},
      sessionId: getUrlParam()["session_id"] ? getUrlParam()["session_id"] : ""
    };
  }

  componentDidMount() {
    const { sessionId } = this.state;
    if (sessionId) {
      this.props.getOrder(sessionId);
    }
    if (this.props.accountManagement && this.props.accountManagement.userAccount) {
      this.setState({
        userAccount: { ...this.props.accountManagement.userAccount }
      });
    } else {
      this.setState({
        ...this.state
      });
    }

    // handleJquerySVG();
    // this.props.history.push(`?tab=${this.state.activeTab}`);
  }

  componentDidUpdate(nextProps) {
    var tabNo = parseInt(getUrlParam()["tab"]);
    if (tabNo && tabNo !== null && tabNo !== undefined && Number.isInteger(tabNo)) {
      if (tabNo !== parseInt(this.state.activeTab)) {
        this.setState(
          {
            activeTab: tabNo
          },
          () => this.props.history.push(`?tab=${this.state.activeTab}`)
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.accountManagement && nextProps.accountManagement.userAccount) {
      this.setState({
        userAccount: { ...nextProps.accountManagement.userAccount }
      });
    } else {
      this.setState({
        ...this.state
      });
    }
  }

  redirectURL = () => {
    this.props.logout();
    localStorage.removeItem("access_token");
    deleteCookie("access_token");
    this.handleHideAlert();
    // window.location.href = "/dang-nhap";
    this.props.history.push("/dang-nhap");
  };

  actLogout = () => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmBtnText={messages[`user.Confirm`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          closeOnClickOutside={false}
          showCancel={true}
          onCancel={this.handleHideAlert}
          onConfirm={() => {
            this.props.actLogoutchecked(this.redirectURL);
          }}
        >
          <IntlMessages id="user.LogOffB" />
        </SweetAlert>
      )
    });
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  toggle = (tab) => {
    this.props.history.push(`?tab=${tab}`);
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    } else {
      this.setState({
        ...this.state
      });
    }
  };

  render() {
    const { loadingUser } = this.props.accountManagement;
    const { loading } = this.props.checkLoggedDevice;
    const { userAccount } = this.state;
    return (
      <div className="manament-tab-nav row equal-height">
        {loading ? this.state.alert : ""}
        <div className="manament-tabcontent-left col-lg-3 col-xl-2 mb-lg-0 mb-4">
          <div className="">
            <ul className="nav">
              <li>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 1
                  })}
                  onClick={() => {
                    this.toggle(1);
                  }}
                >
                  <IntlMessages id="user.Info" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 2
                  })}
                  onClick={() => {
                    this.toggle(2);
                  }}
                >
                  <IntlMessages id="user.RequestHistory" />
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 3
                  })}
                  onClick={() => {
                    this.toggle(3);
                  }}
                >
                  <IntlMessages id="user.LogHistory" />
                </NavLink>
              </li> */}

              <li>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 4
                  })}
                  onClick={() => {
                    this.toggle(4);
                  }}
                >
                  <IntlMessages id="user.Notification" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === 5
                  })}
                  onClick={() => {
                    this.toggle(5);
                  }}
                >
                  <IntlMessages id="user.changpass" />
                </NavLink>
              </li>
            </ul>
            {/* <div className="banner">
              {bannerData &&
                bannerData.length > 0 &&
                bannerData
                  .filter(item => item.position === 4)
                  .map((props, index) => {
                    return (
                      <a
                        key={index}
                        href={props.link || "#bob"}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                          marginTop: "10px",
                          display: "inline-block",
                          width: "100%"
                        }}
                      >
                        <img
                          width="100%"
                          height="auto"
                          src={`${hostImg}${props.url ? props.url : ""}`}
                          alt="advs"
                        />
                      </a>
                    );
                  })}
            </div> */}
          </div>
        </div>

        <div className="manament-tabcontent-right-flex col-lg-9 col-xl-10">
          <div className="card-item mb-4">
            <TabContent activeTab={this.state.activeTab} className="manament-tabcontent-right">
              <TabPane tabId={1}>
                <TabContentTabInfo userAccount={userAccount} loadingUser={loadingUser} {...this.props} />
              </TabPane>
              <TabPane tabId={2}>
                <TabContentTabHistory {...this.props} />
              </TabPane>
              <TabPane tabId={3}>
                <TabContentAccessHistory {...this.props} />
              </TabPane>
              <TabPane tabId={4}>
                <TabContentNotification {...this.props} />
              </TabPane>
              <TabPane tabId={5}>
                <TabChangePassword {...this.props} />
              </TabPane>
            </TabContent>
            {/* {this.state.activeTab === 4 && <ListNotification />} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountManagement: state.accountReducer,
    faqReducer: state.faqReducer,
    checkLoggedDevice: state.authReducer.checkLoggedDevice
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actLogout()),
    actLogoutchecked: (cbSuccess) => dispatch(actLogoutchecked(cbSuccess)),
    // lấy thông tin đơn hàng
    getOrder: (sessionId) => dispatch(actGetOrder(sessionId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AccountsManagementDetail));
