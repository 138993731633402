import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/index.scss";
import { setIpV4 } from "./utils/callApiServer";

let render = () => {
  setIpV4();

  import("./assets/scss/index.scss").then((x) => {
    require("./AppRenderer");
  });
};
render();
