export const defaultLocale = "vi";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "vi", name: "Vietnamese", direction: "ltr" }
];

export const defaultDirection = "ltr";

export const defaultPageConpanyInfo = {
  //Chỉ số
  tabRatio: [
    {
      set: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 17, 18, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
      basic: [[49], [53, 55, 56]],
      standard: [[49], [53, 55, 56]],
      premium: [[48, 49], [50, 51], [52], [53, 54, 55, 56]]
    },
    {
      set: [2, 13, 14, 19, 20, 21, 22],
      basic: [
        [61, 62],
        [59, 57, 58]
      ],
      standard: [
        [61, 62],
        [59, 57, 58]
      ],
      premium: [
        [61, 62],
        [59, 57, 58]
      ]
    }
  ],
  // Chỉ tiêu
  tabAccount: [
    {
      set: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 17, 18, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
      basic: [
        [20, 19],
        [21, 13]
      ],
      standard: [
        [20, 19],
        [21, 13]
      ],
      premium: [
        [20, 14, 11],
        [19, 16, 17, 15, 18]
      ]
    },
    {
      set: [2, 13, 14, 19, 20, 21, 22],
      basic: [[25], [27]],
      standard: [
        [25, 26],
        [27, 31]
      ],
      premium: [
        [25, 26],
        [27, 31],
        [32, 33, 34, 36, 39],
        [41, 47]
      ]
    }
  ]
};

export const basicColor = [];

export const DEFAULT_STATISTIC_VSIC = ["0111", "0112", "0113"];
