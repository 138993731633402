export function numberFormat(amount, decimal = ",", thousands = ".") {
  try {
    return amount
      .toString()
      .replace(thousands, decimal)
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
  } catch (e) {
    // Todo err
  }
}

export function numberFormat_2(amount, fixed = 2, decimal = ".", thousands = ",") {
  try {
    let data;
    if (Number.isInteger(amount)) {
      data = amount.toString();
    } else {
      let fixedNumber = fixed ? fixed : 2;
      data = amount.toFixed(fixedNumber).toString();
    }
    return data.replace(thousands, decimal).replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
  } catch (e) {
    // Todo err
  }
}
