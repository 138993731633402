import moment from "moment";
import formatNumber from "./formatNumber";
require("moment/locale/vi");

export const formatStringToDate = dateString => {
    if (dateString) {
        const date = moment(dateString).format("L");
        return date;
    } else {
        return null;
    }
};

export const formatStringToTime = dateString => {
    if (dateString) {
        const date = moment(dateString).locale("vi");
        return (
            formatNumber(date.hour()) +
            ":" +
            formatNumber(date.minute()) +
            " - " +
            formatNumber(date.date()) +
            "/" +
            formatNumber(date.month() + 1) +
            "/" +
            formatNumber(date.year())
        );
    } else {
        return null;
    }
};

export const countTime = dateString => {
    const date = new moment(dateString).fromNow();
    return date;
};
