import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages.js";
import { getRequestProvince, getRequestDistrict, getRequestCommune } from "../../../redux/Common/actions.js";
import { Dropdown, Menu, Checkbox } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "reactstrap";
import { actAdvanceSearchCompanyByArea, actGetDataPointList } from "../../../redux/CompanyInfo/actions.js";
import { CompanyItem } from "../../../components/CompanyInfo/CompanyChild/CompanyItem.jsx";
import Pagination from "../../../components/Pagination/PaginationSimple.jsx";
import { formatMoney } from "../../../utils/formatMoney.js";
import LoaderTable from "../../../components/LoaderTable.jsx";
import Findnotfound from "../../../components/Findnotfound.jsx";
import { _Demo_, _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel.js";
import { copyArray } from "../../../utils/copyArray.js";
import { callApiServer } from "../../../utils/callApiServer.js";
import moment from "moment";
import { Spinner } from "reactstrap";
import { saveAs as importedSaveAs } from "file-saver";
import { actAddToCart } from "../../../redux/actions.js";
import ConfirmPay from "../../AccountsManagement/ConfirmPayComponent.jsx";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class TabFoundingDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listYear: [],
      selectedDataPoint: [], // giai đoạn dữ liệu
      provinces: [],
      districts: [],
      communes: [],
      selectedArea: [
        // khu vực
        {
          provinceId: "",
          provinceidrequest: "",
          districtId: "",
          communeId: ""
        }
      ],
      provinceIndex: 0,
      districtIndex: 0,
      communeIndex: 0,
      selectedTerms: [], // điều kiện tìm kiếm được thêm
      pageIndex: 1,
      pageSize: 10,
      activePage: 1,
      isSearch: false,
      dataSearch: {},
      isDownloading: false,
      isDownloading_2: false,

      fromDate: null,
      maxFromDate: null,
      minFromDate: null,

      toDate: null,
      maxToDate: null,
      minToDate: null

      // registrationDate: [
      //   {
      //     fromDate: null,
      //     maxFromDate: new Date(),
      //     toDate: new Date(),
      //     maxToDate: new Date(),
      //     minToDate: null
      //   }
      // ]
    };
  }

  componentDidMount() {
    this.props.requestGetListProvince();
    // this.props.getDataPointList();

    this.getListYear();
    this.initSearchDate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const language = localStorage.getItem("currentLanguage");
    let { provinceIndex, districtIndex, communeIndex, districts, communes } = this.state;
    let { listDistrict, listCommune } = nextProps.commonReducer;
    if (listDistrict && listDistrict.length && listDistrict[0].key !== "") {
      listDistrict = listDistrict.map((props) => {
        return { key: props.districtsCode, value: props.id, text: language === "en" && props.enName ? props.enName : props.name };
      });
      listDistrict.unshift({
        key: "",
        text: language === "en" ? "District" : "Quận/Huyện",
        value: ""
      });
    }
    if (listCommune && listCommune.length && listCommune[0].key !== "") {
      listCommune = listCommune.map((props) => {
        return { key: props.id, value: props.id, text: language === "en" && props.enName ? props.enName : props.name };
      });
      listCommune.unshift({
        key: "",
        text: language === "en" ? "Commune" : "Phường/Xã",
        value: ""
      });
    }
    if (listDistrict && districtIndex === provinceIndex) {
      districts[districtIndex] = listDistrict;
    }
    if (listCommune && communeIndex === districtIndex && districtIndex === provinceIndex) {
      communes[communeIndex] = listCommune;
    }
    this.setState({
      districts,
      communes
    });
  }

  initSearchDate() {
    let currentDate = new Date();
    let d = currentDate.getDate();
    let m = currentDate.getMonth();
    let y = currentDate.getFullYear();

    let oneYearDate = new Date(this.convertDate(moment(new Date(y - 1, m, d)).format("DD/MM/YYYY")));

    this.setState({
      toDate: new Date(),
      minToDate: oneYearDate,
      maxToDate: new Date(),
      fromDate: oneYearDate,
      minFromDate: null,
      maxFromDate: new Date()
    });
  }

  // convert: dd/MM/yyyy -> yyyy-MM-dd
  convertDate(date) {
    return date.substring(6) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
  }

  // List năm
  async getListYear() {
    try {
      const response = await callApiServer.get("/Company/ListYear");
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.setState({ listYear: data });
      } else {
        this.setState({
          listYear: []
        });
      }
    } catch (error) {
      this.setState({
        listYear: []
      });
    }
  }

  // chọn giai đoạn dữ liệu
  handleChangeDataPoint = (year) => {
    const index = this.state.selectedDataPoint.indexOf(year);
    if (index < 0) {
      this.state.selectedDataPoint.push(year);
    } else {
      this.state.selectedDataPoint.splice(index, 1);
    }
    this.setState({
      selectedDataPoint: [...this.state.selectedDataPoint]
    });
  };

  handleChangeProvince = (value, index, provinces, prop, provinceidrequest) => {
    const province = provinces.filter((i) => i.value === value)[0];
    let selectedArea = this.state.selectedArea;
    selectedArea[index].provinceId = value;
    selectedArea[index].provinceidrequest = province.provinceidrequest;

    selectedArea[index].provinceCode = province.key;
    selectedArea[index].provinceName = province.text;
    selectedArea[index].districtId = "";
    selectedArea[index].districtCode = "";
    selectedArea[index].districtName = "";
    selectedArea[index].communeId = "";
    selectedArea[index].communeCode = "";
    selectedArea[index].communeName = "";
    this.setState({
      selectedArea,
      provinceIndex: index,
      districtIndex: index,
      communeIndex: index
    });
    this.props.requestGetListDistrict(value);
  };

  handleChangeDistrict = (value, index, districts) => {
    const district = districts.filter((i) => i.value === value)[0];
    let selectedArea = this.state.selectedArea;
    selectedArea[index].districtId = value;
    selectedArea[index].districtCode = district.key;
    selectedArea[index].districtName = district.text;
    selectedArea[index].communeId = "";
    selectedArea[index].communeCode = "";
    selectedArea[index].communeName = "";
    this.setState({
      selectedArea,
      districtIndex: index,
      communeIndex: index
    });
    this.props.requestGetListCommune(value);
  };

  handleChangeCommnue = (value, index, communes) => {
    const commune = communes.filter((i) => i.value === value)[0];
    let selectedArea = this.state.selectedArea;
    selectedArea[index].communeId = value;
    selectedArea[index].communeCode = commune.key;
    selectedArea[index].communeName = commune.text;
    this.setState({
      selectedArea,
      communeIndex: index
    });
  };

  // thêm thời gian
  addRegistrationDate = () => {
    let { registrationDate } = this.state;
    registrationDate.push({
      fromDate: null,
      maxFromDate: new Date(),

      toDate: new Date(),
      maxToDate: new Date(),
      minToDate: null
    });

    this.setState({
      registrationDate
    });
  };

  // xóa thời gian
  removeRegistrationDate = (index) => {
    let { registrationDate } = this.state;
    registrationDate = registrationDate.filter((value, i) => i !== index);

    this.setState({
      registrationDate
    });
  };

  // thêm điều kiện
  addTerms = () => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { fromDate, toDate } = this.state;

    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const x = selectedAdvanceSearchingCriteria.length + 1;

    // Check xem đã có Điều kiện Tìm kiếm theo Ngày thành lập hay chưa ?
    let checkRegistrationDateArr = selectedAdvanceSearchingCriteria.filter((item) => item.type === "registration_date");

    if (x > 10) {
      this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
    } else if (checkRegistrationDateArr.length > 0) {
      this.actAlert(messages[`advanceSearch.founding_date.addTermsCondition`]);
    } else if (fromDate === null || toDate === null) {
      this.actAlert(messages[`advanceSearch.founding_date.addTermsCondition_emptyDate`]);
    } else {
      if (this.checkGapOneYear(fromDate, toDate)) {
        let selectedTerms = [];

        selectedTerms.push({
          type: "registration_date",
          fromDate: fromDate,
          toDate: toDate
        });

        this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, ...selectedTerms]);

        this.setState({
          selectedTerms
        });
      } else {
        this.actAlert(messages[`advanceSearch.founding_date.addTermsCondition_oneYearGap`]);
        this.initSearchDate();
      }
    }
  };

  handleAddFoudingDateTerm = () => {};

  checkGapOneYear = (fromDate, toDate) => {
    const fromDateTime = new Date(fromDate).getTime();
    const toDateTime = new Date(toDate).getTime();

    const gapTwoDate = (toDateTime - fromDateTime) / (1000 * 60 * 60 * 24);
    const gapDayTwoDate = Math.floor(gapTwoDate);

    if (gapDayTwoDate > 366 || gapDayTwoDate < 0) {
      return false;
    } else {
      return true;
    }
  };

  // xóa điều kiện
  removeTerm = (index) => {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    this.props.companyReducer.selectedAdvanceSearchingCriteria = selectedAdvanceSearchingCriteria.filter((item, i) => i !== index);
    let selectedTerms = this.state.selectedTerms.filter((item, i) => i !== index);
    this.setState({
      selectedTerms
    });
  };

  // tìm kiếm
  searchTerms = (page) => {
    const { messages } = this.props.intl;
    const { pageSize } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    let registrationDates = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      this.setState({ isSearch: true });
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        } else if (e.type === "registration_date") {
          if (e.fromDate) {
            registrationDates.push({
              fromDate: e.fromDate ? e.fromDate : "",
              toDate: e.toDate ? e.toDate : ""
            });
          } else {
            registrationDates.push({
              toDate: e.toDate ? e.toDate : ""
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };

      var body = {
        pageIndex: page,
        pageSize: pageSize,
        areas,
        vsics,
        financials,
        companyTypes,
        legals,
        owners,
        importExportTurnover,
        registrationDates
      };
      this.props.advanceSearchCompanyByArea(body);
      this.setState({
        dataSearch: body
      });
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.searchTerms(activePage));
  };

  handleDownLoad(dataSearch) {
    const { messages } = this.props.intl;
    this.setState({
      isDownloading: true
    });
    const body = {
      areas: dataSearch.areas,
      financials: dataSearch.financials,
      vsics: dataSearch.vsics,
      companyTypes: dataSearch.companyTypes,
      legals: dataSearch.legals,
      owners: dataSearch.owners,
      importExportTurnover: dataSearch.importExportTurnover
    };
    callApiServer
      .post("Company/CompanyReportAdvancedSearch/export", body)
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }

  async handleDownLoad_2(selectedAdvanceSearchingCriteria) {
    const { messages } = this.props.intl;

    const toolTipContent = {
      basic: "",
      advance: selectedAdvanceSearchingCriteria
    };
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    let registrationDates = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        } else if (e.type === "registration_date") {
          if (e.fromDate) {
            registrationDates.push({
              fromDate: e.fromDate ? e.fromDate : "",
              toDate: e.toDate ? e.toDate : ""
            });
          } else {
            registrationDates.push({
              toDate: e.toDate ? e.toDate : ""
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }

    this.setState({
      isDownloading_2: true
    });

    const body = {
      searchText: "",
      searchType: "Advanced",
      searchTooltip: JSON.stringify(toolTipContent),
      pageIndex: 0,
      pageSize: 10,
      areas: areas,
      financials: financials,
      vsics: vsics,
      companyTypes: companyTypes,
      legals: legals,
      owners: owners,
      importExportTurnover: importExportTurnover,
      registrationDates: registrationDates
    };
    try {
      const response = await callApiServer.post("/Company/export-search-create-product", body);
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.openPopupConfirmBuy(data.point, data.price, data.productType, 0, data, toolTipContent);
        this.setState({
          isDownloading_2: false
        });
      } else if (response && response.data && response.data.errorCode === "W077") {
        this.openPopupExceedNumberOfCompany();
        this.setState({
          isDownloading_2: false
        });
      } else {
        this.setState({
          isDownloading_2: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloading_2: false
      });
    }
  }

  openPopupExceedNumberOfCompany() {
    const { messages } = this.props.intl;
    this.setState({
      popupExceedNumberOfCompany: (
        <SweetAlert
          warning
          title={messages["user.Notification"]}
          confirmBtnBsStyle="warning"
          confirmBtnText={messages["newWord.close"]}
          onConfirm={() => {
            this.setState({
              popupExceedNumberOfCompany: ""
            });
          }}
        >
          <p style={{ fontSize: "16px" }}>
            <IntlMessages id="quantity_company.exceed_1500.text" />
          </p>
        </SweetAlert>
      )
    });
  }

  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail, toolTipContent) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.productId}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            toolTipContent={toolTipContent}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }

  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail, toolTipContent) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  handleChangeFromDate = (newFromDate) => {
    // if (newFromDate) {
    //   let { fromDate, maxFromDate, minFromDate, toDate, maxToDate, minToDate } = this.state;

    //   const currentDate = new Date();
    //   let y = newFromDate.getFullYear();
    //   let m = newFromDate.getMonth();
    //   let d = newFromDate.getDate();

    //   // Khi fromDate thay đổi, minToDate thay đổi
    //   fromDate = newFromDate;
    //   minFromDate = null;
    //   maxFromDate = new Date(this.convertDate(moment(new Date(y + 1, m, d)).format("DD/MM/YYYY")));

    //   minToDate = newFromDate;
    //   maxToDate = new Date(this.convertDate(moment(new Date(y + 1, m, d)).format("DD/MM/YYYY")));

    //   // Nếu maxToDate > currentDate thì maxToDate = currentDate
    //   if (this.compare(maxToDate, currentDate) === 1) {
    //     maxToDate = currentDate;
    //   }
    //   // Nếu toDate hiện tại vượt quá 1 năm kể từ fromDate, cho toDate
    //   // bằng maxToDate
    //   if (this.compare(toDate, maxToDate) === 1) {
    //     toDate = maxToDate;
    //   }

    //   // Dòng này vừa thêm
    //   maxToDate = currentDate;

    //   this.setState({
    //     fromDate,
    //     maxFromDate,
    //     minFromDate,
    //     toDate,
    //     maxToDate,
    //     minToDate
    //   });
    // }

    this.setState({
      fromDate: newFromDate
    });
  };

  handleChangeToDate = (newToDate) => {
    // if (newToDate) {
    //   let { fromDate, maxFromDate, minFromDate, toDate, maxToDate, minToDate } = this.state;

    //   const currentDate = new Date();
    //   let y = newToDate.getFullYear();
    //   let m = newToDate.getMonth();
    //   let d = newToDate.getDate();

    //   // Khi toDate thay đổi, maxFromDate thay đổi
    //   toDate = newToDate;
    //   maxToDate = currentDate;
    //   minToDate = new Date(this.convertDate(moment(new Date(y - 1, m, d)).format("DD/MM/YYYY")));

    //   maxFromDate = newToDate;
    //   minFromDate = new Date(this.convertDate(moment(new Date(y - 1, m, d)).format("DD/MM/YYYY")));

    //   // Nếu fromDate < minFromDate thì cho fromDate = minFromDate
    //   if (this.compare(fromDate, minFromDate) === -1) {
    //     fromDate = minFromDate;
    //   }

    //   // Nếu toDate nhỏ hơn fromDate thì fromDate sẽ bằng toDate -1
    //   if (this.compare(toDate, fromDate) === -1) {
    //     fromDate = minFromDate;
    //   }

    //   // Dòng này vừa thêm
    //   minFromDate = null;

    //   this.setState({
    //     fromDate,
    //     maxFromDate,
    //     minFromDate,
    //     toDate,
    //     maxToDate,
    //     minToDate
    //   });
    // }

    this.setState({
      toDate: newToDate
    });
  };

  compare(d1, d2) {
    const format = "DD/MM/YYYY";
    const m1 = moment(d1, format);
    const m2 = moment(d2, format);

    if (m1 > m2) {
      return 1;
    } else if (m1 < m2) {
      return -1;
    } else {
      return 0;
    }
  }

  renderTime(time) {
    if (time) return moment(time).format("DD/MM/YYYY");
    else return "";
  }

  render() {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const { listProvince } = this.props.commonReducer;
    const provinces = listProvince.map((props) => {
      return {
        key: props.provinceCode,
        value: props.id,
        text: language === "en" && props.enName ? props.enName : props.name,
        provinceidrequest: props.provinceId ? props.provinceId : ""
      };
    });

    provinces.unshift({
      key: "",
      text: language === "en" ? "Province/City" : "Tỉnh/Thành phố",
      value: "",
      provinceidrequest: ""
    });
    const {
      selectedArea,
      districts,
      communes,
      activePage,
      isSearch,
      dataSearch,
      isDownloading,
      listYear,
      isDownloading_2,
      fromDate,
      maxFromDate,
      minFromDate,
      toDate,
      maxToDate,
      minToDate
    } = this.state;
    const { totalItems, totalPage, loading, companyData, dataPointList, selectedAdvanceSearchingCriteria, advanceSearchLoading } = this.props.companyReducer;

    const { isEmailSpecial } = this.props.userAccount;

    let locale = language === "vi" ? "vi" : "enGB";
    let maxDate = new Date();

    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.popupConfirmBuy}

        {/* popup xuất hiện khi Số lượng doanh nghiệp trong danh sách lớn hơn 1500 */}
        {this.state.popupExceedNumberOfCompany}

        {this.state.popupNotEnough}
        <div className="tab_area">
          <div className="row equal-height">
            <div className="col-lg-7 col-xl-8 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.selectSearchTerms" />
                </div>

                <div>
                  <div className="founding-date mb-3">
                    {/* Đây là đoạn cần có input nhập ngày tháng */}
                    <div className="row">
                      <div className="d-flex align-items-center col-xl-6 col-lg-6 col-md-6 col-12 mb-2">
                        <span className="mr-3">
                          <IntlMessages id="advanceSearch.founding_date.title_from_date" />
                        </span>
                        <div className="datepicker-container">
                          <span className="material-icons icon-calendar">date_range</span>
                          <DatePicker
                            className="datepicker-custom"
                            // minDate={minFromDate}
                            maxDate={maxDate}
                            selected={fromDate}
                            onChange={(newFromDate) => this.handleChangeFromDate(newFromDate)}
                            dateFormat="dd/MM/yyyy"
                            showDayMonthYearPicker
                            // isClearable={true}
                            placeholderText={messages[`advanceSearch.founding_date.placeholder_from_date`]}
                            locale={locale}
                            disabledKeyboardNavigation
                            showMonthDropdown
                            showYearDropdown
                            // onKeyDown={(e) => {
                            //   e.preventDefault();
                            // }}
                          />
                        </div>
                      </div>

                      <div className="d-flex align-items-center col-xl-6 col-lg-6 col-md-6 col-12">
                        <span className="mr-3">
                          <IntlMessages id="advanceSearch.founding_date.title_to_date" />
                        </span>
                        <div className="datepicker-container">
                          <span className="material-icons icon-calendar">date_range</span>
                          <DatePicker
                            className="datepicker-custom"
                            // minDate={minToDate}
                            maxDate={maxDate}
                            selected={toDate}
                            onChange={(newToDate) => this.handleChangeToDate(newToDate)}
                            dateFormat="dd/MM/yyyy"
                            showDayMonthYearPicker
                            // isClearable={true}
                            placeholderText={messages[`advanceSearch.founding_date.placeholder_to_date`]}
                            locale={locale}
                            showMonthDropdown
                            showYearDropdown
                            // onKeyDown={(e) => {
                            //   e.preventDefault();
                            // }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Hết: Đây là đoạn cần có input nhập ngày tháng */}
                  </div>

                  {/* );
                    })} */}
                  {/* <div className="add-time" onClick={() => this.addRegistrationDate()}>
                    <span className="material-icons check mr-1">add_circle</span>
                    <span>
                      <IntlMessages id="advanceSearch.founding_date.add" />
                    </span>
                  </div> */}
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <Button className={`common-button`} onClick={() => this.addTerms()}>
                    <IntlMessages id="advanceSearch.addTerms" />
                    {/* <em className="material-icons">add</em> */}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.searchTerms" />
                </div>
                <div>
                  {selectedAdvanceSearchingCriteria.length > 0 && (
                    <div className="table-custom ">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <IntlMessages id="table.sttTable" />
                            </th>
                            <th>
                              <IntlMessages id="advanceSearch.term" />
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAdvanceSearchingCriteria.map((prop, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                {prop.type === "area" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${prop.area.districtName ? prop.area.districtName + ", " : ""}${
                                      prop.area.provinceName
                                    }`}</span>
                                  </td>
                                )}
                                {prop.type === "industry" && (
                                  <td>
                                    <IntlMessages id="sidebar.Industry" /> {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </td>
                                )}
                                {prop.type === "companyType" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :{language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} (
                                    {prop.dataPoint}):{" "}
                                  </td>
                                )}
                                {prop.type === "legal" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.idPerson}{" "}
                                  </td>
                                )}
                                {prop.type === "owner" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.showId}{" "}
                                  </td>
                                )}
                                {prop.type === "finance" && (
                                  <td>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit})`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_year" && (
                                  <td>
                                    {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.unit}) `
                                        : ""}
                                      {prop.criteria.to ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.unit})` : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <td>
                                    {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </td>
                                )}

                                {prop.type === "registration_date" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.founding_date.condition_tilte" />: <IntlMessages id="advanceSearch.founding_date.condition_from" />{" "}
                                    {prop.fromDate ? " " + this.renderTime(prop.fromDate) : ""} <IntlMessages id="advanceSearch.founding_date.condition_to" />{" "}
                                    {prop.toDate ? this.renderTime(prop.toDate) : ""}
                                  </td>
                                )}
                                <td>
                                  <span className="material-icons check" onClick={() => this.removeTerm(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div className="mt-5 d-flex justify-content-center">
                    <Button className={`common-button`} onClick={() => this.searchTerms(1)} disabled={loading} isLoading={loading}>
                      <IntlMessages id="filter.buttonSearch" />
                      <em className="material-icons">search</em>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {advanceSearchLoading === true && <LoaderTable isLoaded={!loading} styleCustom={{ height: "500px" }} />}
            {advanceSearchLoading === false && (
              <div>
                {companyData && companyData.length > 0 ? (
                  <div className="card-item mb-4 mt-0 company-info-content">
                    <div className="company-info-content-main w-100">
                      <div className="search-result-heading pb-3">
                        <p className="search-result-title">{`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}</p>
                        {isEmailSpecial ? (
                          <div>
                            {isDownloading ? (
                              <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                            ) : (
                              <div
                                className="btn-export-data text-cyan"
                                onClick={() => {
                                  this.handleDownLoad(dataSearch);
                                }}
                              >
                                <em className="material-icons">download</em>
                                <IntlMessages id="newWord.downloadSearchResult" />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {isDownloading_2 ? (
                              <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                            ) : (
                              <div
                                className="btn-export-data text-cyan"
                                onClick={() => {
                                  this.handleDownLoad_2(selectedAdvanceSearchingCriteria);
                                }}
                              >
                                <em className="material-icons">download</em>
                                <IntlMessages id="newWord.downloadSearchResult" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="search-result-list">
                        <div className="row">
                          {companyData.map((props, index) => {
                            return (
                              <CompanyItem
                                {...this.props}
                                key={index}
                                name={props.companyName}
                                nameEnglish={props.companyEnName}
                                id={props.id}
                                taxNumber={props.companyTax}
                                vsic={props.vsic}
                                address={props.companyAddress}
                                addressEnglish={props.companyEnAddress}
                                phoneNumber={props.companyPhone}
                                fax={props.companyFax}
                                webSite={props.companyWebsite}
                                vsicID={props.vsic}
                                isShowValueTooltip={false}
                                enNetProfitLabel={props.enNetProfitLabel}
                                enNetRevenueLabel={props.enNetRevenueLabel}
                                netProfitLabel={props.netProfitLabel}
                                netRevenueLabel={props.netRevenueLabel}
                                netProfitValue={props.netProfitValue}
                                netRevenueValue={props.netRevenueValue}
                                automateCompanyReportPdf={props.automateCompanyReportPdf}
                                automateCompanyReportExcel={props.automateCompanyReportExcel}
                                userAccount={this.props.userAccount}
                                registrationDate={props.registrationDates}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="foot-pagination">
                        <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-item">
                    <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    commonReducer: state.CommonReducer,
    companyReducer: state.companyReducer,
    userAccount: state.accountReducer.userAccount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestGetListProvince: () => dispatch(getRequestProvince()),
    requestGetListDistrict: (id) => dispatch(getRequestDistrict(id)),
    requestGetListCommune: (id) => dispatch(getRequestCommune(id)),
    advanceSearchCompanyByArea: (body) => {
      dispatch(actAdvanceSearchCompanyByArea(body));
    },
    getDataPointList: () => {
      dispatch(actGetDataPointList());
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabFoundingDate));
