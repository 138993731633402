import React, { Component } from "react";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import { actAddToCart, actGetIndustryReportDetail, actGetRelatedReportAndCompany } from "../../redux/actions";
import LoaderTable from "../../components/LoaderTable";
import { Link } from "react-router-dom";
import { Nav, NavItem, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../utils/IntlMessages";
import classnames from "classnames";
import IndustryFilter from "../../components/IndustryFilter/IndustryFilter";
import TabIndex from "./Tabs/TabIndex";
import TabRelatedReport from "./Tabs/TapRelatedReport";
import TabChapter from "./Tabs/TapChapter";
import nav_head_lang_vn from "../../assets/images/ic/nav_head_lang_vn.png";
import nav_head_lang_us from "../../assets/images/ic/nav_head_lang.png";
import { formatStringToTime } from "../../utils/formatDate";
import { handleFlyBuyCart } from "../../assets/js/handleflybuycart.js";
import { callApiServer } from "../../utils/callApiServer.js";
import { saveAs as importedSaveAs } from "file-saver";
import { formatMoney } from "../../utils/formatMoney";
import ConfirmPay from "../../views/AccountsManagement/ConfirmPayComponent.jsx";

class IndustryReportDetail extends Component {
  constructor(props) {
    super(props);

    const systemLanguage = localStorage.getItem("currentLanguage");

    this.state = {
      activeTab: getUrlParam()["tab"] ? getUrlParam()["tab"] : "index",
      id: this.props.match.params.id,
      // reportLang: "vi",
      reportLang: systemLanguage,

      statusButtonBuy: false,
      tabs: []
    };

    this.reportInfoRef = React.createRef();
  }

  componentDidMount() {
    // setTimeout(() => {
    //   handleFlyBuyCart();
    // }, 500);
    const { activeTab, id } = this.state;
    // const staticTab = ["index", "related-report"];
    // const dynamictab = [];
    // if (!["index", "related-report", "chapter-I", "chapter-II", "chapter-III", "chapter-IV", "chapter-V"].includes(activeTab)) {
    //   window.location.href = "/chi-tiet-bao-cao-nganh/" + id + "?tab=index";
    // }

    this.props.actGetIndustryReportDetail(id);
    this.props.actGetRelatedReportAndCompany(id);

    // Enable or Disabled Button Buy
    const { dataRequest } = this.props.carts.dataRequired;
    this.checkItemExistInCart(dataRequest);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Kiếm tra báo cáo có bao nhiêu chương/chapter và mở tab Mục lục đó nếu tab trên url không tồn tại
    const { activeTab, id } = this.state;

    if (nextProps.industryInformationReducer) {
      if (nextProps.industryInformationReducer.industryReportDetail) {
        let listTab = [];
        let staticTab = [];
        let dynamicTab = [];
        const listChapterFromApi = nextProps.industryInformationReducer.industryReportDetail.reportContent ? nextProps.industryInformationReducer.industryReportDetail.reportContent : [];

        const listStaticTab = nextProps.industryInformationReducer.industryReportDetail.reportContentStatic ? nextProps.industryInformationReducer.industryReportDetail.reportContentStatic : [];

        listChapterFromApi.map((item) => {
          dynamicTab.push(`chapter-${item.chapterNo}`);
        });

        listStaticTab.map((item) => {
          staticTab.push(`${item.value}`);
        });

        listTab = [...dynamicTab, ...staticTab];

        this.setState({ tabs: listTab });
        if (this.state.tabs) {
          if (this.state.tabs.length) {
            if (!listTab.includes(activeTab)) {
              window.location.href = "/chi-tiet-bao-cao-nganh/" + id + "?tab=index";
            }
          }
        }
      }
    }

    // Enable or Disabled Button Buy
    const { dataRequest } = nextProps.carts.dataRequired;
    this.checkItemExistInCart(dataRequest);
  }

  // đổi ngôn ngữ báo cáo
  handleChangeReportLang = (reportLang) => {
    this.setState({ reportLang });
  };

  checkItemExistInCart = (dataInCart) => {
    const { industryReportDetail } = this.props.industryInformationReducer;
    const id = industryReportDetail.reportId;
    const isFound = dataInCart.some((item) => item.id === id && item.lang === localStorage.getItem("currentLanguage"));
    this.setState({
      statusButtonBuy: isFound
    });
    return isFound;
  };

  // thay đổi tab
  changeTab = (tab) => {
    const { messages } = this.props.intl;
    if (this.state.activeTab !== tab) {
      if (parseInt(this.state.setChecked) === -1 && [3, 4, 5].includes(tab)) {
        // nếu set = -1 thì thì không thể xem chỉ tiêu, chỉ số, so sánh DN
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title={messages[`user.Notification`]}
              confirmBtnText={messages[`newWord.oke`]}
              customClass="swal-wide"
              confirmBtnCssClass="confirmBtn swal2-styled"
              onConfirm={() => {
                this._hideAlert();
              }}
            >
              <p>
                <IntlMessages id="newWord.notAvailable" />
              </p>
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          activeTab: tab
        });
      }
    }
  };

  downloadReport = () => {
    const { id } = this.state;
    this.props.downloadIndustryReport(id);
  };

  handleBuyIndustry = (data) => {
    // const language = localStorage.getItem("currentLanguage");
    const { reportLang } = this.state;

    let model = {
      id: data.reportId,
      name: data.reportName,
      englishName: data.reportEName,
      requestData: data.reportName,
      price: data.price,
      // point: language === "vi" ? data.point : data.enPoint,
      point: reportLang === "vi" ? data.point : data.enPoint,

      currency: data.currency,
      viracServiceId: 2, // báo cáo ngành
      type: 2,
      // lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
      lang: reportLang
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyIndustry(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const { reportLang } = this.state;

    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} onClick={() => this.handleBuyReport(data)}>
          <span className={"cart-icon material-icons text-cyan"}>shopping_cart</span>
        </button>
        <span className="report-price" style={{ color: "#212529" }}>
          {formatMoney(reportLang === "en" ? data.enPrice : data.price)} VND
        </span>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const { reportLang } = this.state;
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";
    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.reportId && item.lang === reportLang).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  handleBuyReport(data) {
    const { reportLang } = this.state;
    let point = reportLang === "vi" ? data.point : data.enPoint;
    let price = reportLang === "vi" ? data.price : data.enPrice;

    this.openPopupConfirmBuy(point, price, data.productType, 0, data);
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    const { messages } = this.props.intl;
    const { reportLang } = this.state;

    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.reportId}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            reportLang={reportLang}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }
  async handleDownloadReport(reportId, fileName, reportLang) {
    try {
      this.setState({
        isDownloadingIndustryReport: true
      });
      const urlDownload = `/IFReportStatistic/download-if-report-full?ifId=${reportId}&lang=${reportLang}`;
      const response = await callApiServer.post(
        urlDownload,
        {},
        {
          responseType: "blob"
        }
      );

      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingIndustryReport: false
        });
      } else {
        this.setState({
          isDownloadingIndustryReport: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingIndustryReport: false
      });
    }
  }

  // Nút tải Báo cáo/Demo tiếng Anh
  buttonEnReportDownload = (data) => {
    const reportName = data.reportEName ? data.reportEName : data.reportName;
    const reportFile = data.urlFullFileEn ? data.urlFullFileEn : "";
    const fileExtension = reportFile.split(".").pop();

    return (
      <React.Fragment>
        <div className="text-cyan link" onClick={() => this.handleDownloadReport(data.reportId, reportName + `.${fileExtension}`, "en")}>
          <em className="material-icons">download</em>
          {data.enIsPurchased ? <IntlMessages id="industry.downloadReport" /> : <IntlMessages id="industry.downloadDemo" />}
        </div>
      </React.Fragment>
    );
  };

  // Nút tải Báo cáo/Demo tiếng Việt
  buttonViReportDownload = (data) => {
    const reportName = data.reportName ? data.reportName : data.reportEName;
    const reportFile = data.urlFullFile ? data.urlFullFile : "";
    const fileExtension = reportFile.split(".").pop();

    return (
      <React.Fragment>
        <div className="text-cyan link" onClick={() => this.handleDownloadReport(data.reportId, reportName + `.${fileExtension}`, "vi")}>
          <em className="material-icons">download</em>
          {/* {data.isPurchased ? "Tai bao cao" : "Tai demo"} */}
          {data.isPurchased ? <IntlMessages id="industry.downloadReport" /> : <IntlMessages id="industry.downloadDemo" />}
        </div>
      </React.Fragment>
    );
  };

  renderView() {
    const { activeTab, id, reportLang, tabs } = this.state;
    const { industryReportDetail, vsicRelatedCompany, vsicRelatedReport, loadingRelatedReport } = this.props.industryInformationReducer;
    const { hotUrl, reportContent } = industryReportDetail;

    if (activeTab === "related-report") {
      return <TabRelatedReport reportLang={reportLang} vsicRelatedCompany={vsicRelatedCompany} vsicRelatedReport={vsicRelatedReport} id={id} loadingRelatedReport={loadingRelatedReport} />;
    } else if (activeTab.includes("chapter")) {
      return <TabChapter reportLang={reportLang} chapters={reportContent} activeTab={activeTab} id={id} />;
    } else {
      return <TabIndex reportLang={reportLang} chapter={reportContent} imageUrl={hotUrl} id={id} changeTab={this.changeTab} />;
    }

    // switch (activeTab) {
    //   case "related-report":
    //     return (
    //       <TabRelatedReport
    //         vsicRelatedCompany={vsicRelatedCompany}
    //         vsicRelatedReport={vsicRelatedReport}
    //         reportLang={reportLang}
    //         id={id}
    //         loadingRelatedReport={loadingRelatedReport}
    //       />
    //     );
    //   case "chapter-I":
    //   case "chapter-II":
    //   case "chapter-III":
    //   case "chapter-IV":
    //   case "chapter-V":
    //     return <TabChapter reportLang={reportLang} chapters={reportContent} activeTab={activeTab} id={id} />;
    //   default:
    //     return <TabIndex reportLang={reportLang} chapter={reportContent} imageUrl={hotUrl} id={id} changeTab={this.changeTab} />;
    // }
  }

  handlePreviousTab = () => {
    const { id, activeTab } = this.state;

    // Nếu đang ở Mục lục thì hàm này không làm gì cả
    if (activeTab !== "index") {
      const { industryReportDetail } = this.props.industryInformationReducer;
      const { reportContent } = industryReportDetail;

      const romanOfCurrentTab = activeTab.slice(8);
      const indexOfCurrentReport = reportContent.findIndex((item) => item.chapterNo === romanOfCurrentTab);

      if (indexOfCurrentReport === 0) {
        // Khi mà ở chương 1, tab trước đó là tab Mục lục

        this.changeTab("index");
        // Thay đổi đường dẫn URl
        this.props.history.push(`${id}?tab=index`);
      } else if (indexOfCurrentReport === -1) {
        // Khi mà ở tab Báo cáo liên quan, tab trước đó là Chương cuối
        const reportContentLength = reportContent.length;
        if (reportContentLength) {
          const NoOfLastChapter = reportContent[reportContentLength - 1].chapterNo;
          this.changeTab(`chapter-${NoOfLastChapter}`);
          this.props.history.push(`${id}?tab=chapter-${NoOfLastChapter}`);
        } else {
          this.changeTab("index");
          this.props.history.push(`${id}?tab=index`);
        }
      } else {
        const indexOfPreviousReport = indexOfCurrentReport - 1;
        const romanOfPreviousReport = reportContent[indexOfPreviousReport].chapterNo;

        this.changeTab(`chapter-${romanOfPreviousReport}`);
        this.props.history.push(`${id}?tab=chapter-${romanOfPreviousReport}`);
      }

      this.reportInfoRef.current.scrollIntoView();
    } else {
      return;
    }
  };

  handleNextTab = () => {
    const { id, activeTab } = this.state;

    // Nếu đang ở tab Báo cáo liên quan thì hàm này không làm gì cả
    if (activeTab !== "related-report") {
      const { industryReportDetail } = this.props.industryInformationReducer;
      const { reportContent } = industryReportDetail;

      const romanOfCurrentTab = activeTab.slice(8);
      const indexOfCurrentReport = reportContent.findIndex((item) => item.chapterNo === romanOfCurrentTab);

      const indexOfNextReport = indexOfCurrentReport + 1;

      if (indexOfNextReport === reportContent.length) {
        // Khi mà ở chương cuối, tab Tiếp theo sẽ là Báo cáo liên quan

        this.changeTab("related-report");
        // Thay đổi đường dẫn URl
        this.props.history.push(`${id}?tab=related-report`);
      } else {
        const romanOfNextReport = reportContent[indexOfNextReport].chapterNo;

        this.changeTab(`chapter-${romanOfNextReport}`);
        this.props.history.push(`${id}?tab=chapter-${romanOfNextReport}`);
      }

      this.reportInfoRef.current.scrollIntoView();
    } else {
      return;
    }
  };

  render() {
    const { id, reportLang } = this.state;

    const language = localStorage.getItem("currentLanguage");
    const { loadingIndustryReportDetail, industryReportDetail } = this.props.industryInformationReducer;
    const { reportContent } = industryReportDetail;

    return (
      <React.Fragment>
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="company_info_views">
          <div className="industry-filter-results if-ir-home mb-5">
            <div className="card-item search-info-section">
              <IndustryFilter handleSearch={() => {}} {...this.props} />
            </div>
          </div>
          {<LoaderTable isLoaded={!loadingIndustryReportDetail} styleCustom={{ height: "300px" }} />}

          <div className="company_info_views " hidden={loadingIndustryReportDetail} ref={(el) => (this.componentRef = el)}>
            <div
              className="industry-report-detail if-ir-home _views_industry_detail"
              onMouseDown={() => {
                return false;
              }}
            >
              <div className="report-info" ref={this.reportInfoRef}>
                <div className="report-name">
                  <div>{reportLang === "en" && industryReportDetail.reportEName ? industryReportDetail.reportEName : industryReportDetail.reportName}</div>
                  <div className="download-report">
                    {/* <a href="#" className="text-cyan printOut d-block" onClick={() => this.downloadReport()}>
                      <em className="material-icons">download</em>
                      <IntlMessages id="industry.downloadReport" />
                    </a> */}
                  </div>
                </div>
                <div className="report-time">
                  <span className="material-icons">event_available</span>
                  {/* <IntlMessages id="detailCompany.Updated" />:  */}
                  {reportLang === "vi" ? "Cập nhật" : "Updated"}: {formatStringToTime(industryReportDetail.datePost)}
                </div>
                <div>{reportLang === "en" && industryReportDetail.enSummary ? industryReportDetail.enSummary : industryReportDetail.summary}</div>

                <div className="report-buy-download">
                  {reportLang === "en" && !industryReportDetail.enIsPurchased && <div className="buy-button">{this.buttonCart(industryReportDetail)}</div>}

                  {reportLang === "vi" && !industryReportDetail.isPurchased && <div className="buy-button">{this.buttonCart(industryReportDetail)}</div>}

                  {/* Nút tải Báo cáo/Demo */}
                  {reportLang === "vi" && <div className="download-button">{this.buttonViReportDownload(industryReportDetail)}</div>}
                  {reportLang === "en" && <div className="download-button">{this.buttonEnReportDownload(industryReportDetail)}</div>}
                </div>
              </div>

              <div className="report-content-container">
                <Nav>
                  {/* Phần Mục lục */}
                  <NavItem>
                    <Link
                      className={classnames({
                        active: this.state.activeTab === "index" || !this.state.activeTab,
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.changeTab("index");
                      }}
                      to={`${id}?tab=index`}
                    >
                      {/* <IntlMessages id="industry.report.menu" /> */}
                      {reportLang === "vi" ? "Mục lục" : "Index"}
                    </Link>
                  </NavItem>

                  {/* Phần: Các tab */}
                  {reportContent && reportContent.length
                    ? reportContent.map((props, key) => {
                        return (
                          <NavItem key={key}>
                            <Link
                              to={`${id}?tab=chapter-${props.chapterNo}`}
                              className={classnames({
                                active: this.state.activeTab === `chapter-${props.chapterNo}`,
                                "nav-link": true
                              })}
                              onClick={() => {
                                this.changeTab(`chapter-${props.chapterNo}`);
                              }}
                            >
                              {/* <IntlMessages id="industry.report.chapter" /> */}
                              {reportLang === "vi" ? "Chương" : "Chapter"} {props.chapterNo}
                            </Link>
                          </NavItem>
                        );
                      })
                    : ""}

                  {/* Phần Báo cáo liên quan */}
                  <NavItem>
                    <Link
                      to={`${id}?tab=related-report`}
                      className={classnames({
                        active: this.state.activeTab === "related-report",
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.changeTab("related-report");
                      }}
                    >
                      {/* <IntlMessages id="industry.statistic.tab.relatedReport" /> */}
                      {reportLang === "vi" ? "Báo cáo liên quan" : "Related report"}
                    </Link>
                  </NavItem>
                </Nav>
                <div className="report-language">
                  <div className="mr-3">
                    <IntlMessages id="newWord.selectLangReportTable" />:
                  </div>
                  <UncontrolledDropdown className="custom-dropdown-lang">
                    <DropdownToggle className="lang-btn" color="light" size="sm">
                      {reportLang === "vi" ? <img src={nav_head_lang_vn} alt="" className="img_fix_width_" /> : <img src={nav_head_lang_us} alt="" className="img_fix_width_" />}
                      <span className="material-icons">expand_more</span>
                    </DropdownToggle>
                    <DropdownMenu className="report-language-menu">
                      <DropdownItem onClick={() => this.handleChangeReportLang("en")} key={"en"}>
                        <img src={nav_head_lang_us} alt="" className="img_fix_width_" /> EN
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleChangeReportLang("vi")} key={"vi"}>
                        <img src={nav_head_lang_vn} alt="" className="img_fix_width_" /> VI
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>

              <div className="company-panel-content">
                <div className="main-content">
                  <span className="material-icons main-content-icon" onClick={this.handlePreviousTab}>
                    arrow_back_ios
                  </span>
                  {this.renderView()}
                  <span className="material-icons main-content-icon" onClick={this.handleNextTab}>
                    arrow_forward_ios
                  </span>
                </div>

                <div className="tab-direct">
                  <button className="common-button mr-4" onClick={this.handlePreviousTab}>
                    <IntlMessages id="industry.report.btn.previous_tab" />
                  </button>
                  <button className="common-button" onClick={this.handleNextTab}>
                    <IntlMessages id="industry.report.btn.next_tab" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    industryInformationReducer: state.industryInformationReducer,
    carts: state.cartReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actGetIndustryReportDetail: (reportId) => {
      dispatch(actGetIndustryReportDetail(reportId));
    },
    actGetRelatedReportAndCompany: (reportId) => {
      dispatch(actGetRelatedReportAndCompany(reportId));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IndustryReportDetail));
