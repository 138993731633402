import React, { Component } from "react";
import IntlMessages from "../../../utils/IntlMessages";
import CanvasJSReact from "../../../assets/js/canvasjs.react";
import { numberFormat_2 } from "../../../utils/numberFormat";
import { formatMoney_2 } from "../../../utils/formatMoney";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class PieChartOwner extends Component {
  constructor() {
    super();
    this.state = {
      dataChart: [],
      colorChart: [
        "#4661EE",
        "#EC5657",
        "#1BCDD1",
        "#8FAABB",
        "#B08BEB",
        "#3EA0DD",
        "#F5A52A",
        "#23BFAA",
        "#FAA586",
        "#EB8CC6",
        "#4661EE",
        "#EC5657",
        "#1BCDD1",
        "#8FAABB",
        "#B08BEB",
        "#3EA0DD",
        "#F5A52A",
        "#23BFAA",
        "#FAA586",
        "#EB8CC6"
      ]
    };
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }

  // ẩn hiện legend
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  render() {
    const { dataChartStructure, backgroundColor, showSourceEn, noSource, customFontColor } = this.props;
    let renderDataChartStructure = dataChartStructure.map((data) => {
      return {
        name: data.name,
        y: data.y,
        indexLabelFormatter: function(e) {
          return `${e.dataPoint.name}: ${formatMoney_2(e.dataPoint.y, 2)}%`;
        }
      };
    });
    const options = {
      animationEnabled: false,
      colorSet: "#4661EE",
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "transparent",
      height: 300,
      title: {
        fontFamily: "Roboto",
        fontSize: 20
      },
      indexLabel: {
        fontFamily: "Roboto"
      },
      legend: {
        fontColor: customFontColor ? customFontColor : "#B6C4DE",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 12,
        cursor: "pointer"
        // itemclick: this.toggleDataSeries,
        // itemWrap: true,
        // markerMargin: 10,
        // itemWidth: 250,
      },
      toolTip: {
        contentFormatter: function(e) {
          return `${e.entries[0].dataPoint.name}: ${formatMoney_2(e.entries[0].dataPoint.y, 2)}%`;
        }
      },
      data: [
        {
          type: "doughnut",
          innerRadius: "75%",
          // legendMarkerType: "square",
          radius: "100%",
          startAngle: 90,
          // legendText: "{name}",
          showInLegend: true,
          // toolTipContent: `{name}: {y}%`,
          indexLabelPlacement: "outside",
          indexLabel: "{name}: {y}%",
          indexLabelFontColor: customFontColor ? customFontColor : "#B6C4DE",
          indexLabelFontSize: 12,
          xValueFormatString: "####",
          yValueFormatString: "##,##0.##",
          dataPoints: dataChartStructure ? renderDataChartStructure : []
        }
      ]
    };
    return (
      <div className="column-multiple-chart-wrapper">
        <CanvasJSChart
          options={options}
          onRef={(ref) => (this.chart = ref)}
          //containerProps={{width: "80%", position: "relative"}}
        />
        {showSourceEn ? (
          <div>{noSource ? "" : <div className="cright">Source: VIRAC</div>}</div>
        ) : (
          <div>
            {noSource ? (
              ""
            ) : (
              <div className="cright">
                <IntlMessages id="common.sourceVirac" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PieChartOwner;
