import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { actSearchCompanyInfo, actSearchCompanyAutocomplete } from "../../redux/actions";
import CompanyInfoFillter from "../../components/CompanyInfo/CompanyInfoFillter";
import CompanyInfoContent from "../../components/CompanyInfo/CompanyInfoContent";
import paramFromData from "../../utils/paramFromData";
import getUrlParam from "../../utils/getUrlParam";
import queryString from "query-string";
import { DNOrderByVDL, DNOrderByTTS, DNOrderByLN, DNOrderByDT } from "../../constants/models";
import { debounce, some } from "lodash";
import LoaderTable from "../../components/LoaderTable";
import Findnotfound from "../../components/Findnotfound";
import { injectIntl } from "react-intl";
import Breadcrumbs from "../../constants/Breadcrumbs";
import SweetAlert from "react-bootstrap-sweetalert";

import { _Standard_, _Premium_, _Basic_ } from "../../constants/rolesModel";
import { Redirect } from "react-router-dom";

class CompanyInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // chưa dùng
      vdlOptionsVDL: DNOrderByVDL,
      vdlSelectedOptionsVDL: getUrlParam()["vdl"] ? getUrlParam()["vdl"] : DNOrderByVDL[0],
      vdlOptionsTTS: DNOrderByTTS,
      vdlSelectedOptionsTTS: getUrlParam()["tts"] ? getUrlParam()["tts"] : DNOrderByTTS[0],
      vdlOptionsLN: DNOrderByLN,
      vdlSelectedOptionsLN: getUrlParam()["ln"] ? getUrlParam()["ln"] : DNOrderByLN[0],
      vdlOptionsDT: DNOrderByDT,
      vdlSelectedOptionsDT: getUrlParam()["dt"] ? getUrlParam()["dt"] : DNOrderByDT[0],
      custom: { customVDL: { from: 0, to: 0 } },
      selectModelCustom: "",
      sortOwner: "",
      sortMinMax: "0",
      //

      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "",
      page: getUrlParam()["page"] ? parseInt(getUrlParam()["page"]) : 1,
      pageCountx: 1,
      isLoading: false,
      results: [],
      dataResults: [],
      value: "",
      listSuggest: [],
      txtsearchError: "",

      alert: ""
    };
  }

  handleResultSelect = (e, { result }) => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    if (roles && (roles === _Basic_ || roles === _Standard_)) {
      this.actAlert(messages[`newWord.upgradeAccount`]);
      return;
    } else {
      if (result) {
        this.setState({ value: result.title, txtsearch: result.title }, () => this.pushDetailCompany(result.id));
      }
    }
  };
  pushDetailCompany = (id) => {
    if (id !== null && id !== undefined) {
      this.props.history.push("/chi-tiet-doanh-nghiep/" + id);
    }
  };
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value, txtsearch: value }, () => this.requestAutocomplete(value));
  };

  requestAutocomplete = debounce((value) => {
    this.props.actSearchAutocomplete({ txtsearch: value });
  }, 500);
  fucResultFilter = (dataResults) => {
    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          isLoading: false,
          results: [],
          value: ""
        });
      this.setState({
        isLoading: false,
        results: dataResults
      });
    }, 500);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getListSuggest();
    if (this.props.companyReducer.searchAutoComplete.dataAutoComplete !== nextProps.companyReducer.searchAutoComplete.dataAutoComplete) {
      this.setState(
        {
          dataResults: nextProps.companyReducer.searchAutoComplete.dataAutoComplete
        },
        () => this.fucResultFilter(nextProps.companyReducer.searchAutoComplete.dataAutoComplete)
      );
    }
    // pageCount
    if (nextProps.companyReducer.pageCount) {
      this.setState({
        pageCountx: nextProps.companyReducer.pageCount
      });
    }
    if (nextProps.companyReducer.companyData && nextProps.companyReducer.companyData.length > 0) {
      var pageCurrent = getUrlParam()["page"];
      if (pageCurrent && pageCurrent !== undefined && pageCurrent !== null) {
        if (parseInt(pageCurrent) === parseInt(this.state.page)) {
          return;
        } else {
          this.setState(
            {
              page: pageCurrent
            },
            () => {
              this.handlePushPagingxx(pageCurrent);
            }
          );
        }
      }
    }
  }

  // end auto complete
  handlePathnamePush = (data) => {
    this.props.history.push(paramFromData(data));
  };

  handleSubmit = () => {
    this.handlePushPaging(1);
  };

  onKeyDown = (event) => {
    this.setState({
      ...this.state,
      txtsearchError: ""
    });
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };

  handleSortMinMax = (value, name) => {
    this.setState(
      {
        [name]: value
      },
      () => {
        this.handlePushPaging(1);
      }
    );
  };

  handlePushPagination = (page) => {
    this.setState(
      {
        page
      },
      () => {
        this.handlePushPaging(page);
      }
    );
  };

  handlePushPaging = (page) => {
    const { txtsearch, vdlSelectedOptionsVDL, vdlSelectedOptionsTTS, vdlSelectedOptionsLN, vdlSelectedOptionsDT, pageCountx, sortOwner, sortMinMax } = this.state;
    if (txtsearch.trim().length < 3) {
      this.setState({
        ...this.state,
        txtsearchError: "companyFilter.textInput.minLength"
      });
      return;
    }
    this.setState({
      ...this.state,
      txtsearchError: ""
    });
    var model = {
      page: page,
      q: txtsearch.trim(),
      vdl: vdlSelectedOptionsVDL.value,
      tts: vdlSelectedOptionsTTS.value,
      ln: vdlSelectedOptionsLN.value,
      dt: vdlSelectedOptionsDT.value,
      pageCount: pageCountx,
      ss: sortOwner,
      minmax: parseInt(sortMinMax)
    };
    var modelxx = {
      page: page,
      q: txtsearch.trim(),
      vdl:
        this.state.vdlSelectedOptionsVDL.value !== undefined
          ? vdlSelectedOptionsVDL.value
            ? vdlSelectedOptionsVDL.value
            : ""
          : this.state.vdlSelectedOptionsVDL
          ? this.state.vdlSelectedOptionsVDL
          : "",
      tts:
        this.state.vdlSelectedOptionsTTS.value !== undefined
          ? vdlSelectedOptionsTTS.value
            ? vdlSelectedOptionsTTS.value
            : ""
          : this.state.vdlSelectedOptionsTTS
          ? this.state.vdlSelectedOptionsTTS
          : "",
      ln: this.state.vdlSelectedOptionsLN.value !== undefined ? (vdlSelectedOptionsLN.value ? vdlSelectedOptionsLN.value : "") : this.state.vdlSelectedOptionsLN ? this.state.vdlSelectedOptionsLN : "",
      dt: this.state.vdlSelectedOptionsDT.value !== undefined ? (vdlSelectedOptionsDT.value ? vdlSelectedOptionsDT.value : "") : this.state.vdlSelectedOptionsDT ? this.state.vdlSelectedOptionsDT : ""
    };
    this.props.actSearchCompany(model);
    this.handlePathnamePush(modelxx);
  };

  handlePushPagingxx = (page) => {
    const { txtsearch, vdlSelectedOptionsVDL, vdlSelectedOptionsTTS, vdlSelectedOptionsLN, vdlSelectedOptionsDT, pageCountx } = this.state;
    var model = {
      page: page,
      q: txtsearch,
      vdl: vdlSelectedOptionsVDL.value,
      tts: vdlSelectedOptionsTTS.value,
      ln: vdlSelectedOptionsLN.value,
      dt: vdlSelectedOptionsDT.value,
      pageCount: pageCountx
    };
    this.props.actSearchCompany(model);
  };

  getListSuggest = () => {
    const idUser = this.props.userAccount.id || null;
    let listSuggest = !localStorage.getItem("aListSuggest") ? [] : null ? [] : JSON.parse(localStorage.getItem("aListSuggest"));
    let result = listSuggest.filter((item) => item.idUser === idUser);
    this.setState({
      listSuggest: (result[0] && result[0].arrSuggest) || []
    });
  };

  utilsSplitSelected = (paramsUrl) => {
    const { messages } = this.props.intl;
    var arr = paramsUrl.split(",");
    var objCustom = {
      value: `${arr[0]},${arr[1]}`,
      label: `${arr[0]} ${messages[`newWord.sufRangeInput`]} - ${arr[1]} ${messages[`newWord.sufRangeInput`]}`
    };
    return objCustom;
  };

  getValueSelected = () => {
    const { vdlOptionsVDL, vdlOptionsTTS, vdlOptionsLN, vdlOptionsDT } = this.state;
    var vdl = getUrlParam()["vdl"];
    var tts = getUrlParam()["tts"];
    var ln = getUrlParam()["ln"];
    var dt = getUrlParam()["dt"];
    let isCheckedVDL = some(vdlOptionsVDL, { value: vdl });
    let isCheckedTTS = some(vdlOptionsTTS, { value: tts });
    let isCheckedLN = some(vdlOptionsLN, { value: ln });
    let isCheckedDT = some(vdlOptionsDT, { value: dt });
    if (vdl) {
      let vdlSplit = this.utilsSplitSelected(vdl);
      if (vdl && !isCheckedVDL) {
        this.setState({
          vdlSelectedOptionsVDL: vdlSplit
        });
      }
      if (vdl && isCheckedVDL) {
        this.setState({
          vdlSelectedOptionsVDL: vdlOptionsVDL.find((x) => x.value === vdl)
        });
      }
    }
    if (tts) {
      let ttsSplit = this.utilsSplitSelected(tts);
      if (tts && !isCheckedTTS) {
        this.setState({
          vdlSelectedOptionsTTS: ttsSplit
        });
      }
      if (tts && isCheckedTTS) {
        this.setState({
          vdlSelectedOptionsTTS: vdlOptionsTTS.find((x) => x.value === tts)
        });
      }
    }
    if (ln) {
      let lnSplit = this.utilsSplitSelected(ln);
      if (ln && !isCheckedLN) {
        this.setState({
          vdlSelectedOptionsLN: lnSplit
        });
      }
      if (ln && isCheckedLN) {
        this.setState({
          vdlSelectedOptionsLN: vdlOptionsLN.find((x) => x.value === ln)
        });
      }
    }
    if (dt) {
      let dtSplit = this.utilsSplitSelected(dt);
      if (dt && !isCheckedDT) {
        this.setState({
          vdlSelectedOptionsDT: dtSplit
        });
      }
      if (dt && isCheckedDT) {
        this.setState({
          vdlSelectedOptionsDT: vdlOptionsDT.find((x) => x.value === dt)
        });
      }
    }
  };

  handleChangeInputFilter = (e) => {
    const label = e.target.name;
    const value = e.target.value;
    this.setState({
      [label]: value
    });
  };

  changeOrderBy = (e, value) => {
    let name = e.target.id;
    switch (name) {
      case "vdl":
        this.setState({
          vdlSelectedOptionsVDL: this.state.vdlOptionsVDL.find((x) => x.value === value)
        });
        break;
      case "tts":
        this.setState({
          vdlSelectedOptionsTTS: this.state.vdlOptionsTTS.find((x) => x.value === value)
        });
        break;
      case "ln":
        this.setState({
          vdlSelectedOptionsLN: this.state.vdlOptionsLN.find((x) => x.value === value)
        });
        break;
      case "dt":
        this.setState({
          vdlSelectedOptionsDT: this.state.vdlOptionsDT.find((x) => x.value === value)
        });
        break;
      default:
    }
  };

  modelCustomLimit = (e) => {
    const { selectModelCustom } = this.state;
    this.setState({
      ...this.state,
      custom: {
        ...this.state.custom,
        [selectModelCustom]: {
          ...this.state.custom[selectModelCustom],
          [e.target.name]: e.target.value
        }
      }
    });
  };

  customValueSelected(selectModelCustom) {
    const { messages } = this.props.intl;
    const { custom } = this.state;
    var objCustom = {
      value: `${custom[selectModelCustom].from},${custom[selectModelCustom].to}`,
      label: `${custom[selectModelCustom].from} ${messages[`newWord.sufRangeInput`]} - ${custom[selectModelCustom].to} ${messages[`newWord.sufRangeInput`]}`
    };
    return objCustom;
  }

  modelCustomLimitSubmit = () => {
    const { selectModelCustom } = this.state;
    const objCustom = this.customValueSelected(selectModelCustom);

    switch (selectModelCustom) {
      case "customVDL":
        this.setState({
          vdlSelectedOptionsVDL: objCustom
        });
        break;
      case "customTTS":
        this.setState({
          vdlSelectedOptionsTTS: objCustom
        });
        break;
      case "customDT":
        this.setState({
          vdlSelectedOptionsDT: objCustom
        });
        break;
      case "customLN":
        this.setState({
          vdlSelectedOptionsLN: objCustom
        });
        break;

      default:
    }
  };

  componentDidMount() {
    document.title = "VIRACE | Company Detail";

    // this.getValueSelected();
    if (getUrlParam()["q"] !== undefined || getUrlParam()["page"] !== undefined) {
      this.handleSubmit();
    }
  }

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    const {
      txtsearch,
      vdlOptionsVDL,
      vdlSelectedOptionsVDL,
      vdlOptionsTTS,
      vdlSelectedOptionsTTS,
      vdlOptionsLN,
      vdlSelectedOptionsLN,
      vdlOptionsDT,
      vdlSelectedOptionsDT,
      listSuggest,
      sortOwner,
      sortMinMax,
      txtsearchError
    } = this.state;
    const { loading, companyData } = this.props.companyReducer;
    const { roles } = this.props;

    const isRedirect = getUrlParam()["q"] !== undefined || getUrlParam()["page"] !== undefined ? true : false;

    return (
      <React.Fragment>
        {this.state.alert}
        {roles && roles === _Basic_ && <Redirect to="/trang-chu-if-ir" />}
        <div className="views_company_info">
          {/* <Breadcrumbs></Breadcrumbs> */}
          <div className="companyinfo-panel-content">
            <div className="background-navy search-info-section radius-5px card-item">
              <CompanyInfoFillter
                modelCustomLimit={this.modelCustomLimit}
                modelCustomLimitSubmit={this.modelCustomLimitSubmit}
                handleSubmit={this.handleSubmit}
                changeOrderBy={this.changeOrderBy}
                txtsearch={txtsearch}
                vdlOptionsVDL={vdlOptionsVDL}
                vdlSelectedOptionsVDL={vdlSelectedOptionsVDL}
                vdlOptionsTTS={vdlOptionsTTS}
                vdlSelectedOptionsTTS={vdlSelectedOptionsTTS}
                vdlOptionsLN={vdlOptionsLN}
                vdlSelectedOptionsLN={vdlSelectedOptionsLN}
                vdlOptionsDT={vdlOptionsDT}
                vdlSelectedOptionsDT={vdlSelectedOptionsDT}
                handleChangeInputFilter={this.handleChangeInputFilter}
                onKeyDown={this.onKeyDown}
                loading={loading}
                {...this.props}
                listSuggest={listSuggest}
                // autocomplete
                isLoading={this.state.isLoading}
                value={this.state.value}
                results={this.state.results}
                handleResultSelect={this.handleResultSelect}
                handleSearchChange={this.handleSearchChange}
                isRedirect={isRedirect}
                txtsearchError={txtsearchError}
              />
            </div>
            {!isRedirect ? (
              ""
            ) : companyData && companyData.length === 0 && !loading ? (
              <div className="card-item mt-4 mb-4">
                <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
              </div>
            ) : loading ? (
              <LoaderTable isLoaded={!loading} styleCustom={{ height: "70vh" }} />
            ) : companyData && companyData.length > 0 && !loading ? (
              <CompanyInfoContent
                {...this.props}
                sortOwner={sortOwner}
                sortMinMax={sortMinMax}
                handleSortMinMax={this.handleSortMinMax}
                handlePushPaging={this.handlePushPagination}
                txtsearch={txtsearch}
              />
            ) : (
              <Findnotfound />
            )}
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccount: state.accountReducer.userAccount,
    companyReducer: state.companyReducer,
    CommonReducer: state.CommonReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSearchCompany: (body) => {
      dispatch(actSearchCompanyInfo(body));
    },
    actSearchAutocomplete: (body) => {
      dispatch(actSearchCompanyAutocomplete(body));
    }
  };
};

const MyComponent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyInfoPage));
export default function CompanyInfo(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
