import React, { Component } from "react";
import { Table } from "reactstrap";
import { formatStringToTime } from "../../../utils/formatDate";
import { actGetHistoryAccessed } from "../../../redux/AccountManagement/actions";
import { connect } from "react-redux";
import Pagination from "../../../components/Pagination/PaginationSimple";
import LoaderTable from "../../../components/LoaderTable";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import AccessHistoryModal from "../../../components/Modals/AccessHistory/AccessHistoryModal";
import NoData from "../../../components/NoData";

export class TabContentAccessHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      page: 1,
      content: "",
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetHistoryAccess(page);
  }

  handleGetHistoryAccess = (page) => {
    this.props.handleGetHistoryAccessed(page);
  };
  handlePaginationChange = (e, { activePage }) => {
    this.setState(
      {
        page: activePage,
      },
      () => this.handleGetHistoryAccess(activePage)
    );
  };
  activeColorStatus = (status) => {
    if (status === "Success") {
      return <td style={{ color: "#2eb82e", fontWeight: 500 }}>{status}</td>;
    } else {
      return <td>{status}</td>;
    }
  };
  funcRenderAactions = (data) => {
    if (data && data.logs) {
      switch (data.actions) {
        case 3:
          return (
            <a
              className="text-cyan"
              rel="noopener noreferrer"
              href={"/chi-tiet-doanh-nghiep/" + data.rawRequest}
            >
              {data.logs}
            </a>
          );
        case 5:
          return (
            <a
              style={{ textDecoration: "none" }}
              href={"#a"}
              onClick={() => this.handleOpenNotiModal(data.logs)}
            >
              So sánh nhóm doanh nghiệp
            </a>
          );
        default:
          return <span>{data.logs}</span>;
      }
    } else {
      return <span>{data.logs}</span>;
    }
  };
  handleCloseNotiModal = () => {
    this.setState({
      openModal: false,
    });
  };
  runContentList = (data) => {
    this.setState({
      content: data,
    });
  };
  handleOpenNotiModal = (data) => {
    this.setState(
      {
        openModal: !this.state.openModal,
        content: "",
      },
      () => this.runContentList(data)
    );
  };
  render() {
    const {
      historyAccList,
      totalPage,
      loadingAccess,
    } = this.props.accountManagement;
    const { page, openModal } = this.state;
    return (
      <div className="manament-tab-accesshistory">
        {openModal && (
          <AccessHistoryModal
            openModal={openModal}
            handleCloseNotiModal={this.handleCloseNotiModal}
            content={this.state.content}
          />
        )}
        {!loadingAccess && historyAccList && historyAccList.length > 0 ? (
          <div className="table-custom">
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <IntlMessages id="table.sttTable" />
                  </th>
                  <th className="col-action">
                    <IntlMessages id="user.Action" />
                  </th>
                  <th>
                    <IntlMessages id="user.LogActivity" />
                  </th>
                  <th>
                    IP
                  </th>
                  <th className="col-time">
                    <IntlMessages id="user.Date" />
                  </th>
                  <th>
                    <IntlMessages id="user.Browser" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {historyAccList.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td><span>{((page-1)*10) + i + 1}</span></td>
                      <td className="col-action">
                        <span>{d.actionName ? d.actionName : ""}</span>
                      </td>
                      <td>{this.funcRenderAactions(d)}</td>
                      <td>
                        <span> {d.ip ? d.ip : ""}</span>
                      </td>
                      <td className="col-time">
                        <span>
                          {d.timesStamp ? formatStringToTime(d.timesStamp) : ""}
                        </span>
                      </td>
                      <td>
                        <span>{d.browser ? d.browser : ""}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : loadingAccess ? (
          <LoaderTable
            isLoaded={!loadingAccess}
            styleCustom={{ height: "500px" }}
          />
        ) : (
          <NoData />
        )}
        {totalPage > 1 && ( 
          <div
            className="foot-pagination text-right"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              totalPages={totalPage}
              activePage={page}
              onPageChange={this.handlePaginationChange}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountManagement: state.accountReducer.historyAccData,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleGetHistoryAccessed: (page) => dispatch(actGetHistoryAccessed(page)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TabContentAccessHistory));
