import { callApiServer } from "../utils/callApiServer";

export const actSearchCompanyInfo = (body) => {
  const params = {
    searchText: body.search,
    vdl: body.vdl,
    tts: body.tts,
    ln: body.ln,
    dt: body.dt,
    kv: body.kv,
    pageIndex: body.page,
    pageSize: body.pageSize,
    pageCount: body.pageCount,
    sort: body.ss,
    minmax: body.minmax,
    checkSameType: body.checkSameType
  };
  return callApiServer.get("/Company/SearchCompany_v2", { params: params });
};

export const actSearchCompanyInfoAutocomplete = (body) => {
  const params = {
    searchText: body.search.trim(),
    pageIndex: body.page,
    pageSize: body.pageSize
  };
  if (params.searchText.length < 3) {
    return;
  }
  return callApiServer.get("/Company/AutocompleteVsic_ES", { params: params });
};

export const actgetCompanyDetail = (model) => {
  let { id, type } = model;
  var params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi",
    type: type
  };
  return callApiServer.get("/Company/Company/" + id, { params: params });
};

export const actgetTopAccessLatest = () => {
  return callApiServer.get("/Company/RecentlyAccess");
};
export const actgetTopRevenua = () => {
  return callApiServer.get("/Company/TopNetRevenue");
};
export const actgetTop5RatioAccess = () => {
  return callApiServer.get("/Company/TopCompanyBySize");
};
export const actgetTop5RatioAccessByAll = () => {
  return callApiServer.get("/Company/AllTopCompanyBySize");
};

// danh gia tang truong
export const requestGetChartGrowthRate = (id) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/Company/CoCauTangTruong_Chart?lang=" + params.lang, { id });
};

// Thông tin xuất nhập khẩu
export const getDataColumnImportAndExport = (id) => {
  return callApiServer.get("/Company/CompanyImportExportTurnOver?companyId=" + id);
};
export const getDataPieImportAndExport = (id) => {
  return callApiServer.get("/Company/CompanyExportImportShareWeightRatio?companyId=" + id);
};
///

export const requestGetChartByType = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CoCauTangTruongTBN_Chart?lang=" + params.lang, body);
};

export const requestGrowthRateGetCode = (id) => {
  var params = {
    languages: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get(`/Company/CoCauTangTruong_Chart/${id}`, { params: params });
};
//chu so huu
export const requestGetDataLaw = (id) => {
  return callApiServer.get("/Company/Owner/" + id);
};

export const requestGetDataStructor = (id) => {
  var params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/Company/OwnerShip/" + id, { params: params });
};

export const requestGetDataCompanyRef = (id) => {
  var params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/Company/Member/" + id, { params: params });
};
//he so
export const requestFinancial = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/Company/HeSoTBN_Chart?lang=" + params.lang, body);
};
//he so ROA/ROE
export const requestROAROE = (body) => {
  return callApiServer.post("/Company/roa-roe-chart", body);
};
//he so Biên lợi nhuận thuần
export const requestNetProfit = (body) => {
  return callApiServer.post("/Company/net-profit-chart", body);
};

//-Danh sách chỉ tiêu - tab Chỉ tiêu
export const requestAccountMenu = (body) => {
  return callApiServer.post("/Company/account-menu", body);
};
//-Danh sách chỉ số- tab Chỉ số
export const requestRatioMenu = (body) => {
  return callApiServer.post("/Company/ratio-menu", body);
};

//-Data biểu đồ tab Chỉ tiêu và chỉ số
export const requestAccountRatioChart = (body) => {
  return callApiServer.post("/Company/account-ratio-chart", body);
};
export const requestFinancialGetCode = (id) => {
  var params = {
    languages: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get(`/Company/GetMenu_HeSo/${id}`, { params: params });
};
// --------- SO SÁNH TRUNG BÌNH NGÀNH ----------
// GET - DATA - COLUMN CHART

export const actGetDataNetProfitColumnChartApi = (model) => {
  return callApiServer.get("/Company/Get_NetProfit_Virac_GSO_Chart/", {
    params: model
  });
};

export const actGetMenuDataCompareApi = (id) => {
  var params = {
    languages: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/Company/GetMenu_Compare/" + id, { params: params });
};

// -----------//--------------------------
// chi tieu
export const requestLegalRepresentative = (id) => {
  return callApiServer.get("/Company/LegalRepresentative?id=" + id);
};
export const requestOwnerShipCompaies = (model) => {
  return callApiServer.get("/Company/OwnerShipCompaies", { params: model });
};

export const requestTarget = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  let data = JSON.stringify(body);
  return callApiServer.post("/Company/ChiTieuTBN_Chart?lang=" + params.lang, data);
};

export const requestTargetGetCode = (body) => {
  var params = {
    languges: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get(`/Company/GetMenu_ChiTieu/${body.id}`, { params: params });
};

// so sanh - doanh nghiep khac

//ss truc tiep
export const requestLiveChartDNK = (body) => {
  return callApiServer.post("/Company/SoSanhTrungBinhDoanhNghiep_TTCT", body);
};
export const requestLiveChartDNK_TB = (body) => {
  return callApiServer.post("/Company/SoSanhTrungBinhDoanhNghiep_TTCT", body);
};
export const requestLiveChartDNK_TGT = (body) => {
  return callApiServer.post("/Company/SoSanhTongGiaTri_TTCT", body);
};

export const requestLiveChartDNK_TB_GROUP = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CompareBusinesses/GroupCompany_TB_CompareChart?lang=" + params.lang, body);
};
export const requestLiveChartDNK_TGT_GROUP = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CompareBusinesses/GroupCompany_TGT_CompareChart?lang=" + params.lang, body);
};

// advance search
export const actAdvanceSearchCompanyByArea = (body) => {
  return callApiServer.post(`/Company/CompanyReportAdvancedSearch`, body);
};
export const actAdvanceSearchCompanyByIndustry = (body) => {
  return callApiServer.post(`/Company/CompanyReportAdvancedSearch`, body);
};
export const actAdvanceSearchCompanyByFinance = (body) => {
  return callApiServer.post(`/Company/CompanyReportAdvancedSearch`, body);
};

export const actGetFinancialCriteriaList = () => {
  return callApiServer.get("/Company/CompanyFinancialSearchList");
};
export const actGetDataPointList = () => {
  return callApiServer.get("/IFReportStatistic/GetCRTimeInputValue?dataType=FS");
};

// Báo cáo liên quan
export const actGetDataRelatedReportInCompanyDetail = (companyId) => {
  return callApiServer.get(`/IFReportStatistic/GetRelateReportInCompanyDetail?companyId=${companyId}`);
};
