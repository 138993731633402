import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actSearchCompanyInfo } from "../../../redux/actions";

class TabRelatedReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
        };
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return null;
    }

    render() {
        return (
            <div>
                tab related report
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyReducer: state.companyReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // fake
        actSearchCompany: (body) => {
            dispatch(actSearchCompanyInfo(body));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TabRelatedReport));