import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actGetProductList, actAdvanceStatisticIndustry, actAddToCart } from "../../../redux/actions";
import { Dropdown, Menu, Popup } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton";
import IntlMessages from "../../../utils/IntlMessages";
import { formatMoney } from "../../../utils/formatMoney";
import { Table } from "reactstrap";
import StatisticTableFilter from "../../../components/StatisticTableFilter/StatisticTableFilter";
import Pagination from "../../../components/Pagination/PaginationSimple";
import LoaderTable from "../../../components/LoaderTable";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import getUrlParam from "../../../utils/getUrlParam";
import formatNumber from "../../../utils/formatNumber";
import { advanceStatisticCriteria } from "../../../constants/industry";
import { copyArray } from "../../../utils/copyArray";
import FindnotfoundNoTitle from "../../../components/Findnotfound-noTitle";
import { callApiServer } from "../../../utils/callApiServer";
import { saveAs as importedSaveAs } from "file-saver";
import queryString from "query-string";
import { numberFormat } from "../../../utils/numberFormat";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart";
import { Workbook } from "exceljs/dist/exceljs.min.js";
import * as fs from "file-saver";
import * as moment from "moment";
import ConfirmPay from "../../AccountsManagement/ConfirmPayComponent";

class TabDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      selectedIndustry: [], // ds sản phẩm được chọn
      pageSize: 10,
      activePage: 1,
      sortConfig: {
        key: "",
        direction: ""
      },
      // txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "", // từ khóa tìm kiếm
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : getUrlParam()["productId"] ? queryString.parse(this.props.location.search).productId : "", // từ khóa tìm kiếm
      productId: getUrlParam()["productId"] ? queryString.parse(this.props.location.search).productId : "",
      statistic: getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [], // tiêu chí thống kê tìm kiếm
      statisticType: "TUYET_DOI", // tuyệt đối, tương đối, CAGR
      calculationType: "", // tổng, trung bình
      // filter khu vực
      isShowAreaFilter: false,
      isSearchArea: true,
      initFilterArea: [], // ds khu vực đang thống kê
      searchAreaFilterResults: [],
      txtSearchArea: "",
      checkedAllArea: true,
      indeterminateArea: false,
      selectedArea: [],
      isFirstInitBySearch: true,

      // filter mã hàng hóa
      isShowVsicFilter: false,
      isSearchVsic: true,
      initFilterVsic: [], // ds sản phẩm đang thống kê
      searchVsicFilterResults: [],
      txtSearchVsic: "",
      checkedAllVsic: true,
      indeterminateVsic: false,
      selectedVsic: [],

      // filter mô tả hàng hóa
      isShowVsicNameFilter: false,
      isSearchVsicName: true,
      txtSearchVsicName: "",

      // filter loại dữ liệu
      isShowTypeFilter: false,
      isSearchType: true,
      initFilterType: [], // ds type đang thống kê
      searchTypeFilterResults: [],
      txtSearchType: "",
      checkedAllType: true,
      indeterminateType: false,
      selectedType: []
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideFilter, true);
    const { selectedIndustry, statisticType, statistic } = this.state;
    this.statistic(statisticType, "", selectedIndustry, statistic, "", "");
    this.props.getProductList();
    const arr = [];
    statistic.forEach((i) => {
      const x = advanceStatisticCriteria.filter((b) => b.id === i);
      arr.push({
        value: i,
        isChecked: true,
        text: x[0].text ? x[0].text : "",
        enText: x[0].enText ? x[0].enText : ""
      });
    });
    this.setState({
      initFilterType: arr,
      searchTypeFilterResults: arr,
      selectedType: arr
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { filterVsicList, filterTypeList, initFilterVsic, filterAreaList, initFilterArea, statisticData } = nextProps.industryInformationReducer;
    // sản phẩm
    const arr1 = filterVsicList.map((i) => {
      return {
        value: i.productCode,
        vsicName: i.productName,
        vsicEName: i.productEName,
        isChecked: true
      };
    });
    this.setState({
      selectedVsic: arr1,
      filterVsicList
    });
    const arr2 = [];
    initFilterVsic.forEach((i) => {
      if (arr1.filter((x) => x.value === i.productCode).length) {
        arr2.push({
          value: i.productCode,
          vsicName: i.productName,
          vsicEName: i.productEnName,
          isChecked: true
        });
      } else {
        arr2.push({
          value: i.productCode,
          vsicName: i.productName,
          vsicEName: i.productEnName,
          isChecked: false
        });
      }
    });
    const { txtSearchVsic } = this.state;
    const arr3 = txtSearchVsic ? arr2.filter((i) => i.value.includes(txtSearchVsic)) : arr2;
    this.setState({
      initFilterVsic: arr2,
      searchVsicFilterResults: arr3
    });
    if (arr1.length === arr2.length && arr1.length > 0) {
      this.setState({
        indeterminateVsic: false,
        checkedAllVsic: true
      });
    } else if (arr1.length < arr2.length && arr1.length > 0) {
      this.setState({
        indeterminateVsic: true,
        checkedAllVsic: false
      });
    } else {
      this.setState({
        indeterminateVsic: false,
        checkedAllVsic: false
      });
    }

    // khu vực
    let arr4 = [];
    if (filterAreaList) {
      arr4 = filterAreaList.map((i) => {
        return {
          value: i.provincesId,
          isChecked: true,
          text: i.provinceName,
          enText: i.provinceEnName
        };
      });
    }
    this.setState({
      selectedArea: arr4,
      filterAreaList
    });
    const arr5 = [];
    if (initFilterArea) {
      initFilterArea.forEach((i) => {
        if (arr4.filter((x) => x.value === i.provincesId).length) {
          arr5.push({
            value: i.provincesId,
            isChecked: true,
            text: i.provinceName,
            enText: i.provinceEnName
          });
        } else {
          arr5.push({
            value: i.provincesId,
            isChecked: false,
            text: i.provinceName,
            enText: i.provinceEnName
          });
        }
      });
    }

    const { txtSearchArea } = this.state;
    const arr6 = txtSearchArea ? arr5.filter((i) => i.value.includes(txtSearchArea)) : arr5;
    this.setState({
      initFilterArea: arr5,
      searchAreaFilterResults: arr6
    });
    if (arr4.length === arr5.length && arr4.length > 0) {
      this.setState({
        indeterminateArea: false,
        checkedAllArea: true
      });
    } else if (arr4.length < arr5.length && arr4.length > 0) {
      this.setState({
        indeterminateArea: true,
        checkedAllArea: false
      });
    } else {
      this.setState({
        indeterminateArea: false,
        checkedAllArea: false
      });
    }

    // loại dl
    if (filterTypeList && filterTypeList.length) {
      this.setState({
        filterTypeList
      });
    }

    // Check nếu có data của bảng thì thêm hiệu ứng bay cho nút mua
    if (statisticData) {
      if (statisticData.length !== 0) {
        handleFlyBuyCart();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideFilter, true);
  }

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params ? params : messages[`newWord.alertSelectedxRatio`]}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  // statistic
  statistic = (dataType, calculationType, initFilterVsic, selectedType, sortColumn, sortDirection, isChangePage) => {
    const { activePage, pageSize, txtsearch, selectedVsic, selectedArea } = this.state;
    const { productList } = this.props.industryInformationReducer;

    const rq = {
      vsicStatisticKind: selectedType,
      textSearch: txtsearch,
      dataType,
      pageIndex: isChangePage ? activePage : 1,
      pageSize: pageSize,
      calculationType,
      productCode: initFilterVsic
    };

    if (selectedVsic.length) {
      // rq.productFilter = selectedVsic.map((i) => i.value);

      let productFilter = [];
      selectedVsic.forEach((item) => {
        if (item.isChecked) {
          productFilter.push({
            productCode: item.value,
            productEnName: item.vsicEName ? item.vsicEName : "",
            productName: item.vsicName ? item.vsicName : ""
          });
        } else {
          const product = productList.filter((i) => i.productCode === item.value)[0];
          if (product) {
            productFilter.push({
              productCode: product.productCode,
              productEnName: product.productEnName ? product.productEnName : "",
              productName: product.productName ? product.productName : ""
            });
          }
        }
      });

      rq.productFilter = productFilter;
    }
    if (selectedArea.length) {
      rq.areaFilter = selectedArea.map((i) => {
        return {
          provincesId: i.value,
          provinceName: i.text ? i.text : "",
          provinceEnName: i.enText ? i.enText : ""
        };
      });
    }
    if (sortColumn && sortDirection) {
      rq.predicate = sortColumn;
      rq.sortType = sortDirection;
    }
    this.props.actAdvanceStatisticIndustry(rq);
  };

  // chọn sản phẩm
  handleChangeIndustry = (value) => {
    this.setState({
      isFirstInitBySearch: false
    });
    this.setState({
      selectedIndustry: value
    });
  };

  // thêm sản phẩm để thống kê
  addIndustry = () => {
    let { selectedIndustry, statisticType, initFilterVsic, selectedType, selectedVsic } = this.state;
    const { messages } = this.props.intl;
    if (selectedIndustry.length > 0) {
      // Bỏ đi giới hạn 5 phần tử nhập liệu

      // if (selectedIndustry.length > 5) {
      //   this.actAlert(messages[`industry.statistic.add-product-max`]);
      // } else {
      // const vsicArr = [...initFilterVsic.map((i) => i.value), ...selectedIndustry];
      const vsicArr = [...selectedIndustry];

      const typeArr = selectedType.map((i) => i.value);
      const industry = selectedIndustry.map((i) => {
        return { value: i };
      });
      this.setState(
        {
          // selectedVsic: [...selectedVsic, ...industry],
          selectedVsic: [...industry],

          calculationType: ""
        },
        () => {
          this.statistic(statisticType, "", vsicArr, typeArr, "", "");
        }
      );
      // }
    }
  };

  // Chọn loại thống kê
  handleChangeType = (value) => {
    this.setState({
      statisticType: value,
      calculationType: "",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic(value, "", vsicArr, typeArr, "", "");
  };

  // nhấn nút Tổng
  total = () => {
    this.setState({
      calculationType: "TONG",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic("TUYET_DOI", "TONG", vsicArr, typeArr, "", "");
  };

  // nhấn nút Trung bình
  average = () => {
    this.setState({
      calculationType: "TRUNG_BINH",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic("TUYET_DOI", "TRUNG_BINH", vsicArr, typeArr, "", "");
  };

  // Nhấn Tuyệt đối
  relative = () => {
    this.setState({
      calculationType: "",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic("TUYET_DOI", "", vsicArr, typeArr, "", "");
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => {
      const { statisticType, calculationType, initFilterVsic, selectedType, sortConfig } = this.state;
      const type = statisticType === "TUYET_DOI" ? calculationType : "";
      const vsicArr = initFilterVsic.map((i) => i.value);
      const typeArr = selectedType.map((i) => i.value);
      this.statistic(statisticType, type, vsicArr, typeArr, sortConfig.key, sortConfig.direction.toUpperCase(), true);
    });
  };

  // sort ở th
  handleSort = (name) => {
    this.setState({
      isShowVsicFilter: false,
      isShowTypeFilter: false,
      activePage: 1
    });
    const { statisticType, calculationType, initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    if (this.state.sortConfig.key !== name) {
      this.setState(
        {
          sortConfig: {
            key: name,
            direction: "asc"
          }
        },
        () => {
          this.statistic(statisticType, calculationType, vsicArr, typeArr, name, "ASC");
        }
      );
    } else {
      let direction = this.state.sortConfig.direction;
      if (direction === "" || direction === "desc") {
        direction = "asc";
      } else {
        direction = "desc";
      }
      this.setState(
        {
          sortConfig: {
            key: name,
            direction
          }
        },
        () => {
          this.statistic(statisticType, calculationType, vsicArr, typeArr, name, direction.toUpperCase());
        }
      );
    }
  };

  // add class khi sort
  getClassNamesFor = (name) => {
    const { sortConfig } = this.state;
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? "sorted " + sortConfig.direction : "";
  };

  // sort trong filter
  handleSortFilter = (direction, key) => {
    this.setState({
      sortConfig: {
        key,
        direction
      },
      isShowVsicFilter: false,
      isShowVsicNameFilter: false,
      isShowAreaFilter: false,

      isShowTypeFilter: false,
      activePage: 1
    });
    const { statisticType, calculationType, initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic(statisticType, calculationType, vsicArr, typeArr, key, direction.toUpperCase());
  };

  // xóa bộ lọc
  clearFilter = (name) => {
    if (name === "AREA") {
      const initFilterArea = copyArray(this.state.initFilterArea);
      initFilterArea.forEach((i) => (i.isChecked = true));
      this.setState(
        {
          searchAreaFilterResults: copyArray(initFilterArea),
          selectedArea: copyArray(initFilterArea),
          txtSearchArea: "",

          isShowAreaFilter: false,
          indeterminateArea: false,
          checkedAllArea: true
        },
        () => {
          this.applyFilter(name);
        }
      );
    } else if (name === "PRODUCT_ID" || name === "PRODUCT_NAME") {
      const initFilterVsic = copyArray(this.state.initFilterVsic);
      initFilterVsic.forEach((i) => (i.isChecked = true));
      this.setState(
        {
          searchVsicFilterResults: copyArray(initFilterVsic),
          selectedVsic: copyArray(initFilterVsic),
          txtSearchVsic: "",
          txtSearchVsicName: "",

          isShowVsicFilter: false,
          indeterminateVsic: false,
          checkedAllVsic: true
        },
        () => {
          this.applyFilter(name);
        }
      );
    } else if (name === "DATA_TYPE") {
      const initFilterType = copyArray(this.state.initFilterType);
      initFilterType.forEach((i) => (i.isChecked = true));
      this.setState(
        {
          searchTypeFilterResults: copyArray(initFilterType),
          selectedType: copyArray(initFilterType),
          txtSearchType: "",
          isShowTypeFilter: false,
          indeterminateType: false,
          checkedAllType: true
        },
        () => {
          this.applyFilter(name);
        }
      );
    }
  };

  // áp dụng bộ lọc
  applyFilter = (name) => {
    const { messages } = this.props.intl;
    const { statisticType, calculationType, initFilterVsic, selectedVsic, selectedType, selectedArea, sortConfig } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    if (name === "AREA") {
      this.setState({
        isShowAreaFilter: false,
        activePage: 1
      });
    } else if (name === "PRODUCT_ID" || name === "PRODUCT_NAME") {
      this.setState({
        isShowVsicFilter: false,
        isShowVsicNameFilter: false,

        activePage: 1
      });
    } else if (name === "DATA_TYPE") {
      this.setState({
        isShowTypeFilter: false,
        activePage: 1
      });
    }
    if (selectedVsic.length === 0) {
      this.actAlert(messages[`industry.statistic.chart.product-min`]);
    } else if (typeArr.length === 0) {
      this.actAlert(messages[`industry.statistic.chart.statistic-min`]);
    } else if (selectedArea.length === 0) {
      this.actAlert(messages[`industry.statistic.chart.area-min`]);
    } else {
      this.statistic(statisticType, calculationType, vsicArr, typeArr, sortConfig.key, sortConfig.direction.toUpperCase());
    }
  };

  // hủy bộ lọc
  cancelFilter = (name) => {
    const { filterTypeList, searchTypeFilterResults, filterVsicList, searchVsicFilterResults, filterAreaList, searchAreaFilterResults } = this.state;
    if (name === "AREA") {
      searchAreaFilterResults.forEach((i) => {
        if (filterAreaList && filterAreaList.length) {
          if (filterAreaList.filter((j) => i.value === j.provincesId).length) {
            i.isChecked = true;
          } else {
            i.isChecked = false;
          }
        } else {
          i.isChecked = true;
        }
      });
      const arr = copyArray(searchAreaFilterResults).filter((i) => i.isChecked);
      this.setState({
        isShowAreaFilter: false,
        searchAreaFilterResults,
        selectedArea: arr,
        checkedAllArea: searchAreaFilterResults.length === arr.length,
        indeterminateArea: searchAreaFilterResults.length !== arr.length && arr.length !== 0
      });
    } else if (name === "PRODUCT_ID" || name === "PRODUCT_NAME") {
      searchVsicFilterResults.forEach((i) => {
        if (filterVsicList && filterVsicList.length) {
          if (filterVsicList.filter((j) => i.value === j.productCode).length) {
            i.isChecked = true;
          } else {
            i.isChecked = false;
          }
        } else {
          i.isChecked = true;
        }
      });
      const arr = copyArray(searchVsicFilterResults).filter((i) => i.isChecked);
      this.setState({
        isShowVsicFilter: false,
        isShowVsicNameFilter: false,

        searchVsicFilterResults,
        selectedVsic: arr,
        checkedAllVsic: searchVsicFilterResults.length === arr.length,
        indeterminateVsic: searchVsicFilterResults.length !== arr.length && arr.length !== 0
      });
    } else if (name === "DATA_TYPE") {
      searchTypeFilterResults.forEach((i) => {
        if (filterTypeList.includes(i.value)) {
          i.isChecked = true;
        } else {
          i.isChecked = false;
        }
      });
      const arr = copyArray(searchTypeFilterResults).filter((i) => i.isChecked);
      this.setState({
        isShowTypeFilter: false,
        searchTypeFilterResults,
        selectedType: arr,
        checkedAllType: searchTypeFilterResults.length === arr.length,
        indeterminateType: searchTypeFilterResults.length !== arr.length && arr.length !== 0
      });
    }
  };

  // filter khu vực
  handleFilterArea = () => {
    this.setState({
      isShowAreaFilter: !this.state.isShowAreaFilter,
      isShowTypeFilter: false,
      isShowVsicFilter: false
    });
  };

  // tìm kiếm khu vực
  handleInputChangeArea = (event) => {
    const txtSearchArea = event.target.value;
    const initFilterArea = copyArray(this.state.initFilterArea);
    initFilterArea.forEach((i) => (i.isChecked = true));
    const language = localStorage.getItem("currentLanguage");
    this.setState({
      isSearchArea: true,
      txtSearchArea,
      checkedAllArea: true,
      indeterminateArea: false
    });
    if (txtSearchArea.trim() === "") {
      this.setState({
        searchAreaFilterResults: copyArray(initFilterArea),
        selectedArea: copyArray(initFilterArea)
      });
    } else {
      const searchAreaFilterResults = copyArray(initFilterArea).filter((i) =>
        language === "en" ? i.enText.toUpperCase().includes(txtSearchArea.toUpperCase()) : i.text.toUpperCase().includes(txtSearchArea.toUpperCase())
      );
      this.setState({
        searchAreaFilterResults,
        selectedArea: copyArray(searchAreaFilterResults)
      });
    }
  };

  // check all khu vực
  handleCheckedAllArea = () => {
    let { searchAreaFilterResults, checkedAllArea } = this.state;
    searchAreaFilterResults.forEach((e) => {
      e.isChecked = !checkedAllArea ? true : false;
    });
    this.setState({
      checkedAllArea: !checkedAllArea,
      searchAreaFilterResults,
      indeterminateArea: false,
      selectedArea: !checkedAllArea ? copyArray(searchAreaFilterResults) : []
    });
  };

  // check 1 khu vực
  handleCheckedArea = (data) => {
    let { selectedArea, searchAreaFilterResults } = this.state;
    if (data.isChecked) {
      selectedArea = selectedArea.filter((i) => i.value !== data.value);
    } else {
      selectedArea.push(data);
    }
    searchAreaFilterResults.forEach((e) => {
      if (e.value === data.value) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      checkedAllArea: searchAreaFilterResults.length === selectedArea.length,
      indeterminateArea: searchAreaFilterResults.length !== selectedArea.length && selectedArea.length !== 0,
      searchAreaFilterResults,
      selectedArea
    });
  };

  // filter mã vsic
  handleFilterVsic = () => {
    this.setState({
      isShowVsicFilter: !this.state.isShowVsicFilter,
      isShowVsicNameFilter: false,
      isShowTypeFilter: false,
      isShowAreaFilter: false
    });
  };

  // tìm kiếm mã vsic
  handleInputChangeVsic = (event) => {
    const txtSearchVsic = event.target.value;
    const initFilterVsic = copyArray(this.state.initFilterVsic);
    initFilterVsic.forEach((i) => (i.isChecked = true));
    this.setState({
      isSearchVsic: true,
      txtSearchVsic,
      checkedAllVsic: true,
      indeterminateVsic: false
    });
    if (txtSearchVsic.trim() === "") {
      this.setState({
        searchVsicFilterResults: copyArray(initFilterVsic),
        selectedVsic: copyArray(initFilterVsic)
      });
    } else {
      const searchVsicFilterResults = copyArray(initFilterVsic).filter((i) => i.value.includes(txtSearchVsic));
      this.setState({
        searchVsicFilterResults,
        selectedVsic: copyArray(searchVsicFilterResults)
      });
    }
  };

  // check all vsic
  handleCheckedAllVsic = () => {
    let { searchVsicFilterResults, checkedAllVsic } = this.state;
    searchVsicFilterResults.forEach((e) => {
      e.isChecked = !checkedAllVsic ? true : false;
    });
    this.setState({
      checkedAllVsic: !checkedAllVsic,
      searchVsicFilterResults,
      indeterminateVsic: false,
      selectedVsic: !checkedAllVsic ? copyArray(searchVsicFilterResults) : []
    });
  };

  // check 1 vsic
  handleCheckedVsic = (data) => {
    let { selectedVsic, searchVsicFilterResults } = this.state;
    if (data.isChecked) {
      selectedVsic = selectedVsic.filter((i) => i.value !== data.value);
    } else {
      selectedVsic.push(data);
    }
    searchVsicFilterResults.forEach((e) => {
      if (e.value === data.value) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      checkedAllVsic: searchVsicFilterResults.length === selectedVsic.length,
      indeterminateVsic: searchVsicFilterResults.length !== selectedVsic.length && selectedVsic.length !== 0,
      searchVsicFilterResults,
      selectedVsic
    });
  };

  // filter Mô tả hàng hóa
  handleFilterVsicName = () => {
    this.setState({
      isShowVsicNameFilter: !this.state.isShowVsicNameFilter,
      isShowVsicFilter: false,
      isShowTypeFilter: false,
      isShowAreaFilter: false
    });
  };

  // tìm kiếm Mô tả hàng hóa
  handleInputChangeVsicName = (event) => {
    const language = localStorage.getItem("currentLanguage");

    const txtSearchVsicName = event.target.value;

    const initFilterVsic = copyArray(this.state.initFilterVsic);

    initFilterVsic.forEach((i) => (i.isChecked = true));
    this.setState({
      isSearchVsicName: true,
      txtSearchVsicName,
      checkedAllVsic: true,
      indeterminateVsic: false
    });
    if (txtSearchVsicName.trim() === "") {
      this.setState({
        searchVsicFilterResults: copyArray(initFilterVsic),
        selectedVsic: copyArray(initFilterVsic)
      });
    } else {
      const searchVsicFilterResults = copyArray(initFilterVsic).filter((i) => (language === "vi" ? i.vsicName.includes(txtSearchVsicName) : i.vsicEName.includes(txtSearchVsicName)));
      this.setState({
        searchVsicFilterResults,
        selectedVsic: copyArray(searchVsicFilterResults)
      });
    }
  };

  // filter loại dữ liệu
  handleFilterType = () => {
    this.setState({
      isShowTypeFilter: !this.state.isShowTypeFilter,
      isShowVsicFilter: false,
      isShowAreaFilter: false
    });
  };

  // tìm kiếm loại dữ liệu
  handleInputChangeType = (event) => {
    const txtSearchType = event.target.value;
    const initFilterType = copyArray(this.state.initFilterType);
    initFilterType.forEach((i) => (i.isChecked = true));
    const language = localStorage.getItem("currentLanguage");
    this.setState({
      isSearchType: true,
      txtSearchType,
      checkedAllType: true,
      indeterminateType: false
    });
    if (txtSearchType.trim() === "") {
      this.setState({
        searchTypeFilterResults: copyArray(initFilterType),
        selectedType: copyArray(initFilterType)
      });
    } else {
      const searchTypeFilterResults = copyArray(initFilterType).filter((i) =>
        language === "en" ? i.enText.toUpperCase().includes(txtSearchType.toUpperCase()) : i.text.toUpperCase().includes(txtSearchType.toUpperCase())
      );
      this.setState({
        searchTypeFilterResults,
        selectedType: copyArray(searchTypeFilterResults)
      });
    }
  };

  // check all type
  handleCheckedAllType = () => {
    let { searchTypeFilterResults, checkedAllType } = this.state;
    searchTypeFilterResults.forEach((e) => {
      e.isChecked = !checkedAllType ? true : false;
    });
    this.setState({
      checkedAllType: !checkedAllType,
      searchTypeFilterResults,
      indeterminateType: false,
      selectedType: !checkedAllType ? copyArray(searchTypeFilterResults) : []
    });
  };

  // check 1 type
  handleCheckedType = (data) => {
    let { selectedType, searchTypeFilterResults } = this.state;
    if (data.isChecked) {
      selectedType = selectedType.filter((i) => i.value !== data.value);
    } else {
      selectedType.push(data);
    }
    searchTypeFilterResults.forEach((e) => {
      if (e.value === data.value) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      checkedAllType: searchTypeFilterResults.length === selectedType.length,
      indeterminateType: searchTypeFilterResults.length !== selectedType.length && selectedType.length !== 0,
      searchTypeFilterResults,
      selectedType
    });
  };

  handleClickOutsideFilter = (event) => {
    const domNode = document.getElementById("statistic-table-fillter");
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isShowTypeFilter: false,
        isShowVsicFilter: false
      });
    }
  };

  // Add item to cart
  handleBuyIndustry = (data) => {
    let model = {
      id: data.productCode,
      name: data.productName,
      englishName: data.productEnName,
      requestData: data.productName,
      price: data.price,
      point: data.point ? data.point : 0,
      currency: "VND",
      viracServiceId: 4, // báo cáo từ thống kê nâng cao
      type: 4,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyIndustry(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data)}>
          <span className={"cart-icon material-icons text-cyan"}>shopping_cart</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";

    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.productCode).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  handleBuyReport(data) {
    const language = localStorage.getItem("currentLanguage");

    let point = data.point;
    let price = data.price;

    this.openPopupConfirmBuy(point, price, "VsicProduct", 0, data);
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");

    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.productCode}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            reportLang={language}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }
  // Tìm kiếm theo cả code và label text
  handleSearchListStatistic = (options, data) => {
    if (data) {
      return options.filter((item) => item.text.toLowerCase().includes(data.toLowerCase()) || item.label.toLowerCase().includes(data.toLowerCase()));
    }
  };

  // Export dữ liệu ra file excel
  exportData = () => {
    const language = localStorage.getItem("currentLanguage");
    const date = moment().format("DDMMYYYY");

    const { messages } = this.props.intl;
    const { statisticData, pageIndex } = this.props.industryInformationReducer;

    const { calculationType } = this.state;
    const title = "Statistic_" + date;

    // Thêm các  cột theo trạng thái hiện có
    let header = [messages["table.sttTable"], messages["advanceSearch.area.area"], messages["industry.statistic.product-code"]];
    if (!["TONG", "TRUNG_BINH"].includes(calculationType)) {
      header.push(messages["industry.statistic.product-des"]);
    }
    header.push(messages["industry.statistic.table.type"], messages["unit"]);
    statisticData[0].vsicStatisticValues.map((item, index) => {
      if (item.year === "CAGR") {
        header.push(messages["industry.statistic.typeList.cagr"]);
      } else {
        header.push(item.year);
      }
    });

    // Thêm các dữ liệu theo các cột hiện có
    let bodyData = [];
    statisticData.map((props, index) => {
      bodyData.push([(pageIndex - 1) * 10 + index + 1, language === "en" && props.enArea ? props.enArea : props.area, props.productCode]);
      if (!["TONG", "TRUNG_BINH"].includes(calculationType)) {
        bodyData[index].push(language === "en" && props.productEnName ? props.productEnName : props.productName);
      }
      bodyData[index].push(messages[`industry.statistic.${props.vsicDataKindCode}`], language === "en" && props.vsicEUnit ? props.vsicEUnit : props.vsicUnit);
      props.vsicStatisticValues.map((item, i) => {
        bodyData[index].push(props.isPurchased === false || item.value === null ? "N/A" : props.vsicUnit === "%" ? numberFormat(item.value) : formatMoney(parseInt(item.value, 10)));
      });
    });

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title, {
      properties: { useStyles: true, defaultRowHeight: 27 }
    });
    worksheet.addRow([]);
    const headerRow = worksheet.addRow(header);
    headerRow.font = { bold: true };
    bodyData.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns = [
      {
        width: 7
      },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 }
    ];

    worksheet.getColumn(1).alignment = {
      horizontal: "center"
    };
    // Generate excel file with given name
    workbook.xlsx.writeBuffer().then((res) => {
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      fs.saveAs(blob, "Statistic_" + date + ".xlsx");
    });
  };
  async getExcel() {
    try {
      const date = moment().format("DDMMYYYY");
      const fileName = "Statistic_" + date + ".xlsx";
      const urlDownload = `/IFReportStatistic/download-vsic-statistic-advanced-export`;
      const language = localStorage.getItem("currentLanguage");
      const body = JSON.parse(localStorage.getItem("currentSearchBodyAdvance"));
      body.lang = language;
      const response = await callApiServer.post(urlDownload, body, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
      } else {
      }
    } catch (error) {
      console.log("err", error);
    }
  }
  render() {
    const language = localStorage.getItem("currentLanguage");
    const { messages } = this.props.intl;
    const {
      isShowVsicFilter,
      isShowVsicNameFilter,

      isShowTypeFilter,
      isSearchVsic,
      isSearchVsicName,

      searchVsicFilterResults,
      txtSearchVsic,
      txtSearchVsicName,

      sortConfig,
      checkedAllVsic,
      indeterminateVsic,
      isSearchType,
      searchTypeFilterResults,
      txtSearchType,
      checkedAllType,
      indeterminateType,
      selectedIndustry,
      txtsearch,
      productId,
      calculationType,
      isSearchArea,
      txtSearchArea,
      searchAreaFilterResults,
      checkedAllArea,
      indeterminateArea,
      isShowAreaFilter,
      isFirstInitBySearch
    } = this.state;
    const statisticTypeList = [
      {
        value: "TUYET_DOI",
        key: "TUYET_DOI",
        text: messages["industry.statistic.typeList.absolute"]
      },
      {
        value: "TUONG_DOI",
        key: "TUONG_DOI",
        text: messages["industry.statistic.typeList.relative"]
      },
      {
        value: "CAGR",
        key: "CAGR",
        text: messages["industry.statistic.typeList.cagr"]
      }
    ];
    const { statisticLoading, statisticData, totalPage, pageIndex, productList, initFilterVsic } = this.props.industryInformationReducer;
    const { activePage, statisticType } = this.state;
    const products = [];
    productList.forEach((e) => {
      products.push({
        key: e.productCode,
        text: e.productCode,
        value: e.productCode,
        label: language === "en" && e.productEnName ? " - " + e.productEnName : " - " + e.productName
      });
    });
    let arrDefault = [];
    initFilterVsic.forEach((item) => {
      arrDefault.push(item.productCode);
    });

    return (
      <div className="statistic-data-table selected-custom-select card-item">
        {this.state.alert}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="row mx-lg-0 mb-3">
          <div className="col-lg-8 col-xl-7 pl-lg-0">
            <div className="d-flex">
              <Menu compact className="statistic-type" style={{ width: "220px" }}>
                <Dropdown fluid selection options={statisticTypeList} defaultValue={"TUYET_DOI"} onChange={(e, { value }) => this.handleChangeType(value)} style={{ width: "220px" }} />
              </Menu>
              <Button className={`common-button ml-3`} onClick={() => this.total()} disabled={statisticType !== "TUYET_DOI"}>
                <IntlMessages id="industry.statistic.total" />
              </Button>
              <Button className={`common-button ml-3`} onClick={() => this.average()} disabled={statisticType !== "TUYET_DOI"}>
                <IntlMessages id="industry.statistic.average" />
              </Button>
              <Button className={`common-button ml-3`} style={{ minWidth: "44px", width: "44px" }} onClick={() => this.relative()} disabled={statisticType !== "TUYET_DOI"}>
                <span className={"cart-icon material-icons"} style={{ marginLeft: "0" }}>
                  refresh
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="row mx-lg-0 mb-2">
          <div className="col-lg-12 col-xl-12 p-0">
            <div className="d-flex align-items-center">
              {!statisticLoading && (
                <React.Fragment>
                  <Menu compact className="w-100 mr-3 dropdown-vsic-list statistic-dropdown">
                    <Dropdown
                      placeholder={messages["industry.statistic.product"]}
                      fluid
                      multiple
                      search={this.handleSearchListStatistic}
                      selection
                      options={products}
                      noResultsMessage={messages["search.notfound.text"]}
                      defaultValue={!txtsearch && isFirstInitBySearch ? (productId ? [productId] : []) : [...arrDefault]}
                      onChange={(e, { value }) => this.handleChangeIndustry(value)}
                    />
                  </Menu>
                  <span className={`material-icons text-cyan ${selectedIndustry.length === 0 ? "add-vsic-disabled" : ""}`} onClick={this.addIndustry}>
                    search
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div>
          {statisticLoading ? (
            <LoaderTable isLoaded={!statisticLoading} styleCustom={{ height: "500px" }} />
          ) : (
            <div className={`table-custom ${isShowVsicFilter || isShowTypeFilter || isShowAreaFilter ? "show-filter" : ""}`}>
              {statisticData && statisticData.length > 0 && (
                <div>
                  <div
                    className="btn-export-data text-cyan d-flex"
                    style={{ width: "fit-content", maxHeight: "30px", float: "right", flexDirection: "row-reverse", margin: "0px 10px 15px 0px" }}
                    onClick={() => {
                      this.getExcel();
                    }}
                  >
                    <IntlMessages id="newWord.downloadStatisticResult" />
                    <em className="material-icons">download</em>
                  </div>
                  <Table responsive className="sortable">
                    <thead>
                      {!["TONG", "TRUNG_BINH"].includes(calculationType) && (
                        <tr>
                          <th className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", left: "0px", minWidth: "50px", width: "50px" }}>
                            <IntlMessages id="table.sttTable" />
                          </th>

                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "50px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterVsic()}>
                              <IntlMessages id="industry.statistic.product-code" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowVsicFilter && (
                              <StatisticTableFilter
                                type="PRODUCT_ID"
                                isSearch={isSearchVsic}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "PRODUCT_ID")}
                                clearFilter={() => this.clearFilter("PRODUCT_ID")}
                                applyFilter={() => this.applyFilter("PRODUCT_ID")}
                                cancelFilter={() => this.cancelFilter("PRODUCT_ID")}
                                txtSearch={txtSearchVsic}
                                handleInputChange={this.handleInputChangeVsic}
                                searchResults={searchVsicFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllVsic}
                                handleCheckedItem={this.handleCheckedVsic}
                                checkedAllItems={checkedAllVsic}
                                indeterminateItem={indeterminateVsic}
                                activeSort={sortConfig.key === "PRODUCT_ID" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>

                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "200px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterVsicName()}>
                              <IntlMessages id="industry.statistic.product-des" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowVsicNameFilter && (
                              <StatisticTableFilter
                                type="PRODUCT_NAME"
                                isSearch={isSearchVsicName}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "PRODUCT_NAME")}
                                clearFilter={() => this.clearFilter("PRODUCT_NAME")}
                                applyFilter={() => this.applyFilter("PRODUCT_NAME")}
                                cancelFilter={() => this.cancelFilter("PRODUCT_NAME")}
                                txtSearch={txtSearchVsicName}
                                handleInputChange={this.handleInputChangeVsicName}
                                searchResults={searchVsicFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllVsic}
                                handleCheckedItem={this.handleCheckedVsic}
                                checkedAllItems={checkedAllVsic}
                                indeterminateItem={indeterminateVsic}
                                activeSort={sortConfig.key === "PRODUCT_NAME" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th className="filter p-0 fixed-column" style={{ minWidth: "100px", width: "100px", left: "350px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterArea()}>
                              <IntlMessages id="advanceSearch.area.area" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowAreaFilter && (
                              <StatisticTableFilter
                                type="AREA"
                                isSearch={isSearchArea}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "AREA")}
                                clearFilter={() => this.clearFilter("AREA")}
                                applyFilter={() => this.applyFilter("AREA")}
                                cancelFilter={() => this.cancelFilter("AREA")}
                                txtSearch={txtSearchArea}
                                handleInputChange={this.handleInputChangeArea}
                                searchResults={searchAreaFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllArea}
                                handleCheckedItem={this.handleCheckedArea}
                                checkedAllItems={checkedAllArea}
                                indeterminateItem={indeterminateArea}
                                activeSort={sortConfig.key === "AREA" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th className="filter p-0 fixed-column" style={{ minWidth: "150px", width: "150px", left: "450px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterType()}>
                              <IntlMessages id="industry.statistic.table.type" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowTypeFilter && (
                              <StatisticTableFilter
                                type="DATA_TYPE"
                                isSearch={isSearchType}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "DATA_TYPE")}
                                clearFilter={() => this.clearFilter("DATA_TYPE")}
                                applyFilter={() => this.applyFilter("DATA_TYPE")}
                                cancelFilter={() => this.cancelFilter("DATA_TYPE")}
                                txtSearch={txtSearchType}
                                handleInputChange={this.handleInputChangeType}
                                searchResults={searchTypeFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllType}
                                handleCheckedItem={this.handleCheckedType}
                                checkedAllItems={checkedAllType}
                                indeterminateItem={indeterminateType}
                                activeSort={sortConfig.key === "DATA_TYPE" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.handleSort("VSIC_UNIT")}
                            style={{ verticalAlign: "middle", textAlign: "center", minWidth: "100px", width: "100px", left: "600px" }}
                            className={this.getClassNamesFor("VSIC_UNIT") + " fixed-column"}
                          >
                            <IntlMessages id="unit" />
                          </th>

                          {statisticData &&
                            statisticData[0].groupByAreaModels &&
                            statisticData[0].groupByAreaModels[0].vsicStatisticValues.map((item, index) => {
                              return (
                                <th key={index} className={"text-left min-70 " + this.getClassNamesFor(`${item.year}`)}>
                                  {item.year === "CAGR" ? <IntlMessages id="industry.statistic.typeList.cagr" /> : item.year}
                                </th>
                              );
                            })}
                        </tr>
                      )}
                      {["TONG", "TRUNG_BINH"].includes(calculationType) && (
                        <tr>
                          <th className="fixed-column" style={{ textAlign: "center", minWidth: "50px", width: "50px", left: "0px" }}>
                            <IntlMessages id="table.sttTable" />
                          </th>

                          <th className="filter p-0 fixed-column" style={{ textAlign: "center", minWidth: "150px", width: "150px", left: "50px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterVsic()}>
                              <IntlMessages id="industry.statistic.product-code" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowVsicFilter && (
                              <StatisticTableFilter
                                type="PRODUCT_ID"
                                isSearch={isSearchVsic}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "PRODUCT_ID")}
                                clearFilter={() => this.clearFilter("PRODUCT_ID")}
                                applyFilter={() => this.applyFilter("PRODUCT_ID")}
                                cancelFilter={() => this.cancelFilter("PRODUCT_ID")}
                                txtSearch={txtSearchVsic}
                                handleInputChange={this.handleInputChangeVsic}
                                searchResults={searchVsicFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllVsic}
                                handleCheckedItem={this.handleCheckedVsic}
                                checkedAllItems={checkedAllVsic}
                                indeterminateItem={indeterminateVsic}
                                activeSort={sortConfig.key === "PRODUCT_ID" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th className="filter p-0 fixed-column" style={{ textAlign: "center", minWidth: "200px", width: "200px", left: "200px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterArea()}>
                              <IntlMessages id="advanceSearch.area.area" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowAreaFilter && (
                              <StatisticTableFilter
                                type="AREA"
                                isSearch={isSearchArea}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "AREA")}
                                clearFilter={() => this.clearFilter("AREA")}
                                applyFilter={() => this.applyFilter("AREA")}
                                cancelFilter={() => this.cancelFilter("AREA")}
                                txtSearch={txtSearchArea}
                                handleInputChange={this.handleInputChangeArea}
                                searchResults={searchAreaFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllArea}
                                handleCheckedItem={this.handleCheckedArea}
                                checkedAllItems={checkedAllArea}
                                indeterminateItem={indeterminateArea}
                                activeSort={sortConfig.key === "AREA" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th className="filter p-0 fixed-column" style={{ textAlign: "center", minWidth: "150px", width: "150px", left: "400px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterType()}>
                              <IntlMessages id="industry.statistic.table.type" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowTypeFilter && (
                              <StatisticTableFilter
                                type="DATA_TYPE"
                                isSearch={isSearchType}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "DATA_TYPE")}
                                clearFilter={() => this.clearFilter("DATA_TYPE")}
                                applyFilter={() => this.applyFilter("DATA_TYPE")}
                                cancelFilter={() => this.cancelFilter("DATA_TYPE")}
                                txtSearch={txtSearchType}
                                handleInputChange={this.handleInputChangeType}
                                searchResults={searchTypeFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllType}
                                handleCheckedItem={this.handleCheckedType}
                                checkedAllItems={checkedAllType}
                                indeterminateItem={indeterminateType}
                                activeSort={sortConfig.key === "DATA_TYPE" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th
                            onClick={() => this.handleSort("VSIC_UNIT")}
                            className={this.getClassNamesFor("VSIC_UNIT") + " fixed-column"}
                            style={{ textAlign: "center", minWidth: "100px", width: "100px", left: "550px" }}
                          >
                            <IntlMessages id="unit" />
                          </th>

                          {statisticData &&
                            statisticData[0].vsicStatisticValues &&
                            statisticData[0].vsicStatisticValues.map((item, index) => {
                              return (
                                <th key={index} className={"text-left min-70 " + this.getClassNamesFor(`${item.year}`)}>
                                  {item.year === "CAGR" ? <IntlMessages id="industry.statistic.typeList.cagr" /> : item.year}
                                </th>
                              );
                            })}
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {!["TONG", "TRUNG_BINH"].includes(calculationType) &&
                        statisticData.map((vsicGroup, indexVsic) => {
                          return (
                            <React.Fragment key={indexVsic}>
                              {vsicGroup.groupByAreaModels &&
                                vsicGroup.groupByAreaModels.map((props, index) => {
                                  return (
                                    <tr key={index}>
                                      {props.rowSpanForCode ? (
                                        <td
                                          rowSpan={props.rowSpanForCode}
                                          className="fixed-column"
                                          style={{ verticalAlign: "middle", textAlign: "center", minWidth: "50px", width: "50px", left: "0px" }}
                                        >
                                          <span>{formatNumber((pageIndex - 1) * 10 + indexVsic + 1)}</span>
                                        </td>
                                      ) : null}

                                      {props.rowSpanForCode ? (
                                        <td rowSpan={props.rowSpanForCode} className="fixed-column" style={{ verticalAlign: "middle", minWidth: "150px", width: "150px", left: "50px" }}>
                                          <span style={{ display: "flex", alignItems: "center" }}>
                                            {vsicGroup.productCode}
                                            {vsicGroup.isPurchased === false && (
                                              <em className="material-icons text-cyan ml-1" onClick={() => this.handleBuyReport(vsicGroup)}>
                                                shopping_cart
                                              </em>
                                            )}
                                          </span>
                                        </td>
                                      ) : null}
                                      {props.rowSpanForCode ? (
                                        <td rowSpan={props.rowSpanForCode} className="fixed-column" style={{ verticalAlign: "middle", minWidth: "150px", width: "150px", left: "200px" }}>
                                          <Popup
                                            trigger={<span className="">{language === "en" && vsicGroup.productEnName ? vsicGroup.productEnName : vsicGroup.productName}</span>}
                                            className="custom-popup"
                                            position={"top left"}
                                          >
                                            <Popup.Content>{language === "en" && vsicGroup.productEnName ? vsicGroup.productEnName : vsicGroup.productName}</Popup.Content>
                                          </Popup>
                                        </td>
                                      ) : null}

                                      {props.rowSpanForArea ? (
                                        <td
                                          rowSpan={props.rowSpanForArea}
                                          className="fixed-column border-td-left"
                                          style={{ verticalAlign: "middle", minWidth: "100px", width: "100px", left: "349px" }}
                                        >
                                          {language === "en" && props.enArea ? props.enArea : props.area}
                                        </td>
                                      ) : null}

                                      <td className="fixed-column p-0 border-td-left" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "448px" }}>
                                        {messages[`industry.statistic.${props.vsicDataKindCode}`]}
                                      </td>
                                      <td className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "100px", width: "100px", left: "598px" }}>
                                        {language === "en" && props.vsicEUnit ? props.vsicEUnit : props.vsicUnit}
                                      </td>

                                      {props.vsicStatisticValues.map((item, index) => {
                                        return (
                                          <td className="text-right" key={index}>
                                            {vsicGroup.isPurchased === false || item.value === null ? (
                                              "N/A"
                                            ) : (
                                              <span>{props.vsicUnit === "%" ? numberFormat(item.value) : formatMoney(parseInt(item.value, 10))}</span>
                                            )}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })}

                      {["TONG", "TRUNG_BINH"].includes(calculationType) &&
                        statisticData.map((props, index) => {
                          return (
                            <tr key={index}>
                              <td className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "50px", width: "50px", left: "0px" }}>
                                <span>{formatNumber((pageIndex - 1) * 10 + index + 1)}</span>
                              </td>

                              <td className="fixed-column" style={{ verticalAlign: "middle", minWidth: "150px", width: "150px", left: "50px" }}>
                                <span style={{ display: "flex", alignItems: "center" }}>{props.productCode}</span>
                              </td>

                              <td className="fixed-column " style={{ minWidth: "200px", width: "200px", left: "200px" }}>
                                {language === "en" && props.enArea ? props.enArea : props.area}
                              </td>

                              <td className="fixed-column" style={{ minWidth: "150px", width: "150px", left: "400px" }}>
                                {messages[`industry.statistic.${props.vsicDataKindCode}`]}
                              </td>
                              <td className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "100px", width: "100px", left: "550px" }}>
                                {language === "en" && props.vsicEUnit ? props.vsicEUnit : props.vsicUnit}
                              </td>

                              {props.vsicStatisticValues.map((item, index) => {
                                return (
                                  <td className="text-right" key={index}>
                                    {props.isPurchased === false || item.value === null ? (
                                      "N/A"
                                    ) : (
                                      <span>{props.vsicUnit === "%" ? numberFormat(item.value) : formatMoney(parseInt(item.value, 10))}</span>
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="foot-pagination">
                    <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                  </div>
                </div>
              )}
              {(statisticData === null || (statisticData && statisticData.length) === 0) && <FindnotfoundNoTitle />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer,
    industryInformationReducer: state.industryInformationReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actAdvanceStatisticIndustry: (body) => {
      dispatch(actAdvanceStatisticIndustry(body));
    },
    getProductList: (body) => {
      dispatch(actGetProductList(body));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabDataTable));
