import React, { Suspense } from "react";
import IntlMessages from "../../utils/IntlMessages";
import { setCookie } from "../../utils/cookies";

class ModalCookieAccept extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: ""
    };
  }

  handleCloseModal = () => {
    setCookie("virace_cookie", "exist");

    this.props.handleCloseModal();
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="main-content">
          <span onClick={this.handleCloseModal} className="material-icons close-btn">
            close
          </span>
          <div className="coookie-content mb-4">
            <IntlMessages id="cookie.content" />
          </div>
          <div className="button-group">
            <button className="btn common-button btn-secondary mr-4" onClick={this.handleCloseModal}>
              <IntlMessages id="cookie.accept_btn" />
            </button>
            <button className="btn common-button btn-secondary" onClick={this.handleCloseModal}>
              <IntlMessages id="cookie.reject_btn" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const MyComponent = ModalCookieAccept;

export default function ModalCookie(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
