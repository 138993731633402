import React, { Suspense } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import IntlMessages from "../../utils/IntlMessages";

class ModalDisclaimerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: ""
    };
  }
  handleCloseModal = () => {
    this.props.handleCloseForget(false);
  };
  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    const { toggleModal } = this.props;
    const closeBtn = (
      <span onClick={this.handleCloseModal} className="material-icons close-btn">
        close
      </span>
    );

    return (
      <React.Fragment>
        <Modal className="common-modal" isOpen={toggleModal} size="md" centered>
          <ModalHeader close={closeBtn}>
            <IntlMessages id="footer.disclaimer" />
          </ModalHeader>

          <ModalBody className="disclaimer-content">
            <p className="mb-4">
              <IntlMessages id="footer.disclaimer.text2" />
            </p>
            <p className="mb-2">
              <IntlMessages id="footer.disclaimer.text3" />
            </p>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const MyComponent = ModalDisclaimerPage;
export default function ModalDisclaimer(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
