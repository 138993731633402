import { isEmpty, forEach } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import AlertWarning from "../../components/SweetAlert/AlertWarning";
import { actGetDataFromLocalStorage, actCreateOrder, actGetOrder, actRemoveFromCart, actSendRequestData } from "../../redux/Cart/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../../components/CustomButton/CustomButton";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { formatMoney_2 } from "../../utils/formatMoney";
import { Checkbox } from "semantic-ui-react";
import NoData from "../NoData";
import { Link } from "react-router-dom";
import LoaderTable from "../LoaderTable";
import Findnotfound from "../Findnotfound";
import getUrlParam from "../../utils/getUrlParam";
import { callApiServer } from "../../utils/callApiServer";
import { Popup } from "semantic-ui-react";
import ConfirmPayOnlyPointComponent from "../../views/AccountsManagement/ConfirmPayOnlyPointComponent";
import moment from "moment";

class Cart extends Component {
  constructor(props) {
    super(props);
    const { dataRequest } = this.props.carts.dataRequired;

    const industryReportData = dataRequest.filter((i) => i.type === 2 || i.type === 3 || i.type === 4 || i.type === 5);
    const companyProductData = [...dataRequest.filter((i) => i.type === 6 || i.type === 7 || i.type === 8 || i.type === 9)];
    const companyReportData = dataRequest.filter((i) => i.type === 1);
    industryReportData.forEach((e) => {
      e.isChecked = false;
    });
    companyProductData.forEach((e) => {
      e.isChecked = false;
    });
    companyReportData.forEach((e) => {
      e.isChecked = false;
    });
    this.state = {
      openModal: false,
      stt: "",
      comment: "",
      orderNote: "",
      requestData: "",
      message: "",
      isDisabled: true,
      taxNumber: null,
      industryReportData: industryReportData,
      companyProductData: companyProductData,
      companyReportData: companyReportData,
      checkedAllIndustry: false,
      checkedAllCompanyProduct: false,
      checkedAllCompany: false,
      indeterminateIndustry: false, // dấu trừ ở check all
      indeterminateProductData: false, // dấu trừ ở check all
      indeterminateCompany: false, // dấu trừ ở check all
      selectedIndustryList: [],
      selectedCompanyProductList: [],
      selectedCompanyList: [],
      isDisabledBuyButton: true,
      isDisabledRequestButton: true,
      money: 0,
      moneyDiscount: 0,
      point: 0,
      pointDiscount: 0,
      initLoading: true,
      sessionId: getUrlParam()["session_id"] ? getUrlParam()["session_id"] : "",
      agreedTerms: false,
      isLoading: false,
      discountValue: "",
      discountError: false,
      billPayment: ""
    };
  }

  componentDidMount() {
    document.title = "VIRACE | Cart";

    const { sessionId } = this.state;
    if (sessionId) {
      this.props.getOrder(sessionId);
    }
    this.props.handleGetDataFromLocalStorage();

    setTimeout(() => {
      this.setState({
        initLoading: false
      });
    }, 500);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.carts.dataRequired && isEmpty(nextProps.carts.dataRequired) === false) {
      const { dataRequest } = nextProps.carts.dataRequired;
      const { selectedCompanyList, selectedCompanyProductList, selectedIndustryList } = this.state;
      const industryReportData = dataRequest.filter((i) => !!i.id && (i.viracServiceId === 2 || i.viracServiceId === 3 || i.viracServiceId === 4 || i.viracServiceId === 5));
      const companyProductData = [...dataRequest.filter((i) => i.id && (i.viracServiceId === 6 || i.viracServiceId === 7 || i.viracServiceId === 8 || i.viracServiceId === 9))];
      const companyReportData = dataRequest.filter((i) => !!i.id && i.viracServiceId === 1);
      // industryReportData.forEach((e) => {
      //   const x = selectedIndustryList.filter((i) => i.id === e.id);
      //   if (x.length > 0) {
      //     e.isChecked = x[0].isChecked;
      //   } else {
      //     e.isChecked = false;
      //   }
      // });

      // companyReportData.forEach((e) => {
      //   const y = selectedCompanyList.filter((i) => i.id === e.id && i.type === e.type);
      //   if (y.length > 0) {
      //     e.isChecked = y[0].isChecked;
      //   } else {
      //     e.isChecked = false;
      //   }
      // });

      this.setState({
        comment: nextProps.carts.dataRequired.dataRequest.comment,
        orderNote: this.state.orderNote,
        taxNumber: nextProps.carts.dataRequired.dataRequest.taxNumber,
        isDisabled: false,
        industryReportData: industryReportData,
        companyProductData: companyProductData,
        companyReportData: companyReportData
      });
    } else {
      this.setState({
        isDisabled: true,
        orderNote: ""
      });
    }
  }

  onChange = (e) => {
    var target = e.target;
    var value = target.value;
    var name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleShowAlert = (arrToDel) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          title={messages[`user.Confirm`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          message={messages[`newWord.deleteDataRequest`]}
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          confirmToFunc={() => {
            this.handleRemoveFromCart(arrToDel);
            this.handleHideAlert();
          }}
          cancleToFunc={this.handleHideAlert}
        />
      )
    });
  };

  handleShowAlertSuccess = (message) => {
    const { messages } = this.props.intl;
    if (message === "Success") {
      this.setState({
        ...this.state,
        orderNote: null,
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title={messages[`common.successx`]}
            // showCancel
            confirmBtnText={messages[`newWord.oke`]}
            customClass="swal-wide"
            confirmBtnCssClass="confirmBtn swal2-styled"
            onConfirm={() => {
              this.handleHideAlert();
            }}
          >
            <p style={{ fontSize: "15px" }}>
              <IntlMessages id="newWord.orderSent" />
            </p>
          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title={messages[`cart.FailAlert`]}
            // showCancel
            confirmBtnText={messages[`newWord.oke`]}
            customClass="swal-wide"
            confirmBtnCssClass="confirmBtn swal2-styled"
            onConfirm={() => {
              this.handleHideAlert();
            }}
          >
            <p style={{ fontSize: "15px" }}>
              <IntlMessages id="cart.FailAlertOrder" />
            </p>
          </SweetAlert>
        )
      });
    }
  };

  actAlert = (message) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
        >
          {message}
        </SweetAlert>
      )
    });
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  actAlertLogin = (message) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlertLogin();
          }}
        >
          {message}
        </SweetAlert>
      )
    });
  };
  handleHideAlertLogin = () => {
    this.setState({
      alert: ""
    });

    this.props.history.push("/dang-nhap");
  };

  // gửi yêu cầu
  hanldeSendRequest() {
    const { messages } = this.props.intl;

    let isLogged = localStorage.getItem("access_token") ? true : false;
    if (isLogged) {
      this.sendRequestData();
    } else {
      this.actAlertLogin(messages[`newWord.loginAccountRequire`]);
    }
  }
  sendRequestData = () => {
    const { messages } = this.props.intl;
    const { selectedIndustryList, selectedCompanyList, selectedCompanyProductList } = this.state;
    const selectedList = [...selectedIndustryList, ...selectedCompanyList, ...selectedCompanyProductList];
    var arrTemp = [];
    if (selectedList.length === 0) {
      this.actAlert(messages[`cart.no-selected`]);
    } else {
      forEach(selectedList, (item) => {
        arrTemp.push({
          comment: "",
          companyId: item.id ? item.id : "",
          requestData: item.requestData ? item.requestData : "",
          viracServiceId: item.viracServiceId ? item.viracServiceId : ""
        });
      });
      let arrToSend = {
        orderAPI: arrTemp,
        comment: this.state.orderNote
      };

      this.props.handleSendRequestData(arrToSend, selectedList, this.sendSuccess);
    }
  };

  sendSuccess = () => {
    this.setState(
      {
        checkedAllCompany: false,
        checkedAllCompanyProduct: false,
        checkedAllIndustry: false,
        indeterminateCompany: false,
        indeterminateProductData: false,
        indeterminateIndustry: false,
        selectedCompanyList: [],
        selectedCompanyProductList: [],
        selectedIndustryList: []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };

  // check all bc ngành
  handleChangeCheckAllIndustry = () => {
    let { industryReportData, checkedAllIndustry } = this.state;
    industryReportData.forEach((e) => {
      e.isChecked = !checkedAllIndustry ? true : false;
    });
    this.setState(
      {
        checkedAllIndustry: !checkedAllIndustry,
        industryReportData,
        indeterminateIndustry: false,
        selectedIndustryList: !checkedAllIndustry ? industryReportData : []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };

  // check 1 bc ngành
  handleCheckedIndustry = (data) => {
    let { industryReportData, selectedIndustryList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedIndustryList = selectedIndustryList.filter((i) => i.id !== data.id || (i.id === data.id && i.lang !== data.lang));
    } else {
      selectedIndustryList.push(data);
    }
    industryReportData.forEach((e) => {
      if (e.id === data.id && e.lang === data.lang) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState(
      {
        checkedAllIndustry: industryReportData.length === selectedIndustryList.length,
        indeterminateIndustry: industryReportData.length !== selectedIndustryList.length && selectedIndustryList.length !== 0,
        industryReportData,
        selectedIndustryList
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };
  // check all sản phẩm doanh nghiệp
  handleChangeCheckAllCompanyProduct = () => {
    let { companyProductData, checkedAllCompanyProduct } = this.state;
    companyProductData.forEach((e) => {
      e.isChecked = !checkedAllCompanyProduct ? true : false;
    });
    this.setState(
      {
        checkedAllCompanyProduct: !checkedAllCompanyProduct,
        companyProductData,
        indeterminateProductData: false,
        selectedCompanyProductList: !checkedAllCompanyProduct ? companyProductData : []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };

  // check 1 sản phẩm doanh nghiệp
  handleCheckedCompanyProduct = (data) => {
    let { companyProductData, selectedCompanyProductList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedCompanyProductList = selectedCompanyProductList.filter((i) => !(i.id === data.id && i.type === data.type));
    } else {
      selectedCompanyProductList.push(data);
    }

    companyProductData.forEach((e) => {
      if (e.id === data.id && e.type === data.type) {
        e.isChecked = !e.isChecked;
      }
    });

    this.setState(
      {
        checkedAllCompanyProduct: companyProductData.length === selectedCompanyProductList.length,
        indeterminateProductData: companyProductData.length !== selectedCompanyProductList.length && selectedCompanyProductList.length !== 0,
        companyProductData,
        selectedCompanyProductList
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };

  // check all DN
  handleChangeCheckAllCompany = () => {
    let { companyReportData, checkedAllCompany } = this.state;
    companyReportData.forEach((e) => {
      e.isChecked = !checkedAllCompany ? true : false;
    });
    this.setState(
      {
        checkedAllCompany: !checkedAllCompany,
        indeterminateCompany: false,
        companyReportData,
        selectedCompanyList: !checkedAllCompany ? companyReportData : []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };

  // check 1 DN
  handleCheckedCompany = (data) => {
    let { companyReportData, selectedCompanyList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedCompanyList = selectedCompanyList.filter((i) => i.id !== data.id || (i.id === data.id && i.requestData !== data.requestData));
    } else {
      selectedCompanyList.push(data);
    }
    companyReportData.forEach((e) => {
      if (e.requestData === data.requestData && e.id === data.id && e.lang === data.lang) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState(
      {
        checkedAllCompany: companyReportData.length === selectedCompanyList.length,
        indeterminateCompany: companyReportData.length !== selectedCompanyList.length && selectedCompanyList.length !== 0,
        companyReportData,
        selectedCompanyList
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
      }
    );
  };

  // trạng thái của các button: disable?
  handleButtonStatus = (selectedIndustryList, selectedCompanyList, selectedCompanyProductList) => {
    const selectedList = [...selectedIndustryList, ...selectedCompanyList, ...selectedCompanyProductList];
    // không thể mua
    if (selectedList.length === 0 || selectedCompanyList.length > 0) {
      this.setState({
        isDisabledBuyButton: true,
        money: 0,
        moneyDiscount: 0,
        point: 0,
        pointDiscount: 0
      });
    } else {
      // có thể mua
      let money = 0;
      let moneyDiscount = 0;

      let point = 0;
      let pointDiscount = 0;

      // ----báo cáo ngành---
      selectedIndustryList.forEach((e) => {
        if (e.price) {
          money += Number(e.price);
        }
        if (e.point) {
          point += Number(e.point);
        }
        if (e.discount) {
          moneyDiscount += Number(e.price * e.discount);
          pointDiscount += Number(e.point * e.discount);
        } else {
          moneyDiscount += Number(e.price);
          if (e.point) {
            pointDiscount += Number(e.point);
          }
        }
      });

      //--- sản phẩm doanh nghệp----
      let newSelectedCompanyProduct = [...selectedCompanyProductList];

      newSelectedCompanyProduct = newSelectedCompanyProduct.sort(function(a, b) {
        // if (a.id > b.id) return 1;
        // if (a.id < b.id) return -1;
        return a.id.localeCompare(b.id) || b.type - a.type;
      });

      newSelectedCompanyProduct.forEach((item, index) => {
        let typeDiscount = [7, 8];
        if (
          newSelectedCompanyProduct.length > 1 &&
          newSelectedCompanyProduct[index + 1] &&
          item.id === newSelectedCompanyProduct[index + 1].id &&
          typeDiscount.includes(item.type) &&
          typeDiscount.includes(newSelectedCompanyProduct[index + 1].type)
        ) {
          item["discount"] = 0.5;
          newSelectedCompanyProduct[index + 1]["discount"] = 0.5;
        } else {
          delete item["discount"];
        }
      });
      newSelectedCompanyProduct.forEach((e) => {
        if (e.price) {
          money += Number(e.price);
        }
        if (e.point) {
          point += Number(e.point);
        }
        if (e.discount) {
          moneyDiscount += Number(e.price * e.discount);
          pointDiscount += Number(e.point * e.discount);
        } else {
          moneyDiscount += Number(e.price);
          pointDiscount += Number(e.point);
        }
      });
      this.setState(
        {
          isDisabledBuyButton: false,
          money,
          moneyDiscount,
          point,
          pointDiscount
        },
        () => {}
      );
    }

    // không thể gửi yêu cầu
    if (selectedList.length === 0) {
      this.setState({
        isDisabledRequestButton: true
      });
    } else {
      this.setState({
        isDisabledRequestButton: false
      });
    }
  };

  // xóa khỏi cart
  handleDeleteData = (data) => {
    let arrToDel = {
      requestData: data.requestData,
      id: data.id,
      lang: data.lang,
      type: data.type
    };
    this.handleRemoveFromCart(arrToDel);
    const dataType = data.type;
    let selectedIndustryList;
    let selectedCompanyProductList;
    let selectedCompanyList;

    switch (dataType) {
      case 1:
        selectedCompanyList = this.state.selectedCompanyList.filter((i) => i.requestData !== data.requestData && i.name !== data.name);
        this.setState(
          {
            selectedCompanyList
          },
          () => {
            this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
          }
        );
        break;
      case 2:
        selectedIndustryList = this.state.selectedIndustryList.filter((i) => !(i.id === data.id && i.lang === data.lang));
        this.setState(
          {
            selectedIndustryList
          },
          () => {
            this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
          }
        );
        break;
      case 3:
      case 4:
        selectedIndustryList = this.state.selectedIndustryList.filter((i) => !(i.id === data.id));
        this.setState(
          {
            selectedIndustryList
          },
          () => {
            this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
          }
        );

        break;
      case 6:
      case 7:
      case 8:
      case 9:
        selectedCompanyProductList = this.state.selectedCompanyProductList.filter((i) => !(i.id === data.id && i.type === data.type));
        this.setState(
          {
            selectedCompanyProductList
          },
          () => {
            this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList, this.state.selectedCompanyProductList);
          }
        );

        break;

      default:
        break;
    }
  };

  handleRemoveFromCart = (arrToDel) => {
    this.props.removeFromCart(arrToDel);
  };

  // mua hàng
  hanldePayment() {
    const { messages } = this.props.intl;

    let isLogged = localStorage.getItem("access_token") ? true : false;
    if (isLogged) {
      this.payment();
    } else {
      this.actAlertLogin(messages[`newWord.loginAccountRequire`]);
    }
  }
  payment = () => {
    const { messages } = this.props.intl;
    const { selectedIndustryList, selectedCompanyList, selectedCompanyProductList } = this.state;
    const selectedList = [...selectedIndustryList, ...selectedCompanyList, ...selectedCompanyProductList];
    if (selectedList.length === 0) {
      this.actAlert(messages[`cart.no-selected`]);
    } else if (selectedCompanyList.length > 0) {
      this.actAlert(messages[`cart.is-company`]);
    } else {
      const listPay = selectedList.filter((i) => i.viracServiceId !== 1);
      const body = {
        products: listPay.map((i) => {
          let type = "";
          // Kiểm tra type của báo cáo để gửi payload tương ứng
          if (i.viracServiceId === 2) {
            type = "Report";
          } else if (i.viracServiceId === 3) {
            type = "Vsic";
          } else if (i.viracServiceId === 4) {
            type = "VsicProduct";
          } else if (i.viracServiceId === 5) {
            type = "CompanyReportPdf";
          } else if (i.viracServiceId === 6) {
            type = "CompanyDetailView";
          } else if (i.viracServiceId === 7) {
            type = "CompanyReportPdf";
          } else if (i.viracServiceId === 8) {
            type = "CompanyReportExcel";
          } else if (i.viracServiceId === 9) {
            type = "CompanySearchDownload";
          }
          return {
            lang: i.lang,
            productId: i.id,
            productType: type
          };
        }),
        discountCode: this.state.discountValue
      };
      this.checkDiscountAndShowBill(body);
    }
  };

  async checkDiscountAndShowBill(body) {
    this.setState({
      isLoading: true
    });
    const { messages } = this.props.intl;

    try {
      const response = await callApiServer.post("/Order/VerifyOrder", body);

      if (response && response.data.success && response.data.data) {
        const data = response.data.data;

        if (data.validateSuccess) {
          this.showBill(body, data);
          this.setState({
            discountError: false,
            isLoading: false
          });
        } else {
          this.setState({
            discountError: true,
            isLoading: false
          });
        }
      } else {
        this.setState({
          isLoading: false
        });

        this.showCommonErr();
      }
    } catch (err) {
      this.setState({
        discountError: false,
        isLoading: false
      });
      this.showCommonErr();
    }
  }

  // Mua hàng bằng VP
  hanldePaymentByVipoint() {
    const { messages } = this.props.intl;

    let isLogged = localStorage.getItem("access_token") ? true : false;
    if (isLogged) {
      this.paymentByVipoint();
    } else {
      this.actAlertLogin(messages[`newWord.loginAccountRequire`]);
    }
  }

  paymentByVipoint = () => {
    const { messages } = this.props.intl;
    const { selectedIndustryList, selectedCompanyList, selectedCompanyProductList } = this.state;
    const selectedList = [...selectedIndustryList, ...selectedCompanyList, ...selectedCompanyProductList];
    if (selectedList.length === 0) {
      this.actAlert(messages[`cart.no-selected`]);
    } else if (selectedCompanyList.length > 0) {
      this.actAlert(messages[`cart.is-company`]);
    } else {
      const listPay = selectedList.filter((i) => i.viracServiceId !== 1);
      const body = {
        products: listPay.map((i) => {
          let type = "";
          // Kiểm tra type của báo cáo để gửi payload tương ứng
          if (i.viracServiceId === 2) {
            type = "Report";
          } else if (i.viracServiceId === 3) {
            type = "Vsic";
          } else if (i.viracServiceId === 4) {
            type = "VsicProduct";
          } else if (i.viracServiceId === 5) {
            type = "CompanyReportPdf";
          } else if (i.viracServiceId === 6) {
            type = "CompanyDetailView";
          } else if (i.viracServiceId === 7) {
            type = "CompanyReportPdf";
          } else if (i.viracServiceId === 8) {
            type = "CompanyReportExcel";
          } else if (i.viracServiceId === 9) {
            type = "CompanySearchDownload";
          }
          return {
            lang: i.lang,
            productId: i.id,
            productType: type,
            pointValue: i.point
          };
        }),
        discountCode: this.state.discountValue
      };
      this.checkDiscountAndShowPopupPoint(body);
    }
  };

  async checkDiscountAndShowPopupPoint(body) {
    this.setState({
      isLoading: true
    });
    const { messages } = this.props.intl;

    try {
      const response = await callApiServer.post("/virace-point/verify-cart-pay", body);

      if (response && response.data.success && response.data.data) {
        const data = response.data.data;
        if (data.validateSuccess) {
          this.openPopupConfirmBuy(data.totalAmountAfterDiscount, data.totalAmount, data, body);
          this.setState({
            discountError: false,
            isLoading: false
          });
        } else {
          this.setState({
            discountError: true,
            isLoading: false
          });
        }
      } else {
        this.setState({
          isLoading: false
        });

        this.showCommonErr();
      }
    } catch (err) {
      this.setState({
        discountError: false,
        isLoading: false
      });
      this.showCommonErr();
    }
  }

  openPopupConfirmBuy(numberViPoint, oldNumberVipoint = 0, detail = {}, body = {}) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });

            this.props.history.push("/trang-chu-cr");
          }}
        >
          <ConfirmPayOnlyPointComponent
            numberViPoint={numberViPoint}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            body={body}
            success={(orderId) => this.confirmPaySuccess(orderId, body)}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, oldNumberVipoint, detail, body)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
            history={this.props.history}
          ></ConfirmPayOnlyPointComponent>
        </SweetAlert>
      )
    });
  }

  confirmPaySuccess(orderId, body) {
    if (body) {
      let productsInCart = body.products;

      productsInCart.forEach((item) => {
        let productId = item.productId;
        let { companyProductData } = this.state;
        companyProductData = companyProductData.filter((item) => item.id === productId);
        this.handleDeleteData(companyProductData[0]);
      });
    }

    this.setState({
      popupConfirmBuy: ""
    });

    // this.props.history.push(`/quan-li-tai-khoan?tab=1&typeOrder=fromcart&orderId=${orderId}`);
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, oldNumberVipoint = 0, detail, body) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, oldNumberVipoint, detail, body);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, oldNumberVipoint, detail, body)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, oldNumberVipoint, detail, body) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, oldNumberVipoint, detail, body);
  }

  showCommonErr() {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {messages["newWord.somthingWrong"]}
        </SweetAlert>
      )
    });
  }

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handleChangeDiscount = (event) => {
    const value = event.target.value;
    this.setState({
      discountValue: value,
      discountError: false
    });
  };

  showBill(body, bodyVerify) {
    let dataOrder = body;

    Object.assign(dataOrder, {
      discountKey: bodyVerify.discountKey ? bodyVerify.discountKey : "",
      discountCode: bodyVerify.discountCode ? bodyVerify.discountCode : ""
    });

    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const { products, discountCode, totalAmount, vatLabel, vatValue, totalAmountAfterDiscount, totalDiscountValue } = bodyVerify;
    this.setState({
      billPayment: (
        <SweetAlert
          default
          hideOverlay={false}
          title={messages["cart.orderInfor"]}
          confirmBtnText={messages["cart.confirmPay"]}
          customClass="payment-popup"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnText={messages["cart.cancelPay"]}
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this.setState({
              billPayment: ""
            });
            this.props.createOrder(dataOrder);
          }}
          showCancel={true}
          onCancel={() => {
            this.setState({
              billPayment: ""
            });
          }}
        >
          <div className="order-infor">
            {products.map((props, index) => {
              return (
                <div className="order-item mb-2" key={index}>
                  <div className="col-7 order-item-label ">
                    {index + 1}. {language === "vi" ? props.productName : props.productEnName} - {this.renderTypeReport(props)}
                  </div>
                  <div className="col-5 order-item-value font-w-550">
                    <div className="main-value">
                      <span>{formatMoney_2(props.priceAfterDiscount, 2)} VND</span>
                    </div>
                    {props.isDiscount && (
                      <div className="sub-value">
                        <span>{formatMoney_2(props.price, 2)} VND</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.provisionalMoney"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{formatMoney_2(totalAmount, 2)} VND</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.promotionCode"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{discountCode}</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.discount"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>-{formatMoney_2(totalDiscountValue, 2)} VND</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600 ">
                <IntlMessages id="cart.VAT"></IntlMessages> <span>({vatLabel})</span>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{formatMoney_2(vatValue, 2)} VND</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large" style={{ borderTop: "0.5px solid #cdd5d9", paddingTop: "15px" }}>
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.totalMoney"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{formatMoney_2(totalAmountAfterDiscount, 2)} VND</span>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  renderTypeReport(data) {
    const { messages } = this.props.intl;
    switch (data.productType) {
      case "CompanyDetailView":
        return ` ${messages["vpoint.action.2"]}`;
      case "CompanyReportPdf":
        return ` ${messages["vpoint.action.3"]}`;
      case "CompanyReportExcel":
        return ` ${messages["vpoint.action.4"]}`;
      default:
        return "";
    }
  }

  renderTypeReportTable(data) {
    const { messages } = this.props.intl;
    switch (data.type) {
      case 6:
        return ` ${messages["vpoint.action.2"]}`;
      case 7:
        return ` ${messages["vpoint.action.3"]}`;
      case 8:
        return ` ${messages["vpoint.action.4"]}`;
      case 9:
        return ` ${messages["vpoint.action.6"]}`;
      default:
        return "";
    }
  }

  renderDetailTable(props) {
    const language = localStorage.getItem("currentLanguage");
    const { messages } = this.props.intl;

    let type = props.type;
    switch (type) {
      case 6:
      case 7:
      case 8:
        return (
          <Link target="_blank" className="text-cyan" to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>
            {language === "en" && props.englishName ? props.englishName : props.name}
          </Link>
        );
      case 9:
        const toolTipData = props.toolTipContent;
        return (
          <div className="compay-search-download-item">
            <span className="name">{language === "en" && props.englishName ? props.englishName : props.name}</span>

            <Popup
              trigger={
                <span style={{ height: "25px", fontSize: "16px", color: "#b9cbe8" }} className="material-icons  tooltip-icon cursor-default ml-2">
                  info
                </span>
              }
              className="custom-popup ml-1"
            >
              <ul className="pl-0 mb-0">
                {toolTipData && toolTipData.basic ? (
                  <li>
                    - <IntlMessages id="industry.filter.keyWord" /> : <span>{toolTipData.basic}</span>
                  </li>
                ) : (
                  <React.Fragment>
                    {toolTipData && toolTipData.advance && toolTipData.advance.length > 0 && (
                      <span>
                        {toolTipData.advance.map((prop, index) => {
                          return (
                            <li key={index}>
                              -
                              {prop.type === "area" && (
                                <span>
                                  <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                  <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${prop.area.districtName ? prop.area.districtName + ", " : ""}${prop.area.provinceName}`}</span>
                                </span>
                              )}
                              {prop.type === "industry" && (
                                <span>
                                  <IntlMessages id="sidebar.Industry" /> {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                  {prop.industry.code}
                                </span>
                              )}
                              {prop.type === "companyType" && (
                                <span>
                                  <IntlMessages id="advanceSearch.companyType.companyType" /> :{language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} (
                                  {prop.dataPoint}):{" "}
                                </span>
                              )}
                              {prop.type === "legal" && (
                                <span>
                                  <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.idPerson}{" "}
                                </span>
                              )}
                              {prop.type === "owner" && (
                                <span>
                                  <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.showId}{" "}
                                </span>
                              )}
                              {prop.type === "finance" && (
                                <span>
                                  {prop.criteria.criteriaText} ({prop.dataPoint}):
                                  <span>
                                    {prop.criteria.from
                                      ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney_2(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit}) `
                                      : ""}
                                    {prop.criteria.to
                                      ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney_2(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit})`
                                      : ""}
                                  </span>
                                </span>
                              )}
                              {prop.type === "importExport_year" && (
                                <span>
                                  {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                  <span> ({prop.criteria.year}):</span>
                                  <span>
                                    {prop.criteria.from
                                      ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney_2(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.unit}) `
                                      : ""}
                                    {prop.criteria.to ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney_2(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.unit})` : ""}
                                  </span>
                                </span>
                              )}
                              {prop.type === "importExport_hs" && (
                                <span>
                                  {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                  <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                </span>
                              )}
                              {prop.type === "registration_date" && (
                                <span>
                                  <IntlMessages id="advanceSearch.founding_date.condition_tilte" />: <IntlMessages id="advanceSearch.founding_date.condition_from" />{" "}
                                  {prop.fromDate ? " " + this.renderTime(prop.fromDate) : ""} <IntlMessages id="advanceSearch.founding_date.condition_to" />{" "}
                                  {prop.toDate ? this.renderTime(prop.toDate) : ""}
                                </span>
                              )}
                            </li>
                          );
                        })}
                      </span>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </Popup>
          </div>
        );

      default:
        break;
    }
  }

  // gửi yêu cầu
  sendRequest = () => {};

  handleChangeCheckTerm = () => {
    const { agreedTerms } = this.state;

    this.setState({
      agreedTerms: !agreedTerms
    });
  };

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  renderTime(time) {
    if (time) return moment(time).format("DD/MM/YYYY");
    else return "";
  }

  render() {
    const {
      initLoading,
      industryReportData,
      companyReportData,
      companyProductData,
      checkedAllCompany,
      checkedAllIndustry,
      checkedAllCompanyProduct,
      indeterminateCompany,
      indeterminateIndustry,
      indeterminateProductData,
      agreedTerms,
      isLoading
    } = this.state;

    const { userAccount } = this.props;
    const { createOrderLoading, createRequestLoading } = this.props.carts;
    const language = localStorage.getItem("currentLanguage");
    const isLogged = localStorage.getItem("access_token") ? true : false;

    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.billPayment}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        {initLoading ? (
          <LoaderTable isLoaded={!initLoading} styleCustom={{ height: "500px" }} />
        ) : (
          <div className="cart-main-wrapper">
            {industryReportData.length > 0 || companyReportData.length > 0 || companyProductData.length > 0 ? (
              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <div className="card-item mb-4">
                    <div className="card-title card-title-line">
                      <IntlMessages id="cart.industryReport.title" />
                    </div>
                    <div className="table-custom">
                      {industryReportData && industryReportData.length > 0 && (
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="col-no">
                                <div className="input-list-Checkbox">
                                  <Checkbox checked={checkedAllIndustry} onChange={() => this.handleChangeCheckAllIndustry()} indeterminate={indeterminateIndustry} />
                                </div>
                              </th>
                              <th>
                                <IntlMessages id="cart.industryReport.report" />
                              </th>
                              <th>
                                <IntlMessages id="cart.industryReport.price" />
                              </th>
                              <th>
                                <IntlMessages id="cart.equipvalent" />
                              </th>
                              <th>
                                <IntlMessages id="newWord.selectLangReportTable" />
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {industryReportData.map((props, index) => {
                              return (
                                <tr key={index}>
                                  <td className="col-no">
                                    <div className="input-list-Checkbox">
                                      <Checkbox checked={!!props.isChecked} onChange={() => this.handleCheckedIndustry(props)} />
                                    </div>
                                  </td>
                                  <td>
                                    {props.type === 4 || props.type === 3 ? (
                                      <span className="text-cyan">{language === "en" && props.englishName ? props.englishName : props.name}</span>
                                    ) : (
                                      <div>
                                        {props.type === 5 ? (
                                          <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>
                                            {language === "en" && props.englishName ? props.englishName : props.name}
                                          </Link>
                                        ) : (
                                          <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.id ? props.id : ""}`}>
                                            {language === "en" && props.englishName ? props.englishName : props.name}
                                          </Link>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <span>{formatMoney_2(props.price, 2)}</span>
                                  </td>
                                  <td>
                                    <span>{formatMoney_2(props.point, 2)}</span>
                                  </td>
                                  <td>{props.lang === "vi" ? <IntlMessages id="user.langVN" /> : <IntlMessages id="user.langEN" />}</td>
                                  <td>
                                    <span className="material-icons close-icon" onClick={() => this.handleDeleteData(props)}>
                                      cancel
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}

                      {industryReportData && industryReportData.length === 0 && <NoData />}
                    </div>
                    <div className="search">
                      <Link className="text-cyan" to="/trang-chu-if-ir">
                        <em className="material-icons">search</em>
                        <IntlMessages id="cart.industryReport.search" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-item mb-4">
                    <div>
                      <div className="card-title card-title-line">
                        <IntlMessages id="cart.company.title" />
                      </div>
                      {companyProductData && companyProductData.length > 0 && (
                        <span className="text-danger">
                          <IntlMessages id="cart.discountNotice" />
                        </span>
                      )}
                    </div>

                    <div className="table-custom">
                      {companyProductData && companyProductData.length > 0 && (
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="col-no">
                                <div className="input-list-Checkbox">
                                  <Checkbox checked={checkedAllCompanyProduct} onChange={() => this.handleChangeCheckAllCompanyProduct()} indeterminate={indeterminateProductData} />
                                </div>
                              </th>
                              <th>
                                <IntlMessages id="cart.companyProduct.type" />
                              </th>
                              <th>
                                <IntlMessages id="cart.companyProduct.enterprise" />
                              </th>
                              <th>
                                <IntlMessages id="cart.industryReport.price" />
                              </th>
                              <th>
                                <IntlMessages id="cart.equipvalent" />
                              </th>
                              <th>
                                <IntlMessages id="newWord.selectLangReportTable" />
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {companyProductData.map((props, index) => {
                              return (
                                <tr key={index}>
                                  <td className="col-no">
                                    <div className="input-list-Checkbox">
                                      <Checkbox checked={!!props.isChecked} onChange={() => this.handleCheckedCompanyProduct(props)} />
                                    </div>
                                  </td>
                                  <td>{this.renderTypeReportTable(props)}</td>
                                  <td>{this.renderDetailTable(props)}</td>
                                  <td>
                                    <span>{formatMoney_2(props.price, 2)}</span>
                                  </td>
                                  <td>
                                    <span>{formatMoney_2(props.point, 2)}</span>
                                  </td>
                                  <td>{props.lang === "vi" ? <IntlMessages id="user.langVN" /> : <IntlMessages id="user.langEN" />}</td>
                                  <td>
                                    <span className="material-icons close-icon" onClick={() => this.handleDeleteData(props)}>
                                      cancel
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                      {companyProductData && companyProductData.length === 0 && <NoData />}
                    </div>
                    <div className="search">
                      <Link className="text-cyan" to="/trang-chu-cr">
                        <em className="material-icons">search</em>
                        <IntlMessages id="cart.company.search" />
                      </Link>
                    </div>
                  </div>
                  {isLogged && (
                    <div className="card-item mb-4">
                      <div className="card-title card-title-line">
                        <IntlMessages id="cart.company.title" />
                      </div>
                      <div className="table-custom">
                        {companyReportData && companyReportData.length > 0 && (
                          <Table responsive>
                            <thead>
                              <tr>
                                <th className="col-no">
                                  <div className="input-list-Checkbox">
                                    <Checkbox checked={checkedAllCompany} onChange={() => this.handleChangeCheckAllCompany()} indeterminate={indeterminateCompany} />
                                  </div>
                                </th>
                                {/* <th className="col-no">
                            <IntlMessages id="table.sttTable" />
                          </th> */}
                                <th>
                                  <IntlMessages id="cart.company.report" />
                                </th>
                                <th>
                                  <IntlMessages id="cart.company.company" />
                                </th>
                                <th>
                                  <IntlMessages id="cart.company.taxCode" />
                                </th>
                                <th>
                                  <IntlMessages id="newWord.selectLangReportTable" />
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {companyReportData.map((props, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="col-no">
                                      <div className="input-list-Checkbox">
                                        <Checkbox checked={!!props.isChecked} onChange={() => this.handleCheckedCompany(props)} />
                                      </div>
                                    </td>
                                    {/* <td className="col-no">
                                <span>{index + 1}</span>
                              </td> */}
                                    <td>
                                      <span>{props.requestData ? props.requestData : ""}</span>
                                    </td>
                                    <td>
                                      {props.name && (
                                        <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>
                                          {language === "en" && props.englishName ? props.englishName : props.name}
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <span>{props.taxNumber ? props.taxNumber : ""}</span>
                                    </td>
                                    <td>{props.lang === "vi" ? <IntlMessages id="user.langVN" /> : <IntlMessages id="user.langEN" />}</td>
                                    <td>
                                      <span className="material-icons close-icon" onClick={() => this.handleDeleteData(props)}>
                                        cancel
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        )}
                        {companyReportData && companyReportData.length === 0 && <NoData />}
                      </div>
                      <div className="search">
                        <Link className="text-cyan" to="/trang-chu-cr">
                          <em className="material-icons">search</em>
                          <IntlMessages id="cart.company.search" />
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-4 col-xl-3">
                  <div className="card-item payment-info">
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.customer" />
                      </div>
                      <div className="payment-item-content">{userAccount.fullName ? userAccount.fullName : ""}</div>
                    </div>

                    {/* Tạm tính */}
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.money" />
                        <span className="notice text-danger">
                          <IntlMessages id="cart.priceChnageNotice" />
                        </span>
                      </div>
                      <div className="payment-item-content">
                        <span className={this.state.money !== 0 && this.state.moneyDiscount !== 0 && this.state.moneyDiscount !== this.state.money ? "text-line-through" : ""}>
                          {formatMoney_2(this.state.money, 2)} VND
                        </span>
                        {this.state.moneyDiscount !== 0 && this.state.moneyDiscount !== this.state.money && <span className="text-danger">{formatMoney_2(this.state.moneyDiscount, 2)} VND</span>}
                      </div>
                    </div>

                    {/* Quy đổi */}
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.equipvalent_2" />
                      </div>
                      <div className="payment-item-content">
                        <span className={this.state.point !== 0 && this.state.pointDiscount !== 0 && this.state.point !== this.state.pointDiscount ? "text-line-through" : ""}>
                          {formatMoney_2(this.state.point, 2)} VP
                        </span>
                        {this.state.pointDiscount !== 0 && this.state.point !== this.state.pointDiscount && <span className="text-danger">{formatMoney_2(this.state.pointDiscount, 2)} VP</span>}
                      </div>
                    </div>

                    {/* Mã gỉam giá */}
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.promotionCode" />
                      </div>
                      <div className="form-input form-icon">
                        <label>
                          <input
                            id="discount"
                            type="text"
                            name="discount"
                            value={this.state.discountValue}
                            maxLength="256"
                            autoComplete="off"
                            onChange={(e) => this.handleChangeDiscount(e)}
                            style={{ padding: 0 }}
                          />
                        </label>
                      </div>
                    </div>
                    {this.state.discountError && (
                      <div className="form-error mb-4">
                        <IntlMessages id="cart.discountCodeWrong" />
                      </div>
                    )}

                    {/* <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.promotion" />
                      </div>
                      <div className="payment-item-content">0 VND</div>
                    </div>

                    <div className="payment-item total">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.total" />
                      </div>
                      <div className="payment-item-content">
                        <span>{formatMoney_2(parseInt(this.state.money, 10))} VND</span>
                      </div>
                    </div> */}

                    <div className="payment-item term">
                      <div className="input-list-Checkbox mr-2">
                        <Checkbox checked={agreedTerms} onChange={() => this.handleChangeCheckTerm()} />
                      </div>

                      <div className="term-description">
                        <IntlMessages id="cart.termPart_1" />
                        <a
                          className="text-cyan"
                          href={language === "vi" ? "https://viracresearch.com/chinh-sach-cung-cap-dich-vu/" : "https://viracresearch.com/service-provision-policy/"}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <IntlMessages id="cart.termPart_2" />
                        </a>
                        <IntlMessages id="cart.termPart_3" />
                      </div>
                    </div>
                    <div className="payment-button mt-5">
                      <Button
                        className={this.state.isDisabledBuyButton ? "compare-btn common-button btn-disabled" : "compare-btn common-button"}
                        disabled={createOrderLoading || !agreedTerms || isLoading}
                        isLoading={createOrderLoading || isLoading}
                        onClick={() => this.hanldePaymentByVipoint()}
                      >
                        <IntlMessages id="cart.buyVP" />
                      </Button>
                      <Button
                        className={this.state.isDisabledBuyButton ? "compare-btn common-button btn-disabled mt-3" : "compare-btn common-button mt-3"}
                        disabled={createOrderLoading || !agreedTerms || isLoading}
                        isLoading={createOrderLoading || isLoading}
                        onClick={() => this.hanldePayment()}
                      >
                        <IntlMessages id="cart.buy" />
                      </Button>
                      {/* <Button
                        className={`compare-btn common-button mt-3`}
                        disabled={this.state.isDisabledRequestButton || createRequestLoading || !agreedTerms || isLoading}
                        isLoading={createRequestLoading || isLoading}
                        onClick={() => this.hanldeSendRequest()}
                      >
                        <IntlMessages id="cart.request" />
                      </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-item">
                <Findnotfound isCartNoData={true} />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    userAccount: state.accountReducer.userAccount
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleGetDataFromLocalStorage: () => dispatch(actGetDataFromLocalStorage()),

    // xóa khỏi cart
    removeFromCart: (arrToDel) => dispatch(actRemoveFromCart(arrToDel)),

    // gửi yêu cầu
    handleSendRequestData: (arrToSend, selectedList, cbSuccess) => dispatch(actSendRequestData(arrToSend, selectedList, cbSuccess)),

    // mua hàng
    createOrder: (body) => dispatch(actCreateOrder(body)),

    // lấy thông tin đơn hàng
    getOrder: (sessionId) => dispatch(actGetOrder(sessionId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
