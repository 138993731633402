import React, { Component } from "react";
import FaqsFilter from "../../components/Faqs/FaqsFilter";
import { FaqsContent } from "../../components/Faqs/FaqsContent";
import { Accordion } from "semantic-ui-react";
import ic_faqs_tru from "../../assets/images/ic_faqs_tru.svg";
import ic_faqs_plus from "../../assets/images/ic_faqs_plus.svg";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { actRequestFAQ } from "../../redux/actions";
import { filter, includes } from "lodash";
import LoaderTable from "../../components/LoaderTable";
import getUrlParam from "../../utils/getUrlParam";
import Findnotfound from "../../components/Findnotfound";
import { injectIntl } from "react-intl";
import Header from "../../components/Header/HomeHead";
import Footer from "../../components/Footer/Footer";
export class Faqs extends Component {
  state = {
    activeIndex: 0,
    activeTab: 1,
    listItem: [],
    content: "",
    FaqsDataFirst: "",
    // ccc
    value: "",
    suggestions: [],
    isPax: getUrlParam()["pax"] ? true : false
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  toggle = (tab, keyTitle) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
    this.findContentData(tab, keyTitle);
  };
  findContentData = (tab, keyTitle) => {
    const { listItem } = this.state;
    let dataItem = listItem[keyTitle]["faq"];
    let arrDataFilter = filter(dataItem, { id: tab });
    this.setState({
      content: arrDataFilter[0]
    });
  };
  componentDidMount() {
    document.title = "VIRACE | Faqs";

    this.props.requestFAQ();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const listFAQ = nextProps.faqReducer.listFAQ;
    if (listFAQ) {
      this.setState({
        listItem: listFAQ
      });
      if (listFAQ[0]) {
        this.setState({
          FaqsDataFirst: listFAQ[0].faq,
          activeTab: listFAQ[0].faq[0].id
        });
      }
    }
  }
  // filter
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }
  getSuggestions(value) {
    const { listItem } = this.state;
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (escapedValue === "") {
      return [];
    }
    return listItem
      .map((section) => {
        return {
          title: section.name,
          listItem: section.faq.filter((item) => includes(item.questions.toLowerCase(), escapedValue))
        };
      })

      .filter((section) => section.listItem.length > 0);
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { messages } = this.props.intl;
    const { activeIndex, FaqsDataFirst, value, suggestions, isPax } = this.state;
    const { listFAQ, loading, listFAQFormat } = this.props.faqReducer;
    let listFAQFormatx =
      listFAQFormat &&
      listFAQFormat.length > 0 &&
      listFAQFormat.filter((props) => {
        return props.id === getUrlParam()["pax"];
      });
    const inputProps = {
      value,
      onChange: this.onChange,
      placeholder: messages[`newWord.InputQuestion`]
    };
    return (
      <div className="faq-page">
        <Header history={this.props.history} />
        <main className="contacts-container">
          {!loading ? (
            <div className="_views_faqs">
              <FaqsFilter
                inputProps={inputProps}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              />
              {!isPax && listFAQ.length > 0 ? (
                <React.Fragment>
                  <div className="fabs_content row equal-height mb-4">
                    <div className="col-lg-6 col-xl-5 fabs_list_question">
                      <div className="card-item">
                        <Accordion>
                          {listFAQ.map((props, keyTitle) => {
                            return (
                              <React.Fragment key={keyTitle}>
                                <Accordion.Title active={activeIndex === props.id} index={keyTitle} onClick={this.handleClick}>
                                  {props.name ? props.name : ""}
                                  <div className="ic_toggle">
                                    <img src={activeIndex === keyTitle ? ic_faqs_tru : ic_faqs_plus} alt="" />
                                  </div>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === keyTitle}>
                                  <div className="fabs_content_list">
                                    <Nav>
                                      {props.faq &&
                                        props.faq.length > 0 &&
                                        props.faq.map((item, key) => {
                                          return (
                                            <NavItem key={key}>
                                              <NavLink
                                                className={classnames({
                                                  active: this.state.activeTab === item.id
                                                })}
                                                onClick={() => {
                                                  this.toggle(item.id, keyTitle);
                                                }}
                                              >
                                                <i className="fas fa-circle"></i>
                                                {item.questions}
                                              </NavLink>
                                            </NavItem>
                                          );
                                        })}
                                    </Nav>
                                  </div>
                                </Accordion.Content>
                              </React.Fragment>
                            );
                          })}
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 fabs_list_answer">
                      <div className="card-item">
                        <FaqsContent
                          search={this.props.location.search}
                          data={this.state.content}
                          dataFirst={FaqsDataFirst && FaqsDataFirst[0] ? FaqsDataFirst[0] : ""}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : !isPax && listFAQ.length === 0 ? (
                <Findnotfound
                  title={"Không có dữ liệu!"}
                  customStyle={{ marginTop: "4%" }}
                  customStyleImg={{
                    width: "20%"
                  }}
                />
              ) : (
                <div className="fabs_content mb-4">
                  <div className="fabs_list_answer card-item" style={{ width: "100%", height: "500px" }}>
                    <FaqsContent
                      search={this.props.location.search}
                      data={this.state.content}
                      dataFirst={listFAQFormatx && listFAQFormatx[0] ? listFAQFormatx[0] : ""}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="faq-page">
              <LoaderTable isLoaded={!loading} styleCustom={{ height: "80vh" }} />
            </div>
          )}
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqReducer: state.faqReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestFAQ: () => {
      dispatch(actRequestFAQ());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Faqs));
