import * as Types from "../actions";
var initialState = {
  companyOtherSelected: [],
  companyOtherSelectedID: [],
  companyDNSelected: [],
  companyDNSelectedID: [],
  // tat ca ID company DN1
  CompanyIDDN1: [],
  CompanyIDDN1VSIC: [],
  // tat ca ID company DN2
  CompanyIDDN2: [],
  CompanyIDDN2VSIC: [],
  storageChartTarget: [],
  storageChartFinancial: [],
  storageChartCompareAverage: [],
  storageChartIndustryInformationTab3: [],
  type: null,
  loadingChart: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SAVE_DATA_COMPANYORTHER:
      var dataCPM = action.data;
      if (dataCPM && dataCPM.length > 0) {
        var dataID = dataCPM.reduce(function (prev, vlue) {
          return [...prev, vlue.id];
        }, []);
      }
      return {
        ...state,
        companyOtherSelected: action.data,
        companyOtherSelectedID: dataID,
      };
    case Types.SAVE_DATA_COMPANYDN:
      var dataCPMN = action.data;
      if (dataCPMN && dataCPMN.length > 0) {
        var dataIDX = dataCPMN.reduce(function (prev, vlue) {
          return [...prev, vlue.id];
        }, []);
        var ALLVSIC = dataCPMN.reduce(function (prev, vlue) {
          return [...prev, vlue.vsicId];
        }, []);
        var ALLVSICID = dataCPMN.reduce(function (prev, vlue) {
          return [...prev, { id: vlue.id, vsic: vlue.vsicId }];
        }, []);
        var CompanyIDDN1 = dataCPMN.filter(item => item.Group === "dn1").reduce(function (prev, vlue) {
          return [...prev, vlue.id];
        }, []);
        var CompanyIDDN1VSIC = dataCPMN.filter(item => item.Group === "dn1").reduce(function (prev, vlue) {
          return [...prev, { id: vlue.id, vsic: vlue.vsicId }];
        }, []);

        var CompanyIDDN2 = dataCPMN.filter(item => item.Group === "dn2").reduce(function (prev, vlue) {
          return [...prev, vlue.id];
        }, []);
        var CompanyIDDN2VSIC = dataCPMN.filter(item => item.Group === "dn2").reduce(function (prev, vlue) {
          return [...prev, { id: vlue.id, vsic: vlue.vsicId }];
        }, []);
      }
      return {
        ...state,
        companyDNSelectedVSIC: ALLVSIC,
        companyDNSelectedVSICID: ALLVSICID,
        companyDNSelected: action.data,
        //tat ca ID company 
        companyDNSelectedID: dataIDX,
        // tat ca ID company DN1
        CompanyIDDN1: CompanyIDDN1,
        CompanyIDDN1VSIC: CompanyIDDN1VSIC,
        CompanyIDDN2VSIC: CompanyIDDN2VSIC,
        CompanyIDDN2: CompanyIDDN2,
      };
    case Types.UPDATE_REMEMBER_DATA_COMPANYORTHER:
      let updateValue = {
        ...state.companyOtherSelected[action.index],
        remember: !action.value
      };
      const companyData = [...state.companyOtherSelected];
      companyData.splice(action.index, 1, updateValue);

      return {
        ...state,
        companyOtherSelected: companyData
      };
    case Types.STORAGE_CHART_TARGET:
      return {
        ...state,
        storageChartTarget: action.data
      };
    case Types.STORAGE_CHART_FINANCIAL:
      return {
        ...state,
        storageChartFinancial: action.data
      };
    case Types.STORAGE_CHART_FINANCIAL_RESET:
      return {
        ...state,
        storageChartFinancial: [],
        storageChartTarget: []
      };

    default:
      return state;
  }
};
