import React from "react";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import "./customBreadcrumbs.scss";
import { NavLink } from "react-router-dom";
import { injectIntl } from "react-intl";
import IntlMessages from "../utils/IntlMessages";
import mainRoutes from "../routes/main";

const Breadcrumbs = ({ breadcrumbs, intl }) => {
  return (
    <div className="ui breadcrumb breadcrumbCustom">
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <React.Fragment key={index}>
          {index === breadcrumbs.length - 1 ? (
            breadcrumb.props && breadcrumb.props.children ? (
              <span className="active-breadcrumb">
                <IntlMessages id={`breadcrumb.${breadcrumb.props.children.toLowerCase()}`} />
              </span>
            ) : (
              breadcrumb
            )
          ) : (
            <NavLink key={match.url} className="text-cyan" to={match.url === "/" ? "/menu-dieu-huong" : match.url}>
              {match.url === "/" ? (
                <IntlMessages id={`breadcrumb.menu-dieu-huong`} />
              ) : breadcrumb.props && breadcrumb.props.children ? (
                <IntlMessages id={`breadcrumb.${breadcrumb.props.children.toLowerCase()}`} />
              ) : (
                breadcrumb
              )}
            </NavLink>
          )}
          <i className="right angle icon divider"></i>
        </React.Fragment>
      ))}
    </div>
  );
};
export default withBreadcrumbs(mainRoutes)(injectIntl(Breadcrumbs));
