import React, { Component } from "react";
import { CompanyItem } from "./CompanyChild/CompanyItem.jsx";
import Pagination from "../../components/Pagination/PaginationSimple";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { formatMoney } from "../../utils/formatMoney";
import IntlMessages from "../../utils/IntlMessages";
import { callApiServer } from "../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";
import { saveAs as importedSaveAs } from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";
import { actAddToCart } from "../../redux/actions.js";
import ConfirmPay from "../../views/AccountsManagement/ConfirmPayComponent.jsx";

export class CompanyInfoContent extends Component {
  state = {
    activePage: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
    lang: "vi",
    // en-US

    isDownloading: false,
    isDownloading_2: false,
    alert: ""
  };
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.props.handlePushPaging(activePage));
  };

  actChangeSortMinMax = (e, { name, value }) => {
    this.props.handleSortMinMax(value, name);
  };

  handleDownLoad(txtsearch) {
    const { messages } = this.props.intl;

    this.setState({
      isDownloading: true
    });
    const params = {
      searchText: txtsearch,
      vdl: "",
      tts: "",
      ln: "",
      dt: "",
      kv: "",
      checkSameType: ""
    };
    callApiServer
      .get("/Company/SearchCompany_v2/Export", { params: params })
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  actAlert = (messagex) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {messagex}
        </SweetAlert>
      )
    });
  };
  async handleDownLoad_2(txtsearch) {
    const { messages } = this.props.intl;
    const { activePage } = this.state;

    const toolTipContent = {
      basic: txtsearch,
      advance: {}
    };
    this.setState({
      isDownloading_2: true
    });

    const body = {
      searchText: txtsearch,
      searchType: "Basic",
      searchTooltip: JSON.stringify(toolTipContent),
      pageIndex: 0,
      pageSize: 10,
      areas: [],
      vsics: [],
      financials: [],
      companyTypes: [],
      legals: [],
      owners: [],
      importExportTurnover: {
        importExportHsValue: [],
        importExportYearValue: []
      }
    };
    try {
      const response = await callApiServer.post("/Company/export-search-create-product", body);
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.openPopupConfirmBuy(data.point, data.price, data.productType, 0, data, toolTipContent);
        this.setState({
          isDownloading_2: false
        });
      } else {
        this.setState({
          isDownloading_2: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloading_2: false
      });
    }
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail, toolTipContent) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.productId}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            toolTipContent={toolTipContent}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail, toolTipContent) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }
  render() {
    const { txtsearch } = this.props;
    const {
      totalItems,
      // pageIndex,
      companyData,
      totalPage
    } = this.props.companyReducer;

    const { activePage, isDownloading, isDownloading_2 } = this.state;
    const { messages } = this.props.intl;

    const { isEmailSpecial } = this.props.userAccount;
    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="company-info-content mb-4">
          <div className="company-info-content-main card-item">
            <div className="search-result-heading pb-3">
              <p className="search-result-title">{`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}</p>
              {isEmailSpecial ? (
                <div>
                  {isDownloading ? (
                    <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                  ) : (
                    <div
                      className="btn-export-data text-cyan"
                      onClick={() => {
                        this.handleDownLoad(txtsearch);
                      }}
                    >
                      <em className="material-icons">download</em>
                      <IntlMessages id="newWord.downloadSearchResult" />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {isDownloading_2 ? (
                    <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                  ) : (
                    <div
                      className="btn-export-data text-cyan"
                      onClick={() => {
                        this.handleDownLoad_2(txtsearch);
                      }}
                    >
                      <em className="material-icons">download</em>
                      <IntlMessages id="newWord.downloadSearchResult" />
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="search-result-list">
              <div className="row">
                {companyData &&
                  companyData.length > 0 &&
                  companyData.map((props, index) => {
                    return (
                      <CompanyItem
                        {...this.props}
                        key={index}
                        name={props.name}
                        nameEnglish={props.nameEnglish}
                        id={props.id}
                        taxNumber={props.taxNumber}
                        vsic={props.vsic}
                        address={props.address}
                        phoneNumber={props.phoneNumber}
                        fax={props.fax}
                        webSite={props.webSite}
                        vsicID={props.vsicId}
                        vsicName={props.vsicName}
                        district={props.district}
                        province={props.province}
                        enNetProfitLabel={props.enNetProfitLabel}
                        enNetRevenueLabel={props.enNetRevenueLabel}
                        netProfitLabel={props.netProfitLabel}
                        netRevenueLabel={props.netRevenueLabel}
                        netProfitValue={props.netProfitValue}
                        netRevenueValue={props.netRevenueValue}
                        automateCompanyReportPdf={props.automateCompanyReportPdf}
                        automateCompanyReportExcel={props.automateCompanyReportExcel}
                        registrationDate={props.registrationDates}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="foot-pagination">
              <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};
const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer,
    settings: state.settings,
    userAccount: state.accountReducer.userAccount
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(CompanyInfoContent)));
