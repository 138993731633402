import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import Findnotfound from "../../../components/Findnotfound";

class AccessHistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    funcRender = (content) => {
        if (content && content !== null && content !== "" && content) {
            return content.split('|');
        }
    }
    render() {
        const { openModal, content } = this.props;
        const { messages } = this.props.intl;

        return (
            <div className="modal-notification">
                <Modal
                    isOpen={openModal}
                    toggle={() => this.props.handleCloseNotiModal()}
                    className="custom-modal-notification"
                >
                    <ModalHeader
                        toggle={() => this.props.handleCloseNotiModal()}
                    >
                        <IntlMessages id="compare.businessGroup" />
                    </ModalHeader>
                    <ModalBody style={{ paddingBottom: "20px" }}>
                        {this.funcRender(content) && this.funcRender(content).length > 0 ? this.funcRender(content).map((props, index) => {
                            return (
                                <p style={{ fontSize: "14px", marginBottom: "8px" }} key={index}>{props}</p>
                            )
                        }) : <Findnotfound
                                title={messages[`newWord.notAvailable`]}
                                customStyle={{
                                    border: 0,
                                    boxShadow: "none",
                                    borderRadius: 0,
                                    marginTop: 0,
                                    marginBottom: 0,
                                    height: 200
                                }}
                            />}
                    </ModalBody>

                </Modal>
            </div>
        );
    }
}

export default (injectIntl(AccessHistoryModal));
