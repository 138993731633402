import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import LoaderTable from "../../../components/LoaderTable";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { Input } from "reactstrap";
import { Dropdown, Menu } from "semantic-ui-react";
import NoData from "../../../components/NoData";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import vi from "date-fns/locale/vi";
import { actGetHistoryRequested_new, actGetRequestOrderCode, actGetHistoryPurchase, actGetStatusOrder, actGetFillInfo } from "../../../services/accountManagement.service";
import { formatMoney, formatMoney_2 } from "../../../utils/formatMoney";
import SweetAlert from "react-bootstrap-sweetalert";
import AutoReportTemplate from "../../CompanyInfoPage/Tabs/AutoReportTemplate";
import getUrlParam from "../../../utils/getUrlParam";
import NotificationAlert from "react-notification-alert";
import { actGetOrder } from "../../../redux/Cart/actions";
import { callApiServer } from "../../../utils/callApiServer.js";
import { saveAs as importedSaveAs } from "file-saver";
import { Popup } from "semantic-ui-react";
// import ModalListVSIC from "../../../components/CompanyInfo/CompanyChild/ListVSIC.jsx";
import ModalInvoiceRequest from "../../../components/AccountsManagement/ComponentTabs/ModalInvoiceRequest.jsx";

registerLocale("enGB", enGB);
registerLocale("vi", vi);

export class TabContentTabHistory extends Component {
  constructor(props) {
    super(props);

    // const { messages } = this.props.intl;

    this.state = {
      orderId: getUrlParam()["orderId"] ? getUrlParam()["orderId"] : "",
      tab: getUrlParam()["tab"] ? getUrlParam()["tab"] : "",
      templateAutoReport: "",
      modalInvoiceRequest: "",

      pageSize: 10,
      page_Request: 1,
      status_Request: "",
      txtsearch_Request: "",
      listOrderCodeRequested: [],
      dataRequested: {},

      selectedOrderCode: undefined,
      // cái này thêm vào cho Lịch sử mua hàng/ Phương thức thanh toán
      selectedPaymentMethod: undefined,

      isLoadingRequested: false,
      page_Purchase: 1,
      txtsearch_Purchase: "",
      dataPurchase: {},
      isLoadingPurchase: false,
      fromDate: null,
      toDate: null,

      toggleModalInvoiceRequest: true,

      companyName: "",
      taxCode: "",
      address: "",
      totalRecord: 0
    };
  }

  activeColorStatus = (status) => {
    if (status === "New") {
      return <td style={{ color: "#0073e6", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
    if (status === "Confirming") {
      return <td style={{ color: "#2eb82e", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
    if (status === "Proccessing") {
      return <td style={{ color: "#ff6600", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
    if (status === "Canceled") {
      return <td style={{ color: "#ff1a1a", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
    if (status === "Completed") {
      return <td style={{ color: "#2ecc71", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
    if (status === "Pending") {
      return <td style={{ color: "#f39c12", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
    if (status === "Approved") {
      return <td style={{ color: "#1abc9c", fontWeight: 500, textAlign: "right" }}>{status}</td>;
    }
  };

  componentDidMount() {
    const { orderId, tab } = this.state;
    if (tab === "2" && orderId) {
      this.getStatusOrder(orderId);
    }

    // api cua phan Lich su yeu cau (da xoa)
    // this.getRequestOrderCode();
    // this.getDataRequested();

    this.getDataPurchare();
    this.handleGetFillInfo();
  }

  handleGetFillInfo = async () => {
    const response = await actGetFillInfo();
    if (response && response.data && response.data.data) {
      const fillData = response.data.data;

      this.setState({
        companyName: fillData.companyName,
        taxCode: fillData.taxId,
        address: fillData.address
      });
    }
  };

  //Kiểm tra trạng thái đơn hàng
  async getStatusOrder(orderId) {
    try {
      const responseStatus = await actGetStatusOrder(orderId);
      if (responseStatus && responseStatus.data) {
        const status = responseStatus.data.data;
        if (status === true) {
          this.notiFy("success");
          this.props.getOrder(orderId);
        } else {
          this.notiFy("fail");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  notiFy(status) {
    const { messages } = this.props.intl;

    if (status === "success") {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["cart.isPurchaseSuccess"]}</div>
          </div>
        ),
        type: "success",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    } else {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["cart.isPurchaseFail"]}</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  // Lịch sử yêu cầu
  async getRequestOrderCode() {
    try {
      const response = await actGetRequestOrderCode();
      if (response && response.data && response.data.data) {
        let originData = response.data.data;
        let formatList = [
          {
            key: 0,
            value: "",
            text: "All"
          }
        ];
        originData.map((e, index) => {
          formatList.push({
            key: index + 1,
            value: e,
            text: e
          });
        });
        this.setState({
          listOrderCodeRequested: formatList
        });
      } else {
        this.setState({
          listOrderCodeRequested: []
        });
      }
    } catch (error) {
      this.setState({
        listOrderCodeRequested: []
      });
    }
  }

  // Lịch sử yêu cầu cung cấp data
  async getDataRequested() {
    try {
      this.setState({
        dataRequested: {},
        isLoadingRequested: true
      });
      const { pageSize, page_Request, txtsearch_Request, selectedOrderCode, status_Request } = this.state;
      let body = {
        pageSize: pageSize,
        pageIndex: page_Request,
        searchText: txtsearch_Request,
        requestCode: selectedOrderCode ? selectedOrderCode : "",
        status: status_Request
      };
      const response = await actGetHistoryRequested_new(body);
      if (response && response.data && response.data.data) {
        this.setState({
          dataRequested: response.data.data,
          isLoadingRequested: false
        });
      }
    } catch (error) {
      this.setState({
        dataRequested: {},
        isLoadingRequested: false
      });
    }
  }

  handlePaginationRequestedChange = (e, { activePage }) => {
    this.setState(
      {
        page_Request: activePage
      },
      () => this.getDataRequested()
    );
  };

  handleChangeCode = (value) => {
    this.setState(
      {
        selectedOrderCode: value
      },
      () => this.getDataRequested()
    );
  };

  handleChangeMethod = (value) => {
    this.setState(
      {
        // thêm cái này vào để khi thay đổi select, sẽ trở về phân trang 1
        page_Purchase: 1,
        selectedPaymentMethod: value
      },
      () => this.getDataPurchare()
    );
  };

  // Xử lí khi thay đổi pageSize
  handleChangePageSize = (value) => {
    this.setState(
      {
        // thêm cái này vào để khi thay đổi select, sẽ trở về phân trang 1
        page_Purchase: 1,
        pageSize: value
      },
      () => this.getDataPurchare()
    );
  };

  changeFilter = (value) => {
    this.setState(
      {
        status_Request: value
      },
      () => this.getDataRequested()
    );
  };

  handleSearchChangeCompany = (e) => {
    this.handleDebounceCompany(e.target.value);
  };

  handleDebounceCompany = debounce((value) => {
    this.setState(
      {
        txtsearch_Request: value
      },
      () => this.getDataRequested()
    );
  }, 600);

  handleDownloadReport = (e) => {
    if (e.target.value !== "") {
      this.setState({
        selectedYearDownload: e.target.value,
        isChooseDownload: false
      });
    } else {
      this.setState({
        isChooseDownload: true
      });
    }
  };

  // Lịch sử mua hàng
  async getDataPurchare() {
    try {
      this.setState({
        dataPurchase: {},
        isLoadingPurchase: true
      });
      const { pageSize, page_Purchase, txtsearch_Purchase, fromDate, toDate, selectedPaymentMethod } = this.state;

      let body = {
        pageSize: pageSize,
        pageIndex: page_Purchase,
        searchText: txtsearch_Purchase ? txtsearch_Purchase : "",
        fromDate: fromDate
          ? moment(fromDate)
              .format("MM/DD/YYYY")
              .toString()
          : "",
        toDate: toDate
          ? moment(toDate)
              .format("MM/DD/YYYY")
              .toString()
          : "",
        currency: selectedPaymentMethod ? selectedPaymentMethod : "",
        language: localStorage.getItem("currentLanguage")
      };

      const response = await actGetHistoryPurchase(body);
      if (response && response.data && response.data.data) {
        this.setState({
          dataPurchase: response.data.data,
          totalRecord: response.data.data.totalItems,
          isLoadingPurchase: false
        });
      }
    } catch (error) {
      this.setState({
        dataPurchase: {},
        isLoadingPurchase: false
      });
    }
  }

  handleChangeDate(newStartDate, newEndDate) {
    this.setState(
      {
        fromDate: newStartDate,
        toDate: newEndDate,
        pageSize: 10,
        page_Purchase: 1
      },
      () => {
        if ((newStartDate === null && newEndDate === null) || (newStartDate !== null && newEndDate !== null)) {
          this.getDataPurchare();
        }
      }
    );
  }

  handlePaginationPurchaseChange = (e, { activePage }) => {
    this.setState(
      {
        page_Purchase: activePage
      },
      () => this.getDataPurchare()
    );
  };

  handleSearchChangeReportName = (e) => {
    this.handleDebounceReportName(e.target.value);
  };

  handleDebounceReportName = debounce((value) => {
    this.setState(
      {
        page_Purchase: 1,
        txtsearch_Purchase: value
      },
      () => this.getDataPurchare()
    );
  }, 600);

  // templatModalInvoice() {
  //   return (
  //     <SweetAlert
  //       default
  //       hideOverlay={false}
  //       buttons={false}
  //       allowEscape={true}
  //       customClass="auto-report-popup" showCancel={true}>
  //       <ModalInvoiceRequest toggleModal={this.state.toggleModalInvoiceRequest} handleCloseModal={this.closeModalInvoiceRequest} />
  //     </SweetAlert>
  //   );
  // }

  handleModalInvoiceRequest = () => {
    // const { messages } = this.props.intl;

    this.setState({
      modalInvoiceRequest: (
        <SweetAlert customClass="invoice-request-popup" default hideOverlay={false} buttons={false} allowEscape={false} showCancel={false} showConfirm={false}>
          <ModalInvoiceRequest
            toggleModal={this.state.toggleModalInvoiceRequest}
            companyName={this.state.companyName}
            taxCode={this.state.taxCode}
            address={this.state.address}
            handleCloseModal={this.closeModalInvoiceRequest}
          />
        </SweetAlert>
      )
    });

    // {
    //   toggleModalInvoiceRequest && <ModalInvoiceRequest toggleModal={toggleModalInvoiceRequest} handleCloseForget={this.handleModalInvoiceRequest} />;
    // }
  };

  closeModalInvoiceRequest = () => {
    this.setState({
      modalInvoiceRequest: ""
    });
  };

  handleDownloadReportAuto(id) {
    const { messages } = this.props.intl;
    this.setState({
      templateAutoReport: (
        <SweetAlert default hideOverlay={false} buttons={false} allowEscape={true} title={"Report Company"} customClass="auto-report-popup" showCancel={true}>
          <AutoReportTemplate closeTemplate={this.closeAutoReport} id={id}></AutoReportTemplate>
        </SweetAlert>
      )
    });
  }

  closeAutoReport = () => {
    this.setState({
      templateAutoReport: ""
    });
  };

  async handleDownloadReportExcel(id, name) {
    try {
      this.setState({
        isDownloadingReportExcel: true
      });
      const fileName = `Instant_Company_Report_${name}.xlsx`;
      const urlDownload = `/Company/export-excel-company-report?companyId=${id}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingReportExcel: false
        });
      } else {
        this.setState({
          isDownloadingReportExcel: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingReportExcel: false
      });
    }
  }

  async handleDownloadCompanySearch(id, name) {
    try {
      this.setState({
        isDownloadingReportExcel: true
      });
      const fileName = `${name}.xlsx`;
      const urlDownload = `/Company/download-company-search-export?productId=${id}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingCompanySearch: false
        });
      } else {
        this.setState({
          isDownloadingCompanySearch: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingCompanySearch: false
      });
    }
  }

  async handleDownloadManualOrder(id, name, type) {
    try {
      this.setState({
        isDownloadingManualOrder: true
      });
      const fileName = name;
      const urlDownload = `/Report/download-product?dynamicProductId=${id}&productType=${type}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingManualOrder: false
        });
      } else {
        this.setState({
          isDownloadingManualOrder: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingManualOrder: false
      });
    }
  }

  async handleDownloadIndustryReport(id, name, language) {
    try {
      this.setState({
        isDownloadingIndustryReport: true
      });
      const fileName = name;
      const urlDownload = `/IFReportStatistic/download-if-report-full?ifId=${id}&lang=${language}`;

      // Có cần responseType: "blob" ko?
      const response = await callApiServer.post(
        urlDownload,
        {},
        {
          responseType: "blob"
        }
      );

      if (response && response.data) {
        importedSaveAs(response.data, fileName);

        this.setState({
          isDownloadingIndustryReport: false
        });
      } else {
        this.setState({
          isDownloadingIndustryReport: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingIndustryReport: false
      });
    }
  }

  renderActionReport(subItem) {
    const language = localStorage.getItem("currentLanguage");
    return <span className="report-item-name">{language === "vi" ? subItem.actionName : subItem.actionEnName}</span>;
  }

  renderDetail(subItem) {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");

    const productType = subItem.productType;

    switch (productType) {
      case "CompanyDetailView":
        return (
          <Link target="_blank" className="text-cyan" to={`/chi-tiet-doanh-nghiep/${subItem.productId ? subItem.productId : ""}`}>
            <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>
          </Link>
        );

      case "CompanyReportPdf":
        // if (subItem.isPurchase === true) {
        return (
          <div className="report-item-container">
            <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>
            <span className="report-item-action">
              {subItem.isPurchase === true && (
                <div className="download-report-auto text-cyan d-flex flex-row" onClick={() => this.handleDownloadReportAuto(subItem.productId)}>
                  <em className="material-icons mr-1">download</em>
                  <span style={{ minWidth: "51px" }}>( pdf )</span>
                </div>
              )}
            </span>
          </div>
        );
      // } else {
      //   return "";
      // }

      case "CompanyReportExcel":
        return (
          <div className="report-item-container">
            <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>
            <span className="report-item-action">
              {subItem.isPurchase === true && (
                <div
                  className="download-report-auto text-cyan d-flex flex-row"
                  onClick={() => this.handleDownloadReportExcel(subItem.productId, subItem.productEnName ? subItem.productEnName : subItem.productName ? subItem.productName : "")}
                >
                  <em className="material-icons mr-1">download</em>
                  <span style={{ minWidth: "51px" }}>( excel )</span>
                </div>
              )}
            </span>
          </div>
        );

      case "CompanySearchDownload":
        const toolTipResponse = JSON.parse(subItem.tooltip);
        let tooltipData;
        if (toolTipResponse) {
          tooltipData = JSON.parse(toolTipResponse.SearchTooltip ? toolTipResponse.SearchTooltip : "");
        }

        return (
          <div className="report-item-container">
            <span className="report-item-name">{subItem.productName}</span>

            <span className="report-item-action">
              {subItem && subItem.isPurchase && (
                <div
                  className="download-report-auto text-cyan d-flex flex-row"
                  onClick={() => this.handleDownloadCompanySearch(subItem.productId, subItem.productName ? subItem.productName : subItem.productEnName ? subItem.productEnName : "")}
                >
                  <em className="material-icons mr-1">download</em>
                </div>
              )}

              <Popup
                trigger={
                  <span style={{ height: "25px", fontSize: "16px", color: "#b9cbe8" }} className="material-icons  tooltip-icon cursor-default ml-2">
                    info
                  </span>
                }
                className="custom-popup ml-1 report-item-action"
              >
                <ul className="pl-0 mb-0">
                  {tooltipData && tooltipData.basic ? (
                    <li>
                      - <IntlMessages id="industry.filter.keyWord" /> : <span>{tooltipData.basic}</span>
                    </li>
                  ) : (
                    <React.Fragment>
                      {tooltipData && tooltipData.advance && tooltipData.advance.length > 0 && (
                        <span>
                          {tooltipData.advance.map((prop, index) => {
                            return (
                              <li key={index}>
                                -
                                {prop.type === "area" && (
                                  <span>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${prop.area.districtName ? prop.area.districtName + ", " : ""}${
                                      prop.area.provinceName
                                    }`}</span>
                                  </span>
                                )}
                                {prop.type === "industry" && (
                                  <span>
                                    <IntlMessages id="sidebar.Industry" /> {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </span>
                                )}
                                {prop.type === "companyType" && (
                                  <span>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :{language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} (
                                    {prop.dataPoint}):{" "}
                                  </span>
                                )}
                                {prop.type === "legal" && (
                                  <span>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.idPerson}{" "}
                                  </span>
                                )}
                                {prop.type === "owner" && (
                                  <span>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.showId}{" "}
                                  </span>
                                )}
                                {prop.type === "finance" && (
                                  <span>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit})`
                                        : ""}
                                    </span>
                                  </span>
                                )}
                                {prop.type === "importExport_year" && (
                                  <span>
                                    {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.unit}) `
                                        : ""}
                                      {prop.criteria.to ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.unit})` : ""}
                                    </span>
                                  </span>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <span>
                                    {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </span>
                                )}
                                {prop.type === "registration_date" && (
                                  <span>
                                    <IntlMessages id="advanceSearch.founding_date.condition_tilte" />: <IntlMessages id="advanceSearch.founding_date.condition_from" />{" "}
                                    {prop.fromDate ? " " + this.renderTime2(prop.fromDate) : ""} <IntlMessages id="advanceSearch.founding_date.condition_to" />{" "}
                                    {prop.toDate ? this.renderTime2(prop.toDate) : ""}
                                  </span>
                                )}
                              </li>
                            );
                          })}
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </ul>
              </Popup>
            </span>
          </div>
        );

      case "ManualProductOrder":
        return (
          <div className="report-item-container">
            <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>
            <span className="report-item-action">
              {subItem.isPurchase && (
                <div
                  className="download-report-auto text-cyan d-flex flex-row"
                  onClick={() => this.handleDownloadManualOrder(subItem.productId, language === "vi" ? subItem.productName : subItem.productEnName, "ManualProductOrder")}
                >
                  <em className="material-icons mr-1">download</em>
                </div>
              )}
            </span>
          </div>
        );

      // Báo cáo ngành
      case "Report":
        return (
          <div className="report-item-container">
            <span className="report-item-name">
              <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${subItem.productId ? subItem.productId : ""}`}>
                {language === "vi" ? subItem.productName : subItem.productEnName}
              </Link>
            </span>

            <span className="report-item-action">
              {subItem.isPurchase && (
                <div
                  className="download-report-auto text-cyan d-flex flex-row"
                  onClick={() => this.handleDownloadIndustryReport(subItem.productId, language === "vi" ? subItem.productName : subItem.productEnName, subItem.language)}
                >
                  <em className="material-icons mr-1">download</em>
                </div>
              )}
            </span>
          </div>
        );

      // Báo cáo VSIC 4 số
      case "Vsic":
        return (
          <Link
            className="text-cyan"
            to={`/thong-ke-co-ban?productId=${subItem.productId}&statistic=SO_DOANH_NGHIEP,SO_LAO_DONG,NGUON_VON_BINH_QUAN,TAI_SAN_CO_DINH_VA_DAU_TU_DAI_HAN_BINH_QUAN,DOANH_THU_THUAN,LOI_NHUAN_TRUOC_THUE,THUE_VA_CAC_KHOAN_NOP_NGAN_SACH`}
          >
            <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>
          </Link>
        );

      // Báo cáo VSIC 8 số
      case "VsicProduct":
        return (
          <Link className="text-cyan" to={`/thong-ke-nang-cao?productId=${subItem.productId}&filterType=all&statistic=SAN_XUAT,TIEU_THU,TON_KHO`}>
            <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>
          </Link>
        );

      default:
        return <span className="report-item-name">{language === "vi" ? subItem.productName : subItem.productEnName}</span>;
    }
  }

  renderTime(time) {
    if (time) return moment(time).format("HH:mm - DD/MM/YYYY");
    else return "";
  }

  renderTime2(time) {
    if (time) return moment(time).format("DD/MM/YYYY");
    else return "";
  }

  render() {
    const language = localStorage.getItem("currentLanguage");

    const { messages } = this.props.intl;
    const {
      // status_Request,
      // page,
      // listOrderCodeRequested,
      // selectedOrderCode,

      selectedPaymentMethod,

      // dataRequested,
      // isLoadingRequested,
      // page_Request,
      fromDate,
      toDate,
      isLoadingPurchase,
      dataPurchase,
      page_Purchase,

      // toggleModalInvoiceRequest,
      pageSize
    } = this.state;

    // const optionFilter = [
    //   { value: "", text: "All" },
    //   { value: "New", text: "New" },
    //   { value: "Pending", text: "Pending" },
    //   { value: "Canceled", text: "Canceled" },
    //   { value: "Completed", text: "Completed" },
    //   { value: "Proccessing", text: "Proccessing" },
    //   { value: "Confirming", text: "Confirming" }
    // ];

    const listPaymentMethod = [
      {
        key: 0,
        value: "",
        text: messages["user.PaymentMethod"]
      },
      {
        key: 1,
        value: "VND",
        text: "VND"
      },
      {
        key: 2,
        value: "Point",
        text: "VIPOINT"
      }
    ];

    const listPageSize = [
      {
        key: 0,
        value: 10,
        text: "10"
      },
      {
        key: 1,
        value: 25,
        text: "25"
      },
      {
        key: 2,
        value: 50,
        text: "50"
      },
      {
        key: 3,
        value: 100,
        text: "100"
      }
    ];

    let locale = language === "vi" ? "vi" : "enGB";

    let minDate = new Date(moment().subtract(36, "months"));
    let maxDate = new Date();
    return (
      <div>
        {this.state.templateAutoReport}
        {this.state.modalInvoiceRequest}

        <NotificationAlert ref="notificationAlert" />

        {/* Lịch sử mua hàng */}
        <div className="manament-tabplane-history mt-5">
          <div className="management-title-and-sort-filter d-flex justify-content-between">
            <span>
              <IntlMessages id="user.DataOrderHistory" />
            </span>
          </div>

          <div className="wp_total">
            <div className="sort-filter-actions selected-custom selected-custom-select cutsom_fixed_input">
              <div className="head-top row">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="titleHistoryAccount">
                    <IntlMessages id="filter.Sortby" />
                  </span>
                </div>

                <div className="datepicker-container">
                  <span class="material-icons icon-calendar">date_range</span>
                  <DatePicker
                    className="datepicker-custom"
                    minDate={minDate}
                    maxDate={maxDate}
                    selected={fromDate}
                    onChange={([newStartDate, newEndDate]) => this.handleChangeDate(newStartDate, newEndDate)}
                    selectsRange
                    startDate={fromDate}
                    endDate={toDate}
                    dateFormat="dd/MM/yyyy"
                    showDayMonthYearPicker
                    isClearable={true}
                    placeholderText={messages[`user.DatePurchase`]}
                    locale={locale}
                    disabledKeyboardNavigation
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                <div className="report-search">
                  <Input type="text" placeholder={messages[`user.ActionReportName`]} onChange={this.handleSearchChangeReportName}></Input>
                </div>

                {/* Cái này là phần select Lịch sử mua hàng/ Phương thức thanh toán */}
                <div className="selected-custom-select">
                  <Menu compact className="dropdown-vsic-list">
                    <Dropdown
                      fluid
                      search
                      selection
                      placeholder={messages[`user.PaymentMethod`]}
                      noResultsMessage={messages["search.notfound.text"]}
                      value={selectedPaymentMethod}
                      onChange={(e, { value }) => this.handleChangeMethod(value)}
                      options={listPaymentMethod}
                      selectOnBlur={false}
                    ></Dropdown>
                  </Menu>
                </div>
              </div>

              <div className="text-cyan invoice-request" onClick={this.handleModalInvoiceRequest}>
                {/* <a className="text-cyan" onClick={this.handleModalListVSIC}> */}
                <IntlMessages id="user.requestInvoice.title" />
                <em className="material-icons">keyboard_double_arrow_right</em>
                {/* </a> */}
              </div>
            </div>
          </div>

          {!isLoadingPurchase && dataPurchase && dataPurchase.data && dataPurchase.data.length > 0 ? (
            <div className="table-custom ">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">
                      <IntlMessages id="table.sttTable" />
                    </th>
                    <th style={{ maxWidth: "150px" }}>
                      <IntlMessages id="user.orderCode" />
                    </th>

                    <th>
                      <IntlMessages id="user.ActionReportName" />
                    </th>

                    <th>
                      <IntlMessages id="user.DetailContent" />
                    </th>

                    <th className="text-right">
                      <IntlMessages id="user.Amount" />
                    </th>
                    <th className="text-right">
                      <IntlMessages id="user.Date" />
                    </th>
                    <th className="text-right">
                      <IntlMessages id="vpoint.history.effectDay" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {dataPurchase.data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">
                          <div className="pt-5">{(page_Purchase - 1) * pageSize + index + 1}</div>
                        </td>
                        <td>
                          <div className="pt-5" style={{ maxWidth: "150px", wordBreak: "break-word" }}>
                            {item.orderId ? item.orderId : ""}
                          </div>
                        </td>

                        <td>
                          {item.productInfo && item.productInfo.length
                            ? item.productInfo.map((subItem, index) => {
                                return <div key={index}>{this.renderActionReport(subItem)}</div>;
                              })
                            : ""}
                        </td>

                        <td>
                          {item.productInfo && item.productInfo.length
                            ? item.productInfo.map((subItem, i) => {
                                return <div key={i}>{this.renderDetail(subItem)}</div>;
                              })
                            : ""}
                        </td>

                        <td className="text-right">
                          {item.amount ? formatMoney_2(item.amount, 2) : ""} {item.currency === "Point" ? "VP" : "VND"}
                        </td>
                        <td className="text-right">
                          <span>{item.createdDate ? this.renderTime(item.createdDate) : ""}</span>
                        </td>
                        <td className="text-right">
                          {item.productInfo && item.productInfo.length
                            ? item.productInfo.map((subItem, i) => {
                                return (
                                  <div style={{ height: "75px" }} key={i}>
                                    {this.renderTime(subItem.effectDay)}
                                  </div>
                                );
                              })
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : isLoadingPurchase ? (
            <LoaderTable isLoaded={!isLoadingPurchase} styleCustom={{ height: "80vh" }} />
          ) : (
            <NoData />
          )}
          {dataPurchase && dataPurchase.data && dataPurchase.data.length > 0 && (
            <div
              className="foot-pagination text-right"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px"
              }}
            >
              <div
                style={{
                  color: "#B6C4DE"
                }}
              >
                <IntlMessages id="user.totalRecord" />: {this.state.totalRecord}
              </div>

              <Pagination totalPages={dataPurchase.totalPages} activePage={page_Purchase} onPageChange={this.handlePaginationPurchaseChange} />

              <div className="selected-custom-select">
                <div
                  style={{
                    display: "inline-block",
                    color: "#B6C4DE",
                    marginRight: "5px"
                  }}
                >
                  <IntlMessages id="user.pageSize" />:
                </div>

                {/* dropdown-vsic-list */}
                <Menu compact className="dropdown-page-size">
                  <Dropdown
                    fluid
                    selection
                    // placeholder="10"
                    value={pageSize}
                    onChange={(e, { value }) => this.handleChangePageSize(value)}
                    options={listPageSize}
                    selectOnBlur={false}
                  ></Dropdown>
                </Menu>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountManagement: state.accountReducer.requestedHistoryData
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    // lấy thông tin đơn hàng
    getOrder: (sessionId) => dispatch(actGetOrder(sessionId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabContentTabHistory));
