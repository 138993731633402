import React, { Component } from "react";
import CanvasJSReact from "../../assets/js/canvasjs.react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class ColumnChart extends Component {

  constructor() {
    super();
    this.state = {
      dataChart: [],
      colorChart: [
        "#4661EE",
        "#EC5657",
        "#1BCDD1",
        "#8FAABB",
        "#B08BEB",
        "#3EA0DD",
        "#F5A52A",
        "#23BFAA",
        "#FAA586",
        "#EB8CC6",
      ],
    };
  }

  toggleDataSeries = e => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  };

  render() {
    const {
      backgroundColor,
      tickColor,
      suffix,
      height,
      enabledToolTip,
      title,
      subTitle,
      dataChart
    } = this.props;
    const { colorChart } = this.state;
    const renderDataChart =
      dataChart &&
      dataChart.length > 0 &&
      dataChart.map((props, index) => {
        return {
          type: "column",
          name: props.name,
          // legendText: "0111",
          showInLegend: true,
          xValueFormatString: "####",
          yValueFormatString: "##,##0.##",
          dataPoints: props.dataPoint,
          color: colorChart[index],
        };
      });

    const options = {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: false,
      zoomEnabled: false,
      colorSet: "colorSet2",
      // dataPointMaxHeight: 100,
      dataPointMaxWidth: 30,
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "#02164d",
      axisX: {
        prefix: "",
        interval: 1,
        labelFontColor: "#B6C4DE",
        lineColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,
        labelFontSize: 12,
      },
      axisY: {
        title: "",
        includeZero: false,
        labelFontSize: 12,
        titleFontColor: "#B6C4DE",
        labelFontColor: "#B6C4DE",
        fontWeight: "bold",
        suffix: suffix ? suffix : "",
        gridColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,  // màu đường kẻ ngang
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,  // màu các đoạn trỏ đến data
      },
      axisY2: {
        title: "",
        includeZero: false,
        labelFontSize: 12,
        titleFontColor: "#B6C4DE",
        labelFontColor: "#B6C4DE",
        fontWeight: "bold",
        suffix: suffix ? suffix : "",
        gridColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,  // màu đường kẻ ngang
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,  // màu các đoạn trỏ đến data
        tickLength: 0,
        margin: 0,
        // hide axisY2
        tickThickness: 0,
        gridThickness: 0,
        labelFormatter: function (e) {
          return "";
        }
      },
      title: {
        text: title ? title : "",
        margin: 10,
        fontSize: 14,
        fontColor: "#fff",
        fontFamily: "Roboto",
        fontWeight: "bold"
      },
      subtitles: [{
        text: subTitle ? subTitle : "",
        fontSize: 13,
        fontColor: "#B6C4DE",
        margin: 10,
      }],
      toolTip: {
        enabled: enabledToolTip === undefined ? true : enabledToolTip,
        // contentFormatter: function (e) {
        //   return `<p>${e.entries[0].dataSeries.name}</p><p style='font-weight: 500'><span>${e.entries[0].dataPoint.x}</span>: <span style="color: ${e.entries[0].dataSeries.color};">${numberFormat(e.entries[0].dataPoint.y)} ${dataSuf ? dataSuf : ""}</span></p>`;
        // }
      },
      legend: {
        fontColor: "#B6C4DE",
        fontSize: 12,
        cursor: "pointer",
        itemclick: this.toggleDataSeries,
      },
      data: dataChart ? renderDataChart : [],
    };
    if (height !== undefined) {
      options.height = height;
    }
    return (
      <React.Fragment>
        <div className="column-multiple-chart-wrapper">
          <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
          <div className="cright"><IntlMessages id="common.sourceVirac" /></div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(ColumnChart);
