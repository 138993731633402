import React, { Component } from "react";
import { Input, Checkbox } from "semantic-ui-react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { connect } from "react-redux";
import LoaderTable from "../LoaderTable";
import Button from "../../components/CustomButton/CustomButton";

class StatisticTableFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const {
      type,
      isSearch,
      handleSort,
      clearFilter,
      applyFilter,
      cancelFilter,
      txtSearch,
      handleInputChange,
      handleInputKeyup,
      searchResults,
      handleCheckedAllItems,
      handleCheckedItem,
      checkedAllItems,
      indeterminateItem,
      activeSort
    } = this.props;
    return (
      <div className="statistic-table-fillter" id="statistic-table-fillter">
        <div className={`sort ${activeSort === "asc" ? "active" : ""}`} onClick={() => handleSort("asc")}>
          <IntlMessages id="industry.statistic.sortAZ" />
        </div>
        <div className={`sort ${activeSort === "desc" ? "active" : ""}`} onClick={() => handleSort("desc")}>
          <IntlMessages id="industry.statistic.sortZA" />
        </div>
        <div className={`sort`} onClick={clearFilter}>
          <IntlMessages id="industry.statistic.clearFilter" />
        </div>
        <div className="search-filter">
          <div className="d-flex align-items-center">
            <Input
              type="text"
              className="customInput"
              placeholder={messages["industry.statistic.input"]}
              onKeyUp={handleInputKeyup}
              onChange={handleInputChange}
              value={txtSearch}
            />
            <em className="material-icons search-icon">search</em>
          </div>
          {isSearch && (
            <div className="">
              {searchResults && searchResults.length > 0 && (
                <div>
                  <div className="search-result">
                    <div className="input-list-Checkbox">
                      <Checkbox checked={checkedAllItems} onChange={handleCheckedAllItems} indeterminate={indeterminateItem} />
                      <IntlMessages id="industry.filter.all" />
                    </div>
                    {searchResults.map((item, index) => {
                      return (
                        <div className="input-list-Checkbox" key={index}>
                          <Checkbox checked={item.isChecked} onChange={() => handleCheckedItem(item)} />
                          {type === "DATA_TYPE" && messages[`industry.statistic.${item.value}`]}
                          {type === "AREA" && (language === "en" && item.enText ? item.enText : item.text)}
                          {(type === "PRODUCT_ID" || type === "VSIC") && item.value}
                          {type === "VSIC_NAME" && (language === "en" && item.vsicEName ? item.vsicEName : item.vsicName)}
                          {type === "PRODUCT_NAME" && (language === "en" && item.vsicEName ? item.vsicEName : item.vsicName)}
                        </div>
                      );
                    })}
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <Button className={`common-button`} onClick={applyFilter}>
                      OK
                    </Button>
                    <Button className={`common-button ml-3`} onClick={cancelFilter}>
                      <IntlMessages id="common.cancelBtn" />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StatisticTableFilter));
