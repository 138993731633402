import { combineReducers } from "redux";
import settings from "./settings/reducer";
import cartReducer from "./Cart/reducer";
import authReducer from "./Authentication/reducer";
import companyReducer from "./CompanyInfo/reducer";
import accountReducer from "./AccountManagement/reducer";
import newsReducer from "./News/reducer";
import faqReducer from "./FAQ/reducer";
import dataStorageReducer from "./ReducerDataStorage/reducer";
import companyCompareReducer from "./CompanyCompare/reducer";
import FetchReducer from "./Fetch/reducer";
import CommonReducer from "./Common/reducer";
import industryInformationReducer from "./IndustryInformation/reducer";

const reducers = combineReducers({
  cartReducer,
  authReducer,
  companyReducer,
  accountReducer,
  newsReducer,
  faqReducer,
  dataStorageReducer,
  companyCompareReducer,
  FetchReducer,
  CommonReducer,
  industryInformationReducer,
  settings
});

export default reducers;
