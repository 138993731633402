import * as Types from "../actions";

// USER ACCOUNT - Thông tin tài khoản
export const actGetUserAccount = () => {
  return {
    type: Types.GET_USER_ACCOUNT
  };
};

export const getUserAccountSuccess = (userAccData) => {
  return {
    type: Types.GET_USER_ACCOUNT_SUCCESS,
    payload: {
      userAccData
    }
  };
};

export const getUserAccountFail = (userAccountErr) => {
  return {
    type: Types.GET_USER_ACCOUNT_FAIL,
    payload: {
      userAccountErr
    }
  };
};

// USER ACCOUNT - CURRENT POINT
export const actGetCurrentPoint = () => {
  return {
    type: Types.GET_CURRENT_POINT
  };
};

export const getGetCurrentPointSuccess = (pointData) => {
  return {
    type: Types.GET_CURRENT_POINT_SUCCESS,
    payload: {
      pointData
    }
  };
};

// HISTORY REQUESTED- Lịch sử yêu cầu

export const actGetHistoryRequested = (page, status, searchText) => {
  return {
    type: Types.GET_HISTORY_REQUESTED,
    page,
    status,
    searchText
  };
};

export const actGetHistoryOrderAttachment = (OrderId) => {
  return {
    type: Types.GET_HISTORY_ORDER_ATTACHMENT,
    payload: {
      OrderId
    }
  };
};
export const actGetHistoryOrderDownload = (OrderId, name) => {
  return {
    type: Types.GET_HISTORY_ORDER_DOWNLOAD,
    payload: {
      OrderId,
      name
    }
  };
};

export const getHistoryRequestedSuccess = (reqHisData, totalPage) => {
  return {
    type: Types.GET_HISTORY_REQUESTED_SUCCESS,
    payload: {
      reqHisData,
      totalPage
    }
  };
};

export const getHistoryRequestedFail = (reqHisErr) => {
  return {
    type: Types.GET_HISTORY_REQUESTED_FAIL,
    payload: {
      reqHisErr
    }
  };
};

// HISTORY ACCESSED - Lịch sử truy cập

export const actGetHistoryAccessed = (page) => {
  return {
    type: Types.GET_HISTORY_ACCESSED,
    page
  };
};

export const getHistoryAccessedSuccess = (accHisData, totalItems, totalPagex, pageIndex, pageCount) => {
  return {
    type: Types.GET_HISTORY_ACCESSED_SUCCESS,
    payload: {
      accHisData,
      totalItems,
      totalPagex,
      pageIndex,
      pageCount
    }
  };
};

export const getHistoryAccessedFail = (accHisErr) => {
  return {
    type: Types.GET_HISTORY_ACCESSED_FAIL,
    payload: {
      accHisErr
    }
  };
};

// NOTIFICATION - Thông báo

export const actGetNotifications = (page = 1) => {
  return {
    type: Types.GET_NOTIFICATIONS,
    payload: {
      page
    }
  };
};

export const getNotificationsSuccess = (notiList, totalItem, totalPages, pageIndex, pageSize) => {
  return {
    type: Types.GET_NOTIFICATIONS_SUCCESS,
    payload: {
      notiList,
      totalItem,
      totalPages,
      pageIndex,
      pageSize
    }
  };
};

export const getNotificationsFail = (notiErr) => {
  return {
    type: Types.GET_NOTIFICATIONS_FAIL,
    payload: {
      notiErr
    }
  };
};

export const actDeleteNotification = (id, cbSuccess) => {
  return {
    type: Types.DELETE_NOTIFICATION,
    payload: {
      id,
      success: cbSuccess
    }
  };
};

export const actChangeStatusSeenMessage = (id, cbSuccess) => {
  return {
    type: Types.CHANGE_STATUS_SEEN_MESSAGE,
    payload: {
      id,
      success: cbSuccess
    }
  };
};

// export const actDeleteNotificationSuccess = message => {
//     return {
//         type: Types.DELETE_NOTIFICATION_SUCCESS,
//         payload: {
//             message,
//         }
//     };
// };

// CHANGE PASSWORD - Đổi mật khẩu
export const actChangePassword = (oldPass, newPass) => {
  return {
    type: Types.CHANGE_PASSWORD,
    payload: {
      oldPass,
      newPass
    }
  };
};

export const changePasswordMessage = (message) => {
  return {
    type: Types.CHANGE_PASSWORD_MESSAGE,
    payload: {
      message
    }
  };
};

// Request Lang
export const actRequestedLang = (lang) => {
  return {
    type: Types.GET_LANG_REQUESTED,
    payload: {
      lang
    }
  };
};
