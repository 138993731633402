import React, { Component } from "react";

import { formatMoney, formatMoney_2 } from "../../../utils/formatMoney";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Table } from "reactstrap";
import PieChartOwner from "../../../components/CompanyInfo/Chart/PieChartOwner";

import { callApiServer } from "../../../utils/callApiServer";
import moment from "moment";
import { _Standard_, _Premium_ } from "../../../constants/rolesModel";
import MultiColumnImportExport from "../../../components/CompanyInfo/Chart/MultiColumnImportExport";
import ParetoChartTabRatio from "../../../components/CompanyInfo/Chart/ParetoChart_TabRatio";
import LinechartTabRatio from "../../../components/CompanyInfo/Chart/Linechart_TabRatio";

import { formatNumber } from "../../../utils/addSymbols";
import { numberFormat, numberFormat_2 } from "../../../utils/numberFormat";
import { Loader } from "semantic-ui-react";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

class AutoReportTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAutoReport: {}
    };
  }

  componentDidMount() {
    this.initData(this.props.id);
  }

  initData() {
    if (this.props.id !== null && this.props.id !== undefined) {
      this.getDataAutoReport(this.props.id);
    }
  }

  async getDataAutoReport(id) {
    try {
      const response = await callApiServer.get(`/Company/automate-company-report?companyId=${id}`);
      if (response && response.data && response.data.data) {
        this.setState(
          {
            dataAutoReport: response.data.data
          },
          () => {
            this.handleDownloadReportAuto();
          }
        );
      } else {
        this.setState({
          dataAutoReport: {}
        });

        this.props.closeTemplate();
      }
    } catch (error) {
      this.setState({
        dataAutoReport: {}
      });

      this.props.closeTemplate();
    }
  }

  downloadReport = async () => {
    const {
      companyImportExportResponse,
      companyProfileResponse,
      financialReportResponse,
      generalInformationResponse,
      industryDataModel,
      ownershipStructureResponse,
      topCompanyByNetRevenueModel
    } = this.state.dataAutoReport;
    const companyName =
      generalInformationResponse && generalInformationResponse.companyEnName ? generalInformationResponse.companyEnName.replace(/ /g, "_") : "";
    let pageNumberToExport;
    const language = localStorage.getItem("currentLanguage");
    const fileName = `Instant_Company_Report_${companyName}.pdf`;
    let pdf = new jsPDF("p", "mm");
    const a4WidthMm = 210;

    const listPage = document.getElementsByClassName("background_page");
    const pageSize = listPage.length;
    for (let index = 0; index < pageSize; index++) {
      let input = listPage[index];

      const inputHeightMm = this.pxToMm(input.offsetHeight);
      const canvas = await html2canvas(input, {
        useCORS: true,
        allowTaint: true,
        backgroundColor: "#fff",
        scale: 2.5
      });
      setTimeout(() => {}, 100);
      const imgData = canvas.toDataURL("image/jpeg", 4);
      setTimeout(() => {}, 100);

      const imgHeight = (canvas.height * a4WidthMm) / canvas.width;
      // pdf.addPage(a4WidthMm, imgHeight);
      pdf.addPage("a4", "p");

      pdf.addImage(imgData, "JPG", 0, 0, a4WidthMm, imgHeight);
    }

    pdf.deletePage(1);

    pdf.save(fileName);

    this.props.closeTemplate();
  };
  pxToMm = (px) => {
    return Math.floor(px / document.getElementById("myMm").offsetHeight);
  };

  handleDownloadReportAuto = () => {
    setTimeout(() => {
      this.downloadReport();
    }, 2500);
  };

  formatPieCharts(originData) {
    let newData = [];
    if (originData && originData.length) {
      originData.map((item) => {
        newData.push({
          name: item.enLabel,
          y: item.y
        });
      });
    }

    return newData;
  }
  returnDataChart = (data, type) => {
    let dataItem = data.filter((item) => item.enTitle === type)[0];

    return dataItem;
  };

  formatDataCharts(originData) {
    let newData = [];
    if (originData && originData.length) {
      originData.map((item) => {
        newData.push({
          enTitle: item.enTitle,
          title: item.enTitle,
          dataChart: item.dataChart
            ? item.dataChart.map((data) => {
                return {
                  label: data.label,
                  y: data.y
                };
              })
            : []
        });
      });
    }
    return newData;
  }

  getDataImportExportChart(shareData, type) {
    return shareData.filter((item) => item.enTitle === type)[0].dataChart;
  }

  render() {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");
    const roles = _Premium_;
    const {
      companyImportExportResponse,
      companyProfileResponse,
      financialReportResponse,
      generalInformationResponse,
      industryDataModel,
      ownershipStructureResponse,
      topCompanyByNetRevenueModel
    } = this.state.dataAutoReport;

    // Cash Flow Statement
    let isDirectStatement;
    let dataCashFlowStatement;
    if (financialReportResponse && financialReportResponse.cashFlowStatement) {
      if (financialReportResponse.cashFlowStatement.listDirectMethod) {
        isDirectStatement = true;
        dataCashFlowStatement = financialReportResponse.cashFlowStatement.listDirectMethod;
      } else {
        isDirectStatement = false;
        dataCashFlowStatement = financialReportResponse.cashFlowStatement.listIndirectMethod;
      }
    }

    const styleLoading = {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1000,
      backgroundColor: "rgba(0,0,0,0.8)"
    };

    return (
      <React.Fragment>
        <div id="wrap-to-download" className="company_download_template">
          {/*  Trang thông tin tổng quan */}
          <div className="background_page">
            {generalInformationResponse ? (
              <div
                className="row mb-3 mt-2
              "
              >
                <div className="col-lg-12 col-xl-12 mb-2">
                  <div className="title_header mb-5">
                    <div className="main_name">{generalInformationResponse.companyEnName ? generalInformationResponse.companyEnName : ""}</div>
                    <div className="sub_name">{generalInformationResponse.companyName ? generalInformationResponse.companyName : ""} </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6 col-xl-6 ">
                      <div className="card-item ">
                        <div className="card-title">Contact</div>
                        <div className="card_content">
                          {/* Address */}
                          <div className="item">
                            <div className="item_label only_icon">
                              <span className="material-icons">place</span>
                            </div>
                            <div className="item_value">{generalInformationResponse.address ? generalInformationResponse.address : ""} </div>
                          </div>

                          {/* Phone */}
                          <div className="item">
                            <div className="item_label only_icon">
                              <span className="material-icons">phone</span>
                            </div>
                            <div className="item_value">{generalInformationResponse.phoneNumber ? generalInformationResponse.phoneNumber : ""}</div>
                          </div>

                          {/* Fax */}
                          <div className="item">
                            <div className="item_label only_icon">
                              <span className="material-icons">fax</span>
                            </div>
                            <div className="item_value">{generalInformationResponse.fax ? generalInformationResponse.fax : ""}</div>
                          </div>

                          {/* Email */}
                          <div className="item">
                            <div className="item_label only_icon">
                              <span className="material-icons">email</span>
                            </div>
                            <div className="item_value">{generalInformationResponse.email ? generalInformationResponse.email : ""}</div>
                          </div>

                          {/* Website */}
                          <div className="item">
                            <div className="item_label only_icon">
                              <span className="material-icons">language</span>
                            </div>
                            <div className="item_value">{generalInformationResponse.webSite ? generalInformationResponse.webSite : ""}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 pl-0">
                      <div className="card-item ">
                        <div className="card-title">REGISTERED BUSINESS ACTIVITIES</div>
                        <div className="card_content">
                          {generalInformationResponse.vsicInfo ? (
                            generalInformationResponse.vsicInfo.map((props, index) => {
                              return (
                                <div className="item" key={index}>
                                  <div style={{ width: "15px" }}>-</div>
                                  <div className="item_value">{`${props.nameEn} (VSIC: ${props.code ? props.code : ""})`} </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="item">
                              <div style={{ width: "15px" }}>-</div>
                              <div className="item_value">N/A </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 col-xl-12">
                      <div className="card-item">
                        <div className="card-title text-left">Company profile</div>
                        <div className="card_content d-flex flex-row">
                          <div className="col-lg-7 col-xl-7">
                            {/* Registration code */}
                            <div className="item" style={{ height: "40px" }}>
                              <div className="item_label only_text">Registration Code</div>
                              <div className="item_value">{generalInformationResponse.registrationCode ? generalInformationResponse.registrationCode : ""}</div>
                            </div>
                            {/* Incorporated */}
                            <div className="item" style={{ height: "40px" }}>
                              <div className="item_label only_text">Incorporated Date</div>
                              <div className="item_value">
                                {generalInformationResponse.incorporatedDate ? moment(generalInformationResponse.incorporatedDate).format("DD/MM/YYYY") : ""}
                              </div>
                            </div>

                            {/* Legal Form */}
                            <div className="item" style={{ height: "40px" }}>
                              <div className="item_label only_text">Legal Form</div>
                              <div className="item_value">{generalInformationResponse.legalForm ? generalInformationResponse.legalForm : ""}</div>
                            </div>
                            {/* Operation Status */}
                            <div className="item" style={{ height: "40px" }}>
                              <div className="item_label only_text">Operation Status</div>
                              <div className="item_value">
                                {generalInformationResponse.operationStatus
                                  ? generalInformationResponse.operationStatus === true
                                    ? "Active"
                                    : "Inactive"
                                  : ""}
                              </div>
                            </div>
                            {/* Business Size */}
                            <div className="item" style={{ height: "40px" }}>
                              <div className="item_label only_text">Business Size</div>
                              <div className="item_value">{generalInformationResponse.businessSize ? generalInformationResponse.businessSize : ""}</div>
                            </div>
                            {/* Legal Representative */}
                            <div className="item" style={{ height: "170px" }}>
                              <div className="item_label only_text">Legal Representative</div>
                              <div className="item_value">
                                - Name : {generalInformationResponse.representativeName ? generalInformationResponse.representativeName : ""}
                                <br />- Nationality: {generalInformationResponse.nationality ? generalInformationResponse.nationality : ""} <br />- ID
                                No./Passport No: {generalInformationResponse.idPerson ? generalInformationResponse.idPerson : ""}
                                <br />- YOB: {generalInformationResponse.dob ? moment(generalInformationResponse.dob).format("DD/MM/YYYY") : ""}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-5 col-xl-5 pl-0">
                            {/* Rank */}
                            <div className="item no_border mb-3">
                              <div className="item_label only_icon">
                                <span className="material-icons" style={{ fontSize: "50px" }}>
                                  military_tech
                                </span>
                              </div>
                              <div className="item_value ml-3">
                                <span className="text-w-700">
                                  Rank: {generalInformationResponse.rank ? `#${generalInformationResponse.rank} by revenua` : ""}{" "}
                                </span>
                                {/* <br />
                              <span className="text_italic">Updated: Dec,2021</span> */}
                              </div>
                            </div>

                            {/* Employees */}
                            <div className="item no_border mb-3">
                              <div className="item_label only_icon">
                                <span className="material-icons" style={{ fontSize: "50px" }}>
                                  groups
                                </span>
                              </div>
                              <div className="item_value ml-3">
                                {generalInformationResponse.noOfEmployee ? (
                                  <span>
                                    <span className="text-w-700 font-s-20">{generalInformationResponse.noOfEmployee}</span> <span>employees</span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* Charter capital */}
                            <div className="item no_border mb-3">
                              <div className="item_label only_icon">
                                <span className="material-icons" style={{ fontSize: "50px" }}>
                                  attach_money
                                </span>
                              </div>
                              <div className="item_value ml-3">
                                <span className="text-w-700 font-s-20">Charter capital</span> <span>(VND) </span>
                                <br />
                                {generalInformationResponse.charterCapital !== null && generalInformationResponse.charterCapital !== "" ? (
                                  <span className="text-w-700 font-s-20">{formatMoney_2(parseInt(generalInformationResponse.charterCapital, 10), 2)}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {/* Update */}
                            <div className="item no_border mb-3">
                              <div className="item_label only_icon">
                                <span className="material-icons" style={{ fontSize: "50px" }}>
                                  update
                                </span>
                              </div>
                              <div className="item_value ml-3">
                                <span className="text-w-700 font-s-20">Latest Update </span>
                                <br></br>
                                {generalInformationResponse.latestUpdate ? (
                                  <span className="text-w-700 font-s-20">{moment(generalInformationResponse.latestUpdate).format("DD/MM/YYYY")}</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-danger text_italic mt-3 ml-3">No Data</span>
            )}
          </div>

          {/*  Trang cấu trúc sở hữu - Corporate Hold/Private Hold/Foreign Hold*/}
          <div className="background_page">
            <div className="title_content mb-2 mt-2">Ownership Structure</div>

            {ownershipStructureResponse ? (
              <div>
                {/* Corporate Hold */}
                <div className="row mb-3">
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <div className="card-item ">
                      <div className="card-title text_left">Corporate Hold</div>
                      <div className="card_content">
                        {ownershipStructureResponse.corporateHold && ownershipStructureResponse.corporateHold.length ? (
                          <div className="table-custom">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="text_left">Name</th>
                                  <th className="text_right">Amount (VND million)</th>

                                  <th className="text_right">Ownership (%)</th>
                                  <th className="text_right">Update Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ownershipStructureResponse.corporateHold.map((props, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text_left width-col-300"> {props.name ? props.name : ""}</td>
                                      <td className="text_right">
                                        {props.amount !== null && props.amount !== "" ? formatMoney_2(parseInt(props.amount, 10), 2) : ""}
                                      </td>
                                      <td className="text_right"> {props.ownership ? formatMoney_2(parseInt(props.ownership, 10), 2) : ""}</td>
                                      <td className="text_right"> {props.updateDate ? moment(props.updateDate).format("DD/MM/YYYY") : ""}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className="text-danger text-left text_italic mt-3 ml-3">The company’s ownership structure has not been updated.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Private Hold */}
                <div className="row mb-3">
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <div className="card-item ">
                      <div className="card-title text_left">Private Hold</div>
                      <div className="card_content">
                        {ownershipStructureResponse.privateHold && ownershipStructureResponse.privateHold.length ? (
                          <div className="table-custom">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="text_left">Name</th>
                                  <th className="text_right">Amount (VND million)</th>

                                  <th className="text_right">Ownership (%)</th>
                                  <th className="text_right">Update Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ownershipStructureResponse.privateHold.map((props, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text_left width-col-300"> {props.name ? props.name : ""}</td>
                                      <td className="text_right">
                                        {" "}
                                        {props.amount !== null && props.amount !== "" ? formatMoney_2(parseInt(props.amount, 10), 2) : ""}
                                      </td>
                                      <td className="text_right"> {props.ownership ? formatMoney_2(parseInt(props.ownership, 10), 2) : ""}</td>
                                      <td className="text_right"> {props.updateDate ? moment(props.updateDate).format("DD/MM/YYYY") : ""}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className="text-danger text_left text_italic mt-3 ml-3">The company’s ownership structure has not been updated.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Foreign Hold */}
                <div className="row mb-3">
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <div className="card-item ">
                      <div className="card-title text_left">Foreign Hold</div>
                      <div className="card_content">
                        {ownershipStructureResponse.foreignHold && ownershipStructureResponse.foreignHold.length ? (
                          <div className="table-custom">
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="text_left">Name</th>
                                  <th className="text_right">Amount (VND million)</th>

                                  <th className="text_right">Ownership (%)</th>
                                  <th className="text_right">Update Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ownershipStructureResponse.foreignHold.map((props, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text_left width-col-300"> {props.name ? props.name : ""}</td>
                                      <td className="text_right">
                                        {" "}
                                        {props.amount !== null && props.amount !== "" ? formatMoney_2(parseInt(props.amount, 10), 2) : ""}
                                      </td>
                                      <td className="text_right"> {props.ownership ? formatMoney_2(parseInt(props.ownership, 10), 2) : ""}</td>
                                      <td className="text_right"> {props.updateDate ? moment(props.updateDate).format("DD/MM/YYYY") : ""}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <div className="text-danger text_italic text_left mt-3 ml-3">The company’s ownership structure has not been updated.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-danger text_italic mt-3 ml-3">No Data</span>
            )}
          </div>

          {/*  Trang cấu trúc sở hữu - Ownership Structure Chart*/}
          <div className="background_page">
            {ownershipStructureResponse ? (
              <div>
                {/* Ownership Structure Chart */}
                <div className="row mb-3 mt-2">
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <div className="card-item ">
                      <div className="text_center text-bold font-s-20 mb-3"> Ownership Structure Chart</div>
                      <div className="card_content">
                        <div className="col-xl-12">
                          {ownershipStructureResponse &&
                          ownershipStructureResponse.ownershipStructureCharts &&
                          ownershipStructureResponse.ownershipStructureCharts.length > 0 ? (
                            <div className="chart-law">
                              <div className="cover-pie-chart">
                                <PieChartOwner
                                  customFontColor="#0953a8"
                                  labelFontSize={16}
                                  showSourceEn={true}
                                  dataChartStructure={this.formatPieCharts(ownershipStructureResponse.ownershipStructureCharts)}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-danger text_italic mt-3 ml-3">No Data</span>
            )}
          </div>

          {/*  COMPANY PROFILE - BRANCHES/REPRESENTATIVE OFFICES  */}
          {companyProfileResponse && companyProfileResponse.branches && companyProfileResponse.branches.length ? (
            <div>
              {companyProfileResponse &&
                companyProfileResponse.branches &&
                companyProfileResponse.branches.length &&
                companyProfileResponse.branches.map((page, pageIndex) => {
                  return (
                    <div key={pageIndex} className="background_page">
                      {pageIndex === 0 && <div className="title_content mb-2 mt-2">COMPANY PROFILE</div>}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-xl-12 mb-2">
                          <div className="card-item ">
                            {pageIndex === 0 && <div className="card-title text_left">BRANCHES/REPRESENTATIVE OFFICES</div>}

                            <div className="card_content">
                              {page && page.length ? (
                                <div className="table-custom">
                                  <Table responsive>
                                    {pageIndex === 0 && (
                                      <thead>
                                        <tr>
                                          <th className="text_center">No</th>
                                          <th className="text_left">Tax Code</th>
                                          <th className="text_left">Name</th>
                                          <th className="text_left">Address</th>
                                        </tr>
                                      </thead>
                                    )}

                                    <tbody>
                                      {page.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="text_center">{pageIndex * 10 + index + 1}</td>
                                            <td className="text_left width-col-300"> {item.taxCode ? item.taxCode : ""}</td>
                                            <td className="text_left width-col-300"> {item.nameEn ? item.nameEn : ""}</td>
                                            <td className="text_left width-col-300"> {item.address ? item.address : ""}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <div className="text-danger text-left text_italic mt-3 ml-3">The company has no branches/representative offices.</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/*  COMPANY PROFILE - SUBSIDIARIES */}
          {companyProfileResponse && companyProfileResponse.subsidiaries && companyProfileResponse.subsidiaries.length ? (
            <div>
              {companyProfileResponse &&
                companyProfileResponse.subsidiaries &&
                companyProfileResponse.subsidiaries.length &&
                companyProfileResponse.subsidiaries.map((page, pageIndex) => {
                  return (
                    <div key={pageIndex} className="background_page">
                      {pageIndex === 0 && <div className="title_content mb-2 mt-2">COMPANY PROFILE</div>}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-xl-12 mb-2">
                          <div className="card-item ">
                            {pageIndex === 0 && <div className="card-title text_left">SUBSIDIARIES</div>}

                            <div className="card_content">
                              {page && page.length ? (
                                <div className="table-custom">
                                  <Table responsive>
                                    {pageIndex === 0 && (
                                      <thead>
                                        <tr>
                                          <th className="text_center">No</th>
                                          <th className="text_left">Tax Code</th>
                                          <th className="text_left">Name</th>

                                          <th className="text_right">Ownership (%)</th>
                                          <th className="text_left">Registered Business Activities</th>
                                        </tr>
                                      </thead>
                                    )}

                                    <tbody>
                                      {page.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="text_center">{pageIndex * 10 + index + 1}</td>
                                            <td className="text_left width-col-300"> {item.taxCode ? item.taxCode : ""}</td>
                                            <td className="text_left width-col-300"> {item.nameEn ? item.nameEn : ""}</td>
                                            <td className="text_right"> {item.ownership ? formatMoney_2(item.ownership, 2) : ""}</td>
                                            <td className="text_left width-col-300"> {item.vsicEnInfo ? item.vsicEnInfo : ""}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <div className="text-danger text-left text_italic mt-3 ml-3">The company has no subsidiaries.</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/*  COMPANY PROFILE - AFFILIATES */}
          {companyProfileResponse && companyProfileResponse.affiliates && companyProfileResponse.affiliates.length ? (
            <div>
              {companyProfileResponse &&
                companyProfileResponse.affiliates &&
                companyProfileResponse.affiliates.length &&
                companyProfileResponse.affiliates.map((page, pageIndex) => {
                  return (
                    <div key={pageIndex} className="background_page">
                      {pageIndex === 0 && <div className="title_content mb-2 mt-2">COMPANY PROFILE</div>}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-xl-12 mb-2">
                          <div className="card-item ">
                            {pageIndex === 0 && <div className="card-title text_left">AFFILIATES</div>}

                            <div className="card_content">
                              {page && page.length ? (
                                <div className="table-custom">
                                  <Table responsive>
                                    {pageIndex === 0 && (
                                      <thead>
                                        <tr>
                                          <th className="text_center">No</th>
                                          <th className="text_left">Tax Code</th>
                                          <th className="text_left">Name</th>

                                          <th className="text_right">Ownership (%)</th>
                                          <th className="text_left">Registered Business Activities</th>
                                        </tr>
                                      </thead>
                                    )}

                                    <tbody>
                                      {page.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="text_center">{pageIndex * 10 + index + 1}</td>
                                            <td className="text_left width-col-300"> {item.taxCode ? item.taxCode : ""}</td>
                                            <td className="text_left width-col-300"> {item.nameEn ? item.nameEn : ""}</td>
                                            <td className="text_right"> {item.ownership ? formatMoney_2(item.ownership, 2) : ""}</td>
                                            <td className="text_left width-col-300"> {item.vsicEnInfo ? item.vsicEnInfo : ""}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <div className="text-danger text-left text_italic mt-3 ml-3">The company has no subsidiaries.</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/*  Trang Export - Import*/}
          <div className="background_page">
            <div className="title_content mb-2 mt-2">Export - Import</div>
            {companyImportExportResponse ? (
              <div>
                {/* Total Import and Export Chart  */}
                <div className="row mb-3">
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <div className="card-item ">
                      <div className="text_center text-bold font-s-20 mb-3 title-color">
                        {companyImportExportResponse.importAndExportData && companyImportExportResponse.importAndExportData.enTitle
                          ? companyImportExportResponse.importAndExportData.enTitle
                          : "Total Import - Export Value"}
                      </div>
                      <div className="card_content">
                        <div className="col-xl-12">
                          {companyImportExportResponse.importAndExportData ? (
                            <div className="chart-info chart-multi">
                              <MultiColumnImportExport
                                xTypeLabel={true}
                                dataImport={this.getDataImportExportChart(companyImportExportResponse.importAndExportData.chartModels, "Import")}
                                dataExport={this.getDataImportExportChart(companyImportExportResponse.importAndExportData.chartModels, "Export")}
                                suffix=" USD"
                                showValueInColumn={true}
                                showSourceEn={true}
                                customFontColor="#0953a8"
                                customValueInColumnColor="#000000"
                                backgroundColor={"#fff"}
                                tickColor={"rgba(144, 155, 175, 0.3)"}
                                enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                                roles={roles}
                                noTranslate={true}
                              />
                            </div>
                          ) : (
                            <span className="text-danger text_italic mt-3 ml-3">No Data</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Import/Export Chart  */}
                <div className="row mb-3">
                  {/* Export */}
                  <div className="col-lg-6 col-xl-6 mb-2 title-color">
                    <div className="card-item ">
                      <div className="card-title text_left mb-3">Export</div>

                      <div className="text_center text-bold font-s-18 mb-3">
                        {companyImportExportResponse.exportShareWeightRatioData && companyImportExportResponse.exportShareWeightRatioData.enTitle
                          ? companyImportExportResponse.exportShareWeightRatioData.enTitle
                          : "Share of export value by country"}
                      </div>
                      <div className="card_content">
                        <div className="col-xl-12">
                          {companyImportExportResponse.exportShareWeightRatioData &&
                          companyImportExportResponse.exportShareWeightRatioData.dataChart &&
                          companyImportExportResponse.exportShareWeightRatioData.dataChart.length ? (
                            <div className="chart-law">
                              <div className="cover-pie-chart">
                                <PieChartOwner
                                  customFontColor="#0953a8"
                                  labelFontSize={16}
                                  showSourceEn={true}
                                  dataChartStructure={this.formatPieCharts(companyImportExportResponse.exportShareWeightRatioData.dataChart)}
                                />
                              </div>
                            </div>
                          ) : (
                            <span className="text-danger text_italic mt-3 ml-3">No Data</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Import */}
                  <div className="col-lg-6 col-xl-6 pl-0 mb-2 title-color">
                    <div className="card-item ">
                      <div className="card-title text_left mb-3">Import</div>

                      <div className="text_left text-bold font-s-18 mb-3 ">
                        {companyImportExportResponse.importShareWeightRatioData && companyImportExportResponse.importShareWeightRatioData.enTitle
                          ? companyImportExportResponse.importShareWeightRatioData.enTitle
                          : "Share of import value by country"}
                      </div>
                      <div className="card_content">
                        <div className="col-xl-12">
                          {companyImportExportResponse.importShareWeightRatioData &&
                          companyImportExportResponse.importShareWeightRatioData.dataChart &&
                          companyImportExportResponse.importShareWeightRatioData.dataChart.length ? (
                            <div className="chart-law">
                              <div className="cover-pie-chart">
                                <PieChartOwner
                                  customFontColor="#0953a8"
                                  labelFontSize={16}
                                  showSourceEn={true}
                                  dataChartStructure={this.formatPieCharts(companyImportExportResponse.importShareWeightRatioData.dataChart)}
                                />
                              </div>
                            </div>
                          ) : (
                            <span className="text-danger text_italic mt-3 ml-3">No Data</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-danger text_italic mt-3 ml-3">No Data</span>
            )}
          </div>

          {/*  Trang Financial Statements - Balance Sheet  */}
          {financialReportResponse && financialReportResponse.listBalanceSheet && financialReportResponse.listBalanceSheet.length ? (
            <div>
              {financialReportResponse &&
                financialReportResponse.listBalanceSheet &&
                financialReportResponse.listBalanceSheet.length &&
                financialReportResponse.listBalanceSheet.map((page, pageIndex) => {
                  return (
                    <div key={pageIndex} className="background_page">
                      {pageIndex === 0 && <div className="title_content mb-2 mt-2">Financial Statements</div>}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-xl-12 mb-2">
                          <div className="card-item ">
                            {pageIndex === 0 && <div className="card-title text_left">BALANCE SHEET</div>}

                            <div className="card_content">
                              {page && page.length ? (
                                <div className="table-custom">
                                  <Table responsive>
                                    {pageIndex === 0 && (
                                      <thead>
                                        <tr>
                                          <th className="text_left"></th>
                                          {page[0] &&
                                            page[0].listValues.length &&
                                            page[0].listValues.map((item, index) => {
                                              return (
                                                <th key={index} className="text_right width-col-140">
                                                  {item.name}
                                                </th>
                                              );
                                            })}
                                        </tr>
                                      </thead>
                                    )}

                                    <tbody>
                                      {page.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className={`text_left width-col-300 ${item.level === "1" || item.level === "2" ? "text-w-600" : ""}`}>
                                              {" "}
                                              {item.enTitle ? item.enTitle : ""}
                                            </td>
                                            {item.listValues.map((itemValue, index) => {
                                              return (
                                                <td key={index} className="text_right width-col-140">
                                                  {itemValue.value !== null ? formatMoney_2(itemValue.value, 2) : ""}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/* Trang Financial Statements - Income Statement */}
          {financialReportResponse && financialReportResponse.listIncomeStatement && financialReportResponse.listIncomeStatement.length ? (
            <div>
              {financialReportResponse &&
                financialReportResponse.listIncomeStatement &&
                financialReportResponse.listIncomeStatement.length &&
                financialReportResponse.listIncomeStatement.map((page, pageIndex) => {
                  return (
                    <div key={pageIndex} className="background_page">
                      {pageIndex === 0 && <div className="title_content mb-2 mt-2">Financial Statements</div>}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-xl-12 mb-2">
                          <div className="card-item ">
                            {pageIndex === 0 && <div className="card-title text_left text-no-tranform">INCOME STATEMENT (Unit:VND)</div>}

                            <div className="card_content">
                              {page && page.length ? (
                                <div className="table-custom">
                                  <Table responsive>
                                    {pageIndex === 0 && (
                                      <thead>
                                        <tr>
                                          <th className="text_left"></th>
                                          {page[0] &&
                                            page[0].listValues &&
                                            page[0].listValues.map((item, index) => {
                                              return (
                                                <th key={index} className="text_right">
                                                  {item.name}
                                                </th>
                                              );
                                            })}
                                        </tr>
                                      </thead>
                                    )}

                                    <tbody>
                                      {page.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className={`text_left width-col-300 ${item.level === "1" || item.level === "2" ? "text-w-600" : ""}`}>
                                              {" "}
                                              {item.enTitle ? item.enTitle : ""}
                                            </td>
                                            {item.listValues.map((itemValue, index) => {
                                              return (
                                                <td key={index} className="text_right width-col-140">
                                                  {" "}
                                                  {itemValue.value !== null ? formatMoney_2(itemValue.value, 2) : ""}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/* Trang Financial Statements - Cash Flow Statement -  */}
          {financialReportResponse &&
          financialReportResponse.cashFlowStatement &&
          (financialReportResponse.cashFlowStatement.listDirectMethod || financialReportResponse.cashFlowStatement.listIndirectMethod) ? (
            <div>
              {dataCashFlowStatement &&
                dataCashFlowStatement.length &&
                dataCashFlowStatement.map((page, pageIndex) => {
                  return (
                    <div key={pageIndex} className="background_page">
                      {pageIndex === 0 && <div className="title_content mb-2 mt-2">Financial Statements</div>}

                      <div className="row mb-3">
                        <div className="col-lg-12 col-xl-12 mb-2">
                          <div className="card-item ">
                            {pageIndex === 0 && <div className="card-title text_left">CASH FLOW STATEMENT (Unit: VND)</div>}

                            <div className="card_content">
                              <div className="table-custom">
                                <Table responsive>
                                  {pageIndex === 0 && (
                                    <thead>
                                      <tr>
                                        <th className="text_left ma">{isDirectStatement ? "Direct Method" : "Indirect Method"}</th>
                                        {page[0] &&
                                          page[0].listValues &&
                                          page[0].listValues.length &&
                                          page[0].listValues.map((item, i) => {
                                            return (
                                              <th key={i} className="text_right width-col-140">
                                                {item.name}
                                              </th>
                                            );
                                          })}
                                      </tr>
                                    </thead>
                                  )}

                                  <tbody>
                                    {page &&
                                      page.length &&
                                      page.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className={`text_left width-col-300 ${item.level === "1" || item.level === "2" ? "text-w-600" : ""}`}>
                                              {" "}
                                              {item.enTitle ? item.enTitle : ""}
                                            </td>
                                            {item.listValues.map((itemValue, index) => {
                                              return (
                                                <td key={index} className="text_right width-col-140">
                                                  {itemValue.value !== null ? formatMoney_2(itemValue.value, 2) : ""}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {/* Financial Highlight - Trang 1*/}
          <div className="background_page">
            <div className="title_content mb-2 mt-2">Financial Highlight</div>
            {financialReportResponse && financialReportResponse.financialHighlight ? (
              <div>
                <div className="row mb-3">
                  {/* Financial Highlight general  */}
                  <div className="col-lg-6 col-xl-6 mb-2">
                    <div className="card-item ">
                      <div className="card_content">
                        {/* Contributed capital */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">Contributed capital</div>
                          <div className="item_value text-right">
                            {financialReportResponse.financialHighlight.contributedCapital !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.contributedCapital, 2) + " VND"
                              : ""}
                          </div>
                        </div>

                        {/* Net revenue */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">Net revenue</div>
                          <div className="item_value text-right">
                            {financialReportResponse.financialHighlight.netRevenue !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.netRevenue, 2) + " VND"
                              : ""}
                          </div>
                        </div>
                        {/* Net profit */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">Net profit</div>
                          <div className="item_value text-right">
                            {financialReportResponse.financialHighlight.netProfit !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.netProfit, 2) + " VND"
                              : ""}
                          </div>
                        </div>
                        {/* Total assets */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">Total assets</div>
                          <div className="item_value text-right">
                            {financialReportResponse.financialHighlight.totalAssets !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.totalAssets, 2) + " VND"
                              : ""}
                          </div>
                        </div>
                        {/*Owner's equity */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">Owner's equity</div>
                          <div className="item_value text-right">
                            {financialReportResponse.financialHighlight.ownerEquity !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.ownerEquity, 2) + " VND"
                              : ""}
                          </div>
                        </div>
                        {/*ROE */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">ROE</div>
                          <div
                            className={
                              `item_value text-right ` +
                              (parseFloat(financialReportResponse.financialHighlight.roe) < 0 ? "text-danger" : "") +
                              (parseFloat(financialReportResponse.financialHighlight.roe) > 0 ? "text-success" : "")
                            }
                          >
                            {financialReportResponse.financialHighlight.roe !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.roe, 2) + " %"
                              : ""}
                          </div>
                        </div>
                        {/*ROA */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">ROA</div>
                          <div
                            className={
                              `item_value text-right ` +
                              (parseFloat(financialReportResponse.financialHighlight.roa) < 0 ? "text-danger" : "") +
                              (parseFloat(financialReportResponse.financialHighlight.roa) > 0 ? "text-success" : "")
                            }
                          >
                            {financialReportResponse.financialHighlight.roa !== null
                              ? formatMoney_2(financialReportResponse.financialHighlight.roa, 2) + " %"
                              : ""}
                          </div>
                        </div>
                        {/*Update period */}
                        <div className="item" style={{ height: "35px" }}>
                          <div className="item_label only_text">Update period</div>
                          <div className="item_value text-right">
                            {financialReportResponse.financialHighlight.updatePeriod !== null ? financialReportResponse.financialHighlight.updatePeriod : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Company Perfomance Review */}
                  {financialReportResponse.financialHighlight.companyPerformanceReview !== null ? (
                    <div className="col-lg-6 col-xl-6 mb-2">
                      <div className="card-item ">
                        <div className="text_left text-bold font-s-18 title-color mb-3">Company Perfomance Review</div>
                        <div className="card_content">
                          {financialReportResponse.financialHighlight.companyPerformanceReview &&
                          financialReportResponse.financialHighlight.companyPerformanceReview.length ? (
                            <div className="chart-info chart-multi">
                              <ParetoChartTabRatio
                                customFontColor="#0953a8"
                                sourceEn={true}
                                yLabel_1_suffix={" Million VND"}
                                yLabel_2_suffix={"%"}
                                xType="label"
                                labelEnglish={true}
                                roles={roles}
                                dataColumn_1={this.returnDataChart(financialReportResponse.financialHighlight.companyPerformanceReview, "Net revenue")}
                                dataColumn_2={this.returnDataChart(financialReportResponse.financialHighlight.companyPerformanceReview, "Cost of goods sold")}
                                dataColumn_3={this.returnDataChart(financialReportResponse.financialHighlight.companyPerformanceReview, "Gross profit")}
                                dataLine_1={this.returnDataChart(financialReportResponse.financialHighlight.companyPerformanceReview, "Net profit margin")}
                                backgroundColor={"#fff"}
                                tickColor={"rgba(144, 155, 175, 0.3)"}
                                dataChartDefault={financialReportResponse.financialHighlight.companyPerformanceReview}
                                enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                              />
                            </div>
                          ) : (
                            <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <span className="text-danger text_italic mt-3 ml-3">No Data</span>
            )}
          </div>
          {/* Financial Highlight - Trang 2*/}
          {financialReportResponse && financialReportResponse.financialHighlight && financialReportResponse.financialHighlight.assetBreakdown !== null && (
            <div className="background_page">
              {/* Asset Breakdown */}

              <div className="row mb-3">
                <div className="col-lg-12 col-xl-12">
                  <div className="card-item ">
                    <div className="text_left text-bold font-s-18 title-color mb-2">Asset Breakdown</div>
                    <div className="card_content">
                      <div className="row">
                        {financialReportResponse.financialHighlight.assetBreakdown && financialReportResponse.financialHighlight.assetBreakdown.length ? (
                          financialReportResponse.financialHighlight.assetBreakdown.map((props, index) => {
                            return (
                              <div key={index} className="col-lg-12 col-xl-12 font-s-18 mb-3">
                                <div className="text_center text-color-blue">{props.enTitle}</div>
                                <div className="chart-law" style={{ borderBottom: "0.5px solid #b2bac4" }}>
                                  <div className="cover-pie-chart">
                                    <PieChartOwner
                                      customFontColor="#0953a8"
                                      showSourceEn={true}
                                      labelFontSize={16}
                                      noSource={index !== financialReportResponse.financialHighlight.assetBreakdown.length - 1 ? true : false}
                                      dataChartStructure={this.formatPieCharts(props.dataChart)}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                        )}
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          )}
          {/* Financial Highlight - Trang 3*/}
          {financialReportResponse && financialReportResponse.financialHighlight && financialReportResponse.financialHighlight.expenseBreakdown !== null && (
            <div className="background_page">
              {/* Expense Breakdown */}

              <div className="row mb-3">
                <div className="col-lg-12 col-xl-12">
                  <div className="card-item ">
                    <div className="text_left text-bold font-s-18 title-color mb-2">Expense Breakdown</div>
                    <div className="card_content">
                      <div className="row">
                        {financialReportResponse.financialHighlight.expenseBreakdown && financialReportResponse.financialHighlight.expenseBreakdown.length ? (
                          financialReportResponse.financialHighlight.expenseBreakdown.map((props, index) => {
                            return (
                              <div key={index} className="col-lg-12 col-xl-12 font-s-18 mb-3">
                                <div className="text_center text-color-blue">{props.enTitle}</div>
                                <div className="chart-law" style={{ borderBottom: "0.5px solid #b2bac4" }}>
                                  <div className="cover-pie-chart">
                                    <PieChartOwner
                                      customFontColor="#0953a8"
                                      labelFontSize={16}
                                      showSourceEn={true}
                                      noSource={index !== financialReportResponse.financialHighlight.expenseBreakdown.length - 1 ? true : false}
                                      dataChartStructure={this.formatPieCharts(props.dataChart)}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Financial Analysis - Financial Health Ratios + Management Efficiency Ratios*/}
          <div className="background_page">
            <div className="title_content mb-2 mt-2">Financial Analysis</div>
            {financialReportResponse && financialReportResponse.financialAnalysis ? (
              <div>
                {/* Financial Health Ratios */}
                {financialReportResponse.financialAnalysis.financialHealthRatios !== null ? (
                  <div className="row mb-3">
                    <div className="col-lg-12 col-xl-12">
                      <div className="card-item ">
                        <div className="text_left text-bold font-s-18 title-color mb-3">Financial Health Ratios</div>
                        <div className="card_content">
                          {financialReportResponse.financialAnalysis &&
                          financialReportResponse.financialAnalysis.financialHealthRatios &&
                          financialReportResponse.financialAnalysis.financialHealthRatios.length ? (
                            <div className="row">
                              <div className="col-lg-6 col-xl-6">
                                <div className="table-custom">
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th className="text_left">Year</th>
                                        {financialReportResponse.financialAnalysis.financialHealthRatios[0].dataChart.map((props, index) => {
                                          return (
                                            <th key={index} className="text_right">
                                              {props.label ? props.label : ""}
                                            </th>
                                          );
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {financialReportResponse.financialAnalysis.financialHealthRatios.map((props, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="text_left width-col-300"> {props.enTitle ? props.enTitle : ""}</td>
                                            {props.dataChart.map((subProps, index) => {
                                              return (
                                                <td key={index} className="text_right">
                                                  {subProps.y !== null && subProps.y !== "" ? formatMoney_2(subProps.y, 2) : ""}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-6">
                                <div className="chart-info chart-multi">
                                  <LinechartTabRatio
                                    customFontColor="#0953a8"
                                    backgroundColor={"#fff"}
                                    title={""}
                                    xType="label"
                                    suf={true}
                                    percent={true}
                                    dataChart={this.formatDataCharts(financialReportResponse.financialAnalysis.financialHealthRatios)}
                                    dataSuf="%"
                                    enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                                    sourceEn={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* Management Efficiency Ratios */}
                <div className="row mb-3">
                  <div className="col-lg-12 col-xl-12">
                    <div className="card-item ">
                      <div className="text_left text-bold font-s-18 title-color mb-3">Management Efficiency Ratios</div>
                      <div className="card_content">
                        {financialReportResponse &&
                        financialReportResponse.financialAnalysis &&
                        financialReportResponse.financialAnalysis.managementEfficiencyRatios &&
                        financialReportResponse.financialAnalysis.managementEfficiencyRatios.length ? (
                          <div className="row">
                            <div className="col-lg-6 col-xl-6">
                              <div className="table-custom">
                                <Table responsive>
                                  <thead>
                                    <tr>
                                      <th className="text_left">Year</th>
                                      {financialReportResponse.financialAnalysis.managementEfficiencyRatios[0].dataChart.map((props, index) => {
                                        return (
                                          <td key={index} className="text_right">
                                            {props.label ? props.label : ""}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {financialReportResponse.financialAnalysis.managementEfficiencyRatios.map((props, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="text_left width-col-300"> {props.enTitle ? props.enTitle : ""}</td>
                                          {props.dataChart.map((subProps, index) => {
                                            return (
                                              <td key={index} className="text_right">
                                                {subProps.y !== null && subProps.y !== "" ? formatMoney_2(subProps.y, 2) : ""}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </div>

                            <div className="col-lg-6 col-xl-6">
                              <div className="chart-info chart-multi">
                                <ParetoChartTabRatio
                                  customFontColor="#0953a8"
                                  sourceEn={true}
                                  labelEnglish={true}
                                  yLabel_2_suffix={"%"}
                                  xType="label"
                                  roles={roles}
                                  dataColumn_1={this.returnDataChart(
                                    this.formatDataCharts(financialReportResponse.financialAnalysis.managementEfficiencyRatios),
                                    "EBIT (Mil VND)"
                                  )}
                                  dataLine_1={this.returnDataChart(
                                    this.formatDataCharts(financialReportResponse.financialAnalysis.managementEfficiencyRatios),
                                    "ROE (%)"
                                  )}
                                  dataLine_2={this.returnDataChart(
                                    this.formatDataCharts(financialReportResponse.financialAnalysis.managementEfficiencyRatios),
                                    "ROA (%)"
                                  )}
                                  backgroundColor={"#fff"}
                                  tickColor={"rgba(144, 155, 175, 0.3)"}
                                  dataChartDefault={financialReportResponse.financialAnalysis.managementEfficiencyRatios}
                                  enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-danger text_italic mt-3 ml-3">No Data</span>
            )}
          </div>
          {/*  Financial Analysis - Profitability Ratios + Growth Ratios*/}
          {financialReportResponse &&
            financialReportResponse.financialAnalysis &&
            (financialReportResponse.financialAnalysis.profitabilityRatios !== null || financialReportResponse.financialAnalysis.growthRatios !== null) && (
              <div className="background_page">
                {/* Profitability Ratios */}

                {financialReportResponse &&
                financialReportResponse.financialAnalysis &&
                financialReportResponse.financialAnalysis.profitabilityRatios !== null ? (
                  <div className="row mb-3">
                    <div className="col-lg-12 col-xl-12">
                      <div className="card-item ">
                        <div className="text_left text-bold font-s-18 title-color mb-3">Profitability Ratios</div>
                        <div className="card_content">
                          {financialReportResponse &&
                          financialReportResponse.financialAnalysis &&
                          financialReportResponse.financialAnalysis.profitabilityRatios &&
                          financialReportResponse.financialAnalysis.profitabilityRatios.length ? (
                            <div className="row">
                              <div className="col-lg-6 col-xl-6">
                                <div className="table-custom">
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th className="text_left">Year</th>
                                        {financialReportResponse.financialAnalysis.profitabilityRatios[0].dataChart.map((props, index) => {
                                          return (
                                            <th key={index} className="text_right">
                                              {props.label ? props.label : ""}
                                            </th>
                                          );
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {financialReportResponse.financialAnalysis.profitabilityRatios.map((props, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="text_left width-col-300"> {props.enTitle ? props.enTitle : ""}</td>

                                            {props.dataChart.map((subProps, index) => {
                                              return (
                                                <td key={index} className="text_right">
                                                  {subProps.y !== null && subProps.y !== "" ? formatMoney_2(subProps.y, 2) : ""}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-6">
                                <div className="chart-info chart-multi">
                                  <LinechartTabRatio
                                    customFontColor="#0953a8"
                                    backgroundColor={"#fff"}
                                    title={""}
                                    xType="label"
                                    suf={true}
                                    percent={true}
                                    dataChart={this.formatDataCharts(financialReportResponse.financialAnalysis.profitabilityRatios)}
                                    dataSuf="%"
                                    enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                                    sourceEn={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* Growth Ratios */}
                {financialReportResponse && financialReportResponse.financialAnalysis && financialReportResponse.financialAnalysis.growthRatios !== null ? (
                  <div className="row mb-3">
                    <div className="col-lg-12 col-xl-12">
                      <div className="card-item ">
                        <div className="text_left text-bold font-s-18 title-color mb-3">Growth Ratios</div>
                        <div className="card_content">
                          {financialReportResponse &&
                          financialReportResponse.financialAnalysis &&
                          financialReportResponse.financialAnalysis.growthRatios &&
                          financialReportResponse.financialAnalysis.growthRatios.length ? (
                            <div className="row">
                              <div className="col-lg-6 col-xl-6">
                                <div className="table-custom">
                                  <Table responsive>
                                    <thead>
                                      <tr>
                                        <th className="text_left">Year</th>
                                        {financialReportResponse.financialAnalysis.growthRatios[0].dataChart.map((props, index) => {
                                          return (
                                            <th key={index} className="text_right">
                                              {props.label ? props.label : ""}
                                            </th>
                                          );
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {financialReportResponse.financialAnalysis.growthRatios.map((props, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="text_left width-col-300"> {props.enTitle ? props.enTitle : ""}</td>
                                            {props.dataChart.map((subProps, index) => {
                                              return (
                                                <td key={index} className="text_right">
                                                  {subProps.y !== null && subProps.y !== "" ? formatMoney_2(subProps.y, 2) : ""}
                                                </td>
                                              );
                                            })}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>

                              <div className="col-lg-6 col-xl-6">
                                <div className="chart-info chart-multi">
                                  <LinechartTabRatio
                                    customFontColor="#0953a8"
                                    backgroundColor={"#fff"}
                                    title={""}
                                    xType="label"
                                    suf={true}
                                    percent={true}
                                    dataChart={this.formatDataCharts(financialReportResponse.financialAnalysis.growthRatios)}
                                    dataSuf="%"
                                    enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                                    sourceEn={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          {/*  Trang Chỉ số ngành - Industry Indicators */}
          <div className="background_page">
            <div className="row mb-3">
              <div className="col-lg-12 col-xl-12 mb-2">
                <div className="card-item ">
                  <div className="text_left text-bold font-s-18 title-color mb-3">Industry Indicators</div>
                  <div className="card_content">
                    {industryDataModel && industryDataModel.length ? (
                      <div className="table-custom">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="text_left">Industry Data</th>
                              <th className="text_right">CAGR (%)</th>
                              {industryDataModel[0] &&
                                industryDataModel[0].industryValues &&
                                industryDataModel[0].industryValues.map((item, i) => {
                                  return (
                                    <th key={i} className="text_right">
                                      {item.years}
                                    </th>
                                  );
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {industryDataModel.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text_left width-col-300"> {item.enTitle ? item.enTitle : ""}</td>
                                  <td className="text_right">{item.cagr !== null ? formatMoney_2(item.cagr, 2) : ""}</td>

                                  {item.industryValues.map((itemValue, index) => {
                                    return (
                                      <td key={index} className="text_right">
                                        {itemValue.value !== null ? formatMoney_2(itemValue.value, 2) : ""}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Trang Top Doanh nghiệp trong ngành - Key Players Analysis */}
          <div className="background_page">
            <div className="row mb-3">
              <div className="col-lg-12 col-xl-12 mb-2">
                <div className="card-item ">
                  <div className="text_left text-bold font-s-18 title-color mb-3">Key Players Analysis</div>
                  <div className="card_content">
                    {topCompanyByNetRevenueModel && topCompanyByNetRevenueModel.length ? (
                      <div className="table-custom">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="text_left width-col-300">Company Name</th>
                              <th className="text_right">
                                Sales volume <br></br> (VND billion)
                              </th>
                              <th className="text_right">
                                Net income <br></br> (VND billion)
                              </th>
                              <th className="text_right">
                                ROA <br></br> (%)
                              </th>
                              <th className="text_right">
                                ROE <br></br>(%)
                              </th>
                              <th className="text_right pr-2">
                                Liability to Equity <br></br> (%)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {topCompanyByNetRevenueModel.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text_left width-col-300">
                                    {" "}
                                    {item.companyEnName ? item.companyEnName : item.companyName ? item.companyName : ""}
                                  </td>
                                  <td className="text_right">{item.netRevenue !== null ? formatMoney_2(parseFloat(item.netRevenue, 10), 2) : ""}</td>
                                  <td className="text_right">{item.netIncome !== null ? formatMoney_2(parseFloat(item.netIncome, 10), 2) : ""}</td>

                                  <td className="text_right">{item.roa !== null ? formatMoney_2(item.roa, 2) : ""}</td>
                                  <td className="text_right">{item.roe !== null ? numberFormat(item.roe, 2) : ""}</td>
                                  <td className="text_right pr-2">{item.liability !== null ? formatMoney_2(item.liability, 2) : ""}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div className="text-danger text_italic text_left mt-3 ml-3">No data.</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="loading" className="loading-download" style={styleLoading}>
            <div
              className="loading-icon"
              style={{
                marginTop: "20%",
                marginLeft: "-60px"
              }}
            >
              <Loader active size="huge" inline="centered" />
              <span className="mt-2">
                {" "}
                {language === "vi"
                  ? "Hệ thống đang tạo báo cáo, vui lòng không đóng trình duyệt!"
                  : "System is generating report, please do not close this tab!"}
              </span>
            </div>
          </div>
          <div id="myMm" style={{ height: "1mm" }} />
        </div>
      </React.Fragment>
    );
  }
}

export default AutoReportTemplate;
