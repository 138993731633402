import * as Types from "../actions";
const INIT_STATE = {
  companyCompareData: [],
  companyCompareDataResult: [],
  companyCompareDataOther: [],
  companyCompareDataOtherResult: [],

  companyCompareChartLive: [],
  companyCompareChartTotal: [],
  limitChart: false,
  loading: false,
  loadingOrther: false,
  totalItems: 0,
  totalPage: 1,
  pageIndex: 1,
  checkBoxCompanyCompareSSLive: {
    loadingBtn: false,
    checkedBtn: 0,
    chitieu_tindung: null,
    chiso_tindung: null,
    //thuong
    chitieu_thuong: null,
    chiso_thuong: null,
    chiso14_thuong: {
      cautrucvon: null,
      tysohieuqua: null,
      tysothanhtoan: null,
      tysoloinhuan: null,
    },
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_LIST_COMPANYCOMPARE:
      const actionx = action.payload.model;
      if (action.payload.typeIP === 2) {
        return {
          ...state,
          loadingOrther: true,
          companyCompareDataOther: [],
          pageIndex: actionx.pageIndex
        };
      } else {
        return {
          ...state,
          loading: true,
          companyCompareDataOther: [],
          pageIndex: actionx.pageIndex
        };
      }
    case Types.GET_LIST_COMPANYCOMPARE_SUCCESS:
      if (action.typeIP === 2) {
        return {
          ...state,
          loadingOrther: false,
          totalItems: action.totalItems,
          totalPage: action.totalPage,
          companyCompareDataOther: action.data,
          pageIndex: action.pageIndex
        };
      } else {
        return {
          ...state,
          loading: false,
          totalItems: action.totalItems,
          totalPage: action.totalPage,
          companyCompareData: action.data,
          pageIndex: action.pageIndex
        };
      }
    case Types.GET_LIST_COMPANYCOMPARE_UPDATE:
      return {
        ...state,
        companyCompareData: action.data,
        companyCompareDataResult: action.returnObj
      };
    case Types.GET_LIST_COMPANYCOMPAREORTHER_UPDATE:
      return {
        ...state,
        companyCompareDataOther: action.data,
        companyCompareDataOtherResult: action.returnObj
      };
    case Types.GET_LIST_COMPANYCOMPARE_RESET:
      return {
        ...state,
        loading: false,
        companyCompareData: []
      };
    case Types.GET_LIST_COMPANYCOMPARE_FAIL:
      if (action.typeIP === 2) {
        return {
          ...state,
          loadingOrther: false,
          companyCompareDataOther: []
        };
      } else {
        return {
          ...state,
          loading: false,
          companyCompareData: []
        };
      }
    case Types.REQUEST_LIVE_CHARTLIST_CP:
      return {
        ...state,
        checkBoxCompanyCompareSSLive: {
          ...state.checkBoxCompanyCompareSSLive,
          loadingBtn: true
        }
      };
    case Types.REQUEST_LIVE_CHARTLIST_CP_FAIL:
      return {
        ...state,
        checkBoxCompanyCompareSSLive: {
          ...state.checkBoxCompanyCompareSSLive,
          loadingBtn: false
        }
      };

    case Types.REQUEST_LIVE_CHARTLIST_CP_SUCCESS:
      return {
        ...state,
        checkBoxCompanyCompareSSLive: {
          ...state.checkBoxCompanyCompareSSLive,
          loadingBtn: false,
          checkedBtn: action.typeID,
          chitieu_tindung: action.data.creditAccount,
          chiso_tindung: action.data.creditRatio,
          bangcandoiketoan_tindung_thuong: action.data.accountOther,
          ketquahdkd_tindung_thuong: action.data.income_Statement,
          tientetructiep_tindung_thuong: action.data.direct_Cash_Flow_Statement,
          tientegiantiep_tindung_thuong: action.data.indirect_Cash_Flow_Statement,
          //thuong
          chitieu_thuong: action.data.account,
          chiso_thuong: action.data.ratio,
          chiso14_thuong: {
            cautrucvon: action.data.cauTrucVon,
            tisohieuqua: action.data.tiSoHieuQuaHoatDong,
            tisothanhtoan: action.data.tiSoThanhToan,
            tisoloinhuan: action.data.tiSuatLoiNhuan,
          }
        }
      };
    // REQUEST_CHART - so sanh truc tiep
    case Types.REFRESH_CHART:
        return {
          ...state,
          limitChart: false,
        };
    case Types.REQUEST_LIVE_CHART_CP:
      return {
        ...state,
        limitChart: false,
        loading: true
      };
    case Types.REQUEST_LIVE_CHART_CP_SUCCESS:
      let lenghtData = [...action.data, ...state.companyCompareChartLive];
      if (lenghtData.length > 8) {
        return {
          ...state,
          loading: false,
          limitChart: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChart: false,
          companyCompareChartLive: [...action.data, ...state.companyCompareChartLive]
        };
      }

    case Types.REQUEST_LIVE_CHART_CP_FAIL:
      return {
        ...state,
        loading: false
      };
    // REQUEST_CHART - so sanh trung binh
    case Types.REQUEST_LIVE_CHART_CP_TB:
      return {
        ...state,
        limitChart: false,
        loading: true
      };
    case Types.REQUEST_LIVE_CHART_CP_TB_SUCCESS:
   
      let lenghtData2 = [...action.data, ...state.companyCompareChartLive];
      if (lenghtData2.length > 8) {
        return {
          ...state,
          loading: false,
          limitChart: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChart: false,
          companyCompareChartLive: [...action.data, ...state.companyCompareChartLive]
        };
      }
    case Types.REQUEST_LIVE_CHART_CP_TB_FAIL:
      return {
        ...state,
        loading: false
      };
    // REQUEST_CHART - so sanh TGT
    case Types.REQUEST_LIVE_CHART_CP_TGT:
      return {
        ...state,
        limitChart: false,
        loading: true
      };
    case Types.REQUEST_LIVE_CHART_CP_TGT_SUCCESS:
      let lenghtData3 = [...action.data, ...state.companyCompareChartLive];
      if (lenghtData3.length > 8) {
        return {
          ...state,
          loading: false,
          limitChart: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChart: false,
          companyCompareChartLive: [...action.data, ...state.companyCompareChartLive]
        };
      }
    case Types.REQUEST_LIVE_CHART_CP_TGT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Types.REQUEST_LIVE_CHART_CP_DELETE:
      var checked = false;
      var arr = [...state.companyCompareChartLive];
      arr.splice(action.id, 1);
      if (arr.length <= 8) {
        checked = false
      }
      return {
        ...state,
        loading: false,
        limitChart: checked,
        companyCompareChartLive: [...arr]
      };
    case Types.REQUEST_LIVE_CHART_CP_RESET:
      return {
        ...state,
        loading: false,
        companyCompareChartLive: []
      };
    // REQUEST_CHART - so sanh nhom
    case Types.REQUEST_TOTAL_CHART_CP:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_TOTAL_CHART_CP_SUCCESS:
      return {
        ...state,
        loading: false,
        companyCompareChartTotal: action.data
      };
    case Types.REQUEST_TOTAL_CHART_CP_FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return { ...state };
  }
};
