import { all } from "redux-saga/effects";
import AccountManagementSaga from "./AccountManagement/sagas";
import AuthenticationSagas from "./Authentication/saga";
import CompanyInfoSagas from "./CompanyInfo/saga";
import CartSaga from "./Cart/saga";
import NewsSaga from "./News/sagas";
import FaqSaga from "./FAQ/sagas";
import CompanyCompareSaga from "./CompanyCompare/saga";
import CommonSaga from "./Common/saga";
import IndustryInformationSaga from "./IndustryInformation/sagas";
export default function* rootSaga(getState) {
  yield all([AuthenticationSagas(), CartSaga(), CompanyInfoSagas(), AccountManagementSaga(), NewsSaga(), FaqSaga(), CompanyCompareSaga(), CommonSaga(), IndustryInformationSaga()]);
}
