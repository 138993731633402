import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import * as commonService from "../../services/common.service";

function* getDataRequestedProvince() {
  try {
    var response = yield call(commonService.requestAPIProvince);
    if (response.data.data) {
      yield put(actions.getListProvinceSuccess(response.data.data));
    }
  } catch (err) {
    console.log("err");
  }
}

function* watchSendDataRequestProvince() {
  yield takeEvery(Types.GET_DATA_PROVINCE, getDataRequestedProvince);
}

function* getDataRequestedDistrict(params) {
  try {
    var response = yield call(commonService.requestAPIDistrict,params.id);

    if (response.data) {
      yield put(actions.getListDistrictSuccess(response.data.data));
    }else{
      yield put({
        type: Types.GET_DATA_DISTRICT_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_DATA_DISTRICT_FAIL
    });
  }
}

function* watchSendDataRequestDistrict() {
  yield takeEvery(Types.GET_DATA_DISTRICT, getDataRequestedDistrict);
}

function* getDataRequestedCommune(params) {
  try {
    var response = yield call(commonService.requestAPICommune,params.id);

    if (response.data) {
      yield put(actions.getListCommuneSuccess(response.data.data));
    }else{
      yield put({
        type: Types.GET_DATA_COMMUNE_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_DATA_COMMUNE_FAIL
    });
  }
}

function* watchSendDataRequestCommune() {
  yield takeEvery(Types.GET_DATA_COMMUNE, getDataRequestedCommune);
}

export default function* rootSaga() {
  yield all([fork(watchSendDataRequestProvince), fork(watchSendDataRequestDistrict), fork(watchSendDataRequestCommune)]);

}
