import * as Types from "../actions";
var initialState = {
  notify: null
};

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.RESET_FETCH_RESOURCE:
      state = initialState;
      return state;
    case Types.NOTIFY:
      state = { ...state, notify: action.data };
      return state;
    default:
      return state;
  }
};

export default fetchReducer;
