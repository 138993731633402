import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../components/CustomButton/CustomButton";
import IntlMessages from "../../utils/IntlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import ConfirmPay from "../AccountsManagement/ConfirmPayComponent";
import { callApiServer } from "../../utils/callApiServer";
import { injectIntl } from "react-intl";
import { actGetCurrentPoint } from "../../redux/AccountManagement/actions";
import { Checkbox } from "semantic-ui-react";
import { saveAs as importedSaveAs } from "file-saver";

export class ElectronicBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileData: "",
      fileName: "",
      wrongFormat: false,
      wrongTemplate: false,
      isUploading: false,

      id: "",

      popupConfirmBuy: "",
      selectedConfirmOption: "",
      agreedTerms: false
    };
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    document.title = "VIRACE | Account Management";

    // this.props.actBanner();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isEmailSpecial } = nextProps.userAccount;
    if (isEmailSpecial !== null && isEmailSpecial !== undefined) {
      if (isEmailSpecial === true) {
        this.props.history.push("/trang-chu-cr");
      }
    }
  }

  chooseFile() {
    this.inputRef.current.click();
  }
  setFiles(data) {
    if (data && data[0]) {
      const fileName = data[0].name;
      const extensionFile = fileName.split(".").pop();
      const arrFileAccept = ["xlsx", "xls"];
      if (arrFileAccept.indexOf(extensionFile) === -1) {
        this.setState({
          fileData: "",
          fileName: "",
          wrongFormat: true,
          wrongTemplate: false
        });

        this.inputRef.current.value = "";
      } else {
        this.setState({
          fileData: data[0],
          fileName: fileName,
          wrongFormat: false,
          wrongTemplate: false
        });
      }
    }
  }
  handleDragOver = (event) => {
    event.preventDefault();
  };

  handleDrop = (event) => {
    event.preventDefault();

    this.setFiles(event.dataTransfer.files);
  };

  async uploadFile() {
    try {
      this.setState({
        isUploading: true
      });
      const formData = new FormData();
      formData.append("excelFile", this.state.fileData);
      const response = await callApiServer.post("/Company/upload-excel-invoices", formData);
      if (response && response.data && response.data.success === true && response.data.data) {
        const data = response.data.data;
        this.setState(
          {
            id: data.productId
          },
          () => {
            this.openPopupConfirmBuy(data.points, data.amount, data.productType);
          }
        );

        this.setState({
          isUploading: false
        });
      } else if (response && response.data && response.data.message.includes("file exceeds")) {
        this.setState({ wrongTemplate: "electronicBill.wrongTemplateSize", isUploading: false });
      } else if (response && response.data && response.data.message === "Input excel file does not have any data") {
        this.setState({ wrongTemplate: "electronicBill.wrongTemplateData", isUploading: false });
      } else if (response && response.data && response.data.message === "Invalid template excel") {
        this.setState({ wrongTemplate: "electronicBill.wrongTemplate", isUploading: false });
      }
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        const errMessage = error.response.data.message;
        if (errMessage === "Invalid template excel") {
          this.setState({
            wrongTemplate: "electronicBill.wrongTemplate"
          });
        } else if (errMessage === "Input excel file does not have any data") {
          this.setState({
            wrongTemplate: "electronicBill.wrongTemplateData"
          });
        } else if (errMessage.includes("file exceeds")) {
          this.setState({
            wrongTemplate: "electronicBill.wrongTemplateSize"
          });
        }
      }
      this.setState({
        fileData: "",
        fileName: "",
        wrongFormat: false,
        isUploading: false,
        agreedTerms: false
      });
      this.inputRef.current.value = "";
    }
  }

  async downloadFileSample() {
    try {
      const language = localStorage.getItem("currentLanguage");

      const fileName = language === "vi" ? "Mẫu thông tin tra cứu hóa đơn.xlsx" : "Invoice information lookup form.xlsx";
      const response = await callApiServer.get("/Company/get-template-invoice-file", {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
      }
    } catch (error) {}
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={this.state.id}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
              if (type === "CompanyDetailView") {
                this.props.history.push("/trang-chu-cr");
              }
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    const { messages } = this.props.intl;

    this.setState({
      popupConfirmBuy: "",
      fileData: "",
      fileName: "",
      wrongFormat: false,
      wrongTemplate: false,
      isUploading: false,
      agreedTerms: false
    });
    this.inputRef.current.value = "";
    this.setState({
      alert: (
        <SweetAlert
          success
          title=""
          confirmBtnText={messages[`newWord.close`]}
          confirmBtnCssClass="confirmBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
        >
          <IntlMessages id="electronicBill.success"></IntlMessages>
        </SweetAlert>
      )
    });
    this.props.handleGetCurentPoint();
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }
  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handleChangeCheckTerm = () => {
    const { agreedTerms } = this.state;

    this.setState({
      agreedTerms: !agreedTerms
    });
  };
  render() {
    const { fileData, fileName, wrongFormat, wrongTemplate, isUploading, agreedTerms } = this.state;
    const language = localStorage.getItem("currentLanguage");

    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}

        <div className="bill-search-company">
          <div className="title">
            <IntlMessages id="electronicBill.title" />
          </div>
          <div className="guide-and-example">
            <div className="guide">
              <IntlMessages id="electronicBill.guide_1" />
            </div>
            <div className="example">
              <span>
                <IntlMessages id="electronicBill.exampleFile" />
              </span>
              <em className="material-icons text-cyan" onClick={() => this.downloadFileSample()}>
                download
              </em>
            </div>
          </div>
          <div className="upload-container" onDragOver={this.handleDragOver} onDrop={this.handleDrop}>
            <div className="icon">
              <em className="material-icons">description</em>
            </div>
            <div className="guide">
              <IntlMessages id="electronicBill.guide_2" />
            </div>
            <div>
              <IntlMessages id="electronicBill.or" />
            </div>
            <div className="choose-file">
              <input ref={this.inputRef} type="file" hidden accept=".xls, .xlsx" onChange={(event) => this.setFiles(event.target.files)} />
              <Button className={`common-button ml-3 `} isLoading={isUploading} onClick={() => this.chooseFile()}>
                <IntlMessages id="electronicBill.chooseFile" />
              </Button>
            </div>

            <div className="fileName-or-error mt-2 mb-2">
              {wrongFormat && !fileName ? (
                <span className="text-danger">
                  <IntlMessages id="electronicBill.wrongFormat" />
                </span>
              ) : (
                <span>{fileName}</span>
              )}
              <br></br>
              {wrongTemplate && (
                <span className="text-danger">
                  <IntlMessages id={wrongTemplate} />
                </span>
              )}
            </div>
          </div>

          <div className="tearm">
            <div className="input-list-Checkbox mr-2">
              <Checkbox checked={agreedTerms} onChange={() => this.handleChangeCheckTerm()} />
            </div>

            <div className="term-description">
              <IntlMessages id="cart.termPart_1" />
              <a
                className="text-cyan"
                href={language === "vi" ? "https://viracresearch.com/chinh-sach-cung-cap-dich-vu/" : "https://viracresearch.com/service-provision-policy/"}
                rel="noreferrer"
                target="_blank"
              >
                <IntlMessages id="cart.termPart_2" />
              </a>
              <IntlMessages id="cart.termPart_3" />
            </div>
          </div>
          <div className="btn-submit">
            <Button
              className={`common-button ml-3 `}
              isLoading={isUploading}
              disabled={!agreedTerms || wrongFormat || !fileName}
              onClick={() => this.uploadFile()}
            >
              <IntlMessages id="electronicBill.submit" />
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqReducer: state.faqReducer,
    userAccount: state.accountReducer.userAccount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetCurentPoint: () => dispatch(actGetCurrentPoint())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ElectronicBill));
