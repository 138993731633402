import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import { formatMoney, formatMoney_2 } from "../../utils/formatMoney";
import { callApiServer } from "../../utils/callApiServer";
import LoaderTable from "../../components/LoaderTable";

import { actGetListRecharge, actRecharge } from "../../services/accountManagement.service";
class Recharge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      listPointOption: [],
      itemSelected: ""
    };
  }
  componentDidMount() {
    this.getListPointOption();
  }

  async getListPointOption() {
    try {
      this.setState({
        isLoading: true
      });
      const response = await actGetListRecharge();
      if (response && response.data && response.data.data && response.data.data.topUpList) {
        this.setState({
          isLoading: false,
          listPointOption: response.data.data.topUpList
        });
      } else {
        this.setState({
          isLoading: false,
          listPointOption: []
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        listPointOption: []
      });
    }
  }

  handleChosePoint(id) {
    this.setState({
      itemSelected: id
    });
  }

  async confirmRecharge() {
    this.setState({
      isLoading: true
    });
    try {
      const body = {
        id: this.state.itemSelected
      };
      const response = await actRecharge(body);
      if (response && response.data && response.data.data) {
        this.setState({
          isLoading: false
        });

        window.location.href = response.data.data.paymentUrl;
      } else {
        this.setState({
          isLoading: false
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false
      });
    }
  }

  cancelRecharge() {
    this.props.cancel();
  }

  render() {
    const { isLoading, listPointOption, itemSelected } = this.state;
    return (
      <React.Fragment>
        {isLoading ? (
          <LoaderTable isLoaded={!isLoading} styleCustom={{ height: "500px" }} />
        ) : (
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <IntlMessages id="vpoint.exchange"></IntlMessages>
              </div>
              <div className="recharge-list-option">
                {listPointOption &&
                  listPointOption.length &&
                  listPointOption.map((item, index) => {
                    return (
                      <div className={`recharge-option ${itemSelected === item.id ? "option-selected" : ""}`} key={index} onClick={() => this.handleChosePoint(item.id)}>
                        {formatMoney_2(parseInt(item.amount), 2)} VND = {formatMoney_2(parseInt(item.viracePoint), 2)} VP + {item.bonusPercent}
                      </div>
                    );
                  })}
              </div>
              <div className="recharge-subtitle text-danger">
                <IntlMessages id="vpoint.includeVAT"></IntlMessages>
              </div>
            </div>
            <div className="recharge-footer">
              <button className="recharge-btn btn-cancel" disabled={isLoading === true} onClick={() => this.cancelRecharge()}>
                <IntlMessages id="common.cancelBtn"></IntlMessages>
              </button>
              <button className="recharge-btn btn-confirm" disabled={isLoading === true || itemSelected === ""} onClick={() => this.confirmRecharge()}>
                <IntlMessages id="cart.confirmPay"></IntlMessages>
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    userAccount: state.accountReducer.userAccount
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};
export default Recharge;
