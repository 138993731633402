import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import LoaderTable from "../../../components/LoaderTable";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { connect } from "react-redux";
import AlertWarning from "../../../components/SweetAlert/AlertWarning";
import { actRequestedLang } from "../../../redux/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { formatMoney, formatMoney_2 } from "../../../utils/formatMoney";
import getUrlParam from "../../../utils/getUrlParam";
import NotificationAlert from "react-notification-alert";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { Table } from "reactstrap";
import moment from "moment";
import NoData from "../../../components/NoData";
import { actGetHistoryPoint, actGetStatusOrder } from "../../../services/accountManagement.service";
import { actGetCurrentPoint } from "../../../redux/AccountManagement/actions";

import { Link } from "react-router-dom";
import AutoReportTemplate from "../../../views/CompanyInfoPage/Tabs/AutoReportTemplate";

import Recharge from "../RechargeComponent";
import { callApiServer } from "../../../utils/callApiServer.js";
import { saveAs as importedSaveAs } from "file-saver";
import { Popup } from "semantic-ui-react";
import { actGetOrder } from "../../../redux/Cart/actions";

export class TabContentTabInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleForgetPass: false,
      radio: false,
      rechargePopup: "",
      openPurchase: getUrlParam()["openPurchase"] ? getUrlParam()["openPurchase"] : "",
      orderId: getUrlParam()["orderId"] ? getUrlParam()["orderId"] : "",
      typeOrder: getUrlParam()["typeOrder"] ? getUrlParam()["typeOrder"] : "",

      tab: getUrlParam()["tab"] ? getUrlParam()["tab"] : "",

      isLoadingHistory: false,
      dataHistory: {},
      page_Purchase: 1,
      pageSize: 5,
      isDownloadingReportExcel: false
    };
  }

  componentDidMount() {
    this.getHistoryPoint();
    this.props.handleGetCurentPoint();
    const { openPurchase, orderId, tab } = this.state;

    if (openPurchase === "true") {
      this.openRecharge();
    }
    if (tab === "1" && orderId) {
      this.getStatusOrder(orderId);
    }
  }

  async getStatusOrder(orderId) {
    try {
      const { typeOrder } = this.state;

      const responseStatus = await actGetStatusOrder(orderId);
      if (responseStatus && responseStatus.data) {
        const status = responseStatus.data.data;
        if (status === true) {
          if (typeOrder === "fromcart") {
            this.props.getOrder(orderId);
          }
          this.notiFy("success");
        } else {
          this.notiFy("fail");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  notiFy(status) {
    const { messages } = this.props.intl;
    const { typeOrder } = this.state;
    let messageSuccess = "";
    if (typeOrder === "fromcart") {
      messageSuccess = messages["cart.isPurchaseSuccess"];
    } else {
      messageSuccess = messages["vpoint.isPurchaseSuccess"];
    }
    if (status === "success") {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messageSuccess}</div>
          </div>
        ),
        type: "success",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    } else {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["vpoint.isPurchaseFail"]}</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  async getHistoryPoint() {
    this.setState({
      isLoadingHistory: true,
      dataHistory: {}
    });
    try {
      const { pageSize, page_Purchase } = this.state;

      let params = {
        pageSize: pageSize,
        pageIndex: page_Purchase
      };
      const response = await actGetHistoryPoint(params);
      if (response && response.data && response.data.data) {
        this.setState({
          dataHistory: response.data.data,
          isLoadingHistory: false
        });
      } else {
        this.setState({
          dataHistory: {},
          isLoadingHistory: false
        });
      }
    } catch (error) {
      this.setState({
        dataHistory: {},
        isLoadingHistory: false
      });
    }
  }

  handlePagination = (e, { activePage }) => {
    this.setState(
      {
        page_Purchase: activePage
      },
      () => this.getHistoryPoint()
    );
  };

  handlePopupForgetPass = () => {
    this.setState((state) => ({
      toggleForgetPass: !state.toggleForgetPass
    }));
  };

  handleCloseForgetPass = (prams) => {
    this.setState({
      toggleForgetPass: prams
    });
  };

  onChange = (params) => {
    this.actChangeLang(params);
  };
  actChangeLang = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          message={messages[`newWord.Changelanguage`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmToFunc={() => {
            this.props.actRequestedLang(params);
            this.handleHideAlert();
          }}
          cancleToFunc={this.handleHideAlert}
        />
      )
    });
  };
  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  openRecharge = () => {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    this.setState({
      rechargePopup: (
        <SweetAlert
          default
          title={messages["vpoint.rechargeTitle"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              rechargePopup: ""
            });
          }}
        >
          <Recharge
            cancel={() =>
              this.setState({
                rechargePopup: ""
              })
            }
          ></Recharge>
        </SweetAlert>
      )
    });
  };
  setPointSelected(data) {}

  renderPointChange(number) {
    if (number > 0) {
      return `+${formatMoney_2(number, 2)}`;
    } else {
      return formatMoney_2(number, 2);
    }
  }
  renderAction(subitem) {
    const { messages } = this.props.intl;
    if (subitem.pointAction) {
      return <span>{messages[`vpoint.action.${subitem.pointAction}`]}</span>;
    } else return "";
  }

  renderTime(time) {
    if (time) return moment(time).format("HH:mm - DD/MM/YYYY");
    else return "";
  }

  renderDetail(data) {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");

    const pointAction = data.pointAction;
    if (pointAction === 1) {
      return messages["vpoint.success"];
    } else if (pointAction === 2) {
      return (
        <div className="report-item-container">
          <span className="report-item-name">
            <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${data.productId ? data.productId : ""}`}>
              {language === "vi" ? data.productName : data.productEnName}
            </Link>
          </span>
        </div>
      );
    } else if (pointAction === 3 || pointAction === 4) {
      return (
        <div className="report-item-container">
          <span className="report-item-name">{language === "vi" ? data.productName : data.productEnName}</span>

          {data.isPurchased && (
            <span className="report-item-action">
              <div
                className="text-cyan"
                onClick={() =>
                  pointAction === 3
                    ? this.handleDownloadReportAuto(data.productId)
                    : this.handleDownloadReportExcel(data.productId, data.companyEnName ? data.companyEnName : data.productName ? data.productName : "")
                }
              >
                <em className="material-icons mr-1 text-cyan">download</em>
                <span style={{ minWidth: "51px" }}>{pointAction === 3 ? " ( pdf )" : " (excel) "}</span>
              </div>
            </span>
          )}
        </div>
      );
    } else if (pointAction === 6) {
      const toolTipResponse = JSON.parse(data.tooltip);
      let tooltipData;
      if (toolTipResponse) {
        tooltipData = JSON.parse(toolTipResponse.SearchTooltip ? toolTipResponse.SearchTooltip : "");
      }

      return (
        <div className="report-item-container">
          <span className="report-item-name">{language === "vi" ? data.productName : data.productEnName}</span>

          <span className="report-item-action">
            {data && data.isPurchased && (
              <div className="text-cyan" onClick={() => this.handleDownloadCompanySearch(data.productId, data.productName ? data.productName : data.productEnName ? data.productEnName : "")}>
                <em className="material-icons mr-1 text-cyan">download</em>
              </div>
            )}

            <Popup
              trigger={
                <span style={{ height: "25px", fontSize: "16px", color: "#b9cbe8" }} className="material-icons  tooltip-icon cursor-default ml-2">
                  info
                </span>
              }
              className="custom-popup ml-1"
            >
              <ul className="pl-0 mb-0">
                {tooltipData && tooltipData.basic ? (
                  <li>
                    - <IntlMessages id="industry.filter.keyWord" /> : <span>{tooltipData.basic}</span>
                  </li>
                ) : (
                  <React.Fragment>
                    {tooltipData && tooltipData.advance && tooltipData.advance.length > 0 && (
                      <span>
                        {tooltipData.advance.map((prop, index) => {
                          return (
                            <li key={index}>
                              -
                              {prop.type === "area" && (
                                <span>
                                  <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                  <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${prop.area.districtName ? prop.area.districtName + ", " : ""}${prop.area.provinceName}`}</span>
                                </span>
                              )}
                              {prop.type === "industry" && (
                                <span>
                                  <IntlMessages id="sidebar.Industry" /> {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                  {prop.industry.code}
                                </span>
                              )}
                              {prop.type === "companyType" && (
                                <span>
                                  <IntlMessages id="advanceSearch.companyType.companyType" /> :{language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} (
                                  {prop.dataPoint}):{" "}
                                </span>
                              )}
                              {prop.type === "legal" && (
                                <span>
                                  <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.idPerson}{" "}
                                </span>
                              )}
                              {prop.type === "owner" && (
                                <span>
                                  <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.showId}{" "}
                                </span>
                              )}
                              {prop.type === "finance" && (
                                <span>
                                  {prop.criteria.criteriaText} ({prop.dataPoint}):
                                  <span>
                                    {prop.criteria.from
                                      ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit}) `
                                      : ""}
                                    {prop.criteria.to
                                      ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit})`
                                      : ""}
                                  </span>
                                </span>
                              )}
                              {prop.type === "importExport_year" && (
                                <span>
                                  {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                  <span> ({prop.criteria.year}):</span>
                                  <span>
                                    {prop.criteria.from
                                      ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.unit}) `
                                      : ""}
                                    {prop.criteria.to ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.unit})` : ""}
                                  </span>
                                </span>
                              )}
                              {prop.type === "importExport_hs" && (
                                <span>
                                  {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                  <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                </span>
                              )}
                            </li>
                          );
                        })}
                      </span>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </Popup>
          </span>
        </div>
      );
    } else if (pointAction === 7) {
      return (
        <div className="report-item-container">
          <span className="report-item-name">
            <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${data.productId ? data.productId : ""}`}>
              {language === "vi" ? data.productName : data.productEnName}
            </Link>
          </span>
        </div>
      );
    } else if (pointAction === 8 || pointAction === 9) {
      return (
        <div className="report-item-container">
          <span className="report-item-name">{language === "vi" ? data.productName : data.productEnName}</span>
        </div>
      );
    } else {
      return language === "vi" ? data.productName : data.productEnName;
    }
  }

  handleDownloadReportAuto(id) {
    const { messages } = this.props.intl;
    this.setState({
      templateAutoReport: (
        <SweetAlert default hideOverlay={false} buttons={false} allowEscape={true} title={"Report Company"} customClass="auto-report-popup" showCancel={true}>
          <AutoReportTemplate closeTemplate={this.closeAutoReport} id={id}></AutoReportTemplate>
        </SweetAlert>
      )
    });
  }
  closeAutoReport = () => {
    this.setState({
      templateAutoReport: ""
    });
  };
  async handleDownloadReportExcel(id, name) {
    try {
      this.setState({
        isDownloadingReportExcel: true
      });
      const fileName = `Instant_Company_Report_${name}.xlsx`;
      const urlDownload = `/Company/export-excel-company-report?companyId=${id}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingReportExcel: false
        });
      } else {
        this.setState({
          isDownloadingReportExcel: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingReportExcel: false
      });
    }
  }
  async handleDownloadCompanySearch(id, name) {
    try {
      this.setState({
        isDownloadingReportExcel: true
      });
      const fileName = `${name}.xlsx`;
      const urlDownload = `/Company/download-company-search-export?productId=${id}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingCompanySearch: false
        });
      } else {
        this.setState({
          isDownloadingCompanySearch: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingCompanySearch: false
      });
    }
  }

  render() {
    const language = localStorage.getItem("currentLanguage");

    const { userAccount, loadingUser, currentPoint } = this.props;
    const { isLoadingHistory, page_Purchase, dataHistory, pageSize } = this.state;

    return (
      <React.Fragment>
        <div className="manament-tabplane-info">
          <NotificationAlert ref="notificationAlert" />

          {this.state.alert}
          {this.state.rechargePopup}
          {this.state.templateAutoReport}

          {loadingUser ? (
            <LoaderTable styleCustom={{ height: "80vh" }} />
          ) : (
            <React.Fragment>
              <div className="name-and-point">
                <span className="manament-tabplane-info-name">{userAccount.fullName ? userAccount.fullName : ""}</span>
                <div className="devide"></div>
                <div className="point-value mr-2">
                  <div className="point-icon">VP</div>
                  <div className="point-number">{formatMoney_2(currentPoint, 2)}</div>
                </div>
                <div className="point-recharge-action mr-2" onClick={this.openRecharge}>
                  <span className="cart-icon material-icons mr-2" style={{ color: "#fff", fontSize: "18px" }}>
                    add_circle
                  </span>

                  <IntlMessages id="vpoint.rechargeAction" />
                </div>
              </div>
              <div className="padding-content">
                <div className="manament-tabplane-info-job">
                  <span className="material-icons">domain</span>
                  <p>{userAccount.companyName ? userAccount.companyName : ""}</p>
                </div>
                <div className="manament-tabplane-info-phone">
                  <span className="material-icons">phone</span>
                  <p>{userAccount.phoneNumber ? userAccount.phoneNumber : ""}</p>
                </div>
                <div className="manament-tabplane-info-mail">
                  <span className="material-icons">email</span>
                  <p>{userAccount.email ? userAccount.email : ""}</p>
                </div>
                <div className="manament-tabplane-info-lang">
                  <label>
                    <IntlMessages id="user.lang" />
                  </label>
                  <FormGroup check style={{ marginLeft: "20px" }}>
                    <Label check className="container">
                      <Input
                        type="radio"
                        name="radio"
                        onChange={() => this.onChange("en")}
                        checked={userAccount.lang && userAccount.lang === "en" ? true : false}
                        value={userAccount.lang && userAccount.lang ? true : false}
                      />
                      <IntlMessages id="user.langEN" />
                      <span className="checkmark"></span>
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-3">
                    <Label check className="container">
                      <Input
                        type="radio"
                        name="radio"
                        onChange={() => this.onChange("vi")}
                        checked={userAccount.lang && userAccount.lang === "vi" ? true : false}
                        value={userAccount.lang && userAccount.lang ? true : false}
                      />
                      <IntlMessages id="user.langVN" />
                      <span className="checkmark"></span>
                    </Label>
                  </FormGroup>
                </div>
              </div>

              {/* Lịch sử mua hàng */}
              <div className="manament-tabplane-history mt-5">
                <div style={{ color: "#edf6ff", fontSize: "18px" }} className="mb-2">
                  <IntlMessages id="vpoint.history.title" />
                </div>

                {dataHistory && dataHistory.data && dataHistory.data.length > 0 ? (
                  <div className="table-custom ">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-center">
                            <IntlMessages id="table.sttTable" />
                          </th>
                          <th className="text-right">
                            <IntlMessages id="vpoint.history.balance" />
                          </th>
                          <th className="text-right">
                            <IntlMessages id="vpoint.history.change" />
                          </th>

                          {/* <th>
                            <IntlMessages id="vpoint.history.action" />
                          </th>
                          <th>
                            <IntlMessages id="vpoint.history.detail" />
                          </th> */}

                          <th className="text-right">
                            <IntlMessages id="vpoint.history.time" />
                          </th>
                          {/* <th className="text-right">
                            <IntlMessages id="vpoint.history.effectDay" />
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {dataHistory.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{(page_Purchase - 1) * pageSize + index + 1}</span>
                              </td>
                              <td className="text-right">
                                <span>{item.remainPoint ? formatMoney_2(item.remainPoint, 2) : ""}</span>
                              </td>
                              <td className="text-right">
                                <span>{item.pointChange !== null && item.pointChange !== undefined ? this.renderPointChange(item.pointChange) : ""}</span>
                              </td>

                              {/* <td>
                                {item.pointPaymentActionModels &&
                                  item.pointPaymentActionModels.length &&
                                  item.pointPaymentActionModels.map((subItem, index) => {
                                    return (
                                      <span key={index} className="report-item-name">
                                        {this.renderAction(subItem)}
                                      </span>
                                    );
                                  })}
                              </td>
                              <td style={{ width: "350px" }}>
                                {item.pointPaymentActionModels &&
                                  item.pointPaymentActionModels.length &&
                                  item.pointPaymentActionModels.map((subItem, index) => {
                                    return <span key={index}>{this.renderDetail(subItem)}</span>;
                                  })}
                              </td> */}

                              <td className="text-right">
                                <span>{item.createdDate ? this.renderTime(item.createdDate) : ""}</span>
                              </td>

                              {/* <td className="text-right">
                                {item.pointPaymentActionModels &&
                                  item.pointPaymentActionModels.length &&
                                  item.pointPaymentActionModels.map((subItem, index) => {
                                    return (
                                      <div style={{ height: "75px" }} key={index}>
                                        {this.renderTime(subItem.effectDay ? subItem.effectDay : "")}
                                      </div>
                                    );
                                  })}
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : isLoadingHistory ? (
                  <LoaderTable isLoaded={!isLoadingHistory} styleCustom={{ height: "80vh" }} />
                ) : (
                  <NoData />
                )}

                {dataHistory && dataHistory.data && dataHistory.data.length > 0 && (
                  <div
                    className="foot-pagination text-right"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px"
                    }}
                  >
                    <Pagination totalPages={dataHistory.totalPages} activePage={page_Purchase} onPageChange={this.handlePagination} />
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPoint: state.accountReducer.currentPoint
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actRequestedLang: (lang) => dispatch(actRequestedLang(lang)),
    handleGetCurentPoint: () => dispatch(actGetCurrentPoint()),

    // lấy thông tin đơn hàng và xóa khỏi giỏ hàng
    getOrder: (sessionId) => dispatch(actGetOrder(sessionId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabContentTabInfo));
