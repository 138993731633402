import { Formik } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/images/logo.svg";
import BG from "../../assets/images/login.png";
import { actChangePassword } from "../../redux/Authentication/actions";
import ModalConfirmPass from "./modal/ModalConfirmPass";
import Button from "../../components/CustomButton/CustomButton";
import getUrlParam from "../../utils/getUrlParam";
import Header from "../../components/Header/HomeHead";
import IntlMessages from "../../utils/IntlMessages";
import { injectIntl } from "react-intl";
import ModalConfidentiality from "../../components/Footer/ModalConfidentiality";
import ModalCopyright from "../../components/Footer/ModalCopyright";
import ModalDisclaimer from "../../components/Footer/ModalDisclaimer";
import { Redirect } from "react-router-dom";
class ChangeNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheck: false,
      toggleForgetPass: false,
      token: getUrlParam()["token"] ? getUrlParam()["token"] : "",
      uid: getUrlParam()["uid"] ? getUrlParam()["uid"] : "",
      mac: getUrlParam()["mac"] ? getUrlParam()["mac"] : "",
      generatedtime: getUrlParam()["generatedtime"] ? parseFloat(getUrlParam()["generatedtime"]) : "",

      success: false
    };
  }

  componentDidMount() {
    document.title = "VIRACE | Change Password";

    // if (getUrlParam()["token"] === null || getUrlParam()["token"] === undefined || getUrlParam()["uid"] === null || getUrlParam()["uid"] === undefined) {
    //     this.props.history.push("/")
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const confirmToken = nextProps.authReducer;
    if (confirmToken) {
      if (confirmToken.success) {
        this.setState({
          toggleForgetPass: true,
          success: confirmToken.success
        });
      } else {
        this.setState({
          toggleForgetPass: true,
          success: confirmToken.success
        });
      }
    }
  }

  redirectLogin = () => {
    this.props.history.push("/dang-nhap");
    // window.location.replace("/dang-nhap");
  };

  handleCloseForgetPass = (success) => {
    this.setState({
      toggleForgetPass: false
    });
    if (success) {
      localStorage.removeItem("isRemember");
      this.redirectLogin();
    }
  };

  handleModalConfidentiality = () => {
    this.setState((state) => ({
      toggleModalConfidentiality: !state.toggleModalConfidentiality
    }));
  };

  handleModalDisclaimer = () => {
    this.setState((state) => ({
      toggleModalModalDisclaimer: !state.toggleModalModalDisclaimer
    }));
  };

  handleModalCopyright = () => {
    this.setState((state) => ({
      toggleModalModalCopyright: !state.toggleModalModalCopyright
    }));
  };

  render() {
    const { token, uid, mac, generatedtime } = this.state;
    const { loading } = this.props.authReducer;
    const { messages } = this.props.intl;
    var d = new Date();
    var n = d.getFullYear();
    const access_token = localStorage.getItem("access_token");
    return (
      <div className="login-page" style={{ backgroundImage: `url(${BG})` }}>
        {access_token && <Redirect to="/menu-dieu-huong" />}
        {this.state.toggleForgetPass && !loading && (
          <ModalConfirmPass
            success={this.state.success}
            toggleForgetPass={this.state.toggleForgetPass}
            handleCloseForget={this.handleCloseForgetPass}
            redirectLogin={this.redirectLogin}
          />
        )}
        {this.state.toggleModalConfidentiality && (
          <ModalConfidentiality toggleModal={this.state.toggleModalConfidentiality} handleCloseForget={this.handleModalConfidentiality} />
        )}
        {this.state.toggleModalModalDisclaimer && (
          <ModalDisclaimer toggleModal={this.state.toggleModalModalDisclaimer} handleCloseForget={this.handleModalDisclaimer} />
        )}
        {this.state.toggleModalModalCopyright && (
          <ModalCopyright toggleModal={this.state.toggleModalModalCopyright} handleCloseForget={this.handleModalCopyright} />
        )}
        <Header history={this.props.history} />
        <div className="login-form-container form-container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="login-form">
            <Formik
              initialValues={{
                password: "",
                passConfirm: ""
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                setTimeout(() => {
                  this.props.handleChangePassword(values.password, token, uid, mac, generatedtime);
                  this.setState({
                    isCheck: true
                  });
                  setSubmitting(false);
                }, 500);
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required(messages[`error.passRequired`])
                  .min(8, messages[`error.passMinLength`])
                  .matches(/^.*((?=.*[!~/*^$&@]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).[^\s]*$/, messages[`error.passFormatted`]),
                passConfirm: Yup.string()
                  .required(messages[`error.confirmPassRequired`])
                  .oneOf([Yup.ref("password"), null], messages[`error.confirmPassMatch`])
              })}
            >
              {(props) => {
                const { values, errors, handleChange, handleSubmit, handleBlur } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <h1>
                      <IntlMessages id="auth.changePass" />
                    </h1>
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">lock</span>
                        </div>
                        <input
                          type="password"
                          name="password"
                          autoComplete="off"
                          placeholder={messages[`auth.enterNewPass`]}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      {errors.password && <div className="form-error">{errors.password}</div>}
                    </div>
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">lock</span>
                        </div>
                        <input
                          type="password"
                          name="passConfirm"
                          autoComplete="off"
                          placeholder={messages[`auth.enterNewPassAgain`]}
                          value={values.passConfirm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      {errors.passConfirm && <div className="form-error">{errors.passConfirm}</div>}
                    </div>
                    <div className="form-submit login-submit">
                      <Button disabled={loading} isLoading={loading} className="common-button w-100">
                        <IntlMessages id="auth.changePass" />
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          <div className="copyright">
            <p>
              <a href="#" onClick={() => this.handleModalCopyright()}>
                COPYRIGHT &copy; {n} VIRAC
              </a>
            </p>
            <p>
              <a href="#" onClick={() => this.handleModalConfidentiality()}>
                <IntlMessages id="footer.confidentiality" />
              </a>{" "}
              |{" "}
              <a href="#" onClick={() => this.handleModalDisclaimer()}>
                <IntlMessages id="footer.disclaimer" />
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ChangeNewPassword.propTypes = {
  state: PropTypes.object
};
const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer.confirmToken
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    handleChangePassword: (newPassword, token, uid, mac, generatedtime) => dispatch(actChangePassword(newPassword, token, uid, mac, generatedtime))
  };
};

ChangeNewPassword.defaultProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangeNewPassword));
