import React, { Component } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, ButtonDropdown, DropdownItem } from "reactstrap";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import nav_head_lang_vn from "../../assets/images/ic/nav_head_lang_vn.png";
import nav_head_lang_us from "../../assets/images/ic/nav_head_lang.png";
import { changeLocale, actLogout, actLogoutchecked } from "../../redux/actions";
import { getDirection, setDirection } from "../../utils/helperLang";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { deleteCookie } from "../../utils/cookies";
import SweetAlert from "react-bootstrap-sweetalert";
import { actGetUserAccount, actGetCurrentPoint } from "../../redux/AccountManagement/actions";
import { formatMoney, formatMoney_2 } from "../../utils/formatMoney";
import { Popup } from "semantic-ui-react";

import { _Basic_ } from "../../constants/rolesModel";

class HomeHeadInfo extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      active: 0,
      lang: ""
    };
  }

  componentDidMount() {
    if (localStorage.getItem("access_token")) {
      this.props.handleGetAccountManagement();
      this.props.handleGetCurentPoint();
    }
    let lang = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi";
    this.setState({ lang });
    this.handleChangeLocale(lang, "ltr");
  }

  handleChangeLocale = (lang, direction) => {
    this.setState({ lang });
    this.props.changeLocale(lang);
    const currentDirection = getDirection().direction;
    if (direction !== currentDirection) {
      setDirection(direction);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleActiveMenu = (active) => {
    this.setState({ active });
  };

  redirectURL = () => {
    this.props.logout();
    localStorage.removeItem("access_token");
    deleteCookie("access_token");
    this.handleHideAlert();
    this.props.history.push("/dang-nhap");
    // window.location.href = "/dang-nhap";
  };

  actLogout = () => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmBtnText={messages[`user.Confirm`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          closeOnClickOutside={false}
          showCancel={true}
          onCancel={this.handleHideAlert}
          onConfirm={() => {
            this.props.actLogoutchecked(this.redirectURL);
          }}
        >
          <IntlMessages id="user.LogOffB" />
        </SweetAlert>
      )
    });
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  goHomeCenter() {
    const language = localStorage.getItem("currentLanguage");

    // let urlVi = "https://viracresearch.com/trang-chu/";
    // let urlEn = "https://viracresearch.com/home/";

    let urlVi = "/menu-dieu-huong";
    let urlEn = "/menu-dieu-huong";

    if (language === "vi") {
      window.parent.location.href = urlVi;
    } else {
      window.parent.location.href = urlEn;
    }
  }

  render() {
    const { loading } = this.props.checkLoggedDevice;
    const { isPropsChildren, lengthDataCart, lengthDataNotice, locale, userAccount, currentPoint } = this.props;
    const { messages } = this.props.intl;

    let role;
    if (this.props && this.props.userAccount) {
      role = this.props.userAccount.roleClient;
    }

    var mainUrl = localStorage.getItem("access_token") && role && role !== _Basic_ ? "/menu-dieu-huong" : "/trang-chu-if-ir";

    return (
      <div className="header_home_info" style={isPropsChildren ? { flex: "1", width: "initial!important" } : {}}>
        {loading ? (
          this.state.alert
        ) : (
          <SweetAlert
            warning
            title={messages[`user.Notification`]}
            customClass="swal-wide"
            showConfirm={false}
            onConfirm={() => {
              this.handleHideAlert();
            }}
            closeOnClickOutside={false}
            confirmBtnCssClass="confirmBtn swal2-styled"
            cancelBtnCssClass="cancelBBtn swal2-styled"
          >
            <IntlMessages id="newWord.logoutSystem" />
          </SweetAlert>
        )}
        <div className="header-home-info-item">
          <ul>
            {/* <li>
              <NavLink
                to={`/tin-tuc-su-kien`}
                className="nav-link"
                activeClassName="active"
              >
                <IntlMessages id="menu.newevent" />
              </NavLink>
            </li> */}
            <li>
              {/* <a href="#" className="nav-link" onClick={() => this.goHomeCenter()}>
                <IntlMessages id="menu.homeCenter" />
              </a> */}

              <NavLink to={mainUrl} className="nav-link">
                <IntlMessages id="menu.homeCenter" />
              </NavLink>
            </li>
            {localStorage.getItem("access_token") !== null ? (
              ""
            ) : (
              <li>
                <NavLink to={`/dang-nhap`} className="nav-link">
                  <IntlMessages id="auth.login" /> / <IntlMessages id="auth.register" />
                </NavLink>
              </li>
            )}

            <li>
              <NavLink to={`/lien-he`} className="nav-link">
                <IntlMessages id="menu.contact" />
              </NavLink>
            </li>
            <li>
              <NavLink to={`/faqs`} className="nav-link">
                <IntlMessages id="menu.faq" />
              </NavLink>
            </li>
            <li>
              <NavLink to={`/cart`} className="nav-link cart-wrapper">
                <span className="material-icons">shopping_cart</span>
                {lengthDataCart && lengthDataCart.length > 0 && (
                  <span className={lengthDataCart.length > 99 ? "large" : "cart-amount"}>{lengthDataCart.length > 99 ? "99+" : lengthDataCart.length}</span>
                )}
              </NavLink>
            </li>
            {/* {localStorage.getItem("access_token") && (
              <li>
                <NavLink to={`/cart`} className="nav-link cart-wrapper" activeClassName="active">
                  <span className="material-icons">shopping_cart</span>
                  {lengthDataCart && lengthDataCart.length > 0 && (
                    <span className={lengthDataCart.length > 99 ? "large" : "cart-amount"}>{lengthDataCart.length > 99 ? "99+" : lengthDataCart.length}</span>
                  )}
                </NavLink>
              </li>
            )} */}
            {localStorage.getItem("access_token") && (
              <li>
                <NavLink to={`/quan-li-tai-khoan?tab=4`} className="nav-link cart-wrapper">
                  <span className="material-icons">notifications</span>
                  {lengthDataNotice && lengthDataNotice.length >= 0 && (
                    <span className={lengthDataNotice.length > 99 ? "large" : "cart-amount"}>{lengthDataNotice.length > 99 ? "99+" : lengthDataNotice.length}</span>
                  )}
                </NavLink>
              </li>
            )}
          </ul>
          <div className="d-flex align-items-center">
            <UncontrolledDropdown className="custom-dropdown-lang">
              <DropdownToggle color="light" size="sm">
                {locale === "vi" ? <img src={nav_head_lang_vn} alt="" className="img_fix_width_" /> : <img src={nav_head_lang_us} alt="" className="img_fix_width_" />}
                <span className="material-icons">expand_more</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.handleChangeLocale("en", "ltr")} key={"en"}>
                  <img src={nav_head_lang_us} alt="" className="img_fix_width_" /> EN
                </DropdownItem>
                <DropdownItem onClick={() => this.handleChangeLocale("vi", "ltr")} key={"vi"}>
                  <img src={nav_head_lang_vn} alt="" className="img_fix_width_" /> VI
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {localStorage.getItem("access_token") && (
              <Link to={`/quan-li-tai-khoan?tab=1`} className="nav-link cart-wrapper">
                <div className="point-value mr-2 ">
                  <div className="point-icon">VP</div>
                  <div className="point-number">{formatMoney_2(currentPoint, 2)}</div>
                  <Popup
                    trigger={
                      <span style={{ fontSize: "16px", color: "#b9cbe8" }} className="material-icons  tooltip-icon cursor-default ml-2">
                        info
                      </span>
                    }
                    className="custom-popup"
                  >
                    <IntlMessages id="vpoint.history.balance"></IntlMessages>
                  </Popup>
                </div>
              </Link>
            )}
            {localStorage.getItem("access_token") && (
              <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="custom-dropdown-profile">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <span className="material-icons">account_circle</span>
                    {userAccount && userAccount.fullName && (
                      <span className="username">
                        <IntlMessages id="user.hello" />, {userAccount.fullName}
                      </span>
                    )}
                    <span className="material-icons">expand_more</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link to={"/quan-li-tai-khoan?tab=1"} onClick={() => this.handleActiveMenu(1)}>
                      <DropdownItem>
                        <span className="material-icons">account_circle</span>
                        <IntlMessages id="user.profile" />
                      </DropdownItem>
                    </Link>
                    <Link to={"/quan-li-tai-khoan?tab=2"} onClick={() => this.handleActiveMenu(2)}>
                      <DropdownItem>
                        <span className="material-icons">history</span>
                        <IntlMessages id="user.RequestHistory" />
                      </DropdownItem>
                    </Link>

                    {/* <Link to={"/quan-li-tai-khoan?tab=3"} onClick={() => this.handleActiveMenu(3)}>
                      <DropdownItem>
                        <span className="material-icons">history</span>
                        <IntlMessages id="user.LogHistory" />
                      </DropdownItem>
                    </Link> */}

                    <Link to={"/quan-li-tai-khoan?tab=5"} onClick={() => this.handleActiveMenu(5)}>
                      <DropdownItem>
                        <span className="material-icons">lock</span>
                        <IntlMessages id="user.changpass" />
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      onClick={() => {
                        this.actLogout();
                      }}
                    >
                      <span className="material-icons">logout</span>
                      <IntlMessages id="user.LogOff" />
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ButtonDropdown>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.settings;
  return {
    lengthDataCart: state.cartReducer.dataRequired.dataRequest,
    lengthDataNotice: state.accountReducer.notiUnSeen,
    checkLoggedDevice: state.authReducer.checkLoggedDevice,
    locale,
    userAccount: state.accountReducer.userAccount,
    currentPoint: state.accountReducer.currentPoint
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actLogout()),
    changeLocale: (locale) => dispatch(changeLocale(locale)),
    actLogoutchecked: (cbSuccess) => dispatch(actLogoutchecked(cbSuccess)),
    handleGetAccountManagement: () => dispatch(actGetUserAccount()),
    handleGetCurentPoint: () => dispatch(actGetCurrentPoint())
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HomeHeadInfo));
