import * as Types from "../actions";

const INIT_STATE = {
    newsList: [],
    hotNewsList: [],
    totalPages: 0,
    hotNewsItem: {},
    newsItem: {},
    loadingNews: false,
    messageErr: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        //GET - ALL NEWS
        case Types.GET_ALL:
            return {
                ...state,
                loadingNews: true
            };
        //GET - NEWS - BY - CATEGORY

        case Types.GET_NEWS_SUCCESS:
            var { newsList, totalPages } = action.payload;
            return {
                ...state,
                newsList,
                totalPages,
                loadingNews: false
            };

        case Types.GET_NEWS_FAIL:
            let { err } = action.payload;
            return {
                ...state,
                messageErr: err,
                loadingNews: false
            };

        //GET - HOT - NEWS
        case Types.GET_HOT_NEWS:
            return {
                ...state,
                loadingNews: true
            };

        case Types.GET_HOT_NEWS_SUCCESS:
            var { hotNewsList } = action.payload;
            return {
                ...state,
                hotNewsList,
                loadingNews: false,
                hotNewsItem: hotNewsList[0]
            };

        case Types.GET_HOT_NEWS_FAIL:
            let errx = action.payload.err;
            return {
                ...state,
                messageErr: errx,
                hotNewsItem: {},
                loadingNews: false
            };

        //GET - NEWS - ITEM    
        case Types.GET_NEWS_BY_ID:
            return {
                ...state,
                loadingNews: true
            };
        case Types.GET_NEWS_BY_ID_SUCCESS:
            var { newsItem } = { ...action.payload };
            return {
                ...state,
                newsItem,
                loadingNews: false
            };
        case Types.GET_NEWS_BY_ID_FAIL:
            let  errr = action.payload.err;
            return {
                ...state,
                message: errr,
                loadingNews: false
            };

        default:
            return state;
    }
};
