import * as Types from "../actions";

const INIT_STATE = {
  listProvince: [],
  listDistrict: [],
  listCommune: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_DATA_PROVINCE_SUCCESS:
      return {
        ...state,
        listProvince: [...action.data]
      };
    case Types.GET_DATA_PROVINCE_FAIL:
      return {
        ...state,
        listProvince: []
      };
    case Types.GET_DATA_DISTRICT_SUCCESS:
      let districts = action.data.map(props => {
        return { key: props.districtsCode, value: props.id, text: props.name };
      });
      return {
        ...state,
        listDistrict: [...action.data]
      };
    case Types.GET_DATA_DISTRICT_FAIL:
      return {
        ...state,
        listDistrict: []
      };
    case Types.GET_DATA_COMMUNE_SUCCESS:
      let communes = action.data.map(props => {
        return { key: props.id, value: props.id, text: props.name };
      });
      return {
        ...state,
        listCommune: [...action.data]
      };
    case Types.GET_DATA_COMMUNE_FAIL:
      return {
        ...state,
        listCommune: []
      };
    default:
      return state;
  }
};
