import React, { Suspense } from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import close_modals from "../../../assets/images/ic/close_modals.svg";
import IntlMessages from "../../../utils/IntlMessages";

class ModalForgetPassPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: "",
        }
    }
    handleCloseModal = () => {
        this.props.handleCloseForget(false);
    };
    handleHideAlert = () => {
        this.setState({
            alert: "",
        });
    };

    render() {
        const { toggleForgetPass, success } = this.props;
        const closeBtn = (
            <button
                className="close"
                onClick={this.handleCloseModal}
                style={{
                    opacity: 1,
                    width: "15px",
                    height: "15px",
                    paddingRight: "30px"
                }}
            >
                <img src={close_modals} alt="" />
            </button>
        );

        return (
            <React.Fragment>

                <Modal
                    className="modalForgetPass"
                    isOpen={toggleForgetPass}
                    size="md"
                    centered
                >
                    <ModalHeader
                        close={closeBtn}
                    >
                        <IntlMessages id="user.Notification" />
                    </ModalHeader>

                    <ModalBody>
                        {success ? (
                            <React.Fragment>
                                <IntlMessages id="auth.forgotPass.checkMail" />
                            </React.Fragment>
                        ) : ""
                        }
                    </ModalBody>

                </Modal>
            </React.Fragment >
        );
    }
}


const MyComponent = (ModalForgetPassPage);
export default function ModalForgetPass(props) {
    return (
        <Suspense fallback="loading">
            <MyComponent {...props} />
        </Suspense>
    );
}