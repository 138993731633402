export const basicStatisticCriteria = [
    {
        id: 'SO_DOANH_NGHIEP',
        text: 'Số doanh nghiệp',
        enText: 'Number of enterprise',
        key: 'SO_DOANH_NGHIEP',
        isChecked: false
    },
    {
        id: 'SO_LAO_DONG',
        text: 'Số lao động',
        enText: 'Number of labor',
        key: 'SO_LAO_DONG',
        isChecked: false
    },
    {
        id: 'NGUON_VON_BINH_QUAN',
        text: 'Nguồn vốn bình quân',
        enText: 'Average capital',
        key: 'NGUON_VON_BINH_QUAN',
        isChecked: false
    },
    {
        id: 'TAI_SAN_CO_DINH_VA_DAU_TU_DAI_HAN_BINH_QUAN',
        text: 'Tài sản cố định và đầu tư dài hạn bình quân',
        enText: 'Average of fixed assets and long-term investment',
        key: 'TAI_SAN_CO_DINH_VA_DAU_TU_DAI_HAN_BINH_QUAN',
        isChecked: false
    },
    {
        id: 'DOANH_THU_THUAN',
        text: 'Doanh thu thuần',
        enText: 'Net revenue',
        key: 'DOANH_THU_THUAN',
        isChecked: false
    },
    {
        id: 'LOI_NHUAN_TRUOC_THUE',
        text: 'Lợi nhuận trước thuế',
        enText: 'Profit before tax',
        key: 'LOI_NHUAN_TRUOC_THUE',
        isChecked: false
    },
    {
        id: 'THUE_VA_CAC_KHOAN_NOP_NGAN_SACH',
        text: 'Thuế và các khoản đã nộp ngân sách',
        enText: 'Taxes and amounts already paid to the state budget',
        key: 'THUE_VA_CAC_KHOAN_NOP_NGAN_SACH',
        isChecked: false
    }
];

export const advanceStatisticCriteria = [
    {
        id: 'SAN_XUAT',
        text: 'Sản xuất',
        enText: 'Production',
        key: 'SAN_XUAT',
        isChecked: false
    },
    {
        id: 'TIEU_THU',
        text: 'Tiêu thụ',
        enText: 'Consumption',
        key: 'TIEU_THU',
        isChecked: false
    },
    {
        id: 'TON_KHO',
        text: 'Tồn kho',
        enText: 'Inventory',
        key: 'TON_KHO',
        isChecked: false
    }
];