import * as Types from "../actions";

export const actSearchCompanyInfo = (model, dataChart) => ({
  type: Types.GET_LIST_COMPANYINFO,
  dataChart,
  payload: { model }
});
export const actSearchCompanyAutocomplete = (model) => ({
  type: Types.GET_LIST_COMPANY_AUTOCOMPLETE,
  payload: { model }
});
export const actSearchCompanyAutocompleteSuccess = (dataAutoComplete, pageIndex) => {
  return {
    type: Types.GET_LIST_COMPANY_AUTOCOMPLETE_SUCCESS,
    dataAutoComplete,
    pageIndex
  };
};
export const getSearchCompanyInfoSuccess = (companyData, totalItems, totalPage, pageIndex, pageCount, dataChart) => {
  return {
    type: Types.GET_LIST_COMPANYINFO_SUCCESS,
    companyData,
    totalItems,
    totalPage,
    pageIndex,
    pageCount,
    dataChart
  };
};
export const getSearchCompanyInfoFail = (message, isError) => {
  return {
    type: Types.GET_LIST_COMPANYINFO_FAIL,
    message,
    isError
  };
};

// Data Doanh thu top doanh nghiep truy cap
export const actGetTopRevenua = () => {
  return {
    type: Types.GET_TOP_REVENUA
  };
};
export const actGetTopRevenuaSuccess = (data) => {
  return {
    type: Types.GET_TOP_REVENUA_SUCCESS,
    data
  };
};

//get info detail
export const actGetCompanyDetail = (id, typex) => {
  return {
    type: Types.GET_COMPANY_DETAIL,
    id,
    typex
  };
};
export const actGetCompanyDetailSuccess = (detail) => {
  return {
    type: Types.GET_COMPANY_DETAIL_SUCCESS,
    detail
  };
};
export const actGetCompanyDetailFail = (message, isError) => {
  return {
    type: Types.GET_COMPANY_DETAIL_FAIL,
    message,
    isError
  };
};
export const actGetFinancialInitSuccess = (detail) => {
  return {
    type: Types.GET_FINANCIAL_INIT_SUCCESS,
    detail
  };
};
export const actResetFinancial = () => {
  return {
    type: Types.RESET_FINANCIAL
  };
};
export const actGetTargetInitSuccess = (detail) => {
  return {
    type: Types.GET_TARGET_INIT_SUCCESS,
    detail
  };
};
export const actRequestTargetINITSuccess = (detail) => {
  return {
    type: Types.REQUEST_TARGET_INIT_SUCCESS,
    detail
  };
};
//get Year bao cao tai chinh

export const actGetYearFinancialReport = (id) => {
  return {
    type: Types.GET_REPORT_YEAR,
    id
  };
};
export const actGetYearFinancialSuccess = (listYear) => {
  return {
    type: Types.GET_REPORT_YEAR_SUCCESS,
    listYear
  };
};
export const actGetYearFinancialFail = (message, isError) => {
  return {
    type: Types.GET_REPORT_YEAR_FAIL,
    message,
    isError
  };
};
//Báo cáo tài chính hợp nhất (công ty đã lên sàn)
export const actGetYearFinancialReportDownload = (id) => {
  return {
    type: Types.GET_REPORT_DOWNLOAD,
    id
  };
};
export const actGetYearFinancialReportDownloadSuccess = (listYearDown) => {
  return {
    type: Types.GET_REPORT_DOWNLOAD_SUCCESS,
    listYearDown
  };
};
export const actGetYearFinancialReportDownloadFail = (message, isError) => {
  return {
    type: Types.GET_REPORT_DOWNLOAD_FAIL,
    message,
    isError
  };
};
//Đánh giá tăng trưởng cở bản
export const actGetGrowthrateCODECheckbox = (id) => {
  return {
    type: Types.CODE_CHECKBOX_GROWTHRATE,
    payload: {
      id
    }
  };
};
export const actGetChartGrowthRate = (id) => {
  return {
    type: Types.GET_GROWTHRATE_CHART,
    id
  };
};
export const actGetChartGrowthRateSuccess = (dataChart) => {
  return {
    type: Types.GET_GROWTHRATE_CHART_SUCCESS,
    dataChart
  };
};
export const actGetChartGrowthRateFail = (message, isError) => {
  return {
    type: Types.GET_GROWTHRATE_CHART_FAIL,
    message,
    isError
  };
};
export const actGetChartByType = (model) => {
  return {
    type: Types.GET_CHART_BY_TYPE,
    model
  };
};
export const actGetChartByTypeReset = (model) => {
  return {
    type: Types.GET_CHART_BY_TYPE_RESET,
    model
  };
};
//chủ sở hữu
export const actGetOwnerLaw = (id) => {
  return {
    type: Types.GET_OWNER_LAW,
    id
  };
};
export const actGetOwnerLawSuccess = (data) => {
  return {
    type: Types.GET_OWNER_LAW_SUCCESS,
    data
  };
};
export const actGetOwnerLawDetail = (id) => {
  return {
    type: Types.GET_OWNER_LAW_DETAIL,
    id
  };
};
// end-law
export const actGetOwnerStructor = (id) => {
  return {
    type: Types.GET_OWNER_STRUCTOR,
    id
  };
};
export const actGetOwnerStructorSuccess = (data) => {
  return {
    type: Types.GET_OWNER_STRUCTOR_SUCCESS,
    data
  };
};
export const actGetOwnerStructorDetail = (model) => {
  return {
    type: Types.GET_OWNER_STRUCTOR_DETAIL,
    model
  };
};
// end-structor
export const actGetCompanyRef = (id) => {
  return {
    type: Types.GET_OWNER_COMPANYREF,
    id
  };
};
export const actGetCompanyRefSuccess = (data) => {
  return {
    type: Types.GET_OWNER_COMPANYREF_SUCCESS,
    data
  };
};
// end-structor
// heso- financial DELETE
export const actRequestFinancialDeleteChart = (id) => {
  return {
    type: Types.REQUEST_FINANCIAL_DELETE_CHART,
    id
  };
};
// heso- financial
export const actRequestFinancial = (body) => {
  return {
    type: Types.REQUEST_FINANCIAL,
    body
  };
};
export const actRequestFinancialReset = (body) => {
  return {
    type: Types.REQUEST_FINANCIAL_RESET,
    body
  };
};
export const actGetFinancialCODECheckbox = (id) => {
  return {
    type: Types.CODE_CHECKBOX_FINANCIAL,
    payload: {
      id
    }
  };
};

//So sanh trung binh nganh

export const actRequestDataToCompare = (model) => {
  return {
    type: Types.REQUEST_DATA_TO_COMPARE,
    payload: {
      model
    }
  };
};

export const getDataToShowSuccess = (modelx) => {
  return {
    type: Types.REQUEST_DATA_TO_COMPARE_SUCCESS,
    payload: {
      modelx
    }
  };
};
export const getDataFail = (message) => {
  return {
    type: Types.REQUEST_DATA_TO_COMPARE_FAIL,
    payload: {
      message
    }
  };
};
export const actRequestLiveChartCompareGroupReset = () => {
  return {
    type: Types.REQUEST_LIVE_CHART_GROUP_RESET
  };
};
export const actDeleleChartCompareAverage = (key) => {
  return {
    type: Types.DELETE_CHART_COMPARE_AVERAGE,
    payload: {
      key
    }
  };
};

export const actGetDataNetProfitColumnChart = (id) => {
  return {
    type: Types.GET_DATA_NET_PROFIT_COLUMN_CHART,
    payload: {
      id
    }
  };
};

export const getDataNetProfitColumnChartSuccess = (dataPoints) => {
  return {
    type: Types.GET_DATA_NET_PROFIT_COLUMN_CHART_SUCCESS,
    payload: {
      dataPoints
    }
  };
};

export const actMenuGetDataCompare = (id) => {
  return {
    type: Types.GET_MENU_DATA_TO_COMPARE,
    payload: {
      id
    }
  };
};

export const getMenuDataCompareSuccess = (model) => {
  return {
    type: Types.GET_MENU_DATA_TO_COMPARE_SUCCESS,
    payload: {
      model
    }
  };
};

//----------------------------------------
//chitieu Chart DELETE
export const actRequestTargetDelete = (id) => {
  return {
    type: Types.REQUEST_TARGET_DELETE,
    id
  };
};
// chiteu- target
export const actRequestTarget = (body) => {
  return {
    type: Types.REQUEST_TARGET,
    body
  };
};
export const actRequestTargetReset = () => {
  return {
    type: Types.REQUEST_TARGET_RESET
  };
};

export const actRequestTargetINIT = (id, set, roleName) => {
  return {
    type: Types.REQUEST_TARGET_INIT,
    payload: {
      id,
      set,
      roleName
    }
  };
};

export const actRequestFinancialINIT = (id, set, roleName) => {
  return {
    type: Types.REQUEST_FINANCIAL_INIT,
    payload: {
      id,
      set,
      roleName
    }
  };
};

export const actRequestFinancialINITSuccess = (detail) => {
  return {
    type: Types.REQUEST_FINANCIAL_INIT_SUCCESS,
    detail
  };
};

export const actDownLoadReport = (id, year) => {
  return {
    type: Types.DOWNLOAD_REPORT,
    payload: {
      id,
      year
    }
  };
};

export const actGetTargetCODECheckbox = (id, type) => {
  return {
    type: Types.CODE_CHECKBOX_TARGET,
    payload: {
      id,
      type
    }
  };
};
//so sanh - doanh nghiep khac
export const actCompanyIndustry = (model) => ({
  type: Types.REQUEST_COMPANY_INDUSTRY,
  model
});

export const actCompanyIndustrySuccess = (companyDNOrther, totalItems, totalPage, pageIndex) => {
  return {
    type: Types.REQUEST_COMPANY_INDUSTRY_SUCCESS,
    companyDNOrther,
    totalItems,
    totalPage,
    pageIndex
  };
};

export const actCompanyIndustryFail = (message, isError) => {
  return {
    type: Types.REQUEST_COMPANY_INDUSTRY_FAIL,
    message,
    isError
  };
};
//ss truc tiep
export const actRequestLiveChartDNK = (body) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK,
    body
  };
};
//ss trung binh
export const actRequestLiveChartDNKTB = (body) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_TB,
    body
  };
};
//ss Tong gia tri
export const actRequestLiveChartDNKTGT = (body) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_TGT,
    body
  };
};

//ss trung binh Group
export const actRequestLiveChartDNKTBGroup = (body) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP,
    body
  };
};
//ss Tong gia tri Group
export const actRequestLiveChartDNKTGTGroup = (body) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_TGT_GROUP,
    body
  };
};
export const actRequestLiveChartDNKDeleteGroup = (id) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_DELETE_GROUP,
    id
  };
};
export const actRequestLiveChartDNKResetGroup = (id) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_RESET_GROUP,
    id
  };
};

export const actRequestLiveChartDNKDelete = (id) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_DELETE,
    id
  };
};
export const actRequestLiveChartDNKReset = (id) => {
  return {
    type: Types.REQUEST_LIVE_CHARTDNK_RESET,
    id
  };
};

export const actCompanyIndustryBK = (model) => ({
  type: Types.REQUEST_COMPANY_INDUSTRYBK,
  model
});

export const actCompanyIndustryBKSuccess = (companyDNOrtherBK, totalItems, totalPage, pageIndex) => {
  return {
    type: Types.REQUEST_COMPANY_INDUSTRYBK_SUCCESS,
    companyDNOrtherBK,
    totalItems,
    totalPage,
    pageIndex
  };
};

export const actCompanyIndustryBKFail = (message, isError) => {
  return {
    type: Types.REQUEST_COMPANY_INDUSTRYBK_FAIL,
    message,
    isError
  };
};

// CompanyIndustryDNBK

export const actRequestCompanyIndustryDNBK = (model) => ({
  type: Types.REQUEST_COMPANY_INDUSTRYDNBK,
  model
});

export const actCompanyIndustryDNBKSuccess = (companyDNOrtherBK, totalItems, totalPage, pageIndex) => {
  return {
    type: Types.REQUEST_COMPANY_INDUSTRYDNBK_SUCCESS,
    companyDNOrtherBK,
    totalItems,
    totalPage,
    pageIndex
  };
};

export const actCompanyIndustryDNBKFail = (message, isError) => {
  return {
    type: Types.REQUEST_COMPANY_INDUSTRYDNBK_FAIL,
    message,
    isError
  };
};

// download company report
export const actDownloadCompanyReport = (id) => {
  return {
    type: Types.DOWNLOAD_COMPANY_REPORT,
    id
  };
};
export const actDownloadCompanyReportSuccess = (data) => {
  return {
    type: Types.DOWNLOAD_COMPANY_REPORT_SUCCESS,
    data
  };
};
export const actDownloadCompanyReportFail = (message) => {
  return {
    type: Types.DOWNLOAD_COMPANY_REPORT_FAIL,
    message
  };
};
export const actGetCompanyFinancialInfo = (body) => {
  return {
    type: Types.GET_COMPANY_FINANCIAL_INFO,
    body
  };
};
export const actGetCompanyFinancialInfoSuccess = (body) => {
  return {
    type: Types.GET_COMPANY_FINANCIAL_INFO_SUCCESS,
    body
  };
};
export const actGetCompanyFinancialInfoFail = (body) => {
  return {
    type: Types.GET_COMPANY_FINANCIAL_INFO_FAIL,
    body
  };
};

// Báo cáo doanh nghiệp + báo cáo ngành
export const actGetDataRelatedReportInCompanyDetail = (id) => {
  return {
    type: Types.GET_DATA_RELATED_REPORT_IN_COMPANY,
    id
  };
};
export const actGetDataRelatedReportInCompanyDetailSuccess = (body) => {
  return {
    type: Types.GET_DATA_RELATED_REPORT_IN_COMPANY_SUCCESS,
    body
  };
};
export const actGetDataRelatedReportInCompanyDetailFail = (body) => {
  return {
    type: Types.GET_DATA_RELATED_REPORT_IN_COMPANY_FAIL,
    body
  };
};

// advance search
export const actAdvanceSearchCompanyByArea = (body) => {
  return {
    type: Types.ADVANCE_SEARCH_COMPANY_BY_AREA,
    payload: body
  };
};

export const actAdvanceSearchCompanyByIndustry = (body) => {
  return {
    type: Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY,
    payload: body
  };
};

export const actAdvanceSearchCompanyByFinance = (body) => {
  return {
    type: Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE,
    payload: body
  };
};

export const actGetFinancialCriteriaList = () => {
  return {
    type: Types.GET_FINANCIAL_CRITERIA_LIST
  };
};

export const actGetDataPointList = () => {
  return {
    type: Types.GET_DATA_POINT_LIST
  };
};
