import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actSearchCompanyInfo } from "../../../redux/actions";
import getUrlParam from "../../../utils/getUrlParam";
import { callApiServer } from "../../../utils/callApiServer";
import queryString from "query-string";
import { Dropdown, Menu } from "semantic-ui-react";
import LoaderTable from "../../../components/LoaderTable";
import ParetoChartTabRatio from "../../../components/CompanyInfo/Chart/ParetoChart_TabRatio";
import LinechartTabRatio from "../../../components/CompanyInfo/Chart/Linechart_TabRatio";
import NoData from "../../../components/NoData";

import IntlMessages from "../../../utils/IntlMessages";
import { _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";

class TabRelatedRatio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "", // từ khóa tìm kiếm,
      vsicList: [], // Danh sách mã Vsic
      selectedVsic: "",
      data_GDP: [],
      data_Paddy: [],
      title_Paddy: "",
      enTitle_Paddy: "",
      data_INDUSTRIAL_PRODUCTION: [],
      data_TOTAL_SALES: [],
      data_TRANSPORTATION: [],
      data_INVESMENT: [],
      isPageLoading: false,
      alert: ""
    };
  }

  componentDidMount() {
    this.getVsicList();
  }

  async getVsicList() {
    const { txtsearch } = this.state;
    const lang = localStorage.getItem("currentLanguage");
    this.setState({
      isPageLoading: true
    });
    try {
      const response = await callApiServer.get(`/IFReportStatistic/GetDropDownVsic?searchText=${txtsearch}&lang=${lang}`);
      if (response && response.data && response.data.data) {
        const listVsic = [];
        const data = response.data.data;
        data.map((item) => {
          listVsic.push({
            key: item.code,
            text: `${item.code}`,
            value: item.code,
            label: this.renderLabel(item),
            description: `${item.code} ${item.name} ${item.enName}`
          });
        });
        this.setState({
          vsicList: listVsic,
          isPageLoading: false
        });
        this.handleChangeVsic(listVsic[0].value);
      } else
        this.setState({
          vsicList: [],
          isPageLoading: false
        });
    } catch (error) {
      this.setState({
        vsicList: [],
        isPageLoading: false
      });
    }
  }
  renderLabel = (item) => {
    const language = localStorage.getItem("currentLanguage");

    return (
      <div className="container-lalel-item">
        <div className="hyphen"> - </div>
        <div className="container-lalel">
          <div className="main-label">{language === "en" ? item.enName : item.name}</div>
          <div className="sub-label">{language === "en" ? item.name : item.enName}</div>
        </div>
      </div>
    );
  };

  handleSearchListVsic = (options, data) => {
    if (data) {
      return options.filter((item) => item.description.toLowerCase().includes(data.toLowerCase()));
    }
  };
  // chọn ngành (vsic code)
  async handleChangeVsic(value) {
    const { txtsearch } = this.state;
    const lang = localStorage.getItem("currentLanguage");
    this.setState({
      selectedVsic: value,
      isPageLoading: true
    });
    try {
      const response = await callApiServer.get(`/IFReportStatistic/GetRelevantChartByVsic?vsicCode=${value}`);
      if (response && response.data && response.data.data) {
        let data = response.data.data;
        let data_GDP = [];
        let data_Paddy = [];
        let title_Paddy = "";
        let enTitle_Paddy = "";

        let data_INDUSTRIAL_PRODUCTION = [];
        let data_TOTAL_SALES = [];
        let data_TRANSPORTATION = [];
        let data_INVESMENT = [];

        data.map((item) => {
          switch (item.chartNumber) {
            case "One":
              data_GDP = [...item.chartModel];

              break;
            case "Two":
              data_Paddy = [...item.chartModel];
              title_Paddy = item.title;
              enTitle_Paddy = item.enTitle;
              break;
            case "Three":
              data_INDUSTRIAL_PRODUCTION = [...item.chartModel];
              break;
            case "Four":
              data_TOTAL_SALES = [...item.chartModel];
              break;
            case "Five":
              data_TRANSPORTATION = [...item.chartModel];
              break;
            case "Six":
              data_INVESMENT = [...item.chartModel];
              break;
            default:
              break;
          }
        });
        this.setState({
          data_GDP,
          data_Paddy,
          title_Paddy,
          enTitle_Paddy,
          data_INDUSTRIAL_PRODUCTION,
          data_TOTAL_SALES,
          data_TRANSPORTATION,
          data_INVESMENT,
          isPageLoading: false
        });
      } else
        this.setState({
          data_GDP: [],
          data_Paddy: [],
          title_Paddy: "",
          enTitle_Paddy: "",
          data_INDUSTRIAL_PRODUCTION: [],
          data_TOTAL_SALES: [],
          data_TRANSPORTATION: [],
          data_INVESMENT: [],
          isPageLoading: false
        });
    } catch (error) {
      this.setState({
        data_GDP: [],
        data_Paddy: [],
        data_INDUSTRIAL_PRODUCTION: [],
        data_TOTAL_SALES: [],
        data_TRANSPORTATION: [],
        data_INVESMENT: [],
        isPageLoading: false
      });
    }
  }
  returnDataChart = (data, type) => {
    let dataItem = data.filter((item) => item.chartType === type)[0];
    // let dataItemFormat;
    // if (dataItem) {
    //   dataItemFormat = {
    //     chartType: dataItem.chartType,
    //     dataChart: dataItem.dataChart
    //       ? dataItem.dataChart.map((e, index) => {
    //           return {
    //             label: e.label,
    //             y: e.y
    //           };
    //         })
    //       : []
    //   };
    // }

    return dataItem;
  };

  render() {
    const lang = localStorage.getItem("currentLanguage");

    const { messages } = this.props.intl;
    const roles = this.props.userAccount.roleClient;
    const {
      vsicList,
      isPageLoading,
      selectedVsic,
      data_GDP,
      data_Paddy,
      title_Paddy,
      enTitle_Paddy,
      data_INDUSTRIAL_PRODUCTION,
      data_TOTAL_SALES,
      data_TRANSPORTATION,
      data_INVESMENT
    } = this.state;
    return (
      <div>
        <div className="statistic-data-table selected-custom-select card-item mb-4">
          {this.state.alert}
          {isPageLoading ? (
            <LoaderTable isLoaded={!isPageLoading} styleCustom={{ height: "500px" }} />
          ) : (
            <div className="row m-0 d-flex">
              <div className="col-lg-5 col-md-12 pl-lg-0 mb-4 mb-lg-0">
                <Menu compact className="w-100 mr-3 dropdown-vsic-list dropdown-custom-basic-statistic">
                  <Dropdown
                    placeholder={messages["sidebar.Industry"]}
                    fluid
                    search={this.handleSearchListVsic}
                    selection
                    options={vsicList}
                    noResultsMessage={messages["search.notfound.text"]}
                    value={selectedVsic}
                    onChange={(e, { value }) => this.handleChangeVsic(value)}
                  />
                </Menu>
              </div>
            </div>
          )}
        </div>

        {data_GDP.length ||
        data_Paddy.length ||
        data_INDUSTRIAL_PRODUCTION.length ||
        data_TOTAL_SALES.length ||
        data_TRANSPORTATION.length ||
        data_INVESMENT.length ? (
          <div>
            <div className="row equal-height mb-2">
              {data_GDP && data_GDP.length !== 0 && (
                <div className="col-xl-12 pb-4">
                  <div className="card-item">
                    <div className="card-title card-title-line">
                      {" "}
                      <IntlMessages id="industry.statistic.tabRatio.GDP" />
                    </div>
                    <div className="chart-info chart-multi">
                      <ParetoChartTabRatio
                        ySuffix="tỉ đồng"
                        xType="label"
                        roles={roles}
                        dataColumn_1={this.returnDataChart(data_GDP, "column_1")}
                        dataColumn_2={this.returnDataChart(data_GDP, "column_2")}
                        dataColumn_3={this.returnDataChart(data_GDP, "column_3")}
                        backgroundColor={"#02164d"}
                        tickColor={"rgba(144, 155, 175, 0.3)"}
                        dataChartDefault={data_GDP}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row equal-height mb-2">
              {data_Paddy && data_Paddy.length !== 0 && (
                <div className="col-xl-12 pb-4">
                  <div className="card-item">
                    <div className="card-title card-title-line">{lang === "vi" ? title_Paddy : enTitle_Paddy}</div>
                    <div className="chart-info chart-multi">
                      <ParetoChartTabRatio
                        xType="label"
                        roles={roles}
                        dataColumn_1={this.returnDataChart(data_Paddy, "column_1")}
                        dataColumn_2={this.returnDataChart(data_Paddy, "column_2")}
                        dataColumn_3={this.returnDataChart(data_Paddy, "column_3")}
                        dataColumn_4={this.returnDataChart(data_Paddy, "column_4")}
                        dataColumn_5={this.returnDataChart(data_Paddy, "column_5")}
                        dataColumn_6={this.returnDataChart(data_Paddy, "column_6")}
                        dataLine_1={this.returnDataChart(data_Paddy, "line_1")}
                        dataLine_2={this.returnDataChart(data_Paddy, "line_2")}
                        backgroundColor={"#02164d"}
                        tickColor={"rgba(144, 155, 175, 0.3)"}
                        dataChartDefault={data_Paddy}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row equal-height mb-2">
              {data_INDUSTRIAL_PRODUCTION && data_INDUSTRIAL_PRODUCTION.length !== 0 && (
                <div className="col-xl-12 pb-4">
                  <div className="card-item">
                    <div className="card-title card-title-line">
                      <IntlMessages id="industry.statistic.tabRatio.INDUSTRIAL_PRODUCTION" />
                    </div>
                    <div className="chart-info chart-multi">
                      <LinechartTabRatio
                        title={""}
                        xType="label"
                        labelEnglish={true}
                        suf={true}
                        percent={true}
                        dataChart={data_INDUSTRIAL_PRODUCTION}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row equal-height mb-2">
              {data_TOTAL_SALES && data_TOTAL_SALES.length !== 0 && (
                <div className="col-xl-12 pb-4">
                  <div className="card-item">
                    <div className="card-title card-title-line">
                      <IntlMessages id="industry.statistic.tabRatio.TOTAL_SALES" />
                    </div>
                    <div className="chart-info chart-multi">
                      <ParetoChartTabRatio
                        xType="label"
                        roles={roles}
                        dataColumn_1={this.returnDataChart(data_TOTAL_SALES, "column_1")}
                        dataColumn_2={this.returnDataChart(data_TOTAL_SALES, "column_2")}
                        dataColumn_3={this.returnDataChart(data_TOTAL_SALES, "column_3")}
                        dataLine_1={this.returnDataChart(data_TOTAL_SALES, "line_1")}
                        backgroundColor={"#02164d"}
                        tickColor={"rgba(144, 155, 175, 0.3)"}
                        dataChartDefault={data_TOTAL_SALES}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row equal-height mb-2">
              {data_TRANSPORTATION && data_TRANSPORTATION.length !== 0 && (
                <div className="col-xl-12 pb-4">
                  <div className="card-item">
                    <div className="card-title card-title-line">
                      <IntlMessages id="industry.statistic.tabRatio.TRANSPORTATION" />
                    </div>
                    <div className="chart-info chart-multi">
                      <ParetoChartTabRatio
                        xType="label"
                        roles={roles}
                        dataColumn_1={this.returnDataChart(data_TRANSPORTATION, "column_1")}
                        dataColumn_2={this.returnDataChart(data_TRANSPORTATION, "column_2")}
                        dataColumn_3={this.returnDataChart(data_TRANSPORTATION, "column_3")}
                        backgroundColor={"#02164d"}
                        tickColor={"rgba(144, 155, 175, 0.3)"}
                        dataChartDefault={data_TRANSPORTATION}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row equal-height mb-2">
              {data_INVESMENT && data_INVESMENT.length !== 0 && (
                <div className="col-xl-12 pb-4">
                  <div className="card-item">
                    <div className="card-title card-title-line">
                      <IntlMessages id="industry.statistic.tabRatio.INVESMENT" />
                    </div>
                    <div className="chart-info chart-multi">
                      <ParetoChartTabRatio
                        xType="label"
                        roles={roles}
                        dataColumn_1={this.returnDataChart(data_INVESMENT, "column_1")}
                        dataColumn_2={this.returnDataChart(data_INVESMENT, "column_2")}
                        dataColumn_3={this.returnDataChart(data_INVESMENT, "column_3")}
                        dataLine_1={this.returnDataChart(data_INVESMENT, "line_1")}
                        backgroundColor={"#02164d"}
                        tickColor={"rgba(144, 155, 175, 0.3)"}
                        dataChartDefault={data_INVESMENT}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccount: state.accountReducer.userAccount,

    companyReducer: state.companyReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fake
    actSearchCompany: (body) => {
      dispatch(actSearchCompanyInfo(body));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabRelatedRatio));
