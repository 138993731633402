import * as Types from "../actions";
var initialState = {
  isLogin: "",
  token: "",
  loading: false,
  message: "",
  dataForgotPass: {
    message: null,
    loading: false,
    email: null,
  },
  confirmToken: {
    message: null,
    loading: false,
    success: false,
  },
  checkLoggedDevice: {
    loading: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return { ...state, loading: true, message: "" };
    case Types.LOGIN_SUCCESS:
      state = {
        ...state,
        isLogin: action.isLogin,
        token: action.token,
        loading: false,
      };
      return state;
    case Types.LOGIN_FAIL:
      state = {
        ...state,
        loading: false,
        isLogin: false,
        message: action.message,
      };
      return state;
    case Types.LOGOUT:
      state = {
        ...state,
        isLogin: null,
        loading: false,
        token: "",
        message: "",
      };
      return state;

    case Types.HANDLE_FORGOT_PASSWORD:
      return {
        ...state,
        dataForgotPass: {
          ...state.dataForgotPass,
          loading: true,
          success: false,
          message: null,
          email: null,
        },
      };
    case Types.HANDLE_FORGOT_PASSWORD_SUCCESS:
      var { email } = action.payload;
      return {
        ...state,
        dataForgotPass: {
          ...state.dataForgotPass,
          loading: false,
          success: true,
          message: null,
          email: email,
        },
      };

    case Types.HANDLE_FORGOT_PASSWORD_FAIL:
      var message = action.payload.messErr;
      return {
        ...state,
        dataForgotPass: {
          ...state.dataForgotPass,
          loading: false,
          success: false,
          message: message,
          email: null,
        },
      };
    //Confirm token
    case Types.HANDLE_CHANGE_PASSWORD:
      return {
        ...state,
        confirmToken: {
          ...state.confirmToken,
          message: null,
          loading: true,
          success: false,
        },
      };
    case Types.HANDLE_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        confirmToken: {
          ...state.confirmToken,
          message: null,
          loading: false,
          success: true,
        },
      };
    case Types.HANDLE_CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        confirmToken: {
          ...state.confirmToken,
          message: null,
          loading: false,
          success: false,
        },
      };
    // checkLoggedDevice
    case Types.HANDLE_CHECKED_LOGOUT:
      return {
        ...state,
        checkLoggedDevice: {
          ...state.checkLoggedDevice,
          loading: false,
        },
      };
    case Types.HANDLE_CHECKED_LOGOUT_FAIL:
      return {
        ...state,
        checkLoggedDevice: {
          ...state.checkLoggedDevice,
          loading: true,
        },
      };
    case Types.HANDLE_CHECKED_LOGOUT_SUCCESS:
      return {
        ...state,
        checkLoggedDevice: {
          ...state.checkLoggedDevice,
          loading: true,
        },
      };
    default:
      return state;
  }
};
