import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import BASE_API_URL from "../../constants/hostSever";
import { totalPage } from "../../utils/function.util";
import { HistoryAccessed, notification } from "../../constants/pageSize";
import { actNotify } from "./../Fetch/actions";
// USER ACCOUNT - Thông tin tài khoản
import {
  actGetUserAccountApi,
  actGetHistoryAccessedApi,
  actGetNotificationsApi,
  actChangePasswordApi,
  actDeleteNotificationsApi,
  actChangeStatusSeenMessageApi,
  actChangeLang,
  actGetCurrentPoint
} from "../../services/accountManagement.service";
const language = localStorage.getItem("currentLanguage");

function* watchGetUserAccount() {
  yield takeEvery(Types.GET_USER_ACCOUNT, getUserAccount);
}

function* getUserAccount() {
  try {
    var response = yield call(actGetUserAccountApi);
    if (response.data.data) {
      yield put(actions.getUserAccountSuccess(response.data.data));
      const { lang } = response.data.data;
      if (lang) {
        if (localStorage.getItem("currentLanguage") !== lang) {
          localStorage.setItem("currentLanguage", lang);
          yield put({
            type: Types.CHANGE_LOCALE,
            payload: lang
          });
        }
      }
    } else {
      yield put(actions.getUserAccountFail("err"));
    }
  } catch (err) {
    yield put(actions.getUserAccountFail(err.message));
  }
}

function* watchGetCurrentPointt() {
  yield takeEvery(Types.GET_CURRENT_POINT, getCurrentPoint);
}
function* getCurrentPoint() {
  try {
    var response = yield call(actGetCurrentPoint);
    if (response.data && response.data.data !== null) {
      yield put(actions.getGetCurrentPointSuccess(response.data.data));
    }
  } catch (err) {}
}

// HISTORY ACCESSED - Lịch sử truy cập

function* watchGetHistoryAccessed() {
  yield takeEvery(Types.GET_HISTORY_ACCESSED, getHistoryAccessed);
}

function* getHistoryAccessed(action) {
  try {
    var response = yield call(actGetHistoryAccessedApi, action.page);
    if (response.data.data) {
      var data = response.data.data.auditLogs;
      var totalItems = response.data.data.totalItem;
      var pageIndex = response.data.data.pageIndex;
      var pageCount = response.data.data.pageCount;
      var totalPages = totalPage(totalItems, HistoryAccessed);
      yield put(actions.getHistoryAccessedSuccess(data, totalItems, totalPages, pageIndex, pageCount));
    } else {
      yield put(actions.getHistoryAccessedFail("Fail"));
    }
  } catch (err) {
    yield put(actions.getHistoryAccessedFail(err.message));
  }
}

// NOTIFICATION - Thông báo

function* watchGetNotifications() {
  yield takeEvery(Types.GET_NOTIFICATIONS, getNotifications);
}

function* getNotifications(action) {
  var { page } = action.payload;

  try {
    var response = yield call(actGetNotificationsApi, page);
    if (response.data) {
      var { messages, totalItem, pageIndex, pageSize } = response.data.data;
      var totalPages = totalPage(totalItem, notification);
      yield put(actions.getNotificationsSuccess(messages, totalItem, totalPages, pageIndex, pageSize));
    } else {
      yield put(actions.getNotificationsFail(messages));
    }
  } catch (err) {
    yield put(actions.getNotificationsFail(err.message));
  }
}

function* watchDeleteNotification() {
  yield takeEvery(Types.DELETE_NOTIFICATION, deleteNotification);
}

function* deleteNotification(action) {
  try {
    var response = yield call(actDeleteNotificationsApi, action.payload.id);
    if (response.data) {
      var { message } = response.data;
      if (message === "Success") {
        action.payload.success(message);
      } else {
        action.payload.success(message);
      }
    }
  } catch (err) {
    action.payload.success(err.message);
  }
}

function* watchChangeStatusSeenMessage() {
  yield takeEvery(Types.CHANGE_STATUS_SEEN_MESSAGE, changeStatusSeenMessage);
}

function* changeStatusSeenMessage(action) {
  var { id, success } = action.payload;
  try {
    var response = yield call(actChangeStatusSeenMessageApi, id);
    if (response.data) {
      var { message } = response.data;
      if (message === "Success") {
        success(message);
      } else {
        success(message);
      }
    }
  } catch (err) {
    success(err.message);
  }
}

// CHANGE PASSWORD - Thay đổi mật khẩu

function* watchChangePassword() {
  yield takeEvery(Types.CHANGE_PASSWORD, changePasswordRequest);
}

function* changePasswordRequest(action) {
  const model = {
    oldPassword: action.payload.oldPass,
    newPassword: action.payload.newPass
  };
  try {
    var response = yield call(actChangePasswordApi, model);
    if (response.data) {
      var { message } = response.data;
      yield put(actions.changePasswordMessage(message));
    } else {
      yield put(actions.changePasswordMessage(message));
    }
  } catch (err) {
    yield put(actions.changePasswordMessage(err));
  }
}
//  Change LANG

function* watchGetLangRequested() {
  yield takeEvery(Types.GET_LANG_REQUESTED, requestedLANG);
}

function* requestedLANG(action) {
  var { lang } = action.payload;
  var body = {
    lang
  };
  try {
    var response = yield call(actChangeLang, body);
    if (response.data.data) {
      yield put({
        type: Types.GET_LANG_REQUESTED_SUCCESS,
        data: response.data.data.lang
      });
      yield put({
        type: Types.GET_USER_ACCOUNT
      });
    } else {
      yield put({
        type: Types.GET_LANG_REQUESTED_FAIL
      });
      yield put({
        type: Types.GET_USER_ACCOUNT
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_LANG_REQUESTED_FAIL
    });
    yield put({
      type: Types.GET_USER_ACCOUNT
    });
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchGetUserAccount),
    fork(watchGetCurrentPointt),
    fork(watchGetNotifications),
    fork(watchDeleteNotification),
    fork(watchChangeStatusSeenMessage),
    fork(watchChangePassword),
    fork(watchGetLangRequested),
    fork(watchGetHistoryAccessed)
  ]);
}
