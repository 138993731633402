import React, { Component } from "react";
import { Link } from "react-router-dom";
import { subPhone } from "../../../utils/func.util";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { Popup } from "semantic-ui-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { formatMoney } from "../../../utils/formatMoney";

import { _Standard_, _Basic_ } from "../../../constants/rolesModel";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart.js";
import AutoReportTemplate from "../../../views/CompanyInfoPage/Tabs/AutoReportTemplate";
import { connect } from "react-redux";
import { actAddToCart } from "../../../redux/actions";
import BASE_API_URL from "../../../constants/hostSever";
import ConfirmPay from "../../../views/AccountsManagement/ConfirmPayComponent";
import { callApiServer } from "../../../utils/callApiServer.js";
import { saveAs as importedSaveAs } from "file-saver";
import { Spinner } from "reactstrap";

import moment from "moment";

export class CompanyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
      alert: "",

      popupConfirmBuy: "",
      isDownloadingReportExcel: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      handleFlyBuyCart();
    }, 500);
  }

  renderWebsite = (param) => {
    if (param) {
      return param.replace("https://", "");
    }
    return "";
  };

  replaceLocation() {
    if (this.props && this.props.address) {
      var location = this.props.address;
      location = location.split(" ").join("+");
      return `https://www.google.com/maps/search/?api=1&query=${location}`;
    } else {
      // todo
    }
  }

  toggleExpand = () => {
    this.setState({
      ...this.state,
      isExpand: !this.state.isExpand
    });
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart_AutoReport(data, this.props.id);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data)}>
          <span className={"cart-icon material-icons text-cyan"}>shopping_cart</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart_AutoReport = (data, idCompany) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    if (!carts) return false;

    if (carts.length > 0) {
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === idCompany && item.type === 5).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyReportAuto(data);
    } else {
      this.denyClick();
    }
  }
  // handleBuyReportAuto = (data) => {
  //   const { id, name, nameEnglish } = this.props;

  //   let model = {
  //     id: id,
  //     name: name,
  //     englishName: nameEnglish,
  //     requestData: nameEnglish,
  //     price: data.price,
  //     currency: "VND",
  //     viracServiceId: 5, // báo cáo doanh nghiệp tự động
  //     type: 5,
  //     lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  //   };
  //   this.props.addToCart(model);
  // };
  handleBuyReportAuto(data) {
    this.openPopupConfirmBuy(data.point, data.price, data.productType, data.oldPoint, data);
  }
  denyClick = () => {};

  handleDownloadReportAuto() {
    const { messages } = this.props.intl;
    this.setState({
      templateAutoReport: (
        <SweetAlert default hideOverlay={false} buttons={false} allowEscape={true} title={"Report Company"} customClass="auto-report-popup" showCancel={true}>
          <AutoReportTemplate closeTemplate={this.closeAutoReport} id={this.props.id}></AutoReportTemplate>
        </SweetAlert>
      )
    });
  }

  closeAutoReport = () => {
    this.setState({
      templateAutoReport: ""
    });
  };

  async handleDownloadReportExcel(id, name) {
    try {
      this.setState({
        isDownloadingReportExcel: true
      });
      const fileName = `Instant_Company_Report_${name}.xlsx`;
      const urlDownload = `/Company/export-excel-company-report?companyId=${id}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingReportExcel: false
        });
      } else {
        this.setState({
          isDownloadingReportExcel: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingReportExcel: false
      });
    }
  }

  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={this.props.id}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  renderTime(time) {
    if (time) return moment(time).format("DD/MM/YYYY");
    else return "";
  }

  render() {
    const props = this.props;

    const { roles, automateCompanyReportPdf, automateCompanyReportExcel, isDownloadingReportExcel } = this.props;
    const { messages } = this.props.intl;
    const { isEmailSpecial } = this.props.userAccount;

    const language = localStorage.getItem("currentLanguage");

    const itemNotPermisionToView = (textDisplay) => {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (
        <a href="#" onClick={() => this.actAlert(messages[`newWord.upgradeAccount`])}>
          {textDisplay}
        </a>
      );
    };
    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.templateAutoReport}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="col-lg-6 pb-4">
          <div className="result-item">
            <div className="toogle-icon-container" onClick={this.toggleExpand}>
              {/* <span className="material-icons up">{this.state.isExpand ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span> */}
              {props.isShowValueTooltip && (
                <Popup trigger={<span className="material-icons text-cyan tooltip-icon cursor-default">info</span>} className="custom-popup">
                  <Popup.Header>Giá trị trường điều kiện</Popup.Header>
                  <Popup.Content>
                    <ul className="pl-0 mb-0">
                      <li>- Giai đoạn dữ liệu: 2019</li>
                      <li>- Khu vực: P, Q, T</li>
                      <li>- Ngành: 4469</li>
                      <li>- Tài chính chỉ tiêu: 3.000.000.000</li>
                      <li>- Tài chính chỉ số: 2.000.000.000</li>
                      {/* <li>- <IntlMessages id="detailCompany.Keymarket" /></li> */}
                    </ul>
                  </Popup.Content>
                </Popup>
              )}
            </div>
            <div className="vietnamese-name">
              {roles && (roles === _Basic_ || roles === _Standard_) ? (
                itemNotPermisionToView(props.name ? props.name : "")
              ) : (
                <Link target="_blank" to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>
                  {props.name ? props.name : ""}
                </Link>
              )}
            </div>
            <div className="english-name">
              {roles && (roles === _Basic_ || roles === _Standard_) ? (
                itemNotPermisionToView(props.nameEnglish ? props.nameEnglish : "")
              ) : (
                <Link target="_blank" to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>
                  {props.nameEnglish ? props.nameEnglish : ""}
                </Link>
              )}
            </div>
            <div className="address has-icon">
              {" "}
              <span className="material-icons">place</span>
              {props.address ? props.address : ""}
            </div>
            {/* <div className="address has-icon">
            {" "}
            <span className="material-icons">place</span>
            {language === "en" && props.addressEnglish ? props.addressEnglish : props.address}
          </div> */}
            <div className="info-wrap">
              <ul className="info">
                <li className="has-icon">
                  <span className="material-icons">tag</span>
                  <IntlMessages id="page.MST" />: {props.taxNumber ? props.taxNumber : "N/A"}
                </li>
                <li className="has-icon">
                  <span className="material-icons">business_center</span>
                  VSIC: {props.vsicID ? " " + props.vsicID : "...."}
                </li>
                <li className="has-icon">
                  <span className="material-icons">date_range</span>
                  {props.registrationDate ? " " + this.renderTime(props.registrationDate) : "N/A"}
                </li>
              </ul>
              <a className="view-map" target="_blank" rel="noopener noreferrer" href={this.replaceLocation()}>
                {" "}
                <span className="text">
                  <IntlMessages id="viewMap" />
                </span>
                <span className="material-icons">map</span>
              </a>
            </div>
            {/* <Collapse isOpen={this.state.isExpand}> */}
            <div className="result-more-content">
              <ul className="info">
                <li className="has-icon">
                  <span className="material-icons">phone</span>
                  {props.phoneNumber ? subPhone(props.phoneNumber) : "N/A"}
                </li>
                <li className="has-icon">
                  <span className="material-icons">print</span>
                  {props.fax ? props.fax : "N/A"}
                </li>
                <li className="has-icon">
                  <span className="material-icons">public</span>
                  {props.webSite ? (
                    <a className="text-cyan" target="_blank" rel="noopener noreferrer" href={props.webSite ? "https://" + this.renderWebsite(props.webSite) : ""}>
                      {props.webSite}
                    </a>
                  ) : (
                    <span>N/A</span>
                  )}
                </li>
              </ul>
            </div>

            {isEmailSpecial === true && (
              <div className="revenue-box">
                <div className="revenue">
                  <span>{language === "vi" ? props.netRevenueLabel : props.enNetRevenueLabel}</span>
                  <span>{formatMoney(props.netRevenueValue, 0)}</span>
                </div>
                <div className="seperate">|</div>
                <div className="profit">
                  <span>{language === "vi" ? props.netProfitLabel : props.enNetProfitLabel}</span>
                  <span>{formatMoney(props.netProfitValue, 0)}</span>
                </div>
              </div>
            )}

            <div className="auto-report-box mt-3">
              <div className="col-3 auto-report-title-box">
                <IntlMessages id="industry.autodownloadReport" />:
                <Popup
                  trigger={
                    <span style={{ fontSize: "14px", color: "#b9cbe8" }} className="material-icons  tooltip-icon cursor-default">
                      info
                    </span>
                  }
                  className="custom-popup"
                >
                  <IntlMessages id="report.CompanyAuto.content" />{" "}
                </Popup>{" "}
              </div>
              <div className="col-9 auto-report-button-group">
                {/* báo cáo tự động PDF */}
                {automateCompanyReportPdf.isPurchased ? (
                  <div className="auto-report-btn-download">
                    <div className="download-report-auto text-cyan" onClick={() => this.handleDownloadReportAuto()}>
                      <em className="material-icons mr-1">download</em>
                      <IntlMessages id="industry.downloadReportPDF" />
                    </div>
                  </div>
                ) : (
                  <div className="auto-report-btn-download">
                    <div className="download-report-auto text-cyan" onClick={() => this.handleBuyReportAuto(automateCompanyReportPdf)}>
                      <em className="material-icons mr-1">shopping_cart</em>
                      <IntlMessages id="industry.reportPDF" />
                    </div>
                  </div>
                )}

                {/* báo cáo tự động Excel */}
                {isDownloadingReportExcel ? (
                  <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                ) : (
                  <div>
                    {automateCompanyReportExcel.isPurchased ? (
                      <div className="auto-report-btn-download">
                        <div className="download-report-auto text-cyan" onClick={() => this.handleDownloadReportExcel(props.id, props.nameEnglish ? props.nameEnglish : props.name ? props.name : "")}>
                          <em className="material-icons mr-1">download</em>
                          <IntlMessages id="industry.downloadReportExcel" />
                        </div>
                      </div>
                    ) : (
                      <div className="auto-report-btn-download">
                        <div className="download-report-auto text-cyan" onClick={() => this.handleBuyReportAuto(automateCompanyReportExcel)}>
                          <em className="material-icons mr-1">shopping_cart</em>
                          <IntlMessages id="industry.reportExcel" />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* File mẫu */}
                <div className="auto-report-btn-download">
                  <a href={BASE_API_URL + "Report/sampleReport?type=ACR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                    <em className="material-icons">download</em>
                    <IntlMessages id="report.template" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    userAccount: state.accountReducer.userAccount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyItem));

// export default injectIntl(CompanyItem);
