import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actSearchCompanyInfo } from "../../../redux/actions";
import IntlMessages from "../../../utils/IntlMessages";

class TabChapter extends Component {
  constructor(props) {
    super(props);
  }

  //định nghĩa giá trị trả về các ký tự la mã
  valuerRoman = (char) => {
    if (char === "I" || char === "i") return 1;
    if (char === "V" || char === "v") return 5;
    if (char === "X" || char === "x") return 10;
    if (char === "L" || char === "l") return 50;
    if (char === "C" || char === "c") return 100;
    if (char === "D" || char === "d") return 500;
    if (char === "M" || char === "m") return 1000;
    return -1;
  };
  //hàm chuyển đổi

  RomanToDec = (str) => {
    var sum = 0;
    //duyệt qua hết các ký tự
    for (var i = 0; i < str.length; i++) {
      //lấy ký tự đầu
      var s1 = this.valuerRoman(str[i]);
      if (i + 1 < str.length) {
        //lấy giá trị ký tự tiếp theo sau
        var s2 = this.valuerRoman(str[i + 1]);
        //so sánh 2 giá trị trả về
        if (s1 >= s2) {
          //giá trị sau nhỏ hơn giá trị trước
          sum += s1;
        } else {
          //giá trị hiện tại nhỏ hơn giá trị tiếp theo
          sum += s2 - s1;
          i++;
        }
      } else {
        sum += s1;
        i++;
      }
    }
    return sum;
  };

  render() {
    const { id, chapters, activeTab, reportLang } = this.props;

    let index = 0;
    let chapterRomanString = activeTab.slice(8);

    index = this.RomanToDec(chapterRomanString) - 1;
    // switch (activeTab) {
    //   case "chapter-I":
    //     index = 0;
    //     break;
    //   case "chapter-II":
    //     index = 1;
    //     break;
    //   case "chapter-III":
    //     index = 2;
    //     break;
    //   case "chapter-IV":
    //     index = 3;
    //     break;
    //   case "chapter-V":
    //     index = 4;
    //     break;
    //   default:
    // }
    let url;
    if (chapters && chapters[index]) {
      url = reportLang === "en" && chapters[index].chapterEnData ? chapters[index].chapterEnData : chapters[index].chapterData;
    }

    return <div className="report-content">{chapters && chapters[index] ? <img className="report-image" src={url} alt={url} /> : <IntlMessages id="industry.report.chapter-not-found" />}</div>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabChapter));
