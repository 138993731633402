import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { Checkbox } from "semantic-ui-react";
import Linechart from "../../../components/ChartDemo/Linechart";
import LoaderTable from "../../../components/LoaderTable";
import AlertWarning from "../../../components/SweetAlert/AlertWarning";
import { actGetFinancialCODECheckbox, actRequestFinancial, actRequestFinancialDeleteChart, actRequestFinancialINIT } from "../../../redux/actions";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import Button from "../../../components/CustomButton/CustomButton";
import { _Standard_, _Premium_, _Basic_ } from "../../../constants/rolesModel";
import NoData from "../../../components/NoData";
export class TabFinancial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      checkSelectedInput: [],
      isCheckedTarget: true,
      isExpand: true,
      alert: ""
    };
  }

  handleRefresh = () => {
    this.setState({
      checkSelectedInput: [],
      isChecked: false
    });
  };

  toggleChangeTarget = (prams) => {
    this.setState({
      [prams]: !this.state[prams]
    });
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  componentDidMount() {
    if (this.props.companyDetailReducer.id !== null && this.props.companyDetailReducer.id !== undefined) {
      this.props.getFinancialCODECheckbox(this.props.companyDetailReducer.id);
      const { id, set, roleName } = this.props.companyDetailReducer;
      this.props.actRequestFinancialINIT(id, set, roleName);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.intl.locale !== nextProps.intl.locale) {
      if (this.props.companyDetailReducer.id !== null && this.props.companyDetailReducer.id !== undefined) {
        this.props.getFinancialCODECheckbox(this.props.companyDetailReducer.id);
        const { id, set, roleName } = this.props.companyDetailReducer;
        this.props.actRequestFinancialINIT(id, set, roleName);
      }
    }
    const { finalcial, limitChartFN } = nextProps.dataReducer.dataFinacial;
    if (finalcial && finalcial.length > 0) {
      if (finalcial && finalcial.length > 0) {
        if (limitChartFN) {
          this.alertNumChart();
        }
      }
    }
  }

  alertNumChart = () => {
    const { messages } = this.props.intl;
    this.actAlert(messages[`newWord.alertSelected8Chart`]);
  };

  handleApplyChart = () => {
    const { messages } = this.props.intl;
    const { checkSelectedInput, isChecked } = this.state;
    if (this.props.id !== null && this.props.id !== undefined) {
      if (checkSelectedInput.length === 0) {
        this.actAlert();
      } else if (checkSelectedInput.length > 8) {
        this.actAlert(messages[`newWord.alert8LineRatio`]);
      } else {
        const model = {
          id: this.props.id,
          arrHeSo: checkSelectedInput,
          arrKv: [],
          isChecked: isChecked
        };
        this.props.requestFinancial(model);
      }
    }
  };
  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params ? params : messages[`newWord.alertSelectedxRatio`]}
        </SweetAlert>
      )
    });
  };

  actDeleteChart = (prams) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          message={messages[`newWord.AlertRemoveChart`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmToFunc={() => {
            this.handleDeleteChart(prams);
            this._hideAlert();
          }}
          cancleToFunc={this._hideAlert}
        />
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handleChangeCheckbox = (nameInput) => {
    const index = this.state.checkSelectedInput.indexOf(nameInput);
    if (index < 0) {
      this.state.checkSelectedInput.push(nameInput);
    } else {
      this.state.checkSelectedInput.splice(index, 1);
    }
    this.setState({ checkSelectedInput: [...this.state.checkSelectedInput] });
  };

  handleDeleteChart = (prams) => {
    this.props.actRequestFinancialDeleteChart(prams);
  };

  toggleExpand = () => {
    this.setState({
      ...this.state,
      isExpand: !this.state.isExpand
    });
  };

  render() {
    const { checkSelectedInput } = this.state;
    const { finalcial, loadingFNCheckBox, loadingFNChart, finacialCode } = this.props.dataReducer.dataFinacial;
    const { roles, justView } = this.props;
    const { messages } = this.props.intl;
    const { loadingDT } = this.props.dataReducer;
    return (
      <React.Fragment>
        {this.state.alert}
        {loadingFNCheckBox && loadingDT ? (
          <React.Fragment>
            <div ref={(el) => (this.componentRef = el)} className="custom-financial">
              <div className="company_target_views">
                {!justView && (
                  <div className="card-item mb-4">
                    <div className="card-title card-title-line">
                      <IntlMessages id="submenu.Ratios" />
                      <div className="toogle-icon-container" onClick={this.toggleExpand}>
                        <span className="material-icons">{this.state.isExpand ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
                      </div>
                    </div>
                    <Collapse isOpen={this.state.isExpand}>
                      {/* Chỉ số thường */}
                      <div
                        className={
                          roles && (roles === _Standard_ || roles === _Basic_) && finacialCode.ratio && finacialCode.ratio.length > 0 ? "row mb-3" : ""
                        }
                      >
                        {roles && (roles === _Standard_ || roles === _Basic_) && finacialCode.ratio && finacialCode.ratio.length > 0 && (
                          <React.Fragment>
                            {/* <div className="row-title col-12"><IntlMessages id="common.financial" /></div> */}
                            {finacialCode.ratio.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code}
                                    checked={checkSelectedInput.includes(props.code)}
                                    onChange={() => this.handleChangeCheckbox(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* Chỉ số tín dụng */}
                      <div className={roles && finacialCode.tinDung && finacialCode.tinDung.length ? "row mb-3" : ""}>
                        {roles && finacialCode.tinDung && finacialCode.tinDung.length > 0 && (
                          <React.Fragment>
                            {/* <div className="row-title col-12"><IntlMessages id="newWord.Credit" /></div> */}
                            {finacialCode.tinDung.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code}
                                    checked={checkSelectedInput.includes(props.code)}
                                    onChange={() => this.handleChangeCheckbox(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* Cấu trúc vốn */}
                      <div className={roles && finacialCode.cauTrucVon && finacialCode.cauTrucVon.length > 0 ? "row mb-3" : ""}>
                        {roles && finacialCode.cauTrucVon && finacialCode.cauTrucVon.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="ratio.Capitalstructure" />
                            </div>
                            {finacialCode.cauTrucVon.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code}
                                    checked={checkSelectedInput.includes(props.code)}
                                    onChange={() => this.handleChangeCheckbox(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* Chỉ số hiệu quả hoạt động */}
                      <div className={roles && finacialCode.tiSoHieuQuaHoatDong && finacialCode.tiSoHieuQuaHoatDong.length > 0 ? "row mb-3" : ""}>
                        {roles && finacialCode.tiSoHieuQuaHoatDong && finacialCode.tiSoHieuQuaHoatDong.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="ratio.Performanceratios" />
                            </div>
                            {finacialCode.tiSoHieuQuaHoatDong.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code}
                                    name={props.title ? props.title : ""}
                                    checked={checkSelectedInput.includes(props.code)}
                                    onChange={() => this.handleChangeCheckbox(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* Chỉ số thanh toán */}
                      <div className={roles && finacialCode.tiSoThanhToan && finacialCode.tiSoThanhToan.length > 0 ? "row mb-3" : ""}>
                        {roles && finacialCode.tiSoThanhToan && finacialCode.tiSoThanhToan.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="ratio.Liquidityratios" />
                            </div>
                            {finacialCode.tiSoThanhToan.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code}
                                    name={props.title ? props.title : ""}
                                    checked={checkSelectedInput.includes(props.code)}
                                    onChange={() => this.handleChangeCheckbox(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* Chỉ số lợi nhuận */}
                      <div className={roles && finacialCode.tiSuatLoiNhuan && finacialCode.tiSuatLoiNhuan.length > 0 ? "row mb-3" : ""}>
                        {roles && finacialCode.tiSuatLoiNhuan && finacialCode.tiSuatLoiNhuan.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="ratio.Profitabilityratios" />
                            </div>
                            {finacialCode.tiSuatLoiNhuan.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    key={key}
                                    label={props.name ? props.name : ""}
                                    id={props.code}
                                    name={props.title ? props.title : ""}
                                    checked={checkSelectedInput.includes(props.code)}
                                    onChange={() => this.handleChangeCheckbox(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      <div className="company-area-head region-submit mt-4">
                        <Button className="refresh-btn common-button-2" onClick={() => this.handleRefresh()} disabled={checkSelectedInput.length === 0}>
                          <IntlMessages id="common.refresh" />
                        </Button>
                        <Button className="aplly-btn common-button ml-3" onClick={this.handleApplyChart} disabled={loadingFNChart} isLoading={loadingFNChart}>
                          <IntlMessages id="common.Apply" />
                        </Button>
                      </div>
                    </Collapse>
                  </div>
                )}

                {loadingFNChart && <LoaderTable isLoaded={!loadingFNChart} styleCustom={{ height: "500px" }} />}
                <div className="company-chart row equal-height" style={loadingFNChart ? { display: "none" } : {}}>
                  {finalcial &&
                    finalcial.length > 0 &&
                    finalcial.map((props, key) => {
                      return (
                        <div className="col-md-6 col-lg-4 col-xl-3 pb-4" key={key}>
                          <div className="line-chart card-item">
                            <span className="material-icons close-icon" onClick={() => this.actDeleteChart(key)}>
                              close
                            </span>
                            <Linechart
                              suf={true}
                              // percent={true}
                              enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                              dataChart={props}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {finalcial && finalcial.length === 0 && (
                  <div className="card-item mb-4">
                    <NoData />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <LoaderTable isLoaded={loadingFNCheckBox && loadingDT} styleCustom={{ height: "50vh" }} messages={messages} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataStorageReducer: state.dataStorageReducer,
    dataReducer: state.companyReducer,
    companyDetailReducer: state.companyReducer.companyDetail,
    commonReducer: state.CommonReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // DS chỉ số
    getFinancialCODECheckbox: (id) => {
      dispatch(actGetFinancialCODECheckbox(id));
    },
    // Vẽ chart mặc định
    actRequestFinancialINIT: (id, set, roleName) => {
      dispatch(actRequestFinancialINIT(id, set, roleName));
    },
    // Vẽ chart
    requestFinancial: (body) => {
      dispatch(actRequestFinancial(body));
    },
    // Xóa chart
    actRequestFinancialDeleteChart: (id) => {
      dispatch(actRequestFinancialDeleteChart(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabFinancial));
