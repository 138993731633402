import * as Types from "../actions";

export const changeLocale = locale => {
  localStorage.setItem("currentLanguage", locale);
  return {
    type: Types.CHANGE_LOCALE,
    payload: locale
  };
};
export const renderChartRefesh = () => {
  return {
    type: Types.REFRESH_CHART
  };
};
export const renderChartRefeshRESET = () => {
  return {
    type: Types.REFRESH_CHART_RESET
  };
};
