import { defaultLocale, localeOptions } from "../../constants/defaultValues";

import * as Types from "../actions";
const INIT_STATE = {
  locale:
    localStorage.getItem("currentLanguage") &&
    localeOptions.filter(x => x.id === localStorage.getItem("currentLanguage"))
      .length > 0
      ? localStorage.getItem("currentLanguage")
      : defaultLocale,
  refreshChart: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case Types.REFRESH_CHART:
      return { ...state, refreshChart: true };
    case Types.REFRESH_CHART_RESET:
      return { ...state, refreshChart: false };

    default:
      return { ...state };
  }
};
