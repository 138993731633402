import * as Types from "../actions";

export const actSaveCompanyOrther = data => {
  return {
    type: Types.SAVE_DATA_COMPANYORTHER,
    data
  };
};
export const actSaveCompanyDN = data => {
  return {
    type: Types.SAVE_DATA_COMPANYDN,
    data
  };
};
export const actUpdateCompanyOrtherRemember = (value, index) => {
  return {
    type: Types.UPDATE_REMEMBER_DATA_COMPANYORTHER,
    value,
    index
  };
};

/* Chart Target */
export const actChartStorageTarget = data => {
  return {
    type: Types.STORAGE_CHART_TARGET,
    data
  };
};
export const actChartStorageFinancial = data => {
  return {
    type: Types.STORAGE_CHART_FINANCIAL,
    data
  };
};
export const actChartStorageReset = () => {
  return {
    type: Types.STORAGE_CHART_FINANCIAL_RESET
  };
};

export const actcompanyDNTableSelected= data => {
  return {
    type: Types.COMPANY_DNK_SELECTED,
    data
  };
};
//So sanh trung binh nganh

// export const storedChartCompareAverage = data => {
//   return {
//     type: Types.STORAGE_CHART_COMPARE_AVERAGE,
//     payload: {
//       data
//     }
//   };
// };

// export const actDelChartCompareAverage = key => {
//   return {
//     type: Types.DELETE_CHART_COMPARE_AVERAGE,
//     payload: {
//       key
//     }
//   };
// };


//Chart IndustryInformation

// export const storedChartIndustryInformation = (data, type) => {
//   return {
//     type: Types.STORAGE_CHART_INDUSTRY_INFORMATION,
//     payload: {
//       data,
//       type,
//     }
//   };
// };

// export const actDelChartIndustryInformation = key => {
//   return {
//     type: Types.DELETE_CHART_INDUSTRY_INFORMATION,
//     payload: {
//       key
//     }
//   };
// };
