import React, { Suspense } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import IntlMessages from "../../../utils/IntlMessages";
import Data from "../../../components/Dummy/dataVSIC/data";
import { Table } from "reactstrap";
import { actGetVsicList } from "../../../redux/IndustryInformation/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LoaderTable from "../../LoaderTable";
import { Search } from "semantic-ui-react";
import { debounce } from "lodash";
class ModalListVSICPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      listIndustry: [],
      listIndustryFull: []
    };
  }

  componentDidMount() {
    this.props.getVsicList();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { industryList } = nextProps.industryInformationReducer;
    this.setState({
      listIndustry: [...industryList],
      listIndustryFull: [...industryList]
    });
  }
  handleCloseModal = () => {
    this.props.handleCloseForget(false);
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  handleSearchChange = debounce((data) => {
    const { listIndustry, listIndustryFull } = this.state;
    if (data.trim() !== "") {
      let dataSearch = data.trim();
      let dataFilter = listIndustryFull.filter(
        (item) => item.code.includes(dataSearch) || item.name.toLowerCase().includes(dataSearch) || item.enName.toLowerCase().includes(dataSearch)
      );
      this.setState({
        listIndustry: dataFilter
      });
    } else {
      this.setState({
        listIndustry: [...listIndustryFull]
      });
    }
  }, 500);
  render() {
    const { toggleModal } = this.props;
    const { messages } = this.props.intl;
    const { listIndustry } = this.state;
    const closeBtn = (
      <span onClick={this.handleCloseModal} className="material-icons close-btn">
        close
      </span>
    );
    const { industryListLoading } = this.props.industryInformationReducer;

    return (
      <React.Fragment>
        <Modal className="common-modal list-visc-modal" isOpen={toggleModal} size="lg" centered>
          <ModalHeader close={closeBtn}>
            <div style={{ textAlign: "center" }}>
              <IntlMessages id="newWord.IndustrySystem" />

              <div className="search-vsic-container">
                <div className="ui action input fluid customFilter">
                  <input
                    className="searchFilter"
                    placeholder={messages["industry.filter.input"]}
                    onChange={(event) => this.handleSearchChange(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </ModalHeader>

          <ModalBody>
            <div className="table-custom">
              {industryListLoading ? (
                <LoaderTable isLoaded={!industryListLoading} styleCustom={{ height: "250px" }} />
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <IntlMessages id="vsicList.code" />
                      </th>
                      <th className="text-left">
                        <IntlMessages id="vsicList.industryEnName" />
                      </th>
                      <th className="text-left">
                        <IntlMessages id="vsicList.industryName" />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {listIndustry &&
                      listIndustry.length &&
                      listIndustry.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.code}</td>
                            <td className="text-left">{item.enName}</td>
                            <td className="text-left">{item.name}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    industryInformationReducer: state.industryInformationReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVsicList: (body) => {
      dispatch(actGetVsicList(body));
    }
  };
};

const MyComponent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalListVSICPage));

export default function ModalListVSIC(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
