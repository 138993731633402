import React, { Component } from "react";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import queryString from "query-string";
import { injectIntl } from "react-intl";
import IndustryFilter from "../../components/IndustryFilter/IndustryFilter";
import { actSearchIndustry } from "../../redux/actions";
import { Checkbox } from "semantic-ui-react";
import IntlMessages from "../../utils/IntlMessages";
import Button from "../../components/CustomButton/CustomButton";
import LoaderTable from "../../components/LoaderTable";
import { Link } from "react-router-dom";
import { advanceStatisticCriteria, basicStatisticCriteria } from "../../constants/industry";
import { copyArray } from "../../utils/copyArray";
import Findnotfound from "../../components/Findnotfound";
import FindnotfoundNoTitle from "../../components/Findnotfound-noTitle";
import { _ForeignAccount_ } from "../../constants/rolesModel";

class IndustryFilterResults extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "",
      filterType: getUrlParam()["filterType"] ? queryString.parse(this.props.location.search).filterType : "",
      checkedAllBasicStatistic: false,
      checkedAllAdvanceStatistic: false,
      indeterminateBasicStatistic: false, // dấu trừ ở check all
      indeterminateAdvanceStatistic: false, // dấu trừ ở check all
      basicStatisticList: [], // ds thống kê cơ bản
      advanceStatisticList: [], // ds thống kê nâng cao
      selectedBasicStatistic: [],
      selectedAdvanceStatistic: [],
      opacity: "", // làm mờ 1 trong 2 tc thống kê
      statistic: getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [] // tiêu chí thống kê
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const statistic = getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [];
    const basic = copyArray(basicStatisticCriteria);
    const advance = copyArray(advanceStatisticCriteria);
    let checkedAllBasicStatistic = false,
      checkedAllAdvanceStatistic = false,
      indeterminateBasicStatistic = false,
      indeterminateAdvanceStatistic = false,
      selectedBasicStatistic = copyArray(basic.filter((i) => i.isChecked === true)),
      selectedAdvanceStatistic = copyArray(advance.filter((i) => i.isChecked === true));
    if (statistic.length) {
      selectedBasicStatistic = [];
      selectedAdvanceStatistic = [];
      statistic.forEach((e) => {
        basic.forEach((b) => {
          if (b.id === e) {
            b.isChecked = true;
            selectedBasicStatistic.push(b);
          }
        });
        advance.forEach((b) => {
          if (b.id === e) {
            b.isChecked = true;
            selectedAdvanceStatistic.push(b);
          }
        });
      });
    }
    const l1 = basic.filter((i) => i.isChecked === true);
    const l2 = advance.filter((i) => i.isChecked === true);
    if (l1.length === basic.length) {
      checkedAllBasicStatistic = true;
    } else if (l1.length < basic.length && l1.length > 0) {
      checkedAllBasicStatistic = false;
      indeterminateBasicStatistic = true;
    } else {
      checkedAllBasicStatistic = false;
      indeterminateBasicStatistic = false;
    }
    if (l2.length === advance.length) {
      checkedAllAdvanceStatistic = true;
    } else if (l2.length < advance.length && l2.length > 0) {
      checkedAllAdvanceStatistic = false;
      indeterminateAdvanceStatistic = true;
    } else {
      checkedAllAdvanceStatistic = false;
      indeterminateAdvanceStatistic = false;
    }
    this.setState({
      basicStatisticList: basic,
      advanceStatisticList: advance,
      checkedAllBasicStatistic,
      checkedAllAdvanceStatistic,
      indeterminateBasicStatistic,
      indeterminateAdvanceStatistic,
      selectedBasicStatistic,
      selectedAdvanceStatistic
    });
  }

  componentDidMount() {
    document.title = "VIRACE | Industry Filter Result";

    this._isMounted = true;
    if (getUrlParam()["q"] !== undefined && getUrlParam()["filterType"] !== undefined) {
      this.handleSubmit(queryString.parse(this.props.location.search).q, queryString.parse(this.props.location.search).filterType);
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.setState = () => {
      return;
    };
  };

  // nhấn tìm kiếm
  handleSearch = (txtsearch, filterType) => {
    this.handleSubmit(txtsearch, filterType);
  };

  // tìm kiếm
  handleSubmit = (txtsearch, filterType) => {
    var model = {
      searchText: txtsearch
    };
    this.props.actSearchIndustry(model);
  };

  // check all thống kê cơ bản
  handleChangeCheckAllBasicStatistic = () => {
    let { basicStatisticList, checkedAllBasicStatistic, advanceStatisticList } = this.state;
    basicStatisticList.forEach((e) => {
      e.isChecked = !checkedAllBasicStatistic ? true : false;
    });
    advanceStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllBasicStatistic: !checkedAllBasicStatistic,
      basicStatisticList,
      indeterminateBasicStatistic: false,
      selectedBasicStatistic: !checkedAllBasicStatistic ? basicStatisticList : [],
      advanceStatisticList,
      selectedAdvanceStatistic: [],
      indeterminateAdvanceStatistic: false,
      checkedAllAdvanceStatistic: false,
      opacity: !checkedAllBasicStatistic ? "advance" : ""
    });
  };

  // check 1 thống kê cơ bản
  handleCheckedBasicStatistic = (data) => {
    let { selectedBasicStatistic, basicStatisticList, advanceStatisticList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedBasicStatistic = selectedBasicStatistic.filter((i) => i.id !== data.id);
    } else {
      selectedBasicStatistic.push(data);
    }
    basicStatisticList.forEach((e) => {
      if (e.id === data.id) {
        e.isChecked = !e.isChecked;
      }
    });
    advanceStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllBasicStatistic: basicStatisticList.length === selectedBasicStatistic.length,
      indeterminateBasicStatistic: basicStatisticList.length !== selectedBasicStatistic.length && selectedBasicStatistic.length !== 0,
      basicStatisticList,
      selectedBasicStatistic,
      advanceStatisticList,
      selectedAdvanceStatistic: [],
      indeterminateAdvanceStatistic: false,
      checkedAllAdvanceStatistic: false,
      opacity: selectedBasicStatistic.length !== 0 ? "advance" : ""
    });
  };

  // check all thống kê nâng cao
  handleChangeCheckAllAdvanceStatistic = () => {
    let { advanceStatisticList, checkedAllAdvanceStatistic, basicStatisticList } = this.state;
    advanceStatisticList.forEach((e) => {
      e.isChecked = !checkedAllAdvanceStatistic ? true : false;
    });
    basicStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllAdvanceStatistic: !checkedAllAdvanceStatistic,
      advanceStatisticList,
      indeterminateAdvanceStatistic: false,
      selectedAdvanceStatistic: !checkedAllAdvanceStatistic ? advanceStatisticList : [],
      basicStatisticList,
      selectedBasicStatistic: [],
      indeterminateBasicStatistic: false,
      checkedAllBasicStatistic: false,
      opacity: !checkedAllAdvanceStatistic ? "basic" : ""
    });
  };

  // check 1 thống kê nâng cao
  handleCheckedAdvanceStatistic = (data) => {
    let { selectedAdvanceStatistic, advanceStatisticList, basicStatisticList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedAdvanceStatistic = selectedAdvanceStatistic.filter((i) => i.id !== data.id);
    } else {
      selectedAdvanceStatistic.push(data);
    }
    advanceStatisticList.forEach((e) => {
      if (e.id === data.id) {
        e.isChecked = !e.isChecked;
      }
    });
    basicStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllAdvanceStatistic: advanceStatisticList.length === selectedAdvanceStatistic.length,
      indeterminateAdvanceStatistic: advanceStatisticList.length !== selectedAdvanceStatistic.length && selectedAdvanceStatistic.length !== 0,
      advanceStatisticList,
      selectedAdvanceStatistic,
      basicStatisticList,
      selectedBasicStatistic: [],
      indeterminateBasicStatistic: false,
      checkedAllBasicStatistic: false,
      opacity: selectedAdvanceStatistic.length !== 0 ? "basic" : ""
    });
  };

  // thống kê
  applyStatistic = () => {
    const { selectedAdvanceStatistic, selectedBasicStatistic, txtsearch, filterType } = this.state;
    const statistic = [...selectedAdvanceStatistic, ...selectedBasicStatistic];
    let statisticIdList = [];
    statistic.forEach((e) => {
      statisticIdList.push(e.id);
    });
    var model = {
      q: txtsearch.trim(),
      filterType,
      statistic: statisticIdList
    };
    const baseUrl = selectedBasicStatistic.length > 0 ? "thong-ke-co-ban" : "thong-ke-nang-cao";
    this.props.history.push(`/${baseUrl}?q=${model.q}&filterType=${model.filterType}&statistic=${model.statistic}`);
  };

  // xem thêm bc ngành
  viewMoreIndustryReport = () => {
    const { txtsearch, filterType } = this.state;
    var model = {
      page: 1,
      q: txtsearch.trim(),
      filterType
    };
    this.props.history.push(`/danh-sach-bao-cao-nganh?page=${model.page}&q=${model.q}&filterType=${model.filterType}`);
    // window.location = this.paramFromData("/danh-sach-bao-cao-nganh", model);
  };

  // chuyển đến trang kết quả tìm kiếm
  paramFromData = (url, data) => {
    const pathname = window.location.origin;
    const keys = Object.keys(data);
    var paramStr = "";
    keys.map((key, index) => {
      if (data[key]) {
        if (index === 0) paramStr = paramStr.concat(`?${key}=${data[key]}`);
        else paramStr = paramStr.concat(`&${key}=${data[key]}`);
      }
      return null;
    });
    return pathname + url + paramStr;
  };

  render() {
    const {
      txtsearch,
      checkedAllBasicStatistic,
      checkedAllAdvanceStatistic,
      indeterminateBasicStatistic,
      indeterminateAdvanceStatistic,
      basicStatisticList,
      advanceStatisticList,
      opacity,
      selectedAdvanceStatistic,
      selectedBasicStatistic
    } = this.state;
    const filterType = getUrlParam()["filterType"];
    const language = localStorage.getItem("currentLanguage");
    const selectedStatistic = [...selectedAdvanceStatistic, ...selectedBasicStatistic];
    const { searchLoading, industryRelatedCompany, industryReport, totalRelatedReport } = this.props.industryInformationReducer;
    const isLogged = localStorage.getItem("access_token") ? true : false;
    const { roles } = this.props;

    return (
      <div className="industry-filter-results if-ir-home">
        <div className="card-item search-info-section">
          <IndustryFilter
            txtsearch={txtsearch}
            filterType={filterType}
            {...this.props}
            handleSearch={(txtsearch, filterType) => this.handleSearch(txtsearch, filterType)}
          />
        </div>
        {searchLoading ? (
          <LoaderTable isLoaded={!searchLoading} styleCustom={{ height: "500px" }} />
        ) : (
          <div>
            {industryReport.length > 0 || industryRelatedCompany.length > 0 ? (
              <div>
                {filterType !== "report" && (
                  <div className="row card-item mt-4 mx-0 search-result">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className={`${opacity === "basic" ? "opacity" : ""}`}>
                        <div className="input-list-Checkbox title">
                          <Checkbox
                            checked={checkedAllBasicStatistic}
                            onChange={() => this.handleChangeCheckAllBasicStatistic()}
                            indeterminate={indeterminateBasicStatistic}
                          />
                          <IntlMessages id="industry.statistic.basic" />
                        </div>
                        <div>
                          {basicStatisticList.map((props, index) => {
                            return (
                              <div key={index} className="input-list-Checkbox pl-4">
                                <Checkbox checked={props.isChecked} onChange={() => this.handleCheckedBasicStatistic(props)} />
                                {language === "en" && props.enText ? props.enText : props.text}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className={`${opacity === "advance" ? "opacity" : ""}`}>
                        <div className="input-list-Checkbox title">
                          <Checkbox
                            checked={checkedAllAdvanceStatistic}
                            onChange={() => this.handleChangeCheckAllAdvanceStatistic()}
                            indeterminate={indeterminateAdvanceStatistic}
                          />
                          <IntlMessages id="industry.statistic.advance" />
                        </div>
                        <div>
                          {advanceStatisticList.map((props, index) => {
                            return (
                              <div key={index} className="input-list-Checkbox pl-4">
                                <Checkbox checked={props.isChecked} onChange={() => this.handleCheckedAdvanceStatistic(props)} />
                                {language === "en" && props.enText ? props.enText : props.text}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 col-12">
                      <Button disabled={selectedStatistic.length === 0} className="common-button" onClick={this.applyStatistic}>
                        <IntlMessages id="common.Apply" />
                      </Button>
                    </div>
                  </div>
                )}
                {filterType !== "statistic" && (
                  <div className="row card-item mt-4 mb-4 mx-0 search-result">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="">
                        <div className="title report-name">
                          <IntlMessages id="report.industry" />
                        </div>
                        {industryReport && industryReport.length > 0 ? (
                          <div>
                            {industryReport.map((props, key) => {
                              return (
                                <div className="report-name pl-4" key={key}>
                                  <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                    {language === "en" && props.reportEName ? <span>{props.reportEName}</span> : <span>{props.reportName}</span>}
                                  </Link>
                                </div>
                              );
                            })}
                            {totalRelatedReport > 10 && (
                              <div className="more-report pl-4">
                                <a onClick={() => this.viewMoreIndustryReport()}>
                                  <IntlMessages id="industry.report.more" /> {" >>"}
                                </a>
                              </div>
                            )}
                          </div>
                        ) : (
                          <p className="pl-4">
                            <IntlMessages id="notfound.text" />
                          </p>
                        )}
                      </div>
                    </div>
                    {isLogged && roles && roles !== _ForeignAccount_ && (
                      <div className="col-lg-6">
                        <div className="">
                          <div className="title report-name">
                            <IntlMessages id="ownership.Affiliate" />
                          </div>
                          <div>
                            {industryRelatedCompany && industryRelatedCompany.length > 0 ? (
                              <div>
                                {industryRelatedCompany.map((d, key) => {
                                  return (
                                    <div className="report-name pl-4" key={key}>
                                      <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${d.companyId}`}>
                                        {language === "en" && d.companyEName ? d.companyEName : d.companyName}
                                      </Link>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <p className="pl-4">
                                <IntlMessages id="notfound.text" />
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="card-item mt-4 mb-4">
                <FindnotfoundNoTitle />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    industryInformationReducer: state.industryInformationReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSearchIndustry: (body) => {
      dispatch(actSearchIndustry(body));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IndustryFilterResults));
