import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import * as authService from "../../services/authentication.service";
import { setAuthorization } from "../../utils/callApiServer";
import { actNotify } from "./../Fetch/actions";
const language = localStorage.getItem("currentLanguage");

function* actLoginRequest(params) {
  const model = params.payload;
  const hash = localStorage.getItem("hash");

  let body = {
    email: model.email,
    password: model.password,
    hash: hash,
    IsRemember: model.rememberMe
  };
  try {
    var response = yield call(authService.actLogin, body);
    if (response.data.success) {
      var res = response.data.data;
      localStorage.setItem("access_token", res.accessToken);
      setAuthorization(res.accessToken);
      if (model.rememberMe) {
        localStorage.setItem(
          "rememberData",
          JSON.stringify({
            email: model.email,
            password: model.password
          })
        );
      } else {
        localStorage.removeItem("rememberData");
      }
      yield put(actions.actLoginSuccess(true, res.accessToken, res.role));
    } else {
      yield put({
        type: Types.LOGIN_FAIL,
        message: response.data.message
      });
    }
  } catch (err) {
    // yield put(actions.actLoginSuccess(false));
    yield put({
      type: Types.LOGIN_FAIL,
      message: "Hệ thống cập nhật!"
    });
  }
}
export function* watchActLogin() {
  yield takeEvery(Types.LOGIN, actLoginRequest);
}

function* watchForgotPassword() {
  yield takeEvery(Types.HANDLE_FORGOT_PASSWORD, actHandleForgotPassword);
}

function* actHandleForgotPassword(action) {
  var { emailConfirm, url } = action.payload;
  var body = {
    email: emailConfirm,
    url
  };
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(authService.actForgotPasswordApi, body);
    if (response.data.success) {
      yield put(actions.getConfirmEmailSuccess(emailConfirm));
    } else {
      yield put(actions.getConfirmEmailFail(response.data.message));
    }
  } catch (err) {
    yield put(actions.getConfirmEmailFail());
    yield put(actNotify(notify_data));
  }
}

function* watchChangePassword() {
  yield takeEvery(Types.HANDLE_CHANGE_PASSWORD, actChangePassword);
}

function* actChangePassword(action) {
  var { newPass, token, uid, mac, generatedtime } = action.payload;
  var body = {
    token: token,
    newpassword: newPass,
    userId: uid,
    mac: mac,
    generatedTime: generatedtime
  };
  try {
    var response = yield call(authService.actConfirmToken, body);
    if (response.data.success) {
      yield put({
        type: Types.HANDLE_CHANGE_PASSWORD_SUCCESS
      });
    } else {
      yield put({
        type: Types.HANDLE_CHANGE_PASSWORD_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.HANDLE_CHANGE_PASSWORD_FAIL
    });
  }
}
//LOGOUT
function* watchLogoutUser() {
  yield takeEvery(Types.HANDLE_CHECKED_LOGOUT, actCheckLogout);
}

function* actCheckLogout(action) {
  var { cbSuccess } = action.payload;
  var body = {
    logs: "Logout"
  };
  try {
    var response = yield call(authService.actLogoutUser, body);
    if (response.data.success) {
      yield put({
        type: Types.HANDLE_CHECKED_LOGOUT_SUCCESS
      });
      if (cbSuccess) {
        cbSuccess();
      }
    } else {
      if (cbSuccess) {
        cbSuccess();
      }
      yield put({
        type: Types.HANDLE_CHECKED_LOGOUT_FAIL
      });
    }
  } catch (err) {
    if (cbSuccess) {
      cbSuccess();
    }
    yield put({
      type: Types.HANDLE_CHECKED_LOGOUT_FAIL
    });
  }
}
export default function* rootSaga() {
  yield all([fork(watchActLogin), fork(watchForgotPassword), fork(watchChangePassword), fork(watchLogoutUser)]);
}
