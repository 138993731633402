import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { Checkbox } from "semantic-ui-react";
import Linechart from "../../../components/ChartDemo/Linechart";
import LoaderTable from "../../../components/LoaderTable";
import AlertWarning from "../../../components/SweetAlert/AlertWarning";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import Button from "../../../components/CustomButton/CustomButton";
import { _Standard_, _Premium_ } from "../../../constants/rolesModel";
import NoData from "../../../components/NoData";
import { requestAccountMenu, requestAccountRatioChart } from "../../../services/companyInfo.service";
import { defaultPageConpanyInfo } from "../../../constants/defaultValues";

export class TabTargets2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      isCheckedTarget: true,
      selectedRadio: "radio-1",
      selectedInputChart: [],
      isExpand: true,
      alert: "",

      accountMenu: [],
      isLoadingAccountMenu: false,

      dataChartDefault: [],
      configChartDefault: [],
      dataChart: [],
      isLoadingDataChart: false
    };
  }

  handleRefresh = () => {
    this.setState({
      isChecked: false,
      selectedInputChart: []
    });
  };

  componentDidMount() {
    if (this.props.id !== null && this.props.id !== undefined) {
      const { id, set, roleName } = this.props.detail;
      this.getAccountMenu(id);
      this.getDataChartDefault(id, set, roleName);
    }
  }

  async getAccountMenu(id) {
    try {
      this.setState({
        isLoadingAccountMenu: true
      });
      const body = {
        companyId: id
      };
      const response = await requestAccountMenu(body);
      if (response && response.data && response.data.data && response.data.data.data) {
        this.setState({
          isLoadingAccountMenu: false,
          accountMenu: response.data.data.data
        });
      } else {
        this.setState({
          isLoadingAccountMenu: false,
          accountMenu: []
        });
      }
    } catch (error) {
      this.setState({
        isLoadingAccountMenu: false,
        accountMenu: []
      });
    }
  }

  async getDataChartDefault(id, set, roleName) {
    try {
      this.setState({
        isLoadingDataChart: true
      });
      const configDefault = defaultPageConpanyInfo.tabAccount.find((o) => o.set.includes(set))[roleName];
      this.setState({
        configChartDefault: configDefault
      });
      let listId = [];
      if (configDefault) {
        for (let index = 0; index < configDefault.length; index++) {
          let config = configDefault[index];
          for (let i = 0; i < config.length; i++) {
            listId.push(config[i]);
          }
        }
      }
      const body = {
        companyId: id,
        listIndex: listId
      };
      const response = await requestAccountRatioChart(body);

      if (response && response.data && response.data.data) {
        const originData = response.data.data;

        let formatData = [];
        if (configDefault) {
          for (let item of configDefault) {
            let config = item;
            let data = [];
            for (let i = 0; i < config.length; i++) {
              data.push(originData.find((o) => o.index === config[i]));
            }
            formatData.push(data);
          }
        }
        this.setState({
          isLoadingDataChart: false,
          dataChartDefault: formatData,
          dataChart: formatData
        });
      } else {
        this.setState({
          isLoadingDataChart: false,
          dataChartDefault: [],
          dataChart: []
        });
      }
    } catch (error) {
      this.setState({
        isLoadingDataChart: false,
        dataChartDefault: [],
        dataChart: []
      });
    }
  }

  async handleApplyChart() {
    try {
      const { selectedInputChart } = this.state;
      const { messages } = this.props.intl;
      if (this.props.id !== null && this.props.id !== undefined) {
        const currentData = this.state.dataChart;
        if (currentData && currentData.length > 0 && currentData.length === 8) {
          this.alertNumChart();
        } else if (selectedInputChart.length === 0) {
          this.actAlert();
        } else if (selectedInputChart.length > 8) {
          this.actAlert(messages[`newWord.alert8Target`]);
        } else {
          this.setState({
            isLoadingDataChart: true
          });
          const body = {
            companyId: this.props.id,
            listIndex: selectedInputChart
          };
          const response = await requestAccountRatioChart(body);
          if (response && response.data && response.data.data) {
            const responseData = response.data.data;
            const oldData = this.state.dataChart;
            this.setState({
              isLoadingDataChart: false,
              dataChart: [responseData, ...oldData]
            });
          }
        }
      }
    } catch (error) {
      this.setState({
        isLoadingDataChart: false
      });
    }
  }

  alertNumChart = () => {
    const { messages } = this.props.intl;
    this.actAlert(messages[`newWord.alertSelected8Chart`]);
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  funcUntilFilter(arr) {
    const newSelectedInputChart = [...this.state.selectedInputChart];
    for (var i = 0; i < arr.length; i++) {
      const index = newSelectedInputChart.indexOf(arr[i].code);
      if (index >= 0) {
        newSelectedInputChart.splice(index, 1);
      }
    }
    return newSelectedInputChart;
  }

  toggleChangeChildRadio = (e) => {
    this.setState({ selectedRadio: e.target.value });
  };

  handleDeleteChart = (index) => {
    const currentData = this.state.dataChart;
    currentData.splice(index, 1);
    this.setState({
      dataChart: currentData
    });
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params ? params : messages[`newWord.alertSelectedxAccount`]}
        </SweetAlert>
      )
    });
  };

  actDeleteChart = (index) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          message={messages[`newWord.AlertRemoveChart`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmToFunc={() => {
            this.handleDeleteChart(index);
            this._hideAlert();
          }}
          cancleToFunc={this._hideAlert}
        />
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  // click chọn chỉ tiêu
  handleChange = (codeInput) => {
    const index = this.state.selectedInputChart.indexOf(codeInput);
    if (index < 0) {
      this.state.selectedInputChart.push(codeInput);
    } else {
      this.state.selectedInputChart.splice(index, 1);
    }
    this.setState({ selectedInputChart: [...this.state.selectedInputChart] });
  };

  toggleExpand = () => {
    this.setState({
      ...this.state,
      isExpand: !this.state.isExpand
    });
  };

  componentDidUpdate() {
    const { isLoadingDataChart, dataChart } = this.state;

    const { justView } = this.props;
    if (justView && !isLoadingDataChart) {
      this.props.dataTabTargetsDone(true);
    }
  }

  render() {
    const language = localStorage.getItem("currentLanguage");

    const { selectedInputChart, accountMenu, isLoadingAccountMenu, isLoadingDataChart, dataChart } = this.state;

    const { roles, justView } = this.props;

    return (
      <React.Fragment>
        {this.state.alert}
        {!isLoadingAccountMenu ? (
          <React.Fragment>
            <div ref={(el) => (this.componentRef = el)} className="custom-target">
              <div className="company_target_views">
                {!justView && (
                  <div className="card-item mb-4">
                    <div className="card-title card-title-line">
                      <IntlMessages id="submenu.Accounts" />
                      <div className="toogle-icon-container" onClick={this.toggleExpand}>
                        <span className="material-icons">{this.state.isExpand ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
                      </div>
                    </div>
                    <Collapse isOpen={this.state.isExpand}>
                      {roles &&
                        roles === _Premium_ &&
                        accountMenu &&
                        accountMenu.length > 0 &&
                        accountMenu.map((item, index) => {
                          return (
                            <div className="row mb-3" key={index}>
                              <div className="row-title col-12">{language === "vi" ? item.subGroupTitle : item.enSubGroupTitle}</div>
                              {item.data &&
                                item.data.length &&
                                item.data.map((subItem, key) => {
                                  return (
                                    <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                      <Checkbox
                                        label={language === "vi" ? subItem.name : subItem.enName}
                                        id={subItem.id ? subItem.id : ""}
                                        checked={selectedInputChart.includes(subItem.id)}
                                        onChange={() => this.handleChange(subItem.id)}
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}

                      <div className="company-area-head region-submit mt-4">
                        <Button className="refresh-btn common-button-2" onClick={() => this.handleRefresh()} disabled={selectedInputChart.length === 0}>
                          <IntlMessages id="common.refresh" />
                        </Button>
                        <Button
                          className="aplly-btn common-button ml-3"
                          onClick={() => this.handleApplyChart()}
                          disabled={isLoadingAccountMenu}
                          isLoading={isLoadingAccountMenu}
                        >
                          <IntlMessages id="common.Apply" />
                        </Button>
                      </div>
                    </Collapse>
                  </div>
                )}

                {isLoadingDataChart && <LoaderTable isLoaded={!isLoadingDataChart} styleCustom={{ height: "500px" }} />}
                <div className="company-chart row equal-height" style={isLoadingDataChart ? { display: "none" } : {}}>
                  {dataChart &&
                    dataChart.length > 0 &&
                    dataChart.map((props, index) => {
                      return (
                        <div className="col-md-6 col-lg-4 col-xl-3 pb-4" key={index}>
                          <div className="line-chart card-item">
                            <span className="material-icons close-icon" onClick={() => this.actDeleteChart(index)}>
                              close
                            </span>
                            <Linechart xType="label" dataChart={props} enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)} />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {dataChart && dataChart.length === 0 && (
                  <div className="card-item mb-4">
                    <NoData />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <LoaderTable isLoaded={isLoadingAccountMenu && isLoadingDataChart} styleCustom={{ height: "50vh" }} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataStorageReducer: state.dataStorageReducer,
    dataReducer: state.companyReducer,
    commonReducer: state.CommonReducer,
    companyDetailReducer: state.companyReducer.companyDetail
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabTargets2));
