import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { TabContent, TabPane } from "reactstrap";
import TabDataTable from "./TabDataTable";
import TabDataChart from "./TabDataChart";
import classnames from "classnames";

class TabData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1
    };
  }

  componentDidMount() {}

  // thay đổi tab
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const { activeTab } = this.state;
    const { txtsearch, statistic } = this.props;
    return (
      <div className="views_accounts_manament pr-0">
        <div className="manament-tab-nav row">
          <div className="manament-tabcontent-left col-xl-1 mb-lg-0 mb-4">
            <div className="">
              <ul className="nav">
                <li>
                  <a
                    className={classnames({
                      active: activeTab === 1
                    })}
                    onClick={() => {
                      this.toggle(1);
                    }}
                  >
                    <IntlMessages id="industry.statistic.tab.table" />
                  </a>
                </li>
                <li>
                  <a
                    className={classnames({
                      active: activeTab === 2
                    })}
                    onClick={() => {
                      this.toggle(2);
                    }}
                  >
                    <IntlMessages id="industry.statistic.tab.chart" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="manament-tabcontent-right-flex col-xl-11">
            <div className="mb-4">
              <TabContent activeTab={activeTab} className="manament-tabcontent-right">
                <TabPane tabId={1}>
                  <TabDataTable {...this.props} />
                </TabPane>
                <TabPane tabId={2}>
                  <TabDataChart {...this.props} txtsearch={txtsearch} statistic={statistic} />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabData));
