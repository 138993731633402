export const DNOrderByVDL = [
  { value: "", label: "filter.vcsh" },
  { value: "-100,3", label: "filter.vdl1" },
  { value: "3,20", label: "filter.vdl2" },
  { value: "20,100", label: "filter.vdl3" },
  { value: "100,99999", label: "filter.vdl4" }
];
export const DNOrderByTTS = [
  { value: "", label: "filter.tts" },
  { value: "-100,3", label: "filter.vdl1" },
  { value: "3,20", label: "filter.vdl2" },
  { value: "20,100", label: "filter.vdl3" },
  { value: "100,99999", label: "filter.vdl4" }
];
export const DNOrderByLN = [
  { value: "", label: "filter.lnt" },
  { value: "-100,0.3", label: "filter.ln1" },
  { value: "0.3,5", label: "filter.ln2" },
  { value: "5,20", label: "filter.ln3" },
  { value: "30,99999", label: "filter.ln4" }
];
export const DNOrderByDT = [
  { value: "", label: "filter.dt" },
  { value: "-100,3", label: "filter.dt1" },
  { value: "3,50", label: "filter.dt2" },
  { value: "50,200", label: "filter.dt3" },
  { value: "300,99999", label: "filter.dt4" }
];
export const DNOrderByKV = [
  { value: "", text: "Khu Vực" },
  {
    key: "2a5369c9-cf42-4c12-919a-568343e8aa4b",
    value: "2a5369c9-cf42-4c12-919a-568343e8aa4b",
    text: "Thành phố Hồ Chí Minh"
  },
  {
    key: "edc16ed9-8530-41d0-aa8f-d2a23669d94f",
    value: "edc16ed9-8530-41d0-aa8f-d2a23669d94f",
    text: "Hà Nội"
  }
];

export const langReportOptions = [
  { key: "Tiếng Việt", value: "Tiếng Việt", text: "user.langVN" },
  { key: "Tiếng Anh", value: "Tiếng Anh", text: "user.langEN" }
];
export const optionsVersions = [
  { key: "Comprehensive", value: "Comprehensive", text: "Comprehensive" },
  { key: "Standard", value: "Standard", text: "Standard" },
  {
    key: "Market capture",
    value: "Market capture",
    text: "Market capture"
  },
  { key: "Factsheet", value: "Factsheet", text: "Factsheet" }
];

export const optionsQuarter = [
  { key: "Quý 1", value: "Quý 1", text: "newWord.QuarterO" },
  { key: "Quý 2", value: "Quý 2", text: "newWord.QuarterT" },
  { key: "Quý 3", value: "Quý 3", text: "newWord.QuarterTh" },
  { key: "Quý 4", value: "Quý 4", text: "newWord.QuarterF" }
];

export const totalValueFilter = [
  ...DNOrderByVDL,
  ...DNOrderByTTS,
  ...DNOrderByLN,
  ...DNOrderByDT
];
