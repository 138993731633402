import React, { Component } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { renderToString } from "react-dom/server";
import { connect } from "react-redux";
import { Nav, NavItem } from "reactstrap";
import { actGetCompanyDetail, actResetFinancial } from "../../redux/actions";
import { actgetCompanyDetail } from "../../services/companyInfo.service";

import TabFinancial from "./Tabs/TabFinancial";
import TabFinancial2 from "./Tabs/TabFinancial_2";

import TabInfo from "./Tabs/TabInfo";
import TabOwner from "./Tabs/TabOwner";
import TabTargets from "./Tabs/TabTargets";
import TabTargets2 from "./Tabs/TabTargets_2";

import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { subPhone } from "../../utils/func.util";
import LoaderTable from "../../components/LoaderTable";
import { saveAs as importedSaveAs } from "file-saver";
import { _Basic_, _Standard_, _ForeignAccount_ } from "../../constants/rolesModel";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import { defaultPageConpanyInfo } from "../../constants/defaultValues";
import { Redirect } from "react-router-dom";

export class CompanyDetailDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      id: this.props.match.params.id,
      valueFinancialSet: null,
      setChecked: null,
      loadingInitial: true,
      alert: "",
      tabOwnerDone: false,
      tabTargetDone: false,
      tabFinancialDone: false,
      tabInforDone: false,

      functionDownloadRuned: false,
      detail: {}
    };
  }

  handleOnBack = () => {
    this.toggle(this.state.activeTab);
    this.props.history.push(`/chi-tiet-doanh-nghiep/${this.state.id}?tab=${this.state.activeTab}`);
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };
  _hideAlert = () => {
    this.handleOnBack();
    this.setState({
      alert: ""
    });
  };

  getDataHeader() {
    const { id } = this.state;

    const { state } = this.props.history.location;
    const action = this.props.history.action;
    let typeParams = "Thông tin doanh nghiệp";
    if (state && state.type && action === "PUSH") {
      typeParams = state.type;
    }
    // this.props.getCompanyDetail(id, typeParams);
    this.getDataCompanyDetail({ id, typeParams });
  }
  async getDataCompanyDetail(id, typeParams) {
    try {
      this.setState({
        loadingInitial: true
      });
      const response = await actgetCompanyDetail(id, typeParams);
      if (response && response.data && response.data.data) {
        const detail = response.data.data;

        this.setState({
          detail: detail,
          setChecked: detail.set,
          loadingInitial: false
        });

        // this.funcSetLocalCompanySearch(detail.id, detail.name ? detail.name : "");
      }
    } catch (error) {
      this.setState({
        loadingInitial: false
      });
    }
  }
  componentDidMount() {
    this.getDataHeader();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.roles !== "") {
      //Kiểm tra nếu user là roles Basic thì không cho xem chi tiết doanh nghiệp
      const { roles } = nextProps;
      const { messages } = nextProps.intl;
      if (roles && (roles === _Basic_ || roles === _Standard_)) {
        this.actAlert(messages[`newWord.upgradeAccount`]);
        setTimeout(() => {
          this.props.history.push("/menu-dieu-huong/");
        }, 3000);
      }
    }
  }

  renderWebsite = (param) => {
    if (param) {
      return param.replace("https://", "");
    }
    return "";
  };
  handleDownload() {
    setTimeout(() => {
      this.downloadReport();
    }, 3500);
  }

  downloadReport = () => {
    const input = document.getElementById("wrap-to-pdf");
    const inputHeightMm = this.pxToMm(input.offsetHeight);
    const inputHwidthMm = this.pxToMm(input.offsetWidth);

    const a4WidthMm = 210;
    const a4HeightMm = 297;
    const language = localStorage.getItem("currentLanguage");
    const fileName = language === "vi" ? "Bao_cao_doanh_nghiep.pdf" : "Enterprise_report.pdf";

    html2canvas(input, { scale: 4 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        let pdf;
        // Document of a4WidthMm wide and inputHeightMm high
        if (inputHeightMm > a4HeightMm) {
          // elongated a4 (system print dialog will handle page breaks)
          pdf = new jsPDF("p", "mm", [inputHeightMm, inputHwidthMm]);
        } else {
          // standard a4
          pdf = new jsPDF();
        }
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(fileName);
      })
      .finally(() => {
        this.clearDataAfterDownload();
        setTimeout(() => {
          let urlBack = window.location.origin + "/chi-tiet-doanh-nghiep/" + this.state.id;
          window.location.href = urlBack;
        }, 1000);
      });
  };
  pxToMm = (px) => {
    return Math.floor(px / document.getElementById("myMm").offsetHeight);
  };

  range = (start, end) => {
    return Array(end - start)
      .join(0)
      .split(0)
      .map(function(val, id) {
        return id + start;
      });
  };

  clearDataAfterDownload() {
    this.props.resetData();
  }

  dataTabInforDone = (status) => {
    if (status) {
      this.setState({
        tabInforDone: true
      });
    }
  };
  dataTabOwnerDone = (status) => {
    if (status) {
      this.setState({
        tabOwnerDone: true
      });
    }
  };
  dataTabTargetsDone = (status) => {
    if (status) {
      this.setState({
        tabTargetDone: true
      });
    }
  };
  dataTabFinancialDone = (status) => {
    if (status) {
      this.setState({
        tabFinancialDone: true
      });
    }
  };

  render() {
    const { id, loadingInitial, detail, tabInforDone, tabOwnerDone, tabTargetDone, tabFinancialDone, functionDownloadRuned } = this.state;
    const props = this.props;
    const state = this.state;

    const { roles } = this.props;

    const { exportCompanyData } = props.dataReducer;
    const { messages } = this.props.intl;
    if (exportCompanyData) {
      var blob = new Blob([exportCompanyData], { type: "application/pdf" });
      importedSaveAs(blob, messages["companyReportFileName"]);
    }
    const { activeTab, setChecked } = this.state;
    const { set } = this.state.detail;
    if (set) {
      let arrSet = [2, 13, 14, 19, 20, 21, 22]; // doanh nghiệp tín dụng
      var isCreditChecked = arrSet.includes(set);
    }

    const styleLoading = {
      position: "absolute",
      top: "-300px",
      left: "-100px",
      minWidth: "120vw",
      minHeight: "500vh",
      zIndex: 1000,
      backgroundColor: "rgba(0,0,0,0.8)"
    };
    if (!functionDownloadRuned && tabInforDone && tabOwnerDone && tabTargetDone && tabFinancialDone) {
      this.handleDownload();
      this.setState({
        functionDownloadRuned: true
      });
    }
    return (
      <div className="views_company_detail" ref={(el) => (this.componentRef = el)}>
        {roles && roles === _ForeignAccount_ && <Redirect to="/trang-chu-if-ir" />}

        <div id="myMm" style={{ height: "1mm" }} />
        {!this.state.loadingInitial && (
          <div style={{ position: "relative" }}>
            {this.state.alert}
            {state.detail.id && (
              <div id="wrap-to-pdf" style={{ backgroundColor: "#01103d", padding: "15px" }}>
                {/* THÔNG TIN CƠ BẢN */}
                <div className="non-financial-info company-info-content-main">
                  <div className="result-item" style={{ border: 0 }}>
                    <div className="vietnamese-name mb-1 d-flex justify-content-between">
                      <span>{state.detail.name ? state.detail.name : ""}</span>
                    </div>
                    <div className="english-name mb-2">{state.detail.englishName ? state.detail.englishName : ""}</div>

                    {state.detail.subTitle && (
                      <div className="address has-icon">
                        {/* <img src={icon1} alt="icon" /> */}
                        <span className="material-icons">domain</span>
                        {state.detail.subTitle}
                      </div>
                    )}
                    <div className="address has-icon">
                      {" "}
                      <span className="material-icons">place</span>
                      {state.detail.address ? state.detail.address : ""}
                    </div>
                    <div className="info-wrap">
                      <ul className="info">
                        <li className="has-icon">
                          <span className="material-icons">tag</span>
                          <IntlMessages id="page.MST" />: {state.detail.taxNumber ? state.detail.taxNumber : "N/A"}
                        </li>
                        <li className="has-icon" style={{ width: "66.66667%" }}>
                          <span className="material-icons">business_center</span>
                          VSIC: {state.detail.vsicName ? " " + state.detail.vsicName + " - " : ""} {state.detail.vsicCode ? state.detail.vsicCode : ""}
                        </li>
                      </ul>
                    </div>
                    <div className="result-more-content">
                      <ul className="info">
                        <li className="has-icon">
                          <span className="material-icons">phone</span>
                          {state.detail.phoneNumber ? subPhone(state.detail.phoneNumber) : "N/A"}
                        </li>
                        <li className="has-icon">
                          <span className="material-icons">print</span>
                          {state.detail.fax ? state.detail.fax : "N/A"}
                        </li>
                        <li className="has-icon">
                          <span className="material-icons">public</span>
                          {state.detail.webSite ? (
                            <a
                              className="text-cyan"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={state.detail.webSite ? "https://" + this.renderWebsite(state.detail.webSite) : ""}
                            >
                              {state.detail.webSite}
                            </a>
                          ) : (
                            <span>N/A</span>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* THÔNG TIN CHUNG DOANH NGHIỆP */}
                <Nav>
                  <NavItem>
                    <span style={{ color: "#3bdbfd", fontSize: "20px" }}>Thông tin</span>
                  </NavItem>
                </Nav>
                <div className="company-panel-content">
                  <TabInfo
                    detail={detail}
                    loadingDT={!loadingInitial}
                    dataTabInforDone={this.dataTabInforDone}
                    justView={true}
                    history={this.props.history}
                    {...this.props}
                    id={id}
                    checkCreditDN={isCreditChecked}
                    valueFinancial={setChecked}
                  />
                </div>
                {/* THÔNG TIN CHỦ SỬ HỮU */}
                <Nav>
                  <NavItem>
                    <span style={{ color: "#3bdbfd", fontSize: "20px" }}>Chủ sở hữu</span>
                  </NavItem>
                </Nav>
                <div className="company-panel-content">
                  <TabOwner
                    detail={detail}
                    loadingDT={!loadingInitial}
                    dataTabOwnerDone={this.dataTabOwnerDone}
                    justView={true}
                    history={this.props.history}
                    {...this.props}
                    id={id}
                    checkCreditDN={isCreditChecked}
                  />{" "}
                </div>
                {/* THÔNG TIN CHỈ TIÊU */}
                <Nav>
                  <NavItem>
                    <span style={{ color: "#3bdbfd", fontSize: "20px" }}>Chỉ tiêu</span>
                  </NavItem>
                </Nav>
                <div className="company-panel-content">
                  <TabTargets2
                    detail={detail}
                    loadingDT={!loadingInitial}
                    dataTabTargetsDone={this.dataTabTargetsDone}
                    justView={true}
                    history={this.props.history}
                    {...this.props}
                    id={id}
                    checkCreditDN={isCreditChecked}
                  />{" "}
                </div>
                {/* THÔNG TIN CHỈ SỐ */}
                <Nav>
                  <NavItem>
                    <span style={{ color: "#3bdbfd", fontSize: "20px" }}>Chỉ số</span>
                  </NavItem>
                </Nav>
                <div className="company-panel-content">
                  <TabFinancial2
                    detail={detail}
                    loadingDT={!loadingInitial}
                    dataTabFinancialDone={this.dataTabFinancialDone}
                    justView={true}
                    history={this.props.history}
                    {...this.props}
                    id={id}
                    checkCreditDN={isCreditChecked}
                  />{" "}
                </div>
              </div>
            )}
            <div id="loading" className="loading-download" style={styleLoading}>
              <div
                className="loading-icon"
                style={{
                  marginTop: "500px",
                  marginLeft: "-200px"
                }}
              >
                <Loader active size="huge" inline="centered" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // detail: state.companyReducer.companyDetail,
    dataReducer: state.companyReducer,
    userAccount: state.accountReducer.userAccount,
    reportReducer: state.reportReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // chi tiết DN
    getCompanyDetail: (id, type) => {
      dispatch(actGetCompanyDetail(id, type));
    },

    //resetData
    resetData: () => {
      dispatch(actResetFinancial());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyDetailDownload));
