import React, { Component } from "react";
import CanvasJSReact from "../../../assets/js/canvasjs.react";
import XLSX from "xlsx";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import addSymbols, { addSymbols_USD } from "../../../utils/addSymbols.js";
import { numberFormat, numberFormat_2 } from "../../../utils/numberFormat";
import { formatMoney_2 } from "../../../utils/formatMoney";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
var arrayColor1 = [
  "#4661EE",
  // "#EC5657",
  "#1BCDD1",
  "#8FAABB",
  "#B08BEB",
  "#3EA0DD",
  "#F5A52A",
  "#23BFAA",
  "#FAA586",
  "#EB8CC6",
  "#4661EE",
  "#EC5657",
  "#1BCDD1",
  "#8FAABB",
  "#B08BEB",
  "#3EA0DD",
  "#F5A52A",
  "#23BFAA",
  "#FAA586",
  "#EB8CC6"
];
var index = 0;

class MultiColumnImportExport extends Component {
  toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  };
  downloadAsExcel = (args) => {
    var dataPoints, filename;
    var newArr = [];
    filename = args.filename || "chart-data";

    dataPoints = args.chart.data;
    for (var i = 0; i < dataPoints.length; i++) {
      newArr.push({ x: dataPoints[i].name, y: "" }, ...dataPoints[i].dataPoints);
    }

    var ws = XLSX.utils.json_to_sheet(newArr, { skipHeader: true, dateNF: "YYYYMMDD HH:mm:ss" });
    if (!ws["!cols"]) ws["!cols"] = [];
    ws["!cols"][0] = { wch: 17 };
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  genColor(isValue) {
    if (isValue) {
      var color = arrayColor1[index];
      index += 1;
      return color;
    }
    return "grey";
  }
  render() {
    index = 0;
    const { dataImport, dataExport, backgroundColor, customFontColor, colorCustom, customValueInColumnColor, noTranslate, suffix, showValueInColumn, showSourceEn, xTypeLabel } = this.props;
    const language = localStorage.getItem("currentLanguage");

    let nameImport = "";
    let nameExport = "";
    if (noTranslate === true) {
      nameImport = "Import";
      nameExport = "Export";
    } else {
      if (language === "vi") {
        nameImport = "Nhập khẩu";
        nameExport = "Xuất khẩu";
      } else {
        nameImport = "Import";
        nameExport = "Export";
      }
    }

    const { messages } = this.props.intl;
    const options = {
      animationEnabled: true,
      exportEnabled: false,
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "#02164d",
      title: {
        fontSize: 20,
        fontWeight: "bold",
        fontFamily: "Roboto",
        margin: 20
      },
      axisX: {
        valueFormatString: "####",
        interval: 1,
        labelFontSize: 12,
        labelFontWeight: 400,
        labelFontColor: customFontColor ? customFontColor : "#B6C4DE",
        lineColor: "rgba(144, 155, 175, 0.3)"
      },

      axisY: {
        gridThickness: 1,
        gridColor: "rgba(144, 155, 175, 0.3)",
        includeZero: false,
        lineColor: "rgba(144, 155, 175, 0.3)",
        suffix: suffix ? suffix : "",

        labelFormatter: (e) => {
          if (noTranslate === true) {
            return formatMoney_2(e.value, 2);
          } else {
            return addSymbols_USD(e, CanvasJS, messages);
          }
        },
        titleFontColor: customFontColor ? customFontColor : "#B6C4DE",
        labelFontColor: customFontColor ? customFontColor : "#B6C4DE",
        labelFontSize: 12,
        tickColor: "rgba(144, 155, 175, 0.3)",
        grindLine: "rgba(144, 155, 175, 0.3)",
        titleFontSize: 16,
        titleFontWeight: "lighter",
        labelFontFamily: "Roboto"
      },
      axisY2: {
        labelFontSize: 12,
        suffix: "%",
        titleFontColor: "#EC5657",
        lineColor: "#EC5657",
        labelFontColor: "#EC5657",
        tickColor: "rgba(144, 155, 175, 0.3)"
      },
      data: [],
      legend: {
        cursor: "pointer",
        fontFamily: "Roboto",
        fontColor: customFontColor ? customFontColor : "#B6C4DE",
        fontSize: 12,
        itemclick: this.toggleDataSeries,
        fontWeight: 400,
        markerMargin: 4,
        itemWrap: true,
        itemMaxWidth: 300,
        horizontalAlign: "center"
      },
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          var content = "";
          if (xTypeLabel && e.entries[0].dataPoint.label) {
            content += e.entries[0].dataPoint.label;
            content += "<br/>";
          } else if (!xTypeLabel && e.entries[0].dataPoint.x) {
            content += e.entries[0].dataPoint.x;
            content += "<br/>";
          }

          for (var i = 0; i < e.entries.length; i++) {
            content += "<span style='color: " + e.entries[i].dataSeries.color + ";' >" + e.entries[i].dataSeries.name + "</span>" + ": " + formatMoney_2(e.entries[i].dataPoint.y, 2);

            content += "<br/>";
          }
          return content;
        }
      }
    };
    // Data Import
    if (dataImport !== undefined && dataExport !== undefined) {
      options.data.push(
        {
          type: "column",
          showInLegend: true,
          name: nameExport,
          // indexLabel: showValueInColumn ? "{y}" : "",
          indexLabelFontSize: 10,
          indexLabelFontColor: showValueInColumn ? (customValueInColumnColor ? customValueInColumnColor : "#fff") : "",
          indexLabelFormatter: function(e) {
            if (showValueInColumn) return formatMoney_2(e.dataPoint.y, 2);
            else return "";
          },
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataImport),
          xValueFormatString: "####",
          yValueFormatString: "##,##0.## USD",
          dataPoints: dataExport ? dataExport : []
        },
        {
          type: "column",
          showInLegend: true,
          name: nameImport,
          indexLabelFontSize: 10,
          indexLabelFontColor: showValueInColumn ? (customValueInColumnColor ? customValueInColumnColor : "#fff") : "",
          indexLabelFormatter: function(e) {
            if (showValueInColumn) return formatMoney_2(e.dataPoint.y, 2);
            else return "";
          },
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataExport),
          xValueFormatString: "####",
          yValueFormatString: "##,##0.## USD",
          dataPoints: dataImport ? dataImport : []
        }
      );
    }

    return (
      <div className="pareto-chart-views column-multiple-chart-wrapper">
        {/* <button id="downloadExcel" onClick={() => this.downloadAsExcel({ filename: "chart-data", chart: options })}>Download Chart Data as Excel</button> */}
        <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
        {showSourceEn ? (
          <div className="cright">Source: VIRAC</div>
        ) : (
          <div className="cright">
            <IntlMessages id="common.sourceVirac" />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(MultiColumnImportExport);
