import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { actDeleteNotification, actGetUserAccount } from "../../../redux/AccountManagement/actions";
import { connect} from "react-redux";
class NotificationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onDeleteNotification = id => {
        this.props.handleDeleteNotification(id, this.deleteSuccess);
        this.props.handleCloseNotiModal();
    }

    deleteSuccess = message => {
        this.props.deleteSuccess(message);
    }

    render() {
        const { openModal, model } = this.props;
        return (
            <div className="modal-notification">
                <Modal
                    isOpen={openModal}
                    toggle={() => this.props.handleCloseNotiModal()}
                    className="custom-modal-notification"
                >
                    <ModalHeader
                        toggle={() => this.props.handleCloseNotiModal()}
                    >
                        Nội dung Thông báo số {model.stt}
                    </ModalHeader>
                    <ModalBody>
                        <div className="ui form">
                            <div className="title-notification">
                                <span><strong>Tiêu đề: </strong>{model.title}</span>
                            </div>
                            <div className="field content-notification">
                                <span 
                                    dangerouslySetInnerHTML={{
                                        __html: model.content
                                            ? model.content
                                            : ""
                                    }}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            className="btn-cancel"
                            onClick={() => this.props.handleCloseNotiModal()}
                        >
                            Hủy
                        </button>
                        <button
                            className="btn-delete"
                            onClick={() => this.onDeleteNotification(model.id)}
                        >
                            Xóa
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        handleDeleteNotification: (id, cbSuccess) => dispatch(actDeleteNotification(id, cbSuccess)),
        handleGetAccountManagement: () => dispatch(actGetUserAccount()),
    }
}
export default (connect(null, mapDispatchToProps))(NotificationModal);
