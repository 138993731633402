import * as Types from "../actions";

// GET - DATA - FROM - LOCALSTORAGE

export const actGetDataFromLocalStorage = () => {
  return {
    type: Types.GET_DATA_FROM_LOCALSTORAGE
  };
};

// add to cart
export const actAddToCart = (model) => {
  return {
    type: Types.ADD_TO_CART,
    payload: {
      model
    }
  };
};

// remove from cart
export const actRemoveFromCart = (arrToDel) => {
  return {
    type: Types.REMOVE_FROM_CART,
    payload: {
      arrToDel
    }
  };
};

// mua hàng
export const actCreateOrder = (body) => {
  return {
    type: Types.CREATE_ORDER,
    payload: body
  };
};

// lấy thông tin đơn hàng
export const actGetOrder = (sessionId) => {
  return {
    type: Types.GET_ORDER,
    payload: sessionId
  };
};

// SEND - DATA - REQUEST
export const actSendRequestData = (arrSendData, selectedList, cbSuccess) => {
  return {
    type: Types.SEND_DATA_REQUEST,
    payload: {
      arrSendData,
      selectedList,
      success: cbSuccess
    }
  };
};

export const getMessageSendRequestData = (message, listRequested) => {
  return {
    type: Types.SEND_DATA_REQUEST_MESSAGE,
    payload: {
      message,
      listRequested
    }
  };
};
export const actSendRequestDataFail = () => {
  return {
    type: Types.SEND_DATA_REQUEST_FAIL
  };
};
