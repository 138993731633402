import * as Types from "../actions";

export const actLogin = (email, password, rememberMe) => {
  return {
    type: Types.LOGIN,
    payload: { email, password, rememberMe }
  };
};

export const actLoginSuccess = (isLogin, token = "", role = "") => {
  return {
    type: Types.LOGIN_SUCCESS,
    isLogin,
    token,
    role
  };
};

export const actLogout = () => {
  return {
    type: Types.LOGOUT
  };
};

//FORGOT PASSWORD - QUÊN MẬT KHẨU
export const actGetConfirmEmail = (emailConfirm, url) => {
  return {
    type: Types.HANDLE_FORGOT_PASSWORD,
    payload: {
      emailConfirm,
      url
    }
  };
};

export const getConfirmEmailSuccess = (email) => {
  return {
    type: Types.HANDLE_FORGOT_PASSWORD_SUCCESS,
    payload: {
      email
    }
  };
};

export const getConfirmEmailFail = (messErr) => {
  return {
    type: Types.HANDLE_FORGOT_PASSWORD_FAIL,
    payload: {
      messErr
    }
  };
};
//Confirm Token - Confirm Token
export const actConfirmToken = (emailConfirm) => {
  return {
    type: Types.HANDLE_CONFIRM_TOKEN,
    payload: {
      emailConfirm
    }
  };
};

export const confirmTokenSuccess = (messSuccess) => {
  return {
    type: Types.HANDLE_CONFIRM_TOKEN_SUCCESS,
    payload: {
      messSuccess
    }
  };
};

export const confirmTokenFail = (messErr) => {
  return {
    type: Types.HANDLE_CONFIRM_TOKEN_FAIL,
    payload: {
      messErr
    }
  };
};

// CHANGE PASSWORD - Đổi mật khẩu

export const actChangePassword = (newPass, token, uid, mac, generatedtime) => {
  return {
    type: Types.HANDLE_CHANGE_PASSWORD,
    payload: {
      newPass,
      token,
      uid,
      mac,
      generatedtime
    }
  };
};

// Logout

export const actLogoutchecked = (cbSuccess) => {
  return {
    type: Types.HANDLE_CHECKED_LOGOUT,
    payload: {
      cbSuccess
    }
  };
};
