import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as industryInformationService from "../../services/industryInformation.service";
import * as Types from "../actions";
import * as actions from "./actions";
import { actNotify } from "../Fetch/actions";
import { redirectURL } from "../../utils/logoutCommon";
const language = localStorage.getItem("currentLanguage");

//TỔNG QUAN NGÀNH

//SẢN XUẤT - TIÊU THỤ - TỒN KHO

//CHỈ SỐ KINH TẾ NGÀNH

//SO SÁNH NGÀNH

//SS Trung Binh

//SS TGT

//----------------------------------------------------------------------
// IR-IF
// Tra cứu thông tin ngành
function* actSearchIndustry(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustry, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.SEARCH_INDUSTRY_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.SEARCH_INDUSTRY_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.SEARCH_INDUSTRY_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActSearchIndustry() {
  yield takeEvery(Types.SEARCH_INDUSTRY, actSearchIndustry);
}

// DS báo cáo ngành
function* actSearchIndustryReport(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryReport, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.SEARCH_INDUSTRY_REPORT_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.SEARCH_INDUSTRY_REPORT_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.SEARCH_INDUSTRY_REPORT_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActSearchIndustryReport() {
  yield takeEvery(Types.SEARCH_INDUSTRY_REPORT, actSearchIndustryReport);
}

// Thống kê cơ bản - table
function* actStatisticIndustry(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryStatistic, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.STATISTIC_INDUSTRY_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.STATISTIC_INDUSTRY_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.STATISTIC_INDUSTRY_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActStatisticIndustry() {
  yield takeEvery(Types.STATISTIC_INDUSTRY, actStatisticIndustry);
}

// Thống kê nâng cao - table
function* actAdvanceStatisticIndustry(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryAdvanceStatistic, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceStatisticIndustry() {
  yield takeEvery(Types.ADVANCE_STATISTIC_INDUSTRY, actAdvanceStatisticIndustry);
}

// ds năm để vẽ chart
function* actGetStatisticChartYear() {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(industryInformationService.actGetStatisticChartYear);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.STATISTIC_INDUSTRY_SUCCESS_CHART_YEAR,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.STATISTIC_INDUSTRY_FAIL_CHART_YEAR
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.STATISTIC_INDUSTRY_FAIL_CHART_YEAR
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetStatisticChartYear() {
  yield takeEvery(Types.STATISTIC_INDUSTRY_CHART_YEAR, actGetStatisticChartYear);
}

// Thống kê cơ bản - chart
function* actStatisticIndustryChart(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryStatisticChart, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.STATISTIC_INDUSTRY_SUCCESS_CHART,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.STATISTIC_INDUSTRY_FAIL_CHART
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.STATISTIC_INDUSTRY_FAIL_CHART
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActStatisticIndustryChart() {
  yield takeEvery(Types.STATISTIC_INDUSTRY_CHART, actStatisticIndustryChart);
}

// Thống kê nâng cao - table
function* actAdvanceStatisticIndustryChart(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryAdvanceStatisticChart, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_SUCCESS_CHART,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceStatisticIndustryChart() {
  yield takeEvery(Types.ADVANCE_STATISTIC_INDUSTRY_CHART, actAdvanceStatisticIndustryChart);
}

// ds ngành vsic
function* actGetVsicList() {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(industryInformationService.getVsicList);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.VSIC_LIST_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.VSIC_LIST_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.VSIC_LIST_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetVsicList() {
  yield takeEvery(Types.VSIC_LIST, actGetVsicList);
}

// ds sản phẩm
function* actGetProductList() {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(industryInformationService.getProductList);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.PRODUCT_LIST_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.PRODUCT_LIST_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.PRODUCT_LIST_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetProductList() {
  yield takeEvery(Types.PRODUCT_LIST, actGetProductList);
}

// chi tiết bc ngành
function* actGetIndustryReportDetail(body) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(industryInformationService.getIndustryReportDeyail, body.reportId);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.INDUSTRY_REPORT_DETAIL_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.INDUSTRY_REPORT_DETAIL_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.INDUSTRY_REPORT_DETAIL_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetIndustryReportDetail() {
  yield takeEvery(Types.INDUSTRY_REPORT_DETAIL, actGetIndustryReportDetail);
}

// bc liên quan - DN liên quan
function* actGetRelatedReportAndCompany(body) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(industryInformationService.getRelatedReportAndCompany, body.reportId);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.INDUSTRY_RELATED_REPORT_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.INDUSTRY_RELATED_REPORT_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.INDUSTRY_RELATED_REPORT_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetRelatedReportAndCompany() {
  yield takeEvery(Types.INDUSTRY_RELATED_REPORT, actGetRelatedReportAndCompany);
}
//----------------------------------------------------------------------

export default function* rootSaga() {
  yield all([
    //----------------------------------------------------------------------
    // IR-IF
    fork(watchActSearchIndustry),
    fork(watchActSearchIndustryReport),
    fork(watchActStatisticIndustry),
    fork(watchActAdvanceStatisticIndustry),
    fork(watchActStatisticIndustryChart),
    fork(watchActAdvanceStatisticIndustryChart),
    fork(watchActGetStatisticChartYear),
    fork(watchActGetVsicList),
    fork(watchActGetProductList),
    fork(watchActGetIndustryReportDetail),
    fork(watchActGetRelatedReportAndCompany)
    //----------------------------------------------------------------------
  ]);
}
