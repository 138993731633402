import * as Types from "../actions";

//GET - ALL NEWS
export const actGetAll = (cateID, pageIndex) => {
    return {
        type: Types.GET_ALL,
        payload: {
            cateID,
            pageIndex,
        }
    }
}

export const getNewsSuccess = (newsList, totalPages) => {
    return {
        type: Types.GET_NEWS_SUCCESS,
        payload: {
            newsList,
            totalPages
        }
    };
};

export const getNewsFail = err => {
    return {
        type: Types.GET_NEWS_FAIL,
        payload: {
            err
        }
    };
};

//GET - HOT - NEWS
export const actGetHotNews = () => {
    return {
        type: Types.GET_HOT_NEWS,
    };
};

export const getHotNewsSuccess = (hotNewsList, totalPages) => {
    return {
        type: Types.GET_HOT_NEWS_SUCCESS,
        payload: {
            hotNewsList,
            totalPages
        }
    };
};

export const getHotNewsFail = err => {
    return {
        type: Types.GET_HOT_NEWS_FAIL,
        payload: {
            err
        }
    };
};

//GET- NEWS- ITEM
export const actGetNewsById = id => {
    return {
        type: Types.GET_NEWS_BY_ID,
        payload: {
            id
        }
    };
};

export const getNewsByIdSuccess = newsItem => {
    return {
        type: Types.GET_NEWS_BY_ID_SUCCESS,
        payload: {
            newsItem
        }
    };
};

export const getNewsByIdFail = err => {
    return {
        type: Types.GET_NEWS_BY_ID_FAIL,
        payload: {
            err
        }
    };
};



