import React, { Component } from "react";
import { Dropdown, Menu, Input, Checkbox } from "semantic-ui-react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import Button from "../../components/CustomButton/CustomButton";
import ModalListVSIC from "../CompanyInfo/CompanyChild/ListVSIC";
import { connect } from "react-redux";
import { actSearchIndustry, actSearchIndustryReport } from "../../redux/actions";
import { Link } from "react-router-dom";
import paramFromData from "../../utils/paramFromData";
import { advanceStatisticCriteria, basicStatisticCriteria } from "../../constants/industry";
import getUrlParam from "../../utils/getUrlParam";
import { copyArray } from "../../utils/copyArray";
import { debounce } from "lodash";
import queryString from "query-string";
import { _Basic_ } from "../../constants/rolesModel";

class IndustryFilter extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      toggleModalListVSIC: false,
      filterType: getUrlParam()["filterType"] ? getUrlParam()["filterType"] : "all",
      page: 1,
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "", // nội dung tìm kiếm
      txtsearchError: "", // lỗi tìm kiếm
      isShowDropdown: false, // dropdown kết quả
      checkedAllBasicStatistic: false,
      checkedAllAdvanceStatistic: false,
      indeterminateBasicStatistic: false, // dấu trừ ở check all
      indeterminateAdvanceStatistic: false, // dấu trừ ở check all
      basicStatisticList: copyArray(basicStatisticCriteria), // ds thống kê cơ bản
      advanceStatisticList: copyArray(advanceStatisticCriteria), // ds thống kê nâng cao
      selectedBasicStatistic: [],
      selectedAdvanceStatistic: [],
      opacity: "", // làm mờ 1 trong 2 tc thống kê
      statistic: getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [] // tiêu chí thống kê
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const statistic = getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [];
    const basic = copyArray(basicStatisticCriteria);
    const advance = copyArray(advanceStatisticCriteria);
    let checkedAllBasicStatistic = false,
      checkedAllAdvanceStatistic = false,
      indeterminateBasicStatistic = false,
      indeterminateAdvanceStatistic = false,
      selectedBasicStatistic = copyArray(basic.filter((i) => i.isChecked === true)),
      selectedAdvanceStatistic = copyArray(advance.filter((i) => i.isChecked === true));
    if (statistic.length) {
      selectedBasicStatistic = [];
      selectedAdvanceStatistic = [];
      statistic.forEach((e) => {
        basic.forEach((b) => {
          if (b.id === e) {
            b.isChecked = true;
            selectedBasicStatistic.push(b);
          }
        });
        advance.forEach((b) => {
          if (b.id === e) {
            b.isChecked = true;
            selectedAdvanceStatistic.push(b);
          }
        });
      });
    }
    const l1 = basic.filter((i) => i.isChecked === true);
    const l2 = advance.filter((i) => i.isChecked === true);
    if (l1.length === basic.length) {
      checkedAllBasicStatistic = true;
    } else if (l1.length < basic.length && l1.length > 0) {
      checkedAllBasicStatistic = false;
      indeterminateBasicStatistic = true;
    } else {
      checkedAllBasicStatistic = false;
      indeterminateBasicStatistic = false;
    }
    if (l2.length === advance.length) {
      checkedAllAdvanceStatistic = true;
    } else if (l2.length < advance.length && l2.length > 0) {
      checkedAllAdvanceStatistic = false;
      indeterminateAdvanceStatistic = true;
    } else {
      checkedAllAdvanceStatistic = false;
      indeterminateAdvanceStatistic = false;
    }
    this.setState({
      basicStatisticList: basic,
      advanceStatisticList: advance,
      checkedAllBasicStatistic,
      checkedAllAdvanceStatistic,
      indeterminateBasicStatistic,
      indeterminateAdvanceStatistic,
      selectedBasicStatistic,
      selectedAdvanceStatistic
    });
  }

  componentDidMount() {
    this._isMounted = true;
    const { txtsearch } = this.state;
    if (txtsearch.length >= 2 && window.location.pathname !== "/ket-qua-tra-cuu-thong-tin-nganh") {
      let model = {
        searchText: txtsearch
      };
      this.props.actSearchIndustry(model);
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.setState = () => {
      return;
    };
  };

  handleModalListVSIC = () => {
    this.setState((state) => ({
      toggleModalListVSIC: !state.toggleModalListVSIC
    }));
  };

  // chọn loại tìm kiếm
  handleChangeFilterType = (e, { value }) => {
    this.setState({ filterType: value });
  };

  handleDebounce = debounce((value) => {
    if (value.trim().length >= 2) {
      this.setState({
        isShowDropdown: true
      });
      let model = {
        searchText: value.trim()
      };
      this.props.actSearchIndustry(model);
    }
  }, 500);

  handleChange = (e) => {
    this.handleDebounce(e.target.value);
  };

  onKeyUp = (event) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      txtsearch: value,
      txtsearchError: "",
      isShowDropdown: false
    });
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };

  onBlur = () => {
    setTimeout(() => {
      this.setState({
        isShowDropdown: false
      });
    }, 300);
  };

  onFocus = () => {
    if (this.state.txtsearch.length >= 2) {
      setTimeout(() => {
        this.setState({
          isShowDropdown: true
        });
      }, 300);
    }
  };

  // submit search form
  handleSubmit = () => {
    const { txtsearch, filterType } = this.state;
    if (txtsearch.trim().length < 2) {
      this.setState({
        ...this.state,
        txtsearchError: "industry.filter.input.minLength",
        isShowDropdown: false
      });
      return;
    }
    this.setState({
      ...this.state,
      txtsearchError: "",
      isShowDropdown: false
    });
    var model = {
      q: txtsearch.trim(),
      filterType
    };
    if (window.location.pathname !== "/ket-qua-tra-cuu-thong-tin-nganh") {
      this.props.history.push(`/ket-qua-tra-cuu-thong-tin-nganh?q=${model.q}&filterType=${model.filterType}`);
    } else {
      this.props.handleSearch(txtsearch.trim(), filterType);
      this.props.history.push(paramFromData(model));
    }
  };

  // xem thêm bc ngành
  viewMoreIndustryReport = () => {
    const { txtsearch, filterType } = this.state;
    var model = {
      page: 1,
      q: txtsearch.trim(),
      filterType
    };
    window.location.href = `/danh-sach-bao-cao-nganh?page=${model.page}&q=${model.q}&filterType=${model.filterType}`;
  };

  // chuyển đến trang kết quả tìm kiếm
  paramFromData = (url, data) => {
    const pathname = window.location.origin;
    const keys = Object.keys(data);
    var paramStr = "";
    keys.map((key, index) => {
      if (data[key]) {
        if (index === 0) paramStr = paramStr.concat(`?${key}=${data[key]}`);
        else paramStr = paramStr.concat(`&${key}=${data[key]}`);
      }
      return null;
    });
    return pathname + url + paramStr;
  };

  // check all thống kê cơ bản
  handleChangeCheckAllBasicStatistic = () => {
    let { basicStatisticList, checkedAllBasicStatistic, advanceStatisticList } = this.state;
    basicStatisticList.forEach((e) => {
      e.isChecked = !checkedAllBasicStatistic ? true : false;
    });
    advanceStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllBasicStatistic: !checkedAllBasicStatistic,
      basicStatisticList,
      indeterminateBasicStatistic: false,
      selectedBasicStatistic: !checkedAllBasicStatistic ? basicStatisticList : [],
      advanceStatisticList,
      selectedAdvanceStatistic: [],
      indeterminateAdvanceStatistic: false,
      checkedAllAdvanceStatistic: false,
      opacity: !checkedAllBasicStatistic ? "advance" : ""
    });
  };

  // check 1 thống kê cơ bản
  handleCheckedBasicStatistic = (data) => {
    let { selectedBasicStatistic, basicStatisticList, advanceStatisticList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedBasicStatistic = selectedBasicStatistic.filter((i) => i.id !== data.id);
    } else {
      selectedBasicStatistic.push(data);
    }
    basicStatisticList.forEach((e) => {
      if (e.id === data.id) {
        e.isChecked = !e.isChecked;
      }
    });
    advanceStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllBasicStatistic: basicStatisticList.length === selectedBasicStatistic.length,
      indeterminateBasicStatistic: basicStatisticList.length !== selectedBasicStatistic.length && selectedBasicStatistic.length !== 0,
      basicStatisticList,
      selectedBasicStatistic,
      advanceStatisticList,
      selectedAdvanceStatistic: [],
      indeterminateAdvanceStatistic: false,
      checkedAllAdvanceStatistic: false,
      opacity: selectedBasicStatistic.length !== 0 ? "advance" : ""
    });
  };

  // check all thống kê nâng cao
  handleChangeCheckAllAdvanceStatistic = () => {
    let { advanceStatisticList, checkedAllAdvanceStatistic, basicStatisticList } = this.state;
    advanceStatisticList.forEach((e) => {
      e.isChecked = !checkedAllAdvanceStatistic ? true : false;
    });
    basicStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllAdvanceStatistic: !checkedAllAdvanceStatistic,
      advanceStatisticList,
      indeterminateAdvanceStatistic: false,
      selectedAdvanceStatistic: !checkedAllAdvanceStatistic ? advanceStatisticList : [],
      basicStatisticList,
      selectedBasicStatistic: [],
      indeterminateBasicStatistic: false,
      checkedAllBasicStatistic: false,
      opacity: !checkedAllAdvanceStatistic ? "basic" : ""
    });
  };

  // check 1 thống kê nâng cao
  handleCheckedAdvanceStatistic = (data) => {
    let { selectedAdvanceStatistic, advanceStatisticList, basicStatisticList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedAdvanceStatistic = selectedAdvanceStatistic.filter((i) => i.id !== data.id);
    } else {
      selectedAdvanceStatistic.push(data);
    }
    advanceStatisticList.forEach((e) => {
      if (e.id === data.id) {
        e.isChecked = !e.isChecked;
      }
    });
    basicStatisticList.forEach((e) => {
      e.isChecked = false;
    });
    this.setState({
      checkedAllAdvanceStatistic: advanceStatisticList.length === selectedAdvanceStatistic.length,
      indeterminateAdvanceStatistic: advanceStatisticList.length !== selectedAdvanceStatistic.length && selectedAdvanceStatistic.length !== 0,
      advanceStatisticList,
      selectedAdvanceStatistic,
      basicStatisticList,
      selectedBasicStatistic: [],
      indeterminateBasicStatistic: false,
      checkedAllBasicStatistic: false,
      opacity: selectedAdvanceStatistic.length !== 0 ? "basic" : ""
    });
  };

  // thống kê
  applyStatistic = () => {
    const { selectedAdvanceStatistic, selectedBasicStatistic, txtsearch, filterType } = this.state;
    const statistic = [...selectedAdvanceStatistic, ...selectedBasicStatistic];
    let statisticIdList = [];
    statistic.forEach((e) => {
      statisticIdList.push(e.id);
    });
    var model = {
      q: txtsearch.trim(),
      filterType,
      statistic: statisticIdList
    };
    const baseUrl = selectedBasicStatistic.length > 0 ? "thong-ke-co-ban" : "thong-ke-nang-cao";
    const url = `/${baseUrl}?q=${model.q}&filterType=${model.filterType}&statistic=${model.statistic}`;
    if (window.location.href.includes("thong-ke-co-ban") || window.location.href.includes("thong-ke-nang-cao")) {
      window.location.href = url;
    } else {
      this.props.history.push(url);
    }
  };

  render() {
    const {
      txtsearch,
      filterType,
      txtsearchError,
      toggleModalListVSIC,
      isShowDropdown,
      checkedAllBasicStatistic,
      checkedAllAdvanceStatistic,
      indeterminateBasicStatistic,
      indeterminateAdvanceStatistic,
      basicStatisticList,
      advanceStatisticList,
      opacity,
      selectedAdvanceStatistic,
      selectedBasicStatistic
    } = this.state;
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const selectedStatistic = [...selectedAdvanceStatistic, ...selectedBasicStatistic];
    const { searchLoading, industryRelatedCompany, industryReport, totalRelatedReport } = this.props.industryInformationReducer;
    const { roles } = this.props;

    const filterTypeList = [
      {
        key: "all",
        text: messages["industry.filter.all"],
        value: "all"
      },
      {
        key: "statistic",
        text: messages["industry.filter.statistic"],
        value: "statistic"
      },
      {
        key: "report",
        text: messages["industry.filter.report"],
        value: "report"
      }
    ];
    const isLogged = localStorage.getItem("access_token") ? true : false;

    return (
      <div className="company-info-fillter">
        <div className="content-fillter">
          {toggleModalListVSIC && <ModalListVSIC toggleModal={toggleModalListVSIC} handleCloseForget={this.handleModalListVSIC} />}
          <div className="company-search-bar">
            <div className="heading">
              <div className="section-title">
                <IntlMessages id="menu.industryinformationsearch" />
              </div>
              <div className="visc-list">
                <a className="text-cyan" onClick={this.handleModalListVSIC}>
                  <em className="material-icons">featured_play_list</em>
                  <IntlMessages id="filter.dsnn" />
                </a>
              </div>
            </div>

            <div className="ui action input fluid customFilter">
              <Menu compact className="filter-type mr-3 selected-custom-select">
                <Dropdown fluid selection options={filterTypeList} className="custom-dropdown" onChange={this.handleChangeFilterType} defaultValue={filterType} />
              </Menu>
              <Input
                defaultValue={txtsearch}
                type="text"
                className="customInput"
                onChange={this.handleChange}
                onKeyUp={this.onKeyUp}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                placeholder={messages["industry.filter.input"]}
              />
              <Button disabled={searchLoading} isLoading={searchLoading} className={`common-button ml-3 ${searchLoading ? "disabled" : ""}`} onClick={this.handleSubmit}>
                <IntlMessages id="filter.buttonSearch" />
                <em className="material-icons">search</em>
              </Button>
            </div>

            {txtsearchError && (
              <div className="form-error">
                <IntlMessages id={txtsearchError} />
              </div>
            )}
          </div>
          {!searchLoading && isShowDropdown && (
            <div className="search-result search-result-dropdown">
              <div className="result-container">
                <div className="row">
                  {filterType !== "report" && (
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className={`mb-5 ${opacity === "basic" ? "opacity" : ""}`}>
                        <div className="input-list-Checkbox title">
                          <Checkbox checked={checkedAllBasicStatistic} onChange={() => this.handleChangeCheckAllBasicStatistic()} indeterminate={indeterminateBasicStatistic} />
                          <IntlMessages id="industry.statistic.basic" />
                        </div>
                        <div>
                          {basicStatisticList.map((props, index) => {
                            return (
                              <div key={index} className="input-list-Checkbox pl-4">
                                <Checkbox checked={props.isChecked} onChange={() => this.handleCheckedBasicStatistic(props)} />
                                {language === "en" && props.enText ? props.enText : props.text}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className={`${opacity === "advance" ? "opacity" : ""}`}>
                        <div className="input-list-Checkbox title">
                          <Checkbox checked={checkedAllAdvanceStatistic} onChange={() => this.handleChangeCheckAllAdvanceStatistic()} indeterminate={indeterminateAdvanceStatistic} />
                          <IntlMessages id="industry.statistic.advance" />
                        </div>
                        <div>
                          {advanceStatisticList.map((props, index) => {
                            return (
                              <div key={index} className="input-list-Checkbox pl-4">
                                <Checkbox checked={props.isChecked} onChange={() => this.handleCheckedAdvanceStatistic(props)} />
                                {language === "en" && props.enText ? props.enText : props.text}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="mt-4">
                        <Button disabled={selectedStatistic.length === 0} className="common-button" onClick={this.applyStatistic}>
                          <IntlMessages id="common.Apply" />
                        </Button>
                      </div>
                    </div>
                  )}
                  {filterType !== "statistic" && (
                    <div className="col-lg-6">
                      <div className="mb-5">
                        <div className="title report-name">
                          <IntlMessages id="report.industry" />
                        </div>
                        {industryReport && industryReport.length > 0 ? (
                          <div>
                            {industryReport.map((props, key) => {
                              if (key < 4) {
                                return (
                                  <div className="report-name pl-4" key={key}>
                                    <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                      {language === "en" && props.reportEName ? <span>{props.reportEName}</span> : <span>{props.reportName}</span>}
                                    </Link>
                                  </div>
                                );
                              }
                            })}
                            {totalRelatedReport > 4 && (
                              <div className="more-report pl-4">
                                <a onClick={() => this.viewMoreIndustryReport()}>
                                  <IntlMessages id="industry.report.more" /> {" >>"}
                                </a>
                              </div>
                            )}
                          </div>
                        ) : (
                          <p className="pl-4">
                            <IntlMessages id="notfound.text" />
                          </p>
                        )}
                      </div>
                      {isLogged === true && roles && roles !== _Basic_ && (
                        <div className="">
                          <div className="title report-name">
                            <IntlMessages id="ownership.Affiliate" />
                          </div>
                          <div>
                            {industryRelatedCompany && industryRelatedCompany.length > 0 ? (
                              <div>
                                {industryRelatedCompany.map((d, key) => {
                                  if (key < 4) {
                                    return (
                                      <div className="report-name pl-4" key={key}>
                                        <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${d.companyId}`}>
                                          {language === "en" && d.companyEName ? d.companyEName : d.companyName}
                                        </Link>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            ) : (
                              <p className="pl-4">
                                <IntlMessages id="notfound.text" />
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {/* {industryReport.length > 0 || industryRelatedCompany.length > 0 ? (
                  
                ) : (
                  <div className="pl-3">
                    <IntlMessages id="search.notfound.text" />
                  </div>
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    industryInformationReducer: state.industryInformationReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSearchIndustry: (body) => {
      dispatch(actSearchIndustry(body));
    },
    actSearchIndustryReport: (body) => {
      dispatch(actSearchIndustryReport(body));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IndustryFilter));
