const data = [
    { c3: "011", c4: "", c5: "", name: "Trồng cây hàng năm", },
    { c3: "", c4: "0111", c5: "01110", name: "Trồng lúa", },
    { c3: "", c4: "0112", c5: "01120", name: "Trồng ngô và cây lương thực có hạt khác", },
    { c3: "", c4: "0113", c5: "01130", name: "Trồng cây lấy củ có chất bột ", },
    { c3: "", c4: "0114", c5: "01140", name: "Trồng cây mía", },
    { c3: "", c4: "0115", c5: "01150", name: "Trồng cây thuốc lá, thuốc lào", },
    { c3: "", c4: "0116", c5: "01160", name: "Trồng cây lấy sợi", },
    { c3: "", c4: "0117", c5: "01170", name: "Trồng cây có hạt chứa dầu", },
    { c3: "", c4: "0118", c5: "", name: "Trồng rau, đậu các loại và trồng hoa", },
    { c3: "", c4: "", c5: "01181", name: "Trồng rau các loại", },
    { c3: "", c4: "", c5: "01182", name: "Trồng đậu các loại", },
    { c3: "", c4: "", c5: "01183", name: "Trồng hoa hàng năm", },
    { c3: "", c4: "0119", c5: "", name: "Trồng cây hàng năm khác ", },
    { c3: "", c4: "", c5: "01191", name: "Trồng cây gia vị hàng năm", },
    { c3: "", c4: "", c5: "01192", name: "Trồng cây dược liệu, hương liệu hàng năm", },
    { c3: "", c4: "", c5: "01199", name: "Trồng cây hàng năm khác còn lại", },
    { c3: "012", c4: "", c5: "", name: "Trồng cây lâu năm", },
    { c3: "", c4: "0121", c5: "", name: "Trồng cây ăn quả", },
    { c3: "", c4: "", c5: "01211", name: "Trồng nho", },
    { c3: "", c4: "", c5: "01212", name: "Trồng cây ăn quả vùng nhiệt đới và cận nhiệt đới", },
    { c3: "", c4: "", c5: "01213", name: "Trồng cam, quýt và các loại quả có múi khác", },
    { c3: "", c4: "", c5: "01214", name: "Trồng táo, mận và các loại quả có hạt như táo", },
    { c3: "", c4: "", c5: "01215", name: "Trồng nhãn, vải, chôm chôm", },
    { c3: "", c4: "", c5: "01219", name: "Trồng cây ăn quả khác", },
    { c3: "", c4: "0122", c5: "01220", name: "Trồng cây lấy quả chứa dầu", },
    { c3: "", c4: "0123", c5: "01230", name: "Trồng cây điều", },
    { c3: "", c4: "0124", c5: "01240", name: "Trồng cây hồ tiêu", },
    { c3: "", c4: "0125", c5: "01250", name: "Trồng cây cao su", },
    { c3: "", c4: "0126", c5: "01260", name: "Trồng cây cà phê", },
    { c3: "", c4: "0127", c5: "01270", name: "Trồng cây chè ", },
    { c3: "", c4: "0128", c5: "", name: "Trồng cây gia vị, cây dược liệu, cây hương liệu lâu năm", },
    { c3: "", c4: "", c5: "01281", name: "Trồng cây gia vị lâu năm", },
    { c3: "", c4: "", c5: "01282", name: "Trồng cây dược liệu, hương liệu lâu năm", },
    { c3: "", c4: "0129", c5: "", name: "Trồng cây lâu năm  khác ", },
    { c3: "", c4: "", c5: "01291", name: "Trồng cây cảnh lâu năm", },
    { c3: "", c4: "", c5: "01299", name: "Trồng cây lâu năm khác còn lại", },
    { c3: "013", c4: "", c5: "", name: "Nhân và chăm sóc cây giống nông nghiệp", },
    { c3: "", c4: "0131", c5: "01310", name: "Nhân và chăm sóc cây giống hàng năm", },
    { c3: "", c4: "0132", c5: "01320", name: "Nhân và chăm sóc cây giống lâu năm", },
    { c3: "014", c4: "", c5: "", name: "Chăn nuôi", },
    { c3: "", c4: "0141", c5: "", name: "Chăn nuôi trâu, bò và sản xuất giống trâu, bò", },
    { c3: "", c4: "", c5: "01411", name: "Sản xuất giống trâu, bò", },
    { c3: "", c4: "", c5: "01412", name: "Chăn nuôi trâu, bò", },
    { c3: "", c4: "0142", c5: "", name: "Chăn nuôi ngựa, lừa, la và sản xuất giống ngựa, lừa", },
    { c3: "", c4: "", c5: "01421", name: "Sản xuất giống ngựa, lừa", },
    { c3: "", c4: "", c5: "01422", name: "Chăn nuôi ngựa, lừa, la", },
    { c3: "", c4: "0144", c5: "", name: "Chăn nuôi dê, cừu và sản xuất giống dê, cừu, hươu, nai", },
    { c3: "", c4: "", c5: "01441", name: "Sản xuất giống dê, cừu, hươu, nai", },
    { c3: "", c4: "", c5: "01442", name: "Chăn nuôi dê, cừu, hươu, nai", },
    { c3: "", c4: "0145", c5: "", name: "Chăn nuôi lợn và sản xuất giống lợn", },
    { c3: "", c4: "", c5: "01451", name: "Sản xuất giống lợn", },
    { c3: "", c4: "", c5: "01452", name: "Chăn nuôi lợn", },
    { c3: "", c4: "0146", c5: "", name: "Chăn nuôi gia cầm", },
    { c3: "", c4: "", c5: "01461", name: "Hoạt động ấp trứng và sản xuất giống gia cầm", },
    { c3: "", c4: "", c5: "01462", name: "Chăn nuôi gà", },
    { c3: "", c4: "", c5: "01463", name: "Chăn nuôi vịt, ngan, ngỗng", },
    { c3: "", c4: "", c5: "01469", name: "Chăn nuôi gia cầm khác", },
    { c3: "", c4: "0149", c5: "01490", name: "Chăn nuôi khác ", },
    { c3: "015", c4: "0150", c5: "01500", name: "Trồng trọt, chăn nuôi hỗn hợp", },
    { c3: "016", c4: "", c5: "", name: "Hoạt động dịch vụ nông nghiệp ", },
    { c3: "", c4: "0161", c5: "01610", name: "Hoạt động dịch vụ trồng trọt ", },
    { c3: "", c4: "0162", c5: "01620", name: "Hoạt động dịch vụ chăn nuôi ", },
    { c3: "", c4: "0163", c5: "01630", name: "Hoạt động dịch vụ sau thu hoạch", },
    { c3: "", c4: "0164", c5: "01640", name: "Xử lý hạt giống để nhân giống", },
    { c3: "017", c4: "0170", c5: "01700", name: "Săn bắt, đánh bẫy và hoạt động dịch vụ có liên quan", },
    { c3: "021", c4: "0210", c5: "", name: "Trồng rừng, chăm sóc rừng và ươm giống cây lâm nghiệp", },
    { c3: "", c4: "", c5: "02101", name: "Trồng rừng và chăm sóc rừng cây thân gỗ", },
    { c3: "", c4: "", c5: "02102", name: "Trồng rừng và chăm sóc rừng họ tre", },
    { c3: "", c4: "", c5: "02103", name: "Trồng rừng và chăm sóc rừng khác", },
    { c3: "", c4: "", c5: "02104", name: "Ươm giống cây lâm nghiệp", },
    { c3: "022", c4: "0220", c5: "", name: "Khai thác gỗ", },
    { c3: "", c4: "", c5: "02200", name: "Khai thác gỗ", },
    { c3: "023", c4: "", c5: "", name: "Khai thác, thu nhặt lâm sản khác trừ gỗ", },
    { c3: "", c4: "0231", c5: "02310", name: "Khai thác lâm sản khác trừ gỗ", },
    { c3: "", c4: "0232", c5: "02320", name: "Thu nhặt lâm sản khác trừ gỗ", },
    { c3: "024", c4: "0240", c5: "02400", name: "Hoạt động dịch vụ lâm nghiệp", },
    { c3: "031", c4: "", c5: "", name: "Khai thác thuỷ sản", },
    { c3: "", c4: "0311", c5: "03110", name: "Khai thác thuỷ sản biển", },
    { c3: "", c4: "0312", c5: "", name: "Khai thác thuỷ sản nội địa", },
    { c3: "", c4: "", c5: "03120", name: "Khai thác thuỷ sản nội địa", },
    { c3: "032", c4: "", c5: "", name: "Nuôi trồng thuỷ sản", },
    { c3: "", c4: "0321", c5: "", name: "Nuôi trồng thuỷ sản biển", },
    { c3: "", c4: "", c5: "03211", name: "Nuôi cá", },
    { c3: "", c4: "", c5: "03212", name: "Nuôi tôm", },
    { c3: "", c4: "", c5: "03213", name: "Nuôi thủy sản khác", },
    { c3: "", c4: "", c5: "03214", name: "Sản xuất giống thủy sản biển", },
    { c3: "", c4: "0322", c5: "", name: "Nuôi trồng thuỷ sản nội địa", },
    { c3: "", c4: "", c5: "03221", name: "Nuôi cá", },
    { c3: "", c4: "", c5: "03222", name: "Nuôi tôm", },
    { c3: "", c4: "", c5: "03223", name: "Nuôi thủy sản khác", },
    { c3: "", c4: "", c5: "03224", name: "Sản xuất giống thủy sản nội địa", },
    { c3: "051", c4: "0510", c5: "05100", name: "Khai thác và thu gom than cứng", },
    { c3: "052", c4: "0520", c5: "05200", name: "Khai thác và thu gom than non", },
    { c3: "061", c4: "0610", c5: "06100", name: "Khai thác dầu thô", },
    { c3: "062", c4: "0620", c5: "06200", name: "Khai thác khí đốt tự nhiên", },
    { c3: "071", c4: "0710", c5: "07100", name: "Khai thác quặng sắt", },
    { c3: "072", c4: "", c5: "", name: "Khai thác quặng không chứa sắt (trừ quặng kim loại quý hiếm)", },
    { c3: "", c4: "0721", c5: "07210", name: "Khai thác quặng uranium và quặng thorium", },
    { c3: "", c4: "0722", c5: "", name: "Khai thác quặng kim loại khác không chứa sắt ", },
    { c3: "", c4: "", c5: "07221", name: "Khai thác quặng bôxit", },
    { c3: "", c4: "", c5: "07229", name: "Khai thác quặng kim loại khác không chứa sắt chưa được phân vào đâu", },
    { c3: "073", c4: "0730", c5: "07300", name: "Khai thác quặng kim loại quý hiếm ", },
    { c3: "081", c4: "0810", c5: "", name: "Khai thác đá, cát, sỏi, đất sét ", },
    { c3: "", c4: "", c5: "08101", name: "Khai thác đá", },
    { c3: "", c4: "", c5: "08102", name: "Khai thác cát, sỏi", },
    { c3: "", c4: "", c5: "08103", name: "Khai thác đất sét", },
    { c3: "089", c4: "", c5: "", name: "Khai khoáng chưa được phân vào đâu", },
    { c3: "", c4: "0891", c5: "08910", name: "Khai thác khoáng hoá chất và khoáng phân bón", },
    { c3: "", c4: "0892", c5: "08920", name: "Khai thác và thu gom than bùn", },
    { c3: "", c4: "0893", c5: "08930", name: "Khai thác muối", },
    { c3: "", c4: "0899", c5: "08990", name: "Khai khoáng khác chưa được phân vào đâu", },
    { c3: "091", c4: "0910", c5: "09100", name: "Hoạt động dịch vụ hỗ trợ khai thác dầu thô và khí tự nhiên", },
    { c3: "099", c4: "0990", c5: "09900", name: "Hoạt động dịch vụ hỗ trợ khai khoáng khác", },
    { c3: "101", c4: "1010", c5: "", name: "Chế biến, bảo quản thịt và các sản phẩm từ thịt", },
    { c3: "", c4: "", c5: "10101", name: "Giết mổ gia súc, gia cầm", },
    { c3: "", c4: "", c5: "10102", name: "Chế biến và bảo quản thịt", },
    { c3: "", c4: "", c5: "10109", name: "Chế biến và bảo quản các sản phẩm từ thịt", },
    { c3: "102", c4: "1020", c5: "", name: "Chế biến, bảo quản thuỷ sản và các sản phẩm từ thuỷ sản", },
    { c3: "", c4: "", c5: "10201", name: "Chế biến và bảo quản thủy sản đông lạnh", },
    { c3: "", c4: "", c5: "10202", name: "Chế biến và bảo quản thủy sản khô", },
    { c3: "", c4: "", c5: "10203", name: "Chế biến và bảo quản nước mắm", },
    { c3: "", c4: "", c5: "10209", name: "Chế biến và bảo quản các sản phẩm khác từ thủy sản", },
    { c3: "103", c4: "1030", c5: "", name: "Chế biến và bảo quản rau quả", },
    { c3: "", c4: "", c5: "10301", name: "Sản xuất nước ép từ rau quả", },
    { c3: "", c4: "", c5: "10309", name: "Chế biến và bảo quản rau quả khác", },
    { c3: "104", c4: "1040", c5: "", name: "Sản xuất dầu, mỡ động, thực vật", },
    { c3: "", c4: "", c5: "10401", name: "Sản xuất dầu, mỡ động vật", },
    { c3: "", c4: "", c5: "10402", name: "Sản xuất dầu, bơ thực vật", },
    { c3: "105", c4: "1050", c5: "10500", name: "Chế biến sữa và các sản phẩm từ sữa", },
    { c3: "106", c4: "", c5: "", name: "Xay xát và sản xuất bột ", },
    { c3: "", c4: "1061", c5: "", name: "Xay xát và sản xuất bột thô", },
    { c3: "", c4: "", c5: "10611", name: "Xay xát", },
    { c3: "", c4: "", c5: "10612", name: "Sản xuất bột thô", },
    { c3: "", c4: "1062", c5: "10620", name: "Sản xuất tinh bột và các sản phẩm từ tinh bột", },
    { c3: "107", c4: "", c5: "", name: "Sản xuất thực phẩm khác", },
    { c3: "", c4: "1071", c5: "10710", name: "Sản xuất các loại bánh từ bột", },
    { c3: "", c4: "1072", c5: "10720", name: "Sản xuất đường", },
    { c3: "", c4: "1073", c5: "10730", name: "Sản xuất ca cao, sôcôla và bánh kẹo", },
    { c3: "", c4: "1074", c5: "10740", name: "Sản xuất mì ống, mỳ sợi và sản phẩm tương tự", },
    { c3: "", c4: "1075", c5: "", name: "Sản xuất món ăn, thức ăn chế biến sẵn", },
    { c3: "", c4: "", c5: "10751", name: "Sản xuất món ăn, thức ăn chế biến sẵn từ thịt", },
    { c3: "", c4: "", c5: "10752", name: "Sản xuất món ăn, thức ăn chế biến sẵn từ thủy sản", },
    { c3: "", c4: "", c5: "10759", name: "Sản xuất món ăn, thức ăn chế biến sẵn khác", },
    { c3: "", c4: "1076", c5: "10760", name: "Sản xuất chè", },
    { c3: "", c4: "1077", c5: "10770", name: "Sản xuất cà phê", },
    { c3: "", c4: "1079", c5: "10790", name: "Sản xuất thực phẩm khác chưa được phân vào đâu", },
    { c3: "108", c4: "1080", c5: "10800", name: "Sản xuất thức ăn gia súc, gia cầm và thuỷ sản", },
    { c3: "110", c4: "", c5: "", name: "Sản xuất đồ uống ", },
    { c3: "", c4: "1101", c5: "11010", name: "Chưng, tinh cất và pha chế các loại rượu mạnh", },
    { c3: "", c4: "1102", c5: "11020", name: "Sản xuất rượu vang", },
    { c3: "", c4: "1103", c5: "11030", name: "Sản xuất bia và mạch nha ủ men bia", },
    { c3: "", c4: "1104", c5: "", name: "Sản xuất đồ uống không cồn, nước khoáng", },
    { c3: "", c4: "", c5: "11041", name: "Sản xuất nước khoáng, nước tinh khiết đóng chai", },
    { c3: "", c4: "", c5: "11042", name: "Sản xuất đồ uống không cồn", },
    { c3: "120", c4: "1200", c5: "", name: "Sản xuất sản phẩm thuốc lá", },
    { c3: "", c4: "", c5: "12001", name: "Sản xuất thuốc lá", },
    { c3: "", c4: "", c5: "12009", name: "Sản xuất thuốc hút khác", },
    { c3: "131", c4: "", c5: "", name: "Sản xuất sợi, vải dệt thoi và hoàn thiện sản phẩm dệt", },
    { c3: "", c4: "1311", c5: "13110", name: "Sản xuất sợi", },
    { c3: "", c4: "1312", c5: "13120", name: "Sản xuất vải dệt thoi", },
    { c3: "", c4: "1313", c5: "13130", name: "Hoàn thiện sản phẩm dệt", },
    { c3: "139", c4: "", c5: "", name: "Sản xuất hàng dệt khác", },
    { c3: "", c4: "1391", c5: "13910", name: "Sản xuất vải dệt kim, vải đan móc và vải không dệt khác", },
    { c3: "", c4: "1392", c5: "13920", name: "Sản xuất hàng dệt sẵn (trừ trang phục)", },
    { c3: "", c4: "1393", c5: "13930", name: "Sản xuất thảm, chăn, đệm", },
    { c3: "", c4: "1394", c5: "13940", name: "Sản xuất các loại dây bện và lưới", },
    { c3: "", c4: "1399", c5: "13990", name: "Sản xuất các loại hàng dệt khác chưa được phân vào đâu", },
    { c3: "141", c4: "1410", c5: "14100", name: "May trang phục (trừ trang phục từ da lông thú)", },
    { c3: "142", c4: "1420", c5: "14200", name: "Sản xuất sản phẩm từ da lông thú", },
    { c3: "143", c4: "1430", c5: "14300", name: "Sản xuất trang phục dệt kim, đan móc", },
    { c3: "151", c4: "", c5: "", name: "Thuộc, sơ chế da; sản xuất va li, túi xách, yên đệm; sơ chế và nhuộm da lông thú ", },
    { c3: "", c4: "1511", c5: "15110", name: "Thuộc, sơ chế da; sơ chế và nhuộm da lông thú", },
    { c3: "", c4: "1512", c5: "15120", name: "Sản xuất vali, túi xách và các loại tương tự, sản xuất yên đệm", },
    { c3: "152", c4: "1520", c5: "15200", name: "Sản xuất giày, dép", },
    { c3: "161", c4: "1610", c5: "", name: "Cưa, xẻ, bào gỗ và bảo quản gỗ", },
    { c3: "", c4: "", c5: "16101", name: "Cưa, xẻ và bào gỗ", },
    { c3: "", c4: "", c5: "16102", name: "Bảo quản gỗ", },
    { c3: "162", c4: "", c5: "", name: "Sản xuất sản phẩm từ gỗ, tre, nứa (trừ giường, tủ, bàn, ghế); sản xuất sản phẩm từ rơm, rạ và vật liệu tết bện", },
    { c3: "", c4: "1621", c5: "16210", name: "Sản xuất gỗ dán, gỗ lạng, ván ép và ván mỏng khác", },
    { c3: "", c4: "1622", c5: "16220", name: "Sản xuất đồ gỗ xây dựng", },
    { c3: "", c4: "1623", c5: "16230", name: "Sản xuất bao bì bằng gỗ", },
    { c3: "", c4: "1629", c5: "", name: "Sản xuất sản phẩm khác từ gỗ; sản xuất sản phẩm từ tre, nứa, rơm, rạ và vật liệu tết bện ", },
    { c3: "", c4: "", c5: "16291", name: "Sản xuất sản phẩm khác từ gỗ", },
    { c3: "", c4: "", c5: "16292", name: "Sản xuất sản phẩm từ lâm sản (trừ gỗ), cói và vật liệu tết bện", },
    { c3: "170", c4: "", c5: "", name: "Sản xuất giấy và sản phẩm từ giấy", },
    { c3: "", c4: "1701", c5: "17010", name: "Sản xuất bột giấy, giấy và bìa", },
    { c3: "", c4: "1702", c5: "", name: "Sản xuất giấy nhăn, bìa nhăn, bao bì từ giấy và bìa", },
    { c3: "", c4: "", c5: "17021", name: "Sản xuất bao bì bằng giấy, bìa", },
    { c3: "", c4: "", c5: "17022", name: "Sản xuất giấy nhăn và bìa nhăn", },
    { c3: "", c4: "1709", c5: "17090", name: "Sản xuất các sản phẩm khác từ giấy và bìa chưa được phân vào đâu ", },
    { c3: "181", c4: "", c5: "", name: "In ấn và dịch vụ liên quan đến in", },
    { c3: "", c4: "1811", c5: "18110", name: "In ấn", },
    { c3: "", c4: "1812", c5: "18120", name: "Dịch vụ liên quan đến in", },
    { c3: "182", c4: "1820", c5: "18200", name: "Sao chép bản ghi các loại", },
    { c3: "191", c4: "1910", c5: "19100", name: "Sản xuất than cốc", },
    { c3: "192", c4: "1920", c5: "19200", name: "Sản xuất sản phẩm dầu mỏ tinh chế", },
    { c3: "201", c4: "", c5: "", name: "Sản xuất hoá chất cơ bản, phân bón và hợp chất ni tơ; sản xuất plastic và cao su tổng hợp dạng nguyên sinh ", },
    { c3: "", c4: "2011", c5: "", name: "Sản xuất hoá chất cơ bản", },
    { c3: "", c4: "", c5: "20111", name: "Sản xuất khí công nghiệp", },
    { c3: "", c4: "", c5: "20112", name: "Sản xuất chất nhuộm và chất màu", },
    { c3: "", c4: "", c5: "20113", name: "Sản xuất hóa chất vô cơ cơ bản khác", },
    { c3: "", c4: "", c5: "20114", name: "Sản xuất hóa chất hữu cơ cơ bản khác", },
    { c3: "", c4: "", c5: "20119", name: "Sản xuất hóa chất cơ bản khác", },
    { c3: "", c4: "2012", c5: "20120", name: "Sản xuất phân bón và hợp chất ni tơ", },
    { c3: "", c4: "2013", c5: "", name: "Sản xuất plastic và cao su tổng hợp dạng nguyên sinh", },
    { c3: "", c4: "", c5: "20131", name: "Sản xuất plastic nguyên sinh", },
    { c3: "", c4: "", c5: "20132", name: "Sản xuất cao su tổng hợp dạng nguyên sinh", },
    { c3: "202", c4: "", c5: "", name: "Sản xuất sản phẩm hoá chất khác", },
    { c3: "", c4: "2021", c5: "20210", name: "Sản xuất thuốc trừ sâu và sản phẩm hoá chất khác dùng trong nông nghiệp", },
    { c3: "", c4: "2022", c5: "", name: "Sản xuất sơn, véc ni và các chất sơn, quét tương tự; sản xuất mực in và ma tít", },
    { c3: "", c4: "", c5: "20221", name: "Sản xuất sơn, véc ni và các chất sơn, quét tương tự, ma tít", },
    { c3: "", c4: "", c5: "20222", name: "Sản xuất mực in", },
    { c3: "", c4: "2023", c5: "", name: "Sản xuất mỹ phẩm, xà phòng, chất tẩy rửa, làm bóng và chế phẩm vệ sinh", },
    { c3: "", c4: "", c5: "20231", name: "Sản xuất mỹ phẩm", },
    { c3: "", c4: "", c5: "20232", name: "Sản xuất xà phòng, chất tẩy rửa, làm bóng và chế phẩm vệ sinh", },
    { c3: "", c4: "2029", c5: "20290", name: "Sản xuất sản phẩm hoá chất khác chưa được phân vào đâu", },
    { c3: "203", c4: "2030", c5: "20300", name: "Sản xuất sợi nhân tạo", },
    { c3: "210", c4: "2100", c5: "", name: "Sản xuất thuốc, hoá dược và dược liệu", },
    { c3: "", c4: "", c5: "21001", name: "Sản xuất thuốc các loại", },
    { c3: "", c4: "", c5: "21002", name: "Sản xuất hóa dược và dược liệu", },
    { c3: "221", c4: "", c5: "", name: "Sản xuất sản phẩm từ cao su", },
    { c3: "", c4: "2211", c5: "22110", name: "Sản xuất săm, lốp cao su; đắp và tái chế lốp cao su", },
    { c3: "", c4: "2219", c5: "22190", name: "Sản xuất sản phẩm khác từ cao su", },
    { c3: "222", c4: "2220", c5: "", name: "Sản xuất sản phẩm từ plastic", },
    { c3: "", c4: "", c5: "22201", name: "Sản xuất bao bì từ plastic", },
    { c3: "", c4: "", c5: "22209", name: "Sản xuất sản phẩm khác từ plastic", },
    { c3: "231", c4: "2310", c5: "", name: "Sản xuất thuỷ tinh và sản phẩm từ thuỷ tinh", },
    { c3: "", c4: "", c5: "23101", name: "Sản xuất thủy tinh phẳng và sản phẩm từ thủy tinh phẳng", },
    { c3: "", c4: "", c5: "23102", name: "Sản xuất thủy tinh rỗng và sản phẩm từ thủy tinh rỗng", },
    { c3: "", c4: "", c5: "23103", name: "Sản xuất sợi thủy tinh và sản phẩm từ sợi thủy tinh", },
    { c3: "", c4: "", c5: "23109", name: "Sản xuất thủy tinh khác và các sản phẩm từ thủy tinh", },
    { c3: "239", c4: "", c5: "", name: "Sản xuất sản phẩm từ khoáng phi kim loại chưa được phân vào đâu", },
    { c3: "", c4: "2391", c5: "23910", name: "Sản xuất sản phẩm chịu lửa", },
    { c3: "", c4: "2392", c5: "23920", name: "Sản xuất vật liệu xây dựng từ đất sét", },
    { c3: "", c4: "2393", c5: "23930", name: "Sản xuất sản phẩm gốm sứ khác", },
    { c3: "", c4: "2394", c5: "", name: "Sản xuất xi măng, vôi và thạch cao", },
    { c3: "", c4: "", c5: "23941", name: "Sản xuất xi măng", },
    { c3: "", c4: "", c5: "23942", name: "Sản xuất vôi", },
    { c3: "", c4: "", c5: "23943", name: "Sản xuất thạch cao", },
    { c3: "", c4: "2395", c5: "23950", name: "Sản xuất bê tông và các sản phẩm từ bê tông, xi măng và thạch cao", },
    { c3: "", c4: "2396", c5: "23960", name: "Cắt tạo dáng và hoàn thiện đá", },
    { c3: "", c4: "2399", c5: "23990", name: "Sản xuất sản phẩm từ chất khoáng phi kim loại khác chưa được phân vào đâu", },
    { c3: "241", c4: "2410", c5: "24100", name: "Sản xuất sắt, thép, gang", },
    { c3: "242", c4: "2420", c5: "", name: "Sản xuất kim loại quý và kim loại màu", },
    { c3: "", c4: "", c5: "24201", name: "Sản xuất kim loại quý", },
    { c3: "", c4: "", c5: "24202", name: "Sản xuất kim loại màu", },
    { c3: "243", c4: "", c5: "", name: "Đúc kim loại", },
    { c3: "", c4: "2431", c5: "24310", name: "Đúc sắt, thép", },
    { c3: "", c4: "2432", c5: "24320", name: "Đúc kim loại màu", },
    { c3: "251", c4: "", c5: "", name: "Sản xuất các cấu kiện kim loại, thùng, bể chứa và nồi hơi ", },
    { c3: "", c4: "2511", c5: "25110", name: "Sản xuất các cấu kiện kim loại ", },
    { c3: "", c4: "2512", c5: "25120", name: "Sản xuất thùng, bể chứa và dụng cụ chứa đựng bằng kim loại", },
    { c3: "", c4: "2513", c5: "25130", name: "Sản xuất nồi hơi (trừ nồi hơi trung tâm)", },
    { c3: "252", c4: "2520", c5: "25200", name: "Sản xuất vũ khí và đạn dược", },
    { c3: "259", c4: "", c5: "", name: "Sản xuất sản phẩm khác bằng kim loại; các dịch vụ xử lý, gia công kim loại", },
    { c3: "", c4: "2591", c5: "25910", name: "Rèn, dập, ép và cán kim loại; luyện bột kim loại", },
    { c3: "", c4: "2592", c5: "25920", name: "Gia công cơ khí; xử lý và tráng phủ kim loại", },
    { c3: "", c4: "2593", c5: "25930", name: "Sản xuất dao kéo, dụng cụ cầm tay và đồ kim loại thông dụng", },
    { c3: "", c4: "2599", c5: "", name: "Sản xuất sản phẩm khác bằng kim loại chưa được phân vào đâu", },
    { c3: "", c4: "", c5: "25991", name: "Sản xuất đồ dùng bằng kim loại cho nhà bếp, nhà vệ sinh và nhà ăn", },
    { c3: "", c4: "", c5: "25999", name: "Sản xuất sản phẩm khác còn lại bằng kim loại chưa được phân vào đâu", },
    { c3: "261", c4: "2610", c5: "26100", name: "Sản xuất linh kiện điện tử", },
    { c3: "262", c4: "2620", c5: "26200", name: "Sản xuất máy vi tính và thiết bị ngoại vi của máy vi tính", },
    { c3: "263", c4: "2630", c5: "26300", name: "Sản xuất thiết bị truyền thông", },
    { c3: "264", c4: "2640", c5: "26400", name: "Sản xuất sản phẩm điện tử dân dụng", },
    { c3: "265", c4: "", c5: "", name: "Sản xuất thiết bị đo lường, kiểm tra, định hướng và điều khiển; sản xuất đồng hồ", },
    { c3: "", c4: "2651", c5: "26510", name: "Sản xuất thiết bị đo lường, kiểm tra, định hướng và điều khiển", },
    { c3: "", c4: "2652", c5: "26520", name: "Sản xuất đồng hồ", },
    { c3: "266", c4: "2660", c5: "26600", name: "Sản xuất thiết bị bức xạ, thiết bị điện tử trong y học, điện liệu pháp", },
    { c3: "267", c4: "2670", c5: "26700", name: "Sản xuất thiết bị và dụng cụ quang học", },
    { c3: "268", c4: "2680", c5: "26800", name: "Sản xuất băng, đĩa từ tính và quang học", },
    { c3: "271", c4: "2710", c5: "", name: "Sản xuất mô tơ, máy phát, biến thế điện, thiết bị phân phối và điều khiển điện", },
    { c3: "", c4: "", c5: "27101", name: "Sản xuất mô tơ, máy phát", },
    { c3: "", c4: "", c5: "27102", name: "Sản xuất biến thế điện, thiết bị phân phối và điều khiển điện", },
    { c3: "272", c4: "2720", c5: "27200", name: "Sản xuất pin và ắc quy", },
    { c3: "273", c4: "", c5: "", name: "Sản xuất dây và thiết bị dây dẫn ", },
    { c3: "", c4: "2731", c5: "27310", name: "Sản xuất dây cáp, sợi cáp quang học", },
    { c3: "", c4: "2732", c5: "27320", name: "Sản xuất dây, cáp điện và điện tử khác", },
    { c3: "", c4: "2733", c5: "27330", name: "Sản xuất thiết bị dây dẫn điện các loại", },
    { c3: "274", c4: "2740", c5: "27400", name: "Sản xuất thiết bị điện chiếu sáng", },
    { c3: "275", c4: "2750", c5: "27500", name: "Sản xuất đồ điện dân dụng", },
    { c3: "279", c4: "2790", c5: "27900", name: "Sản xuất thiết bị điện khác", },
    { c3: "281", c4: "", c5: "", name: "Sản xuất máy thông dụng", },
    { c3: "", c4: "2811", c5: "28110", name: "Sản xuất động cơ, tua bin (trừ động cơ máy bay, ô tô, mô tô và xe máy)", },
    { c3: "", c4: "2812", c5: "28120", name: "Sản xuất thiết bị sử dụng năng lượng chiết lưu ", },
    { c3: "", c4: "2813", c5: "28130", name: "Sản xuất máy bơm, máy nén, vòi và van khác", },
    { c3: "", c4: "2814", c5: "28140", name: "Sản xuất bi, bánh răng, hộp số, các bộ phận điều khiển và truyền chuyển động", },
    { c3: "", c4: "2815", c5: "28150", name: "Sản xuất lò nướng, lò luyện và lò nung", },
    { c3: "", c4: "2816", c5: "28160", name: "Sản xuất các thiết bị nâng, hạ và bốc xếp", },
    { c3: "", c4: "2817", c5: "28170", name: "Sản xuất máy móc và thiết bị văn phòng (trừ máy vi tính và thiết bị ngoại vi của máy vi tính)", },
    { c3: "", c4: "2818", c5: "28180", name: "Sản xuất dụng cụ cầm tay chạy bằng mô tơ hoặc khí nén ", },
    { c3: "", c4: "2819", c5: "28190", name: "Sản xuất máy thông dụng khác", },
    { c3: "282", c4: "", c5: "", name: "Sản xuất máy chuyên dụng", },
    { c3: "", c4: "2821", c5: "28210", name: "Sản xuất máy nông nghiệp và lâm nghiệp", },
    { c3: "", c4: "2822", c5: "28220", name: "Sản xuất máy công cụ và máy tạo hình kim loại", },
    { c3: "", c4: "2823", c5: "28230", name: "Sản xuất máy luyện kim", },
    { c3: "", c4: "2824", c5: "28240", name: "Sản xuất máy khai thác mỏ và xây dựng", },
    { c3: "", c4: "2825", c5: "28250", name: "Sản xuất máy chế biến thực phẩm, đồ uống và thuốc lá", },
    { c3: "", c4: "2826", c5: "28260", name: "Sản xuất máy cho ngành dệt, may và da", },
    { c3: "", c4: "2829", c5: "", name: "Sản xuất máy chuyên dụng khác", },
    { c3: "", c4: "", c5: "28291", name: "Sản xuất máy sản xuất vật liệu xây dựng", },
    { c3: "", c4: "", c5: "28299", name: "Sản xuất máy chuyên dụng khác chưa được phân vào đâu", },
    { c3: "291", c4: "2910", c5: "29100", name: "Sản xuất ô tô và xe có động cơ khác", },
    { c3: "292", c4: "2920", c5: "29200", name: "Sản xuất thân xe ô tô và xe có động cơ khác, rơ moóc và bán rơ moóc", },
    { c3: "293", c4: "2930", c5: "29300", name: "Sản xuất phụ tùng và bộ phận phụ trợ cho xe ô tô và xe có động cơ khác", },
    { c3: "301", c4: "", c5: "", name: "Đóng tàu và thuyền", },
    { c3: "", c4: "3011", c5: "30110", name: "Đóng tàu và cấu kiện nổi", },
    { c3: "", c4: "3012", c5: "30120", name: "Đóng thuyền, xuồng thể thao và giải trí", },
    { c3: "302", c4: "3020", c5: "30200", name: "Sản xuất đầu máy xe lửa, xe điện và toa xe", },
    { c3: "303", c4: "3030", c5: "30300", name: "Sản xuất máy bay, tàu vũ trụ và máy móc liên quan", },
    { c3: "304", c4: "3040", c5: "30400", name: "Sản xuất xe cơ giới chiến đấu dùng trong quân đội", },
    { c3: "309", c4: "", c5: "", name: "Sản xuất phương tiện và thiết bị vận tải chưa được phân vào đâu", },
    { c3: "", c4: "3091", c5: "30910", name: "Sản xuất mô tô, xe máy", },
    { c3: "", c4: "3092", c5: "30920", name: "Sản xuất xe đạp và xe cho người khuyết tật", },
    { c3: "", c4: "3099", c5: "30990", name: "Sản xuất phương tiện và thiết bị vận tải khác chưa được phân vào đâu", },
    { c3: "310", c4: "3100", c5: "", name: "Sản xuất giường, tủ, bàn, ghế", },
    { c3: "", c4: "", c5: "31001", name: "Sản xuất giường, tủ, bàn, ghế bằng gỗ", },
    { c3: "", c4: "", c5: "31002", name: "Sản xuất giường, tủ, bàn, ghế bằng kim loại", },
    { c3: "", c4: "", c5: "31009", name: "Sản xuất giường, tủ, bàn, ghế bằng vật liệu khác", },
    { c3: "321", c4: "", c5: "", name: "Sản xuất đồ kim hoàn, đồ giả kim hoàn và các chi tiết liên quan", },
    { c3: "", c4: "3211", c5: "32110", name: "Sản xuất đồ kim hoàn và chi tiết liên quan", },
    { c3: "", c4: "3212", c5: "32120", name: "Sản xuất đồ giả kim hoàn và chi tiết liên quan", },
    { c3: "322", c4: "3220", c5: "32200", name: "Sản xuất nhạc cụ ", },
    { c3: "323", c4: "3230", c5: "32300", name: "Sản xuất dụng cụ thể dục, thể thao", },
    { c3: "324", c4: "3240", c5: "32400", name: "Sản xuất đồ chơi, trò chơi", },
    { c3: "325", c4: "3250", c5: "", name: "Sản xuất thiết bị, dụng cụ y tế, nha khoa, chỉnh hình và phục hồi chức năng", },
    { c3: "", c4: "", c5: "32501", name: "Sản xuất thiết bị, dụng cụ y tế, nha khoa", },
    { c3: "", c4: "", c5: "32502", name: "Sản xuất dụng cụ chỉnh hình, phục hồi chức năng", },
    { c3: "329", c4: "3290", c5: "32900", name: "Sản xuất khác chưa được phân vào đâu", },
    { c3: "331", c4: "", c5: "", name: "Sửa chữa và bảo dưỡng máy móc, thiết bị và sản phẩm kim loại đúc sẵn", },
    { c3: "", c4: "3311", c5: "33110", name: "Sửa chữa các sản phẩm kim loại đúc sẵn", },
    { c3: "", c4: "3312", c5: "33120", name: "Sửa chữa máy móc, thiết bị", },
    { c3: "", c4: "3313", c5: "33130", name: "Sửa chữa thiết bị điện tử và quang học", },
    { c3: "", c4: "3314", c5: "33140", name: "Sửa chữa thiết bị điện", },
    { c3: "", c4: "3315", c5: "33150", name: "Sửa chữa và bảo dưỡng phương tiện vận tải (trừ ô tô, mô tô, xe  máy và xe có động cơ khác)", },
    { c3: "", c4: "3319", c5: "33190", name: "Sửa chữa thiết bị khác", },
    { c3: "332", c4: "3320", c5: "33200", name: "Lắp đặt máy móc và thiết bị công nghiệp", },
    { c3: "351", c4: "", c5: "", name: "Sản xuất, truyền tải và phân phối điện", },
    { c3: "", c4: "3511", c5: "", name: "Sản xuất điện", },
    { c3: "", c4: "", c5: "35111", name: "Thủy điện", },
    { c3: "", c4: "", c5: "35112", name: "Nhiệt điện than", },
    { c3: "", c4: "", c5: "35113", name: "Nhiệt điện khí", },
    { c3: "", c4: "", c5: "35114", name: "Điện hạt nhân", },
    { c3: "", c4: "", c5: "35115", name: "Điện gió", },
    { c3: "", c4: "", c5: "35116", name: "Điện mặt trời", },
    { c3: "", c4: "", c5: "35119", name: "Điện khác", },
    { c3: "", c4: "3512", c5: "", name: "Truyền tải và phân phối điện", },
    { c3: "", c4: "", c5: "35121", name: "Truyền tải điện", },
    { c3: "", c4: "", c5: "35122", name: "Phân phối điện", },
    { c3: "352", c4: "3520", c5: "", name: "Sản xuất khí đốt, phân phối nhiên liệu khí bằng đường ống", },
    { c3: "", c4: "", c5: "35201", name: "Sản xuất khí đốt", },
    { c3: "", c4: "", c5: "35202", name: "Phân phối nhiên liệu khí bằng đường ống", },
    { c3: "353", c4: "3530", c5: "", name: "Sản xuất, phân phối hơi nước, nước nóng, điều hoà không khí và sản xuất nước đá", },
    { c3: "", c4: "", c5: "35301", name: "Sản xuất, phân phối hơi nước, nước nóng và điều hòa không khí", },
    { c3: "", c4: "", c5: "35302", name: "Sản xuất nước đá", },
    { c3: "360", c4: "3600", c5: "36000", name: "Khai thác, xử lý và cung cấp nước", },
    { c3: "370", c4: "3700", c5: "", name: "Thoát nước và xử lý nước thải", },
    { c3: "", c4: "", c5: "37001", name: "Thoát nước", },
    { c3: "", c4: "", c5: "37002", name: "Xử lý nước thải", },
    { c3: "381", c4: "", c5: "", name: "Thu gom rác thải", },
    { c3: "", c4: "3811", c5: "38110", name: "Thu gom rác thải không độc hại", },
    { c3: "", c4: "3812", c5: "", name: "Thu gom rác thải độc hại", },
    { c3: "", c4: "", c5: "38121", name: "Thu gom rác thải y tế", },
    { c3: "", c4: "", c5: "38129", name: "Thu gom rác thải độc hại khác", },
    { c3: "382", c4: "", c5: "", name: "Xử lý và tiêu huỷ rác thải", },
    { c3: "", c4: "3821", c5: "38210", name: "Xử lý và tiêu huỷ rác thải không độc hại", },
    { c3: "", c4: "3822", c5: "", name: "Xử lý và tiêu huỷ rác thải độc hại", },
    { c3: "", c4: "", c5: "38221", name: "Xử lý và tiêu hủy rác thải y tế", },
    { c3: "", c4: "", c5: "38229", name: "Xử lý và tiêu hủy rác thải độc hại khác", },
    { c3: "383", c4: "3830", c5: "", name: "Tái chế phế liệu", },
    { c3: "", c4: "", c5: "38301", name: "Tái chế phế liệu kim loại", },
    { c3: "", c4: "", c5: "38302", name: "Tái chế phế liệu phi kim loại", },
    { c3: "390", c4: "3900", c5: "39000", name: "Xử lý ô nhiễm và hoạt động quản lý chất thải khác", },
    { c3: "410", c4: "", c5: "", name: "Xây dựng nhà các loại", },
    { c3: "", c4: "4101", c5: "41010", name: "Xây dựng nhà để ở ", },
    { c3: "", c4: "4102", c5: "41020", name: "Xây dựng nhà không để ở", },
    { c3: "421", c4: "", c5: "", name: "Xây dựng công trình đường sắt và đường bộ", },
    { c3: "", c4: "4211", c5: "", name: "Xây dựng công trình đường sắt ", },
    { c3: "", c4: "", c5: "42110", name: "Xây dựng công trình đường sắt ", },
    { c3: "", c4: "4212", c5: "", name: "Xây dựng công trình đường bộ ", },
    { c3: "", c4: "", c5: "42120", name: "Xây dựng công trình đường bộ ", },
    { c3: "422", c4: "", c5: "", name: "Xây dựng công trình công ích", },
    { c3: "", c4: "4221", c5: "42210", name: "Xây dựng công trình điện", },
    { c3: "", c4: "4222", c5: "42220", name: "Xây dựng công trình cấp, thoát nước", },
    { c3: "", c4: "4223", c5: "42230", name: "Xây dựng công trình viễn thông, thông tin liên lạc", },
    { c3: "", c4: "4229", c5: "42290", name: "Xây dựng công trình công ích khác", },
    { c3: "429", c4: "", c5: "", name: "Xây dựng công trình kỹ thuật dân dụng khác", },
    { c3: "", c4: "4291", c5: "42910", name: "Xây dựng công trình thủy", },
    { c3: "", c4: "4292", c5: "42920", name: "Xây dựng công trình khai khoáng", },
    { c3: "", c4: "4293", c5: "42930", name: "Xây dựng công trình chế biến, chế tạo", },
    { c3: "", c4: "4299", c5: "42990", name: "Xây dựng công trình kỹ thuật dân dụng khác", },
    { c3: "431", c4: "", c5: "", name: "Phá dỡ và chuẩn bị mặt bằng", },
    { c3: "", c4: "4311", c5: "43110", name: "Phá dỡ", },
    { c3: "", c4: "4312", c5: "43120", name: "Chuẩn bị mặt bằng", },
    { c3: "432", c4: "", c5: "", name: "Lắp đặt hệ thống điện, hệ thống cấp thoát nước và lắp đặt xây dựng khác", },
    { c3: "", c4: "4321", c5: "43210", name: "Lắp đặt hệ thống điện", },
    { c3: "", c4: "4322", c5: "", name: "Lắp đặt hệ thống cấp, thoát nước, hệ thống sưởi và điều hoà không khí", },
    { c3: "", c4: "", c5: "43221", name: "Lắp đặt hệ thống cấp, thoát nước", },
    { c3: "", c4: "", c5: "43222", name: "Lắp đặt hệ thống sưởi và điều hòa không khí", },
    { c3: "", c4: "4329", c5: "43290", name: "Lắp đặt hệ thống xây dựng khác", },
    { c3: "433", c4: "4330", c5: "43300", name: "Hoàn thiện công trình xây dựng", },
    { c3: "439", c4: "4390", c5: "43900", name: "Hoạt động xây dựng chuyên dụng khác", },
    { c3: "451", c4: "", c5: "", name: "Bán ô tô và xe có động cơ khác ", },
    { c3: "", c4: "4511", c5: "", name: "Bán buôn ô tô và xe có động cơ khác ", },
    { c3: "", c4: "", c5: "45111", name: "Bán buôn ô tô con (loại 9 chỗ ngồi trở xuống)", },
    { c3: "", c4: "", c5: "45119", name: "Bán buôn xe có động cơ khác", },
    { c3: "", c4: "4512", c5: "45120", name: "Bán lẻ ô tô con (loại 9 chỗ ngồi trở xuống)", },
    { c3: "", c4: "4513", c5: "", name: "Đại lý ô tô và xe có động cơ khác", },
    { c3: "", c4: "", c5: "45131", name: "Đại lý ô tô con (loại 9 chỗ ngồi trở xuống)", },
    { c3: "", c4: "", c5: "45139", name: "Đại lý xe có động cơ khác", },
    { c3: "452", c4: "4520", c5: "45200", name: "Bảo dưỡng, sửa chữa ô tô và xe có động cơ khác", },
    { c3: "453", c4: "4530", c5: "", name: "Bán phụ tùng và các bộ phận phụ trợ của ô tô và xe có động cơ khác", },
    { c3: "", c4: "", c5: "45301", name: "Bán buôn phụ tùng và các bộ phận phụ trợ của ô tô và xe có động cơ khác", },
    { c3: "", c4: "", c5: "45302", name: "Bán lẻ phụ tùng và các bộ phận phụ trợ của ô tô con (loại 9 chỗ ngồi trở xuống)", },
    { c3: "", c4: "", c5: "45303", name: "Đại lý phụ tùng và các bộ phận phụ trợ của ô tô và xe có động cơ khác", },
    { c3: "454", c4: "", c5: "", name: "Bán, bảo dưỡng và sửa chữa mô tô, xe máy, phụ tùng và các bộ phận phụ trợ của mô tô, xe máy", },
    { c3: "", c4: "4541", c5: "", name: "Bán mô tô, xe máy", },
    { c3: "", c4: "", c5: "45411", name: "Bán buôn mô tô, xe máy", },
    { c3: "", c4: "", c5: "45412", name: "Bán lẻ mô tô, xe máy", },
    { c3: "", c4: "", c5: "45413", name: "Đại lý mô tô, xe máy", },
    { c3: "", c4: "4542", c5: "45420", name: "Bảo dưỡng và sửa chữa mô tô, xe máy", },
    { c3: "", c4: "4543", c5: "", name: "Bán phụ tùng và các bộ phận phụ trợ của mô tô, xe máy", },
    { c3: "", c4: "", c5: "45431", name: "Bán buôn phụ tùng và các bộ phận phụ trợ của mô tô, xe máy", },
    { c3: "", c4: "", c5: "45432", name: "Bán lẻ phụ tùng và các bộ phận phụ trợ của mô tô, xe máy", },
    { c3: "", c4: "", c5: "45433", name: "Đại lý phụ tùng và các bộ phận phụ trợ của mô tô, xe máy", },
    { c3: "461", c4: "4610", c5: "", name: "Đại lý, môi giới, đấu giá hàng hóa", },
    { c3: "", c4: "", c5: "46101", name: "Đại lý bán hàng hóa", },
    { c3: "", c4: "", c5: "46102", name: "Môi giới mua bán hàng hóa", },
    { c3: "", c4: "", c5: "46103", name: "Đấu giá hàng hóa", },
    { c3: "462", c4: "4620", c5: "", name: "Bán buôn nông, lâm sản nguyên liệu (trừ gỗ, tre, nứa) và động vật sống", },
    { c3: "", c4: "", c5: "46201", name: "Bán buôn thóc, ngô và các loại hạt ngũ cốc khác", },
    { c3: "", c4: "", c5: "46202", name: "Bán buôn hoa và cây", },
    { c3: "", c4: "", c5: "46203", name: "Bán buôn động vật sống", },
    { c3: "", c4: "", c5: "46204", name: "Bán buôn thức ăn và nguyên liệu làm thức ăn cho gia súc, gia cầm và thủy sản", },
    { c3: "", c4: "", c5: "46209", name: "Bán buôn nông, lâm sản nguyên liệu khác (trừ gỗ, tre, nứa)", },
    { c3: "463", c4: "", c5: "", name: "Bán buôn lương thực, thực phẩm, đồ uống và sản phẩm thuốc lá, thuốc lào", },
    { c3: "", c4: "4631", c5: "46310", name: "Bán buôn gạo, lúa mỳ, hạt ngũ cốc khác, bột mỳ", },
    { c3: "", c4: "4632", c5: "", name: "Bán buôn thực phẩm", },
    { c3: "", c4: "", c5: "46321", name: "Bán buôn thịt và các sản phẩm từ thịt", },
    { c3: "", c4: "", c5: "46322", name: "Bán buôn thủy sản", },
    { c3: "", c4: "", c5: "46323", name: "Bán buôn rau, quả", },
    { c3: "", c4: "", c5: "46324", name: "Bán buôn cà phê", },
    { c3: "", c4: "", c5: "46325", name: "Bán buôn chè", },
    { c3: "", c4: "", c5: "46326", name: "Bán buôn đường, sữa và các sản phẩm sữa, bánh kẹo và các sản phẩm chế biến từ ngũ cốc, bột, tinh bột", },
    { c3: "", c4: "", c5: "46329", name: "Bán buôn thực phẩm khác", },
    { c3: "", c4: "4633", c5: "", name: "Bán buôn đồ uống", },
    { c3: "", c4: "", c5: "46331", name: "Bán buôn đồ uống có cồn", },
    { c3: "", c4: "", c5: "46332", name: "Bán buôn đồ uống không có cồn", },
    { c3: "", c4: "4634", c5: "46340", name: "Bán buôn sản phẩm thuốc lá, thuốc lào ", },
    { c3: "464", c4: "", c5: "", name: "Bán buôn đồ dùng gia đình", },
    { c3: "", c4: "4641", c5: "", name: "Bán buôn vải, hàng may mặc, giày dép", },
    { c3: "", c4: "", c5: "46411", name: "Bán buôn vải", },
    { c3: "", c4: "", c5: "46412", name: "Bán buôn thảm, đệm, chăn, màn, rèm, ga trải giường, gối và hàng dệt khác", },
    { c3: "", c4: "", c5: "46413", name: "Bán buôn hàng may mặc", },
    { c3: "", c4: "", c5: "46414", name: "Bán buôn giày dép", },
    { c3: "", c4: "4649", c5: "", name: "Bán buôn đồ dùng khác cho gia đình", },
    { c3: "", c4: "", c5: "46491", name: "Bán buôn vali, cặp, túi, ví, hàng da và giả da khác", },
    { c3: "", c4: "", c5: "46492", name: "Bán buôn dược phẩm và dụng cụ y tế", },
    { c3: "", c4: "", c5: "46493", name: "Bán buôn nước hoa, hàng mỹ phẩm và chế phẩm vệ sinh", },
    { c3: "", c4: "", c5: "46494", name: "Bán buôn hàng gốm, sứ, thủy tinh", },
    { c3: "", c4: "", c5: "46495", name: "Bán buôn đồ điện gia dụng, đèn và bộ đèn điện", },
    { c3: "", c4: "", c5: "46496", name: "Bán buôn giường, tủ, bàn ghế và đồ dùng nội thất tương tự", },
    { c3: "", c4: "", c5: "46497", name: "Bán buôn sách, báo, tạp chí, văn phòng phẩm", },
    { c3: "", c4: "", c5: "46498", name: "Bán buôn dụng cụ thể dục, thể thao", },
    { c3: "", c4: "", c5: "46499", name: "Bán buôn đồ dùng khác cho gia đình chưa được phân vào đâu", },
    { c3: "465", c4: "", c5: "", name: "Bán buôn máy móc, thiết bị và phụ tùng máy", },
    { c3: "", c4: "4651", c5: "46510", name: "Bán buôn máy vi tính, thiết bị ngoại vi và phần mềm", },
    { c3: "", c4: "4652", c5: "46520", name: "Bán buôn thiết bị và linh kiện điện tử, viễn thông", },
    { c3: "", c4: "4653", c5: "46530", name: "Bán buôn máy móc, thiết bị và phụ tùng máy nông nghiệp ", },
    { c3: "", c4: "4659", c5: "", name: "Bán buôn máy móc, thiết bị và phụ tùng máy khác ", },
    { c3: "", c4: "", c5: "46591", name: "Bán buôn máy móc, thiết bị và phụ tùng máy khai khoáng, xây dựng", },
    { c3: "", c4: "", c5: "46592", name: "Bán buôn máy móc, thiết bị điện, vật liệu điện (máy phát điện, động cơ điện, dây điện và thiết bị khác dùng trong mạch điện)", },
    { c3: "", c4: "", c5: "46593", name: "Bán buôn máy móc, thiết bị và phụ tùng máy dệt, may, da giày", },
    { c3: "", c4: "", c5: "46594", name: "Bán buôn máy móc, thiết bị và phụ tùng máy văn phòng (trừ máy vi tính và thiết bị ngoại vi)", },
    { c3: "", c4: "", c5: "46595", name: "Bán buôn máy móc, thiết bị y tế", },
    { c3: "", c4: "", c5: "46599", name: "Bán buôn máy móc, thiết bị và phụ tùng máy khác chưa được phân vào đâu", },
    { c3: "466", c4: "", c5: "", name: "Bán buôn chuyên doanh khác", },
    { c3: "", c4: "4661", c5: "", name: "Bán buôn nhiên liệu rắn, lỏng, khí và các sản phẩm liên quan", },
    { c3: "", c4: "", c5: "46611", name: "Bán buôn than đá và nhiên liệu rắn khác", },
    { c3: "", c4: "", c5: "46612", name: "Bán buôn dầu thô", },
    { c3: "", c4: "", c5: "46613", name: "Bán buôn xăng dầu và các sản phẩm liên quan", },
    { c3: "", c4: "", c5: "46614", name: "Bán buôn khí đốt và các sản phẩm liên quan", },
    { c3: "", c4: "4662", c5: "", name: "Bán buôn kim loại và quặng kim loại", },
    { c3: "", c4: "", c5: "46621", name: "Bán buôn quặng kim loại", },
    { c3: "", c4: "", c5: "46622", name: "Bán buôn sắt, thép", },
    { c3: "", c4: "", c5: "46623", name: "Bán buôn kim loại khác", },
    { c3: "", c4: "", c5: "46624", name: "Bán buôn vàng, bạc và kim loại quý khác", },
    { c3: "", c4: "4663", c5: "", name: "Bán buôn vật liệu, thiết bị lắp đặt khác trong xây dựng", },
    { c3: "", c4: "", c5: "46631", name: "Bán buôn tre, nứa, gỗ cây và gỗ chế biến", },
    { c3: "", c4: "", c5: "46632", name: "Bán buôn xi măng", },
    { c3: "", c4: "", c5: "46633", name: "Bán buôn gạch xây, ngói, đá, cát, sỏi", },
    { c3: "", c4: "", c5: "46634", name: "Bán buôn kính xây dựng", },
    { c3: "", c4: "", c5: "46635", name: "Bán buôn sơn, vécni", },
    { c3: "", c4: "", c5: "46636", name: "Bán buôn gạch ốp lát và thiết bị vệ sinh", },
    { c3: "", c4: "", c5: "46637", name: "Bán buôn đồ ngũ kim", },
    { c3: "", c4: "", c5: "46639", name: "Bán buôn vật liệu, thiết bị lắp đặt khác trong xây dựng", },
    { c3: "", c4: "4669", c5: "", name: "Bán buôn chuyên doanh khác chưa được phân vào đâu", },
    { c3: "", c4: "", c5: "46691", name: "Bán buôn phân bón, thuốc trừ sâu và hóa chất khác sử dụng trong nông nghiệp", },
    { c3: "", c4: "", c5: "46692", name: "Bán buôn hóa chất khác (trừ loại sử dụng trong nông nghiệp)", },
    { c3: "", c4: "", c5: "46693", name: "Bán buôn chất dẻo dạng nguyên sinh", },
    { c3: "", c4: "", c5: "46694", name: "Bán buôn cao su", },
    { c3: "", c4: "", c5: "46695", name: "Bán buôn tơ, xơ, sợi dệt", },
    { c3: "", c4: "", c5: "46696", name: "Bán buôn phụ liệu may mặc và giày dép", },
    { c3: "", c4: "", c5: "46697", name: "Bán buôn phế liệu, phế thải kim loại, phi kim loại", },
    { c3: "", c4: "", c5: "46699", name: "Bán buôn chuyên doanh khác còn lại chưa được phân vào đâu", },
    { c3: "469", c4: "4690", c5: "46900", name: "Bán buôn tổng hợp ", },
    { c3: "471", c4: "", c5: "", name: "Bán lẻ trong các cửa hàng kinh doanh tổng hợp", },
    { c3: "", c4: "4711", c5: "", name: "Bán lẻ lương thực, thực phẩm, đồ uống, thuốc lá, thuốc lào chiếm tỷ trọng lớn trong các cửa hàng kinh doanh tổng hợp ", },
    { c3: "", c4: "", c5: "47111", name: "Bán lẻ trong siêu thị (Supermarket)", },
    { c3: "", c4: "", c5: "47112", name: "Bán lẻ trong cửa hàng tiện lợi (Minimarket)", },
    { c3: "", c4: "", c5: "47119", name: "Bán lẻ trong cửa hàng kinh doanh tổng hợp khác", },
    { c3: "", c4: "4719", c5: "", name: "Bán lẻ  khác trong các cửa hàng kinh doanh tổng hợp ", },
    { c3: "", c4: "", c5: "47191", name: "Bán lẻ trong siêu thị (Supermarket)", },
    { c3: "", c4: "", c5: "47192", name: "Bán lẻ trong cửa hàng tiện lợi (Minimarket)", },
    { c3: "", c4: "", c5: "47199", name: "Bán lẻ trong cửa hàng kinh doanh tổng hợp khác", },
    { c3: "472", c4: "", c5: "", name: "Bán lẻ lương thực, thực phẩm, đồ uống hoặc thuốc lá, thuốc lào trong các cửa hàng chuyên doanh ", },
    { c3: "", c4: "4721", c5: "47210", name: "Bán lẻ lương thực trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4722", c5: "", name: "Bán lẻ thực phẩm trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47221", name: "Bán lẻ thịt và các sản phẩm thịt trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47222", name: "Bán lẻ thủy sản trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47223", name: "Bán lẻ rau, quả trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47224", name: "Bán lẻ đường, sữa và các sản phẩm sữa, bánh, mứt, kẹo và các sản phẩm chế biến từ ngũ cốc, bột, tinh bột trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47229", name: "Bán lẻ thực phẩm khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4723", c5: "47230", name: "Bán lẻ đồ uống trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4724", c5: "47240", name: "Bán lẻ sản phẩm thuốc lá, thuốc lào trong các cửa hàng chuyên doanh", },
    { c3: "473", c4: "4730", c5: "47300", name: "Bán lẻ nhiên liệu động cơ trong các cửa hàng chuyên doanh", },
    { c3: "474", c4: "", c5: "", name: "Bán lẻ thiết bị công nghệ thông tin liên lạc trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4741", c5: "", name: "Bán lẻ máy vi tính, thiết bị ngoại vi, phần mềm và thiết bị viễn thông trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47411", name: "Bán lẻ máy vi tính, thiết bị ngoại vi, phần mềm trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47412", name: "Bán lẻ thiết bị viễn thông trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4742", c5: "47420", name: "Bán lẻ thiết bị nghe nhìn trong các cửa hàng chuyên doanh", },
    { c3: "475", c4: "", c5: "", name: "Bán lẻ thiết bị gia đình khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4751", c5: "", name: "Bán lẻ vải, len, sợi, chỉ khâu và hàng dệt khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47511", name: "Bán lẻ vải trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47519", name: "Bán lẻ len, sợi, chỉ khâu và hàng dệt khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4752", c5: "", name: "Bán lẻ đồ ngũ kim, sơn, kính và thiết bị lắp đặt khác trong xây dựng trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47521", name: "Bán lẻ đồ ngũ kim trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47522", name: "Bán lẻ sơn, màu, véc ni trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47523", name: "Bán lẻ kính xây dựng trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47524", name: "Bán lẻ xi măng, gạch xây, ngói, đá, cát sỏi, sắt thép và vật liệu xây dựng khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47525", name: "Bán lẻ gạch ốp lát, thiết bị vệ sinh trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47529", name: "Bán lẻ thiết bị lắp đặt khác trong xây dựng trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4753", c5: "47530", name: "Bán lẻ thảm, đệm, chăn, màn, rèm, vật liệu phủ tường và sàn trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4759", c5: "", name: "Bán lẻ đồ điện gia dụng, giường, tủ, bàn, ghế và đồ nội thất tương tự, đèn và bộ đèn điện, đồ dùng gia đình khác chưa được phân vào đâu  trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47591", name: "Bán lẻ đồ điện gia dụng, đèn và bộ đèn điện trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47592", name: "Bán lẻ giường, tủ, bàn, ghế và đồ dùng nội thất tương tự trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47593", name: "Bán lẻ đồ dùng gia đình bằng gốm, sứ, thủy tinh trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47594", name: "Bán lẻ nhạc cụ trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47599", name: "Bán lẻ đồ dùng gia đình khác còn lại chưa được phân vào đâu trong các cửa hàng chuyên doanh", },
    { c3: "476", c4: "", c5: "", name: "Bán lẻ hàng văn hóa, giải trí trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4761", c5: "47610", name: "Bán lẻ sách, báo, tạp chí văn phòng phẩm trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4762", c5: "47620", name: "Bán lẻ băng đĩa âm thanh, hình ảnh (kể cả băng, đĩa trắng) trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4763", c5: "47630", name: "Bán lẻ thiết bị, dụng cụ thể dục, thể thao trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4764", c5: "47640", name: "Bán lẻ trò chơi, đồ chơi trong các cửa hàng chuyên doanh", },
    { c3: "477", c4: "", c5: "", name: "Bán lẻ hàng hóa khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4771", c5: "", name: "Bán lẻ hàng may mặc, giày dép, hàng da và giả da trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47711", name: "Bán lẻ hàng may mặc trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47712", name: "Bán lẻ giày dép trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47713", name: "Bán lẻ cặp, túi, ví, hàng da và giả da khác trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4772", c5: "", name: "Bán lẻ thuốc, dụng cụ y tế, mỹ phẩm và vật phẩm vệ sinh trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47721", name: "Bán lẻ dược phẩm, dụng cụ y tế trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47722", name: "Bán lẻ nước hoa, mỹ phẩm và vật phẩm vệ sinh trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47723", name: "Bán thuốc đông y, bán thuốc nam trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4773", c5: "", name: "Bán lẻ hàng hóa khác mới trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47731", name: "Bán lẻ hoa, cây cảnh, cá cảnh, chim cảnh, vật nuôi cảnh trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47732", name: "Bán lẻ vàng, bạc, đá quý và đá bán quý, đồ trang sức trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47733", name: "Bán lẻ hàng lưu niệm, hàng đan lát, hàng thủ công mỹ nghệ trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47734", name: "Bán lẻ tranh, ảnh và các tác phẩm nghệ thuật khác (trừ đồ cổ) trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47735", name: "Bán lẻ dầu hỏa, gas, than nhiên liệu dùng cho gia đình trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47736", name: "Bán lẻ đồng hồ, kính mắt trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47737", name: "Bán lẻ máy ảnh, phim ảnh và vật liệu ảnh trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47738", name: "Bán lẻ xe đạp và phụ tùng trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47739", name: "Bán lẻ hàng hóa khác mới chưa được phân vào đâu trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "4774", c5: "", name: "Bán lẻ hàng hóa đã qua sử dụng trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47741", name: "Bán lẻ hàng may mặc đã qua sử dụng trong các cửa hàng chuyên doanh", },
    { c3: "", c4: "", c5: "47749", name: "Bán lẻ hàng hóa khác đã qua sử dụng trong các cửa hàng chuyên doanh", },
    { c3: "478", c4: "", c5: "", name: "Bán lẻ lưu động hoặc bán tại chợ", },
    { c3: "", c4: "4781", c5: "", name: "Bán lẻ lương thực, thực phẩm, đồ uống, thuốc lá, thuốc lào lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47811", name: "Bán lẻ lương thực lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47812", name: "Bán lẻ thực phẩm khô, thực phẩm công nghiệp, đường sữa bánh kẹo lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47813", name: "Bán lẻ đồ uống lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47814", name: "Bán lẻ sản phẩm thuốc lá, thuốc lào lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47815", name: "Bán lẻ thịt gia súc, gia cầm tươi sống, đông lạnh lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47816", name: "Bán lẻ thủy sản tươi sống, đông lạnh lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47817", name: "Bán lẻ rau quả lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47818", name: "Bán lẻ thực phẩm chín lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47819", name: "Bán lẻ thực phẩm loại khác chưa được phân vào đâu", },
    { c3: "", c4: "4782", c5: "", name: "Bán lẻ hàng dệt, may sẵn, giày dép lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47821", name: "Bán lẻ hàng dệt lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47822", name: "Bán lẻ hàng may mặc lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47823", name: "Bán lẻ giày dép lưu động hoặc tại chợ", },
    { c3: "", c4: "4783", c5: "", name: "Bán lẻ thiết bị công nghệ thông tin liên lạc lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47830", name: "Bán lẻ thiết bị công nghệ thông tin liên lạc lưu động hoặc tại chợ", },
    { c3: "", c4: "4784", c5: "", name: "Bản lẻ thiết bị gia đình khác lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47841", name: "Bán lẻ đồ ngũ kim, sơn, kính và thiết bị lắp đặt khác trong xây dựng lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47842", name: "Bán lẻ thảm, đệm, chăn, màn, rèm, vật liệu phủ tường và sàn lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47843", name: "Bán lẻ đồ điện gia dụng, giường, tủ, bàn, ghế và đồ nội thất tương tự, đèn và bộ đèn điện, đồ dùng gia đình khác chưa được phân vào đâu lưu động hoặc tại chợ", },
    { c3: "", c4: "4785", c5: "", name: "Bán lẻ hàng văn hóa, giải trí lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47850", name: "Bán lẻ hàng văn hóa, giải trí lưu động hoặc tại chợ", },
    { c3: "", c4: "4789", c5: "", name: "Bán  lẻ hàng hóa khác lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47891", name: "Bán lẻ mỹ phẩm và vật phẩm vệ sinh lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47892", name: "Bán lẻ hàng gốm sứ, thủy tinh lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47893", name: "Bán lẻ hoa tươi, cây cảnh lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47894", name: "Bán lẻ vàng, bạc, đá quý và đá bán quý, đồ trang sức lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47895", name: "Bán lẻ hàng lưu niệm, hàng đan lát, hàng thủ công mỹ nghệ lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47896", name: "Bán lẻ đồng hồ, kính mắt lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47897", name: "Bán lẻ xe đạp và phụ tùng lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47898", name: "Bán lẻ hàng hóa đã qua sử dụng lưu động hoặc tại chợ", },
    { c3: "", c4: "", c5: "47899", name: "Bán lẻ hàng hóa khác chưa được phân vào đâu, lưu động hoặc tại chợ", },
    { c3: "479", c4: "", c5: "", name: "Bán lẻ hình thức khác (trừ bán lẻ tại cửa hàng, lưu động hoặc tại chợ)", },
    { c3: "", c4: "4791", c5: "47910", name: "Bán lẻ theo yêu cầu đặt hàng qua bưu điện hoặc internet", },
    { c3: "", c4: "4799", c5: "47990", name: "Bán lẻ hình thức khác chưa được phân vào đâu", },
    { c3: "491", c4: "", c5: "", name: "Vận tải đường sắt", },
    { c3: "", c4: "4911", c5: "49110", name: "Vận tải hành khách đường sắt", },
    { c3: "", c4: "4912", c5: "49120", name: "Vận tải hàng hóa đường sắt", },
    { c3: "492", c4: "", c5: "", name: "Vận tải hành khách bằng xe buýt", },
    { c3: "", c4: "4921", c5: "49210", name: "Vận tải hành khách bằng xe buýt trong nội thành", },
    { c3: "", c4: "4922", c5: "49220", name: "Vận tải hành khách bằng xe buýt giữa nội thành và ngoại thành, liên tỉnh", },
    { c3: "", c4: "4929", c5: "49290", name: "Vận tải hành khách bằng xe buýt loại khác", },
    { c3: "493", c4: "", c5: "", name: "Vận tải đường bộ khác", },
    { c3: "", c4: "4931", c5: "", name: "Vận tải hành khách đường bộ trong nội thành, ngoại thành (trừ vận tải bằng xe buýt)", },
    { c3: "", c4: "", c5: "49311", name: "Vận tải hành khách bằng hệ thống đường sắt ngầm hoặc đường sắt trên cao", },
    { c3: "", c4: "", c5: "49312", name: "Vận tải hành khách bằng taxi", },
    { c3: "", c4: "", c5: "49313", name: "Vận tải hành khách bằng mô tô, xe máy và xe có động cơ khác", },
    { c3: "", c4: "", c5: "49319", name: "Vận tải hành khách đường bộ loại khác trong nội thành, ngoại thành (trừ xe buýt)", },
    { c3: "", c4: "4932", c5: "", name: "Vận tải hành khách đường bộ khác", },
    { c3: "", c4: "", c5: "49321", name: "Vận tải hành khách bằng xe khách nội tỉnh, liên tỉnh", },
    { c3: "", c4: "", c5: "49329", name: "Vận tải hành khách đường bộ khác chưa được phân vào đâu", },
    { c3: "", c4: "4933", c5: "", name: "Vận tải hàng hóa bằng đường bộ", },
    { c3: "", c4: "", c5: "49331", name: "Vận tải hàng hóa bằng ô tô chuyên dụng", },
    { c3: "", c4: "", c5: "49332", name: "Vận tải hàng hóa bằng ô tô loại khác (trừ ô tô chuyên dụng)", },
    { c3: "", c4: "", c5: "49333", name: "Vận tải hàng hóa bằng xe có động cơ loại khác", },
    { c3: "", c4: "", c5: "49334", name: "Vận tải hàng hóa bằng xe thô sơ", },
    { c3: "", c4: "", c5: "49339", name: "Vận tải hàng hóa bằng phương tiện đường bộ khác", },
    { c3: "494", c4: "4940", c5: "49400", name: "Vận tải đường ống", },
    { c3: "501", c4: "", c5: "", name: "Vận tải ven biển và viễn dương", },
    { c3: "", c4: "5011", c5: "", name: "Vận tải hành khách ven biển và viễn dương", },
    { c3: "", c4: "", c5: "50111", name: "Vận tải hành khách ven biển", },
    { c3: "", c4: "", c5: "50112", name: "Vận tải hành khách viễn dương", },
    { c3: "", c4: "5012", c5: "", name: "Vận tải hàng hóa ven biển và viễn dương", },
    { c3: "", c4: "", c5: "50121", name: "Vận tải hàng hóa ven biển", },
    { c3: "", c4: "", c5: "50122", name: "Vận tải hàng hóa viễn dương", },
    { c3: "502", c4: "", c5: "", name: "Vận tải đường thuỷ nội địa ", },
    { c3: "", c4: "5021", c5: "", name: "Vận tải hành khách đường thuỷ nội địa ", },
    { c3: "", c4: "", c5: "50211", name: "Vận tải hành khách đường thuỷ nội địa bằng phương tiện cơ giới", },
    { c3: "", c4: "", c5: "50212", name: "Vận tải hành khách đường thủy nội địa bằng phương tiện thô sơ", },
    { c3: "", c4: "5022", c5: "", name: "Vận tải hàng hóa đường thuỷ nội địa ", },
    { c3: "", c4: "", c5: "50221", name: "Vận tải hàng hóa đường thuỷ nội địa bằng phương tiện cơ giới", },
    { c3: "", c4: "", c5: "50222", name: "Vận tải hàng hóa đường thuỷ nội địa bằng phương tiện thô sơ", },
    { c3: "511", c4: "5110", c5: "", name: "Vận tải hành khách hàng không", },
    { c3: "", c4: "", c5: "51101", name: "Vận tải hành khách hàng không theo tuyến và lịch trình cố định", },
    { c3: "", c4: "", c5: "51109", name: "Vận tải hành khách hàng không loại khác", },
    { c3: "512", c4: "5120", c5: "", name: "Vận tải hàng hóa hàng không", },
    { c3: "", c4: "", c5: "51201", name: "Vận tải hàng hóa hàng không theo tuyến và lịch trình cố định", },
    { c3: "", c4: "", c5: "51209", name: "Vận tải hàng hóa hàng không loại khác", },
    { c3: "521", c4: "5210", c5: "", name: "Kho bãi và lưu giữ hàng hóa", },
    { c3: "", c4: "", c5: "52101", name: "Kho bãi và lưu giữ hàng hóa trong kho ngoại quan", },
    { c3: "", c4: "", c5: "52102", name: "Kho bãi và lưu giữ hàng hóa trong kho đông lạnh (trừ kho ngoại quan)", },
    { c3: "", c4: "", c5: "52109", name: "Kho bãi và lưu giữ hàng hóa trong kho loại khác", },
    { c3: "522", c4: "", c5: "", name: "Hoạt động dịch vụ hỗ trợ cho vận tải", },
    { c3: "", c4: "5221", c5: "", name: "Hoạt động dịch vụ hỗ trợ trực tiếp cho vận tải đường sắt ", },
    { c3: "", c4: "", c5: "52210", name: "Hoạt động dịch vụ hỗ trợ trực tiếp cho vận tải đường sắt ", },
    { c3: "", c4: "5222", c5: "", name: "Hoạt động dịch vụ hỗ trợ trực tiếp cho vận tải đường thủy", },
    { c3: "", c4: "", c5: "52221", name: "Hoạt động điều hành cảng biển", },
    { c3: "", c4: "", c5: "52222", name: "Hoạt động dịch vụ hỗ trợ liên quan đến vận tải ven biển và viễn dương", },
    { c3: "", c4: "", c5: "52223", name: "Hoạt động điều hành cảng đường thủy nội địa", },
    { c3: "", c4: "", c5: "52224", name: "Hoạt động dịch vụ hỗ trợ liên quan đến vận tải đường thủy nội địa", },
    { c3: "", c4: "5223", c5: "", name: "Hoạt động dịch vụ hỗ trợ trực tiếp cho vận tải hàng không", },
    { c3: "", c4: "", c5: "52231", name: "Dịch vụ điều hành bay", },
    { c3: "", c4: "", c5: "52232", name: "Dịch vụ điều hành hoạt động cảng hàng không", },
    { c3: "", c4: "", c5: "52239", name: "Hoạt động dịch vụ hỗ trợ liên quan đến vận tải hàng không", },
    { c3: "", c4: "5224", c5: "", name: "Bốc xếp hàng hóa", },
    { c3: "", c4: "", c5: "52241", name: "Bốc xếp hàng hóa ga đường sắt", },
    { c3: "", c4: "", c5: "52242", name: "Bốc xếp hàng hóa đường bộ", },
    { c3: "", c4: "", c5: "52243", name: "Bốc xếp hàng hóa cảng biển", },
    { c3: "", c4: "", c5: "52244", name: "Bốc xếp hàng hóa cảng sông", },
    { c3: "", c4: "", c5: "52245", name: "Bốc xếp hàng hóa cảng hàng không", },
    { c3: "", c4: "", c5: "52249", name: "Bốc xếp hàng hóa loại khác", },
    { c3: "", c4: "5225", c5: "", name: "Hoạt động dịch vụ hỗ trợ trực tiếp cho vận tải đường bộ", },
    { c3: "", c4: "", c5: "52251", name: "Hoạt động điều hành bến xe", },
    { c3: "", c4: "", c5: "52252", name: "Hoạt động quản lý, điều hành đường cao tốc, cầu, hầm đường bộ", },
    { c3: "", c4: "", c5: "52253", name: "Hoạt động quản lý bãi đỗ, trông giữ phương tiện đường bộ", },
    { c3: "", c4: "", c5: "52259", name: "Hoạt động dịch vụ khác hỗ trợ liên quan đến vận tải đường bộ", },
    { c3: "", c4: "5229", c5: "", name: "Hoạt động dịch vụ hỗ trợ khác liên quan đến vận tải", },
    { c3: "", c4: "", c5: "52291", name: "Dịch vụ đại lý, giao nhận vận chuyển", },
    { c3: "", c4: "", c5: "52292", name: "Logistics", },
    { c3: "", c4: "", c5: "52299", name: "Dịch vụ hỗ trợ khác liên quan đến vận tải chưa được phân vào đâu", },
    { c3: "531", c4: "5310", c5: "53100", name: "Bưu chính ", },
    { c3: "532", c4: "5320", c5: "53200", name: "Chuyển phát", },
    { c3: "551", c4: "5510", c5: "", name: "Dịch vụ lưu trú ngắn ngày", },
    { c3: "", c4: "", c5: "55101", name: "Khách sạn", },
    { c3: "", c4: "", c5: "55102", name: "Biệt thự hoặc căn hộ kinh doanh dịch vụ lưu trú ngắn ngày", },
    { c3: "", c4: "", c5: "55103", name: "Nhà khách, nhà nghỉ kinh doanh dịch vụ lưu trú ngắn ngày", },
    { c3: "", c4: "", c5: "55104", name: "Nhà trọ, phòng trọ và các cơ sở lưu trú ngắn ngày tương tự", },
    { c3: "559", c4: "5590", c5: "", name: "Cơ sở lưu trú khác", },
    { c3: "", c4: "", c5: "55901", name: "Ký túc xá học sinh, sinh viên", },
    { c3: "", c4: "", c5: "55902", name: "Chỗ nghỉ trọ trên xe lưu động, lều quán, trại dùng để nghỉ tạm", },
    { c3: "", c4: "", c5: "55909", name: "Cơ sở lưu trú khác chưa được phân vào đâu", },
    { c3: "561", c4: "5610", c5: "", name: "Nhà hàng và các dịch vụ ăn uống phục vụ lưu động", },
    { c3: "", c4: "", c5: "56101", name: "Nhà hàng, quán ăn, hàng ăn uống (trừ cửa hàng ăn uống thuộc chuỗi cửa hàng ăn nhanh)", },
    { c3: "", c4: "", c5: "56102", name: "Cửa hàng ăn uống thuộc chuỗi cửa hàng ăn nhanh", },
    { c3: "", c4: "", c5: "56109", name: "Dịch vụ ăn uống phục vụ lưu động khác", },
    { c3: "562", c4: "", c5: "", name: "Cung cấp dịch vụ ăn uống theo hợp đồng không thường xuyên và dịch vụ ăn uống khác", },
    { c3: "", c4: "5621", c5: "56210", name: "Cung cấp dịch vụ ăn uống theo hợp đồng không thường xuyên với khách hàng ", },
    { c3: "", c4: "5629", c5: "56290", name: "Dịch vụ ăn uống khác", },
    { c3: "563", c4: "5630", c5: "", name: "Dịch vụ phục vụ đồ uống", },
    { c3: "", c4: "", c5: "56301", name: "Quán rượu, bia, quầy bar", },
    { c3: "", c4: "", c5: "56302", name: "Quán cà phê, giải khát", },
    { c3: "", c4: "", c5: "56309", name: "Dịch vụ phục vụ đồ uống khác", },
    { c3: "581", c4: "", c5: "", name: "Xuất bản sách, ấn phẩm định kỳ và các hoạt động xuất bản khác", },
    { c3: "", c4: "5811", c5: "", name: "Xuất bản sách", },
    { c3: "", c4: "", c5: "58111", name: "Xuất bản sách trực tuyến", },
    { c3: "", c4: "", c5: "58112", name: "Xuất bản sách khác", },
    { c3: "", c4: "5812", c5: "", name: "Xuất bản các danh mục chỉ dẫn và địa chỉ", },
    { c3: "", c4: "", c5: "58121", name: "Xuất bản các danh mục chỉ dẫn và địa chỉ trực tuyến", },
    { c3: "", c4: "", c5: "58122", name: "Xuất bản các danh mục chỉ dẫn và địa chỉ khác", },
    { c3: "", c4: "5813", c5: "", name: "Xuất bản báo, tạp chí và các ấn phẩm định kỳ ", },
    { c3: "", c4: "", c5: "58131", name: "Xuất bản báo, tạp chí và các ấn phẩm định kỳ trực tuyến", },
    { c3: "", c4: "", c5: "58132", name: "Xuất bản báo, tạp chí và các ấn phẩm định kỳ khác", },
    { c3: "", c4: "5819", c5: "", name: "Hoạt động xuất bản khác", },
    { c3: "", c4: "", c5: "58191", name: "Hoạt động xuất bản trực tuyến khác", },
    { c3: "", c4: "", c5: "58192", name: "Hoạt động xuất bản khác", },
    { c3: "582", c4: "5820", c5: "58200", name: "Xuất bản phần mềm", },
    { c3: "591", c4: "", c5: "", name: "Hoạt động điện ảnh và sản xuất chương trình truyền hình", },
    { c3: "", c4: "5911", c5: "", name: "Hoạt động sản xuất phim điện ảnh, phim video và chương trình truyền hình", },
    { c3: "", c4: "", c5: "59111", name: "Hoạt động sản xuất phim điện ảnh", },
    { c3: "", c4: "", c5: "59112", name: "Hoạt động sản xuất phim video", },
    { c3: "", c4: "", c5: "59113", name: "Hoạt động sản xuất chương trình truyền hình", },
    { c3: "", c4: "5912", c5: "59120", name: "Hoạt động hậu kỳ", },
    { c3: "", c4: "5913", c5: "59130", name: "Hoạt động phát hành phim điện ảnh, phim video và chương trình truyền hình", },
    { c3: "", c4: "5914", c5: "", name: "Hoạt động chiếu phim", },
    { c3: "", c4: "", c5: "59141", name: "Hoạt động chiếu phim cố định", },
    { c3: "", c4: "", c5: "59142", name: "Hoạt động chiếu phim lưu động", },
    { c3: "592", c4: "5920", c5: "59200", name: "Hoạt động ghi âm và xuất bản âm nhạc", },
    { c3: "601", c4: "6010", c5: "60100", name: "Hoạt động phát thanh", },
    { c3: "602", c4: "", c5: "", name: "Hoạt động truyền hình và cung cấp chương trình thuê bao", },
    { c3: "", c4: "6021", c5: "60210", name: "Hoạt động truyền hình", },
    { c3: "", c4: "6022", c5: "60220", name: "Chương trình cáp, vệ tinh và các chương trình thuê bao khác", },
    { c3: "611", c4: "6110", c5: "", name: "Hoạt động viễn thông có dây", },
    { c3: "", c4: "", c5: "61101", name: "Hoạt động cung cấp trực tiếp dịch vụ viễn thông có dây", },
    { c3: "", c4: "", c5: "61102", name: "Hoạt động cung cấp dịch vụ viễn thông có dây sử dụng quyền truy cập hạ tầng viễn thông của đơn vị khác", },
    { c3: "612", c4: "6120", c5: "", name: "Hoạt động viễn thông không dây", },
    { c3: "", c4: "", c5: "61201", name: "Hoạt động cung cấp trực tiếp dịch vụ viễn thông không dây", },
    { c3: "", c4: "", c5: "61202", name: "Hoạt động cung cấp dịch vụ viễn thông không dây sử dụng quyền truy cập hạ tầng viễn thông của đơn vị khác", },
    { c3: "613", c4: "6130", c5: "61300", name: "Hoạt động viễn thông vệ tinh", },
    { c3: "619", c4: "6190", c5: "", name: "Hoạt động viễn thông khác", },
    { c3: "", c4: "", c5: "61901", name: "Hoạt động của các điểm truy cập internet", },
    { c3: "", c4: "", c5: "61909", name: "Hoạt động viễn thông khác chưa được phân vào đâu", },
    { c3: "620", c4: "", c5: "", name: "Lập trình máy vi tính, dịch vụ tư vấn và các hoạt động khác liên quan đến máy vi tính", },
    { c3: "", c4: "6201", c5: "62010", name: "Lập trình máy vi tính", },
    { c3: "", c4: "6202", c5: "62020", name: "Tư vấn máy vi tính và quản trị hệ thống máy vi tính", },
    { c3: "", c4: "6209", c5: "62090", name: "Hoạt động dịch vụ công nghệ thông tin và dịch vụ khác liên quan đến máy vi tính ", },
    { c3: "631", c4: "", c5: "", name: "Xử lý dữ liệu, cho thuê và các hoạt động liên quan; cổng thông tin", },
    { c3: "", c4: "6311", c5: "63110", name: "Xử lý dữ liệu, cho thuê và các hoạt động liên quan", },
    { c3: "", c4: "6312", c5: "63120", name: "Cổng thông tin", },
    { c3: "639", c4: "", c5: "", name: "Dịch vụ thông tin khác", },
    { c3: "", c4: "6391", c5: "63910", name: "Hoạt động thông tấn", },
    { c3: "", c4: "6399", c5: "63990", name: "Dịch vụ thông tin khác chưa được phân vào đâu", },
    { c3: "641", c4: "", c5: "", name: "Hoạt động trung gian tiền tệ", },
    { c3: "", c4: "6411", c5: "64110", name: "Hoạt động ngân hàng trung ương", },
    { c3: "", c4: "6419", c5: "64190", name: "Hoạt động trung gian tiền tệ khác", },
    { c3: "642", c4: "6420", c5: "64200", name: "Hoạt động công ty nắm giữ tài sản", },
    { c3: "643", c4: "6430", c5: "64300", name: "Hoạt động quỹ tín thác, các quỹ và các tổ chức tài chính khác", },
    { c3: "649", c4: "", c5: "", name: "Hoạt động dịch vụ tài chính khác (trừ bảo hiểm và bảo hiểm xã hội) ", },
    { c3: "", c4: "6491", c5: "64910", name: "Hoạt động cho thuê tài chính", },
    { c3: "", c4: "6492", c5: "64920", name: "Hoạt động cấp tín dụng khác", },
    { c3: "", c4: "6499", c5: "64990", name: "Hoạt động dịch vụ tài chính khác chưa được phân vào đâu (trừ bảo hiểm và bảo hiểm xã hội) ", },
    { c3: "651", c4: "", c5: "", name: "Bảo hiểm", },
    { c3: "", c4: "6511", c5: "65110", name: "Bảo hiểm nhân thọ", },
    { c3: "", c4: "6512", c5: "", name: "Bảo hiểm phi nhân thọ ", },
    { c3: "", c4: "", c5: "65120", name: "Bảo hiểm phi nhân thọ ", },
    { c3: "", c4: "6513", c5: "", name: "Bảo hiểm sức khỏe", },
    { c3: "", c4: "", c5: "65131", name: "Bảo hiểm y tế", },
    { c3: "", c4: "", c5: "65139", name: "Bảo hiểm sức khỏe khác", },
    { c3: "652", c4: "6520", c5: "65200", name: "Tái bảo hiểm", },
    { c3: "653", c4: "6530", c5: "65300", name: "Bảo hiểm xã hội", },
    { c3: "661", c4: "", c5: "", name: "Hoạt động hỗ trợ dịch vụ tài chính (trừ bảo hiểm và bảo hiểm xã hội)", },
    { c3: "", c4: "6611", c5: "66110", name: "Quản lý thị trường tài chính", },
    { c3: "", c4: "6612", c5: "66120", name: "Môi giới hợp đồng hàng hoá và chứng khoán", },
    { c3: "", c4: "6619", c5: "66190", name: "Hoạt động hỗ trợ dịch vụ tài chính chưa được phân vào đâu", },
    { c3: "662", c4: "", c5: "", name: "Hoạt động hỗ trợ bảo hiểm và bảo hiểm xã hội", },
    { c3: "", c4: "6621", c5: "66210", name: "Đánh giá rủi ro và thiệt hại", },
    { c3: "", c4: "6622", c5: "66220", name: "Hoạt động của đại lý và môi giới bảo hiểm", },
    { c3: "", c4: "6629", c5: "66290", name: "Hoạt động hỗ trợ khác cho bảo hiểm và bảo hiểm xã hội", },
    { c3: "663", c4: "6630", c5: "66300", name: "Hoạt động quản lý quỹ", },
    { c3: "681", c4: "6810", c5: "", name: "Kinh doanh bất động sản, quyền sử dụng đất thuộc chủ sở hữu, chủ sử dụng hoặc đi thuê ", },
    { c3: "", c4: "", c5: "68101", name: "Mua, bán nhà ở và quyền sử dụng đất ở", },
    { c3: "", c4: "", c5: "68102", name: "Mua, bán nhà và quyền sử dụng đất không để ở", },
    { c3: "", c4: "", c5: "68103", name: "Cho thuê, điều hành, quản lý nhà và đất ở", },
    { c3: "", c4: "", c5: "68104", name: "Cho thuê, điều hành, quản lý nhà và đất không để ở", },
    { c3: "", c4: "", c5: "68109", name: "Kinh doanh bất động sản khác", },
    { c3: "682", c4: "6820", c5: "", name: "Tư vấn, môi giới, đấu giá bất động sản, đấu giá quyền sử dụng đất", },
    { c3: "", c4: "", c5: "68201", name: "Tư vấn, môi giới bất động sản, quyền sử dụng đất", },
    { c3: "", c4: "", c5: "68202", name: "Đấu giá bất động sản, quyền sử dụng đất", },
    { c3: "691", c4: "6910", c5: "", name: "Hoạt động pháp luật", },
    { c3: "", c4: "", c5: "69101", name: "Hoạt động đại diện, tư vấn pháp luật", },
    { c3: "", c4: "", c5: "69102", name: "Hướng dẫn chung và tư vấn, chuẩn bị các tài liệu pháp lý", },
    { c3: "", c4: "", c5: "69109", name: "Hoạt động pháp luật khác", },
    { c3: "692", c4: "6920", c5: "69200", name: "Hoạt động liên quan đến kế toán, kiểm toán và tư vấn về thuế", },
    { c3: "701", c4: "7010", c5: "70100", name: "Hoạt động của trụ sở văn phòng ", },
    { c3: "702", c4: "7020", c5: "70200", name: "Hoạt động tư vấn quản lý", },
    { c3: "711", c4: "7110", c5: "", name: "Hoạt động kiến trúc và tư vấn kỹ thuật có liên quan", },
    { c3: "", c4: "", c5: "71101", name: "Hoạt động kiến trúc", },
    { c3: "", c4: "", c5: "71102", name: "Hoạt động đo đạc và bản đồ", },
    { c3: "", c4: "", c5: "71103", name: "Hoạt động thăm dò địa chất, nguồn nước", },
    { c3: "", c4: "", c5: "71109", name: "Hoạt động tư vấn kỹ thuật có liên quan khác", },
    { c3: "712", c4: "7120", c5: "71200", name: "Kiểm tra và phân tích kỹ thuật", },
    { c3: "721", c4: "", c5: "", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học tự nhiên và kỹ thuật", },
    { c3: "", c4: "7211", c5: "72110", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học tự nhiên", },
    { c3: "", c4: "7212", c5: "72120", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học kỹ thuật và công nghệ", },
    { c3: "", c4: "7213", c5: "72130", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học y, dược", },
    { c3: "", c4: "7214", c5: "72140", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học nông nghiệp", },
    { c3: "722", c4: "", c5: "", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học xã hội và nhân văn", },
    { c3: "", c4: "7221", c5: "72210", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học xã hội", },
    { c3: "", c4: "7222", c5: "72220", name: "Nghiên cứu khoa học và phát triển công nghệ trong lĩnh vực khoa học nhân văn", },
    { c3: "731", c4: "7310", c5: "73100", name: "Quảng cáo", },
    { c3: "732", c4: "7320", c5: "73200", name: "Nghiên cứu thị trường và thăm dò dư luận", },
    { c3: "741", c4: "7410", c5: "74100", name: "Hoạt động thiết kế chuyên dụng", },
    { c3: "742", c4: "7420", c5: "74200", name: "Hoạt động nhiếp ảnh", },
    { c3: "749", c4: "7490", c5: "", name: "Hoạt động chuyên môn, khoa học và công nghệ khác chưa được phân vào đâu", },
    { c3: "", c4: "", c5: "74901", name: "Hoạt động khí tượng thủy văn", },
    { c3: "", c4: "", c5: "74909", name: "Hoạt động chuyên môn, khoa học và công nghệ khác còn lại chưa được phân vào đâu", },
    { c3: "750", c4: "7500", c5: "75000", name: "Hoạt động thú y", },
    { c3: "771", c4: "7710", c5: "", name: "Cho thuê xe có động cơ", },
    { c3: "", c4: "", c5: "77101", name: "Cho thuê ô tô", },
    { c3: "", c4: "", c5: "77109", name: "Cho thuê xe có động cơ khác", },
    { c3: "772", c4: "", c5: "", name: "Cho thuê đồ dùng cá nhân và gia đình", },
    { c3: "", c4: "7721", c5: "77210", name: "Cho thuê thiết bị thể thao, vui chơi giải trí", },
    { c3: "", c4: "7722", c5: "77220", name: "Cho thuê băng, đĩa video", },
    { c3: "", c4: "7729", c5: "77290", name: "Cho thuê đồ dùng cá nhân và gia đình khác", },
    { c3: "773", c4: "7730", c5: "", name: "Cho thuê máy móc, thiết bị và đồ dùng hữu hình khác không kèm người điều khiển", },
    { c3: "", c4: "", c5: "77301", name: "Cho thuê máy móc, thiết bị nông, lâm nghiệp không kèm người điều khiển", },
    { c3: "", c4: "", c5: "77302", name: "Cho thuê máy móc, thiết bị xây dựng không kèm người điều khiển", },
    { c3: "", c4: "", c5: "77303", name: "Cho thuê máy móc, thiết bị văn phòng (kể cả máy vi tính) không kèm người điều khiển", },
    { c3: "", c4: "", c5: "77304", name: "Cho thuê máy bay, phương tiện bay không kèm người điều khiển", },
    { c3: "", c4: "", c5: "77305", name: "Cho thuê tàu, thuyền và kết cấu nổi không kèm người điều khiển", },
    { c3: "", c4: "", c5: "77309", name: "Cho thuê máy móc, thiết bị và đồ dùng hữu hình khác, không kèm người điều khiển chưa được phân vào đâu", },
    { c3: "774", c4: "7740", c5: "77400", name: "Cho thuê tài sản vô hình phi tài chính", },
    { c3: "781", c4: "7810", c5: "78100", name: "Hoạt động của các trung tâm, đại lý tư vấn, giới thiệu và môi giới lao động, việc làm", },
    { c3: "782", c4: "7820", c5: "78200", name: "Cung ứng lao động tạm thời", },
    { c3: "783", c4: "7830", c5: "", name: "Cung ứng và quản lý nguồn lao động ", },
    { c3: "", c4: "", c5: "78301", name: "Cung ứng và quản lý nguồn lao động trong nước", },
    { c3: "", c4: "", c5: "78302", name: "Cung ứng và quản lý nguồn lao động đi làm việc ở nước ngoài", },
    { c3: "791", c4: "", c5: "", name: "Hoạt động của các đại lý du lịch, kinh doanh tua du lịch ", },
    { c3: "", c4: "7911", c5: "79110", name: "Đại lý du lịch", },
    { c3: "", c4: "7912", c5: "79120", name: "Điều hành tua du lịch", },
    { c3: "799", c4: "7990", c5: "79900", name: "Dịch vụ đặt chỗ và các dịch vụ hỗ trợ liên quan đến quảng bá và tổ chức tua du lịch", },
    { c3: "801", c4: "8010", c5: "80100", name: "Hoạt động bảo vệ tư nhân", },
    { c3: "802", c4: "8020", c5: "80200", name: "Dịch vụ hệ thống bảo đảm an toàn", },
    { c3: "803", c4: "8030", c5: "80300", name: "Dịch vụ điều tra", },
    { c3: "811", c4: "8110", c5: "81100", name: "Dịch vụ hỗ trợ tổng hợp", },
    { c3: "812", c4: "", c5: "", name: "Dịch vụ vệ sinh", },
    { c3: "", c4: "8121", c5: "81210", name: "Vệ sinh chung nhà cửa ", },
    { c3: "", c4: "8129", c5: "81290", name: "Vệ sinh công nghiệp và các công trình chuyên biệt", },
    { c3: "813", c4: "8130", c5: "81300", name: "Dịch vụ chăm sóc và duy trì cảnh quan", },
    { c3: "821", c4: "", c5: "", name: "Hoạt động hành chính và hỗ trợ văn phòng", },
    { c3: "", c4: "8211", c5: "82110", name: "Dịch vụ hành chính văn phòng tổng hợp", },
    { c3: "", c4: "8219", c5: "", name: "Photo, chuẩn bị tài liệu và các hoạt động hỗ trợ văn phòng đặc biệt khác", },
    { c3: "", c4: "", c5: "82191", name: "Photo, chuẩn bị tài liệu ", },
    { c3: "", c4: "", c5: "82199", name: "Hoạt động hỗ trợ văn phòng đặc biệt khác", },
    { c3: "822", c4: "8220", c5: "82200", name: "Hoạt động dịch vụ liên quan đến các cuộc gọi", },
    { c3: "823", c4: "8230", c5: "82300", name: "Tổ chức giới thiệu và xúc tiến thương mại", },
    { c3: "829", c4: "", c5: "", name: "Hoạt động dịch vụ hỗ trợ kinh doanh khác chưa được phân vào đâu", },
    { c3: "", c4: "8291", c5: "82910", name: "Hoạt động dịch vụ hỗ trợ thanh toán, tín dụng", },
    { c3: "", c4: "8292", c5: "82920", name: "Dịch vụ đóng gói", },
    { c3: "", c4: "8299", c5: "82990", name: "Hoạt động dịch vụ hỗ trợ kinh doanh khác còn lại chưa được phân vào đâu", },
    { c3: "841", c4: "", c5: "", name: "Hoạt động của Đảng cộng sản, tổ chức chính trị - xã hội, quản lý nhà nước và quản lý chính sách kinh tế, xã hội", },
    { c3: "", c4: "8411", c5: "", name: "Hoạt động của Đảng cộng sản, tổ chức chính trị - xã hội, hoạt động quản lý nhà nước nói chung và kinh tế tổng hợp", },
    { c3: "", c4: "", c5: "84111", name: "Hoạt động của Đảng cộng sản, tổ chức chính trị - xã hội", },
    { c3: "", c4: "", c5: "84112", name: "Hoạt động quản lý nhà nước nói chung và kinh tế tổng hợp", },
    { c3: "", c4: "8412", c5: "84120", name: "Hoạt động quản lý nhà nước trong các lĩnh vực y tế, giáo dục, văn hoá và các dịch vụ xã hội khác (trừ bảo đảm xã hội bắt buộc)", },
    { c3: "", c4: "8413", c5: "84130", name: "Hoạt động quản lý nhà nước trong các lĩnh vực kinh tế chuyên ngành", },
    { c3: "842", c4: "", c5: "", name: "Hoạt động phục vụ chung cho toàn đất nước", },
    { c3: "", c4: "8421", c5: "84210", name: "Hoạt động đối ngoại", },
    { c3: "", c4: "8422", c5: "84220", name: "Hoạt động quốc phòng", },
    { c3: "", c4: "8423", c5: "84230", name: "Hoạt động an ninh, trật tự an toàn xã hội", },
    { c3: "843", c4: "8430", c5: "84300", name: "Hoạt động bảo đảm xã hội bắt buộc", },
    { c3: "851", c4: "", c5: "", name: "Giáo dục mầm non", },
    { c3: "", c4: "8511", c5: "85110", name: "Giáo dục nhà trẻ", },
    { c3: "", c4: "8512", c5: "85120", name: "Giáo dục mẫu giáo", },
    { c3: "852", c4: "", c5: "", name: "Giáo dục phổ thông", },
    { c3: "", c4: "8521", c5: "85210", name: "Giáo dục tiểu học", },
    { c3: "", c4: "8522", c5: "", name: "Giáo dục trung học cơ sở", },
    { c3: "", c4: "", c5: "85220", name: "Giáo dục trung học cơ sở", },
    { c3: "", c4: "8523", c5: "", name: "Giáo dục trung học phổ thông", },
    { c3: "", c4: "", c5: "85230", name: "Giáo dục trung học phổ thông", },
    { c3: "853", c4: "", c5: "", name: "Giáo dục nghề nghiệp ", },
    { c3: "", c4: "8531", c5: "", name: "Đào tạo sơ cấp", },
    { c3: "", c4: "", c5: "85310", name: "Đào tạo sơ cấp", },
    { c3: "", c4: "8532", c5: "", name: "Đào tạo trung cấp", },
    { c3: "", c4: "", c5: "85320", name: "Đào tạo trung cấp", },
    { c3: "", c4: "8533", c5: "", name: "Đào tạo cao đẳng", },
    { c3: "", c4: "", c5: "85330", name: "Đào tạo cao đẳng", },
    { c3: "854", c4: "", c5: "", name: "Giáo dục đại học", },
    { c3: "", c4: "8541", c5: "85410", name: "Đào tạo đại học", },
    { c3: "", c4: "8542", c5: "85420", name: "Đào tạo thạc sỹ", },
    { c3: "", c4: "8543", c5: "85430", name: "Đào tạo tiến sỹ", },
    { c3: "855", c4: "", c5: "", name: "Giáo dục khác", },
    { c3: "", c4: "8551", c5: "85510", name: "Giáo dục thể thao và giải trí", },
    { c3: "", c4: "8552", c5: "85520", name: "Giáo dục văn hoá nghệ thuật", },
    { c3: "", c4: "8559", c5: "85590", name: "Giáo dục khác chưa được phân vào đâu", },
    { c3: "856", c4: "8560", c5: "85600", name: "Dịch vụ hỗ trợ giáo dục", },
    { c3: "861", c4: "8610", c5: "", name: "Hoạt động của các bệnh viện, trạm y tế", },
    { c3: "", c4: "", c5: "86101", name: "Hoạt động của các bệnh viện", },
    { c3: "", c4: "", c5: "86102", name: "Hoạt động của các trạm y tế và trạm y tế bộ, ngành", },
    { c3: "862", c4: "8620", c5: "", name: "Hoạt động của các phòng khám đa khoa, chuyên khoa và nha khoa", },
    { c3: "", c4: "", c5: "86201", name: "Hoạt động của các phòng khám đa khoa, chuyên khoa ", },
    { c3: "", c4: "", c5: "86202", name: "Hoạt động của các phòng khám nha khoa", },
    { c3: "869", c4: "", c5: "", name: "Hoạt động y tế khác", },
    { c3: "", c4: "8691", c5: "86910", name: "Hoạt động y tế dự phòng ", },
    { c3: "", c4: "8692", c5: "86920", name: "Hoạt động của hệ thống cơ sở chỉnh hình, phục hồi chức năng", },
    { c3: "", c4: "8699", c5: "86990", name: "Hoạt động y tế khác chưa được phân vào đâu", },
    { c3: "871", c4: "8710", c5: "", name: "Hoạt động của các cơ sở nuôi dưỡng, điều dưỡng", },
    { c3: "", c4: "", c5: "87101", name: "Hoạt động của các cơ sở nuôi dưỡng, điều dưỡng thương bệnh binh", },
    { c3: "", c4: "", c5: "87109", name: "Hoạt động của các cơ sở nuôi dưỡng, điều dưỡng các đối tượng khác", },
    { c3: "872", c4: "8720", c5: "", name: "Hoạt động chăm sóc sức khoẻ người bị thiểu năng, tâm thần và người nghiện", },
    { c3: "", c4: "", c5: "87201", name: "Hoạt động chăm sóc sức khỏe người bị thiểu năng, tâm thần", },
    { c3: "", c4: "", c5: "87202", name: "Hoạt động chăm sóc sức khỏe, cai nghiện phục hồi người nghiện", },
    { c3: "873", c4: "8730", c5: "", name: "Hoạt động chăm sóc sức khoẻ người có công, người già và người khuyết tật không có khả năng tự chăm sóc", },
    { c3: "", c4: "", c5: "87301", name: "Hoạt động chăm sóc sức khỏe người có công (trừ thương bệnh binh)", },
    { c3: "", c4: "", c5: "87302", name: "Hoạt động chăm sóc sức khỏe người già", },
    { c3: "", c4: "", c5: "87303", name: "Hoạt động chăm sóc sức khỏe người khuyết tật", },
    { c3: "879", c4: "8790", c5: "", name: "Hoạt động chăm sóc tập trung khác", },
    { c3: "", c4: "", c5: "87901", name: "Hoạt động chữa bệnh, phục hồi nhân phẩm cho đối tượng mại dâm", },
    { c3: "", c4: "", c5: "87909", name: "Hoạt động chăm sóc tập trung khác chưa được phân vào đâu", },
    { c3: "881", c4: "8810", c5: "", name: "Hoạt động trợ giúp xã hội không tập trung đối với người có công, thương bệnh binh, người già và người khuyết tật", },
    { c3: "", c4: "", c5: "88101", name: "Hoạt động trợ giúp xã hội không tập trung đối với người có công (trừ thương bệnh binh)", },
    { c3: "", c4: "", c5: "88102", name: "Hoạt động trợ giúp xã hội không tập trung đối với thương bệnh binh", },
    { c3: "", c4: "", c5: "88103", name: "Hoạt động trợ giúp xã hội không tập trung đối với người già và người khuyết tật", },
    { c3: "889", c4: "8890", c5: "88900", name: "Hoạt động trợ giúp xã hội không tập trung khác", },
    { c3: "900", c4: "9000", c5: "90000", name: "Hoạt động sáng tác, nghệ thuật và giải trí ", },
    { c3: "910", c4: "", c5: "", name: "Hoạt động của  thư viện, lưu trữ, bảo tàng và các hoạt động văn hoá khác", },
    { c3: "", c4: "9101", c5: "91010", name: "Hoạt động thư viện và lưu trữ", },
    { c3: "", c4: "9102", c5: "91020", name: "Hoạt động bảo tồn, bảo tàng", },
    { c3: "", c4: "9103", c5: "91030", name: "Hoạt động của các vườn bách thảo, bách thú và khu bảo tồn tự nhiên", },
    { c3: "920", c4: "9200", c5: "", name: "Hoạt động xổ số, cá cược và đánh bạc", },
    { c3: "", c4: "", c5: "92001", name: "Hoạt động xổ số", },
    { c3: "", c4: "", c5: "92002", name: "Hoạt động cá cược và đánh bạc", },
    { c3: "931", c4: "", c5: "", name: "Hoạt động thể thao", },
    { c3: "", c4: "9311", c5: "93110", name: "Hoạt động của các cơ sở thể thao", },
    { c3: "", c4: "9312", c5: "93120", name: "Hoạt động của các câu lạc bộ thể thao", },
    { c3: "", c4: "9319", c5: "93190", name: "Hoạt động thể thao khác", },
    { c3: "932", c4: "", c5: "", name: "Hoạt động vui chơi giải trí khác", },
    { c3: "", c4: "9321", c5: "93210", name: "Hoạt động của các công viên vui chơi và công viên theo chủ đề", },
    { c3: "", c4: "9329", c5: "93290", name: "Hoạt động vui chơi giải trí khác chưa được phân vào đâu", },
    { c3: "941", c4: "", c5: "", name: "Hoạt động của các hiệp hội kinh doanh, nghiệp chủ và nghề nghiệp", },
    { c3: "", c4: "9411", c5: "94110", name: "Hoạt động của các hiệp hội kinh doanh và nghiệp chủ", },
    { c3: "", c4: "9412", c5: "94120", name: "Hoạt động của các hội nghề nghiệp", },
    { c3: "942", c4: "9420", c5: "94200", name: "Hoạt động của công đoàn", },
    { c3: "949", c4: "", c5: "", name: "Hoạt động của các tổ chức khác ", },
    { c3: "", c4: "9491", c5: "94910", name: "Hoạt động của các tổ chức tôn giáo", },
    { c3: "", c4: "9499", c5: "94990", name: "Hoạt động của các tổ chức khác chưa được phân vào đâu", },
    { c3: "951", c4: "", c5: "", name: "Sửa chữa máy vi tính và thiết bị liên lạc", },
    { c3: "", c4: "9511", c5: "95110", name: "Sửa chữa máy vi tính và thiết bị ngoại vi", },
    { c3: "", c4: "9512", c5: "95120", name: "Sửa chữa thiết bị liên lạc", },
    { c3: "952", c4: "", c5: "", name: "Sửa chữa đồ dùng cá nhân và gia đình", },
    { c3: "", c4: "9521", c5: "95210", name: "Sửa chữa thiết bị nghe nhìn điện tử gia dụng", },
    { c3: "", c4: "9522", c5: "95220", name: "Sửa chữa thiết bị, đồ dùng gia đình", },
    { c3: "", c4: "9523", c5: "95230", name: "Sửa chữa giày, dép, hàng da và giả da", },
    { c3: "", c4: "9524", c5: "95240", name: "Sửa chữa giường, tủ, bàn, ghế và đồ nội thất tương tự", },
    { c3: "", c4: "9529", c5: "95290", name: "Sửa chữa xe đạp, đồng hồ, đồ dùng cá nhân và gia đình khác chưa được phân vào đâu", },
    { c3: "961", c4: "9610", c5: "96100", name: "Dịch vụ tắm hơi, massage và các dịch vụ tăng cường sức khoẻ tương tự (trừ hoạt động thể thao)", },
    { c3: "962", c4: "9620", c5: "96200", name: "Giặt là, làm sạch các sản phẩm dệt và lông thú", },
    { c3: "963", c4: "", c5: "", name: "Hoạt động dịch vụ phục vụ cá nhân khác chưa được phân vào đâu", },
    { c3: "", c4: "9631", c5: "96310", name: "Cắt tóc, làm đầu, gội đầu ", },
    { c3: "", c4: "9632", c5: "96320", name: "Hoạt động dịch vụ phục vụ tang lễ", },
    { c3: "", c4: "9633", c5: "96330", name: "Hoạt động dịch vụ phục vụ hôn lễ", },
    { c3: "", c4: "9639", c5: "96390", name: "Hoạt động dịch vụ phục vụ cá nhân khác còn lại chưa được phân vào đâu", },
    { c3: "970", c4: "9700", c5: "97000", name: "Hoạt động làm thuê công việc gia đình trong các hộ gia đình", },
    { c3: "981", c4: "9810", c5: "98100", name: "Hoạt động sản xuất các sản phẩm vật chất tự tiêu dùng của hộ gia đình", },
    { c3: "982", c4: "9820", c5: "98200", name: "Hoạt động sản xuất các sản phẩm dịch vụ tự tiêu dùng của hộ gia đình", },
    { c3: "990", c4: "9900", c5: "99000", name: "Hoạt động của các tổ chức và cơ quan quốc tế", },
]
export default data;
