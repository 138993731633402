import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { actGetCompanyDetail, actResetFinancial } from "../../redux/actions";
import { actgetCompanyDetail } from "../../services/companyInfo.service";
import { actGetCurrentPoint } from "../../redux/AccountManagement/actions";
import { actAddToCart } from "../../redux/actions";

import getUrlParam from "../../utils/getUrlParam";
import TabFinancial from "./Tabs/TabFinancial";
import TabFinancial2 from "./Tabs/TabFinancial_2";

import TabRelatedReport from "./Tabs/TabRelatedReport";
import TabInfo from "./Tabs/TabInfo";
import TabOwner from "./Tabs/TabOwner";
import TabTargets from "./Tabs/TabTargets";
import TabTargets2 from "./Tabs/TabTargets_2";

import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { subPhone } from "../../utils/func.util";
import LoaderTable from "../../components/LoaderTable";
import { saveAs as importedSaveAs } from "file-saver";
import { isArray } from "lodash";
import TabCompareCompany from "./Tabs/TabCompareCompany";
import { _Basic_, _Standard_ } from "../../constants/rolesModel";
import { Redirect } from "react-router-dom";
import { callApiServer } from "../../utils/callApiServer";
import { Spinner } from "reactstrap";
import { formatMoney_2 } from "../../utils/formatMoney";
import ConfirmPay from "../AccountsManagement/ConfirmPayComponent";
import AutoReportTemplate from "./Tabs/AutoReportTemplate";

export class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getUrlParam()["tab"] ? parseInt(getUrlParam()["tab"]) : 1,
      isTab: getUrlParam()["tab"] ? true : false,
      dropdownOpen: false,
      id: this.props.match.params.id,
      valueFinancialSet: null,
      setChecked: null,
      loadingInitial: true,
      alert: "",
      isDownloading: false,

      detail: {},
      popupConfirmBuy: "",
      selectedConfirmOption: ""
    };
  }
  // thay đổi tab
  toggle = (tab) => {
    const { messages } = this.props.intl;
    if (this.state.activeTab !== tab) {
      if (parseInt(this.state.setChecked) === -1 && [3, 4, 5].includes(tab)) {
        // nếu set = -1 thì thì không thể xem chỉ tiêu, chỉ số, so sánh DN
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title={messages[`user.Notification`]}
              confirmBtnText={messages[`newWord.oke`]}
              customClass="swal-wide"
              confirmBtnCssClass="confirmBtn swal2-styled"
              onConfirm={() => {
                this._hideAlert();
              }}
            >
              <p>
                <IntlMessages id="newWord.notAvailable" />
              </p>
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          activeTab: tab
        });
      }
    }
  };

  handleOnBack = () => {
    this.toggle(this.state.activeTab);
    this.props.history.push(`/chi-tiet-doanh-nghiep/${this.state.id}?tab=${this.state.activeTab}`);
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };
  _hideAlert = () => {
    this.handleOnBack();
    this.setState({
      alert: ""
    });
  };

  toggleSS = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true });
  };

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false });
  };

  componentDidMount() {
    document.title = "VIRACE | Company Detail";
    const { activeTab, id } = this.state;

    if (![1, 2, 3, 4, 5, 6].includes(activeTab)) {
      window.location.href = "/chi-tiet-doanh-nghiep/" + id + "?tab=1";
    }
    const { state } = this.props.history.location;
    const action = this.props.history.action;
    let typeParams = "Thông tin doanh nghiệp";
    if (state && state.type && action === "PUSH") {
      typeParams = state.type;
    }
    // this.props.getCompanyDetail(id, typeParams);
    this.getDataCompanyDetail(id, typeParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.userAccount && this.state.detail && this.state.loadingInitial === false) {
      this.funcSetLocalCompanySearch(this.state.detail.id, this.state.detail.name ? this.state.detail.name : "");
    }

    if (nextProps.roles !== "") {
      //Kiểm tra nếu user là roles Basic thì không cho xem chi tiết doanh nghiệp
      const { roles } = nextProps;
      const { messages } = nextProps.intl;
      if (roles && (roles === _Basic_ || roles === _Standard_)) {
        this.actAlert(messages[`newWord.upgradeAccount`]);
        setTimeout(() => {
          this.props.history.push("/menu-dieu-huong/");
        }, 3000);
      }
    }
  }

  async getDataCompanyDetail(id, type) {
    try {
      this.setState({
        loadingInitial: true
      });
      const response = await actgetCompanyDetail({ id, type });
      if (response && response.data && response.data.data) {
        const detail = response.data.data;
        this.setState({
          detail: detail
        });
        if (response.data.message === "Success") {
          // this.funcSetLocalCompanySearch(detail.id, detail.name ? detail.name : "");
          this.setState({
            detail: detail,
            setChecked: detail.set,
            loadingInitial: false
          });
        } else if (response.data.message === "NotPurchased") {
          this.openPopupConfirmBuy(this.state.detail.companyDetailView.point, this.state.detail.companyDetailView.price, "CompanyDetailView", 0, this.state.detail.companyDetailView);
        }
      }
    } catch (error) {
      this.setState({
        loadingInitial: false
      });
    }
  }

  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail = {}) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });

            this.props.history.push("/trang-chu-cr");
          }}
        >
          <ConfirmPay
            id={this.state.id}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
              if (type === "CompanyDetailView") {
                this.props.history.push("/trang-chu-cr");
              }
            }}
            history={this.props.history}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  // danh sách DN đã xem gần nhất
  funcSetLocalCompanySearch = (id, name) => {
    const idUser = this.props.userAccount.id || null;
    try {
      if (id && name) {
        let model = { id: id, name: name };
        var arr = !localStorage.getItem("aListSuggest") ? [] : JSON.parse(localStorage.getItem("aListSuggest"));
        if (isArray(arr)) {
          const index = arr.findIndex((e) => e.idUser === idUser);
          if (index === -1) {
            arr.unshift({
              idUser: idUser,
              arrSuggest: [model]
            });
          } else {
            let arrSG = arr[index].arrSuggest;
            arrSG.splice(5);
            const indexSG = arrSG.findIndex((e) => e.id === id);
            if (indexSG === -1) {
              arr[index] = {
                ...arr[index],
                idUser: idUser,
                arrSuggest: [model, ...(arr[index].arrSuggest || [])]
              };
            }
          }
        }
        localStorage.setItem("aListSuggest", JSON.stringify(arr));
      }
    } catch (err) {
      localStorage.removeItem("aListSuggest");
    }
  };

  renderWebsite = (param) => {
    if (param) {
      return param.replace("https://", "");
    }
    return "";
  };

  renderView() {
    const { activeTab, id, setChecked, detail, set, loadingInitial } = this.state;
    var activeTabCheck = activeTab;
    if (set) {
      let arrSet = [2, 13, 14, 19, 20, 21, 22]; // doanh nghiệp tín dụng
      var isCreditChecked = arrSet.includes(set);
    }
    switch (activeTabCheck) {
      case 1:
        return (
          <TabInfo detail={detail} loadingDT={!loadingInitial} justView={false} history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} valueFinancial={setChecked} />
        );
      case 2:
        return <TabOwner detail={detail} loadingDT={!loadingInitial} justView={false} history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
      case 3:
        return <TabTargets2 detail={detail} loadingDT={!loadingInitial} justView={false} history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
      case 4:
        return <TabFinancial2 detail={detail} loadingDT={!loadingInitial} justView={false} history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
      case 5:
        if (this.props.userAccount.isEmailSpecial === true) {
          return <TabCompareCompany detail={detail} loadingDT={!loadingInitial} history={this.props.history} id={id} checkCreditDN={isCreditChecked} />;
        } else {
          this.toggle(1);
          return;
        }
      case 6:
        return (
          <TabRelatedReport isEmailSpecial={this.props.userAccount.isEmailSpecial} detail={detail} loadingDT={!loadingInitial} history={this.props.history} id={id} checkCreditDN={isCreditChecked} />
        );
      default:
        return <TabInfo detail={detail} loadingDT={!loadingInitial} history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
    }
  }
  processDownload() {
    const curentHost = window.location.origin;
    const curentPath = window.location.pathname;

    this.props.resetData();
    window.location.href = curentHost + curentPath + "/download";
  }

  async processDownload_Excel() {
    this.setState({
      isDownloading: true
    });
    const { id } = this.state;
    const language = localStorage.getItem("currentLanguage");

    const fileName = language === "vi" ? "Bao_cao_doanh_nghiep.xlsx" : "Enterprise_report.xlsx";
    const urlDownload = `/Company/export-excel-company-detail?companyId=${id}`;
    try {
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloading: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloading: false
      });
    }
  }

  handleDownloadReportAuto() {
    const { messages } = this.props.intl;
    this.setState({
      templateAutoReport: (
        <SweetAlert default hideOverlay={false} buttons={false} allowEscape={true} title={"Report Company"} customClass="auto-report-popup" showCancel={true}>
          <AutoReportTemplate closeTemplate={this.closeAutoReport} id={this.state.id}></AutoReportTemplate>
        </SweetAlert>
      )
    });
  }

  closeAutoReport = () => {
    this.setState({
      templateAutoReport: ""
    });
  };

  async handleDownloadReportExcel(id, name) {
    try {
      this.setState({
        isDownloadingReportExcel: true
      });
      const fileName = `Instant_Company_Report_${name}.xlsx`;
      const urlDownload = `/Company/export-excel-company-report?companyId=${id}`;
      const response = await callApiServer.get(urlDownload, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingReportExcel: false
        });
      } else {
        this.setState({
          isDownloadingReportExcel: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingReportExcel: false
      });
    }
  }

  render() {
    const { id, isDownloading, detail } = this.state;
    const props = this.props;
    const state = this.state;
    const { automateCompanyReportPdf, automateCompanyReportExcel } = this.state.detail;
    const { roles } = this.props;
    const { exportCompanyData } = props.dataReducer;
    const { messages } = this.props.intl;
    if (exportCompanyData) {
      var blob = new Blob([exportCompanyData], { type: "application/pdf" });
      importedSaveAs(blob, messages["companyReportFileName"]);
    }
    return (
      <div className="views_company_detail" ref={(el) => (this.componentRef = el)}>
        {this.state.templateAutoReport}

        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}

        {roles && roles === _Basic_ && <Redirect to="/trang-chu-if-ir" />}

        {<LoaderTable isLoaded={!this.state.loadingInitial} styleCustom={{ height: "calc(100vh - 200px)" }} />}
        {!this.state.loadingInitial && (
          <div>
            {this.state.alert}
            {state.detail.id && (
              <div className="non-financial-info company-info-content-main">
                <div className="result-item" style={{ border: 0 }}>
                  <div className="vietnamese-name mb-1 d-flex justify-content-between">
                    <span>{state.detail.name ? state.detail.name : ""}</span>

                    {this.props.userAccount && this.props.userAccount.isEmailSpecial === true ? (
                      <div className="search-info-section p-0">
                        <div className="visc-list" style={{ display: "flex", flexDirection: "column" }}>
                          <div className="text-cyan link mb-2" onClick={() => this.processDownload()}>
                            <em className="material-icons">download</em>
                            <IntlMessages id="company.downloadReport" />
                            <span>&nbsp;(pdf)</span>
                          </div>
                          {isDownloading ? (
                            <Spinner style={{ borderRightColor: "#3bdbfd", marginLeft: "20px" }} variant="success" animation="border" />
                          ) : (
                            <div className="text-cyan link" onClick={() => this.processDownload_Excel()}>
                              <em className="material-icons">download</em>
                              <IntlMessages id="company.downloadReport" /> <span>&nbsp;(excel)</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="search-info-section p-0">
                        <div className="visc-list" style={{ display: "flex", flexDirection: "column" }}>
                          {automateCompanyReportPdf && automateCompanyReportPdf.isPurchased ? (
                            <div className="text-cyan link mb-2" onClick={() => this.handleDownloadReportAuto()}>
                              <em className="material-icons">download</em>
                              <IntlMessages id="industry.downloadReportPDF" />
                            </div>
                          ) : (
                            <div
                              className="text-cyan link mb-2"
                              onClick={() =>
                                this.openPopupConfirmBuy(
                                  automateCompanyReportPdf.point,
                                  automateCompanyReportPdf.price,
                                  automateCompanyReportPdf.productType,
                                  automateCompanyReportPdf.oldPoint,
                                  automateCompanyReportPdf
                                )
                              }
                            >
                              <em className="material-icons">shopping_cart</em>
                              <IntlMessages id="industry.reportPDF" />
                            </div>
                          )}
                          {automateCompanyReportExcel && automateCompanyReportExcel.isPurchased ? (
                            <div
                              className="text-cyan link mb-2"
                              onClick={() => this.handleDownloadReportExcel(id, state.detail.englishName ? state.detail.englishName : state.detail.name ? state.detail.name : "")}
                            >
                              <em className="material-icons">download</em>
                              <IntlMessages id="industry.downloadReportExcel" />
                            </div>
                          ) : (
                            <div
                              className="text-cyan link mb-2"
                              onClick={() =>
                                this.openPopupConfirmBuy(
                                  automateCompanyReportExcel.point,
                                  automateCompanyReportExcel.price,
                                  automateCompanyReportExcel.productType,
                                  automateCompanyReportExcel.oldPoint,
                                  automateCompanyReportExcel
                                )
                              }
                            >
                              <em className="material-icons">shopping_cart</em>
                              <IntlMessages id="industry.reportExcel" />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="english-name mb-2">{state.detail.englishName ? state.detail.englishName : ""}</div>

                  {state.detail.subTitle && (
                    <div className="address has-icon">
                      {/* <img src={icon1} alt="icon" /> */}
                      <span className="material-icons">domain</span>
                      {state.detail.subTitle}
                    </div>
                  )}
                  <div className="address has-icon">
                    {" "}
                    <span className="material-icons">place</span>
                    {state.detail.address ? state.detail.address : ""}
                  </div>
                  <div className="info-wrap">
                    <ul className="info">
                      <li className="has-icon">
                        <span className="material-icons">tag</span>
                        <IntlMessages id="page.MST" />: {state.detail.taxNumber ? state.detail.taxNumber : "N/A"}
                      </li>
                      <li className="has-icon" style={{ width: "66.66667%" }}>
                        <span className="material-icons">business_center</span>
                        VSIC: {state.detail.vsicName ? " " + state.detail.vsicName + " - " : ""} {state.detail.vsicCode ? state.detail.vsicCode : ""}
                      </li>
                    </ul>
                  </div>
                  <div className="result-more-content">
                    <ul className="info">
                      <li className="has-icon">
                        <span className="material-icons">phone</span>
                        {state.detail.phoneNumber ? subPhone(state.detail.phoneNumber) : "N/A"}
                      </li>
                      <li className="has-icon">
                        <span className="material-icons">print</span>
                        {state.detail.fax ? state.detail.fax : "N/A"}
                      </li>
                      <li className="has-icon">
                        <span className="material-icons">public</span>
                        {state.detail.webSite ? (
                          <a className="text-cyan" target="_blank" rel="noopener noreferrer" href={state.detail.webSite ? "https://" + this.renderWebsite(state.detail.webSite) : ""}>
                            {state.detail.webSite}
                          </a>
                        ) : (
                          <span>N/A</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {this.props.match.params.id !== null && this.props.match.params.id !== undefined && (
              <Nav>
                <NavItem>
                  <Link
                    className={classnames({
                      active: this.state.activeTab === 1 || !this.state.activeTab,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(1);
                    }}
                    to={`${id}?tab=1`}
                  >
                    <IntlMessages id="submenu.Businessinformation" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${id}?tab=2`}
                    className={classnames({
                      active: this.state.activeTab === 2,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(2);
                    }}
                  >
                    <IntlMessages id="submenu.Ownership" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${id}?tab=3`}
                    className={classnames({
                      active: this.state.activeTab === 3,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(3);
                    }}
                  >
                    <IntlMessages id="submenu.Accounts" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${id}?tab=4`}
                    className={classnames({
                      active: this.state.activeTab === 4,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(4);
                    }}
                  >
                    <IntlMessages id="submenu.Ratios" />
                  </Link>
                </NavItem>
                {this.props.userAccount && this.props.userAccount.isEmailSpecial === true && (
                  <NavItem>
                    <Link
                      to={`${id}?tab=5`}
                      className={classnames({
                        active: this.state.activeTab === 5,
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.toggle(5);
                      }}
                    >
                      <IntlMessages id="menu.companycomparisonorigin" />
                    </Link>
                  </NavItem>
                )}

                <NavItem>
                  <Link
                    to={`${id}?tab=6`}
                    className={classnames({
                      active: this.state.activeTab === 6,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(6);
                    }}
                  >
                    <IntlMessages id="submenu.relatedIndustryReports" />
                  </Link>
                </NavItem>
              </Nav>
            )}
            <div className="company-panel-content">{this.renderView()}</div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // detail: state.companyReducer.companyDetail,
    dataReducer: state.companyReducer,
    userAccount: state.accountReducer.userAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // chi tiết DN
    getCompanyDetail: (id, type) => {
      dispatch(actGetCompanyDetail(id, type));
    },
    //resetData
    resetData: () => {
      dispatch(actResetFinancial());
    },
    handleGetCurentPoint: () => {
      dispatch(actGetCurrentPoint());
    },

    // Thêm vào giỏ hàng
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyDetail));
