import React from "react";
import ic_company from "../assets/images/ic/company.svg";
import company_active from "../assets/images/ic/company_active.min.svg";
import industry_n from "../assets/images/ic/industry_n.svg";
import industry_n_active from "../assets/images/ic/industry_n_active.svg";
import Cart from "../components/Cart/Cart";
import AccountsManagement from "../views/AccountsManagement/AccountsManagement";
import CompanyDetail from "../views/CompanyInfoPage/CompanyDetail";
import CompanyDetailDownload from "../views/CompanyInfoPage/CompanyDetailDownload";

import CompanyInfo from "../views/CompanyInfoPage/CompanyInfo";
import CRHome from "../views/CR/CR_Home";
import IFIRHome from "../views/IF_IR/IF_IR_Home";
import MainMenu from "../views/MainMenu";
import CompanySearchAdvance from "../views/CompanySearchAdvance/CompanySearchAdvance";
import IndustryFilterResults from "../views/IndustryFilterResults/IndustryFilterResults";
import IndustryReportList from "../views/IndustryReport/IndustryReportList";
import IndustryReportDetail from "../views/IndustryReport/IndustryReportDetail";
import BasicStatistic from "../views/BasicStatistic/BasicStatistic";
import AdvanceStatistic from "../views/AdvanceStatistic/AdvanceStatistic";
import ElectronicBill from "../views/ElectronicBill/ElectronicBill";
import DownloadFile from "../views/DownloadFile/DownloadFile";

var mainRoutes = [
  {
    path: "/menu-dieu-huong",
    name: "menu.home",
    invisible: true,
    component: (props, roles, isLogged) => <MainMenu {...props} roles={roles} isLogged={isLogged} />
  },
  {
    path: "/trang-chu-cr",
    name: "menu.cr-home",
    icon: ic_company,
    icon_active: company_active,
    component: (props, roles) => <CRHome {...props} roles={roles} title={"menu.cr-home"} />
  },
  {
    path: "/trang-chu-if-ir",
    name: "menu.if-ir-home",
    icon: industry_n,
    icon_active: industry_n_active,
    component: (props, roles) => <IFIRHome {...props} roles={roles} title={"menu.if-ir-home"} />
  },
  {
    path: "/chi-tiet-doanh-nghiep/:id",
    name: "menu.companyinfoorigin",
    exact: true,
    invisible: true,
    component: (props, roles) => <CompanyDetail {...props} roles={roles} title={"menu.companyinformationsearch"} />
  },
  {
    path: "/chi-tiet-doanh-nghiep/:id/download",
    name: "menu.companyinfoorigin",
    exact: true,
    invisible: true,
    component: (props, roles) => <CompanyDetailDownload {...props} roles={roles} title={"menu.companyinformationsearch"} />
  },
  {
    path: "/ket-qua-tra-cuu-thong-tin-doanh-nghiep",
    name: "menu.companyinfoorigin",
    collapse: true,
    invisible: true,
    state: "openComponentsInfo",
    views: [
      {
        path: "/ket-qua-tra-cuu-thong-tin-doanh-nghiep",
        name: "menu.companyinfoorigin",
        exact: true,
        component: (props, roles) => <CompanyInfo {...props} roles={roles} title={"menu.companyinformationsearch"} />
      }
    ]
  },
  {
    path: "/tim-kiem-danh-sach-doanh-nghiep",
    name: "Tìm kiếm danh sách doanh nghiệp",
    invisible: true,
    component: (props, roles) => <CompanySearchAdvance {...props} roles={roles} />
  },
  {
    path: "/ket-qua-tra-cuu-thong-tin-nganh",
    name: "Kết quả tra cứu thông tin ngành",
    invisible: true,
    component: (props) => <IndustryFilterResults {...props} />
  },
  {
    path: "/danh-sach-bao-cao-nganh",
    name: "Danh sách báo cáo ngành",
    invisible: true,
    component: (props) => <IndustryReportList {...props} />
  },
  {
    path: "/chi-tiet-bao-cao-nganh/:id",
    name: "Chi tiết báo cáo ngành",
    exact: true,
    invisible: true,
    component: (props, roles) => <IndustryReportDetail {...props} roles={roles} />
  },
  {
    path: "/thong-ke-co-ban",
    name: "Thống kê cơ bản",
    invisible: true,
    component: (props) => <BasicStatistic {...props} />
  },
  {
    path: "/thong-ke-nang-cao",
    name: "Thống kê nâng cao",
    invisible: true,
    component: (props) => <AdvanceStatistic {...props} />
  },
  {
    path: "/quan-li-tai-khoan",
    name: "Quản lý tài khoản",
    invisible: true,
    component: (props) => <AccountsManagement {...props} title={"menu.accountsManagement"} />
  },
  {
    path: "/electronic-bill",
    name: "Hóa đơn điện tử",
    invisible: true,
    component: (props) => <ElectronicBill {...props} title={"menu.electronicBillSearch"} />
  },
  {
    path: "/tai-ket-qua-hoa-don",
    name: "Downnload Invoice",
    invisible: true,
    component: (props) => <DownloadFile {...props} />
  },
  {
    path: "/cart",
    name: "Cart",
    invisible: true,
    component: (props) => <Cart {...props} title={"menu.cartDataTitle"} />
  },
  {
    redirect: true,
    path: "/",
    pathTo: localStorage.getItem("access_token") ? "/menu-dieu-huong" : "/trang-chu-if-ir",
    name: "Menu điều hướng"
  }
];

export default mainRoutes;
