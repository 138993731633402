import React, { Component } from "react";
import findNotFound from "../assets/images/404-error.png";
import { injectIntl } from "react-intl";
import IntlMessages from "../utils/IntlMessages";
import { Link } from "react-router-dom";
class Findnotfound extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    return (
      <div className="views_find_notfound" style={props.customStyle}>
        <div className="search-no-result-wrap">
          <div className="search-no-result-text mb-3">
            {props.isSearch ? (
              <div>
                <h1>
                  <IntlMessages id="search.notfound.title" />
                </h1>
                <p>
                  <IntlMessages id="search.notfound.text" />
                </p>
              </div>
            ) : props.isCartNoData ? (
              <div>
                <h1>
                  <IntlMessages id="user.Notification" />
                </h1>
                <p>
                  <IntlMessages id="cart.no-data" />
                </p>
              </div>
            ) : (
              <h3>
                <IntlMessages id="newWord.notAvailable" />
              </h3>
            )}

            {props.goHome && (
              <Link to={props.goHome} className="common-button-2 mt-4">
                <IntlMessages id="goHomePage" />
              </Link>
            )}
          </div>
          <div className="search-no-result-img">
            <img src={findNotFound} alt="findNotFound" />
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Findnotfound);
