import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

class LogoutPage extends Component {
  state = {};

  render() {
    return (
      <div>
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={`Đăng Xuất`}
          showCancel
          confirmBtnText="Đăng Xuất"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          cancelBtnText={"Quay Lại"}
          onConfirm={() => {
            localStorage.removeItem("access_token");
            this.props.history.push("/dang-nhap");
          }}
          onCancel={() => this.props.history.push("/menu-dieu-huong")}
        >
          Bạn có chắc muốn đăng xuất khỏi Virac?
        </SweetAlert>
      </div>
    );
  }
}

LogoutPage.propTypes = {};
LogoutPage.defaultProps = {};

export default LogoutPage;
