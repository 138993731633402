import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import LoaderTable from "../../../components/LoaderTable";
import NotificationModal from "../../../components/Modals/Notifications/ModalNotificationContent";
import {
  actChangeStatusSeenMessage,
  actGetNotifications,
} from "../../../redux/AccountManagement/actions";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { actGetHotNews } from "../../../redux/News/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import NoData from "../../../components/NoData";

export class TabContentNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      model: {},
      page: 1,
      notificationList: [],
    };
  }

  componentDidMount() {
    this.props.handleGetHotNews();
    this.props.handleGetNotifications();
    const {
      notificationList,
    } = this.props.notificationManagement.notificationListData;
    if (notificationList && notificationList.length > 0) {
      this.setState({
        notificationList: notificationList,
      });
    }
  }
  handlePaginationChange = (e, { activePage }) => {
    this.setState(
      {
        page: activePage,
      },
      () => this.getListNotification(activePage)
    );
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      notificationList,
    } = nextProps.notificationManagement.notificationListData;
    if (notificationList && notificationList.length > 0) {
      this.setState({
        notificationList: notificationList,
      });
    } else {
      this.setState({
        ...this.state,
        notificationList: [],
      });
    }
  }

  handleOpenNotiModal = (model) => {
    if (model.isSeen === false) {
      this.props.handleChangeStatusSeenMessage(model.id, this.handleSuccess);
      this.setState((prevState) => ({
        openModal: !prevState.modal,
        model,
      }));
    } else {
      this.setState((prevState) => ({
        openModal: !prevState.modal,
        model,
      }));
    }
  };

  handleCloseNotiModal = () => {
    this.setState((prevState) => ({
      openModal: false,
    }));
  };

  handleSuccess = (message) => {
    if (message === "Success") {
      this.getListNotification();
    } else {
      this.handleShowAlertSuccess();
    }
  };
  getListNotification(page) {
    let pageIndex = page ? page : this.state.page;
    this.props.handleGetNotifications(pageIndex);
  }
  handleShowAlertSuccess = () => {
    const { messages } = this.props.intl;
    this.setState({
      ...this.state,
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`cart.FailAlert`]}
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
        >
          <p style={{ fontSize: "15px" }}>
            <IntlMessages id="newWord.deleteNotification" />
          </p>
        </SweetAlert>
      ),
    });
  };

  render() {
    const { openModal, model, notificationList, page } = this.state;
    const {
      totalPage,
      loadingNoti,
    } = this.props.notificationManagement.notificationListData;
    return (
      <React.Fragment>
        {this.state.alert}
        <div className="manament-tab-notification">
          <div className="table-custom">
            {!loadingNoti &&
              notificationList &&
              notificationList.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <IntlMessages id="table.sttTable" />
                    </th>
                    <th>
                      <IntlMessages id="user.NotificationContent" />
                    </th>
                    <th>
                      <IntlMessages id="user.DateSend" />
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {notificationList.map((d, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span>{((page - 1) * 10) + index + 1}</span>
                        </td>
                        <td
                          onClick={() => {
                            var model = {
                              id: d.id,
                              stt: index + 1,
                              title: d.subject,
                              content: d.content,
                              isSeen: d.isSeen,
                            };

                            this.handleOpenNotiModal(model);
                          }}
                          style={{
                            color: d.isSeen === false ? "#1298ff" : "#ffffff",
                            cursor: "pointer",
                          }}
                        >
                          {d.subject}
                        </td>
                        <td>
                          <span>{d.dateMessage}</span>
                        </td>
                      </tr>
                    );
                  })
                  }

                </tbody>
              </Table>
            ) : loadingNoti ? (
              <LoaderTable
                isLoaded={!loadingNoti}
                styleCustom={{ height: "500px", justifyContent: "center" }}
              />
            ) : (
              <NoData />
            )}
            {totalPage > 1 && (
              <div
                className="foot-pagination text-right"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  totalPages={totalPage}
                  activePage={page}
                  onPageChange={this.handlePaginationChange}
                />
              </div>
            )}
          </div>
          <NotificationModal
            model={model}
            openModal={openModal}
            handleCloseNotiModal={this.handleCloseNotiModal}
            deleteSuccess={this.handleSuccess}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationManagement: state.accountReducer,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleGetNotifications: (page) => dispatch(actGetNotifications(page)),
    handleChangeStatusSeenMessage: (id, cbSuccess) =>
      dispatch(actChangeStatusSeenMessage(id, cbSuccess)),
    handleGetHotNews: () => dispatch(actGetHotNews()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TabContentNotification));
