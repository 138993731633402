import { callApiServer } from "../utils/callApiServer";

export const actSendDataRequestdApi = (model) => {
  return callApiServer.post("/Order/Request", model);
};

// mua hàng
export const actCreateOrder = (body) => {
  const rq = {
    cancelUrl: window.location.origin + "/cart",
    returnUrl: window.location.origin + "/quan-li-tai-khoan?tab=2",
    products: body.products,
    discountCode: body.discountCode,
    discountKey: body.discountKey
  };
  return callApiServer.post("/Order/CreateOrder", rq);
};

// lấy thông tin đơn hàng
export const actGetOrder = (sessionId) => {
  return callApiServer.get(`/Order/GetOrderInfo?identifyId=${sessionId}`);
};
