import React, { Component } from "react";
import { Table } from "reactstrap";
import ModalLaw from "../../../Modals/Owner/ModalLaw";
import { connect } from "react-redux";
import { actGetOwnerLawDetail } from "../../../../redux/actions";
import Findnotfound from "../../../../components/Findnotfound";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../utils/IntlMessages";
import { _Premium_ } from "../../../../constants/rolesModel";
export class TableOwnerLaw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleLaw: false,
    };
  }
  handlePopupLaw = (e, id) => {
    e.preventDefault();
    if (id !== null && id !== undefined) {
      this.props.getOwnerLawDetail(id);
    }
    this.setState((state) => ({
      toggleLaw: !state.toggleLaw,
    }));
  };
  handleCloseLaw = (prams) => {
    this.setState({
      toggleLaw: prams,
    });
  };
  render() {
    const { toggleLaw } = this.state;
    const { lawsData, roles } = this.props;
    const { lawDetail, loadingDetailLaw } = this.props.lawDetailData;
    const { messages } = this.props.intl;
    return (
      <div className="table-custom">
        {toggleLaw && (
          <ModalLaw
            lawDetailData={lawDetail}
            toggleLaw={toggleLaw}
            loadingDetailLaw={loadingDetailLaw}
            handleCloseLaw={this.handleCloseLaw}
          />
        )}
        <div className="card-title card-title-line">
          <IntlMessages id="ownership.Legal" />
        </div>
        {lawsData && lawsData.length > 0 ? (
          <div className="table-custom">
            <Table responsive>
              <thead>
                <tr className="owner-law-title">
                  <th style={{ width: "70px" }}>STT</th>
                  <th>
                    <IntlMessages id="ownership.Fullname" />
                  </th>
                  <th>
                    <IntlMessages id="ownership.Position" />
                  </th>
                  <th style={{ textAlign: "right" }}>
                    <IntlMessages id="ownership.Ownershippercentage" />
                  </th>
                  <th>
                    <IntlMessages id="ownership.Updateddate" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {lawsData.length > 0 &&
                  lawsData.map((props, key) => {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          <a
                            href="#"
                            className="text-cyan"
                            onClick={
                              roles && roles === _Premium_
                                ? (e) => this.handlePopupLaw(e, props.profileId)
                                : () => {
                                  return false;
                                }
                            }
                          >
                            {`${props.lastName || ""} ${props.middleName || ""} ${props.firstName || ""}`}
                          </a>
                        </td>
                        <td>
                          {props.legalTitle}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            paddingRight: "25px",
                          }}
                        >
                          {props.ownershipRate ? `${props.ownershipRate}%` : ""}
                        </td>
                        <td>{props.dateUpdate ? props.dateUpdate : ""}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        ) : (
          <Findnotfound
            title={messages[`newWord.notAvailable`]}
            customStyle={{
              border: 0,
              boxShadow: "none",
              borderRadius: 0,
              marginTop: 0,
              height: "250px",
              paddingTop: "30px",
            }}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lawDetailData: state.companyReducer.owner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOwnerLawDetail: (id) => {
      dispatch(actGetOwnerLawDetail(id));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TableOwnerLaw));
