import React, { Component } from "react";
import CanvasJSReact from "../../../assets/js/canvasjs.react";
import XLSX from "xlsx";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { numberFormat } from "../../../utils/numberFormat";
import addSymbols, { renderSuf } from "../../../utils/addSymbols.js";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

class LinechartTabRatio extends Component {
  constructor() {
    super();
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
    this.state = {
      dataChart: [],
      colorChart: ["#4661EE", "#EC5657", "#1BCDD1", "#8FAABB", "#B08BEB", "#3EA0DD", "#F5A52A", "#23BFAA", "#FAA586", "#EB8CC6"]
    };
  }

  // ẩn hiện legend
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  genTitle(data) {
    const { labelEnglish } = this.props;
    const language = localStorage.getItem("currentLanguage");
    if (labelEnglish) {
      return data.enTitle;
    } else {
      if (language === "vi" && data.title) {
        return data.title;
      } else if (language === "en" && data.enTitle) {
        return data.enTitle;
      } else return "";
    }
  }

  render() {
    const { dataChart, suffix, enabledToolTip, percent, suf, height, backgroundColor, tickColor, dataSuf, xType, labelEnglish, sourceEn, customFontColor } = this.props;
    const { colorChart } = this.state;
    const { messages } = this.props.intl;
    var checkTypePercent = percent;
    var tooltipSuf = "";
    const renderDataChart =
      dataChart &&
      dataChart.length > 0 &&
      dataChart.map((props, index) => {
        checkTypePercent = suf && props.type !== "3" ? true : false;
        tooltipSuf = renderSuf(suf, props.type);

        return {
          type: "line",
          connectNullData: true,
          markerSize: 5,
          legendMarkerType: "square",
          color: colorChart[index],
          name: this.genTitle(props),
          showInLegend: true,

          markerBorderColor: colorChart[index],
          dataPoints: props.dataChart ? props.dataChart : [],
          legendMarkerColor: colorChart[index]
          // markerColor: "white",
          // lineThickness: 4,
          // markerSize: 13,
          // markerBorderThickness: 3,
          // legendMarkerType: "square",
          // xValueFormatString: "####",
          // yValueFormatString: "##,##0.##",
        };
      });

    const options = {
      animationEnabled: false,
      zoomEnabled: false,
      colorSet: "colorSet2",
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "#02164d",
      theme: "light2", // "light1", "dark1", "dark2"
      title: {
        text: this.props.title ? this.props.title : "",
        margin: 20,
        fontSize: 14,
        fontColor: "#fff",
        fontFamily: "Roboto",
        fontWeight: "bold"
      },
      axisY: {
        title: "",
        includeZero: false,
        labelFontSize: 12,
        titleFontColor: customFontColor ? customFontColor : "#B6C4DE",
        labelFontColor: customFontColor ? customFontColor : "#B6C4DE",
        fontWeight: "bold",
        suffix: dataSuf ? dataSuf : "",
        gridColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor, // màu đường kẻ ngang
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor, // màu các đoạn trỏ đến data
        labelFormatter: (e) => addSymbols(e, CanvasJS, messages, checkTypePercent)
      },
      axisX: {
        prefix: "",
        valueFormatString: "####",
        interval: 1,
        labelFontColor: customFontColor ? customFontColor : "#B6C4DE",
        lineColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,
        lineDashType: "dot",
        labelFontSize: 12
      },
      toolTip: {
        shared: true,

        enabled: enabledToolTip === undefined ? true : enabledToolTip,
        contentFormatter: function(e) {
          let content = "";
          if (e.entries[0].dataPoint.label) {
            content += e.entries[0].dataPoint.label;
            content += "<br/>";

            if (e.entries[0].dataPoint.vsicName) {
              content += e.entries[0].dataPoint.vsicName;
              content += "<br/>";
            }
          } else if (e.entries[0].dataPoint.x) {
            content += e.entries[0].dataPoint.x;
            content += "<br/>";

            if (e.entries[0].dataPoint.vsicName) {
              content += e.entries[0].dataPoint.vsicName;
              content += "<br/>";
            }
          }
          for (var i = 0; i < e.entries.length; i++) {
            content += "<span style='color: " + e.entries[i].dataSeries.color + ";' >" + e.entries[i].dataSeries.name + "</span>" + ": " + numberFormat(e.entries[i].dataPoint.y);

            content += "<br/>";
          }
          return content;
          // return `<p>${e.entries[0].dataSeries.name}</p><p style='font-weight: 500'><span>${
          //   xType !== "label" ? e.entries[0].dataPoint.x : e.entries[0].dataPoint.label
          // }</span>: <span style="color: ${e.entries[0].dataSeries.color};">${numberFormat(e.entries[0].dataPoint.y)} ${dataSuf ? dataSuf : ""}</span></p>`;
        }
      },
      legend: {
        fontColor: customFontColor ? customFontColor : "#B6C4DE",
        fontSize: 12,
        cursor: "pointer",
        itemclick: this.toggleDataSeries
        // horizontalAlign: "right",
        // verticalAlign: "center",
      },
      data: this.props.dataChart ? renderDataChart : []
    };
    if (height !== undefined) {
      options.height = height;
    }
    return (
      <React.Fragment>
        <div className="column-multiple-chart-wrapper">
          <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
          {sourceEn ? (
            <div className="cright">Source: VIRAC</div>
          ) : (
            <div className="cright">
              <IntlMessages id="common.sourceVirac" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(LinechartTabRatio);
