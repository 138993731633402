export const subPhone = str => {
  let indexF = str.indexOf(";");
  var pos = "";
  if (indexF !== -1) {
     pos = str.substring(0, indexF);
    return pos;
  } else {
     pos = str;
    return pos;
  }
};

export const getDate = () => {
  var today = new Date();
  var date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
  return date;
}