import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { Link } from "react-router-dom";
import getUrlParam from "../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
export class FaqsFilter extends Component {
  state = {
    isPax: getUrlParam()["pax"] ? true : false
  };

  getSuggestionValue(suggestion) {
    return suggestion.questions;
  }
  // this.props.pushParamsUrl(suggestion.id)
  renderSuggestion(suggestion) {
    return (
      <Link to={`/faqs/detail?pax=${suggestion.id}&t=${suggestion.questions}`}>
        {suggestion.questions}
      </Link>
    );
  }

  renderSectionTitle(section) {
    return <strong>{section.questions}</strong>;
  }

  getSectionSuggestions(section) {
    return section.listItem;
  }

  render() {
    const { messages } = this.props.intl;
    const {
      suggestions,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      inputProps
    } = this.props;
    return (
      <div className="card-item mb-4 search-info-section">
        <div className="section-title mb-4"><IntlMessages id="newWord.AskQuestion" /></div>
        <div className="faqs-search-bar">
          {this.state.isPax && (
            <Link to={"/faqs"}>
              <i className="fas fa-arrow-left"></i>
            </Link>
          )}
          {/* <div className="ui action input fluid"> */}
          <Autosuggest
            multiSection={true}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            renderSectionTitle={this.renderSectionTitle}
            getSectionSuggestions={this.getSectionSuggestions}
            inputProps={inputProps}
            minCharacters={3}
            noResultsMessage={messages["search.notfound.text"]}
          />
          {/* <button className={`ui button cpn-search-btn `} onClick={}>Tìm kiếm</button>
        </div> */}
        </div>
      </div>
    );
  }
}

export default injectIntl(FaqsFilter);
