import * as Types from "../actions";

export const actSearchCompanyCompare = (model, typeIP) => ({
  type: Types.GET_LIST_COMPANYCOMPARE,
  payload: { model, typeIP }
});
export const getSearchCompanyCompareSuccess = (
  data,
  totalItems,
  totalPage,
  pageIndex,
  typeIP
) => {
  return {
    type: Types.GET_LIST_COMPANYCOMPARE_SUCCESS,
    data,
    totalItems,
    totalPage,
    pageIndex,
    typeIP
  };
};

//requestChartSSliveCompare SS Truc Tiep
export const actRequestLiveCharteCompare = body => {
  return {
    type: Types.REQUEST_LIVE_CHART_CP,
    body
  };
};

//Reset Data
export const actRequestResetDataSSDN = body => {
  return {
    type: Types.GET_LIST_COMPANYCOMPARE_RESET,
    body
  };
};
// SS Trung binh
export const actRequestLiveCharteCompare_TB = body => {
  return {
    type: Types.REQUEST_LIVE_CHART_CP_TB,
    body
  };
};
//SS Tong Gia tri
export const actRequestLiveCharteCompare_TGT = body => {
  return {
    type: Types.REQUEST_LIVE_CHART_CP_TGT,
    body
  };
};
export const actRequestLiveCharteCompareGListCB = (id) => {
  return {
    type: Types.REQUEST_LIVE_CHARTLIST_CP,
    id
  };
};
//delete and reset
export const actRequestLiveChartCompareDelete = id => {
  return {
    type: Types.REQUEST_LIVE_CHART_CP_DELETE,
    id
  };
};
export const actRequestLiveChartCompareReset = () => {
  return {
    type: Types.REQUEST_LIVE_CHART_CP_RESET
  };
};
//requestChartSSTotalGroup
export const actRequestTotalCharteCompare = body => {
  return {
    type: Types.REQUEST_TOTAL_CHART_CP,
    body
  };
};
// update
export const actUpdateDataCompare = (data, returnObj) => {
  return {
    type: Types.GET_LIST_COMPANYCOMPARE_UPDATE,
    data,
    returnObj
  };
};
export const actUpdateDataCompareOrther = (data, returnObj) => {
  return {
    type: Types.GET_LIST_COMPANYCOMPAREORTHER_UPDATE,
    data,
    returnObj
  };
};
// menu compare
export const actGetMenuCompare = (model) => ({
  type: Types.GET_LIST_COMPANYCOMPARE,
  payload: { model }
});
