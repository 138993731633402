import React, { Component } from "react";
import { connect } from "react-redux";
import { actAddToCart, actGetDataRelatedReportInCompanyDetail } from "../../../redux/actions";
import LoaderTable from "../Loader/LoaderTable";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import Swiper from "swiper";
import { formatMoney } from "../../../utils/formatMoney";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart.js";
import axios from "axios";
import fileDownload from "js-file-download";
import BASE_API_URL from "../../../constants/hostSever";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import AutoReportTemplate from "./AutoReportTemplate";
import ConfirmPay from "../../AccountsManagement/ConfirmPayComponent";

class TabRelatedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initAutoReport: false
    };
  }

  handleBuyCompany = (requestData) => {
    const { detail } = this.props;
    let model = {
      id: detail.id,
      name: detail.name,
      englishName: detail.englishName,
      vsicId: detail.vsicCode,
      taxNumber: detail.taxNumber,
      requestData: requestData,
      viracServiceId: 1, // dữ liệu doanh nghiệp
      type: 1,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  UNSAFE_componentDidMount() {}

  componentDidMount() {
    setTimeout(() => {
      handleFlyBuyCart();
      this.swiper();
    }, 500);

    this.initData();
  }

  initData() {
    if (this.props.id !== null && this.props.id !== undefined) {
      this.props.getDataRelatedReportInCompanyDetail(this.props.id);
    }
  }

  swiper = () => {
    new Swiper(".swiper-container", {
      grabCursor: true,
      autoplay: {
        delay: 3000
      },
      rewind: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      slidesPerView: 1.25,
      spaceBetween: 25,
      breakpoints: {
        320: {
          slidesPerView: 1.25,
          centeredSlides: true
        },
        992: {
          slidesPerView: 2
        }
      }
    });
  };

  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob"
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  handleBuyIndustry = (data) => {
    const language = localStorage.getItem("currentLanguage");

    let model = {
      id: data.reportId,
      name: data.reportName,
      englishName: data.reportEName,
      requestData: data.reportName,
      price: data.price,
      point: language === "vi" ? data.point : data.enPoint,

      currency: "VND",
      viracServiceId: 2, // báo cáo ngành
      type: 2,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  // handleBuyReportAuto = (data) => {
  //   const { detail } = this.props;

  //   let model = {
  //     id: detail.id,
  //     name: detail.name,
  //     englishName: detail.englishName,
  //     requestData: detail.englishName,
  //     price: data.price,
  //     currency: "VND",
  //     viracServiceId: 5, // báo cáo doanh nghiệp tự động
  //     type: 5,
  //     lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  //   };
  //   this.props.addToCart(model);
  // };
  handleBuyReportAuto = (data) => {
    this.openPopupConfirmBuy(data.point, data.price, data.productType);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data, type) {
    event.preventDefault();
    if (data) {
      if (type === "reportAuto") {
        this.handleBuyReportAuto(data);
      } else {
        this.handleBuyIndustry(data);
      }
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data, type = "industry") => {
    const { detail } = this.props;

    const stateBtn = type === "reportAuto" ? this.checkDisabledBtnCart_AutoReport(data, detail.id) : this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data, type)}>
          <span className={"cart-icon material-icons text-cyan"}>shopping_cart</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";
    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.reportId && item.lang === language).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  checkDisabledBtnCart_AutoReport = (data, idCompany) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    if (!carts) return false;

    if (carts.length > 0) {
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === idCompany && item.type === 5).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleDownloadReportAuto() {
    const { messages } = this.props.intl;
    this.setState({
      templateAutoReport: (
        <SweetAlert default hideOverlay={false} buttons={false} allowEscape={true} title={"Report Company"} customClass="auto-report-popup" showCancel={true}>
          <AutoReportTemplate closeTemplate={this.closeAutoReport} id={this.props.id}></AutoReportTemplate>
        </SweetAlert>
      )
    });
  }

  closeAutoReport = () => {
    this.setState({
      templateAutoReport: ""
    });
  };

  handleBuyReportAuto(data) {
    this.openPopupConfirmBuy(data.point, data.price, data.productType);
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={this.props.detail.id}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }

  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  render() {
    const { detail, isEmailSpecial } = this.props;
    const { loadingDT, loadingRelatedData } = this.props.reducer;
    const { vsicReportData } = this.props.reducer.companyRelatedData;
    const { automateCompanyReportPdf } = this.props.detail;
    const reportAutoData = {};
    const { messages } = this.props.intl;

    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");
    const checkDisabledBtnCart = (requestData) => {
      if (!carts) return false;
      if (
        carts.length > 0 &&
        carts[0].dataRequest &&
        carts[0].dataRequest.filter((i) => i.id === detail.id && i.lang === language && i.name === detail.name && i.requestData === requestData).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };
    var buttonCartCompany = (requestData) => (
      <React.Fragment>
        <button
          className="ui basic button add-to-cart p-0 m-0"
          disabled={checkDisabledBtnCart(requestData)}
          onClick={detail.id ? () => this.handleBuyCompany(requestData) : () => this.denyClick()}
        >
          {/* <img className="cart-icon" src={ic_btn_fly} alt="" /> */}
          <span className="cart-icon material-icons text-cyan">shopping_cart</span>
        </button>
      </React.Fragment>
    );

    //
    this.swiper();
    return (
      <React.Fragment>
        {this.state.templateAutoReport}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <LoaderTable isLoaded={!loadingRelatedData} styleCustom={{ height: "300px" }} />
        <div className="company_info_views related-report" hidden={loadingRelatedData} ref={(el) => (this.componentRef = el)}>
          <div className="row equal-height">
            <div className="col-xl-6 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line">
                  <IntlMessages id="report.company" />
                </div>
                {/* Auto Report */}
                {isEmailSpecial === true && (
                  <div className="info_tbody_l_table_item itemx">
                    <div className="info_tbody_l_table_item_l">
                      <div className="mb-3 report-name">
                        {" "}
                        <IntlMessages id="report.CompanyAuto.title" />
                      </div>
                      <IntlMessages id="report.CompanyAuto.content" />
                    </div>
                    <div className="info_tbody_l_table_item_r">
                      {automateCompanyReportPdf.isPurchased ? (
                        <div className="auto-report-btn-download">
                          <a href={BASE_API_URL + "Report/sampleReport?type=ACR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                            <em className="material-icons">download</em>
                            <IntlMessages id="report.template" />
                          </a>
                          <div className="download-report-auto text-cyan" onClick={() => this.handleDownloadReportAuto()}>
                            <em className="material-icons mr-1">download</em>
                            <IntlMessages id="industry.downloadReport" />
                          </div>
                        </div>
                      ) : (
                        <div className="auto-report-btn-buy">
                          <a href={BASE_API_URL + "Report/sampleReport?type=ACR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                            <em className="material-icons">download</em>
                            <IntlMessages id="report.template" />
                          </a>
                          {/* {this.buttonCart(automateCompanyReportPdf, "reportAuto")} */}
                          <div className="text-cyan mt-2" onClick={() => this.handleBuyReportAuto(automateCompanyReportPdf)}>
                            <em className="material-icons mr-1" style={{ fontSize: "24px" }}>
                              shopping_cart
                            </em>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.CompanySnapshot.title" />
                    </div>
                    <IntlMessages id="report.CompanySnapshot.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.CompanySnapshot.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=CS"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload(BASE_API_URL + CS_report, 'VIRAC-SAMPLE-CS.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.CompanyExperditious.title" />
                    </div>
                    <IntlMessages id="report.CompanyExperditious.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.CompanyExperditious.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=CER"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload(BASE_API_URL + CS_report, 'VIRAC-SAMPLE-CS.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.StandardCompany.title" />
                    </div>
                    <IntlMessages id="report.StandardCompany.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.StandardCompany.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=SCR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload('http://www.africau.edu/images/default/sample.pdf', 'VIRAC-SAMPLE-SCR.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.ComprehensiveCompany.title" />
                    </div>
                    <IntlMessages id="report.ComprehensiveCompany.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.ComprehensiveCompany.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=CCR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload('http://www.africau.edu/images/default/sample.pdf', 'VIRAC-SAMPLE-CCR.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line">
                  <IntlMessages id="report.industry" />
                </div>
                {vsicReportData && vsicReportData.length > 0 && (
                  <div className="">
                    <div className="swiper-container">
                      <div className="swiper-wrapper">
                        {vsicReportData.map((props, key) => {
                          return (
                            <div className="swiper-slide" key={key}>
                              <Link to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                <img className="report-image" src={props.thumbnailUrl} alt={props.reportName} />
                              </Link>
                              <div className="report-name mt-4 mb-3" style={{ height: "40px" }}>
                                <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                  {language === "en" && props.reportEName ? <span>{props.reportEName}</span> : <span>{props.reportName}</span>}
                                </Link>
                              </div>

                              {/* Tạm ẩn description */}
                              {/* <div className="report-des">
                                {language === "en" && props.enSummary ? <span>{props.enSummary}</span> : <span>{props.summary}</span>}
                              </div> */}
                              {language === "en" && !props.enIsPurchased && (
                                <div className="report-price-container">
                                  {this.buttonCart(props)}
                                  <span className="report-price">{formatMoney(parseInt(props.enPrice, 10))} VND</span>
                                </div>
                              )}
                              {language === "vi" && !props.isPurchased && (
                                <div className="report-price-container">
                                  {this.buttonCart(props)}
                                  <span className="report-price">{formatMoney(parseInt(props.price, 10))} VND</span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="swiper-navigation">
                      <div className="swiper-btn btn-prev swiper-button-prev">
                        <span className="material-icons">chevron_left</span>
                      </div>
                      <div className="swiper-btn btn-next swiper-button-next">
                        <span className="material-icons">chevron_right</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* {this.state.initAutoReport === true && (
            <div className="auto-report-container">
              <AutoReportTemplate></AutoReportTemplate>
            </div>
          )} */}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    reducer: state.companyReducer,
    // detail: state.companyReducer.companyDetail,
    reportReducer: state.reportReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    },

    //Báo cáo doanh nghiệp + báo cáo ngành
    getDataRelatedReportInCompanyDetail: (id) => {
      dispatch(actGetDataRelatedReportInCompanyDetail(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabRelatedReport));
