import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import * as companyCompareService from "../../services/companyCompare.service";
import { totalPage } from "../../utils/function.util";
import { companyPageSize } from "../../constants/pageSize";
import { actNotify } from "./../Fetch/actions";
import { redirectURL } from "../../utils/logoutCommon";
const language = localStorage.getItem("currentLanguage");
//SS Truc Tiep
function* actRequestLiveCharteCompare(params) {
  let body = {
    arrayRatio: params.body.arrayRatio,
    arrayAccount: params.body.arrayAccount,
    arrCompanyIds: params.body.arrCompanyIds
  };
  try {
    var response = yield call(companyCompareService.requestLiveCharteCompare, body);

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHART_CP_FAIL
    });
  }
}
export function* watchRequestLiveCharteCompare() {
  yield takeEvery(Types.REQUEST_LIVE_CHART_CP, actRequestLiveCharteCompare);
}

function* actRequestChartListCB(params) {
  let typeID = params.id;
  try {
    var response = yield call(companyCompareService.requestTotalChartListCB, typeID);
    if (response.data.success) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTLIST_CP_SUCCESS,
        data: response.data.data,
        typeID
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTLIST_CP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTLIST_CP_FAIL
    });
  }
}
export function* watchRequestListCbChart() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTLIST_CP, actRequestChartListCB);
}
export default function* rootSaga() {
  yield all([fork(watchRequestLiveCharteCompare), fork(watchRequestListCbChart)]);
}
