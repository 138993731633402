import * as Types from "../actions";

export const getRequestProvince = () => {
  return {
    type: Types.GET_DATA_PROVINCE
  };
};
export const getListProvinceSuccess = data => {
  return {
    type: Types.GET_DATA_PROVINCE_SUCCESS,
    data
  };
};
export const getListProvinceFail = () => {
  return {
    type: Types.GET_DATA_PROVINCE_FAIL
  };
};


export const getRequestDistrict = (id) => {
  return {
    type: Types.GET_DATA_DISTRICT,
    id
  };
};
export const getListDistrictSuccess = (data) => {
  return {
    type: Types.GET_DATA_DISTRICT_SUCCESS,
    data
  };
};
export const getListDistrictFail = () => {
  return {
    type: Types.GET_DATA_DISTRICT_FAIL
  };
};

export const getRequestCommune = (id) => {
  return {
    type: Types.GET_DATA_COMMUNE,
    id
  };
};
export const getListCommuneSuccess = data => {
  return {
    type: Types.GET_DATA_COMMUNE_SUCCESS,
    data
  };
};
export const getListCommuneFail = () => {
  return {
    type: Types.GET_DATA_COMMUNE_FAIL
  };
};
