import { callApiServer } from "../utils/callApiServer";
//GET - ALL - NEWS - BY CATEGORY
export const actGetNewsApi = (model) => {
  let params = {
    ...model,
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/News/NewList", { params: params });
};

//GET - HOT - NEWS
export const actGetHotNewsApi = () => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/News/Hot-News", { params: params });
};

//GET - NEWS - ITEM
export const actGetNewsByIdApi = (id) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/News/" + id, { params: params });
};
