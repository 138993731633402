import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { injectIntl } from "react-intl";
import { renderChartRefesh, renderChartRefeshRESET } from "../../redux/actions";
import IntlMessages from "../../utils/IntlMessages";
import { connect } from "react-redux";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openComponentsHome: false,
      openComponentsInfo: false,
      openComponentsCompare: false,
      openComponentsIndustry: false,
      openMarco: false,
      openMC: false,
      isOpen: false
    };
  }
  activeRoute = (routeName) => {
    var path = routeName.split("/");
    if (path.length > 1) {
      return this.props.location.pathname.indexOf(path[1]) > -1 ? "active" : "";
    }
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  redirectRouter = (url) => {
    this.props.history.push(url);
  };
  componentDidMount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {}
  handleCollapse = () => {
    this.setState({
      openComponentsHome: false,
      openComponentsInfo: false,
      openComponentsCompare: false,
      openComponentsIndustry: false,
      openMarco: false,
      openMC: false,
      isOpen: false
    });
  };
  handleExpand = () => {
    this.setState({
      openComponentsHome: true,
      openComponentsInfo: true,
      openComponentsCompare: true,
      openComponentsIndustry: true,
      openMarco: true,
      openMC: true,
      isOpen: true
    });
  };
  handlechartRefesh = () => {
    this.props.chartRefesh();
  };
  handlechartRefeshRESET = () => {
    this.props.chartRefeshRESET();
  };
  render() {
    const { messages } = this.props.intl;
    // const { roles } = this.props;
    return (
      <div className="sidebar">
        {/* <div className="sideBar-logo sticky">
          <Link to={"/"}>
            <img src={lc_sidebar1} alt="" className="virac_logo1" />
            <img src={lc_sidebar2} alt="" className="virac_logo2" />
          </Link>
          <BurgetButton
            handlechartRefesh={this.handlechartRefesh}
            handlechartRefeshRESET={this.handlechartRefeshRESET}
            handleCollapse={this.handleCollapse}
            handleExpand={this.handleExpand}
            isOpen={this.state.isOpen}
          />
        </div> */}
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;
              if (prop.invisible) return null;
              if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                  <li className={`group-li ${this.activeRoute(prop.path)}`} key={key} title={messages[prop.name]}>
                    <a
                      data-toggle="collapse"
                      className={!this.state.isOpen ? "nav-link" : ""}
                      aria-expanded={this.state[prop.state]}
                      onClick={() => {
                        this.setState(st);
                      }}
                      href={prop.path}
                    >
                      {!this.state.isOpen ? (
                        <img
                          className="svg nav_sidebar_icon"
                          src={this.activeRoute(prop.path) !== "" ? prop.icon_active : prop.icon}
                          onClick={() => this.redirectRouter()}
                          alt=""
                        />
                      ) : (
                        ""
                      )}

                      <div className="nametabs des-link">
                        <IntlMessages id={prop.name} />
                      </div>
                    </a>
                    {/* <Collapse isOpen={this.state[prop.state]}>
                      <ul className="nav submenu">
                        {prop.views.map((prop, key) => {
                          if (prop.redirect) return null;
                          if (roles && roles === _Basic_ && prop.checkRoles)
                            return null;
                          return (
                            <li
                              className={this.activeRoute(prop.path)}
                              key={key}
                            >
                              <NavLink
                                to={prop.path}
                                className="nav-link"
                                activeClassName="active"
                                title={messages[prop.name]}
                                onMouseOver={(e) =>
                                  (e.currentTarget.children[0].src =
                                    prop.icon_active)
                                }
                                onMouseOut={
                                  this.activeRoute(prop.path) !== ""
                                    ? (e) =>
                                        (e.currentTarget.children[0].src =
                                          prop.icon_active)
                                    : (e) =>
                                        (e.currentTarget.children[0].src =
                                          prop.icon)
                                }
                              >
                                <img
                                  className="svg nav_sidebar_icon"
                                  src={
                                    this.activeRoute(prop.path) !== ""
                                      ? prop.icon_active
                                      : prop.icon
                                  }
                                  alt=""
                                />

                                <p>
                                  <IntlMessages id={prop.name} />
                                </p>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse> */}
                  </li>
                );
              }
              return (
                <li title={messages[prop.name]} className={this.activeRoute(prop.path)} key={key}>
                  <NavLink to={prop.path} className="nav-link " activeClassName="active" title={messages[prop.name]}>
                    <img className="svg nav_sidebar_icon" src={prop.icon} alt="" />
                    <div className="des-link">
                      <IntlMessages id={prop.name} />
                    </div>
                  </NavLink>
                </li>
              );
            })}
            {/* thong tin vi mo */}
            {/* <li
              title={messages[`menu.macroinformationorigin`]}
              className={`group-li ${this.activeRoute("/thong-tin-vi-mo")}`}>
              <a
                href="/thong-tin-vi-mo/ti-gia?t=Tỉ%20giá"
                aria-expanded={this.state.openMC}
                className={!this.state.isOpen ? ("nav-link") : ("")}
                onClick={() => this.setState({ openMC: !this.state.openMC })}
              >
                {!this.state.isOpen ? (<img
                  className="svg nav_sidebar_icon"
                  src={
                    this.activeRoute("/thong-tin-vi-mo/") !== ""
                      ? marco_n_active
                      : marco_n
                  }
                  alt=""
                />) : ("")}

                <p className="nametabs">
                  <IntlMessages id={"menu.macroinformationorigin"} />
                </p>
              </a>
              <Collapse isOpen={this.state.openMC}>
                <ul className="nav submenu">
                  {listData &&
                    listData.map((prop, key) => {
                      return (
                        <li
                          className={this.activeRoute(
                            "/thong-tin-vi-mo/" + prop.id
                          )}
                          key={key}
                        >
                          <NavLink
                            to={`/thong-tin-vi-mo/${prop.id}?t=${prop.name}`}
                            className="nav-link"
                            activeClassName="active"
                            title={prop.name}
                            onMouseOver={(e) =>
                              (e.currentTarget.children[0].src = marco_n_active)
                            }
                            onMouseOut={
                              this.activeRoute(
                                "/thong-tin-vi-mo/" + prop.id
                              ) !== ""
                                ? (e) =>
                                    (e.currentTarget.children[0].src = marco_n_active)
                                : (e) =>
                                    (e.currentTarget.children[0].src = marco_n)
                            }
                          >
                            <img
                              className="svg nav_sidebar_icon"
                              src={
                                this.activeRoute(
                                  "/thong-tin-vi-mo/" + prop.id
                                ) !== ""
                                  ? marco_n_active
                                  : marco_n
                              }
                              alt=""
                            />

                            <p>{prop.name}</p>
                          </NavLink>
                        </li>
                      );
                    })}
                  <li className={this.activeRoute("/thong-tin-vi-mo/ti-gia")}>
                    <NavLink
                      to={`/thong-tin-vi-mo/ti-gia?t=${
                        messages[`submenu.ExchangeRate`]
                      }`}
                      className="nav-link"
                      activeClassName="active"
                      title={messages[`submenu.ExchangeRate`]}
                      onMouseOver={(e) =>
                        (e.currentTarget.children[0].src = marco_n_active)
                      }
                      onMouseOut={
                        this.activeRoute("/thong-tin-vi-mo/ti-gia") !== ""
                          ? (e) =>
                              (e.currentTarget.children[0].src = marco_n_active)
                          : (e) => (e.currentTarget.children[0].src = marco_n)
                      }
                    >
                      <img
                        className="svg nav_sidebar_icon"
                        src={
                          this.activeRoute("/thong-tin-vi-mo/ti-gia") !== ""
                            ? marco_n_active
                            : marco_n
                        }
                        alt=""
                      />

                      <p>
                        {" "}
                        <IntlMessages id={"submenu.ExchangeRate"} />
                      </p>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li> */}
            {/* end thong tin vi mo */}
          </Nav>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    chartRefesh: () => dispatch(renderChartRefesh()),
    chartRefeshRESET: () => dispatch(renderChartRefeshRESET())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SideBar));
