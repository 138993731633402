import React, { Suspense } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
// import IntlMessages from "../../utils/IntlMessages";
import IntlMessages from "../../../utils/IntlMessages";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as Yup from "yup";

import Button from "../../../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert";

// File scss cua modal nay
import "../../../assets/scss/views/_view_invoice_request_modal.scss";
import { actPostInvoiceRequest } from "../../../services/accountManagement.service";

class ModalInvoiceRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: ""
    };
  }

  componentDidMount() {}

  handleCloseModal = () => {
    this.props.handleCloseModal();
  };

  handleShowAlert = (errorCode) => {
    const { messages } = this.props.intl;

    if (errorCode === 1) {
      this.setState({
        alert: (
          <SweetAlert danger title="" confirmBtnBsStyle="light" confirmBtnText={messages["user.requestInvoice.close_btn"]} onConfirm={this.handleHideAlert}>
            <p style={{ fontSize: "16px" }}>
              <IntlMessages id="user.requestInvoice.fail_confirmation_1" />
            </p>
          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert success title="" confirmBtnBsStyle="light" confirmBtnText={messages["user.requestInvoice.close_btn"]} onConfirm={this.redirectURL}>
            <p style={{ fontSize: "16px" }}>
              <IntlMessages id="user.requestInvoice.success_confirmation" />
            </p>
          </SweetAlert>
        )
      });
    }
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handleSubmit = async (orderId, companyName, taxCode, address, notes) => {
    try {
      let body = {
        productOrderId: orderId,
        companyName: companyName,
        taxId: taxCode,
        address: address,
        keepNote: notes
      };

      const response = await actPostInvoiceRequest(body);

      if (response && response.data) {
        this.handleShowAlert(response.data.errorCode);
      }
    } catch (error) {}
  };

  redirectURL = () => {
    this.handleCloseModal();
  };

  render() {
    const closeBtn = (
      <span onClick={this.handleCloseModal} className="material-icons close-btn">
        close
      </span>
    );

    const { messages } = this.props.intl;
    const { alert } = this.state;

    return (
      <React.Fragment>
        <div className="invoice-form">
          {alert}
          <div className="heading-form">
            <p className="heading-title">{messages["user.requestInvoice.title"]}</p>
            {closeBtn}
          </div>

          {/* this.props.taxCode */}
          <Formik
            initialValues={{
              orderId: "",
              companyName: this.props.companyName,
              taxCode: this.props.taxCode,
              address: this.props.address,
              notes: ""
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setTimeout(() => {
                this.handleSubmit(values.orderId, values.companyName, values.taxCode, values.address, values.notes);
                setSubmitting(false);
              }, 500);
            }}
            validationSchema={Yup.object().shape({
              orderId: Yup.string()
                .required(messages[`user.requestInvoice.orderId_error1`])
                .max(255, messages[`user.requestInvoice.mutual_error1`])
                .matches(/^[a-z0-9]+$/i, messages[`user.requestInvoice.orderId_error2`]),
              companyName: Yup.string()
                .required(messages[`user.requestInvoice.companyName_error1`])
                .max(255, messages[`user.requestInvoice.mutual_error1`]),
              taxCode: Yup.string()
                .required(messages[`user.requestInvoice.taxCode_error1`])
                .matches(/^\d{10}(\d{3})?$/, messages[`user.requestInvoice.taxCode_error2`])
                .min(10, messages[`user.requestInvoice.taxCode_error3`])
                .max(13, messages[`user.requestInvoice.taxCode_error4`]),
              address: Yup.string()
                .required(messages[`user.requestInvoice.address_error1`])
                .max(255, messages[`user.requestInvoice.mutual_error1`])
            })}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit, handleBlur, setFieldValue } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="row form-group">
                    <label className="col-3">
                      {/* {messages[`user.requestInvoice.orderId`]} */}
                      <IntlMessages id="user.requestInvoice.orderId" />
                      <span className="aterisk-class">*</span>
                    </label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="orderId"
                        autoComplete="off"
                        spellCheck="false"
                        maxLength="255"
                        placeholder={messages[`user.requestInvoice.orderId_placeholder`]}
                        value={values.orderId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.orderId && <div className="form-error">{errors.orderId}</div>}
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-3">
                      {messages[`user.requestInvoice.companyName`]}
                      <span className="aterisk-class">*</span>
                    </label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="companyName"
                        autoComplete="off"
                        spellCheck="false"
                        maxLength="255"
                        placeholder={messages[`user.requestInvoice.companyName_placeholder`]}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.companyName && <div className="form-error">{errors.companyName}</div>}
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-3">
                      {messages[`user.requestInvoice.taxCode`]}
                      <span className="aterisk-class">*</span>
                    </label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="taxCode"
                        autoComplete="off"
                        spellCheck="false"
                        maxLength="13"
                        placeholder={messages[`user.requestInvoice.taxCode_placeholder`]}
                        value={values.taxCode}
                        onChange={(e) => {
                          const reg = /^[0-9\b]+$/;

                          if (e.target.value === "" || reg.test(e.target.value)) {
                            setFieldValue("taxCode", e.target.value);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.taxCode && <div className="form-error">{errors.taxCode}</div>}
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-3">
                      {messages[`user.requestInvoice.address`]}
                      <span className="aterisk-class">*</span>
                    </label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        name="address"
                        autoComplete="off"
                        spellCheck="false"
                        maxLength="255"
                        placeholder={messages[`user.requestInvoice.address_placeholder`]}
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.address && <div className="form-error">{errors.address}</div>}
                    </div>
                  </div>

                  <div className="row form-group">
                    <label className="col-3">{messages[`user.requestInvoice.notes`]}</label>
                    <div className="col-9">
                      <textarea
                        className="form-control"
                        type="text"
                        name="notes"
                        autoComplete="off"
                        spellCheck="false"
                        placeholder={messages[`user.requestInvoice.notes_placeholder`]}
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.notes && <div className="form-error">{errors.notes}</div>}
                    </div>
                  </div>

                  <div className="row customer-note form-group">
                    <div>
                      <IntlMessages id="user.requestInvoice.customer_note_1" />
                    </div>
                    <div>
                      <IntlMessages id="user.requestInvoice.customer_note_2" />
                    </div>
                  </div>

                  <div className="form-submit">
                    <Button className="common-button w-20" onClick={this.handleCloseModal}>
                      <IntlMessages id="user.requestInvoice.cancel_btn" />
                    </Button>
                    <Button type="submit" className="common-button w-20" onClick={this.handleSubmitForm}>
                      <IntlMessages id="user.requestInvoice.confirm_btn" />
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    // handleInvoiceRequest: (orderId, companyName, taxCode, address, notes) => dispatch(actInvoiceRequest(orderId, companyName, taxCode, address, notes))
  };
};

ModalInvoiceRequest.defaultProps = {};

export default connect(mapDispatchToProps)(injectIntl(ModalInvoiceRequest));

// const MyComponent = ModalInvoiceRequest;

// export default function ModalInvoiceRequest(props) {
//   return (
//     <Suspense fallback="loading">
//       <MyComponent {...props} />
//     </Suspense>
//   );
// }
