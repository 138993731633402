import React, { Component } from "react";
import queryString from "query-string";
import getUrlParam from "../../utils/getUrlParam";
export class FaqsContent extends Component {
  state = {
    txtTitle: getUrlParam()["t"] ? queryString.parse(this.props.search).t : "",
    pax: getUrlParam()["pax"] ? getUrlParam()["pax"] : 1
  };

  componentDidUpdate() {
    var curentPax = getUrlParam()["pax"];
    let curentT = getUrlParam()["t"];
    if (curentPax !== undefined && curentT !== undefined) {
      if (curentPax !== this.state.pax) {
        this.setState({
          pax: curentPax,
          txtTitle: queryString.parse(this.props.search).t
        });
      }
    }
  }
  render() {
    return (
      <div className="faqs_content_detail">
        {
          this.state.txtTitle &&
          <h1>
            {this.state.txtTitle}
          </h1>
        }
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.data.reply
              ? this.props.data.reply
              : this.props.dataFirst.reply
          }}
        />
      </div>
    );
  }
}

export default FaqsContent;
