import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as faqService from "../../services/faq.service";
import * as Types from "../actions";
// import {requestAPIProvince} from "../../services/common.service";
// import {
//   actGetUserAccountApi,
// } from "../../services/accountManagement.service";
function* actRequestFAQ() {
  try {
    var response = yield call(faqService.actGetListFAQ);
    
    if (response.data.data) {
      yield put({
        type: Types.GET_LIST_FAQ_SUCCESS,
        data: response.data.data.faqCategorys
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_LIST_FAQ_FAIL
    });
  }
}

function* watchRequestFAQ() {
  yield takeEvery(Types.GET_LIST_FAQ, actRequestFAQ);
}
// GET-BANNER
function* actBanner() {
  try {
    var response = yield call(faqService.actBannerService);
    if (response.data.data) {
      yield put({
        type: Types.GET_BANNER_SUCCESS,
        data: response.data.data.banner
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_BANNER_FAIL
    });
  }
}


function* watchRequestBANNER() {
  yield takeEvery(Types.GET_BANNER, actBanner);
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestFAQ),
    fork(watchRequestBANNER)
  ]);
}
