import { callApiServer } from "../utils/callApiServer";
import { HistoryAccessed, HistoryRequest, notification } from "../constants/pageSize";
// User account - thông tin tài khoản

export const actGetUserAccountApi = () => {
  return callApiServer.get("/Users/profile");
};

// History Request - lịch sử yêu cầu
export const actGetHistoryRequested_new = (params) => {
  return callApiServer.get("/Order/get-request-history", { params: params });
};
export const actGetRequestOrderCode = () => {
  return callApiServer.get("/Order/get-request-order-code");
};

export const actGetHistoryPurchase = (params) => {
  return callApiServer.get("/Order/get-purchase-history", { params: params });
};

// Yeu cau xuat hoa don
export const actPostInvoiceRequest = (body) => {
  return callApiServer.post("/Users/generate-issue-invoice-tax", body);
};

export const actGetFillInfo = () => {
  return callApiServer.get("/Users/get-user-company-tax-info", {});
};

//History Accessed - lịch sử điểm
export const actGetHistoryPoint = (params) => {
  return callApiServer.get("/virace-point/payment-history", { params: params });
};
//History Accessed - lịch sử truy cập

export const actGetHistoryAccessedApi = (page) => {
  const params = {
    pageIndex: page,
    pageSize: HistoryAccessed
  };
  return callApiServer.get("/AuditLog/AccessHistory", { params: params });
};

//Notification - thông báo

export const actGetNotificationsApi = (page) => {
  const params = {
    pageIndex: page,
    pageSize: notification
  };
  return callApiServer.get("/Message/MessageList", { params: params });
};

export const actDeleteNotificationsApi = (id) => {
  return callApiServer.delete("/Message/Delete/" + id);
};

export const actChangeStatusSeenMessageApi = (id) => {
  return callApiServer.get("/Message/isSeenMessage/" + id);
};

//Change Password - Đổi mật khẩu

export const actChangePasswordApi = (body) => {
  return callApiServer.post("/Users/ChangePassword", body);
};

//Change LANG

export const actChangeLang = (body) => {
  return callApiServer.post("/Users/language", body);
};

// Virace Point
export const actGetCurrentPoint = () => {
  return callApiServer.get("/virace-point/current-point");
};
export const actGetListRecharge = () => {
  return callApiServer.get("/virace-point/get-top-up-list");
};

export const actRecharge = (body) => {
  return callApiServer.post("/virace-point/top-up", body);
};

export const actPayByPoint = (body) => {
  return callApiServer.post("/virace-point/pay", body);
};

export const actPayByPointInCart = (body) => {
  return callApiServer.post("/virace-point/cart-pay", body);
};

export const actPayByOtherMethod = (body) => {
  return callApiServer.post("/payment/pay-now", body);
};

// Kiểm tra trạng thái đơn hàng
export const actGetStatusOrder = (orderId) => {
  return callApiServer.get(`/payment/check-transaction?orderId=${orderId}`);
};
