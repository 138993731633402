import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import close_modals from "../../../assets/images/ic/close_modals.svg";
import IntlMessages from "../../../utils/IntlMessages";

class ModalForgetPass extends React.Component {
    handleCloseModal = () => {
        this.props.handleCloseForget(this.props.success);
    };

    render() {
        const { toggleForgetPass, success } = this.props;
        const closeBtn = (
            <button
                className="close"
                onClick={this.handleCloseModal}
                style={{
                    opacity: 1,
                    width: "15px",
                    height: "15px",
                    paddingRight: "30px"
                }}
            >
                <img src={close_modals} alt="" />
            </button>
        );

        return (
            <React.Fragment>

                <Modal
                    className="modalForgetPass"
                    isOpen={toggleForgetPass}
                    size="md"
                    centered
                >
                    <ModalHeader
                        close={closeBtn}
                    >
                        <IntlMessages id="user.Notification" />
                    </ModalHeader>

                    <ModalBody>
                        {success ? <IntlMessages id="newWord.resetPass" /> : <IntlMessages id="auth.changePassFail" />
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}


export default ModalForgetPass;
