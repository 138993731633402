import { callApiServer } from "../utils/callApiServer";

//SS truc tiep
export const requestLiveCharteCompare = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CompareBusinesses/Compare_TT_Chart?lang=" + params.lang, body);
};

export const requestTotalChartListCB = (id) => {
  var params = {
    languagues: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/CompareBusinesses/Menu_Compare?id=" + id, { params: params });
};
