import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { newsPageSize } from "../../constants/pageSize";
import * as newsService from "../../services/news.service";
import * as Types from "../actions";
import * as actions from "./actions";

// GET - ALL NEWS

function* watchGetAll() {
    yield takeEvery(Types.GET_ALL, getAll);
}

function* getAll(action) {
    try {
        var model = {
            id: action.payload.cateID,
            pageIndex: action.payload.pageIndex,
            pageSize: newsPageSize
        };
        var response = yield call(newsService.actGetNewsApi, model);
        if (response.data) {
            var { posts, totalPages } = response.data.data;
            yield put(actions.getNewsSuccess(posts, totalPages));
        }
    } catch (err) {
        yield put(actions.getNewsFail(err));
    }
}

// GET HOT NEWS

function* watchGetHotNews() {
    yield takeEvery(Types.GET_HOT_NEWS, getHotNews);
}

function* getHotNews() {
    try {
        var response = yield call(newsService.actGetHotNewsApi);
        if (response.data) {
            var { posts, totalPages } = response.data.data;
            yield put(actions.getHotNewsSuccess(posts, totalPages));
        }
    } catch (err) {
        yield put(actions.getHotNewsFail(err));
    }
}

// GET - NEWS - ITEM

function* watchGetNewsItem() {
    yield takeEvery(Types.GET_NEWS_BY_ID, getNewsItem);
}

function* getNewsItem(action) {
    var id = action.payload.id;
    try {
        var response = yield call(newsService.actGetNewsByIdApi, id);
        if (response.data) {
            var newsItem = response.data.data;
            yield put(actions.getNewsByIdSuccess(newsItem));
        } else {
            yield put(actions.getNewsByIdFail("err"));
        }
    } catch (err) {
        yield put(actions.getNewsByIdFail(err));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetAll),
        fork(watchGetHotNews),
        fork(watchGetNewsItem),
    ]);
}
