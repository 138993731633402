import React from "react";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import PropsType from "prop-types";

const AlertWarning = ({ confirmToFunc, cancleToFunc, message, title, confirmBtnText, cancelBtnText }) => {
  return (
    <SweetAlert
      warning
      style={{ display: "block" }}
      title={title ? title : "Confirm"}
      showCancel
      customClass="swal-wide"
      confirmBtnText={confirmBtnText ? confirmBtnText : "Confirm"}
      cancelBtnText={cancelBtnText ? cancelBtnText : "Cancel"}
      confirmBtnCssClass="confirmBtn swal2-styled"
      cancelBtnCssClass="cancelBBtn swal2-styled"
      onConfirm={() => confirmToFunc()}
      onCancel={() => cancleToFunc()}
    >
      {message}
    </SweetAlert>
  );
};

export default AlertWarning;
AlertWarning.propTypes = {
  confirmToFunc: PropsType.func,
  cancleToFunc: PropsType.func,
  message: PropsType.string
};
