import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./index.reducers";
import sagas from "./sagas";
import { actLogoutchecked } from "./Authentication/actions";
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const initialState = {};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  initialState,
  compose(composeEnhancer(applyMiddleware(...middlewares)))
);
sagaMiddleware.run(sagas);

if (module.hot) {
  module.hot.accept("./index.reducers", () => {
    const nextRootReducer = require("./index.reducers");
    store.replaceReducer(nextRootReducer);
  });
}

export function actLogout(cbSuccess) {
  store.dispatch(actLogoutchecked(cbSuccess));
}

