import React, { Component } from "react";
import findNotFound from "../assets/images/404-error.png";
import { injectIntl } from "react-intl";
import IntlMessages from "../utils/IntlMessages";
import { Link } from "react-router-dom";
class FindnotfoundNoTitle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    return (
      <div className="views_find_notfound">
        <div className="search-no-result-wrap">
          <div className="search-no-result-text mb-3">
            <div>
              <h1>
                <IntlMessages id="search.notfound.text-1" />
              </h1>
              <p>
                <IntlMessages id="search.notfound.text-2" />
              </p>
            </div>
          </div>
          <div className="search-no-result-img">
            <img src={findNotFound} alt="findNotFound" />
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(FindnotfoundNoTitle);
