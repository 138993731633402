import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import IndustryFilter from "../../components/IndustryFilter/IndustryFilter";
import Swiper from "swiper";
import img1 from "../../assets/images/IF_IR/statistic-1.jpg";
import img2 from "../../assets/images/IF_IR/statistic-2.jpg";
import img3 from "../../assets/images/IF_IR/statistic-3.jpg";

import { Button } from "semantic-ui-react";
import ModalCookie from "./ModalCookieAccept";
import { getCookie } from "../../utils/cookies";

export class IFIRHome extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      toggleModalCookie: true
    };
  }

  componentDidMount() {
    document.title = "VIRACE | IF-IR Home";

    this._isMounted = true;
    setTimeout(() => {
      this.swiper1();
      this.swiper2();
    }, 1000);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.setState = () => {
      return;
    };
  };

  // statistic slide
  swiper1 = () => {
    new Swiper(".swiper-1", {
      grabCursor: true,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: ".swiper-pagination-1",
        clickable: true
      },
      dynamicBullets: true
    });
  };

  // report slide
  swiper2 = () => {
    new Swiper(".swiper-2", {
      grabCursor: true,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: ".swiper-pagination-2",
        clickable: true
      },
      dynamicBullets: true
    });
  };

  viewIndustryReport = () => {
    var model = {
      page: 1,
      q: "",
      filterType: "all"
    };
    this.props.history.push(`/danh-sach-bao-cao-nganh?page=${model.page}&q=${model.q}&filterType=${model.filterType}`);
  };

  viewStatistic = () => {
    this.props.history.push(
      `/thong-ke-co-ban?statistic=SO_DOANH_NGHIEP,SO_LAO_DONG,NGUON_VON_BINH_QUAN,TAI_SAN_CO_DINH_VA_DAU_TU_DAI_HAN_BINH_QUAN,DOANH_THU_THUAN,LOI_NHUAN_TRUOC_THUE,THUE_VA_CAC_KHOAN_NOP_NGAN_SACH`
    );
  };

  handleModalCookie = () => {
    this.setState({
      toggleModalCookie: !this.state.toggleModalCookie
    });
  };

  render() {
    return (
      <>
        <div className="if-ir-home">
          <div className="card-item search-info-section">
            <IndustryFilter handleSearch={() => {}} {...this.props} />
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 mb-4">
              <div className="card-item slide_banner">
                <div className="card-title">
                  <IntlMessages id="industry.filter.statistic" />
                </div>
                <div className="swiper swiper-1" style={{ width: "100%" }}>
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <img src={img1} alt="img1" />
                    </div>
                    <div className="swiper-slide">
                      <img src={img2} alt="img2" />
                    </div>
                    <div className="swiper-slide">
                      <img src={img3} alt="img3" />
                    </div>
                  </div>
                  <div className="swiper-pagination swiper-pagination-1" />
                  <div className="overlay-block"></div>
                  <Button className={`common-button view-more`} onClick={() => this.viewStatistic()}>
                    <IntlMessages id="industry.view" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-4">
              <div className="card-item slide_banner">
                <div className="card-title">
                  <IntlMessages id="industry.filter.report" />
                </div>
                <div className="swiper swiper-1 video-container" style={{ width: "100%" }}>
                  <div className="swiper-wrapper">
                    {/* <div className="swiper-slide">
                    <img src={img4} alt="img4" />
                  </div>
                  <div className="swiper-slide">
                    <img src={img5} alt="img5" />
                  </div>
                  <div className="swiper-slide">
                    <img src={img6} alt="img1" />
                  </div> */}
                    <div className="swiper-slide">
                      <iframe
                        type="text/html"
                        className="container-video"
                        width="100%"
                        height="400px"
                        src="https://www.youtube.com/embed/fPLMB1c1YuI?mute=1&autoplay=1&controls=0&loop=1&playlist=fPLMB1c1YuI&rel=0?vq=hd1080"
                        title="Video IF promo final"
                        frameBorder="0"
                        allow="mute; autoplay; controls; loop; playlist; rel; vq;  encrypted-media"
                        allowFullScreen
                      ></iframe>{" "}
                    </div>
                  </div>
                  {/* <div className="swiper-pagination swiper-pagination-1" /> */}
                  <div className="overlay-block"></div>
                  <Button className={`common-button view-more`} onClick={() => this.viewIndustryReport()}>
                    <IntlMessages id="industry.view" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IFIRHome));
