import React, { Component } from "react";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import queryString from "query-string";
import { injectIntl } from "react-intl";
import IndustryFilter from "../../components/IndustryFilter/IndustryFilter";
import { actSearchIndustryReport, actAddToCart } from "../../redux/actions";
import LoaderTable from "../../components/LoaderTable";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination/PaginationSimple";
import paramFromData from "../../utils/paramFromData";
import { formatMoney } from "../../utils/formatMoney";
import Findnotfound from "../../components/Findnotfound";
import { handleFlyBuyCart } from "../../assets/js/handleflybuycart.js";
import IntlMessages from "../../utils/IntlMessages";
import { Input, Checkbox } from "semantic-ui-react";
import Button from "../../components/CustomButton/CustomButton";
import { callApiServer } from "../../utils/callApiServer";
import { saveAs as importedSaveAs } from "file-saver";
import ConfirmPay from "../../views/AccountsManagement/ConfirmPayComponent.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

class IndustryReportList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "",
      filterType: getUrlParam()["filterType"] ? queryString.parse(this.props.location.search).filterType : "",
      activePage: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
      categoryList: [],
      categoryListSelected: [],
      yearOfReportList: ["2021", "2022", "2023"],
      yearOfReportListSelected: [],
      isShowCategoryFilter: false,
      isShowYearFilter: false,

      industryReportList: [] // ds bc ngành
    };
  }

  handlePaginationChange = (e, { activePage }) => {
    const { txtsearch, filterType, categoryListSelected, yearOfReportListSelected } = this.state;
    this.setState({ activePage }, () => {
      var model = {
        page: activePage,
        searchText: txtsearch,
        filterType,
        categoryListSelected,
        yearOfReportListSelected
      };
      this.props.history.push(paramFromData(model));
      this.props.actSearchIndustryReport(model);
    });
  };

  componentDidMount() {
    document.title = "VIRACE | Industry Report List";

    this._isMounted = true;
    this.getListCategory();
    this.getListYear();
    if (getUrlParam()["q"] !== undefined || getUrlParam()["page"] !== undefined || getUrlParam()["filterType"] !== undefined) {
      this.handleSubmit(queryString.parse(this.props.location.search).q, getUrlParam()["filterType"], getUrlParam()["page"], this.state.categoryListSelected, this.state.yearOfReportListSelected);
    }

    setTimeout(() => {
      handleFlyBuyCart();
    }, 500);
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.setState = () => {
      return;
    };
  };

  handleSearch = () => {
    return null;
  };

  // tìm kiếm
  handleSubmit = (searchText, filterType, page, categoryListSelected, yearOfReportListSelected) => {
    var model = {
      page,
      searchText,
      filterType,
      categoryListSelected: categoryListSelected,
      yearOfReportListSelected: yearOfReportListSelected
    };
    this.props.actSearchIndustryReport(model);
  };

  // Add item to cart
  handleBuy = (data) => {
    const language = localStorage.getItem("currentLanguage");

    let model = {
      id: data.id,
      name: data.reportName,
      englishName: data.reportEName,
      requestData: data.reportName,
      price: language === "vi" ? data.price : data.enPrice,
      point: language === "vi" ? data.point : data.enPoint,
      currency: "VND",
      viracServiceId: 2, // báo cáo ngành
      type: 2,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuy(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} onClick={() => this.handleBuyReport(data)}>
          <span className={"cart-icon material-icons text-cyan"}>shopping_cart</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";
    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.id && item.lang === language).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  handleBuyReport(data) {
    const language = localStorage.getItem("currentLanguage");

    let point = language === "vi" ? data.point : data.enPoint;
    let price = language === "vi" ? data.price : data.enPrice;

    this.openPopupConfirmBuy(point, price, data.productType, 0, data);
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");

    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.id}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            reportLang={language}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }
  async getListCategory() {
    try {
      const response = await callApiServer.get("/IFReportStatistic/ReportCategory");
      if (response && response.data && response.data.data) {
        let responseCategory = response.data.data;
        let newCategory = [];
        responseCategory.map((item) => {
          newCategory.push({
            id: item.id,
            name: item.name,
            enName: item.enName,
            isChecked: false
          });
        });
        this.setState({
          categoryList: newCategory
        });
      } else {
        this.setState({
          categoryList: []
        });
      }
    } catch (error) {
      this.setState({
        categoryList: []
      });
    }
  }

  async getListYear() {
    try {
      const response = await callApiServer.get("/IFReportStatistic/ListYearOfReport");
      if (response && response.data && response.data.data) {
        let responseYear = response.data.data;
        let newYear = [];
        responseYear.map((item) => {
          newYear.push({
            value: item,
            isChecked: false
          });
        });

        this.setState({
          yearOfReportList: newYear
        });
      } else {
        this.setState({
          yearOfReportList: []
        });
      }
    } catch (error) {
      this.setState({
        yearOfReportList: []
      });
    }
  }
  handleFilterCategory = () => {
    let { isShowCategoryFilter } = this.state;
    this.setState({
      isShowCategoryFilter: !isShowCategoryFilter,
      isShowYearFilter: false
    });
  };
  handleFilterYear = () => {
    let { isShowYearFilter } = this.state;
    this.setState({
      isShowYearFilter: !isShowYearFilter,
      isShowCategoryFilter: false
    });
  };
  handleCheckedCategory = (data, index) => {
    let { categoryList, categoryListSelected } = this.state;
    if (data.isChecked) {
      categoryListSelected = categoryListSelected.filter((i) => i !== data.id);
    } else {
      categoryListSelected.push(data.id);
    }
    categoryList.forEach((e) => {
      if (e.id === data.id) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      categoryListSelected,
      categoryList
    });
  };
  handleCheckedYear = (data, index) => {
    let { yearOfReportList, yearOfReportListSelected } = this.state;
    if (data.isChecked) {
      yearOfReportListSelected = yearOfReportListSelected.filter((i) => i !== data.value);
    } else {
      yearOfReportListSelected.push(data.value);
    }
    yearOfReportList.forEach((e) => {
      if (e.value === data.value) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      yearOfReportListSelected,
      yearOfReportList
    });
  };

  cancelFilterCategory = () => {
    let { categoryList } = this.state;
    categoryList.forEach((item) => {
      item.isChecked = false;
    });
    this.setState(
      {
        isShowCategoryFilter: false,
        isShowYearFilter: false,
        categoryListSelected: [],
        categoryList: categoryList
      },
      () => {
        this.applyFilter();
      }
    );
  };
  cancelFilterYear = () => {
    let { yearOfReportList } = this.state;
    yearOfReportList.forEach((item) => {
      item.isChecked = false;
    });
    this.setState(
      {
        isShowCategoryFilter: false,
        isShowYearFilter: false,
        yearOfReportListSelected: [],
        yearOfReportList: yearOfReportList
      },
      () => {
        this.applyFilter();
      }
    );
  };
  applyFilter = () => {
    const { txtsearch, filterType, categoryListSelected, yearOfReportListSelected, activePage } = this.state;

    var model = {
      page: activePage,
      searchText: txtsearch,
      filterType,
      categoryListSelected,
      yearOfReportListSelected
    };
    this.props.actSearchIndustryReport(model);
    this.setState({
      isShowCategoryFilter: false,
      isShowYearFilter: false
    });
  };
  async handleDownloadReport(reportId, fileName, reportLang) {
    try {
      this.setState({
        isDownloadingIndustryReport: true
      });
      const urlDownload = `/IFReportStatistic/download-if-report-full?ifId=${reportId}&lang=${reportLang}`;
      const response = await callApiServer.post(
        urlDownload,
        {},
        {
          responseType: "blob"
        }
      );

      if (response && response.data) {
        importedSaveAs(response.data, fileName);
        this.setState({
          isDownloadingIndustryReport: false
        });
      } else {
        this.setState({
          isDownloadingIndustryReport: false
        });
      }
    } catch (error) {
      this.setState({
        isDownloadingIndustryReport: false
      });
    }
  }
  // Nút tải Báo cáo/Demo tiếng Anh
  buttonEnReportDownload = (data) => {
    const reportName = data.reportEName ? data.reportEName : data.reportName;
    const reportFile = data.urlFullFileEn ? data.urlFullFileEn : "";
    const fileExtension = reportFile.split(".").pop();

    return (
      <React.Fragment>
        <div className="text-cyan link" style={{ marginLeft: "0px" }} onClick={() => this.handleDownloadReport(data.id, reportName + `.${fileExtension}`, "en")}>
          <em className="material-icons" style={{ fontSize: "25px", marginRight: "8px" }}>
            download
          </em>{" "}
          {data.enIsPurchased ? <IntlMessages id="industry.downloadReport" /> : <IntlMessages id="industry.downloadDemo" />}
        </div>
      </React.Fragment>
    );
  };

  // Nút tải Báo cáo/Demo tiếng Việt
  buttonViReportDownload = (data) => {
    const reportName = data.reportName ? data.reportName : data.reportEName;
    const reportFile = data.urlFullFile ? data.urlFullFile : "";
    const fileExtension = reportFile.split(".").pop();

    return (
      <React.Fragment>
        <div className="text-cyan link" style={{ marginLeft: "0px" }} onClick={() => this.handleDownloadReport(data.id, reportName + `.${fileExtension}`, "vi")}>
          <em className="material-icons" style={{ fontSize: "25px", marginRight: "8px" }}>
            download
          </em>
          {/* {data.isPurchased ? "Tai bao cao" : "Tai demo"} */}
          {data.isPurchased ? <IntlMessages id="industry.downloadReport" /> : <IntlMessages id="industry.downloadDemo" />}
        </div>
      </React.Fragment>
    );
  };
  render() {
    const { txtsearch, filterType, yearOfReportList, categoryList, isShowCategoryFilter, isShowYearFilter } = this.state;
    const language = localStorage.getItem("currentLanguage");
    const { activePage } = this.state;
    const { messages } = this.props.intl;
    const { industryReportLoading, industryReportList, totalPage, totalItems } = this.props.industryInformationReducer;
    return (
      <div className="industry-report-results if-ir-home">
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="card-item search-info-section">
          <IndustryFilter txtsearch={txtsearch} filterType={filterType} handleSearch={this.handleSearch} {...this.props} />
        </div>
        {industryReportLoading ? (
          <LoaderTable isLoaded={!industryReportLoading} styleCustom={{ height: "300px" }} />
        ) : industryReportList && industryReportList.length > 0 ? (
          <div className="card-item mt-4 mb-4">
            <div className="search-result-heading pb-3">
              <p className="search-result-title">{`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["report.industry"]})`}</p>
              <div className="report-filter-box">
                <div className="filter-category">
                  <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterCategory()}>
                    <span className="material-icons mr-1">filter_alt</span>
                    <IntlMessages id="newWord.categoryFilter" />
                  </div>
                  {isShowCategoryFilter && (
                    <div className="filter-container">
                      <div className="statistic-table-fillter" id="statistic-table-fillter">
                        <div className="search-filter">
                          <div>
                            <div className="search-result">
                              {categoryList.map((item, index) => {
                                return (
                                  <div className="input-list-Checkbox" key={index}>
                                    <Checkbox checked={item.isChecked} onChange={() => this.handleCheckedCategory(item, index)} />
                                    <span>{language === "vi" ? item.name : item.enName}</span>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="d-flex justify-content-center pb-2">
                              <Button className={`common-button`} onClick={() => this.applyFilter()}>
                                OK
                              </Button>
                              <Button className={`common-button ml-3`} onClick={() => this.cancelFilterCategory()}>
                                <IntlMessages id="common.cancelBtn" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="filter-year">
                  <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterYear()}>
                    <span className="material-icons mr-1">filter_alt</span>
                    <IntlMessages id="newWord.yearFilter" />
                  </div>
                  {isShowYearFilter && (
                    <div className="filter-container year">
                      <div className="statistic-table-fillter" id="statistic-table-fillter">
                        <div className="search-filter">
                          <div>
                            <div className="search-result">
                              {yearOfReportList.map((item, index) => {
                                return (
                                  <div className="input-list-Checkbox" key={index}>
                                    <Checkbox checked={item.isChecked} onChange={() => this.handleCheckedYear(item, index)} />
                                    <span>{item.value}</span>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="d-flex justify-content-center pb-2">
                              <Button className={`common-button`} onClick={() => this.applyFilter()}>
                                OK
                              </Button>
                              <Button className={`common-button ml-3`} onClick={() => this.cancelFilterYear()}>
                                <IntlMessages id="common.cancelBtn" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row search-result search-result-list">
              {industryReportList.map((props, key) => {
                return (
                  <div className="col-xl-6 pb-5" key={key}>
                    <div className="report-detail">
                      <Link to={`/chi-tiet-bao-cao-nganh/${props.id ? props.id : ""}`}>
                        <img className="report-image" src={props.imageUrl} alt={props.imageUrl} />
                      </Link>

                      <div className="report-info">
                        <div className="report-name">
                          <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.id ? props.id : ""}`}>
                            {language === "en" && props.reportEName ? <span className="report-name-large">{props.reportEName}</span> : <span className="report-name-large">{props.reportName}</span>}
                          </Link>
                        </div>
                        <div className="report-buy-download">
                          {language === "en" && !props.enIsPurchased && (
                            <div className="report-price-container">
                              {this.buttonCart(props)}
                              <span className="report-price" style={{ color: "#212529" }}>
                                {formatMoney(parseInt(props.enPrice, 10))} VND
                              </span>
                            </div>
                          )}
                          {language === "vi" && !props.isPurchased && (
                            <div className="report-price-container">
                              {this.buttonCart(props)}
                              <span className="report-price" style={{ color: "#212529" }}>
                                {formatMoney(parseInt(props.price, 10))} VND
                              </span>
                            </div>
                          )}

                          {/* Nút tải Báo cáo/Demo */}
                          {language === "vi" && <div className="download-button">{this.buttonViReportDownload(props)}</div>}
                          {language === "en" && <div className="download-button">{this.buttonEnReportDownload(props)}</div>}
                        </div>
                        <div className="report-desc">{language === "en" && props.eDescription ? <span>{props.eDescription}</span> : <span>{props.description}</span>}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="foot-pagination">
              <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
            </div>
          </div>
        ) : (
          <div className="card-item mt-4 mb-4">
            <Findnotfound goHome={"/trang-chu-if-ir"} isSearch={true} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    industryInformationReducer: state.industryInformationReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSearchIndustryReport: (body) => {
      dispatch(actSearchIndustryReport(body));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IndustryReportList));
