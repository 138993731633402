import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { formatMoney, formatMoney_2 } from "../../utils/formatMoney";
import { callApiServer } from "../../utils/callApiServer";
import LoaderTable from "../../components/LoaderTable";
import { actAddToCart } from "../../redux/actions";
import NotificationAlert from "react-notification-alert";

import { actPayByPointInCart } from "../../services/accountManagement.service";
class ConfirmPayOnlyPointComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }
  componentDidMount() {}

  async confirmPay() {
    this.setState({
      isLoading: true
    });
    try {
      const { id, numberViPoint, detail, lang, body } = this.props;

      // const bodyRequest = {
      //   productId: id,
      //   pointValue: numberViPoint
      // };
      const response = await actPayByPointInCart(body);
      if (response && response.data && response.data.data) {
        this.props.success(response.data.data);
      } else if (response && response.data && response.data.message === "Require top-up point") {
        this.props.notEnoughPoint();
      }
    } catch (error) {
      this.setState({
        isLoading: false
      });
      this.notiFy("fail");
    }
  }

  cancelPay() {
    this.props.cancel();
  }

  notiFy(status) {
    const { messages } = this.props.intl;

    if (status === "success") {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["cart.isPurchaseSuccess"]}</div>
          </div>
        ),
        type: "success",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    } else {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["newWord.somthingWrong"]}</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  render() {
    const { isLoading } = this.state;
    const { numberViPoint, oldNumberVipoint } = this.props;
    return (
      <React.Fragment>
        <NotificationAlert ref="notificationAlert" />

        {isLoading ? (
          <LoaderTable isLoaded={!isLoading} styleCustom={{ height: "500px" }} />
        ) : (
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.confirmAction.subtitle_1"></IntlMessages>

                {oldNumberVipoint !== 0 && oldNumberVipoint !== numberViPoint && (
                  <span className="ml-1" style={{ textDecoration: "line-through" }}>
                    {formatMoney_2(oldNumberVipoint, 2)} VP
                  </span>
                )}
                <span className="ml-1">{formatMoney_2(numberViPoint, 2)} VP</span>
                <IntlMessages id="vpoint.confirmAction.subtitle_2"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPay()}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.confirmPay()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>{" "}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    userAccount: state.accountReducer.userAccount
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    // Thêm vào giỏ hàng
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmPayOnlyPointComponent));
// export default ConfirmPay;
