/* eslint-disable no-unused-vars */
import React, { Component, Suspense } from "react";
import { hostImg } from "../../constants/hostImg";
import { connect } from "react-redux";
import Swiper from "swiper";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import CompanyInfoFillter from "../../components/CompanyInfo/CompanyInfoFillter";
import { actSearchCompanyAutocomplete, actRequestFinancial, actGetTopRevenua } from "../../redux/actions";
import { debounce } from "lodash";
import { actBanner } from "../../redux/FAQ/actions";
import LoaderTable from "../../components/LoaderTable";
import Linechart from "../../components/ChartDemo/Linechart";
import Area from "../../components/ChartDemo/Area";
import { Redirect } from "react-router-dom";
import Button from "../../components/CustomButton/CustomButton";

import { Table } from "reactstrap";
import Findnotfound from "../../components/Findnotfound";
import { formatMoney } from "../../utils/formatMoney";
import { Link } from "react-router-dom";
import StackedBar100PercentChart from "../../components/ChartDemo/StackedBar100PercentChart";
import { numberFormat } from "../../utils/numberFormat";
import { _Standard_, _Premium, _Basic_, _Premium_ } from "../../constants/rolesModel";
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";

import * as companyInfoService from "../../services/companyInfo.service";
class CRHomePage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      txtsearch: "", // nội dung tìm kiếm
      page: 1,
      isLoading: false,
      results: [], // dropdown search list
      dataResults: [],
      value: "",
      listSuggest: [], // danh sách DN truy cập gần nhất
      userAccount: [],
      txtsearchError: "", // lỗi tìm kiếm,

      loadingDataChart: false,
      loadingTopLastestAccess: false,
      dataTopLastestAccess: [],
      loadingTopRevenua: false,
      dataTopRevenua: [],
      loadingTop5RatioAccess: false,
      dataTop5RatioAccess: [],
      loadingTop5RatioAccessByAll: false,
      dataTop5RatioAccessByAll: []
    };
  }

  redirectRouter = (url) => {
    this.props.history.push(url);
  };

  componentDidMount() {
    document.title = "VIRACE | CR Home";

    this._isMounted = true;
    this.props.actBanner();
    setTimeout(() => {
      this.swiper();
    }, 1000);
    // fake
    const model1 = {
      arrHeso: ["16", "17", "18", "19", "20"],
      arrKv: [],
      id: "a7773e36-4fc1-4200-ba7b-509c93338f39",
      isCheck: false
    };
    this.props.requestFinancial(model1);
    //

    // Lấy thông tin các biểu đồ
    this.getTopAccessLatest();

    this.props.getDataTopRevenua();

    this.getTop5RarioAccess();
    this.getTop5RarioAccessByAll();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.setState = () => {
      return;
    };
  };

  swiper = () => {
    new Swiper(".swiper-container-banner", {
      grabCursor: true,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      dynamicBullets: true
    });
  };

  // submit search form
  handleSubmit = () => {
    this.handlePushPaging(1);
  };

  // nhấn enter
  onKeyDown = (event) => {
    this.setState({
      ...this.state,
      txtsearchError: ""
    });
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  };

  // chuyển đến trang tìm kiếm
  paramFromData = (data) => {
    const keys = Object.keys(data);
    var paramStr = "";
    keys.map((key, index) => {
      if (data[key]) {
        if (index === 0) paramStr = paramStr.concat(`?${key}=${data[key]}`);
        else paramStr = paramStr.concat(`&${key}=${data[key]}`);
      }
      return null;
    });
    return "/ket-qua-tra-cuu-thong-tin-doanh-nghiep" + paramStr;
  };

  handlePathnamePush = (data) => {
    // window.location = this.paramFromData(data);
    this.props.history.push(this.paramFromData(data));
  };

  // tìm kiếm theo page
  handlePushPaging = (page) => {
    const { txtsearch } = this.state;
    if (txtsearch.trim().length < 3) {
      this.setState({
        ...this.state,
        txtsearchError: "companyFilter.textInput.minLength"
      });
      return;
    }
    this.setState({
      ...this.state,
      txtsearchError: ""
    });
    var modelxx = {
      page: page,
      q: txtsearch.trim()
    };
    this.handlePathnamePush(modelxx);
  };

  handleChangeInputFilter = (e) => {
    const label = e.target.name;
    const value = e.target.value;
    this.setState({
      [label]: value
    });
  };

  // click chọn ở dropdown list
  handleResultSelect = (e, { result }) => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    if (roles && (roles === _Basic_ || roles === _Standard_)) {
      this.actAlert(messages[`newWord.upgradeAccount`]);
      return;
    } else {
      if (result) {
        this.setState({ value: result.title, txtsearch: result.title }, () => this.pushDetailCompany(result.id));
      }
    }
  };

  // chuyển đến trang chi tiết DN
  pushDetailCompany = (id) => {
    if (id !== null && id !== undefined) {
      this.props.history.push("/chi-tiet-doanh-nghiep/" + id);
    }
  };

  // thay đổi thông tin tìm kiếm
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value, txtsearch: value }, () => this.requestAutocomplete(value));
  };

  requestAutocomplete = debounce((value) => {
    this.props.actSearchAutocomplete({ txtsearch: value });
  }, 500);

  fucResultFilter = (dataResults) => {
    setTimeout(() => {
      if (this.state.value.length < 1)
        return this.setState({
          isLoading: false,
          results: [],
          value: ""
        });
      this.setState({
        isLoading: false,
        results: dataResults
      });
    }, 500);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getListSuggest();
    if (this.props.dataReducer.searchAutoComplete.dataAutoComplete !== nextProps.dataReducer.searchAutoComplete.dataAutoComplete) {
      this.setState(
        {
          dataResults: nextProps.dataReducer.searchAutoComplete.dataAutoComplete
        },
        () => this.fucResultFilter(nextProps.dataReducer.searchAutoComplete.dataAutoComplete)
      );
    }

    //Kiểm tra nếu user là roles Standard thì không cho xem chi tiết doanh nghiệp
    if (nextProps) {
      const { roles } = nextProps;
      const { messages } = nextProps.intl;
      if (roles && roles === _Standard_) {
        this.actAlert(messages[`newWord.upgradeAccount`]);
        setTimeout(() => {
          this.props.history.push("/menu-dieu-huong/");
        }, 3000);
      }
    }
  }

  // danh sách DN truy cập gần nhất
  getListSuggest = () => {
    const idUser = this.props.userAccount.id || null;
    let listSuggest = !localStorage.getItem("aListSuggest") ? [] : null ? [] : JSON.parse(localStorage.getItem("aListSuggest"));
    let result = listSuggest.filter((item) => item.idUser === idUser);
    this.setState({
      listSuggest: (result[0] && result[0].arrSuggest) || []
    });
  };

  // Start: Lấy thông tin data chart

  // ++++ Bảng doanh nghiệp truy cập gần nhất +++
  getTopAccessLatest() {
    this.setState({
      loadingTopLastestAccess: true
    });
    companyInfoService
      .actgetTopAccessLatest()
      .then((res) => {
        if (res.data && res.data.data.length) {
          this.setState({
            dataTopLastestAccess: res.data.data
          });
        }
      })
      .catch()
      .finally(
        this.setState({
          loadingTopLastestAccess: false
        })
      );
  }
  // ++++ Biểu đồ tỉ lệ doanh nghiệp theo quy mô 5 ngành người dùng truy cập +++
  getTop5RarioAccess() {
    this.setState({
      loadingTop5RatioAccess: true
    });
    companyInfoService
      .actgetTop5RatioAccess()
      .then((res) => {
        if (res.data && res.data.data.length) {
          this.setState({
            dataTop5RatioAccess: res.data.data
          });
        }
      })
      .catch()
      .finally(
        this.setState({
          loadingTop5RatioAccess: false
        })
      );
  }

  // ++++ Biểu đồ tỉ lệ doanh nghiệp theo quy mô 5 ngành tất cả người dùng truy cập +++
  getTop5RarioAccessByAll() {
    this.setState({
      loadingTop5RatioAccessByAll: true
    });
    companyInfoService
      .actgetTop5RatioAccessByAll()
      .then((res) => {
        if (res.data && res.data.data.length) {
          this.setState({
            dataTop5RatioAccessByAll: res.data.data
          });
        }
      })
      .catch()
      .finally(
        this.setState({
          loadingTop5RatioAccessByAll: false
        })
      );
  }

  // End: Lấy thông tin data chart

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  openPageElectricBill = () => {
    this.props.history.push("/electronic-bill");
  };
  render() {
    // 1 - slider trang chủ, 2 - banner tin tức, 3 - slider QL tài khoản, 4 - banner QL tài khoản
    const { bannerData } = this.props.faqReducer;
    const { searchAutoComplete } = this.props.dataReducer;
    const { isEmailSpecial } = this.props.userAccount;

    const homeBanner = bannerData.filter((item) => item.position === 1);
    const {
      txtsearch,
      listSuggest,
      txtsearchError,
      isLoading,
      loadingDataChart,
      loadingTopLastestAccess,
      dataTopLastestAccess,
      dataTop5RatioAccess,
      loadingTop5RatioAccess,
      dataTop5RatioAccessByAll,
      loadingTop5RatioAccessByAll
    } = this.state;
    const { messages } = this.props.intl;
    const { roles } = this.props;

    //data top revenua
    const { dataTopRevenua, loadingDataTopRevenua } = this.props.dataReducer;

    // fake
    const { loadingFNChart } = this.props.dataReducer.dataFinacial;

    //
    const language = localStorage.getItem("currentLanguage");

    return (
      <React.Fragment>
        {this.state.alert}{" "}
        <div className="views-home">
          {roles && roles === _Basic_ && <Redirect to="/trang-chu-if-ir" />}
          <div className="home-panel-content">
            {/* <div className="slide_banner">
              <div className="swiper swiper-container-banner responsive-banner">
                <div className="swiper-wrapper">
                  {homeBanner &&
                    homeBanner.length > 0 &&
                    homeBanner.map((props, index) => {
                      return (
                        <div
                          key={index}
                          className="swiper-slide sliderImage"
                          style={{
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundImage: `url(${props.url ? props.url : ""})`
                          }}
                        >
                          <div className="home-head">
                            <div className="home-head-left">
                              <div dangerouslySetInnerHTML={{ __html: props.desc ? props.desc : "" }} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="swiper-pagination" />
              </div>
            </div> */}
            <div className="container-fluid mt-4 mb-4">
              <div className="row mb-4">
                <div className={"col-9 p-0 " + (isEmailSpecial ? "col-12" : "")}>
                  <div className="content-group">
                    <section className="home-div-search card-item background-navy search-info-section radius-5px">
                      <CompanyInfoFillter
                        handleSubmit={this.handleSubmit}
                        txtsearch={txtsearch}
                        handleChangeInputFilter={this.handleChangeInputFilter}
                        onKeyDown={this.onKeyDown}
                        loading={searchAutoComplete.isLoading}
                        {...this.props}
                        listSuggest={listSuggest}
                        // autocomplete
                        value={this.state.value}
                        results={this.state.results}
                        handleResultSelect={this.handleResultSelect}
                        handleSearchChange={this.handleSearchChange}
                        isRedirect={true}
                        txtsearchError={txtsearchError}
                      />
                    </section>
                  </div>
                </div>
                {!isEmailSpecial && (
                  <div className="col-3">
                    <div className="content-group">
                      <section className="home-div-search card-item background-navy search-info-section radius-5px h-100">
                        <div className="company-search-bar">
                          <div className="heading d-flex justify-content-center">
                            <div className="section-title ">
                              <IntlMessages id="menu.electronicBillSearch" />
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                          <Button className={`common-button ml-3 `} onClick={this.openPageElectricBill}>
                            <IntlMessages id="filter.buttonSearch" />
                            <em className="material-icons">search</em>
                          </Button>
                        </div>
                      </section>
                    </div>
                  </div>
                )}
              </div>

              {/* DATA CHART */}
              <div className="row">
                <div className="col-12 mb-4 px-0">
                  <div className="card-item">
                    <div className="table-title">
                      <IntlMessages id="crhome.table1" />
                    </div>
                    {loadingTopLastestAccess ? (
                      <LoaderTable isLoaded={!loadingTopLastestAccess} styleCustom={{ height: "500px" }} />
                    ) : (
                      <div className="table-custom">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <IntlMessages id="table.sttTable" />
                              </th>
                              <th>
                                <IntlMessages id="ownership.Companyname" />
                              </th>
                              {isEmailSpecial === true && (
                                <th>
                                  <IntlMessages id="crhome.netRevenue" />
                                </th>
                              )}
                              {isEmailSpecial === true && (
                                <th>
                                  <IntlMessages id="crhome.netRevenueGrowthRate" />
                                </th>
                              )}
                              {isEmailSpecial === true && (
                                <th>
                                  <IntlMessages id="detailCompany.Netprofit" />
                                </th>
                              )}

                              <th>
                                <IntlMessages id="crhome.vsic" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataTopLastestAccess && dataTopLastestAccess.length > 0 ? (
                              dataTopLastestAccess.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <span>{index + 1}</span>
                                    </td>
                                    <td>
                                      <Link className="text-cyan" target="_blank" to={`/chi-tiet-doanh-nghiep/${item.companyId ? item.companyId : ""}`}>
                                        <span>{item.companyName}</span>
                                      </Link>
                                    </td>
                                    {isEmailSpecial === true && (
                                      <td>
                                        <span>{item.netRevenue ? formatMoney(parseInt(item.netRevenue, 10)) + messages["suffixD"] : "N/A"}</span>
                                      </td>
                                    )}
                                    {isEmailSpecial === true && (
                                      <td>
                                        {item.netRevenueGrowthRate === "Infinity" ? (
                                          <span>N/A</span>
                                        ) : (
                                          <span
                                            className={classnames({
                                              "text-danger": item.netRevenueGrowthRate < 0,
                                              "text-success": item.netRevenueGrowthRate > 0
                                            })}
                                          >
                                            {item.netRevenueGrowthRate ? numberFormat(item.netRevenueGrowthRate) + "%" : "N/A"}
                                          </span>
                                        )}
                                      </td>
                                    )}
                                    {isEmailSpecial === true && (
                                      <td>
                                        <span
                                          className={classnames({
                                            "text-danger": item.netProfit < 0,
                                            "text-success": item.netProfit > 0
                                          })}
                                        >
                                          {item.netProfit ? formatMoney(parseInt(item.netProfit, 10)) + messages["suffixD"] : "N/A"}
                                        </span>
                                      </td>
                                    )}

                                    <td>
                                      <span>{item.vsicCode ? item.vsicCode : ""}</span>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7">
                                  <Findnotfound
                                    title={messages[`cart.NoteNo`]}
                                    customStyle={{
                                      border: 0,
                                      boxShadow: "none",
                                      marginTop: 0,
                                      height: "500px"
                                    }}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row equal-height">
                {isEmailSpecial === true && (
                  <div className="col-lg-4 mb-4 mb-lg-0 px-0 pr-lg-4">
                    <div className="card-item">
                      <div className="chart-info chart-line flex-column align-items-stretch">
                        {loadingDataTopRevenua ? (
                          <LoaderTable isLoaded={!loadingDataTopRevenua} styleCustom={{ height: "500px" }} />
                        ) : (
                          <Area
                            dataSuf={localStorage.getItem("currentLanguage") === "en" ? "VND" : "VNĐ"}
                            title={messages["crhome.chart1"]}
                            suf={true}
                            percent={false}
                            dataChart={dataTopRevenua}
                            enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className={`${isEmailSpecial ? "col-lg-4" : "col-lg-6"} mb-4 mb-lg-0 px-0 pr-lg-4`}>
                  <div className="card-item">
                    <div className="chart-info chart-line flex-column align-items-stretch">
                      {loadingTop5RatioAccess ? (
                        <LoaderTable isLoaded={!loadingTop5RatioAccess} styleCustom={{ height: "500px" }} />
                      ) : (
                        <StackedBar100PercentChart
                          title={messages["crhome.chart2"]}
                          suf={true}
                          backgroundColor={"#02164d"}
                          dataChart={dataTop5RatioAccess}
                          needSwitchName={true}
                          enabledToolTip={true}
                          tickColor={"rgba(144, 155, 175, 0.3)"}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={`${isEmailSpecial ? "col-lg-4" : "col-lg-6"}  px-0 `}>
                  <div className="card-item">
                    <div className="chart-info chart-line flex-column align-items-stretch">
                      {loadingTop5RatioAccessByAll ? (
                        <LoaderTable isLoaded={!loadingTop5RatioAccessByAll} styleCustom={{ height: "500px" }} />
                      ) : (
                        <StackedBar100PercentChart
                          title={messages["crhome.chart3"]}
                          suf={true}
                          backgroundColor={"#02164d"}
                          dataChart={dataTop5RatioAccessByAll}
                          enabledToolTip={true}
                          needSwitchName={true}
                          tickColor={"rgba(144, 155, 175, 0.3)"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqReducer: state.faqReducer,
    CommonReducer: state.CommonReducer,
    userAccount: state.accountReducer.userAccount,
    dataReducer: state.companyReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // tìm kiếm dropdown
    actSearchAutocomplete: (body) => {
      dispatch(actSearchCompanyAutocomplete(body));
    },
    // banner
    actBanner: () => dispatch(actBanner()),
    // fake
    // Chart ROA/ROE - Tỷ lệ TTLN/Biên LNT
    requestFinancial: (body) => {
      dispatch(actRequestFinancial(body));
    },

    getDataTopRevenua: () => {
      dispatch(actGetTopRevenua());
    }
  };
};
const MyComponent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CRHomePage));

export default function CRHome(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
