import React, { Component } from "react";
import { connect } from "react-redux";
import AccountsManagementDetail from "./AccountsManagementDetail";
export class AccountsManagement extends Component {
  componentDidMount() {
    document.title = "VIRACE | Account Management";

    // this.props.actBanner();
  }
  
  render() {
    return (
      <div className="views_accounts_manament">
        <div className="manament-panel-content mb-4">
          <AccountsManagementDetail {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqReducer: state.faqReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // banner
    // actBanner: () => dispatch(actBanner()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsManagement);
