import * as Types from "../actions";

//TỔNG QUAN NGÀNH
export const actSearchVSIC = model => {
  return {
    type: Types.GET_INDUSTRY_INFORMATION,
    payload: {
      model
    }
  };
};

export const getVSICInformationSuccess = (dataVSIC, totalItems,
  totalPage,
  pageIndex,
  pageCount, ) => {
  return {
    type: Types.GET_INDUSTRY_INFORMATION_SUCCESS,
    payload: {
      dataVSIC,
      totalItems,
      totalPage,
      pageIndex,
      pageCount,
    }
  };
};

export const getVSICInformationFail = message => {
  return {
    type: Types.GET_INDUSTRY_INFORMATION_FAIL,
    payload: {
      message
    }
  };
};

export const actGetTopVSIC = () => {
  return {
    type: Types.GET_TOP_VSIC
  };
};

export const getTopVSICSuccess = chartDAta => {
  return {
    type: Types.GET_TOP_VSIC_SUCCESS,
    payload: {
      chartDAta
    }
  };
};

//SẢN XUẤT - TIÊU THỤ - TỒN KHO
export const actGetDataProductToCompare = model => {
  return {
    type: Types.GET_DATA_PRODUCT_TO_COMPARE,
    payload: {
      model
    }
  };
};

export const getDataProductToCompareSuccess = dataProduct => {
  return {
    type: Types.GET_DATA_PRODUCT_TO_COMPARE_SUCCESS,
    payload: {
      dataProduct
    }
  };
};

export const actSendDataToCompare = model => {
  return {
    type: Types.SEND_DATA_TO_COMPARE,
    payload: {
      model
    }
  };
};

export const actGetDataChartExportIndustryInformationSuccess = data => {
  return {
    type: Types.GET_DATA_CHART_EXPORT_TO_SHOW_SUCCESS,
    payload: {
      data
    }
  };
};

export const actDelChartIndustryInformation = (key, id) => {
  return {
    type: Types.DELETE_CHART_INDUSTRY_INFORMATION,
    payload: {
      key,
      id
    }
  };
};

//CHỈ SỐ KINH TẾ NGÀNH
export const actGetDataFinancialToCompare = model => {
  return {
    type: Types.GET_DATA_FINANCIAL_TO_COMPARE,
    payload: {
      model
    }
  };
};

export const getDataFinancialToCompareSuccess = dataFinancial => {
  return {
    type: Types.GET_DATA_FINANCIAL_TO_COMPARE_SUCCESS,
    payload: {
      dataFinancial
    }
  };
};

export const handleSendDataFinancialToCompare = model => {
  return {
    type: Types.SEND_DATA_FINANCIAL_TO_COMPARE,
    payload: {
      model
    }
  };
};
export const handleSendDataFinancialToCompareReset = () => {
  return {
    type: Types.SEND_DATA_FINANCIAL_TO_COMPARE_RESET,
  
  };
};

export const getDataChartFinancialToCompareSuccess = dataFinancialChart => {
  return {
    type: Types.GET_DATA_CHART_FINANCIAL_TO_COMPARE_SUCCESS,
    payload: {
      dataFinancialChart
    }
  };
};

export const handleDeleteFinancialChart = key => {
  return {
    type: Types.DELETE_FINANCIAL_CHART,
    payload: {
      key
    }
  };
};

//SO SÁNH NGÀNH

export const actGetDataCompare = model => {
  return {
    type: Types.GET_DATA_COMPARE_INDUSTRY_INFORMATION,
    payload: {
      model
    }
  };
};

export const getDataCompareSuccess = dataCompare => {
  return {
    type: Types.GET_DATA_COMPARE_INDUSTRY_INFORMATION_SUCCESS,
    payload: {
      dataCompare
    }
  };
};

export const handleSendDataCompare = model => {
  return {
    type: Types.SEND_DATA_COMPARE,
    payload: {
      model
    }
  };
};

export const handleDeleteCompareChart = key => {
  return {
    type: Types.DELETE_COMPARE_CHART,
    payload: {
      key
    }
  };
};
export const actSearchCompanyAutocompleteIndustry = model => {
  return {
    type: Types.SEARCH_AUTOCOMPLETE_INDUSTRY,
    payload: {
      model
    }
  };
};

//So sánh Ngành - TB


// trung binh
export const actRequestChartSSNganh_TB = body => {
  return {
    type: Types.REQUEST_CHART_SSNGANH_TB,
    body
  };
};
//Tong gia tri
export const actRequestChartSSNganh_TGT = body => {
  return {
    type: Types.REQUEST_CHART_SSNGANH_TGT,
    body
  };
};
//Chart Chart
export const actRequestSSNganhChartDelete = id => {
  return {
    type: Types.REQUEST_CHART_SSNGANH_DELETE,
    id
  };
};


// Tra cứu thông tin ngành
export const actSearchIndustry = (model) => ({
  type: Types.SEARCH_INDUSTRY,
  payload: { model },
});

// Tra cứu báo cáo ngành
export const actSearchIndustryReport = (model) => ({
  type: Types.SEARCH_INDUSTRY_REPORT,
  payload: { model },
});

// Thống kê cơ bản - table
export const actStatisticIndustry = (model) => ({
  type: Types.STATISTIC_INDUSTRY,
  payload: { model },
});

// Thống kê nâng cao - table
export const actAdvanceStatisticIndustry = (model) => ({
  type: Types.ADVANCE_STATISTIC_INDUSTRY,
  payload: { model },
});

// ds năm để vẽ chart
export const actGetStatisticChartYear = () => ({
  type: Types.STATISTIC_INDUSTRY_CHART_YEAR,
});

// Thống kê cơ bản - chart
export const actStatisticIndustryChart = (model) => ({
  type: Types.STATISTIC_INDUSTRY_CHART,
  payload: { model },
});

// Thống kê nâng cao - chart
export const actAdvanceStatisticIndustryChart = (model) => ({
  type: Types.ADVANCE_STATISTIC_INDUSTRY_CHART,
  payload: { model },
});

// ds ngành vsic
export const actGetVsicList = () => ({
  type: Types.VSIC_LIST,
});

// ds sản phẩm
export const actGetProductList = () => ({
  type: Types.PRODUCT_LIST,
});

// chi tiết bc ngành
export const actGetIndustryReportDetail = (reportId) => ({
  type: Types.INDUSTRY_REPORT_DETAIL,
  reportId
});

// bc liên quan - DN liên quan
export const actGetRelatedReportAndCompany = (reportId) => ({
  type: Types.INDUSTRY_RELATED_REPORT,
  reportId
});