import { callApiServer } from "../utils/callApiServer";

export const requestAPIProvince = () => {
  return callApiServer.get("/Address/GetProvince");
};
export const requestAPIDistrict = (id) => {
  return callApiServer.get("/Address/GetDistrict/" + id);
};
export const requestAPICommune= (id) => {
  return callApiServer.get("/Address/GetCommune/" + id);
};
