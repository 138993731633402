import $ from "jquery";
export const handleFlyBuyCart = () => {
  $(document).ready(function() {
    $(".add-to-cart").on("click", function() {
      var cart = $(".cart-wrapper");
      var imgtodrag = $(this)
        .find(".cart-icon")
        .eq(0);
      if (imgtodrag) {
        var imgclone = imgtodrag
          .clone()
          .offset({
            top: imgtodrag.offset().top,
            left: imgtodrag.offset().left
          })
          .addClass("active")
          .css({
            opacity: "1!important",
            position: "absolute",
            height: "20px",
            width: "20px",
            "z-index": "9999"
          })
          .appendTo($("body"))
          .animate(
            {
              opacity: "1!important",
              top: cart.offset().top + 20,
              left: cart.offset().left + 10,
              width: 60,
              height: 60
            },
            1000
          );

        imgclone.animate(
          {
            width: 0,
            height: 0
          },
          function() {
            $(this).detach();
          }
        );
      }
    });
  });
};
