import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
// import indexRoutes from "./routes/index.jsx";
import { createBrowserHistory } from "history";
import { IntlProvider } from "react-intl";
import AppLocale from "./lang";

import LoginPage from "./views/Pages/LoginPage";
import RegisterPage from "./views/Pages/RegisterPage";

import LogoutPage from "./views/Pages/LogoutPage";
import LayoutApp from "./layout/LayoutApp";
import ForgotPassword from "./views/Pages/ForgotPassword";
import ChangeNewPassword from "./views/Pages/ChangingPassword";
import Contacts from "./views/Contacts/Contacts";
import WrongDevice from "./views/WrongDevice/WrongDevice";

import Faqs from "./views/Faqs/Faqs";
import viLang from "./lang/entries/vi-VN";

// import ReactGA from "react-ga";
import ReactGA from "react-ga4";
import ModalCookie from "./views/IF_IR/ModalCookieAccept";
import { getCookie } from "./utils/cookies";
import SweetAlert from "react-bootstrap-sweetalert";

const hist = createBrowserHistory();
let GA_KEY = "";
if (window.location.host.includes("virace.viracresearch")) {
  GA_KEY = `G-ENHR4NCQF5`;
} else {
  GA_KEY = `G-VWVXD0EW87`;
}

ReactGA.initialize(GA_KEY);
hist.listen((location, action) => {
  ReactGA.send(location.pathname + location.search);
});
const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("access_token") && localStorage.getItem("hash") ? (
          <Component {...props} />
        ) : (
          // <Redirect
          //   to={{
          //     pathname: "/menu-dieu-huong",
          //     state: { from: props.location }
          //   }}
          // />

          <Component {...props} isLogged={false} />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // toggleModalCookie: true,
      modalCookie: ""
    };
  }

  componentDidMount() {
    this.handleTemplateModalCookie();
  }

  handleTemplateModalCookie() {
    this.setState({
      modalCookie: (
        <SweetAlert customClass="accept-cookie-popup" default hideOverlay={false} buttons={false} allowEscape={false} showCancel={false} showConfirm={false}>
          <ModalCookie handleCloseModal={this.closeModalCookie} />
        </SweetAlert>
      )
    });
  }

  closeModalCookie = () => {
    this.setState({
      modalCookie: ""
    });
  };

  render() {
    const { locale } = this.props;
    const currentAppLocale = locale ? AppLocale[locale] : AppLocale["vi"];
    let haveViraceCookie = getCookie("virace_cookie");

    return (
      <React.Fragment>
        {/* {<div style={{ color: "white" }}>Accept cookies</div>} */}

        <IntlProvider locale={currentAppLocale ? currentAppLocale.locale : viLang.locale} messages={currentAppLocale ? currentAppLocale.messages : viLang.messages}>
          <React.Fragment>
            {haveViraceCookie === "" && this.state.modalCookie}
            <Router history={hist}>
              <Switch>
                <Route path="/dang-nhap" component={LoginPage} />
                <Route path="/dang-ki" component={RegisterPage} />

                <Route path="/dang-xuat" component={LogoutPage} />
                <Route path="/quen-mat-khau" component={ForgotPassword} />
                <Route path="/confirmtoken" component={ChangeNewPassword} />
                <Route path="/lien-he" component={Contacts} />
                <Route path="/wrong-device" component={WrongDevice} />

                <Route path="/faqs" component={Faqs} key="faqs" exact={true} />
                <Route path="/faqs/detail" component={Faqs} key="faqs-detail" exact={true} />

                <AuthRoute path="/" component={LayoutApp} />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.settings.locale
  };
};

export default connect(mapStateToProps)(App);
