import * as Types from "../actions";

export const actRequestFAQ = () => {
  return {
    type: Types.GET_LIST_FAQ
  };
};
// GET - BANNER
export const actBanner = () => {
  return {
    type: Types.GET_BANNER
  };
};

