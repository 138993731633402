import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import IntlMessages from "../../../utils/IntlMessages";

import Button from "../../../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "reactstrap";
import { actAdvanceSearchCompanyByIndustry } from "../../../redux/CompanyInfo/actions";
import { CompanyItem } from "../../../components/CompanyInfo/CompanyChild/CompanyItem";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { formatMoney } from "../../../utils/formatMoney";
import LoaderTable from "../../../components/LoaderTable";
import Findnotfound from "../../../components/Findnotfound";
import { _Demo_, _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";
import { copyArray } from "../../../utils/copyArray";
import { callApiServer } from "../../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";
import { Search, Input } from "semantic-ui-react";
import { debounce } from "lodash";

import { saveAs as importedSaveAs } from "file-saver";
import { actAddToCart } from "../../../redux/actions";
import ConfirmPay from "../../AccountsManagement/ConfirmPayComponent.jsx";

export class TabOwnership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDataPoint: [], // giai đoạn dữ liệu
      selectedIndustry: [], // ds ngành
      selectedTerms: [], // điều kiện tìm kiếm được thêm
      pageIndex: 1,
      pageSize: 10,
      activePage: 1,
      isSearch: false,
      dataSearch: {},
      isDownloading: false,
      isDownloading_2: false,

      // Đại diện pháp luật
      textSearchRepresent: "",
      pageIndexRepresent: 1,
      totalPageRepresent: [],
      listRepresent: [],
      totalRepresent: 0,
      searchingRepresent: false,
      isFirstRepresent: true,
      errTextSearchRepresent: "",

      // Chủ sở hữu
      textSearchOwner: "",
      pageIndexOwner: 1,
      totalPageOwner: [],
      listOwner: [],
      totalOwner: 0,
      searchingOwner: false,
      isFirstOwner: true,
      errTextSearchOwner: ""
    };
  }

  componentDidMount() {}

  // xóa điều kiện
  removeTerm = (index) => {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    this.props.companyReducer.selectedAdvanceSearchingCriteria = selectedAdvanceSearchingCriteria.filter((item, i) => i !== index);
    // let selectedTerms = this.state.selectedTerms.filter((item, i) => i !== index);
    this.setState({
      ...this.state
    });
  };

  // tìm kiếm
  searchTerms = (page) => {
    const { messages } = this.props.intl;
    const { pageSize } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    let registrationDates = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      this.setState({ isSearch: true });
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        } else if (e.type === "registration_date") {
          if (e.fromDate) {
            registrationDates.push({
              fromDate: e.fromDate ? e.fromDate : "",
              toDate: e.toDate ? e.toDate : ""
            });
          } else {
            registrationDates.push({
              toDate: e.toDate ? e.toDate : ""
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };

      var body = {
        pageIndex: page,
        pageSize: pageSize,
        areas,
        vsics,
        financials,
        companyTypes,
        legals,
        owners,
        importExportTurnover,
        registrationDates
      };
      this.props.advanceSearchCompanyByIndustry(body);
      this.setState({
        dataSearch: body
      });
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
  };
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.searchTerms(activePage));
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handleDownLoad(dataSearch) {
    const { messages } = this.props.intl;
    this.setState({
      isDownloading: true
    });
    const body = {
      areas: dataSearch.areas,
      financials: dataSearch.financials,
      vsics: dataSearch.vsics,
      companyTypes: dataSearch.companyTypes,
      legals: dataSearch.legals,
      owners: dataSearch.owners,
      importExportTurnover: dataSearch.importExportTurnover
    };
    callApiServer
      .post("Company/CompanyReportAdvancedSearch/export", body)
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }

  // Đại diện phap luật
  changeSearchRepresent = (event, data) => {
    if (data) {
      this.setState({
        ...this.state,
        textSearchRepresent: data.value
      });
    }
  };

  handleSearchRepresent = async (type) => {
    const valueSearch = this.state.textSearchRepresent;
    if (valueSearch.trim().length >= 3) {
      this.setState({
        searchingRepresent: true,
        isFirstRepresent: false,
        errTextSearchRepresent: ""
      });
      let pageIndex;
      if (type === "search") {
        pageIndex = 1;
        this.setState({
          pageIndexRepresent: 1
        });
      } else {
        pageIndex = this.state.pageIndexRepresent;
      }
      const url = `/Company/SearchLegalRepresentative?pageIndex=${pageIndex}&inputSearch=${this.state.textSearchRepresent}`;

      try {
        const response = await callApiServer.get(url);
        if (response && response.data && response.data.data && response.data.data.results) {
          this.setState({
            listRepresent: response.data.data.results,
            totalPageRepresent: Math.ceil(response.data.data.total / 5),
            totalRepresent: response.data.data.total,
            searchingRepresent: false
          });
        } else {
          this.setState({
            listRepresent: [],
            totalPageRepresent: [],
            totalRepresent: 0,
            searchingRepresent: false
          });
        }
      } catch (error) {
        this.setState({
          listRepresent: [],
          totalPageRepresent: [],
          totalRepresent: 0,
          searchingRepresent: false
        });
      }
    } else {
      this.setState({
        errTextSearchRepresent: "companyFilter.textInput.minLength"
      });
    }
  };
  handlePaginationChangeRepresent = (e, { activePage }) => {
    this.setState({ pageIndexRepresent: activePage }, () => this.handleSearchRepresent("changePage"));
  };
  // thêm điều kiện đại diện pháp luật
  addRepresent = (data) => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    let newDataRepresent = {
      type: "legal",
      userId: data.id,
      enName: data.enFullName,
      name: data.fullName,
      idPerson: data.idPerson
    };
    if (selectedAdvanceSearchingCriteria.length === 10) {
      this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
    } else {
      this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, newDataRepresent]);
      this.setState({
        ...this.state
      });
    }
  };

  checkDisableAddRepresent(presentId) {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    if (presentId && selectedAdvanceSearchingCriteria && selectedAdvanceSearchingCriteria.length) {
      const result = selectedAdvanceSearchingCriteria.filter((e) => e.type === "legal" && e.userId === presentId);
      if (result && result.length) {
        return true;
      } else return false;
    }
  }

  // Chủ sở hữu
  changeSearchOwner = (event, data) => {
    if (data) {
      this.setState({
        ...this.state,
        textSearchOwner: data.value
      });
    }
  };

  handleSearchOwner = async (type) => {
    const valueSearch = this.state.textSearchOwner;
    if (valueSearch.trim().length >= 3) {
      this.setState({
        searchingOwner: true,
        isFirstOwner: false,
        errTextSearchOwner: ""
      });
      let pageIndex;
      if (type === "search") {
        pageIndex = 1;
        this.setState({
          pageIndexOwner: 1
        });
      } else {
        pageIndex = this.state.pageIndexOwner;
      }
      const url = `/Company/SearchCompanyOwner?pageIndex=${pageIndex}&inputSearch=${this.state.textSearchOwner}`;

      try {
        const response = await callApiServer.get(url);
        if (response && response.data && response.data.data && response.data.data.results) {
          this.setState({
            listOwner: response.data.data.results,
            totalPageOwner: Math.ceil(response.data.data.total / 5),
            totalOwner: response.data.data.total,
            searchingOwner: false
          });
        } else {
          this.setState({
            listOwner: [],
            totalPageOwner: 0,
            totalOwner: 0,
            searchingOwner: false
          });
        }
      } catch (error) {
        this.setState({
          listOwner: [],
          totalPageOwner: 0,
          totalOwner: 0,
          searchingOwner: false
        });
      }
    } else {
      this.setState({
        errTextSearchOwner: "companyFilter.textInput.minLength"
      });
    }
  };
  handlePaginationChangeOwner = (e, { activePage }) => {
    this.setState({ pageIndexOwner: activePage }, () => this.handleSearchOwner("changePage"));
  };
  addOwner = (data) => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    let newDataRepresent = {
      type: "owner",
      userId: data.id,
      enName: data.enFullName,
      name: data.fullName,
      showId: data.showId,
      ownerShipType: data.ownerShipType
    };
    if (selectedAdvanceSearchingCriteria.length === 10) {
      this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
    } else {
      this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, newDataRepresent]);
      this.setState({
        ...this.state
      });
    }
  };

  checkDisableAddOwner(ownerId) {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    if (ownerId && selectedAdvanceSearchingCriteria && selectedAdvanceSearchingCriteria.length) {
      const result = selectedAdvanceSearchingCriteria.filter((e) => e.type === "owner" && e.userId === ownerId);
      if (result && result.length) {
        return true;
      } else return false;
    }
  }

  onKeyUpRepresent = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const valueSearch = this.state.textSearchRepresent;
      if (valueSearch.trim().length >= 3) {
        this.handleSearchRepresent("search");
      } else {
        this.setState({
          errTextSearchRepresent: "companyFilter.textInput.minLength"
        });
      }
    }
  };
  onKeyUpOwner = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const valueSearch = this.state.textSearchOwner;
      if (valueSearch.trim().length >= 3) {
        this.handleSearchOwner("search");
      } else {
        this.setState({
          errTextSearchOwner: "companyFilter.textInput.minLength"
        });
      }
    }
  };
  async handleDownLoad_2(selectedAdvanceSearchingCriteria) {
    const { messages } = this.props.intl;

    const toolTipContent = {
      basic: "",
      advance: selectedAdvanceSearchingCriteria
    };
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    let registrationDates = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        } else if (e.type === "registration_date") {
          if (e.fromDate) {
            registrationDates.push({
              fromDate: e.fromDate ? e.fromDate : "",
              toDate: e.toDate ? e.toDate : ""
            });
          } else {
            registrationDates.push({
              toDate: e.toDate ? e.toDate : ""
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
    this.setState({
      isDownloading_2: true
    });
    const body = {
      searchText: "",
      searchType: "Advanced",
      searchTooltip: JSON.stringify(toolTipContent),
      pageIndex: 0,
      pageSize: 10,
      areas: areas,
      financials: financials,
      vsics: vsics,
      companyTypes: companyTypes,
      legals: legals,
      owners: owners,
      importExportTurnover: importExportTurnover,
      registrationDates: registrationDates
    };
    try {
      const response = await callApiServer.post("/Company/export-search-create-product", body);
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.openPopupConfirmBuy(data.point, data.price, data.productType, 0, data, toolTipContent);
        this.setState({
          isDownloading_2: false
        });
      } else {
        this.setState({
          isDownloading_2: false
        });
      }
    } catch (error) {}
    this.setState({
      isDownloading_2: false
    });
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail, toolTipContent) {
    const { messages } = this.props.intl;
    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.productId}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            toolTipContent={toolTipContent}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail, toolTipContent) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail, toolTipContent);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  renderTime(time) {
    if (time) return moment(time).format("DD/MM/YYYY");
    else return "";
  }

  render() {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const { totalPage, loading, companyData, selectedAdvanceSearchingCriteria, advanceSearchLoading } = this.props.companyReducer;

    const {
      activePage,
      dataSearch,
      isDownloading,
      listRepresent,
      totalPageRepresent,
      pageIndexRepresent,
      searchingRepresent,
      totalRepresent,
      isFirstRepresent,
      errTextSearchRepresent,

      listOwner,
      totalPageOwner,
      pageIndexOwner,
      searchingOwner,
      totalOwner,
      isFirstOwner,
      errTextSearchOwner,
      isDownloading_2
    } = this.state;
    const { isEmailSpecial } = this.props.userAccount;

    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="tab_area">
          <div className="row equal-height mb-2">
            <div className="col-lg-7 col-xl-8 mb-4">
              <div className="card-item" style={{ height: "auto" }}>
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.selectSearchTerms" />
                </div>
                {/*  Đại diện pháp luật */}
                <div className="mb-5">
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.legalRepresentative" />
                  </div>
                  <div className="content-fillter">
                    <div className="company-search-bar">
                      <div className="ui action input fluid customFilter">
                        <Input
                          defaultValue=""
                          type="text"
                          className="customInput"
                          onChange={debounce(this.changeSearchRepresent, 150)}
                          onKeyUp={this.onKeyUpRepresent}
                          placeholder={messages["advanceSearch.legalRepresentative.placeHoder"]}
                        />
                        <Button disabled={loading} isLoading={loading} className={`common-button ml-3 ${loading ? "disabled" : ""}`} onClick={() => this.handleSearchRepresent("search")}>
                          <IntlMessages id="filter.buttonSearch" />
                          <em className="material-icons">search</em>
                        </Button>
                      </div>
                      {errTextSearchRepresent && (
                        <div className="form-error">
                          <IntlMessages id={errTextSearchRepresent} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="search-result mt-2">
                    {searchingRepresent === true && <LoaderTable isLoaded={!searchingRepresent} styleCustom={{ height: "500px" }} />}
                    {searchingRepresent === false && (
                      <div>
                        {listRepresent && listRepresent.length > 0 ? (
                          <div className="table-custom ">
                            <p
                              style={{
                                fontSize: "1.25rem",
                                fontWeight: "700",
                                letterSpacing: "0.025rem",
                                lineHeight: "1.375",
                                textTransform: "uppercase",
                                margin: "0",
                                color: "#fff"
                              }}
                            >
                              {`${messages["compare.searchResult"]} (${formatMoney(totalRepresent)} ${messages["advanceSearch.legalRepresentative"]})`}
                            </p>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>
                                    <IntlMessages id="table.sttTable" />
                                  </th>
                                  <th>
                                    <IntlMessages id="auth.fullName" />
                                  </th>
                                  <th>ID</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {listRepresent.map((prop, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <span>{(pageIndexRepresent - 1) * 5 + index + 1}</span>
                                      </td>
                                      <td>{language === "en" && prop.enFullName ? prop.enFullName : prop.fullName}</td>
                                      <td>{prop.idPerson}</td>
                                      <td>
                                        {this.checkDisableAddRepresent(prop.id) ? (
                                          ""
                                        ) : (
                                          <Button className={`common-button`} onClick={() => this.addRepresent(prop)}>
                                            <IntlMessages id="advanceSearch.addTerms" />
                                            {/* <em className="material-icons">add</em> */}
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <div className="foot-pagination">
                              <Pagination activePage={pageIndexRepresent} totalPages={totalPageRepresent} onPageChange={this.handlePaginationChangeRepresent} />
                            </div>
                          </div>
                        ) : (
                          <div>
                            {!isFirstRepresent && (
                              <Findnotfound
                                title={messages[`newWord.notAvailable`]}
                                isSearch={true}
                                customStyle={{
                                  border: 0,
                                  boxShadow: "none",
                                  height: "280px"
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* Chử sở hữu */}

                <div>
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.owner" />
                  </div>
                  <div className="content-fillter">
                    <div className="company-search-bar">
                      <div className="ui action input fluid customFilter">
                        <Input
                          defaultValue=""
                          type="text"
                          className="customInput"
                          onChange={debounce(this.changeSearchOwner, 150)}
                          onKeyUp={this.onKeyUpOwner}
                          placeholder={messages["advanceSearch.owner.placeHoder"]}
                        />
                        <Button disabled={loading} isLoading={loading} className={`common-button ml-3 ${loading ? "disabled" : ""}`} onClick={() => this.handleSearchOwner("search")}>
                          <IntlMessages id="filter.buttonSearch" />
                          <em className="material-icons">search</em>
                        </Button>
                      </div>
                      {errTextSearchOwner && (
                        <div className="form-error">
                          <IntlMessages id={errTextSearchOwner} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="search-result mt-2">
                    {searchingOwner === true && <LoaderTable isLoaded={!searchingOwner} styleCustom={{ height: "500px" }} />}
                    {searchingOwner === false && (
                      <div>
                        {listOwner && listOwner.length > 0 ? (
                          <div className="table-custom ">
                            <p
                              style={{
                                fontSize: "1.25rem",
                                fontWeight: "700",
                                letterSpacing: "0.025rem",
                                lineHeight: "1.375",
                                textTransform: "uppercase",
                                margin: "0",
                                color: "#fff"
                              }}
                            >
                              {`${messages["compare.searchResult"]} (${formatMoney(totalOwner)} ${messages["advanceSearch.owner"]})`}
                            </p>
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th>
                                    <IntlMessages id="table.sttTable" />
                                  </th>
                                  <th>
                                    <IntlMessages id="auth.fullName" />
                                  </th>
                                  <th>ID</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {listOwner.map((prop, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <span>{(pageIndexOwner - 1) * 5 + index + 1}</span>
                                      </td>
                                      <td>{language === "en" && prop.enFullName ? prop.enFullName : prop.fullName}</td>
                                      <td>{prop.showId}</td>
                                      <td>
                                        {this.checkDisableAddOwner(prop.id) ? (
                                          ""
                                        ) : (
                                          <Button className={`common-button`} onClick={() => this.addOwner(prop)}>
                                            <IntlMessages id="advanceSearch.addTerms" />
                                            {/* <em className="material-icons">add</em> */}
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <div className="foot-pagination">
                              <Pagination activePage={pageIndexOwner} totalPages={totalPageOwner} onPageChange={this.handlePaginationChangeOwner} />
                            </div>
                          </div>
                        ) : (
                          <div>
                            {!isFirstOwner && (
                              <Findnotfound
                                title={messages[`newWord.notAvailable`]}
                                isSearch={true}
                                customStyle={{
                                  border: 0,
                                  boxShadow: "none",
                                  height: "280px"
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.searchTerms" />
                </div>
                <div>
                  {selectedAdvanceSearchingCriteria.length > 0 && (
                    <div className="table-custom ">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <IntlMessages id="table.sttTable" />
                            </th>
                            <th>
                              <IntlMessages id="advanceSearch.term" />
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAdvanceSearchingCriteria.map((prop, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                {prop.type === "area" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${prop.area.districtName ? prop.area.districtName + ", " : ""}${
                                      prop.area.provinceName
                                    }`}</span>
                                  </td>
                                )}
                                {prop.type === "industry" && (
                                  <td>
                                    <IntlMessages id="sidebar.Industry" /> {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </td>
                                )}
                                {prop.type === "companyType" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :{language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} (
                                    {prop.dataPoint}):{" "}
                                  </td>
                                )}
                                {prop.type === "legal" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.idPerson}{" "}
                                  </td>
                                )}
                                {prop.type === "owner" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID: {prop.showId}{" "}
                                  </td>
                                )}
                                {prop.type === "finance" && (
                                  <td>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.criteriaUnit})`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_year" && (
                                  <td>
                                    {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${prop.criteria.unit}) `
                                        : ""}
                                      {prop.criteria.to ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${prop.criteria.unit})` : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <td>
                                    {prop.dataPoint === "import" ? <IntlMessages id="advanceSearch.importExport.import" /> : <IntlMessages id="advanceSearch.importExport.export" />}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </td>
                                )}

                                {prop.type === "registration_date" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.founding_date.condition_tilte" />: <IntlMessages id="advanceSearch.founding_date.condition_from" />{" "}
                                    {prop.fromDate ? " " + this.renderTime(prop.fromDate) : ""} <IntlMessages id="advanceSearch.founding_date.condition_to" />{" "}
                                    {prop.toDate ? this.renderTime(prop.toDate) : ""}
                                  </td>
                                )}
                                <td>
                                  <span className="material-icons check" onClick={() => this.removeTerm(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div className="mt-5 d-flex justify-content-center">
                    <Button className={`common-button`} onClick={() => this.searchTerms(1)} disabled={loading} isLoading={loading}>
                      <IntlMessages id="filter.buttonSearch" />
                      <em className="material-icons">search</em>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            {advanceSearchLoading === true && <LoaderTable isLoaded={!loading} styleCustom={{ height: "500px" }} />}
            {advanceSearchLoading === false && (
              <div>
                {companyData && companyData.length > 0 ? (
                  <div className="card-item mb-4 mt-0 company-info-content">
                    <div className="company-info-content-main w-100">
                      <div className="search-result-heading pb-3">
                        <p className="search-result-title mb-2">{`${messages["newWord.amountCompanies"]} (${formatMoney(companyData.length)} ${messages["newWord.companies"]})`}</p>
                        {isEmailSpecial ? (
                          <div>
                            {isDownloading ? (
                              <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                            ) : (
                              <div
                                className="btn-export-data text-cyan"
                                onClick={() => {
                                  this.handleDownLoad(dataSearch);
                                }}
                              >
                                <em className="material-icons">download</em>
                                <IntlMessages id="newWord.downloadSearchResult" />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {isDownloading_2 ? (
                              <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                            ) : (
                              <div
                                className="btn-export-data text-cyan"
                                onClick={() => {
                                  this.handleDownLoad_2(selectedAdvanceSearchingCriteria);
                                }}
                              >
                                <em className="material-icons">download</em>
                                <IntlMessages id="newWord.downloadSearchResult" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="search-result-list">
                        <div className="row">
                          {companyData.map((props, index) => {
                            return (
                              <CompanyItem
                                {...this.props}
                                key={index}
                                name={props.companyName}
                                nameEnglish={props.companyEnName}
                                id={props.id}
                                taxNumber={props.companyTax}
                                vsic={props.vsic}
                                address={props.companyAddress}
                                addressEnglish={props.companyEnAddress}
                                phoneNumber={props.companyPhone}
                                fax={props.companyFax}
                                webSite={props.companyWebsite}
                                vsicID={props.vsic}
                                isShowValueTooltip={false}
                                enNetProfitLabel={props.enNetProfitLabel}
                                enNetRevenueLabel={props.enNetRevenueLabel}
                                netProfitLabel={props.netProfitLabel}
                                netRevenueLabel={props.netRevenueLabel}
                                netProfitValue={props.netProfitValue}
                                netRevenueValue={props.netRevenueValue}
                                automateCompanyReportPdf={props.automateCompanyReportPdf}
                                automateCompanyReportExcel={props.automateCompanyReportExcel}
                                userAccount={this.props.userAccount}
                                registrationDate={props.registrationDates}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="foot-pagination">
                        <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-item">
                    <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    commonReducer: state.CommonReducer,
    companyReducer: state.companyReducer,
    industryInformationReducer: state.industryInformationReducer,
    userAccount: state.accountReducer.userAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    advanceSearchCompanyByIndustry: (body) => {
      dispatch(actAdvanceSearchCompanyByIndustry(body));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabOwnership));
