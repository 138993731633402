import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import { formatMoney, formatMoney_2 } from "../../utils/formatMoney";
import { callApiServer } from "../../utils/callApiServer";
import LoaderTable from "../../components/LoaderTable";
import { actAddToCart } from "../../redux/actions";
import NotificationAlert from "react-notification-alert";

import { actPayByPoint, actPayByOtherMethod } from "../../services/accountManagement.service";
class ConfirmPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      itemSelected: "",
      listOption: [
        {
          label: "vpoint.confirmAction.optionVipont",
          type: "Point"
        },
        {
          label: "vpoint.confirmAction.optionOther",
          type: "otherPayment"
        }
      ]
    };
  }
  componentDidMount() {
    const listOptionDefaut = this.state.listOption;
    if (this.props.openAddToCart) {
      this.setState({
        listOption: [
          ...listOptionDefaut,
          {
            label: "vpoint.confirmAction.addToCart",
            type: "cart"
          }
        ]
      });
    }
  }

  handleChoseOption(option) {
    this.setState({
      itemSelected: option
    });
  }

  async confirmPay() {
    const { itemSelected } = this.state;

    if (itemSelected === "cart") {
      this.handleAddToCart();
    } else {
      this.setState({
        isLoading: true
      });
      try {
        const { id, numberViPoint, numberMonney, type, lang } = this.props;
        const { itemSelected } = this.state;
        const language = localStorage.getItem("currentLanguage");

        if (itemSelected === "Point") {
          const body = {
            productId: id,
            productType: type,
            pointValue: numberViPoint,
            lang: this.props.reportLang ? this.props.reportLang : "vi"
          };
          const response = await actPayByPoint(body);
          if (response && response.data && response.data.message === "Success") {
            this.props.success();
          } else if (response && response.data && response.data.message === "Require top-up point") {
            this.props.notEnoughPoint();
          } else {
            this.setState({
              isLoading: false
            });
            this.notiFy("fail");
          }
        } else if (itemSelected === "otherPayment") {
          const body = {
            productId: id,
            productType: type,
            productPrice: numberMonney,
            productLang: this.props.reportLang ? this.props.reportLang : language
          };
          const response = await actPayByOtherMethod(body);
          if (response && response.data && response.data.data) {
            window.location.href = response.data.data.paymentUrl;
          } else {
            this.setState({
              isLoading: false
            });
            this.notiFy("fail");
          }
        }
      } catch (error) {
        // this.props.notEnoughPoint();

        this.setState({
          isLoading: false
        });
        this.notiFy("fail");
      }
    }
  }

  cancelPay() {
    this.props.cancel();
  }

  handleAddToCart() {
    const { detail, type, toolTipContent } = this.props;
    let typeProduct;
    if (type === "CompanyDetailView") {
      typeProduct = 6;
    } else if (type === "CompanyReportPdf") {
      typeProduct = 7;
    } else if (type === "CompanyReportExcel") {
      typeProduct = 8;
    } else if (type === "CompanySearchDownload") {
      typeProduct = 9;
    } else if (type === "Report") {
      typeProduct = 2;
    } else if (type === "Vsic") {
      typeProduct = 3;
    } else if (type === "VsicProduct") {
      typeProduct = 4;
    }
    let model;

    if (typeProduct === 2) {
      model = {
        id: this.props.id,
        name: detail.reportName,
        englishName: detail.reportEName,
        requestData: detail.reportName,
        price: this.props.reportLang === "vi" ? detail.price : detail.enPrice,
        point: this.props.reportLang === "vi" ? detail.point : detail.enPoint,
        currency: "VND",
        viracServiceId: typeProduct,
        type: typeProduct,
        productType: type,
        lang: this.props.reportLang,
        toolTipContent: toolTipContent
      };
    } else if (typeProduct === 3) {
      model = {
        id: this.props.id,
        name: detail.vsicName,
        englishName: detail.vsicEname,
        requestData: detail.vsicName,
        price: detail.price,
        point: detail.point,
        currency: "VND",
        viracServiceId: typeProduct,
        type: typeProduct,
        productType: type,
        lang: this.props.reportLang,
        toolTipContent: toolTipContent
      };
    } else if (typeProduct === 4) {
      model = {
        id: this.props.id,
        name: detail.productName,
        englishName: detail.productEnName,
        requestData: detail.productName,
        price: detail.price,
        point: detail.point,
        currency: "VND",
        viracServiceId: typeProduct,
        type: typeProduct,
        productType: type,
        lang: this.props.reportLang,
        toolTipContent: toolTipContent
      };
    } else {
      model = {
        id: detail.productId,
        name: detail.productName,
        englishName: detail.productEnName,
        requestData: detail.productName,
        price: detail.price,
        point: detail.point,
        currency: "VND",
        viracServiceId: typeProduct,
        type: typeProduct,
        productType: type,
        lang: "vi",
        toolTipContent: toolTipContent
      };
    }
    this.props.addToCart(model);
    if (type === "CompanyDetailView") {
      this.props.history.push("/cart");
    } else {
      this.props.cancel();
    }
  }

  notiFy(status) {
    const { messages } = this.props.intl;

    if (status === "success") {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["cart.isPurchaseSuccess"]}</div>
          </div>
        ),
        type: "success",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    } else {
      const options = {
        place: "tr",
        message: (
          <div>
            <div>{messages["newWord.somthingWrong"]}</div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
      };
      this.refs.notificationAlert.notificationAlert(options);
    }
  }

  render() {
    const { isLoading, listOption, itemSelected } = this.state;
    const { numberViPoint, oldNumberVipoint } = this.props;
    return (
      <React.Fragment>
        <NotificationAlert ref="notificationAlert" />

        {isLoading ? (
          <LoaderTable isLoaded={!isLoading} styleCustom={{ height: "500px" }} />
        ) : (
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.confirmAction.subtitle_1"></IntlMessages>

                {oldNumberVipoint !== 0 && oldNumberVipoint !== numberViPoint && (
                  <span className="ml-1" style={{ textDecoration: "line-through" }}>
                    {formatMoney_2(oldNumberVipoint, 2)} VP
                  </span>
                )}
                <span className="ml-1">{formatMoney_2(numberViPoint, 2)} VP</span>
                <IntlMessages id="vpoint.confirmAction.subtitle_2"></IntlMessages>
              </div>
              <div className="recharge-list-option mt-3">
                {listOption &&
                  listOption.map((option, index) => {
                    return (
                      <div key={index} className={`recharge-option ${itemSelected === option.type ? "option-selected" : ""}`} onClick={() => this.handleChoseOption(option.type)}>
                        <IntlMessages id={option.label}></IntlMessages>
                      </div>
                    );
                  })}
              </div>
              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPay()}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" disabled={itemSelected === ""} onClick={() => this.confirmPay()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>{" "}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    userAccount: state.accountReducer.userAccount
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    // Thêm vào giỏ hàng
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmPay));
// export default ConfirmPay;
