import React, { Component } from "react";
import { connect } from "react-redux";
import IntlMessages from "../../utils/IntlMessages";
import { callApiServer } from "../../utils/callApiServer";
import { injectIntl } from "react-intl";
import { saveAs as importedSaveAs } from "file-saver";
import { Loader } from "semantic-ui-react";
import getUrlParam from "../../utils/getUrlParam";
import BASE_API_URL from "../../constants/hostSever";

export class DownloadFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: getUrlParam()["id"] ? getUrlParam()["id"] : "",
      filename: getUrlParam()["filename"] ? getUrlParam()["filename"] : ""
    };
  }
  componentDidMount() {
    document.title = "VIRACE | Downnload Invoice";
    const { id, filename } = this.state;
    if (id && filename) {
      this.downloadFile(id, filename);
    } else {
      this.props.history.push("/menu-dieu-huong");
    }
  }

  async downloadFile(id, fileName) {
    try {
      let formatFileName = `${fileName}.zip`;
      const response = await callApiServer.get(`/Company/download-invoice?id=${id}&fileName=${fileName}`, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, formatFileName);
        this.props.history.push("/menu-dieu-huong");
      }
    } catch (error) {}
  }

  render() {
    const styleLoading = {
      position: "fixed",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      zIndex: 1000,
      backgroundColor: "rgba(0,0,0,0.6)"
    };
    return (
      <React.Fragment>
        {/* <a id="link"></a> */}
        <div id="loading" className="loading-download" style={styleLoading}>
          <div
            className="loading-icon"
            style={{
              marginTop: "300px"
            }}
          >
            <Loader active size="huge" inline="centered" />
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqReducer: state.faqReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DownloadFile));
