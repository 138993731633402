import React, { Component } from "react";
import Iframe from "react-iframe";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import Header from "../../components/Header/HomeHead";
import Footer from "../../components/Footer/Footer";
export class WrongDevice extends Component {
  componentDidMount() {
    document.title = "VIRACE | WrongDevice";
  }
  render() {
    return (
      <div className="_view_contacts">
        <Header history={this.props.history} />
        <main className="">
          <div style={{ width: "100vw", height: "200px", marginTop: "200px", color: "red", textAlign: "center", fontSize: "17px" }}>
            <IntlMessages id="wrongDevice"></IntlMessages>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default injectIntl(WrongDevice);
