import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import HomeHeadInfo from "../Header/HomeHeadInfo";
import { _Basic_ } from "../../constants/rolesModel";
import { connect } from "react-redux";

export class HomeHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      checkPropsChildren: this.props.children ? true : false
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  back() {
    this.props.history.goBack();
  }
  forward() {
    this.props.history.goForward();
  }

  showBackAndForward() {
    const URL_HIDDEN = "https://viracresearch.com";
    let value = false;

    if (document && document.location && document.location.ancestorOrigins) {
      let ancestorOrigins = Array.from(document.location.ancestorOrigins);
      ancestorOrigins.map((item) => {
        if (item.includes(URL_HIDDEN)) {
          value = true;
        }
      });
    } else {
      value = false;
    }
    return value;
  }

  render() {
    let role;
    if (this.props && this.props.userAccount) {
      role = this.props.userAccount.roleClient;
    }
    var mainUrl = localStorage.getItem("access_token") && role && role !== _Basic_ ? "/menu-dieu-huong" : "/trang-chu-if-ir";

    return (
      <div className="view_header_home">
        <div className="logo">
          <Link to={mainUrl}>
            <img src={logo} alt="logo" />
          </Link>

          {/* {this.showBackAndForward() && (
            <div className="back-previous">
              <button onClick={() => this.back()} className="btn-control">
                <em className="material-icons">arrow_back</em>
              </button>
              <button onClick={() => this.forward()} className="btn-control">
                <em className="material-icons">arrow_forward</em>
              </button>
            </div>
          )} */}
        </div>
        <HomeHeadInfo {...this.props} isLogged={this.props.isLooged} isPropsChildren={this.state.checkPropsChildren} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userAccount: state.accountReducer.userAccount
  };
};

// export default injectIntl(HomeHead);
export default connect(mapStateToProps, null)(injectIntl(HomeHead));
