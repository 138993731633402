import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actGetVsicList, actStatisticIndustry, actAddToCart } from "../../../redux/actions";
import { Dropdown, Menu, Popup } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton";
import IntlMessages from "../../../utils/IntlMessages";
import { formatMoney } from "../../../utils/formatMoney";
import { Table } from "reactstrap";
import StatisticTableFilter from "../../../components/StatisticTableFilter/StatisticTableFilter";
import Pagination from "../../../components/Pagination/PaginationSimple";
import LoaderTable from "../../../components/LoaderTable";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import getUrlParam from "../../../utils/getUrlParam";
import formatNumber from "../../../utils/formatNumber";
import { basicStatisticCriteria } from "../../../constants/industry";
import { copyArray } from "../../../utils/copyArray";
import { DEFAULT_STATISTIC_VSIC } from "../../../constants/defaultValues";
import Findnotfound from "../../../components/Findnotfound";
import { callApiServer } from "../../../utils/callApiServer";
import { saveAs as importedSaveAs } from "file-saver";

import FindnotfoundNoTitle from "../../../components/Findnotfound-noTitle";
import queryString from "query-string";
import { numberFormat } from "../../../utils/numberFormat";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart";
import { Workbook } from "exceljs/dist/exceljs.min.js";
import * as fs from "file-saver";
import * as moment from "moment";
import ConfirmPay from "../../AccountsManagement/ConfirmPayComponent";

class TabDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: "",
      // selectedIndustry: !getUrlParam()["q"] ? DEFAULT_STATISTIC_VSIC : [], // ds ngành được chọn
      selectedIndustry: !getUrlParam()["q"] ? (getUrlParam()["productId"] ? [getUrlParam()["productId"]] : DEFAULT_STATISTIC_VSIC) : [], // ds ngành được chọn

      pageSize: 10,
      activePage: 1,
      sortConfig: {
        key: "",
        direction: ""
      },
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "", // từ khóa tìm kiếm
      // txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : getUrlParam()["productId"] ? queryString.parse(this.props.location.search).productId : "",
      productId: getUrlParam()["productId"] ? queryString.parse(this.props.location.search).productId : "",
      statistic: getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [], // tiêu chí thống kê tìm kiếm
      statisticType: "TUYET_DOI", // tuyệt đối, tương đối, CAGR
      calculationType: "", // tổng, trung bình
      // filter mã vsic
      isShowVsicFilter: false,
      isSearchVsic: true,
      initFilterVsic: [], // ds ngành đang thống kê
      searchVsicFilterResults: [],
      txtSearchVsic: "",
      checkedAllVsic: true,
      indeterminateVsic: false,
      selectedVsic: [],
      isFirstInitBySearch: true,
      // filter vsic name
      isShowVsicNameFilter: false,
      isSearchVsicName: true,
      initFilterVsicName: [], // ds ngành đang thống kê
      searchVsicNameFilterResults: [],
      txtSearchVsicName: "",
      checkedAllVsicNam: true,
      indeterminateVsicName: false,
      selectedVsicName: [],
      // filter loại dữ liệu
      isShowTypeFilter: false,
      isSearchType: true,
      initFilterType: [], // ds type đang thống kê
      searchTypeFilterResults: [],
      txtSearchType: "",
      checkedAllType: true,
      indeterminateType: false,
      selectedType: []
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideFilter, true);
    const { selectedIndustry, statisticType, statistic } = this.state;
    this.statistic(statisticType, "", selectedIndustry, statistic, "", "");
    this.props.getVsicList();
    const arr = [];
    statistic.forEach((i) => {
      const x = basicStatisticCriteria.filter((b) => b.id === i.replace(/ *#*/g, ""));
      if (x && x.length) {
        arr.push({
          value: i,
          isChecked: true,
          text: x[0].text ? x[0].text : "",
          enText: x[0].enText ? x[0].enText : ""
        });
      }
    });
    this.setState({
      initFilterType: arr,
      searchTypeFilterResults: arr,
      selectedType: arr
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const language = localStorage.getItem("currentLanguage");

    const { filterVsicList, filterTypeList, initFilterVsic, statisticData } = nextProps.industryInformationReducer;
    // ngành
    const arr1 = filterVsicList.map((i) => {
      return {
        value: i.vsicCode,
        vsicName: i.vsicName,
        vsicEName: i.vsicEName,

        isChecked: true
      };
    });
    this.setState({
      selectedVsic: arr1,
      filterVsicList
    });
    const arr2 = [];
    let selectedIndusArr = [];

    initFilterVsic.forEach((i) => {
      selectedIndusArr.push(i.vsicCode);

      if (arr1.filter((x) => x.value === i.vsicCode && (language === "vi" ? x.vsicName === i.vsicName : x.vsicEName === i.vsicEName)).length) {
        arr2.push({
          value: i.vsicCode,
          vsicName: i.vsicName,
          vsicEName: i.vsicEName,

          isChecked: true
        });
      } else {
        arr2.push({
          value: i.vsicCode,
          vsicName: i.vsicName,
          vsicEName: i.vsicEName,
          isChecked: false
        });
      }
    });

    this.setState({
      selectedIndustry: [...selectedIndusArr]
    });
    const { txtSearchVsic, txtSearchVsicName } = this.state;
    const arr3 =
      txtSearchVsic || txtSearchVsicName
        ? arr2.filter((i) => i.value.includes(txtSearchVsic) || (language === "vi" ? i.vsicName.includes(txtSearchVsicName) : i.vsicEName.includes(txtSearchVsicName)))
        : arr2;
    this.setState({
      initFilterVsic: arr2,
      searchVsicFilterResults: arr3
    });
    if (arr1.length === arr2.length && arr1.length > 0) {
      this.setState({
        indeterminateVsic: false,
        checkedAllVsic: true
      });
    } else if (arr1.length < arr2.length && arr1.length > 0) {
      this.setState({
        indeterminateVsic: true,
        checkedAllVsic: false
      });
    } else {
      this.setState({
        indeterminateVsic: false,
        checkedAllVsic: false
      });
    }

    // loại dl
    if (filterTypeList && filterTypeList.length) {
      this.setState({
        filterTypeList
      });
    }

    // Check nếu có data của bảng + Đã đăng nhập thì thêm hiệu ứng bay cho nút mua
    const isLogged = localStorage.getItem("access_token") ? true : false;
    if (statisticData) {
      if (statisticData.length !== 0) {
        handleFlyBuyCart();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideFilter, true);
  }

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params ? params : messages[`newWord.alertSelectedxRatio`]}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  // statistic
  statistic = (dataType, calculationType, initFilterVsic, selectedType, sortColumn, sortDirection, isChangePage) => {
    const { activePage, pageSize, txtsearch, selectedVsic } = this.state;
    const rq = {
      vsicStatisticKind: selectedType,
      textSearch: txtsearch,
      dataType,
      pageIndex: isChangePage ? activePage : 1,
      pageSize: pageSize,
      calculationType,
      vsicCode: initFilterVsic
    };
    if (selectedVsic.length) {
      rq.vsicFilter = selectedVsic.map((i) => i.value);
    }
    if (sortColumn && sortDirection) {
      rq.predicate = sortColumn;
      rq.sortType = sortDirection;
    }
    this.props.actStatisticIndustry(rq);
  };

  // chọn ngành (vsic code)
  handleChangeIndustry = (value) => {
    this.setState({
      isFirstInitBySearch: false
    });
    this.setState({
      selectedIndustry: value
    });
  };

  // thêm ngành để thống kê
  addIndustry = () => {
    let { selectedIndustry, statisticType, initFilterVsic, selectedType, selectedVsic } = this.state;
    const { messages } = this.props.intl;
    if (selectedIndustry.length > 0) {
      // Bỏ đi giới hạn 5 phần tử nhập liệu
      // if (selectedIndustry.length > 5) {
      //   this.actAlert(messages[`industry.statistic.add-vsic-max`]);
      // } else {
      // const vsicArr = [...initFilterVsic.map((i) => i.value), ...selectedIndustry];
      const vsicArr = [...selectedIndustry];

      const typeArr = selectedType.map((i) => i.value);
      const industry = selectedIndustry.map((i) => {
        return { value: i };
      });
      this.setState(
        {
          // selectedVsic: [...selectedVsic, ...industry],
          selectedVsic: [...industry],

          calculationType: ""
        },
        () => {
          this.statistic(statisticType, "", vsicArr, typeArr, "", "");
        }
      );
      // }
    }
  };

  // Chọn loại thống kê
  handleChangeType = (value) => {
    this.setState({
      statisticType: value,
      calculationType: "",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic(value, "", vsicArr, typeArr, "", "");
  };

  // nhấn nút Tổng
  total = () => {
    this.setState({
      calculationType: "TONG",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic("TUYET_DOI", "TONG", vsicArr, typeArr, "", "");
  };

  // nhấn nút Trung bình
  average = () => {
    this.setState({
      calculationType: "TRUNG_BINH",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic("TUYET_DOI", "TRUNG_BINH", vsicArr, typeArr, "", "");
  };

  // Nhấn Tuyệt đối
  relative = () => {
    this.setState({
      calculationType: "",
      activePage: 1
    });
    const { initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic("TUYET_DOI", "", vsicArr, typeArr, "", "");
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => {
      const { statisticType, calculationType, initFilterVsic, selectedType, sortConfig } = this.state;
      const type = statisticType === "TUYET_DOI" ? calculationType : "";
      const vsicArr = initFilterVsic.map((i) => i.value);
      const typeArr = selectedType.map((i) => i.value);
      this.statistic(statisticType, type, vsicArr, typeArr, sortConfig.key, sortConfig.direction.toUpperCase(), true);
    });
  };

  // sort ở th
  handleSort = (name) => {
    this.setState({
      isShowVsicFilter: false,
      isShowTypeFilter: false,
      activePage: 1
    });
    const { statisticType, calculationType, initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    if (this.state.sortConfig.key !== name) {
      this.setState(
        {
          sortConfig: {
            key: name,
            direction: "asc"
          }
        },
        () => {
          this.statistic(statisticType, calculationType, vsicArr, typeArr, name, "ASC");
        }
      );
    } else {
      let direction = this.state.sortConfig.direction;
      if (direction === "" || direction === "desc") {
        direction = "asc";
      } else {
        direction = "desc";
      }
      this.setState(
        {
          sortConfig: {
            key: name,
            direction
          }
        },
        () => {
          this.statistic(statisticType, calculationType, vsicArr, typeArr, name, direction.toUpperCase());
        }
      );
    }
  };

  // add class khi sort
  getClassNamesFor = (name) => {
    const { sortConfig } = this.state;
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? "sorted " + sortConfig.direction : "";
  };

  // sort trong filter
  handleSortFilter = (direction, key) => {
    this.setState({
      sortConfig: {
        key,
        direction
      },
      isShowVsicFilter: false,
      isShowVsicNameFilter: false,
      isShowTypeFilter: false,
      activePage: 1
    });
    const { statisticType, calculationType, initFilterVsic, selectedType } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    this.statistic(statisticType, calculationType, vsicArr, typeArr, key, direction.toUpperCase());
  };

  // xóa bộ lọc
  clearFilter = (name) => {
    if (name === "VSIC" || name === "VSIC_NAME") {
      const initFilterVsic = copyArray(this.state.initFilterVsic);
      initFilterVsic.forEach((i) => (i.isChecked = true));
      this.setState(
        {
          searchVsicFilterResults: copyArray(initFilterVsic),
          selectedVsic: copyArray(initFilterVsic),
          txtSearchVsic: "",
          txtSearchVsicName: "",

          isShowVsicFilter: false,
          indeterminateVsic: false,
          checkedAllVsic: true
        },
        () => {
          this.applyFilter(name);
        }
      );
    } else if (name === "DATA_TYPE") {
      const initFilterType = copyArray(this.state.initFilterType);
      initFilterType.forEach((i) => (i.isChecked = true));
      this.setState(
        {
          searchTypeFilterResults: copyArray(initFilterType),
          selectedType: copyArray(initFilterType),
          txtSearchType: "",
          isShowTypeFilter: false,
          indeterminateType: false,
          checkedAllType: true
        },
        () => {
          this.applyFilter(name);
        }
      );
    }
  };

  // áp dụng bộ lọc
  applyFilter = (name) => {
    const { messages } = this.props.intl;
    const { statisticType, calculationType, initFilterVsic, selectedVsic, selectedType, sortConfig } = this.state;
    const vsicArr = initFilterVsic.map((i) => i.value);
    const typeArr = selectedType.map((i) => i.value);
    if (name === "VSIC" || name === "VSIC_NAME") {
      this.setState({
        isShowVsicFilter: false,
        isShowVsicNameFilter: false,

        activePage: 1
      });
    } else if (name === "DATA_TYPE") {
      this.setState({
        isShowTypeFilter: false,
        activePage: 1
      });
    }
    if (selectedVsic.length === 0) {
      this.actAlert(messages[`industry.statistic.chart.vsic-min`]);
    } else if (typeArr.length === 0) {
      this.actAlert(messages[`industry.statistic.chart.statistic-min`]);
    } else {
      this.statistic(statisticType, calculationType, vsicArr, typeArr, sortConfig.key, sortConfig.direction.toUpperCase());
    }
  };

  // hủy bộ lọc
  cancelFilter = (name) => {
    const { filterTypeList, searchTypeFilterResults, filterVsicList, searchVsicFilterResults } = this.state;
    if (name === "VSIC" || name === "VSIC_NAME") {
      searchVsicFilterResults.forEach((i) => {
        if (filterVsicList && filterVsicList.length) {
          if (filterVsicList.filter((j) => i.value === j.vsicCode).length) {
            i.isChecked = true;
          } else {
            i.isChecked = false;
          }
        } else {
          i.isChecked = true;
        }
      });
      const arr = copyArray(searchVsicFilterResults).filter((i) => i.isChecked);
      this.setState({
        isShowVsicFilter: false,
        isShowVsicNameFilter: false,

        searchVsicFilterResults,
        selectedVsic: arr,
        checkedAllVsic: searchVsicFilterResults.length === arr.length,
        indeterminateVsic: searchVsicFilterResults.length !== arr.length && arr.length !== 0
      });
    } else if (name === "DATA_TYPE") {
      searchTypeFilterResults.forEach((i) => {
        if (filterTypeList.includes(i.value)) {
          i.isChecked = true;
        } else {
          i.isChecked = false;
        }
      });
      const arr = copyArray(searchTypeFilterResults).filter((i) => i.isChecked);
      this.setState({
        isShowTypeFilter: false,
        searchTypeFilterResults,
        selectedType: arr,
        checkedAllType: searchTypeFilterResults.length === arr.length,
        indeterminateType: searchTypeFilterResults.length !== arr.length && arr.length !== 0
      });
    }
  };

  // filter mã vsic
  handleFilterVsic = () => {
    this.setState({
      isShowVsicFilter: !this.state.isShowVsicFilter,
      isShowVsicNameFilter: false,
      isShowTypeFilter: false
    });
  };

  // tìm kiếm mã vsic
  handleInputChangeVsic = (event) => {
    const txtSearchVsic = event.target.value;
    const initFilterVsic = copyArray(this.state.initFilterVsic);
    initFilterVsic.forEach((i) => (i.isChecked = true));
    this.setState({
      isSearchVsic: true,
      txtSearchVsic,
      checkedAllVsic: true,
      indeterminateVsic: false
    });
    if (txtSearchVsic.trim() === "") {
      this.setState({
        searchVsicFilterResults: copyArray(initFilterVsic),
        selectedVsic: copyArray(initFilterVsic)
      });
    } else {
      const searchVsicFilterResults = copyArray(initFilterVsic).filter((i) => i.value.includes(txtSearchVsic));
      this.setState({
        searchVsicFilterResults,
        selectedVsic: copyArray(searchVsicFilterResults)
      });
    }
  };

  // check all vsic
  handleCheckedAllVsic = () => {
    let { searchVsicFilterResults, checkedAllVsic } = this.state;
    searchVsicFilterResults.forEach((e) => {
      e.isChecked = !checkedAllVsic ? true : false;
    });
    this.setState({
      checkedAllVsic: !checkedAllVsic,
      searchVsicFilterResults,
      indeterminateVsic: false,
      selectedVsic: !checkedAllVsic ? copyArray(searchVsicFilterResults) : []
    });
  };

  // check 1 vsic
  handleCheckedVsic = (data) => {
    let { selectedVsic, searchVsicFilterResults } = this.state;
    if (data.isChecked) {
      selectedVsic = selectedVsic.filter((i) => i.value !== data.value);
    } else {
      selectedVsic.push(data);
    }
    searchVsicFilterResults.forEach((e) => {
      if (e.value === data.value) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      checkedAllVsic: searchVsicFilterResults.length === selectedVsic.length,
      indeterminateVsic: searchVsicFilterResults.length !== selectedVsic.length && selectedVsic.length !== 0,
      searchVsicFilterResults,
      selectedVsic
    });
  };

  // filter vsic name
  handleFilterVsicName = () => {
    this.setState({
      isShowVsicNameFilter: !this.state.isShowVsicNameFilter,
      isShowVsicFilter: false,
      isShowTypeFilter: false
    });
  };

  // tìm kiếm vsic name
  handleInputChangeVsicName = (event) => {
    const language = localStorage.getItem("currentLanguage");

    const txtSearchVsicName = event.target.value;

    const initFilterVsic = copyArray(this.state.initFilterVsic);
    initFilterVsic.forEach((i) => (i.isChecked = true));
    this.setState({
      isSearchVsicName: true,
      txtSearchVsicName,
      checkedAllVsic: true,

      indeterminateVsic: false
    });
    if (txtSearchVsicName.trim() === "") {
      this.setState({
        searchVsicFilterResults: copyArray(initFilterVsic),
        selectedVsic: copyArray(initFilterVsic)
      });
    } else {
      const searchVsicFilterResults = copyArray(initFilterVsic).filter((i) => (language === "vi" ? i.vsicName.includes(txtSearchVsicName) : i.vsicEName.includes(txtSearchVsicName)));
      this.setState({
        searchVsicFilterResults,
        selectedVsic: copyArray(searchVsicFilterResults)
      });
    }
  };

  // filter loại dữ liệu
  handleFilterType = () => {
    this.setState({
      isShowTypeFilter: !this.state.isShowTypeFilter,
      isShowVsicFilter: false,
      isShowVsicNameFilter: false
    });
  };

  // tìm kiếm loại dữ liệu
  handleInputChangeType = (event) => {
    const txtSearchType = event.target.value;
    const initFilterType = copyArray(this.state.initFilterType);
    initFilterType.forEach((i) => (i.isChecked = true));
    const language = localStorage.getItem("currentLanguage");
    this.setState({
      isSearchType: true,
      txtSearchType,
      checkedAllType: true,
      indeterminateType: false
    });
    if (txtSearchType.trim() === "") {
      this.setState({
        searchTypeFilterResults: copyArray(initFilterType),
        selectedType: copyArray(initFilterType)
      });
    } else {
      const searchTypeFilterResults = copyArray(initFilterType).filter((i) =>
        language === "en" ? i.enText.toUpperCase().includes(txtSearchType.toUpperCase()) : i.text.toUpperCase().includes(txtSearchType.toUpperCase())
      );
      this.setState({
        searchTypeFilterResults,
        selectedType: copyArray(searchTypeFilterResults)
      });
    }
  };

  // check all type
  handleCheckedAllType = () => {
    let { searchTypeFilterResults, checkedAllType } = this.state;
    searchTypeFilterResults.forEach((e) => {
      e.isChecked = !checkedAllType ? true : false;
    });
    this.setState({
      checkedAllType: !checkedAllType,
      searchTypeFilterResults,
      indeterminateType: false,
      selectedType: !checkedAllType ? copyArray(searchTypeFilterResults) : []
    });
  };

  // check 1 type
  handleCheckedType = (data) => {
    let { selectedType, searchTypeFilterResults } = this.state;
    if (data.isChecked) {
      selectedType = selectedType.filter((i) => i.value !== data.value);
    } else {
      selectedType.push(data);
    }
    searchTypeFilterResults.forEach((e) => {
      if (e.value === data.value) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState({
      checkedAllType: searchTypeFilterResults.length === selectedType.length,
      indeterminateType: searchTypeFilterResults.length !== selectedType.length && selectedType.length !== 0,
      searchTypeFilterResults,
      selectedType
    });
  };

  handleClickOutsideFilter = (event) => {
    const domNode = document.getElementById("statistic-table-fillter");
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isShowTypeFilter: false,
        isShowVsicFilter: false
      });
    }
  };

  // Add item to cart
  handleBuyIndustry = (data) => {
    let model = {
      id: data.vsicCode,
      name: data.vsicName,
      englishName: data.vsicEname,
      requestData: data.vsicName,
      price: data.price,
      point: data.point ? data.point : 0,
      currency: "VND",
      viracServiceId: 3, // báo cáo từ thống kê cơ bản
      type: 3,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};
  actAlertLogin = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlertLogin();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlertLogin = () => {
    this.setState({
      alert: ""
    });
    this.props.history.push("/dang-nhap");
  };

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyIndustry(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0"} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data)}>
          <span className={"cart-icon material-icons text-cyan"}>near_me</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";

    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;
      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.vsicCode).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  handleBuyReport(data) {
    const language = localStorage.getItem("currentLanguage");

    let point = data.point;
    let price = data.price;

    this.openPopupConfirmBuy(point, price, "Vsic", 0, data);
  }
  openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");

    this.setState({
      popupConfirmBuy: (
        <SweetAlert
          default
          title={messages["vpoint.confirmAction.title"]}
          customClass="recharge-popup"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupConfirmBuy: ""
            });
          }}
        >
          <ConfirmPay
            id={detail.vsicCode}
            numberViPoint={numberViPoint}
            numberMonney={numberMonney}
            type={type}
            oldNumberVipoint={oldNumberVipoint}
            detail={detail}
            reportLang={language}
            openAddToCart={true}
            success={() => this.confirmPaySuccess()}
            notEnoughPoint={() => this.openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}
            cancel={() => {
              this.setState({
                popupConfirmBuy: ""
              });
            }}
          ></ConfirmPay>
        </SweetAlert>
      )
    });
  }
  confirmPaySuccess() {
    this.setState({
      popupConfirmBuy: ""
    });
    // this.props.history.push("/quan-li-tai-khoan?tab=1&isPurchaseSuccess=true");
    this.props.history.push(`/quan-li-tai-khoan?tab=2`);
  }

  openPopupNotEnoughPoint(numberViPoint, numberMonney, type, oldNumberVipoint = 0, detail) {
    this.setState({
      popupConfirmBuy: "",
      popupNotEnough: (
        <SweetAlert
          default
          title={""}
          customClass="recharge-popup no-heading"
          closeOnClickOutside={true}
          onCancel={() => {
            this.setState({
              popupNotEnough: ""
            });
            this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
          }}
        >
          <div className="recharge-container">
            <div className="recharge-body">
              <div className="recharge-subtitle">
                <span className="material-icons close-icon mr-2">warning</span>
                <IntlMessages id="vpoint.notenough.warning"></IntlMessages>
              </div>

              <div className="recharge-footer">
                <button className="recharge-btn btn-cancel" onClick={() => this.cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail)}>
                  <IntlMessages id="common.cancelBtn"></IntlMessages>
                </button>
                <button className="recharge-btn btn-confirm" onClick={() => this.goTPurchase()}>
                  <IntlMessages id="user.Confirm"></IntlMessages>
                </button>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  cancelPopupNotEnough(numberViPoint, numberMonney, type, oldNumberVipoint, detail) {
    this.setState({
      popupNotEnough: ""
    });
    this.openPopupConfirmBuy(numberViPoint, numberMonney, type, oldNumberVipoint, detail);
  }

  goTPurchase() {
    this.props.history.push("/quan-li-tai-khoan?tab=1&openPurchase=true");
  }

  // Tìm kiếm theo cả code và label text
  handleSearchListStatistic = (options, data) => {
    if (data) {
      return options.filter((item) => item.text.toLowerCase().includes(data.toLowerCase()) || item.label.toLowerCase().includes(data.toLowerCase()));
    }
  };

  // Export dữ liệu ra file excel
  exportData = () => {
    const language = localStorage.getItem("currentLanguage");
    const date = moment().format("DDMMYYYY");

    const { messages } = this.props.intl;
    const { statisticData, pageIndex } = this.props.industryInformationReducer;

    const { calculationType } = this.state;
    const title = "Statistic_" + date;

    // Thêm các  cột theo trạng thái hiện có
    let header = [messages["table.sttTable"], messages["vsicId"]];
    if (!["TONG", "TRUNG_BINH"].includes(calculationType)) {
      header.push(messages["vsicName"]);
    }
    header.push(messages["industry.statistic.table.type"], messages["unit"]);
    statisticData[0].dataTypeGroup.map((item, index) => {
      if (item.year === "CAGR") {
        header.push(messages["industry.statistic.typeList.cagr"]);
      } else {
        header.push(item.year);
      }
    });

    // Thêm các dữ liệu theo các cột hiện có
    let bodyData = [];
    statisticData.map((props, index) => {
      bodyData.push([(pageIndex - 1) * 10 + index + 1, props.vsicCode]);
      if (!["TONG", "TRUNG_BINH"].includes(calculationType)) {
        bodyData[index].push(language === "en" && props.vsicEname ? props.vsicEname : props.vsicName);
      }
      bodyData[index].push(messages[`industry.statistic.${props.vsicDataKindCode}`], language === "en" && props.vsicEUnit ? props.vsicEUnit : props.vsicUnit);
      props.dataTypeGroup.map((item, i) => {
        bodyData[index].push(props.isPurchased === false || item.value === null ? "N/A" : props.vsicUnit === "%" ? numberFormat(item.value) : formatMoney(parseInt(item.value, 10)));
      });
    });

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(title, {
      properties: { useStyles: true, defaultRowHeight: 27 }
    });
    worksheet.addRow([]);
    const headerRow = worksheet.addRow(header);
    headerRow.font = { bold: true };
    bodyData.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns = [
      {
        width: 7
      },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 12 }
    ];

    worksheet.getColumn(1).alignment = {
      horizontal: "center"
    };
    // Generate excel file with given name
    workbook.xlsx.writeBuffer().then((res) => {
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      fs.saveAs(blob, "Statistic_" + date + ".xlsx");
    });
  };

  async getExcel() {
    try {
      const date = moment().format("DDMMYYYY");
      const fileName = "Statistic_" + date + ".xlsx";
      const urlDownload = `/IFReportStatistic/download-vsic-statistic-export`;
      const language = localStorage.getItem("currentLanguage");
      const body = JSON.parse(localStorage.getItem("currentSearchBodyBasic"));
      body.lang = language;
      const response = await callApiServer.post(urlDownload, body, {
        responseType: "blob"
      });
      if (response && response.data) {
        importedSaveAs(response.data, fileName);
      } else {
      }
    } catch (error) {
      console.log("err", error);
    }
  }

  render() {
    const language = localStorage.getItem("currentLanguage");
    const { messages } = this.props.intl;
    const {
      isShowVsicFilter,
      isShowVsicNameFilter,
      isShowTypeFilter,
      isSearchVsic,
      isSearchVsicName,
      searchVsicFilterResults,

      txtSearchVsic,
      txtSearchVsicName,
      sortConfig,
      checkedAllVsic,
      indeterminateVsic,
      isSearchType,
      searchTypeFilterResults,
      txtSearchType,
      checkedAllType,
      indeterminateType,
      selectedIndustry,
      txtsearch,
      productId,
      calculationType,
      isFirstInitBySearch
    } = this.state;
    const statisticTypeList = [
      {
        value: "TUYET_DOI",
        key: "TUYET_DOI",
        text: messages["industry.statistic.typeList.absolute"]
      },
      {
        value: "TUONG_DOI",
        key: "TUONG_DOI",
        text: messages["industry.statistic.typeList.relative"]
      },
      {
        value: "CAGR",
        key: "CAGR",
        text: messages["industry.statistic.typeList.cagr"]
      }
    ];
    const { statisticLoading, statisticData, totalPage, pageIndex, industryList, initFilterVsic } = this.props.industryInformationReducer;
    const { activePage, statisticType } = this.state;

    const vsicList = [];
    industryList.forEach((e) => {
      vsicList.push({
        key: e.code,
        text: e.code,
        value: e.code,
        label: language === "en" && e.enName ? " - " + e.enName : " - " + e.name
      });
    });
    let arrDefault = [];
    initFilterVsic.forEach((item) => {
      arrDefault.push(item.vsicCode);
    });

    return (
      <div className="statistic-data-table selected-custom-select card-item">
        {this.state.alert}
        {this.state.popupConfirmBuy}
        {this.state.popupNotEnough}
        <div className="row mx-lg-0 mb-3">
          <div className="col-lg-8 col-xl-7 pl-lg-0">
            <div className="d-flex">
              <Menu compact className="statistic-type" style={{ width: "220px" }}>
                <Dropdown fluid selection options={statisticTypeList} defaultValue={"TUYET_DOI"} onChange={(e, { value }) => this.handleChangeType(value)} style={{ width: "220px" }} />
              </Menu>
              <Button className={`common-button ml-3`} onClick={this.total} disabled={statisticType !== "TUYET_DOI"}>
                <IntlMessages id="industry.statistic.total" />
              </Button>
              <Button className={`common-button ml-3`} onClick={this.average} disabled={statisticType !== "TUYET_DOI"}>
                <IntlMessages id="industry.statistic.average" />
              </Button>
              <Button className={`common-button ml-3`} style={{ minWidth: "44px", width: "44px" }} onClick={this.relative} disabled={statisticType !== "TUYET_DOI"}>
                <span className={"cart-icon material-icons"} style={{ marginLeft: "0" }}>
                  refresh
                </span>
              </Button>
            </div>
          </div>
        </div>
        <div className="row mx-lg-0 mb-2">
          <div className="col-lg-12 col-xl-12 p-0">
            <div className="d-flex align-items-center">
              {!statisticLoading && (
                <React.Fragment>
                  <Menu compact className="w-100 mr-3 dropdown-vsic-list statistic-dropdown">
                    <Dropdown
                      placeholder={messages["sidebar.Industry"]}
                      fluid
                      multiple
                      search={this.handleSearchListStatistic}
                      selection
                      options={vsicList}
                      noResultsMessage={messages["search.notfound.text"]}
                      // defaultValue={!txtsearch ? DEFAULT_STATISTIC_VSIC : []}
                      defaultValue={!txtsearch && isFirstInitBySearch ? (productId ? [productId] : DEFAULT_STATISTIC_VSIC) : [...arrDefault]}
                      onChange={(e, { value }) => this.handleChangeIndustry(value)}
                    />
                  </Menu>
                  <span className={`material-icons text-cyan ${selectedIndustry.length === 0 ? "add-vsic-disabled" : ""}`} onClick={this.addIndustry}>
                    search
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <div>
          {statisticLoading ? (
            <LoaderTable isLoaded={!statisticLoading} styleCustom={{ height: "500px" }} />
          ) : (
            <div className={`table-custom ${isShowVsicFilter || isShowTypeFilter ? "show-filter" : ""}`}>
              {statisticData && statisticData.length > 0 && (
                <div>
                  <div
                    className="btn-export-data text-cyan d-flex"
                    style={{ width: "fit-content", maxHeight: "30px", float: "right", flexDirection: "row-reverse", margin: "0px 10px 15px 0px" }}
                    onClick={() => {
                      this.getExcel();
                    }}
                  >
                    <IntlMessages id="newWord.downloadStatisticResult" />
                    <em className="material-icons">download</em>
                  </div>
                  <Table responsive className="sortable">
                    <thead>
                      {!["TONG", "TRUNG_BINH"].includes(calculationType) && (
                        <tr>
                          <th className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", left: "0px", minWidth: "50px", width: "50px" }}>
                            <IntlMessages id="table.sttTable" />
                          </th>
                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "50px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterVsic()}>
                              <IntlMessages id="vsicId" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowVsicFilter && (
                              <StatisticTableFilter
                                type="VSIC"
                                isSearch={isSearchVsic}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "VSIC")}
                                clearFilter={() => this.clearFilter("VSIC")}
                                applyFilter={() => this.applyFilter("VSIC")}
                                cancelFilter={() => this.cancelFilter("VSIC")}
                                txtSearch={txtSearchVsic}
                                handleInputChange={this.handleInputChangeVsic}
                                searchResults={searchVsicFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllVsic}
                                handleCheckedItem={this.handleCheckedVsic}
                                checkedAllItems={checkedAllVsic}
                                indeterminateItem={indeterminateVsic}
                                activeSort={sortConfig.key === "VSIC" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "200px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterVsicName()}>
                              <IntlMessages id="vsicName" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowVsicNameFilter && (
                              <StatisticTableFilter
                                type="VSIC_NAME"
                                isSearch={isSearchVsicName}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "VSIC_NAME")}
                                clearFilter={() => this.clearFilter("VSIC_NAME")}
                                applyFilter={() => this.applyFilter("VSIC_NAME")}
                                cancelFilter={() => this.cancelFilter("VSIC_NAME")}
                                txtSearch={txtSearchVsicName}
                                handleInputChange={this.handleInputChangeVsicName}
                                searchResults={searchVsicFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllVsic}
                                handleCheckedItem={this.handleCheckedVsic}
                                checkedAllItems={checkedAllVsic}
                                indeterminateItem={indeterminateVsic}
                                activeSort={sortConfig.key === "VSIC_NAME" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "350px" }}>
                            <div className="filter-text d-flex align-items-center " onClick={() => this.handleFilterType()}>
                              <IntlMessages id="industry.statistic.table.type" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowTypeFilter && (
                              <StatisticTableFilter
                                type="DATA_TYPE"
                                isSearch={isSearchType}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "DATA_TYPE")}
                                clearFilter={() => this.clearFilter("DATA_TYPE")}
                                applyFilter={() => this.applyFilter("DATA_TYPE")}
                                cancelFilter={() => this.cancelFilter("DATA_TYPE")}
                                txtSearch={txtSearchType}
                                handleInputChange={this.handleInputChangeType}
                                searchResults={searchTypeFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllType}
                                handleCheckedItem={this.handleCheckedType}
                                checkedAllItems={checkedAllType}
                                indeterminateItem={indeterminateType}
                                activeSort={sortConfig.key === "DATA_TYPE" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th
                            style={{ verticalAlign: "middle", textAlign: "center", minWidth: "100px", width: "100px", left: "500px" }}
                            onClick={() => this.handleSort("VSIC_UNIT")}
                            className={this.getClassNamesFor("VSIC_UNIT") + " fixed-column"}
                          >
                            <IntlMessages id="unit" />
                          </th>

                          {statisticData &&
                            statisticData[0].dataTypeGroup &&
                            statisticData[0].dataTypeGroup[0].vsicStatisticValues.map((item, index) => {
                              return (
                                <th key={index} className={"text-right min-70 " + this.getClassNamesFor(`${item.year}`)}>
                                  {item.year === "CAGR" ? <IntlMessages id="industry.statistic.typeList.cagr" /> : item.year}
                                </th>
                              );
                            })}
                        </tr>
                      )}
                      {["TONG", "TRUNG_BINH"].includes(calculationType) && (
                        <tr>
                          <th className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", left: "0px", minWidth: "50px", width: "50px" }}>
                            <IntlMessages id="table.sttTable" />
                          </th>
                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "50px" }}>
                            <div className="filter-text d-flex align-items-center" onClick={() => this.handleFilterVsic()}>
                              <IntlMessages id="vsicId" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowVsicFilter && (
                              <StatisticTableFilter
                                type="VSIC"
                                isSearch={isSearchVsic}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "VSIC")}
                                clearFilter={() => this.clearFilter("VSIC")}
                                applyFilter={() => this.applyFilter("VSIC")}
                                cancelFilter={() => this.cancelFilter("VSIC")}
                                txtSearch={txtSearchVsic}
                                handleInputChange={this.handleInputChangeVsic}
                                searchResults={searchVsicFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllVsic}
                                handleCheckedItem={this.handleCheckedVsic}
                                checkedAllItems={checkedAllVsic}
                                indeterminateItem={indeterminateVsic}
                                activeSort={sortConfig.key === "VSIC" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>

                          <th className="filter p-0 fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "200px" }}>
                            <div className="filter-text d-flex align-items-center " onClick={() => this.handleFilterType()}>
                              <IntlMessages id="industry.statistic.table.type" />
                              <span className="material-icons">filter_alt</span>
                            </div>
                            {isShowTypeFilter && (
                              <StatisticTableFilter
                                type="DATA_TYPE"
                                isSearch={isSearchType}
                                sortConfig={sortConfig}
                                handleSort={(e) => this.handleSortFilter(e, "DATA_TYPE")}
                                clearFilter={() => this.clearFilter("DATA_TYPE")}
                                applyFilter={() => this.applyFilter("DATA_TYPE")}
                                cancelFilter={() => this.cancelFilter("DATA_TYPE")}
                                txtSearch={txtSearchType}
                                handleInputChange={this.handleInputChangeType}
                                searchResults={searchTypeFilterResults}
                                handleCheckedAllItems={this.handleCheckedAllType}
                                handleCheckedItem={this.handleCheckedType}
                                checkedAllItems={checkedAllType}
                                indeterminateItem={indeterminateType}
                                activeSort={sortConfig.key === "DATA_TYPE" ? sortConfig.direction : ""}
                              />
                            )}
                          </th>
                          <th
                            style={{ verticalAlign: "middle", textAlign: "center", minWidth: "100px", width: "100px", left: "350px" }}
                            onClick={() => this.handleSort("VSIC_UNIT")}
                            className={this.getClassNamesFor("VSIC_UNIT") + " fixed-column"}
                          >
                            <IntlMessages id="unit" />
                          </th>

                          {statisticData &&
                            statisticData[0].vsicStatisticValues &&
                            statisticData[0].vsicStatisticValues.map((item, index) => {
                              return (
                                <th key={index} className={"text-right min-70 " + this.getClassNamesFor(`${item.year}`)}>
                                  {item.year === "CAGR" ? <IntlMessages id="industry.statistic.typeList.cagr" /> : item.year}
                                </th>
                              );
                            })}
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {!["TONG", "TRUNG_BINH"].includes(calculationType) &&
                        statisticData.map((vsicGroup, indexVsic) => {
                          return (
                            <React.Fragment key={indexVsic}>
                              {vsicGroup.dataTypeGroup &&
                                vsicGroup.dataTypeGroup.map((props, index) => {
                                  return (
                                    <tr key={index}>
                                      {props.rowSpan ? (
                                        <td rowSpan={props.rowSpan} className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "50px", width: "50px", left: "0px" }}>
                                          <span>{formatNumber((pageIndex - 1) * 10 + indexVsic + 1)}</span>
                                        </td>
                                      ) : null}
                                      {props.rowSpan ? (
                                        <td rowSpan={props.rowSpan} className="fixed-column" style={{ verticalAlign: "middle", textAlign: "center", minWidth: "150px", width: "150px", left: "50px" }}>
                                          <span style={{ display: "flex", alignItems: "center" }}>
                                            {vsicGroup.vsicCode}
                                            {vsicGroup.isPurchased === false && (
                                              <em className="material-icons text-cyan ml-1" onClick={() => this.handleBuyReport(vsicGroup)}>
                                                shopping_cart
                                              </em>
                                            )}
                                          </span>
                                        </td>
                                      ) : null}
                                      {props.rowSpan ? (
                                        <td rowSpan={props.rowSpan} className="fixed-column" style={{ verticalAlign: "middle", minWidth: "150px", width: "150px", left: "200px" }}>
                                          <Popup
                                            trigger={<div className="">{language === "en" && vsicGroup.vsicEname ? vsicGroup.vsicEname : vsicGroup.vsicName}</div>}
                                            className="custom-popup"
                                            position={"top left"}
                                          >
                                            <Popup.Content>{language === "en" && vsicGroup.vsicEname ? vsicGroup.vsicEname : vsicGroup.vsicName}</Popup.Content>
                                          </Popup>
                                        </td>
                                      ) : null}
                                      <td className="fixed-column border-td-left" style={{ minWidth: "150px", width: "150px", left: "349px" }}>
                                        {messages[`industry.statistic.${props.vsicDataKindCode}`]}
                                      </td>
                                      <td className="fixed-column" style={{ minWidth: "100px", width: "100px", left: "499px" }}>
                                        {language === "en" && props.vsicEUnit ? props.vsicEUnit : props.vsicUnit}
                                      </td>
                                      {props.vsicStatisticValues &&
                                        props.vsicStatisticValues.map((item, index) => {
                                          return (
                                            <td className="text-right" key={index}>
                                              {vsicGroup.isPurchased === false || item.value === null ? (
                                                "N/A"
                                              ) : (
                                                <span>{props.vsicUnit === "%" ? numberFormat(item.value) : formatMoney(parseInt(item.value, 10))}</span>
                                              )}
                                            </td>
                                          );
                                        })}
                                    </tr>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })}

                      {["TONG", "TRUNG_BINH"].includes(calculationType) &&
                        statisticData.map((props, index) => {
                          return (
                            <tr key={index}>
                              <td className="fixed-column" style={{ textAlign: "center", minWidth: "50px", width: "50px", left: "0px" }}>
                                <span>{formatNumber((pageIndex - 1) * 10 + index + 1)}</span>
                              </td>
                              <td className="vsic-code fixed-column" style={{ minWidth: "150px", width: "150px", left: "50px" }}>
                                {props.vsicCode}
                              </td>

                              <td className="vsic-code fixed-column" style={{ minWidth: "150px", width: "150px", left: "200px" }}>
                                {" "}
                                {messages[`industry.statistic.${props.vsicDataKindCode}`]}
                              </td>

                              <td className="vsic-code fixed-column" style={{ minWidth: "100px", width: "100px", left: "350px" }}>
                                {language === "en" && props.vsicEUnit ? props.vsicEUnit : props.vsicUnit}
                              </td>
                              {props.vsicStatisticValues &&
                                props.vsicStatisticValues.map((item, index) => {
                                  return (
                                    <td className="text-left" key={index}>
                                      {props.isPurchased === false || item.value === null ? (
                                        "N/A"
                                      ) : (
                                        <span>{props.vsicUnit === "%" ? numberFormat(item.value) : formatMoney(parseInt(item.value, 10))}</span>
                                      )}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  <div className="foot-pagination">
                    <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                  </div>
                </div>
              )}
              {statisticData && statisticData.length === 0 && <FindnotfoundNoTitle />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer,
    industryInformationReducer: state.industryInformationReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actStatisticIndustry: (body) => {
      dispatch(actStatisticIndustry(body));
    },
    getVsicList: (body) => {
      dispatch(actGetVsicList(body));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabDataTable));
