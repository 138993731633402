import { callApiServer } from "../utils/callApiServer";
export const actLogin = (body) => {
  return callApiServer.post("/Users/Login", body);
};

export const actForgotPasswordApi = (body) => {
  return callApiServer.post("/Users/ForgotPassword", body);
};
export const actConfirmToken = (body) => {
  return callApiServer.post("/Users/ConfirmToken", body);
};
// handleCheckedLogout
export const actLogoutUser = (body) => {
  return callApiServer.post("/Users/Logout", body);
};
