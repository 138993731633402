import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";
import Header from "../components/Header/HomeHead";
// import Loader from "../components/Loader";
import Sidebar from "../components/SideBar/SideBar";
import { actGetDataFromLocalStorage } from "../redux/Cart/actions";
import mainRoutes from "../routes/main";
import { _Basic_ } from "../constants/rolesModel";

import { isDesktop, isMobile, isTablet } from "react-device-detect";
// import { getCookie } from "../utils/cookies";
class LayoutApp extends Component {
  state = {
    getDataLocal: !localStorage.getItem("carts") ? [] : null ? [] : JSON.parse(localStorage.getItem("carts"))
  };

  UNSAFE_componentWillMount() {
    // Kiểm tra giỏ hàng, nếu có nhiều item của luồng cũ thì xóa đi
    // luồng cũ data cart lưu thông tin của các tài khoản khác nhau
    // luồng mới: data cart chỉ có 1, đồng bộ giữa đăng nhập và chưa đăng nhập
    const oldData = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));
    let newData;
    if (oldData && oldData.length) {
      newData = oldData.filter((item) => item.userID === null || item.userID === undefined);
    }

    if (newData) {
      localStorage.setItem("carts", JSON.stringify(newData));
    }
  }

  componentDidMount() {
    this.checkDevice();

    // var obj = {
    //   // userID: this.props.accountManagement.userAccount.id,
    //   userID: null,

    //   dataRequest: []
    // };
    // const { dataRequired } = this.props.carts;
    // if (dataRequired.userID !== obj.userID) {
    //   this.props.handleGetDataFromLocalStorage(obj);
    // }
    this.props.handleGetDataFromLocalStorage();
  }

  notify(message, color) {
    const options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // const { dataRequired } = this.props.carts;
    if (nextProps.notify !== null && nextProps.notify !== this.props.notify) {
      this.notify(nextProps.notify.message, nextProps.notify.color);
    }

    // if (nextProps.accountManagement.userAccount.id) {
    //   var obj = {
    //     userID: nextProps.accountManagement.userAccount.id,
    //     userID: null,

    //     dataRequest: []
    //   };

    //   if (dataRequired.userID !== obj.userID) {
    //     this.props.handleGetDataFromLocalStorage(obj);
    //   }
    // }
  }

  checkDevice() {
    const blockDevice = isMobile && !isTablet && !isDesktop;
    if (blockDevice) {
      localStorage.removeItem("access_token");
      this.props.history.push("/wrong-device");
    }
  }

  render() {
    let isLogged = localStorage.getItem("access_token") ? true : false;

    // var roles = _Basic_;
    let roles = "";

    const userAccount = this.props.accountManagement.userAccount;
    if (userAccount.id && userAccount.roleClient) {
      roles = userAccount.roleClient;
    }
    var addClass = window.location.href.includes("menu-dieu-huong") ? "is-main-menu" : "";

    return (
      <>
        <div className="wrapper">
          <NotificationAlert ref="notificationAlert" />
          <Header history={this.props.history} userAccount={userAccount} isLogged={isLogged} />
          <main>
            <section className={`main-panel ${addClass}`} ref="mainPanel" style={{ paddingLeft: isLogged && roles && roles !== _Basic_ ? "" : "25px" }}>
              {/* {addClass !== 'is-main-menu' && <Breadcrumbs></Breadcrumbs>} */}
              <Switch>
                {mainRoutes.map((prop, key) => {
                  if (prop.collapse) {
                    return prop.views.map((prop2, key2) => {
                      return (
                        <Route
                          path={prop2.path}
                          render={(props) => {
                            return prop2.component(props, roles, isLogged);
                          }}
                          key={key2}
                          exact={prop2.exact}
                        />
                      );
                    });
                  }
                  if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                  return (
                    <Route
                      path={prop.path}
                      render={(props) => {
                        return prop.component(props, roles, isLogged);
                      }}
                      key={key}
                      exact={prop.exact}
                    />
                  );
                })}
              </Switch>
              {isLogged && roles && roles !== _Basic_ && <Sidebar {...this.props} routes={mainRoutes} roles={roles} />}
            </section>
          </main>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notify: state.FetchReducer.notify,
    accountManagement: state.accountReducer,
    carts: state.cartReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetDataFromLocalStorage: () => dispatch(actGetDataFromLocalStorage())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutApp);
