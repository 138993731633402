import React, { Component } from "react";
import BG from "../assets/images/login.png";
import IconCR from "../assets/images/icon/business-and-finance.png";
import IconIF from "../assets/images/icon/chart.png";
import IconBG from "../assets/images/icon/1a.png";
import "../assets/scss/views/_view_main_menu.scss";
import IntlMessages from "../utils/IntlMessages";
import { Link } from "react-router-dom";
import { _Standard_, _Premium_, _Basic_, _ForeignAccount_ } from "../constants/rolesModel";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";

var navItemStyle = {
  backgroundImage: `url(${IconBG})`
};

export class MainMenu extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      alert: ""
    };
  }
  componentDidMount() {
    document.title = "VIRACE | Home";
  }
  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  render() {
    const { roles } = this.props;
    const { messages } = this.props.intl;

    return (
      <React.Fragment>
        {this.state.alert}
        {roles && roles === _ForeignAccount_ && <Redirect to="/trang-chu-if-ir" />}

        <div className="login-page home-nav" style={{ backgroundImage: `url(${BG})` }}>
          <div className="container home-nav-container">
            <div className="row home-nav-content">
              {localStorage.getItem("access_token") && roles && roles !== _ForeignAccount_ && (
                <div className="col-md-6 col-xl-5 nav-col nav-col-small mb-5">
                  {roles && roles === _Standard_ ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a className="nav-item" style={navItemStyle} onClick={() => this.actAlert(messages[`newWord.upgradeAccount`])}>
                      <div className="nav-icon">
                        <img src={IconCR} alt="icon" />
                      </div>
                      <div className="nav-text">
                        <div>
                          <IntlMessages id="mainMenu.company1" /> <br />
                          <IntlMessages id="mainMenu.company2" />
                        </div>
                      </div>
                    </a>
                  ) : (
                    <Link to="/trang-chu-cr" className="nav-item" style={navItemStyle}>
                      <div className="nav-icon">
                        <img src={IconCR} alt="icon" />
                      </div>
                      <div className="nav-text">
                        <div>
                          <IntlMessages id="mainMenu.company1" /> <br />
                          <IntlMessages id="mainMenu.company2" />
                        </div>
                      </div>
                    </Link>
                  )}
                  {/* <Link to="/trang-chu-cr" className="nav-item" style={navItemStyle}>
                                <div className="nav-icon"><img src={IconCR} alt="icon" /></div>
                                <div className="nav-text"><div><IntlMessages id="mainMenu.company1" /> <br /><IntlMessages id="mainMenu.company2" /></div></div>
                            </Link> */}
                </div>
              )}

              <div className="col-md-6 col-xl-5 offset-xl-2 nav-col nav-col-small">
                <Link to="/trang-chu-if-ir" className="nav-item" style={navItemStyle}>
                  <div className="nav-icon">
                    <img src={IconIF} alt="icon" />
                  </div>
                  <div className="nav-text">
                    <div>
                      <IntlMessages id="mainMenu.industry1" /> <br />
                      <IntlMessages id="mainMenu.industry2" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(MainMenu);
