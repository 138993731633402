import { Formik } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/images/logo.svg";
import BG from "../../assets/images/login.png";
import Button from "../../components/CustomButton/CustomButton";
import { actLogin, actLogout } from "../../redux/actions";
import Eye from "../../assets/images/ic/Eye.svg";
import EyeActive from "../../assets/images/ic/EyeActive.svg";
import { setAuthorization } from "../../utils/callApiServer";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { v4 as uuidv4 } from "uuid";
import Header from "../../components/Header/HomeHead";
import IntlMessages from "../../utils/IntlMessages";
import { injectIntl } from "react-intl";
import ModalConfidentiality from "../../components/Footer/ModalConfidentiality";
import ModalCopyright from "../../components/Footer/ModalCopyright";
import ModalDisclaimer from "../../components/Footer/ModalDisclaimer";
import img from "../../assets/images/logoSaleNoti.png";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isLogin: false,
      message: null,
      initialValues: { email: "", password: "" },
      rememberMe: false,
      isShowPassword: false,
      toggleModalConfidentiality: false,
      toggleModalModalDisclaimer: false,
      toggleModalModalCopyright: false
    };
    this.form = React.createRef();
  }

  componentDidMount = () => {
    document.title = "VIRACE | Login";

    const rememberData = JSON.parse(localStorage.getItem("rememberData"));
    if (rememberData && rememberData.email) {
      this.setState({
        initialValues: {
          email: rememberData.email,
          password: rememberData.password
        },
        rememberMe: true
      });
    }
  };

  handleChangeRemember = () => {
    this.setState({
      rememberMe: !this.state.rememberMe
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isLoginCheck(nextProps);
  }

  handleChangeForm = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      message: "",
      initialValues: {
        ...this.state.initialValues,
        [name]: value
      }
    });
  };

  _isLoginCheck = (nextProps) => {
    const auth = nextProps.authentication;
    if (auth.isLogin === false) {
      this.setState({
        message: auth.message,
        isLoading: false
      });
    } else if (auth.isLogin === true) {
      if (auth.role === "Basic") {
        this.props.history.push("/trang-chu-if-ir");
      } else {
        this.props.history.push("/menu-dieu-huong");
      }
    }
  };

  showPassword = () => {
    this.setState((state) => ({
      isShowPassword: !state.isShowPassword
    }));
    setTimeout(() => {
      this.setState((state) => ({
        isShowPassword: !state.isShowPassword
      }));
    }, 3000);
  };

  handleModalConfidentiality = () => {
    this.setState((state) => ({
      toggleModalConfidentiality: !state.toggleModalConfidentiality
    }));
  };

  handleModalDisclaimer = () => {
    this.setState((state) => ({
      toggleModalModalDisclaimer: !state.toggleModalModalDisclaimer
    }));
  };

  handleModalCopyright = () => {
    this.setState((state) => ({
      toggleModalModalCopyright: !state.toggleModalModalCopyright
    }));
  };
  goToConfirmPage() {
    window.open("http://online.gov.vn/Home/WebDetails/86982", "_blank");
  }

  render() {
    const { loading } = this.props.authentication;
    const { messages } = this.props.intl;
    var d = new Date();
    var n = d.getFullYear();
    setAuthorization("");
    if (localStorage.getItem("hash") == null) {
      // const fpPromise = FingerprintJS.load();
      // (async () => {
      //   // Get the visitor identifier when you need it.
      //   const fp = await fpPromise;
      //   let { components } = await fp.get();
      //   components.canvas = {};
      //   // Optinally, you can make a visitor identifier from your custom list of components
      //   const visitorId = FingerprintJS.hashComponents(components);
      //   // // This is the visitor identifier:
      //   var parts = [];
      //   parts.push(visitorId.slice(0, 8));
      //   parts.push(visitorId.slice(8, 12));
      //   parts.push(visitorId.slice(12, 16));
      //   parts.push(visitorId.slice(16, 20));
      //   parts.push(visitorId.slice(20, 32));
      //   var GUID = parts.join("-");
      //   localStorage.setItem("hash", GUID);
      // })();
      localStorage.setItem("hash", uuidv4());
    }
    const access_token = localStorage.getItem("access_token");
    const useFeMesssage = ["Not Found User", "Password is incorrect!", "Password is incorrect!", "Account be locked due to failed logins"];

    return (
      <div className="login-page" style={{ backgroundImage: `url(${BG})` }}>
        {access_token && <Redirect to="/menu-dieu-huong" />}
        <Header history={this.props.history} />
        {this.state.toggleModalConfidentiality && <ModalConfidentiality toggleModal={this.state.toggleModalConfidentiality} handleCloseForget={this.handleModalConfidentiality} />}
        {this.state.toggleModalModalDisclaimer && <ModalDisclaimer toggleModal={this.state.toggleModalModalDisclaimer} handleCloseForget={this.handleModalDisclaimer} />}
        {this.state.toggleModalModalCopyright && <ModalCopyright toggleModal={this.state.toggleModalModalCopyright} handleCloseForget={this.handleModalCopyright} />}
        <div className="login-form-container form-container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>

          <div className="login-form">
            <Formik
              enableReinitialize
              initialValues={{
                email: this.state.initialValues.email,
                password: this.state.initialValues.password
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                setTimeout(() => {
                  this.setState({ isLoading: true }, () => this.props.loginpage(values.email, values.password, this.state.rememberMe));
                  setSubmitting(false);
                }, 500);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .required(<IntlMessages id="error.emailRequired" />)
                  .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.-]{2,}$/, <IntlMessages id="error.emailFormatted" />),
                password: Yup.string()
                  .required(<IntlMessages id="error.passRequired" />)
                  .min(8, <IntlMessages id="error.passMinLength" />)
                  .matches(/^.*((?=.*[!~/*^$&@]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).[^\s]*$/, <IntlMessages id="error.passFormatted" />)
              })}
            >
              {(props) => {
                const { errors, handleBlur, handleSubmit } = props;

                console.log("LoginPage state", this.state);
                console.log("LoginPage loginpage", this.props.loginpage);

                return (
                  <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <h1>
                      <IntlMessages id="auth.login" />
                    </h1>
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">person</span>
                        </div>
                        <input
                          id="email"
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={this.state.initialValues.email}
                          maxLength="256"
                          autoComplete="off"
                          ref={(input) => {
                            this.email = input;
                          }}
                          onChange={(e) => this.handleChangeForm(e)}
                          onBlur={handleBlur}
                        />
                      </label>
                      {errors.email && <div className="form-error">{errors.email}</div>}
                      {this.state.message && this.state.message === "Not Found User" && (
                        <div className="form-error">
                          <IntlMessages id="auth.wrongAccount_p1" />
                          {this.state.initialValues.email}
                          <IntlMessages id="auth.wrongAccount_p2" />
                        </div>
                      )}
                    </div>
                    <div className="form-input form-icon form-password">
                      <label>
                        <div className="icon">
                          <span className="material-icons">lock</span>
                        </div>
                        <input
                          id="password"
                          type={this.state.isShowPassword ? "text" : "password"}
                          name="password"
                          autoComplete="off"
                          maxLength="50"
                          placeholder={messages[`auth.password`]}
                          ref={(input) => {
                            this.password = input;
                          }}
                          value={this.state.initialValues.password}
                          onChange={(e) => this.handleChangeForm(e)}
                          onBlur={handleBlur}
                        />
                      </label>
                      <img src={this.state.isShowPassword ? EyeActive : Eye} alt="eye" className={this.state.isShowPassword ? "eye active" : "eye"} onClick={() => this.showPassword()} />
                      {errors.password && <div className="form-error">{errors.password}</div>}
                      {this.state.message && this.state.message === "Password is incorrect!" && (
                        <div className="form-error">
                          <IntlMessages id="auth.wrongPassword" />
                        </div>
                      )}
                    </div>
                    {this.state.message && this.state.message === "Account be locked due to failed logins" && (
                      <div className="form-error">
                        <IntlMessages id="auth.lockedAccount_p1" />
                        024 6328 9520
                        <IntlMessages id="auth.lockedAccount_p2" />
                        viracresearch@virac.com.vn
                      </div>
                    )}
                    {this.state.message && !useFeMesssage.includes(this.state.message) && <div className="form-error input-feedback-checked">{this.state.message}</div>}
                    <div className="login-bottom flex justify-between items-center">
                      <div className="login-remember">
                        <label className="custom-checkbox">
                          <input type="checkbox" value={this.state.rememberMe} name="example" onChange={this.handleChangeRemember} id="idcheckbox" checked={this.state.rememberMe} />
                          <span className="icon" />
                          <div className="label">
                            <IntlMessages id="auth.remember" />
                          </div>
                        </label>
                      </div>
                      <div className="login-forgot text-picton-blue">
                        <Link to="/quen-mat-khau">
                          <IntlMessages id="auth.forgotPass" />?
                        </Link>
                      </div>
                    </div>
                    <div className="form-submit login-submit">
                      <Button disabled={loading} isLoading={loading} className="common-button w-100">
                        <IntlMessages id="auth.login" />
                      </Button>
                    </div>
                    <div className="mt-3 login-or-register">
                      <IntlMessages id="auth.notHaveAcount" />

                      <span className="ml-2 action-link">
                        <Link to="/dang-ki">
                          <IntlMessages id="auth.register" />
                        </Link>
                      </span>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>

          <div className="copyright">
            <p>
              <a href="#" onClick={() => this.handleModalCopyright()}>
                COPYRIGHT &copy; {n} VIRAC
              </a>
            </p>
            <p className="mt-2">
              <a href="#" onClick={() => this.handleModalConfidentiality()}>
                <IntlMessages id="footer.confidentiality" />
              </a>{" "}
              |{" "}
              <a href="#" onClick={() => this.handleModalDisclaimer()}>
                <IntlMessages id="footer.disclaimer" />
              </a>
            </p>
            <p className="mt-3">
              <span onClick={() => this.goToConfirmPage()} className="img-noti">
                <img src={img} alt="" />
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginpage: (email, password, rememberMe) => {
      dispatch(actLogin(email, password, rememberMe));
    },
    logout: () => {
      dispatch(actLogout());
    }
  };
};

const MyComponent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginPage));
export default function Login(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
