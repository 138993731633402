import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import * as companyInfoService from "../../services/companyInfo.service";
import { totalPage } from "../../utils/function.util";
import { companyPageSize } from "../../constants/pageSize";
import BASE_API_URL from "../../constants/hostSever";
import { actNotify } from "./../Fetch/actions";
import { redirectURL } from "../../utils/logoutCommon";
import { defaultPageConpanyInfo } from "../../constants/defaultValues";
const language = localStorage.getItem("currentLanguage");

function* actGetCompanyInfo(params) {
  const model = params.payload.model;
  const body = {
    dt: model.dt,
    kv: model.kv,
    pageSize: companyPageSize,
    ln: model.ln,
    page: model.page,
    tts: model.tts,
    search: model.q,
    vdl: model.vdl,
    pageCount: model.pageCount,
    ss: model.ss,
    minmax: model.minmax,
    checkSameType: model.checkSameType
  };
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(companyInfoService.actSearchCompanyInfo, body);
    if (response.data.success) {
      var companyData = response.data.data.companies;
      var totalItems = response.data.data.totalItem;
      var pageIndex = response.data.data.pageIndex;
      var pageCount = response.data.data.pageCount;
      var totalPages = totalPage(totalItems, companyPageSize);
      yield put(actions.getSearchCompanyInfoSuccess(companyData, totalItems, totalPages, pageIndex, pageCount));
    } else if (response.data.statusCode === 203) {
      let notify = {
        message: response.data.message,
        color: "danger"
      };
      yield put(actNotify(notify));
      setTimeout(function() {
        redirectURL();
      }, 4000);
    } else {
      yield put(actions.getSearchCompanyInfoFail("that bai", true));
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put(actions.getSearchCompanyInfoFail("thai Bai", true));
    yield put(actNotify(notify_data));
  }
}
function* actGetSearchAutoComplete(params) {
  const model = params.payload.model;
  const body = {
    pageSize: 10,
    page: 1,
    search: model.txtsearch
  };
  try {
    var response = yield call(companyInfoService.actSearchCompanyInfoAutocomplete, body);
    if (response.data.success) {
      let dataAutoComplete = response.data.data.data;
      let pageIndex = response.data.data.pageNumber;
      yield put(actions.actSearchCompanyAutocompleteSuccess(dataAutoComplete, pageIndex));
    } else {
      yield put({
        type: Types.GET_LIST_COMPANY_AUTOCOMPLETE_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_LIST_COMPANY_AUTOCOMPLETE_FAIL
    });
  }
}
function* actGetTopRevenua() {
  try {
    var response = yield call(companyInfoService.actgetTopRevenua);
    if (response.data.success) {
      var dataTopRevenua = response.data.data;
      yield put(actions.actGetTopRevenuaSuccess(dataTopRevenua));
    } else {
      yield put({
        type: Types.GET_TOP_REVENUA_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_TOP_REVENUA_FAIL
    });
  }
}

function* actGetCompanyDetail_v2(params) {
  let model = {
    id: params.id,
    type: params.typex
  };
  try {
    var response = yield call(companyInfoService.actgetCompanyDetail, model);
    if (response.status === 200) {
      yield put(actions.actGetCompanyDetailSuccess(response.data.data));
    } else {
      yield put(actions.actGetCompanyDetailFail("Fail", true));
      let notify = {
        message: "Error!",
        color: "danger"
      };
      yield put(actNotify(notify));
    }
  } catch (err) {
    yield put(actions.actGetCompanyDetailFail("Fail", true));
    let notify = {
      message: "Error!",
      color: "danger"
    };
    yield put(actNotify(notify));
  }
}
//Hot Fix Work Arround HieuHV
function* homeReponseChart(id, number) {
  try {
    switch (number) {
      case 6:
        return yield call(companyInfoService.requestTarget, id); // vẽ biểu đồ chỉ tiêu
      case 7:
        return yield call(companyInfoService.requestFinancial, id); // biểu đồ chỉ số

      default:
        return null;
    }
  } catch (err) {
    return null;
  }
}
//End Hot Fix Work Arround HieuHV
export function* watchActSeachCompany() {
  yield takeEvery(Types.GET_LIST_COMPANYINFO, actGetCompanyInfo);
}
export function* watchActSeachCompanyAutocomplete() {
  yield takeEvery(Types.GET_LIST_COMPANY_AUTOCOMPLETE, actGetSearchAutoComplete);
}

export function* watchActGetTopRevenua() {
  yield takeEvery(Types.GET_TOP_REVENUA, actGetTopRevenua);
}
export function* watchActGetCompanyDetail() {
  yield takeEvery(Types.GET_COMPANY_DETAIL, actGetCompanyDetail_v2);
}

//GrowTH Rate
function* actGetGrowthRateCode(params) {
  let id = params.payload.id;
  try {
    var response = yield call(companyInfoService.requestGrowthRateGetCode, id);
    if (response.data.data) {
      yield put({
        type: Types.CODE_CHECKBOX_GROWTHRATE_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.CODE_CHECKBOX_GROWTHRATE_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.CODE_CHECKBOX_GROWTHRATE_FAIL
    });
  }
}
function* actGetDataChartGrowthRate(model) {
  const id = model.id;

  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };

  try {
    var response = yield call(companyInfoService.requestGetChartGrowthRate, id);
    if (response.data.success) {
      // if(responseTarget.data.data && responseTarget.data.data.length > 0){
      //   responseTarget.data.data[0].type="5";
      //   responseTarget.data.data[0].title="LN Thuần";
      //   responseTarget.data.data.title="LN Thuần";
      //   response.data.data=response.data.data.concat(responseTarget.data.data);
      // }
      yield put(actions.actGetChartGrowthRateSuccess(response.data.data));
    } else {
      yield put(actions.actGetChartGrowthRateFail("That bai", true));
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put(actions.actGetChartGrowthRateFail("That bai", true));
    // yield put(actNotify(notify_data));
  }
}

function* actGetDataChartGrowthByType(params) {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  const model = {
    id: params.model.id,
    arrHeso: params.model.arrHeso,
    arrKv: [params.model.arrKv],
    isCheck: params.model.isCheck,
    postion: 0
  };
  try {
    var response = yield call(companyInfoService.requestGetChartByType, model);
    if (response.data.success) {
      yield put({
        type: Types.GET_CHART_BY_TYPE_SUCCESS,
        dataChartType: response.data.data
      });
    } else {
      yield put({
        type: Types.GET_CHART_BY_TYPE_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.GET_CHART_BY_TYPE_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchActGetDataChartGrowthRate() {
  yield takeEvery(Types.GET_GROWTHRATE_CHART, actGetDataChartGrowthRate);
}
export function* watchActGetDataChartByType() {
  yield takeEvery(Types.GET_CHART_BY_TYPE, actGetDataChartGrowthByType);
}
export function* watchRequestGrowthRateGetCODE() {
  yield takeEvery(Types.CODE_CHECKBOX_GROWTHRATE, actGetGrowthRateCode);
}

//CHỦ SỞ HỮU

function* actGetOwnerLaw(params) {
  const id = params.id;
  try {
    var response = yield call(companyInfoService.requestGetDataLaw, id);
    if (response.data.data) {
      yield put(actions.actGetOwnerLawSuccess(response.data.data));
    } else {
      // yield put(actions.actGetChartByTypeFail("That bai", true));
      yield put({
        type: Types.GET_OWNER_LAW_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_OWNER_LAW_FAIL
    });
  }
}

export function* watchActGetOwnerLaw() {
  yield takeEvery(Types.GET_OWNER_LAW, actGetOwnerLaw);
}

function* actGetOwnerStructor(params) {
  const id = params.id;
  try {
    var response = yield call(companyInfoService.requestGetDataStructor, id);
    if (response.data.data) {
      yield put(actions.actGetOwnerStructorSuccess(response.data.data));
    } else {
      yield put({
        type: Types.GET_OWNER_STRUCTOR_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_OWNER_STRUCTOR_FAIL
    });
  }
}

export function* watchActGetOwnerStructor() {
  yield takeEvery(Types.GET_OWNER_STRUCTOR, actGetOwnerStructor);
}

function* actGetOwnerCompanyRef(params) {
  const id = params.id;
  try {
    var response = yield call(companyInfoService.requestGetDataCompanyRef, id);
    if (response.data.data) {
      yield put(actions.actGetCompanyRefSuccess(response.data.data));
    } else {
      yield put({
        type: Types.GET_OWNER_COMPANYREF_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_OWNER_STRUCTOR_FAIL
    });
  }
}

export function* watchActGetOwnerCompanyRef() {
  yield takeEvery(Types.GET_OWNER_COMPANYREF, actGetOwnerCompanyRef);
}

//---------CHỦ SỞ HỮU

//he so

function* actRequestFinancial(params) {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.requestFinancial, params.body);
    if (response.data.success) {
      yield put({
        type: Types.REQUEST_FINANCIAL_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_FINANCIAL_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_FINANCIAL_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchRequestFinancial() {
  yield takeEvery(Types.REQUEST_FINANCIAL, actRequestFinancial);
}
//chitieu
function* actRequestTarget(params) {
  const model = {
    id: params.body.id,
    arrHeso: params.body.arrHeso,
    arrKv: params.body.arrKv,
    isCheck: params.body.isChecked,
    postion: 4
  };
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.requestTarget, model);
    if (response.data.data) {
      yield put({
        type: Types.REQUEST_TARGET_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_TARGET_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_TARGET_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
function* actRequestTargetINIT(params) {
  const id = params.payload.id;
  const roleName = params.payload.roleName;
  const set = params.payload.set;
  const configDefault = defaultPageConpanyInfo.tabAccount.find((o) => o.set.includes(set))[roleName];
  const modelDefault = {
    arrHeso: [].concat.apply([], configDefault),
    arrKv: [""],
    id: id,
    isCheck: false,
    postion: 4
  };
  let responseChartDefault = [];
  try {
    let response = yield* homeReponseChart(modelDefault, 6);
    if (response.data.success) {
      for (let index = 0; index < configDefault.length; index++) {
        let config = configDefault[index];
        let data = [];
        for (let i = 0; i < config.length; i++) {
          let heso = config[i].toString();
          data.push(response.data.data.find((o) => o.heSo === heso));
        }
        responseChartDefault.push(data);
      }
      response.data.data.target = responseChartDefault;
      yield put(actions.actRequestTargetINITSuccess(response.data.data));
    } else {
      yield put(actions.actGetCompanyDetailFail("That bai", true));
    }
  } catch (err) {
    yield put(actions.actGetCompanyDetailFail("That bai", true));
  }
}
function* actGetTargetCode(params) {
  let body = {
    id: params.payload.id,
    type: params.payload.type
  };
  try {
    var response = yield call(companyInfoService.requestTargetGetCode, body);
    if (response.data.data) {
      yield put({
        type: Types.CODE_CHECKBOX_TARGET_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.CODE_CHECKBOX_TARGET_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.CODE_CHECKBOX_TARGET_FAIL
    });
  }
}

export function* watchRequestTarget() {
  yield takeEvery(Types.REQUEST_TARGET, actRequestTarget);
}
export function* watchRequestTargetINIT() {
  yield takeEvery(Types.REQUEST_TARGET_INIT, actRequestTargetINIT);
}

export function* watchRequestTargetGetCODE() {
  yield takeEvery(Types.CODE_CHECKBOX_TARGET, actGetTargetCode);
}
//heso
export function* watchRequestFinancialGetCODE() {
  yield takeEvery(Types.CODE_CHECKBOX_FINANCIAL, actGetFinancialCode);
}
function* actRequestFinancialINIT(params) {
  const id = params.payload.id;
  const roleName = params.payload.roleName;
  const set = params.payload.set;
  const configDefault = defaultPageConpanyInfo.tabRatio.find((o) => o.set.includes(set))[roleName];
  const modelDefault = {
    arrHeso: [].concat.apply([], configDefault),
    arrKv: [""],
    id: id,
    isCheck: false,
    postion: 4
  };
  let responseChartDefault = [];
  try {
    let response = yield* homeReponseChart(modelDefault, 7);
    if (response.data.success) {
      for (let index = 0; index < configDefault.length; index++) {
        let config = configDefault[index];
        let data = [];
        for (let i = 0; i < config.length; i++) {
          let heso = config[i].toString();
          data.push(response.data.data.find((o) => o.heSo === heso));
        }
        responseChartDefault.push(data);
      }
      response.data.data.finalcial = responseChartDefault;
      yield put(actions.actRequestFinancialINITSuccess(response.data.data));
    } else {
      yield put(actions.actGetCompanyDetailFail("That bai", true));
    }
  } catch (err) {
    yield put(actions.actGetCompanyDetailFail("That bai", true));
  }
}
export function* watchRequestFinancialINIT() {
  yield takeEvery(Types.REQUEST_FINANCIAL_INIT, actRequestFinancialINIT);
}
function* actGetFinancialCode(params) {
  let id = params.payload.id;
  try {
    var response = yield call(companyInfoService.requestFinancialGetCode, id);
    if (response.data.data) {
      yield put({
        type: Types.CODE_CHECKBOX_FINANCIAL_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.CODE_CHECKBOX_FINANCIAL_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.CODE_CHECKBOX_FINANCIAL_FAIL
    });
  }
}
//So sanh trung binh nganh
export function* watchActGetRevenueColumnChart() {
  yield takeEvery(Types.GET_DATA_NET_PROFIT_COLUMN_CHART, actGetDataNetProfitColumnChart);
}

function* actGetDataNetProfitColumnChart(action) {
  try {
    // var notify_data = {
    //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    //   color: "danger"
    // };
    const { id } = action.payload;
    const model = {
      id: id
    };
    var response = yield call(companyInfoService.actGetDataNetProfitColumnChartApi, model);
    if (response.data) {
      var dataPoints = response.data.data.length > 0 ? response.data.data[0] : response.data.data;
      yield put(actions.getDataNetProfitColumnChartSuccess(dataPoints));
    } else {
      yield put(actions.getDataFail(response.data.message));
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put(actions.getDataFail(err.message));
    // yield put(actNotify(notify_data));
  }
}

export function* watchActGetMenuDataToCompare() {
  yield takeEvery(Types.GET_MENU_DATA_TO_COMPARE, actGetMenuDataToCompare);
}

function* actGetMenuDataToCompare(action) {
  try {
    var response = yield call(companyInfoService.actGetMenuDataCompareApi, action.payload.id);
    if (response.data) {
      const { data } = response.data;
      yield put(actions.getMenuDataCompareSuccess(data));
    } else {
      yield put(actions.getDataFail(response.data.message));
    }
  } catch (err) {
    yield put(actions.getDataFail(err.message));
  }
}
//so sanh doanh nghiep khac

function* actRequestLiveCharteDNK(params) {
  let body = {
    vsicId: params.body.vsicId,
    id: params.body.id,
    arrayRatio: params.body.arrayRatio,
    arrayAccount: params.body.arrayAccount,
    arrayCompanyId: params.body.arrCompanyIds
  };
  try {
    var response = yield call(companyInfoService.requestLiveChartDNK, body);

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTDNK_FAIL
    });
  }
}
export function* watchRequestLiveChartDNK() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTDNK, actRequestLiveCharteDNK);
}

//SS Trung Binh

function* actRequestLiveCharteDNK_TB(params) {
  let body = {
    vsicId: params.body.vsicId,
    id: params.body.id,
    arrayRatio: params.body.arrayRatio,
    arrayAccount: params.body.arrayAccount,
    arrayCheckCompanyIds: params.body.arrCompanyIds,
    arrayAllCompanyIds: params.body.arrAllCompanyIds,
    isCheckTBN: params.body.isCheckTBN,
    arrayKv: params.body.arrayKv,
    isCheckAllCompany: params.body.isCheckAllCompany,
    isCheckCompany: params.body.isCheckCompany
  };
  try {
    var response = yield call(companyInfoService.requestLiveChartDNK_TB, body);

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TB_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TB_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTDNK_TB_FAIL
    });
  }
}
export function* watchRequestLiveChartDNK_TB() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTDNK_TB, actRequestLiveCharteDNK_TB);
}

//SS TGT

function* actRequestLiveCharteDNK_TGT(params) {
  let body = {
    vsicId: params.body.vsicId,
    id: params.body.id,
    arrayAccount: params.body.arrayAccount,
    arrayCheckCompanyIds: params.body.arrCompanyIds,
    arrayAllCompanyIds: params.body.arrAllCompanyIds,
    isCheckTBN: params.body.isCheckTBN,
    arrayKv: params.body.arrayKv,
    isCheckAllCompany: params.body.isCheckAllCompany,
    isCheckCompany: params.body.isCheckCompany
  };
  try {
    var response = yield call(companyInfoService.requestLiveChartDNK_TGT, body);

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TGT_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TGT_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTDNK_TGT_FAIL
    });
  }
}
export function* watchRequestLiveChartDNK_TGT() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTDNK_TGT, actRequestLiveCharteDNK_TGT);
}

//SS Trung Binh group

function* actRequestLiveCharteDNK_TB_GROUP(params) {
  let body = {
    vsic: params.body.vsicId,
    isCheckTBN: params.body.isCheckTBN,
    isCheckAllCompany: params.body.isCheckAllCompany,
    isCheckListCompany: params.body.isCheckCompany,

    arrayKv: params.body.arrayKv,

    arrayRatio: params.body.arrayRatio,
    arrayAccount: params.body.arrayAccount,

    arrCompanyIds: params.body.arrCompanyIds,
    arrAllCompanyIds: params.body.arrAllCompanyIds,
    arrCompanyIds1: params.body.arrCompanyIds1,
    arrAllCompanyIds1: params.body.arrAllCompanyIds1
  };
  try {
    var response = yield call(companyInfoService.requestLiveChartDNK_TB_GROUP, body);

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL
    });
  }
}
export function* watchRequestLiveChartDNK_TB_GROUP() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTDNK_TB_GROUP, actRequestLiveCharteDNK_TB_GROUP);
}

//SS TGT group

function* actRequestLiveCharteDNK_TGT_GROUP(params) {
  let body = {
    vsic: params.body.vsicId,
    isCheckTBN: params.body.isCheckTBN,
    isCheckAllCompany: params.body.isCheckAllCompany,
    isCheckListCompany: params.body.isCheckCompany,

    arrayKv: params.body.arrayKv,

    arrayAccount: params.body.arrayAccount,

    arrCompanyIds: params.body.arrCompanyIds,
    arrAllCompanyIds: params.body.arrAllCompanyIds,
    arrCompanyIds1: params.body.arrCompanyIds1,
    arrAllCompanyIds1: params.body.arrAllCompanyIds1
  };
  try {
    var response = yield call(companyInfoService.requestLiveChartDNK_TGT_GROUP, body);

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL
    });
  }
}
export function* watchRequestLiveChartDNK_TGT_GROUP() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTDNK_TGT_GROUP, actRequestLiveCharteDNK_TGT_GROUP);
}

//DetailOwnerLaw
//chitieu
function* actRequestOwnerDetail(params) {
  const id = params.id;
  try {
    var response = yield call(companyInfoService.requestLegalRepresentative, id);
    if (response.data.data) {
      yield put({
        type: Types.GET_OWNER_LAW_DETAIL_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.GET_OWNER_LAW_DETAIL_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_OWNER_LAW_DETAIL_FAIL
    });
  }
}
export function* watchRequestOwnerDetail() {
  yield takeEvery(Types.GET_OWNER_LAW_DETAIL, actRequestOwnerDetail);
}
function* actRequestOwnerXDDetail(params) {
  let model = {
    ownerShipCompanyId: params.model.id,
    type: params.model.type,
    isVn: params.model.isVN
  };
  try {
    var response = yield call(companyInfoService.requestOwnerShipCompaies, model);
    if (response.data.data) {
      yield put({
        type: Types.GET_OWNER_STRUCTOR_DETAIL_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.GET_OWNER_STRUCTOR_DETAIL_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_OWNER_STRUCTOR_DETAIL_FAIL
    });
  }
}
export function* watchRequestOwnerXDDetail() {
  yield takeEvery(Types.GET_OWNER_STRUCTOR_DETAIL, actRequestOwnerXDDetail);
}

// download company report

// advance search
function* actAdvanceSearchCompanyByArea(params) {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.actAdvanceSearchCompanyByArea, params.payload);
    if (response.data.success) {
      yield put({
        type: Types.ADVANCE_SEARCH_COMPANY_BY_AREA_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.ADVANCE_SEARCH_COMPANY_BY_AREA_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_SEARCH_COMPANY_BY_AREA_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceSearchCompanyByArea() {
  yield takeEvery(Types.ADVANCE_SEARCH_COMPANY_BY_AREA, actAdvanceSearchCompanyByArea);
}

function* actAdvanceSearchCompanyByIndustry(params) {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.actAdvanceSearchCompanyByIndustry, params.payload);
    if (response.data.success) {
      yield put({
        type: Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceSearchCompanyByIndustry() {
  yield takeEvery(Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY, actAdvanceSearchCompanyByIndustry);
}

function* actAdvanceSearchCompanyByFinance(params) {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.actAdvanceSearchCompanyByFinance, params.payload);
    if (response.data.success) {
      yield put({
        type: Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceSearchCompanyByFinance() {
  yield takeEvery(Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE, actAdvanceSearchCompanyByFinance);
}

function* actGetFinancialCriteriaList() {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.actGetFinancialCriteriaList);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.GET_FINANCIAL_CRITERIA_LIST_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.GET_FINANCIAL_CRITERIA_LIST_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.GET_FINANCIAL_CRITERIA_LIST_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchActGetFinancialCriteriaList() {
  yield takeEvery(Types.GET_FINANCIAL_CRITERIA_LIST, actGetFinancialCriteriaList);
}

function* actGetDataPointList() {
  // var notify_data = {
  //   message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
  //   color: "danger"
  // };
  try {
    var response = yield call(companyInfoService.actGetDataPointList);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.GET_DATA_POINT_LIST_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.GET_DATA_POINT_LIST_FAIL
      });
      // yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.GET_DATA_POINT_LIST_FAIL
    });
    // yield put(actNotify(notify_data));
  }
}
export function* watchActGetDataPointList() {
  yield takeEvery(Types.GET_DATA_POINT_LIST, actGetDataPointList);
}

function* actGetDataRelatedReport(params) {
  let notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(companyInfoService.actGetDataRelatedReportInCompanyDetail, params.id);
    if (response.data) {
      yield put({
        type: Types.GET_DATA_RELATED_REPORT_IN_COMPANY_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.GET_DATA_RELATED_REPORT_IN_COMPANY_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.GET_DATA_RELATED_REPORT_IN_COMPANY_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetDataRelatedReport() {
  yield takeEvery(Types.GET_DATA_RELATED_REPORT_IN_COMPANY, actGetDataRelatedReport);
}

export default function* rootSaga() {
  yield all([
    fork(watchActSeachCompany),
    fork(watchActGetCompanyDetail),
    fork(watchActGetDataChartGrowthRate),
    fork(watchActGetDataChartByType),
    fork(watchActGetOwnerLaw),
    fork(watchActGetOwnerStructor),
    fork(watchActGetOwnerCompanyRef),
    fork(watchRequestFinancial),
    fork(watchActGetRevenueColumnChart),
    fork(watchRequestTarget),
    fork(watchRequestTargetINIT),
    fork(watchActGetMenuDataToCompare),
    fork(watchRequestTargetGetCODE),
    fork(watchActSeachCompanyAutocomplete),
    fork(watchRequestFinancialGetCODE),
    fork(watchRequestGrowthRateGetCODE),
    fork(watchRequestLiveChartDNK),
    fork(watchRequestLiveChartDNK_TB),
    fork(watchRequestLiveChartDNK_TGT),
    fork(watchRequestLiveChartDNK_TB_GROUP),
    fork(watchRequestLiveChartDNK_TGT_GROUP),
    fork(watchRequestOwnerDetail),
    fork(watchRequestOwnerXDDetail),
    fork(watchRequestFinancialINIT),
    fork(watchActAdvanceSearchCompanyByArea),
    fork(watchActAdvanceSearchCompanyByIndustry),
    fork(watchActAdvanceSearchCompanyByFinance),
    fork(watchActGetFinancialCriteriaList),
    fork(watchActGetDataPointList),
    fork(watchActGetDataRelatedReport),
    fork(watchActGetTopRevenua)
  ]);
}
