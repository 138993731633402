import React, { Component } from "react";
import lgloader from "../../../assets/images/ic/virac-logo-loader.png";
class LoaderTable extends Component {
  state = {};
  render() {
    const isLoaded = this.props.isLoaded;
    const styleCustom = this.props.styleCustom;
    return (
      <React.Fragment>
        {isLoaded ? (
          ""
        ) : (
          <div
            className="lds-css3 ng-scope"
            style={
              styleCustom ? styleCustom : { width: "100%", height: "300px" }
            }
          >
            <div
              style={{ width: "100%", height: "100%" }}
              className="lds-dual-ring3"
            >
              <img src={lgloader} alt="" />
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default LoaderTable;
