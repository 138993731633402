import * as Types from "../actions";
export const actResetFetchResource = () => {
  return {
    type: Types.RESET_FETCH_RESOURCE
  };
};

export const actNotify = data => {
  return {
    type: Types.NOTIFY,
    data
  };
};
