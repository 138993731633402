import React, { Component } from "react";
import CanvasJSReact from "../../assets/js/canvasjs.react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { numberFormat } from "../../utils/numberFormat";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class StackedBar100PercentChart extends Component {
  constructor() {
    super();
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
    this.state = {
      dataChart: [],
      colorChart: ["#4661EE", "#EC5657", "#1BCDD1", "#8FAABB", "#B08BEB", "#3EA0DD", "#F5A52A", "#23BFAA", "#FAA586", "#EB8CC6"]
    };
  }
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  renderNameEnterprise(defaultName, lang) {
    switch (defaultName) {
      case "MicroEnterprise":
        return lang === "en" ? "Micro Enterprise" : "Doanh nghiệp siêu nhỏ";
      case "SmallEnterprise":
        return lang === "en" ? "Small Enterprise" : "Doanh nghiệp nhỏ";
      case "MediumEnterprise":
        return lang === "en" ? "Medium Enterprise" : "Doanh nghiệp vừa";
      case "LargeEnterprise":
        return lang === "en" ? "Large Enterprise" : "Doanh nghiệp lớn";
      case "BigCorp":
        return lang === "en" ? "Big Corp" : "Doanh nghiệp siêu lớn";
      default:
        return "N/A";
    }
  }

  render() {
    const { enabledToolTip, height, backgroundColor, tickColor } = this.props;
    const { colorChart } = this.state;
    const language = localStorage.getItem("currentLanguage");

    const { dataChart, needSwitchName } = this.props;
    const renderDataChart =
      dataChart &&
      dataChart.length > 0 &&
      dataChart.map((props, index) => {
        return {
          type: "stackedBar100",
          color: colorChart[index],
          name: props.name ? (needSwitchName ? this.renderNameEnterprise(props.name, language) : props.name) : "N/A",
          showInLegend: true,
          dataPoints: props.dataPoints ? props.dataPoints : [],
          indexLabel: "{y}",
          indexLabelFontColor: "white",
          yValueFormatString: "#,###"
        };
      });
    const options = {
      animationEnabled: false,
      zoomEnabled: false,
      // colorSet: "colorSet2",
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "#2e2e2e",
      theme: "light2", // "light1", "dark1", "dark2"
      title: {
        text: this.props.title ? this.props.title : "",
        margin: 20,
        fontSize: 14,
        fontColor: "#fff",
        fontFamily: "Roboto",
        fontWeight: "bold"
      },
      // trục ngang
      axisY: {
        title: "",
        labelFontSize: 12,
        titleFontColor: "#B6C4DE",
        labelFontColor: "#B6C4DE",
        gridColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor, // màu đường kẻ ngang
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor // màu các đoạn trỏ đến data
      },
      // trục dọc
      axisX: {
        labelFontColor: "#B6C4DE",
        lineColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,
        tickColor: tickColor === undefined ? "rgba(144, 155, 175, 0.3)" : tickColor,
        labelFontSize: 12
      },
      toolTip: {
        enabled: enabledToolTip === undefined ? true : enabledToolTip,
        // shared: true,
        contentFormatter: function(e) {
          return `<p style='font-weight: 500'>${e.entries[0].dataPoint.label}</p><p style=''><span>${
            e.entries[0].dataSeries.name
          }</span>: <span style="color: ${e.entries[0].dataSeries.color}; font-weight: 500;">${numberFormat(e.entries[0].dataPoint.y)}%</span></p>`;
        }
      },
      legend: {
        fontColor: "#B6C4DE",
        fontSize: 12,
        cursor: "pointer",
        itemclick: this.toggleDataSeries
        // horizontalAlign: "right",
        // verticalAlign: "center",
      },
      data: this.props.dataChart ? renderDataChart : [],
      height: 350,
      dataPointWidth: 30
    };
    if (height !== undefined) {
      options.height = height;
    }
    return (
      <React.Fragment>
        <div className="column-multiple-chart-wrapper">
          <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
          <div className="cright">
            <IntlMessages id="common.sourceVirac" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(StackedBar100PercentChart);
