import React, { Component } from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "../utils/IntlMessages";
class NoData extends Component {
  render() {
    return (
      <div className="no-data-container">
        <IntlMessages id="newWord.notAvailable" />
      </div>
    );
  }
}
export default injectIntl(NoData);
