import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/Authentication/actions";
import { Formik } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import * as Yup from "yup";
import ModalForgetPass from "./modal/ModalForgetPass";
import logo from "../../assets/images/logo.svg";
import BG from "../../assets/images/login.png";
import Button from "../../components/CustomButton/CustomButton";
import Header from "../../components/Header/HomeHead";
import IntlMessages from "../../utils/IntlMessages";
import { injectIntl } from "react-intl";
import ModalConfidentiality from "../../components/Footer/ModalConfidentiality";
import ModalCopyright from "../../components/Footer/ModalCopyright";
import ModalDisclaimer from "../../components/Footer/ModalDisclaimer";
import { Redirect } from "react-router-dom";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheck: false,
      toggleForgetPass: false,
      success: false,
      message: null,
      url: window.location.protocol + "//" + window.location.host,
      isSubmitted: false,
      toggleModalConfidentiality: false,
      toggleModalModalDisclaimer: false,
      toggleModalModalCopyright: false
    };
    this.form = React.createRef();
  }
  componentDidMount() {
    document.title = "VIRACE | Forgot Password";
  }
  handlePopupForgetPass = () => {
    this.setState((state) => ({
      toggleForgetPass: !state.toggleForgetPass
    }));
  };

  handleCloseForgetPass = (prams) => {
    this.setState({
      toggleForgetPass: prams
    });
  };

  handleModalConfidentiality = () => {
    this.setState((state) => ({
      toggleModalConfidentiality: !state.toggleModalConfidentiality
    }));
  };

  handleModalDisclaimer = () => {
    this.setState((state) => ({
      toggleModalModalDisclaimer: !state.toggleModalModalDisclaimer
    }));
  };

  handleModalCopyright = () => {
    this.setState((state) => ({
      toggleModalModalCopyright: !state.toggleModalModalCopyright
    }));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dataForgotPass = nextProps.authReducer;
    if (dataForgotPass) {
      if (dataForgotPass.success) {
        this.setState({
          toggleForgetPass: true,
          success: dataForgotPass.success,
          message: dataForgotPass.message
        });
      } else {
        this.setState({
          success: dataForgotPass.success,
          message: dataForgotPass.message
        });
      }
    }
  }

  handleInput = () => {
    this.setState({
      message: ""
    });
  };

  render() {
    const { loading, email } = this.props.authReducer;
    const { messages } = this.props.intl;
    const errorMessage = this.state.message;
    var d = new Date();
    var n = d.getFullYear();
    const { url } = this.state;
    const access_token = localStorage.getItem("access_token");
    return (
      <div className="login-page" style={{ backgroundImage: `url(${BG})` }}>
        {access_token && <Redirect to="/menu-dieu-huong" />}
        {this.state.toggleForgetPass && !loading && (
          <ModalForgetPass
            email={email}
            success={this.state.success}
            message={this.state.message}
            toggleForgetPass={this.state.toggleForgetPass}
            handleCloseForget={this.handleCloseForgetPass}
          />
        )}
        {this.state.toggleModalConfidentiality && (
          <ModalConfidentiality toggleModal={this.state.toggleModalConfidentiality} handleCloseForget={this.handleModalConfidentiality} />
        )}
        {this.state.toggleModalModalDisclaimer && (
          <ModalDisclaimer toggleModal={this.state.toggleModalModalDisclaimer} handleCloseForget={this.handleModalDisclaimer} />
        )}
        {this.state.toggleModalModalCopyright && (
          <ModalCopyright toggleModal={this.state.toggleModalModalCopyright} handleCloseForget={this.handleModalCopyright} />
        )}
        <Header history={this.props.history} />
        <div className="login-form-container form-container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="login-form">
            <Formik
              initialValues={{ emailConfirm: "" }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                setTimeout(() => {
                  this.props.handleGetConfirmEmail(values.emailConfirm, url);
                  this.setState({
                    isCheck: true
                  });
                  setSubmitting(false);
                }, 500);
              }}
              validationSchema={Yup.object().shape({
                emailConfirm: Yup.string()
                  .required(<IntlMessages id="error.emailRequired" />)
                  .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.-]{2,}$/, <IntlMessages id="error.emailFormatted" />)
              })}
            >
              {(props) => {
                const { values, errors, handleChange, handleBlur, handleSubmit } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <ConnectedFocusError />
                    <h1>
                      <IntlMessages id="auth.forgotPass" />
                    </h1>
                    <div className="note">
                      <IntlMessages id="auth.forgotPass.text" />
                    </div>
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">email</span>
                        </div>
                        <input
                          id="email"
                          type="text"
                          placeholder={messages[`auth.enterEmail`]}
                          name="emailConfirm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={this.handleInput}
                          value={values.emailConfirm}
                        />
                      </label>
                      {errors.emailConfirm && <div className="form-error">{errors.emailConfirm}</div>}
                      {errorMessage && errorMessage === "Not Found User" && (
                        <div className="form-error">
                          <IntlMessages id="auth.wrongAccount_p1" />
                          {values.emailConfirm}
                          <IntlMessages id="auth.wrongAccount_p2" />
                        </div>
                      )}
                    </div>
                    <div className="form-submit login-submit">
                      <Button disabled={loading} isLoading={loading} className="common-button w-100">
                        <IntlMessages id="auth.forgotPass.send" />
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          <div className="copyright">
            <p>
              <a href="#" onClick={() => this.handleModalCopyright()}>
                COPYRIGHT &copy; {n} VIRAC
              </a>
            </p>
            <p>
              <a href="#" onClick={() => this.handleModalConfidentiality()}>
                <IntlMessages id="footer.confidentiality" />
              </a>{" "}
              |{" "}
              <a href="#" onClick={() => this.handleModalDisclaimer()}>
                <IntlMessages id="footer.disclaimer" />
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer.dataForgotPass
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    handleGetConfirmEmail: (emailConfirm, url) => dispatch(actions.actGetConfirmEmail(emailConfirm, url))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotPassword));
