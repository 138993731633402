import React, { Component } from "react";
import { Icon, Pagination } from "semantic-ui-react";
import { injectIntl } from "react-intl";
class PaginationSimple extends Component {
	render() {
        const { messages } = this.props.intl;
        const { activePage, totalPages, onPageChange } = this.props;
        return (
            <React.Fragment>
                <Pagination
                    activePage={activePage}
                    onPageChange={onPageChange}
                    ellipsisItem={{
                        content: <Icon name="ellipsis horizontal" />,
                        icon: true
                    }}
                    firstItem={{
                        content: messages["pagi.First"],
                        icon: true
                    }}
                    lastItem={{
                        content:  messages["pagi.Last"],
                        icon: true
                    }}
                    prevItem={{
                        content: <span className="material-icons">chevron_left </span>,
                        icon: true
                    }}
                    nextItem={{
                        content: <span className="material-icons">chevron_right </span>,
                        icon: true
                    }}
                    totalPages={totalPages}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(PaginationSimple);